import React from 'react';
import {
  CcItemMobile,
  DoubleForms,
  FormCcContainer,
  FormLabel,
  InputCcField,
  KartuKreditTabStyled,
  Logo,
  LogoContainer,
  TitleMobile,
} from './styled';

import visa from 'assets/png/KartuKredit/image 21.png';
import masterCard from 'assets/png/KartuKredit/image 22.png';
import jcb from 'assets/png/KartuKredit/image 23.png';
import americanExpress from 'assets/png/KartuKredit/image 24.png';
import cc from 'assets/svg/creditCard.svg';

function KartuKreditTab({
  isMobile,
  setTab,
  hide,
  data_kartu_kredit,
  onKartuKreditChange,
  dataOrder,
  onDataOrderChange,
}) {
  const payment = { name: 'Kartu Kredit', logo: cc, desc: 'CC' };

  const normalizeCardNumber = (value) => {
    return (
      value
        .replace(/\s/g, '')
        .match(/\d{1,4}/g)
        ?.join(' ')
        .substr(0, 19) || ''
    );
  };

  return (
    <KartuKreditTabStyled hide={hide}>
      <TitleMobile>Kartu Kredit</TitleMobile>
      <CcItemMobile
        onClick={() => {
          setTab('Kartu Kredit');
          if (isMobile) {
            onDataOrderChange({
              ...dataOrder,
              paymentMethod: 'credit_card',
            });
          }
        }}
        selected={dataOrder.paymentMethod === 'credit_card'}
        payment={payment}
      />
      <LogoContainer>
        <Logo src={visa} />
        <Logo src={masterCard} />
        <Logo src={jcb} />
        <Logo src={americanExpress} />
      </LogoContainer>
      <form>
        <FormCcContainer>
          <FormLabel>Nama Sesuai Kartu</FormLabel>
          <InputCcField name="nama" dark placeholder="Nama" />
        </FormCcContainer>
        <FormCcContainer>
          <FormLabel>Nomor Kartu</FormLabel>
          <InputCcField
            value={data_kartu_kredit.cardNumber}
            type="tel"
            inputMode="numeric"
            autoComplete="cc-number"
            name="cc"
            dark
            placeholder="Contoh: 1234 5678 9012 3456"
            onChange={(val) => {
              const { value } = val.target;
              val.target.value = normalizeCardNumber(value);
              onKartuKreditChange({
                ...data_kartu_kredit,
                cardNumber: normalizeCardNumber(value),
              });
            }}
          />
        </FormCcContainer>
        <FormCcContainer>
          <FormLabel>Tanggal Kadaluarsa</FormLabel>
          <DoubleForms>
            <InputCcField
              value={data_kartu_kredit.expiryDate}
              onChange={(e) => {
                onKartuKreditChange({
                  ...data_kartu_kredit,
                  expiryDate: e.target.value,
                });
              }}
              name="expired"
              small
              dark
              placeholder="mm/yy"
            />
            <FormLabel secondary>Kode Keamanan</FormLabel>
            <InputCcField
              value={data_kartu_kredit.cardCvv}
              onChange={(e) => {
                onKartuKreditChange({
                  ...data_kartu_kredit,
                  cardCvv: e.target.value,
                });
              }}
              type="password"
              name="security"
              small
              dark
              maxLength={3}
              placeholder="3 angka di belakang kartu"
            />
          </DoubleForms>
        </FormCcContainer>
      </form>
    </KartuKreditTabStyled>
  );
}

export default KartuKreditTab;
