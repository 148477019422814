import styled from 'styled-components';

export const FeedbackWebinarStyled = styled.div`
  margin-top: 77px;
  display: flex;
  flex-direction: column;
`;

export const TitleFeedBack = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  text-align: center;
  margin-top: 40px;

  color: #ffffff;
`;
