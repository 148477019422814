import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  background-color: ${(props) => (props.home ? '#141414' : '#202020')};
  padding: 50px 0 100px;

  .button-slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    .swiper-wrapper {
      margin-left: 16px;
    }
  }

  .slider {
    position: relative;
    margin-bottom: 8px;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 40px 0 100px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 30px 0 60px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 24px 0;
  }
`;

export const ButtonEnd = styled.div`
  background-color: #373737;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 502px;
  width: 100vw;

  .none{
    display: none;
  }

  .wrap-button{
    display: block;
    cursor: pointer;

    div.content{
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      color: #00DEBF;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    border-radius: 12px;
    height: 550px;
    width: 50vw;

    .wrap-button{
      svg{
        width: 100px;
      }

      div.content{
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 35px;
        text-align: center;
        color: #00DEBF;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    border-radius: 12px;
    height: 290px;
    width: 50vw;

    .wrap-button{
      svg{
        width: 50px;
      }

      div.content{
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 35px;
        text-align: center;
        color: #00DEBF;
      }
    }
  }

`

export const Title = styled.h2`
  font-family: Archivo;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #fdfdfd;
  margin: 0px auto 50px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 80%;
    margin: 0 auto;
    font-size: 20px;
    margin-bottom: 24px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  padding: 0 100px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0 60px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 0;
  }
`;

export const SliderPagination = styled.div`
  position: relative;
  text-align: center;
  transition: all 300ms ease;
  transform: translate3d(0, 0, 0);
  z-index: 2;

  span {
    margin: 0 5px;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: #c4c4c4;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    width: 24px;
    height: 6px;
    border-radius: 20px;
    background-color: #ffffff;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin-top: 0px;

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
    }

    .swiper-pagination-bullet-active {
      width: 20px;
    }
  }
`;
