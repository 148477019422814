import React from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { Container, ButtonContainer } from './styled';
import PopupModal from 'components/atoms/Modal';
import { Title, Separator } from '../KeluarModal/styled';
import { Content } from '../SmallDarkModal/styled';
import Button from 'components/atoms/Button';
import { scrollMidle } from 'utils/scrollPage';
import { isDateExpired } from '../../../../utils/date';
import { t } from 'hooks/intl/useLang';

const IkutiKelasModal = ({
  freeTrial,
  setShowModal,
  submitNewTraining,
  id,
  trainerId,
  userSubscription,
  profile,
  ...rest
}) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const date = new Date(profile?.subscription_end_date);
  const expired = isDateExpired(profile?.subscription_end_date);

  const checkTitleModal = () => {
    // const noQuota = profile?.User?.free_trial === true && profile?.training_quota === 0 && profile?.is_subscription === false;
    // const expiredSubs = isDateExpired(profile?.subscription_end_date);
    const haveQuotaFreeTrial = profile?.User?.free_trial && profile?.training_quota > 0 && !expired;
    const haveQuota = profile?.training_quota > 0 && !profile?.User?.free_trial;
    // const kuotaBulananHabis =
    //   profile?.User?.free_trial || (profile?.is_subscription && profile?.userSubscription === 'Monthly' && profile?.training_quota === 0);

    switch (true) {
      // case noQuota:
      //   return <Title>Oops! Kuota Free Trial Kamu Sudah Habis</Title>;
      case haveQuotaFreeTrial:
        return <Title>{t('Kamu punya')} {profile?.video_quota} {t('Video Training Gratis')}</Title>;
      case haveQuota:
        return <Title>{t('Kamu Yakin Mengikuti Training Ini?')}</Title>;
      // case expiredSubs:
      //   return <Title>Oops! Masa Langganan Kamu Habis</Title>;
      // case kuotaBulananHabis:
      //   return <Title>Oops! Kuota Kamu Sudah Habis</Title>;
      default:
        return <Title>{'Oops! Kamu tidak mempunyai kuota'}</Title>;
    }
  };

  const submitBuyTrainingOne = () => {
    // history.push({ pathname, hash: 'ikutikelas' });
    const element = document.getElementById('ikutikelas');
    if (element === null) {
      history.push({ pathname: `${url}/deskripsi` });
      setTimeout(() => {
        scrollMidle('ikutikelas', 5);
      }, 300);
    } else {
      scrollMidle('ikutikelas', 5);
    }
    setShowModal(false);
  };

  const checkIkutiKelasType = () => {
    const haveQuota = profile?.training_quota > 0 && !profile?.User?.free_trial;
    const haveQuotaFreeTrial = profile?.User?.free_trial && profile?.training_quota > 0 && !expired;
    // const noQuotaFreeTrial = profile?.User?.free_trial === true && profile?.training_quota === 0 && profile?.is_subscription === false;
    // const noQuota = (profile?.User?.free_trial && profile?.training_quota === 0) || is_subscription;
    // const expiredSubs = isDateExpired(profile?.subscription_end_date);
    // const kuotaBulananHabis =
    //   profile?.User?.free_trial || (profile?.is_subscription && profile?.userSubscription === 'Monthly' && profile?.training_quota === 0);

    // if (!profile?.User?.free_trial && !profile?.is_subscription && expiredSubs) {
    //   return (
    //     <p>
    //       Kamu tidak bisa ikuti training ini. Silakan mulai berlangganan lagi atau beli training ini
    //       untuk akses selamanya seharga Rp 150.000.
    //     </p>
    //   );
    // }
    // if (!haveQuota && kuotaBulananHabis) {
    //   <p>
    //     Kamu tidak mempunyai kuota tersisa untuk mengikuti training bulan ini. Silahkan upgrade
    //     menjadi langganan Tahunan untuk menikmati Training tak terbatas.
    //   </p>;
    // }

    // if (expiredSubs) {
    //   return (
    //     <p>
    //       Kamu tidak bisa ikuti training ini. Silakan mulai berlangganan lagi atau beli training ini
    //       untuk akses selamanya seharga Rp 150.000.
    //     </p>
    //   );
    // }

    switch (true) {
      case haveQuota:
        return (
          <p>
            {t('Kamu mempunyai')} <strong>{profile?.training_quota} {t('kuota')}</strong> {t('tersisa untuk mengikuti training. Kamu bisa menambah kuota training kamu untuk ikuti training lebih banyak.')}
          </p>
        );

      case haveQuotaFreeTrial:
        return (
          <p>
            {t('Kamu memiliki akses Free Trial sampai')}{' '}
            <strong>
              {date?.toLocaleDateString('id', { day: '2-digit', month: 'long', year: 'numeric' })}{' '}
              23:59.
            </strong>{' '}
            {t('Silakan klik salah satu video yang ingin Kamu tonton atau langsung beli training ini dengan klik button Detail Paket di bawah.')}
          </p>
        );
      default:
        return (
          <p>
            {t('Silakan beli paket training terlebih dahulu untuk ikuti training dan menambah skill kamu.')}
          </p>
        );
    }
  };

  const checkButtonType = () => {
    switch (true) {
      case profile?.training_quota > 0 && profile?.User?.free_trial:
        return (
          <>
            {/* {!profile?.User?.free_trial ? (
              <Button medium className="button" onClick={() => history.push('/berlangganan')}>
                Beli Paket Training
              </Button>
            ) : ( */}
            <Button medium className="button" onClick={() => submitBuyTrainingOne()}>
              {t('Detail Paket')}
            </Button>
            {!expired && (
              <Button medium className="secondary button" onClick={() => setShowModal(false)}>
                {t('Cari Video Training')}
              </Button>
            )}
          </>
        );

      default:
        return (
          <>
            <Button medium className="button" onClick={() => submitTraining(id, trainerId)}>
              {t('Ya, ikuti Training dengan')} {profile?.User?.free_trial ? 'Free Trial' : t('Kuota')}
            </Button>

            <Button medium className="secondary button" onClick={() => setShowModal(false)}>
              {t('Tidak')}
            </Button>
          </>
        );
    }
  };

  const submitTraining = (id, trainerId) => {
    submitNewTraining({
      variables: {
        input: {
          trainingId: id,
          trainerId: trainerId,
          trainingType: 'package',
        },
      },
    });
  };

  return (
    <PopupModal dark medium {...rest}>
      <Container>
        {checkTitleModal()}
        <Separator />
        <Content>{checkIkutiKelasType()}</Content>
        <ButtonContainer className="mb-4">{checkButtonType()}</ButtonContainer>
      </Container>
    </PopupModal>
  );
};

export default IkutiKelasModal;
