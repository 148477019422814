import styled from 'styled-components';

export const Container = styled.section`
  padding: 40px 50px 80px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 30px 32px 40px;
  }
`;

export const Title = styled.h2`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 33px;

  /* identical to box height */

  text-align: center;

  color: #fdfdfd;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 24px;
    line-height: 26px;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 60px;

  & > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height, or 150% */
      margin: 40px 0 0 0;
      text-align: center;

      color: #fdfdfd;
    }

    & > p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      /* or 150% */

      text-align: center;

      color: #c4c4c4;
      width: 80%;
      margin: 24px 0 0 0;
    }
  }

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    flex-direction: column;
    gap: 24px;

    & > div {
      h3 {
        font-size: 16px;
        line-height: 20px;
        margin: 20px 0 0 0;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        margin: 12px 0 0 0;
        width: 100%;
      }
    }
  }
`;
