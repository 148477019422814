import styled from 'styled-components';

export const Container = styled.div`
  padding: 33px 100px 70px;
  background-color: #141414;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
  row-gap: 50px;
  column-gap: 20px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 30px 30px 0;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    grid-template-columns: auto;
    padding: 30px 0px 0;
    row-gap: 30px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    grid-template-columns: auto;
    row-gap: 12px;
  }
`;
