import { gql } from '@apollo/client';

export const GET_VOUCHER = gql`
  query GET_VOUCHER($code: String) {
    Voucher(code: $code) {
      id
      image
      code
      title
      description
      benefit_type
      benefit_value
      start_at
      expired_at
      quota
      specific_users
      quota_per_user
      is_active
      is_partner_voucher
      specific_subscriptions
      specific_package
      specific_training
      specific_webinar
    }
  }
`;

export const GET_VOUCHER_PRAKERJA = gql`
  query GET_VOUCHER_PRAKERJA($voucherCode: String) {
    CheckPrakerjaVoucher(voucherCode: $voucherCode) {
      status
      statusText
      item {
        Training {
          id
          title
          thumbnail
          slug
          trainer {
            fullname
            id
          }
        }
      }
    }
  }
`;

export const GET_VOUCHER_UNDANG_TEMAN = gql`
  query USER_LIST_VOUCHER_VIDEO_FREE_TRAINING {
    UserVideoVouchers {
      items {
        code
        title
        expired_at
        start_at
      }
    }
    UserPackages(referral: true) {
      items {
        id
        status
        type
        remaining_quota
        referral
        expired_at
      }
    }
  }
`;
