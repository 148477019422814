import React, { useEffect, useState } from 'react';
import { Detail, HeadContainer, SocialMedia, MainContainer } from './styled';

import Star from 'assets/png/star.png';
import LinkedIn from 'assets/png/socialmedia/LinkedIn.png';
import Facebook from 'assets/png/socialmedia/facebook.png';
import Instagram from 'assets/png/socialmedia/Instagram.png';
import Twitter from 'assets/png/socialmedia/Twitter.png';
import { Link } from 'react-router-dom';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';

const DetailTrainer = ({
  data: {
    fullname,
    categories = [],
    views,
    total_training,
    rating,
    website,
    instagram,
    twitter,
    facebook,
    linkedin,
    profession = null,
    total_score,
  },
  userProfile,
}) => {
  const [isMobile, setCheckMobile] = useState(false);

  useEffect(() => {
    setCheckMobile(checkMobile());
  }, []);

  if (isMobile) {
    return (
      <>
        <Detail>
          <HeadContainer>
            <h3>{fullname}</h3>
          </HeadContainer>
          <p className="profession">{profession}</p>
          <SocialMedia>
            {facebook && (
              <a target="_blank" rel="noreferrer" href={facebook}>
                <img src={Facebook} alt="facebook-logo" />
              </a>
            )}
            {linkedin && (
              <a target="_blank" rel="noreferrer" href={linkedin}>
                <img src={LinkedIn} alt="linkedin-logo" />
              </a>
            )}
            {instagram && (
              <a target="_blank" href={instagram} rel="noreferrer">
                <img src={Instagram} alt="instagram-logo" />
              </a>
            )}
            {twitter && (
              <a target="_blank" rel="noreferrer" href={twitter}>
                <img src={Twitter} alt="twitter-logo" />
              </a>
            )}
          </SocialMedia>
        </Detail>
        <MainContainer>
          {!userProfile && (
            <div className="trainer-stats">
              <div id="rating-trainer">
                <p>
                  <img src={Star} alt="rating-icon" />
                  {typeof rating !== 'undefined' ? rating.toFixed(1) : 0} ({total_score} {t('penilaian')})
                </p>
              </div>
              <span>{'\u2022'}</span>
              <div id="views-trainer">
                <p>{views} viewers</p>
              </div>
              <span>{'\u2022'}</span>
              <div id="kelas-trainer">
                <p>{total_training} Training</p>
              </div>
            </div>
          )}
          <div className={categories?.length > 1 ? 'trainer-kategori' : 'trainer-kategori-more'}>
            {categories &&
              categories.map((data) => (
                <Link to={`/trainings?page=1&category=${data.id}`} key={data.id}>
                  {data.name}
                </Link>
              ))}
          </div>
        </MainContainer>
      </>
    );
  }

  return (
    <Detail>
      <HeadContainer>
        <h3>{fullname}</h3>
        <SocialMedia>
          {facebook && (
            <a target="_blank" href={facebook} rel="noreferrer">
              <img src={Facebook} alt="facebook-logo" />
            </a>
          )}
          {linkedin && (
            <a target="_blank" rel="noreferrer" href={linkedin}>
              <img src={LinkedIn} alt="linkedin-logo" />
            </a>
          )}
          {instagram && (
            <a target="_blank" rel="noreferrer" href={instagram}>
              <img src={Instagram} alt="instagram-logo" />
            </a>
          )}
          {twitter && (
            <a target="_blank" rel="noreferrer" href={twitter}>
              <img src={Twitter} alt="twitter-logo" />
            </a>
          )}
        </SocialMedia>
      </HeadContainer>

      <p className="profession">{userProfile ? profession : profession}</p>
      <MainContainer>
        {!userProfile && (
          <div className="trainer-stats">
            <div id="rating-trainer">
              <p>
                <img src={Star} alt="rating-icon" />
                {typeof rating !== 'undefined' ? rating.toFixed(1) : 0} ({total_score} {t('penilaian')})
              </p>
            </div>
            <span>{'\u2022'}</span>
            <div id="views-trainer">
              <p>{views} viewers</p>
            </div>
            <span>{'\u2022'}</span>
            <div id="kelas-trainer">
              <p>{total_training} Training</p>
            </div>
          </div>
        )}
        <div className="trainer-kategori">
          {categories &&
            categories.map((data) => (
              <Link to={`/trainings?page=1&category=${data.id}`} key={data.id}>
                {data.name}
              </Link>
            ))}
        </div>
      </MainContainer>
    </Detail>
  );
};

export default DetailTrainer;
