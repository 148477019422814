/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';

// Import the Slate editor factory.
import { createEditor } from 'slate';

// Import the Slate components and React plugin.
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';

import { Container } from './styled';

const RichTextInput = ({ type = 'plain', onChange, value, ...rest }) => {
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);

  const withMentions = (editor) => {
    const { isInline, isVoid } = editor;

    editor.isInline = (element) => {
      return element.type === 'mention' ? true : isInline(element);
    };

    editor.isVoid = (element) => {
      return element.type === 'mention' ? true : isVoid(element);
    };

    return editor;
  };

  return (
    <Container>
      <Slate editor={editor} value={value} onChange={onChange}>
        <Editable {...rest} placeholder="e.g Lorem ipsum" />
      </Slate>
    </Container>
  );
};

export default RichTextInput;
