import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_QUIZ_USER } from 'queries/kuis';
import { setContext } from 'utils';
import { useSelector } from 'react-redux';

import PopupModal from 'components/atoms/Modal';
import { Head, Detail, Footer, LoadingWrapper } from './styled';
import Button from 'components/atoms/Button';
import { LoaderIcon } from 'react-hot-toast';

import { ReactComponent as SuccessIcon } from 'assets/svg/Kuis/winner.svg';
import { ReactComponent as FailIcon } from 'assets/svg/Kuis/fail-small.svg';
import { t } from 'hooks/intl/useLang';

const KuisSelesaiModal = ({
  currentSection,
  setCurrentSection,
  title,
  setShowSelesaiModal,
  trainingTitle,
  setType,
  retakeQuizPostTest,
  trainingId,
  getTrainingDetails,
  getUserTraining,
  resetModal,
  moduleData,
  type,
  pretestAnswer,
  updateTraining,
  updateUserTraining,
  allowPostTest,
  setShownAllowPostes,
  ...rest
}) => {
  const authData = useSelector((state) => state.Auth);

  const { data, loading = true } = useQuery(GET_QUIZ_USER, {
    context: setContext(authData),
    variables: {
      trainingId: trainingId,
      trainingSectionId: currentSection.training_chapter_id,
    },
  });

  const renderData = () => {
    if (loading) {
      return (
        <LoadingWrapper className="wrapper-loading">
          <LoaderIcon className="loading-icon" />
        </LoadingWrapper>
      );
    }

    const summary = data?.ResultQuizUser?.summary.split('/');

    if (type === 'Pretest') {
      return (
        <>
          <Head>
            <h5>{t('Selamat! sudah menyelesaikan Pretes')}</h5>
            <h6>{trainingTitle}</h6>
          </Head>
          <Detail>
            <SuccessIcon />
            <p>
              {t('Kamu berhasil menjawab')} <span>{pretestAnswer}/20</span> {t('pertanyaan dengan benar')}
            </p>
            <p className="text">
              {t('*Kamu bisa ambil ulang pretes ini dengan klik button Retake di bawah atau klik button Lanjut Materi untuk ke materi berikutnya.')}
            </p>
          </Detail>
          <Footer>
            <Button
              className="button"
              onClick={() => {
                setShowSelesaiModal(false);

                setType('Material');

                updateTraining({
                  variables: {
                    input: {
                      trainingId: trainingId,
                      trainingProgress: 0,
                      status: 'Progress',
                      lastTrainingChapterId: moduleData?.TrainingChapters?.items[0]?.id,
                      lastTrainingSectionId:
                        moduleData?.TrainingChapters?.items[0]?.sections[0]?.id,
                      lastTrainingSectionDuration: 0,
                    },
                  },
                });

                updateUserTraining({
                  variables: {
                    input: {
                      trainingId: trainingId,
                      trainingChapterId: moduleData?.TrainingChapters?.items[0]?.id,
                      trainingSectionId: moduleData?.TrainingChapters?.items[0]?.sections[0]?.id,
                      duration: 0,
                    },
                  },
                });

                setCurrentSection({
                  ...currentSection,
                  training_id: moduleData?.TrainingChapters?.items[0]?.id,
                  training_chapter_id: moduleData?.TrainingChapters?.items[0]?.sections[0]?.id,
                  training_order: 1,
                  section_order: 1,
                });
              }}
            >
              {t('Lanjut Materi')}
            </Button>
            <Button
              className="button secondary"
              onClick={() => {
                setType('Pretest');

                retakeQuizPostTest();

                resetModal();
              }}
            >
              Retake
            </Button>
          </Footer>
        </>
      );
    }

    if (+summary[0] / +summary[1] >= 0.75) {
      return (
        <>
          <Head>
            <h5>{t('Selamat! sudah menyelesaikan kuis')} {title}</h5>
            <h6>{trainingTitle}</h6>
          </Head>
          <Detail>
            <SuccessIcon />
            <p>
              {t('Kamu berhasil menjawab')} <span>{data?.ResultQuizUser?.summary}</span> {t('pertanyaan dengan benar')}
            </p>
            <text className="text">
              {t('*Kamu bisa ambil ulang kuis ini dengan klik button Retake di bawah atau klik button Lanjut Materi untuk ke materi berikutnya.')}
            </text>
          </Detail>
          <Footer>
            <Button
              className="button"
              onClick={async () => {
                setShowSelesaiModal(false);

                const nextChapter =
                  moduleData?.TrainingChapters?.items[currentSection?.training_order - 1]?.sections[
                    currentSection?.section_order
                  ];
                const nextType =
                  moduleData?.TrainingChapters?.items[currentSection?.training_order - 1]?.sections[
                    currentSection?.section_order
                  ]?.type;
                if (nextChapter) {
                  if (allowPostTest) {
                    // console.log('ALOOWPSOTES');
                    setType(nextType);
                    setCurrentSection({
                      ...currentSection,
                      training_id:
                        moduleData?.TrainingChapters?.items[currentSection?.training_order - 1]?.id,
                      training_chapter_id:
                        moduleData?.TrainingChapters?.items[currentSection?.training_order - 1]
                          ?.sections[currentSection?.section_order]?.id,
                      training_order: currentSection?.training_order,
                      section_order: currentSection?.section_order + 1,
                    });
                  } else if (nextType === 'PostTest' && !allowPostTest) {
                    // popup allowpostest
                    setShownAllowPostes(true);
                  } else {
                    setType('Material');

                    setCurrentSection({
                      ...currentSection,
                      training_id:
                        moduleData?.TrainingChapters?.items[currentSection?.training_order]?.id,
                      training_chapter_id:
                        moduleData?.TrainingChapters?.items[currentSection?.training_order]
                          ?.sections[0]?.id,
                      training_order: currentSection?.training_order + 1,
                      section_order: 1,
                    });
                  }
                } else {
                  // console.log('LAINYA AFTER KUIS');
                  setType('Material');

                  setCurrentSection({
                    ...currentSection,
                    training_id:
                      moduleData?.TrainingChapters?.items[currentSection?.training_order]?.id,
                    training_chapter_id:
                      moduleData?.TrainingChapters?.items[currentSection?.training_order]
                        ?.sections[0]?.id,
                    training_order: currentSection?.training_order + 1,
                    section_order: 1,
                  });
                }

                await getUserTraining();
                await getTrainingDetails();
              }}
            >
              Lanjut Materi
            </Button>
            {/* <Button
              className="button secondary"
              onClick={() => {
                setType('Test');

                retakeQuizPostTest();
                getUserTraining();
                getTrainingDetails();

                resetModal();
              }}
            >
              Retake
            </Button> */}
          </Footer>
        </>
      );
    } else {
      return (
        <>
          <Head>
            <h5 className="danger">{t('Nilai kuis kamu kurang dari 80 :(')}</h5>
            <h6>{trainingTitle}</h6>
          </Head>
          <Detail>
            <FailIcon />
            <p>
              {t('Kamu berhasil menjawab')} <span className="danger">{data?.ResultQuizUser?.summary}</span>{' '} {t('pertanyaan dengan benar')}
            </p>
            <p className="text">
              {t('Kamu harus mendapatkan nilai kuis minimum 80 untuk lanjut ke materi selanjutnya. Klik retake untuk ikuti kuis lagi. Semangat!')}
            </p>
          </Detail>
          <Footer>
            <Button
              className="button"
              onClick={() => {
                setShowSelesaiModal(false);
                setType('Material');

                setCurrentSection({
                  ...currentSection,
                  training_chapter_id:
                    moduleData?.TrainingChapters?.items[0]?.sections[
                      currentSection?.section_order - 2
                    ]?.id,
                  section_order: currentSection?.section_order - 1,
                });

                getUserTraining();
                getTrainingDetails();
              }}
            >
              {t('Kembali ke Materi')}
            </Button>
            <Button
              className="button secondary"
              onClick={() => {
                setType('Test');

                retakeQuizPostTest();
                getUserTraining();
                getTrainingDetails();

                resetModal();
              }}
            >
              Retake
            </Button>
          </Footer>
        </>
      );
    }
  };

  return (
    <PopupModal withoutCloseButton dark {...rest} className="w-80">
      {renderData()}
    </PopupModal>
  );
};

export default KuisSelesaiModal;
