import styled from 'styled-components';
import { mq } from 'assets/styles/theme';

const Search = styled.div`
  width: 50vw;
  height: 48px;
  margin: 0 30px 0 30px;
  padding: 8px 10px 10px 16px;
  border-radius: 5px;
  background-color: #23272f;
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;

  input:focus {
    outline: none;
  }

  display: flex;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;

  &.mobile {
    width: 100%;
    justify-content: space-between;

    & img {
      padding: 3px 10px 3px 2.5px !important;
    }
  }

  input[type='text'] {
    background-color: inherit;
    width: 80%;
    border: none;
    height: 90%;
    font-family: Inter;
    color: #fdfdfd;
  }

  img {
    cursor: pointer;

    width: 18px;
    height: 18px;
    margin: 0;
    padding: 3px 20.5px 3px 2.5px;
  }

  @media screen and (max-width: 2001px) {
    margin: 0 8px 0 10px;
    width: 35vw;
  }

  @media screen and ${(props) => props.theme.breakpoints.xl} {
    input[type='text'] {
      font-size: 13px;
    }
  }

  ${mq({
    width: [null, '30vw', '24vw', '50vw', '40vw'],
    margin: [null, null, '0 16px', '0 10px', '0 5px'],
  })}
`;

export default Search;
