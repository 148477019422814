import React from 'react';
import { InputContainer, InputFieldStyled, LabelInput } from './styled';

function TextArea({ register, label, ...rest }) {
  const [isFocus, setIsFocus] = React.useState(false);
  const [value /*, setValue*/] = React.useState('');

  return (
    <InputContainer {...rest}>
      <LabelInput focus={isFocus}>{label}</LabelInput>
      <InputFieldStyled
        ref={register}
        defaultValue={value}
        onFocus={() => setIsFocus(true)}
        onBlur={(e) => {
          if (e.target.value === '') {
            setIsFocus(false);
          }
        }}
        {...rest}
      />
    </InputContainer>
  );
}

export default TextArea;
