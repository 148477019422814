import React, { useState } from 'react';
import PageLayout from '../../components/pagelayout/Template';
import {
  BottomSection,
  CardSection,
  DescContainer,
  DescWebinar,
  DetailWebinarStyled,
  ImageBannerWebinar,
  MobileTitle,
  PriceText,
  TitleCardSection,
  TitleWebinar,
} from './styled';
import MaxContainer from '../../components/atoms/MaxContainer';
import Button from '../../components/atoms/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_USER_WEBINAR_BY_ID,
  GET_USER_WEBINARS,
  GET_WEBINAR_BY_ID,
} from '../../queries/webinar';
import { getValue, setContext } from '../../utils';
import { useSelector } from 'react-redux';
import { CREATE_USER_WEBINAR } from '../../mutation/webinar';
import { GET_USER_DETAIL } from '../../queries/user';
import OTPModal from '../../components/molecule/Modal/OTPModal';
import LengkapiProfilModal from '../../components/molecule/Modal/LengkapiProfilModal';
import { COMPLETE_PROFILE } from '../../mutation/profile';
import PopupBerhasil from '../../components/molecule/Modal/PopupBerhail';
import { dataSubsNotAuth } from '../../utils/makeVarAuth';
import { formatRp } from '../../utils/convertNumToStr';
import TentangTrainer from '../../components/molecule/Deskripsi/TentangTrainer';
import checklist from '../../assets/svg/checklist.svg';
import { CheckList } from '../../components/molecule/Cards/TrainingOverview/TrainingHover.styled';
import { t } from 'hooks/intl/useLang';

const seo = {
  title: 'Webinar',
  description: t('Ikuti Webinar bersama Pemateri Berpengalaman'),
  canonical: null,
};

const DetailWebinar = () => {
  const { roomId } = useParams();
  const authData = useSelector((state) => state.Auth);
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [openModalOTP, setOpenModalOTP] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [dataLengkapiProfile, setDataLengkapiProfile] = useState();

  const { data: webinarData } = useQuery(GET_WEBINAR_BY_ID, {
    variables: {
      room_id: roomId,
    },
    onCompleted(data) {
      window.Moengage.track_event(t('Preview Webinar'), {
        webinar_name: data?.Webinar?.name,
        trainer_name: data?.Webinar?.trainer?.fullname,
      });
    },
  });

  const { data: profile } = useQuery(GET_USER_DETAIL, {
    fetchPolicy: 'network-only',
    context: setContext(authData),
    variables: {
      slug: authData.data.slug,
    },
  });

  const { data: userWebinar } = useQuery(GET_USER_WEBINAR_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      webinar_id: webinarData?.Webinar?.id,
    },
    context: setContext(authData),
  });

  const [registerWebinar, { loading }] = useMutation(CREATE_USER_WEBINAR, {
    fetchPolicy: 'no-cache',
    variables: {
      input: { webinar_id: webinarData?.Webinar?.id },
    },
    context: setContext(authData),
    onCompleted(data) {
      setShowSuccessModal(true);
      window.Moengage.track_event('Join Webinar', {
        webinar_name: webinarData?.Webinar?.name,
        trainer_name: webinarData?.Webinar?.trainer?.fullname,
      });
    },
    refetchQueries: [
      {
        query: GET_USER_WEBINARS,
        context: setContext(authData),
      },
      {
        query: GET_USER_WEBINAR_BY_ID,
        variables: {
          webinar_id: webinarData?.Webinar?.id,
        },
        context: setContext(authData),
      },
    ],
  });

  const [completeProfile, { error: errorCompleteProfile }] = useMutation(COMPLETE_PROFILE, {
    refetchQueries: [
      {
        query: GET_USER_DETAIL,
        context: setContext(authData),
        variables: {
          slug: getValue(authData, 'data.slug'),
        },
      },
    ],
    onCompleted() {
      if (webinarData?.Webinar?.is_free) {
        registerWebinar();
      } else {
        history.push('/pembayaran/webinar/' + webinarData?.Webinar?.room_id);
      }
      setOpenModalOTP(false);
    },
  });

  const joinWebinar = async () => {
    if (!profile?.ProfileUser?.User?.email || !profile?.ProfileUser?.User?.phone_number) {
      setOpenModal(true);
    } else {
      if (webinarData?.Webinar?.is_free) {
        registerWebinar();
      } else {
        history.push('/pembayaran/webinar/' + webinarData?.Webinar?.room_id);
      }
    }
  };

  const liveWebinar = () => {
    history.push(`/webinar/live/${roomId}`);
  };

  const toLogin = () => {
    dataSubsNotAuth('/webinar/detail/' + roomId);
    history.push(`/registrasi`);
  };

  return (
    <PageLayout seo={seo} noFooter>
      <MaxContainer>
        <DetailWebinarStyled>
          <ImageBannerWebinar src={webinarData?.Webinar?.banner_img} />
          <MaxContainer>
            <MobileTitle>{webinarData?.Webinar?.name}</MobileTitle>

            <CardSection style={{ paddingBottom: 8 }}>
              <TitleCardSection>{t('Deskripsi')}</TitleCardSection>
              <DescWebinar
                dangerouslySetInnerHTML={{ __html: webinarData?.Webinar?.description }}
              />
            </CardSection>
            {/*<TitleWebinar>{webinarData?.Webinar?.name}</TitleWebinar>*/}
            {/*<DescContainer>*/}
            {/*  <DescWebinar*/}
            {/*    dangerouslySetInnerHTML={{ __html: webinarData?.Webinar?.description }}*/}
            {/*  />*/}
            {/*</DescContainer>*/}
            <CardSection>
              <TentangTrainer
                isFollowTraining={false}
                item={webinarData?.Webinar}
                withDescription
              />
            </CardSection>
            <CardSection style={{ paddingBottom: 8 }}>
              <TitleCardSection>{t('Apa yang akan kamu pelajari')}:</TitleCardSection>
              <CheckList icon={checklist} style={{ marginTop: 16 }}>
                {webinarData?.Webinar?.benefits?.map((benefit, index) => {
                  return (
                    <li key={index} className="overview-list">
                      {benefit.title}
                    </li>
                  );
                })}
              </CheckList>
            </CardSection>

            <BottomSection>
              <TitleWebinar style={{ flex: 1 }}>{webinarData?.Webinar?.name}</TitleWebinar>

              {webinarData?.Webinar?.is_free ? (
                <PriceText free>FREE</PriceText>
              ) : (
                <PriceText>
                  {!userWebinar?.UserWebinar
                    ? formatRp(webinarData?.Webinar?.price)
                    : t('Sudah Terdaftar')}
                </PriceText>
              )}
              <Button
                style={{ paddingLeft: 24, paddingRight: 24, boxSizing: 'border-box' }}
                disabled={loading}
                onClick={
                  !userWebinar?.UserWebinar
                    ? !authData.data
                      ? toLogin
                      : joinWebinar
                    : userWebinar?.UserWebinar?.webinar?.room_available
                    ? liveWebinar
                    : toLogin
                }
              >
                {!userWebinar?.UserWebinar
                  ? webinarData?.Webinar?.is_free
                    ? t('Daftar Sekarang')
                    : t('Beli Sekarang')
                  : userWebinar?.UserWebinar?.webinar?.room_available
                  ? t('Join Webinar Sekarang')
                  : t('Ingatkan Saya')}
              </Button>
              {/*{userWebinar?.UserWebinar && (*/}
              {/*  <TitleWebinar style={{ fontSize: 20 }}>Kamu Sudah Terdaftar</TitleWebinar>*/}
              {/*)}*/}
            </BottomSection>
          </MaxContainer>
        </DetailWebinarStyled>
        {profile && (
          <LengkapiProfilModal
            setOpenModalOTP={setOpenModalOTP}
            setOpenModal={setOpenModal}
            isOpen={openModal}
            profil={profile?.ProfileUser}
            setDataLengkapiProfile={setDataLengkapiProfile}
            onRequestClose={() => setOpenModal(false)}
          />
        )}
        {openModalOTP && (
          <OTPModal
            lengkapiProfile
            dark
            dataLengkapiProfile={dataLengkapiProfile}
            isOpen={openModalOTP}
            setOpenModalOTP={setOpenModalOTP}
            onRequestClose={() => setOpenModalOTP(false)}
            onSuccessOTP={() => {
              completeProfile({
                context: setContext(authData),
                variables: {
                  input: {
                    fullname: dataLengkapiProfile.fullname,
                    email: dataLengkapiProfile.email,
                    phoneNumber: dataLengkapiProfile.phoneNumber,
                  },
                },
              });
            }}
          />
        )}
        <PopupBerhasil
          title={`Selamat! kamu berhasil mendaftar di webinar "${webinarData?.Webinar?.name}"`}
          text={'Silakan mengikuti webinar sesuai jadwal yang sudah ditentukan.'}
          onRequestClose={() => setShowSuccessModal(!showSuccessModal)}
          isOpen={showSuccessModal}
          onRight={() => setShowSuccessModal(false)}
          textButtonRight={'OK'}
        />
      </MaxContainer>
    </PageLayout>
  );
};

export default DetailWebinar;
