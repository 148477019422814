import React, { useEffect } from 'react';
import { TestSectionContainer } from './styled';
import MaxContainer from '../../../atoms/MaxContainer';
import { ItemsDescTBM, TextDescTBM, TitleDescTBM } from '../DescSectionTBM/styled';
import CardTestTBM from '../../../molecule/TBMMolecules/CardTestTBM';
import { ReactComponent as MBTIImage } from 'assets/svg/TBM/mbti.svg';
import { ReactComponent as MBTI2Image } from 'assets/svg/TBM/mbti2.svg';
import { useSelector } from 'react-redux';
import { dataSubsNotAuth } from '../../../../utils/makeVarAuth';

const TestSection = () => {
  const authData = useSelector((state) => {
    return state.Auth;
  });

  useEffect(() => {
    if (!authData.data) {
      dataSubsNotAuth('aptitude-test');
    }
  }, []);

  return (
    <MaxContainer>
      <TestSectionContainer>
        <TitleDescTBM>Masih Bingung dengan Potensi Karir Terbaikmu?</TitleDescTBM>
        <TextDescTBM>
          Coba ikuti tes sekarang untuk mendapatkan 1 MODUL TRAINING GRATIS!
        </TextDescTBM>
        <ItemsDescTBM>
          <CardTestTBM
            title={'Tes Karaktersitik Pribadi'}
            image={<MBTIImage />}
            desc={
              'Tes ini menggunakan metode DISC untuk mengetahui kepribadian kamu secara lebih detail dan dipersonalisasi'
            }
            action={authData.data ? '/disc-test' : '/registrasi'}
          />
          <CardTestTBM
            title={'Tes Potensi Karir'}
            image={<MBTI2Image />}
            desc={
              'Tes ini menggunakan metode RIASEC & MBTI untuk menemukan Potensi Karier Terbaikmu dengan akurasi yang lebih tinggi dan dipersonalisasi'
            }
            action={authData.data ? '/tes-potensi-karir' : '/registrasi'}
          />
        </ItemsDescTBM>
      </TestSectionContainer>
    </MaxContainer>
  );
};

export default TestSection;
