import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_TESTIMONI } from 'queries/testimoni';
import { SwiperSlide } from 'swiper/react';
import loadable from '@loadable/component';
import { trackWindowScroll, LazyLoadComponent } from 'react-lazy-load-image-component';

import { LoaderDualRing } from 'components/atoms/Loading/';
// import CardTestimoni from 'components/molecule/MainTestimoni';
// import Slider from 'components/atoms/Slider';

import { Container, Title, CardContainer, ButtonSlider, ContainerSlide } from './styled';
import { SliderControlPrimary } from 'components/atoms/Slider/Slider.styled';
import { SliderPagination } from 'components/organisms/PopularTrainer/PopularTrainer.styled';

import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';
import MaxContainer from 'components/atoms/MaxContainer';
import { t } from 'hooks/intl/useLang';

const CardTestimoni = loadable(() => import('components/molecule/MainTestimoni'));
const Slider = loadable(() => import('components/atoms/Slider'));

const img1 = `https://public.terampil.com/assets/testimonies/citra_amallia.jpg`;
const img2 = `https://public.terampil.com/assets/testimonies/Fika dwi utami.png`;

const dataTes = [
  {
    nama: 'Citra Amallia',
    text: 'Training di Terampil seru banget, materinya dijelaskan secara komprehensif oleh para pakar yang berpengalaman di bidangnya, mudah dipahami karena video trainingnya interaktif dengan materi yang terstruktur dan dilengkapi Quiz di setiap BAB nya.',
    image: img1,
    profession: 'Partnership Manager',
  },
  {
    nama: 'Fika Dwi Utami',
    text: 'Sebagai Entrepreneur, aku butuh banget pelatihan yang bisa membantu aku untuk membuat Branding Produk, Membuat Konten Jualan dan juga untuk Meningkatkan Penjualan. Terimakasih Banyak untuk Terampil, dengan ikut Paket Gold seharga Rp. 495.000 aku bisa ikut banyak pelatihan yang mendukung untuk membangun bisnis yang sedang aku jalankan.',
    image: img2,
    profession: 'F&B Entrepreneur',
  },
];

function Testimoni({ scrollPosition }) {
  const { loading } = useQuery(FETCH_TESTIMONI, {
    variables: {
      input: {
        page: 0,
        limit: 5,
      },
    },
  });

  if (loading) return <LoaderDualRing />;

  return (
    <Container>
      <MaxContainer>
        <Title>{t('Apa Kata Mereka?')}</Title>
        <ContainerSlide>
          <ButtonSlider className="slider">
            <SliderControlPrimary className="prev-primary" id="prev-testimoni">
              <PrevIcon />
            </SliderControlPrimary>
            <SliderControlPrimary className="next-primary" id="next-testimoni">
              <NextIcon />
            </SliderControlPrimary>
          </ButtonSlider>

          <CardContainer>
            <Slider next="next-testimoni" prev="prev-testimoni" pagination="testimoni" count={1}>
              {dataTes.map((item, index) => (
                <SwiperSlide key={index}>
                  <LazyLoadComponent scrollPosition={scrollPosition}>
                    <CardTestimoni key={item.id} testimoni={item} />
                  </LazyLoadComponent>
                </SwiperSlide>
              ))}
            </Slider>
          </CardContainer>
        </ContainerSlide>
        <SliderPagination id="testimoni" />
      </MaxContainer>
    </Container>
  );
}

export default trackWindowScroll(Testimoni);
