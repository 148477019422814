import { gql } from '@apollo/client';

export const TRAINING_FIELDS = `
  id
  title
  thumbnail
  promo
  price
  training_price
  video_apple_url
  video_non_apple_url
  training_price
  training_prakerja
  prakerja_trainers {
    id
    avatar
    fullname
    rating
    slug
    total_training
    total_score
    profession
    about
  }
  trainer {
    id
    avatar
    fullname
    rating
    slug
    total_training
    total_score
    profession
    about
  }
  collaboration {
    name
  }
  cooperation {
    name
  }
  total_rating
  viewers
  isBookmark
  category {
    id
    name
  }
  rating
  durations
  slug
  created_at
  description
  benefits {
    title
  }
  professional_testimonies {
    avatar
    fullname
    profession
    testimony
  }
`;
export const GET_NEW_TRAINING = gql`
  query GET_NEW_TRAINING($orderBy: TrainingOrder, $orderType: OrderType) {
    Trainings(input: { orderBy: $orderBy, orderType: $orderType, limit: 6 }) {
      status
      statusText
      items {
        id
        title
        thumbnail
        video_apple_url
        video_non_apple_url
        slug
        promo
        price
        training_price
        training_prakerja
        total_rating
        trainer {
          id
          fullname
          slug
          rating
          profession
        }
      }
    }
  }
`;

export const GET_RECOMMEND_TRAINING = gql`
  query GET_RECOMMEND_TRAINING($input: TrainingRecommendationRequest) {
    TrainingRecommendation(input: $input) {
      status
      statusText
      items {
        id
        title
        thumbnail
        promo
        price
        training_price
        trainer {
          fullname
          rating
          slug
        }
        collaboration {
          name
        }
        cooperation {
          name
        }
        total_rating
        viewers
        isBookmark
        category {
          id
        }
        rating
        durations
        slug
        benefits {
          title
        }
      }
    }
  }
`;

export const GET_POPULAR_TRAINING = gql`
  query GET_POPULAR_TRAINING($id: Int, $limit: Int) {
    Trainings(
      input: { categoryId: $id, popular: true, orderBy: created_at, orderType: desc, limit: $limit }
    ) {
      status
      statusText
      items {
        id
        title
        thumbnail
        promo
        price
        training_price
        training_prakerja
        trainer {
          fullname
          rating
          slug
        }
        collaboration {
          name
        }
        cooperation {
          name
        }
        total_rating
        viewers
        isBookmark
        category {
          id
        }
        rating
        durations
        slug
        benefits {
          title
        }
      }
    }
  }
`;

export const GET_TRAININGS = gql`
  query GET_TRAININGS($input: TrainingsRequest) {
    Trainings(input: $input) {
      status
      statusText
      total
      filtered
      items {
        id
        title
        thumbnail
        training_prakerja
        trainer {
          fullname
          rating
          slug
        }
        collaboration {
          name
        }
        cooperation {
          name
        }
        total_rating
        viewers
        isBookmark
        category {
          id
        }
        rating
        durations
        slug
        benefits {
          title
        }
      }
    }
  }
`;

export const GET_BOOKMARK = gql`
  query getBookMark {
    Bookmarks(input: { limit: 12 }) {
      status
      statusText
      items {
        id
        training {
          id
          title
          durations
          thumbnail
          trainer {
            fullname
            rating
            slug
          }
          total_rating
          viewers
          isBookmark
          slug
          benefits {
            title
          }
        }
      }
    }
  }
`;

export const GET_KELAS_BERSAMA_TRAINER = gql`
  query KelasBersamaTrainer($trainerId: Int) {
    Trainings(input: { limit: 12, orderBy: created_at, orderType: desc, trainerId: $trainerId }) {
      status
      statusText
      items {
        id
        title
        thumbnail
        training_prakerja
        trainer {
          fullname
          rating
          slug
        }
        total_rating
        viewers
        durations
        isBookmark
        slug
        benefits {
          title
        }
      }
    }
  }
`;

export const GET_TRAININGS_AND_RECOMMENDATION = gql`
  query GET_TRAININGS_AND_RECOMMENDATION(
    $input: TrainingsRequest
    $inputRecommend: TrainingRecommendationRequest
  ) {
    Trainings(input: $input) {
      status
      statusText
      total
      filtered
      items {
        id
        title
        thumbnail
        training_prakerja
        trainer {
          fullname
          rating
          slug
        }
        collaboration {
          name
        }
        cooperation {
          name
        }
        total_rating
        viewers
        isBookmark
        category {
          id
        }
        rating
        durations
        promo
        price
        training_price
        slug
        benefits {
          title
        }
      }
    }
    TrainingRecommendation(input: $inputRecommend) {
      status
      statusText
      filtered
      items {
        id
        title
        promo
        price
        training_price
        thumbnail
        trainer {
          fullname
          rating
          slug
        }
        collaboration {
          name
        }
        cooperation {
          name
        }
        total_rating
        viewers
        isBookmark
        category {
          id
        }
        rating
        durations
        slug
        benefits {
          title
        }
      }
    }
  }
`;

export const GET_TRAININGS_SEARCHED = gql`
  query GET_TRAININGS_SEARCHED($input: TrainingsRequest) {
    Trainings(input: $input) {
      status
      statusText
      filtered
      items {
        id
        title
        promo
        price
        training_price
        thumbnail
        training_prakerja
        trainer {
          fullname
          rating
          slug
        }
        collaboration {
          name
        }
        cooperation {
          name
        }
        total_rating
        viewers
        isBookmark
        category {
          id
        }
        rating
        durations
        slug
        benefits {
          title
        }
      }
    }
  }
`;

// export const GET_TRAININGS_SEARCHED = gql`
//   query GET_TRAININGS_SEARCHED($input: TrainingsRequest) {
//     Trainings(input: $input) {
//       status
//       statusText
//       filtered
//       items {
//         id
//         title
//         thumbnail
//         trainer {
//           fullname
//           rating
//           slug
//         }
//         collaboration {
//           name
//         }
//         cooperation {
//           name
//         }
//         total_rating
//         viewers
//         isBookmark
//         category {
//           id
//         }
//         rating
//         durations
//         slug
//       }
//     }
//   }
// `;

export const GET_DETAIL_TRAINING = gql`
  query GET_DETAIL_TRAINING($input: String) {
    Training(slug: $input) {
      title
      description
      id
      total_rating
      rating
      durations
      slug
      created_at
      isBookmark
      updated_at
      promo
      price
      training_price
      thumbnail
      training_price
      training_prakerja
      tag
      attachment_link
      category {
        id
        name
      }
      benefits {
        title
      }
      video_apple_url
      video_non_apple_url
      professional_testimonies {
        fullname
        avatar
        profession
        testimony
        created_at
      }
      collaboration {
        name
        image
      }
      cooperation {
        name
        image
      }
      prakerja_trainers {
        fullname
        avatar
        slug
        id
        about
        views
        total_training
        total_score
        rating
        slug
        created_at
        updated_at
        categories {
          name
        }
      }
      trainer {
        fullname
        avatar
        slug
        id
        about
        views
        total_training
        total_score
        rating
        slug
        created_at
        updated_at
        categories {
          name
        }
        profession
      }
    }
  }
`;

export const GET_TRAINING_MODULE = gql`
  query GetTrainingModule($id: Int, $auth: Boolean!, $isPrakerja: Boolean) {
    TrainingChapters(trainingId: $id) {
      total
      filtered
      status
      statusText
      items {
        id
        training_id
        order
        title
        total_section
        total_duration
        is_active
        sections {
          id
          title
          training_chapter_id
          is_active
          order
          duration
          type
          video_apple_url
          video_non_apple_url
          thumbnail
          download_link
          schedule
          link_meet
          quiz {
            id
            questions
            answers
            accepted_answer
          }
        }
      }
    }
    TrainingUserDurations(trainingId: $id, isPrakerja: $isPrakerja) @include(if: $auth) {
      status
      items {
        training_id
        training_chapter_id
        training_section_id
        duration
        completed
      }
    }
  }
`;

export const GET_FILTERED_CATEGORY_TRAINING = gql`
  query GET_FILTERED_CATEGORY_TRAINING($input: TrainingsRequest) {
    Trainings(input: $input) {
      items {
        thumbnail
        viewers
        rating
        id
        total_rating
        durations
        title
        slug
        training_prakerja
        trainer {
          slug
          fullname
          rating
        }
        category {
          name
          slug
          image
          id
        }
        benefits {
          title
        }
      }
    }
  }
`;

// benefits {
//   title
// }

export const GET_TOTAL_COMENT = gql`
  query GET_TRAINING_COMMENTS($input: TrainingCommentRequest) {
    TrainingComments(input: $input) {
      total
    }
  }
`;

export const GET_TRAINING_COMMENTS = gql`
  query GET_TRAINING_COMMENTS($input: TrainingCommentRequest) {
    TrainingComments(input: $input) {
      total
      items {
        comment
        id
        isLiked
        like
        total_replies
        created_at
        replies {
          comment
          like
          isLiked
          id
          created_at
          user {
            fullname
            avatar
            id
          }
        }
        user {
          fullname
          avatar
          id
        }
      }
    }
  }
`;

export const GET_USER_TRAININGS = gql`
  query GET_USER_TRAININGS {
    UserTrainings(input: { limit: 100 }) {
      status
      items {
        id
        status
        training_progress
        last_training_chapter_id
        last_training_chapter
        last_training_section_id
        last_training_section
        last_training_section_duration
        training_section_id
        sections_freetrial
        training_type
        enable
        order
        training {
          id
          slug
          title
          description
          thumbnail
          video_apple_url
          video_non_apple_url
          training_price
          viewers
          isBookmark
          total_rating
          rating
          durations
          training_prakerja
          cooperation {
            name
            image
          }
          collaboration {
            name
            image
          }
          trainer {
            id
            user_id
            fullname
            profession
          }
        }
      }
    }
  }
`;

export const LIST_REVIEW_TRAINING = gql`
  query LIST_REVIEW_TRAINING($input: TrainingTestimonyRequest) {
    TrainingTestimonies(input: $input) {
      total
      items {
        text
        profile {
          fullname
          id
          avatar
          user_id
        }
        rating
        created_at
        id
      }
    }
  }
`;

export const GET_TRAINING_RATING = gql`
  query GET_TRAINING_RATING($input: Int) {
    TrainingRating(trainingId: $input) {
      rating1
      rating2
      rating3
      rating4
      rating5
      totalRating
      totalTestimony
      star
    }
  }
`;

export const GET_USER_TRAINING = gql`
  query GET_USER_TRAINING($id: Int) {
    UserTraining(id: $id) {
      status
      training_type
    }
  }
`;

export const GET_USER_TRAINING_DURATIONS = gql`
  query GET_USER_TRAINING_DURATIONS($trainingId: Int, $isPrakerja: Boolean) {
    TrainingUserDurations(trainingId: $trainingId, isPrakerja: $isPrakerja) {
      status
      statusText
      total
      items {
        duration
        training_id
        training_chapter_id
        training_section_id
        completed
      }
    }
  }
`;

export const GET_SUMMARY_USER_TRAINING = gql`
  query GET_SUMMARY_USER_TRAINING {
    SummaryUserTrainings {
      levelLearning
      summary
      levelReaction {
        minutes
        seconds
      }
    }
  }
`;

export const GET_TRAINER_TESTIMONY = gql`
  query GET_TRAINER_TESTIMONY($input: TrainerTestimonyRequest) {
    TrainerTestimony(input: $input) {
      profile {
        fullname
        user_id
      }
      text
      rating
      id
      created_at
    }
  }
`;

export const GET_TRAINING_TESTIMONY_BY_ID = gql`
  query GET_TESTIMONY_TRAINING_BY_ID($trainingId: Int) {
    TrainingTestimonyByUsers(trainingId: $trainingId) {
      item {
        text
        rating
        profile {
          fullname
        }
        id
      }
    }
  }
`;
export const GET_TRAINING_SUMMARY = gql`
  query GET_TRAINING_SUMMARY($trainingId: Int) {
    TrainingUserResult(input: { trainingId: $trainingId }) {
      levelLearning
      levelReaction {
        minutes
        seconds
      }
      summary
    }
  }
`;

export const GET_TRAINING_COMMENTS_USER = gql`
  query GET_TRAINING_COMMENTS_USER($input: TrainingCommentRequest) {
    TrainingComments(input: $input) {
      items {
        user {
          fullname
          user_id
        }
        replies {
          user {
            fullname
            id
          }
        }
      }
    }
  }
`;

export const GET_TRAINING_PURCHASED = gql`
  query TRAINING_PURCHASED {
    TrainingsUserPurchased {
      items {
        user_id
        training {
          id
          slug
          title
          description
          thumbnail
          video_apple_url
          video_non_apple_url
          training_price
          viewers
          isBookmark
          total_rating
          rating
          durations
          cooperation {
            name
            image
          }
          collaboration {
            name
            image
          }
          trainer {
            id
            user_id
            fullname
            profession
          }
        }
      }
    }
  }
`;

export const GET_TRAINING_POPULER_REAL = gql`
  query GET_TRAINING_POPULER {
    TrainingPopuler {
      filtered
      total
      items {
        id
        training {
          id
          title
          promo
          price
          training_price
          thumbnail
          trainer {
            fullname
            rating
            slug
          }
          collaboration {
            name
          }
          cooperation {
            name
          }
          total_rating
          viewers
          isBookmark
          category {
            id
          }
          rating
          durations
          slug
          benefits {
            title
          }
        }
      }
    }
  }
`;

export const TRAINING_PROGRES_DURATION = gql`
  query TRAINING_PROGRES_DURATION($summary: Boolean, $trainingId: Int) {
    TrainingUserDurationProgress(summary: $summary, trainingId: $trainingId) {
      item {
        progress
      }
    }
  }
`;

export const GET_POPULAR_TRAINING_PRAKERJA = gql`
  query GET_POPULAR_TRAINING($id: Int, $limit: Int, $trainingPrakerja: Boolean) {
    Trainings(
      input: {
        categoryId: $id
        orderBy: created_at
        orderType: desc
        limit: $limit
        trainingPrakerja: $trainingPrakerja
      }
    ) {
      status
      statusText
      items {
        id
        title
        thumbnail
        training_prakerja
        trainer {
          fullname
          rating
          slug
        }
        collaboration {
          name
        }
        cooperation {
          name
        }
        total_rating
        viewers
        isBookmark
        category {
          id
        }
        rating
        durations
        slug
        benefits {
          title
        }
      }
    }
  }
`;

export const LIST_TRAINING_BUNDLE = gql`
  query LIST_TRAINING_BUNDLE($bundleId: Int) {
    TrainingsBundle(bundleId: $bundleId) {
      items {
        id
        Training {
         ${TRAINING_FIELDS}
        }
      }
    }
  }
`;

export const USER_TRAININGS_FILTER = gql`
  query GET_USER_TRAININGS($input: UserTrainingsInput) {
    UserTrainings(input: $input) {
      status
      filtered
      items {
        status
        id
        status
        training_progress
        last_training_chapter_id
        last_training_chapter
        last_training_section_id
        last_training_section
        last_training_section_duration
        training_section_id
        sections_freetrial
        training_type
        enable
        order
        Training {
          id
          slug
          title
          description
          thumbnail
          video_apple_url
          video_non_apple_url
          training_price
          viewers
          isBookmark
          total_rating
          rating
          durations
          promo
          price
          training_price
          training_prakerja
          cooperation {
            name
            image
          }
          collaboration {
            name
            image
          }
          trainer {
            id
            user_id
            fullname
            profession
          }
        }
      }
    }
  }
`;
