import styled from 'styled-components';

export const DetailWebinarStyled = styled.div`
  margin-top: 80px;
  padding-bottom: 100px;
  position: relative;
`;

export const ImageBannerWebinar = styled.img`
  width: 100%;
  max-height: 450px;
  object-fit: cover;
`;

export const TitleWebinar = styled.h1`
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */

  text-align: left;
  margin: 0;
  padding: 0;
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: none;
  }
`;

export const PriceText = styled.p`
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  text-align: center;

  color: ${(props) => (props.free ? '#00debf' : '#F18F01')};
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    flex: 1;
  }
`;

export const DescWebinar = styled.div`
  font-size: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: #c4c4c4;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  strong {
    color: #fdfdfd;
  }

  h4 {
    text-align: center;
    width: 100%;
  }
`;

export const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

export const CardSection = styled.div`
  background: #202020;
  border-radius: 10px;
  padding: 24px;
  margin: 32px 150px 0;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 0 16px 24px;
  }
`;

export const TitleCardSection = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #fdfdfd;
  text-align: left;
`;

export const BottomSection = styled.div`
  position: sticky;
  bottom: 16px;
  margin: 50px 50px 0;
  background: #303030;
  border: 1px solid #626262;
  border-radius: 10px;
  padding: 25px 50px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  box-sizing: border-box;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 32px 16px 0;
    padding: 16px;
    gap: 24px;
  }
`;

export const MobileTitle = styled(TitleCardSection)`
  display: none;
  padding: 16px;
  margin-bottom: 0;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
  }
`;
