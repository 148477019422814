import React from 'react';
import { Contianer, CardBottom } from './styled';
import { t } from 'hooks/intl/useLang';
const BelumAdaTestimoni = ({ selected }) => {
  return (
    <Contianer>
      <CardBottom>
        <h4>{t('Belum Ada Ulasan')}</h4>
        <p>
          {t('Belum ada ulasan untuk training ini. Jadi orang pertama yang memberikan ulasan untuk training ini')}
        </p>
      </CardBottom>
    </Contianer>
  );
};

export default BelumAdaTestimoni;
