import styled from 'styled-components';
import Button from 'components/atoms/Button';

export const MetodePembayaranStyled = styled.div`
  width: 100%;
  background-color: #202020;
  border-radius: 5px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    background-color: transparent;
    padding-bottom: 74px;
  }
`;

export const ButtonPay = styled(Button)`
  width: 100%;
  margin: 0;
  margin-top: ${(props) => (props.point ? '32px' : '')};
  height: 50px;
`;

export const DetailMetodeBayar = styled.div`
  box-sizing: border-box;
  padding: 0 32px 32px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #000004;
    width: 100%;
    padding: 8px 16px 12px;
  }
`;

export const Separator = styled.hr`
  border: 1px solid #626262;
  margin: 24px 0 0;
  display: ${(props) => (props.mobile ? 'none' : 'block')};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: none;
  }
`;

export const SectionSeparator = styled.hr`
  border: 8px solid #202020;
  margin: 24px 0;
  display: none;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
  }
`;

export const TitleMobile = styled.h2`
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: left;

  color: ${(props) => props.theme.color.white};

  display: none;
  margin: 0;
  padding: 8px 16px 0;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
  }
`;
