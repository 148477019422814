import styled from 'styled-components';
import { mq } from 'assets/styles/theme';

const Search = styled.div`
  height: 29px;
  margin: 0 30px;
  padding: 0 10px 0 16px;
  border-radius: 10px;
  background-color: #929292;
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;

  input {
    ::placeholder {
      color: #ffffff;
    }
  }

  input:focus {
    outline: none;
  }

  display: flex;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;

  &.mobile {
    width: 100%;
    justify-content: space-between;

    & img {
      padding: 3px 10px 3px 2.5px !important;
    }
  }

  input[type='text'] {
    background-color: inherit;
    width: 80%;
    border: none;
    height: 90%;
    font-family: Inter;
    color: #ffffff;
  }

  img {
    cursor: pointer;

    width: 18px;
    height: 18px;
    margin: 0;
    padding: 3px 20.5px 3px 2.5px;
  }

  @media screen and (max-width: 2001px) {
    margin: 0 8px 0 10px;
    width: 20vw;
  }

  @media screen and ${(props) => props.theme.breakpoints.xl} {
    input[type='text'] {
      font-size: 13px;
    }
  }

  ${mq({
    margin: [null, null, '0 16px', '0 10px', '0 5px'],
  })}
`;

export default Search;
