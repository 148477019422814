import React from 'react';
import { ContainerOption } from './styled';
import { components } from 'react-select';

function OptionDropdownSort(props) {
  return (
    <ContainerOption>
      <components.Option {...props}>
        <div className="indicator" />
        {props.data.label}
      </components.Option>
    </ContainerOption>
  );
}

export default OptionDropdownSort;
