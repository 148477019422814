import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px 100px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 24px 0;
  }
`;

export const WrapperTitle = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 0 16px 24px;
  }
`;

export const Title = styled.p`
  padding: 0;
  margin: 0;

  font-family: Inter;
  font-weight: 600;
  font-size: 30px;
  color: #fdfdfd;
  border-bottom: 1px solid #c4c4c4;

  margin-bottom: 8px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
  }
`;

export const SubTitle = styled.p`
  padding: 0;
  margin: 0;

  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  font-style: ${(props) => (props.level ? 'normal' : 'italic')};
  margin-bottom: ${(props) => props.level && '32px'};
  color: #c4c4c4;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    margin: ${(props) => props.level && '0 16px'};
  }
`;

export const Training = styled.p`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 30px;

  margin-bottom: ${(props) => (props.level ? '12px' : '42px')};

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    margin: ${(props) => (props.level ? '0 16px 8px' : '0 16px 24px')};
  }
`;

export const Box = styled.div`
  margin: 0;

  border: 1px solid #626262;
  border-radius: 10px;
  padding: 32px;
  margin-bottom: 50px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    border: ${(props) => props.second && 'unset'};
    padding: ${(props) => (props.second ? '0' : '16px')};
    margin: ${(props) => (props.second ? '0' : '0 16px 24px')};
  }
`;

export const TableContent = styled.table`
  font-size: 20px;
  font-weight: normal;
  text-align: left;
  border-collapse: collapse;

  tbody::after {
    height: 16px;
    display: table-row;
    content: '';
  }

  tbody:last-child::after {
    height: unset;
    display: unset;
    content: unset;
  }

  .mid {
    padding: 0 20px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    .mid {
      padding: 0 10px;
    }
  }
`;
