import React from 'react';
import {
  Container,
  Title,
  Flex,
  Image,
  Subtitle,
  Paragraf,
  ContainerContain,
  Button,
} from './styled';

import { Link } from 'react-router-dom';
import { formatDateLong } from 'utils/date';

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import SocialMediaShare from 'components/atoms/SocialMediaShare';

const DetailsArtikel = ({ data = {} }) => {
  return (
    <Container>
      <Title>{data?.title} </Title>
      <Flex>
        <p>{formatDateLong(data?.publish_date || '2021-02-09T00:00:00.000Z')}</p>
        <div className="flex">
          <div className="svg">
            <FacebookShareButton
              size={30}
              url={`terampil.com/blog/${data?.slug}`}
              quote={data?.title}
            >
              <SocialMediaShare clicked type="Facebook" />
            </FacebookShareButton>
          </div>
          <div className="svg">
            <TwitterShareButton
              size={30}
              url={`terampil.com/blog/${data?.slug}`}
              title={data?.title}
            >
              <SocialMediaShare clicked type="Twitter" />
            </TwitterShareButton>
          </div>
          <div className="svg">
            <EmailShareButton
              size={30}
              url={`terampil.com/blog/${data?.slug}`}
              subject={'Blog Terampil'}
              body={`${data?.title}\n`}
            >
              <SocialMediaShare clicked type="Email" />
            </EmailShareButton>
          </div>
          <div className="svg">
            <WhatsappShareButton
              url={`terampil.com/blog/${data?.slug}`}
              title={data?.title}
              size={30}
            >
              <SocialMediaShare clicked type="WhatsApp" />
            </WhatsappShareButton>
          </div>
        </div>
      </Flex>
      <div>
        <Image image={data?.image_headline} />
      </div>
      <ContainerContain>
        <Subtitle>{data?.title}</Subtitle>
        <Paragraf dangerouslySetInnerHTML={{ __html: data?.body }} />
      </ContainerContain>
      <ContainerContain></ContainerContain>
      <a href={data?.link_button} target={'blank'}>
        <Button>{data?.text_button}</Button>
      </a>
    </Container>
  );
};

export default DetailsArtikel;
