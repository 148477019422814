import styled from 'styled-components';

export const Container = styled.figure`
  position: relative;
  padding: 24px 32px;

  margin: 50px 0px 20px;
  background: ${({ trainerPage }) => (trainerPage ? '#202020' : '#373737')};

  font-family: Inter, sans-serif;

  border-radius: 10px;

  .rating-trainer {
    margin-top: 20px;

    font-size: 16px;
    font-weight: 400;

    color: #c4c4c4;

    & > span {
      margin: 0 16px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 12px 16px 16px 16px;
    margin: 0;

    .rating-trainer {
      margin-top: 4px;

      font-size: 12px;
    }
  }
`;

export const Head = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  margin: 8px 0 38px 0;

  font-size: 20px;
  text-align: left;

  .header-text {
    margin-left: 182px;
  }

  h4 {
    margin: 8px 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    margin: 0;
    margin-bottom: 12px;
    font-size: 16px;

    h4 {
      margin: 0;
    }

    .header-text {
      margin-left: 12px;
    }
  }
`;

export const Img = styled.div`
  position: absolute;
  bottom: -20px;
  width: 130px;
  height: 130px;

  border: 5px solid ${({ trainerPage }) => (trainerPage ? '#202020' : '#373737')};
  border-radius: 100%;

  background: ${({ trainerPage }) => (trainerPage ? '#202020' : '#373737')};

  background-image: url('${(props) => props.src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    position: static;

    width: 80px;
    height: 80px;
    border: none;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #c4c4c4;

  text-align: left;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 14px;
  }
`;
