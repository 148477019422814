import React from 'react';
import { Container, Title } from './styled';
import { data, dataLogin } from './static';
import Accordion from 'components/atoms/Accordion';
import { t } from 'hooks/intl/useLang';

const FAQUndangTeman = ({ login }) => {
  return (
    <Container>
      <Title>{t('Frequently Asked Questions')}</Title>
      {!login
        ? data.map((item, i) => {
            return <Accordion pembayaran={true} undangTeman data={item} />;
          })
        : dataLogin.map((item, i) => {
            return <Accordion pembayaran={true} undangTeman data={item} />;
          })}
    </Container>
  );
};

export default FAQUndangTeman;
