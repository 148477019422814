import styled from 'styled-components';

export const LandingContainer = styled.div`
  background: #fff;
  margin-top: 77px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 60px;
  }
`;
