import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  padding: 100px 100px;
  width: 100%;
  box-sizing: border-box;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 40px 60px 50px;
  }
  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 40px 50px 50px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 40px 50px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 40px 0px 30px;
  }

  .image-icon {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 43;
    margin-bottom: 0px;

    /* img {
      width: 15%;
    } */

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      width: 90%;
      display: grid;
      justify-items: center;
      grid-template-columns: auto auto auto auto;
      gap: 20px;
      margin: 0 auto;
      padding-top: 20px;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      width: 90%;
      display: grid;
      justify-items: center;
      grid-template-columns: auto auto auto auto;
      gap: 10px;
      margin: 0 auto;
    }
  }
`;

export const Text = styled.h2`
  height: 26px;
  margin: 0;
  margin: 0px auto 60px;
  font-family: Archivo;
  font-size: 24px;
  font-weight: 500;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 18px;
    padding: 0 30px;
    margin: 0px auto 20px;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16px;
    margin: 20px 0;
    line-height: 17px;
  }

  span {
    color: #0cc375;
  }
`;

export const Image = styled.img`
  align-self: center;
  height: 60px;
  width: 20%;
  object-fit: contain;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 90%;
    max-width: 100%;
  }
`;
