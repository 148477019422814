import React from 'react';
import { BookmarkContainer } from './styled';
import TitleSection from 'components/molecule/TitleSectionHome';
import MaxContainer from 'components/atoms/MaxContainer';
import SliderTraining from 'components/molecule/SliderTraining';
import { GET_BOOKMARK } from 'queries/training';
import { useQuery } from '@apollo/client';
import { LoaderDualRing } from '../../atoms/Loading';

import { useSelector } from 'react-redux';
import { setContext } from 'utils';

function BookmarkTraining() {
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const { data: bookmarks, loading } = useQuery(GET_BOOKMARK, {
    context: setContext(authData),
    onError(errors) {
      console.error(errors.message);
    },
  });

  return (
    <BookmarkContainer data={bookmarks?.Bookmarks?.items.length > 0}>
      <MaxContainer>
        <TitleSection destination="/pelatihan-saya/bookmarks">Bookmark</TitleSection>
        {loading && <LoaderDualRing />}
        {bookmarks?.Bookmarks && (
          <SliderTraining
            trainings={bookmarks?.Bookmarks?.items?.map((bookmark) => {
              return bookmark.training;
            })}
            id={'bookmark'}
          />
        )}
      </MaxContainer>
    </BookmarkContainer>
  );
}

export default BookmarkTraining;
