import React from 'react';

import { Container, Icon, Detail, Button } from './styled';

import BillIcon from 'assets/png/invoice/bill.png';
import PaidIcon from 'assets/png/invoice/paid.png';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';
import { checkMobile } from 'utils/checkMobile';
import { Link, useHistory } from 'react-router-dom';
import { formatLocaleDateShort, momentDateShort } from 'utils/date';
import { t } from 'hooks/intl/useLang';

const PembayaranCard = ({ data, invoice }) => {
  const history = useHistory();

  const paymentVA = ['bca', 'bni', 'mandiri', 'permata'];
  const paymentMobile = ['gopay', 'shopeepay', 'qris', 'dana', 'dana_recurring'];
  const paymentCC = [
    'credit_card',
    'VISA',
    'AMEX',
    'MASTERCARD',
    'DISCOVER',
    'DINERS',
    'JCB',
    'CARD',
  ];

  const moveToCaraBayar = () => {
    if (paymentVA.includes(data.payment_method)) {
      return `/rincian-transfer/${invoice}`;
    } else if (paymentMobile.includes(data.payment_method)) {
      return `/rincian-online-payment/${invoice}`;
    } else if (paymentCC.includes(data.payment_method)) {
      return `/rincian-online-payment/${invoice}`;
    }
  };

  const renderButton = (type) => {
    switch (type) {
      case 'Pending':
        return paymentCC.includes(data.payment_method) ? (
          <Link to={moveToCaraBayar}>
            <Button className="secondary">Detail</Button>
          </Link>
        ) : (
          <Link to={moveToCaraBayar}>
            <Button className="primary">Cara Bayar</Button>
          </Link>
        );
      case 'Paid':
        return checkMobile() ? (
          <div className="x-5">
            <NextIcon />
          </div>
        ) : (
          <Link to={`/detail-riwayat-pembayaran/${invoice}`}>
            <Button className="secondary">Detail</Button>
          </Link>
        );
      case 'Failed':
        return checkMobile() ? (
          <div className="x-5">
            <NextIcon />
          </div>
        ) : (
          <Link to={`/detail-riwayat-pembayaran/${invoice}`}>
            <Button className="secondary">Detail</Button>
          </Link>
        );
      default:
        return null;
    }
  };

  const renderIcon = (type) => {
    switch (type) {
      case 'Pending':
        return <img src={BillIcon} alt="bill-icon" />;
      case 'Paid':
        return <img src={PaidIcon} alt="paid-icon" />;
      default:
        return <img src={BillIcon} alt="bill-icon" />;
    }
  };

  let status = {};
  switch (data?.status) {
    case 'Failed':
      if (checkMobile()) {
        status = {
          message: t('Gagal Bayar'),
          info_payment: t('Tanggal pembayaran'),
          date: '-',
        };
      } else {
        status = {
          message: t('Gagal Bayar'),
          info_payment: t('Tagihan sudah lewat batas pembayaran'),
          date: momentDateShort(data?.due_date),
        };
      }
      break;
    case 'Pending':
      if (checkMobile()) {
        status = {
          message: t('Belum bayar'),
          info_payment: t('Bayar sebelum tanggal'),
          date: momentDateShort(data?.due_date),
        };
      } else {
        status = {
          message: t('Belum bayar'),
          info_payment: t('Bayar sebelum tanggal'),
          date: momentDateShort(data?.due_date),
        };
      }
      break;
    case 'Paid':
      status = {
        message: t('Berhasil'),
        info_payment: t('Transaksi pada Tanggal'),
        date: momentDateShort(data?.payment_date),
      };
      break;
    default:
      break;
  }

  const onClickMobile = () => {
    if (checkMobile()) {
      if (data?.status === 'Paid') {
        history.push(`/detail-riwayat-pembayaran/${invoice}`);
      }
    }
  };

  return (
    <Container onClick={onClickMobile}>
      <div>
        <Icon>{renderIcon(data?.status)}</Icon>
        <Detail>
          <div className="mb-3">
            <h6>{data?.order_name}</h6>
            {data.payment_method !== 'prakerja' ? (
              <p
                className={`${
                  status.message === t('Berhasil')
                    ? 'color-berhasil'
                    : status.message === t('Gagal Bayar')
                    ? 'color-failed'
                    : ''
                }`}
              >
                {status.message}
              </p>
            ) : (
              <p>Paket Prakerja</p>
            )}
          </div>
          <div>
            <p>
              {status.info_payment} <strong>{status.date}</strong>
            </p>
            {data.payment_method !== 'prakerja' && (
              <p className="price">
                <strong>Rp {data.price.toLocaleString('id')}</strong>
              </p>
            )}
          </div>
        </Detail>
      </div>
      <div>{renderButton(data?.status)}</div>
    </Container>
  );
};

export default PembayaranCard;
