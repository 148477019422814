import { gql } from '@apollo/client';

export const GET_COOPERATION = gql`
query getAllCooperation( $orderBy: CooperationOrder, $is_active: Boolean) {
  Cooperations(input: { limit: 6, orderBy: $orderBy, is_active: $is_active }) {
    status
    statusText
    items {
      id
      name
      is_active
      image
    }
  }
}
`;

