import styled from 'styled-components';

export const Section = styled.section`
  width: 60%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px 100px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 100%;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    padding: 24px;
    justify-content: unset;
  }

  div.logotablet {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto 50px;

    img {
      height: auto;
      margin-bottom: 100px;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin: 0 auto;
    }

    img {
      width: 50%;
      height: auto;
    }
  }
`;

export const Head = styled.div`
  h6 {
    margin: 0;
    margin-bottom: 16px;

    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;

    color: #141414;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;

    color: #626262;

    margin-bottom: 42px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    p {
      margin-bottom: 0;
    }
  }
`;

export const BoxForm = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: solid 1px #c4c4c4;
  width: 60%;

  padding: 24px 75px 50px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 70%;
    justify-content: flex-start;
    padding: 24px 42px;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    border: none;
    width: 90%;
    padding: 20px 50px;
    justify-content: flex-start;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 20px 10px;
  }
`;
