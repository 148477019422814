import React from 'react';
import {
  ContainerTitle,
  Title,
  PointTitle,
  Icon,
  ContainerPoint,
  TitlePrice,
  Button,
  ContainerLogo,
} from './UniqueTerampil.styled';

import IconDownload from 'assets/png/uniqueValue/Download.png';
import IconKelasOnline from 'assets/png/uniqueValue/Kelas Online.png';
import IconPengajar from 'assets/png/uniqueValue/Pengajar Profesional.png';
import IconSertifikat from 'assets/png/uniqueValue/Sertifikat.png';
import IconDiskusi from 'assets/svg/diskusi.svg';
import IconKelasBaru from 'assets/svg/kelasbaru.svg';
import { Link } from 'react-router-dom';
import AppStoreLogo from 'assets/png/PelatihanSaya/App Store.png';
import PlayStoreLogo from 'assets/png/PelatihanSaya/PlayStore.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { t } from 'hooks/intl/useLang';

const PointUniqeTerampil = [
  { src: IconKelasOnline, message: t('1200+ Video Training') },
  { src: IconPengajar, message: t('100+ Trainer Profesional') },
  // { src: IconKelasBaru, message: '4+ Training baru setiap minggu' },
  {
    src: IconSertifikat,
    message: t('Bersertifikat Terampil'),
  },
  { src: IconDownload, message: t('Download dan tampilkan secara offline') },
  { src: IconDiskusi, message: t('Live Q&A session dengan Trainer') },
];
function UniqueTitle({ scrollPosition }) {
  return (
    <ContainerTitle>
      <Title>{t('Nikmati berbagai keuntungan belajar di Terampil')}</Title>
      {PointUniqeTerampil.map((item, i) => {
        return (
          <ContainerPoint key={i}>
            <Icon src={item.src} alt="logo" />
            <PointTitle>
              {item.message} {i === 2 && <>&reg;</>}
            </PointTitle>
          </ContainerPoint>
        );
      })}
      <div className="flex">
        <ContainerLogo>
          <a href="https://apps.apple.com/id/app/terampil/id1559671480" target="blank">
            <LazyLoadImage
              effect="blur"
              className="app-store"
              scrollPosition={scrollPosition}
              src={AppStoreLogo}
              alt="apple-store"
            />
          </a>
        </ContainerLogo>
        <ContainerLogo>
          <a target="blank" href="https://play.google.com/store/apps/details?id=com.terampil.app">
            <LazyLoadImage
              effect="blur"
              className="playstore"
              scrollPosition={scrollPosition}
              src={PlayStoreLogo}
              alt="google-playstore"
            />
          </a>
        </ContainerLogo>
      </div>
      <div className="button-container">
        <Link to="/registrasi" className="link">
          <Button large>{t('Coba Gratis 3 Video Sekarang')}</Button>
        </Link>
        {/* <div className="price-tag">
          <TitlePrice>50.000/bulan</TitlePrice>
          <PointTitle font="smaller">Billed annualy</PointTitle>
        </div> */}
      </div>
    </ContainerTitle>
  );
}

export default UniqueTitle;
