import React from 'react';
import { Container, Line, Font, ContainerSection } from './Footer.styled';
import { About, MobileLink, PerusahaanLink, SocialMedia } from '../../molecule/MainFooter/';
import { useSelector } from 'react-redux';
import { getValue } from 'utils';
import MaxContainer from 'components/atoms/MaxContainer';

function Footer({ bgFooter, ...rest }) {
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const token = getValue(authData, 'data.accessToken', '');

  return (
    <ContainerSection isAuth={token} {...rest} bgColor={bgFooter}>
      <MaxContainer>
        <Container isAuth={token} {...rest} bgColor={bgFooter}>
          <About />
          <PerusahaanLink isAuth={token} />
          <SocialMedia />
          <MobileLink />
        </Container>
      </MaxContainer>
      <Line bgColor={bgFooter} />
      <Font bgColor={bgFooter}>&copy; 2021, PT Terampil Online Pratama</Font>
    </ContainerSection>
  );
}

export default Footer;
