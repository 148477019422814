import styled from 'styled-components';

export const Section = styled.section`
  width: 60%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 130px;
  position: relative;
  overflow: auto;

  a {
    text-decoration: none;
  }

  .logo {
    max-width: 50%;
    margin-top: -20px;
  }
  svg.back-button {
    position: absolute;
    width: 16px;
    height: 14px;
    left: 30px;
    top: 40px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 100%;
    padding: 40px 100px;

    svg.back-button {
      flex-direction: column;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    padding: 16px 0px;

    svg.back-button {
      left: 30px;
      top: 28px;
      width: 15px;
      height: 15px;
      flex-direction: column;
    }
  }
`;

export const BoxRegister = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: solid 1px #c4c4c4;
  padding: 20px 75px 32px;
  position: relative;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    border: none;
    justify-content: space-between;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    height: 100%;
    padding: 20px 0px;
    border: none;
    justify-content: flex-start;
  }
`;

export const Title = styled.h2`
  font-family: Archivo;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #101010;
  font-stretch: normal;
  margin: 20px 0 20px 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 20px;
    margin: 15px 0 10px 0;
  }
`;

export const TermCondition = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #626262;
  padding: 10px 0 0 0;
  margin-top: 20px;

  span {
    font-weight: bold;
    color: #f18f01;
    cursor: pointer;

    &:hover {
      color: #626262;
      font-weight: normal;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    padding: 10px 20px;
    margin-top: ${({ login }) => (login === 'link' ? 170 : 27)}px;
  }
`;

export const CheckLogin = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #626262;
  padding: 0;
  margin-top: 30px;
  padding-top: 12px;

  span {
    font-weight: bold;
    color: #f18f01;
    cursor: pointer;

    &:hover {
      color: #626262;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    border-top: 1px solid #e5e5e5;
    width: 100%;
    padding: 24px 0px;
    margin-top: 20px;
  }
`;
