import { gql } from '@apollo/client';

export const DO_SIGNOUT = gql`
  mutation DoSignout {
    DoSignout {
      status
      statusText
    }
  }
`;
