import styled from 'styled-components';
import InputField from 'components/atoms/InputField';
import { ReactComponent as CheckIcon } from 'assets/svg/valid-voucher.svg';
import { LoaderDualRing } from 'components/atoms/Loading';

export const RingkasanPembayaranStyled = styled.div`
  width: 100%;

  .price-content {
    align-items: flex-start;
  }

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0 16px;
    box-sizing: border-box;
  }
`;

export const TitlePembayaran = styled.h1`
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  padding: 32px 0;
  margin: 0;

  color: #fdfdfd;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 18px;
    padding: 12px 0;
  }
`;

export const PaketDetail = styled.div`
  width: 100%;
  background-color: #373737;
  border-radius: 5px;
  padding: 32px 32px 16px;
  box-sizing: border-box;
  margin-bottom: 24px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    background-color: transparent;
    padding: 16px 16px 0;
    border: 1px solid #626262;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const HeaderPaketDetail = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 32px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    margin-bottom: 16px;
    justify-content: space-between;
  }
`;

export const ChangePlan = styled.p`
  font-family: Archivo, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin: 4px 24px 0;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.color.white};
  }

  color: ${(props) => props.theme.color.primary};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    margin-right: 0;
    font-size: 12px;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  &.input-voucher {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  &.point {
    margin-bottom: 8px;
  }

  .price-message {
    justify-self: flex-end;
  }
`;

export const PriceLabel = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: right;
  margin: 0;

  color: #c4c4c4;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
  }
`;
export const Message = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  color: #c4c4c4;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    margin-right: 4px;
  }
`;

export const PointLabel = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  margin: 0 0 0 16px;

  color: #707070;

  &.point {
    color: #fdfdfd;
    font-weight: bold;
  }

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    margin: 0 0 0 4px;
  }
`;

export const TitlePaket = styled.h2`
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  margin: 0;

  color: #fdfdfd;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    font-weight: normal;
  }
`;

export const FieldVoucher = styled(InputField)`
  width: 65%;
  color: ${(props) => props.theme.color.primary};
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  display: flex;
  flex-grow: 1;
  margin-right: 16px;
  align-items: center;
  border: 0;

  ::placeholder {
    color: ${(props) => props.theme.color.primary};
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 48px;

    @media only screen and ${(props) => props.theme.breakpoints.xs} {
      font-size: 14px;
    }
  }

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    border-radius: 50px;
  }
`;

export const Separator = styled.hr`
  border: 1px solid #626262;
  margin: 36px 0 24px;
  display: ${(props) => (props.mobile ? 'none' : 'block')};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    width: 109%;
    display: ${(props) => (props.mobile ? 'block' : 'none')};
    margin: 16px -16px;
    border: 0.5px solid #626262;
  }

  @media only screen and (max-width: 375px) {
    width: 110.1%;
  }

  @media only screen and ${(props) => props.theme.breakpoints.xxs} {
    width: 112%;
  }
`;

export const TotalText = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin: 0;

  text-align: right;

  color: #f18f01;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    color: ${(props) => props.theme.color.white};
    font-size: 20px;
  }
`;

export const VoucherContainer = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border: 1px dashed #c4c4c4;
  border-radius: 4px;
  padding: 0 16px 0 0;

  & > div {
    cursor: pointer;
  }

  &.error {
    border: 1px dashed #f18f01;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    border: 1px solid #c4c4c4;
    border-radius: 50px;
    margin-bottom: 0;
    &.error {
      border: 1px solid #f18f01;
    }
    & > div {
      margin: 0;
    }
  }
`;

export const ValidIcon = styled(CheckIcon)`
  position: absolute;
  right: 32px;
`;

export const PointContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 16px;
`;

export const Loader = styled(LoaderDualRing)`
  width: 10px;
  height: 10px;
  margin: 0;

  ::after {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0;
  }
`;
