import React from 'react';
import { Container, CardContiner, AvatarImage } from './styled';
import { t } from 'hooks/intl/useLang';
const TestimoniDeskripsi = ({ item }) => {
  return (
    <Container>
      {item?.professional_testimonies && item?.professional_testimonies?.length > 0 && (
        <>
          <h2>{t('Testimoni dari Profesional')}</h2>
          {item?.professional_testimonies.map((data, i) => {
            return (
              <CardContiner key={i}>
                <AvatarImage image={data?.avatar} />
                <div className="container">
                  <h2>{data?.fullname}</h2>
                  <h3>{data?.profession}</h3>
                  <div className="coment">{data?.testimony}</div>
                </div>
                <div className="none">{data?.testimony}</div>
              </CardContiner>
            );
          })}
        </>
      )}
    </Container>
  );
};

export default TestimoniDeskripsi;
