import styled from 'styled-components';

export const Headers = styled.header`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 77px;
  padding: 15px 120px 14px 120px;
  background-color: #000004;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  -webkit-backface-visibility: hidden;
  z-index: 10;
  a {
    text-decoration: none;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 15px 16px 14px 16px;
  }
`;

export const BlogTitle = styled.h3`
  font-family: Inter;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  margin: 0;
  cursor: pointer;
  color: #c4c4c4;
`;

export const Image = styled.img`
  width: 139px;
  height: 54px;
  margin: 11px 14px 0px 0;
  object-fit: contain;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 127px;
    height: 50px;
  }
`;

export const Container = styled.div`
  max-width: 1920px;
  height: inherit;
  margin: 0 auto;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  .category-box {
    display: flex;
    gap: 80px;
    justify-content: space-between;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const CategorysName = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  cursor: pointer;
  color: #fdfdfd;
`;
