import React from 'react';
import { Link } from 'react-router-dom';
import { GET_USER_TRAINING_DURATIONS } from 'queries/training';
import { GET_TRAINING_SUMMARY } from 'queries/training';
import { useQuery } from '@apollo/client';
import { setContext } from 'utils';
import { useSelector } from 'react-redux';
import { convertDurationSecondProgress } from 'utils/duration';

import { Contianer, CardContainer, CardUpside, CardBottom, Button, Flex } from './styled';

import TimerIcon from 'assets/svg/trainingDetails/hasilTime.svg';
import GrafikIcon from 'assets/svg/trainingDetails/hasilGrafik.svg';
import { ReactComponent as Arrow } from 'assets/svg/trainingDetails/arrow.svg';
import { t } from 'hooks/intl/useLang';

const BelumBerlanggananTraining = ({ trainingId }) => {
  const authData = useSelector((state) => state.Auth);

  const { data: durations } = useQuery(GET_USER_TRAINING_DURATIONS, {
    context: setContext(authData),
    variables: {
      trainingId: trainingId,
    },
  });

  const { data: postTest } = useQuery(GET_TRAINING_SUMMARY, {
    context: setContext(authData),
    variables: {
      trainingId: trainingId,
    },
  });

  const totalDuration = (data) => {
    if (!data) return '0 m 0 s';

    const sum = data.reduce((x, y) => x + y.duration, 0);

    return convertDurationSecondProgress(sum);
  };

  return (
    <Contianer>
      <CardContainer>
        <CardUpside>
          <h3>{t('Level Learning')}</h3>
          <div>
            <p>{t('Pengetahuan diperoleh sejak Anda pertama kali mengikuti pelatihan')}</p>
            <Flex>
              <Arrow />
              <h2>{postTest?.TrainingUserResult?.levelLearning} %</h2>
            </Flex>
          </div>
          <img src={GrafikIcon} alt="" />
        </CardUpside>
        <CardUpside>
          <h3>{t('Level Reaction')}</h3>
          <div>
            <p>{('Total waktu yang Anda habiskan dalam pelatihan ini')}</p>
            <h2>{totalDuration(durations?.TrainingUserDurations?.items)}</h2>
          </div>
          <img src={TimerIcon} alt="" />
        </CardUpside>
      </CardContainer>
      <CardBottom>
        <h2>
          {t('Untuk mengunduh sertifikat silahkan tekan tombol')} <span>{t('Beli Paket')}</span> {t('dibawah')}.
        </h2>
        <Link to={`/berlangganan`}>
          <Button rounded>{t('Beli Paket')}</Button>
        </Link>
        <p>
          {t('Sertifikat bisa diunduh jika 70% jawaban kamu dari seluruh pertanyaan benar, jika belum benar kamu bisa ikuti lagi kusinya.')}
        </p>
      </CardBottom>
    </Contianer>
  );
};

export default BelumBerlanggananTraining;
