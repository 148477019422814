import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px 0;
  background-color: #141414;
  display: grid;
  place-items: center;

  div.container-title {
    width: 50%;
    box-sizing: border-box;
  }

  h2 {
    font-family: Archivo;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: #fdfdfd;
    text-align: left;
    margin: 0 0 20px 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    div.container-title {
      width: 70%;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    div.container-title {
      width: 100%;
      background-color: #141414;
      padding: 32px 16px 0;
      margin-top: 8px;
    }
    h2 {
      margin: 0 0 16px 0;
    }
    background-color: #202020;
    padding: 0;
    margin-top: 8px;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  place-items: center;
  width: 50%;
  box-sizing: border-box;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 70%;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 100%;
    background-color: #141414;
    padding: 0 16px;
  }
`;
