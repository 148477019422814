/* eslint-disable no-unused-vars */
import React from 'react';
import { Container, ContainerContain, Button, Image, LabelPrakerja } from './styled';
import { ReactComponent as Cirle } from 'assets/svg/PelatihanSaya/circle.svg';
import { formatLocaleDateShort } from 'utils/date';
import { getValue, setContext } from 'utils';
import { t } from 'hooks/intl/useLang';
// import { useMutation } from '@apollo/client';
// import { CREATE_CERTIFICATE } from 'mutation/certificate';

const CardDownloadSertifkat = ({ item, auth }) => {
  // const [createCertificate] = useMutation(CREATE_CERTIFICATE, {
  //   context: setContext(auth),
  // });

  // const onClickDownload = () => {
  //   if (!item.file.link) {
  //     createCertificate({
  //       variables: {
  //         input: {
  //           trainingTitle: item?.training?.title,
  //           trainingCollaboration: item?.training?.collaboration?.image || '',
  {
    /*          trainingCooporation: item?.training?.cooperation?.image || '',*/
  }
  {
    /*          trainerFullname: item?.training?.trainer?.fullname,*/
  }
  {
    /*          trainerProfession: item?.training?.trainer?.profession,*/
  }
  //           trainingId: item?.training?.id,
  //         },
  //       },
  //     });
  //   }
  //   if (item.file.link && item.score >= 60) {
  //     window.open(item.file.link);
  //   }
  // };

  return (
    <Container>
      {item?.is_prakerja && <LabelPrakerja>PRAKERJA</LabelPrakerja>}
      <Image src={item.training.thumbnail} alt="thumbnail" />
      <ContainerContain>
        <h2>{item.serial_number}</h2>
        <div className="nilai-container">
          <p>{t('Nilai')} : </p>
          <p className="nilai">{item.score}</p>
          <Cirle />
          <p>{t('Tanggal Dikeluarkan')}: {formatLocaleDateShort(item.created_at)}</p>
        </div>
        <h3>{item.training.title}</h3>
        <h4>{item.training.trainer.fullname}</h4>
        {getValue(auth, 'data.accessToken') && (
          <a href={item.file.link} target="_blank" rel="noreferrer" id="download-certificate">
            <Button rounded>{t('Unduh Sertifikat')}</Button>
          </a>
        )}
      </ContainerContain>
    </Container>
  );
};

export default CardDownloadSertifkat;
