import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Title, ProfileName, Image, PromoTraining } from './styled';
import IconPlay from 'assets/png/icon-play-white.png';
import VidioModal from 'components/molecule/Modal/VidioTrainingModal';
import star from 'assets/svg/star.svg';
import { t } from 'hooks/intl/useLang';

function CardNewTraining(props) {
  const [showVidioModal, setShowVidioModal] = useState(false);

  const showModalVidio = () => {
    setShowVidioModal(!showVidioModal);
  };
  const { newTraining } = props;
  console.log(newTraining, 'NEWWW');
  return (
    <Container>
      <VidioModal
        data={newTraining}
        isOpen={showVidioModal}
        setShowModal={setShowVidioModal}
        onRequestClose={() => setShowVidioModal(false)}
      />

      <div>
        <Image src={newTraining.thumbnail} onClick={showModalVidio}>
          <img src={IconPlay} alt="icon-play" />
        </Image>
      </div>
      <Link className="link-training" to={`/training/${newTraining.slug}/deskripsi`}>
        <Title>{newTraining.title}</Title>
      </Link>
      <Link className="link-training" to={`/trainer/${newTraining?.trainer?.slug}/deskripsi`}>
        <ProfileName>{newTraining?.trainer?.fullname}</ProfileName>
      </Link>
      {newTraining?.promo && (
        <PromoTraining>
          <p className="price-discount">Rp {newTraining?.training_price.toLocaleString('id')}</p>
          <p className="price-normal">Rp {newTraining?.price.toLocaleString('id')}</p>
          <div className="percent-box">{newTraining?.promo}off</div>
        </PromoTraining>
      )}
      <div style={{ marginTop: '23px' }}>
        <span className="penilaian-trainer"> {newTraining?.trainer?.profession}</span>
      </div>
      <div className="row">
        <img className="tinyIcon" src={star} alt={'Star Icon'} />
        <span>
          {newTraining?.trainer?.rating ? newTraining?.trainer?.rating?.toFixed(1) : 0} (
          {newTraining?.total_rating} {t('penilaian')})
        </span>
      </div>
    </Container>
  );
}

export default CardNewTraining;
