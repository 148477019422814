import React from 'react';
import { Container } from './styled';

const ThumbnailCard = ({ src, trainer = false }) => {
  return (
    <Container src={src} trainer={trainer}>
      <div className={`main-thumb ${trainer ? 'bg-main-trainer' : ''}`}></div>
      <div className={`bg-thumb ${trainer ? 'bg-trainer' : ''}`}></div>
    </Container>
  );
};

export default ThumbnailCard;
