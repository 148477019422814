import styled from 'styled-components';

export const SliderTrainingContainer = styled.div`
  margin-top: ${(props) => (props.row ? '10px' : '-100px')};
  position: relative;
  z-index: 0;
  padding-bottom: ${(props) => (props.row ? '50px' : '')};

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    margin-top: 0;
  }

  .button-slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .swiper-wrapper {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .training-pagination {
    margin-top: ${(props) => (props.row ? '' : '-100px')};
  }

  .slider {
    position: relative;
    margin-bottom: 8px;
  }

  .overlay {
    overflow-x: scroll;
  }

  .inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    padding-right: 16px;
    box-sizing: border-box;
    width: fit-content;
  }
`;

export const ButtonEnd = styled.div`
  background-color: #373737;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-top: ${(props) => (props.row ? '20px' : '100px')};
  height: 370px;
  width: 100vw;

  .wrap-button {
    display: block;
    cursor: pointer;

    div.content {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      color: #00debf;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin-top: 0px;
    margin-left: 16px;
    border-radius: 4px;
    height: 250px;
    width: 25vw;

    .wrap-button {
      svg {
        width: 50px;
      }

      div.content {
        font-size: 14px;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin-top: 0px;
    margin-left: 16px;
    border-radius: 4px;
    height: 250px;
    width: 40vw;

    .wrap-button {
      svg {
        width: 50px;
      }

      div.content {
        font-size: 12px;
      }
    }
  }
`;
