import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  .link {
    margin: 24px 134px 0 8px;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.white ? '#202020' : '#fdfdfd')};
    cursor: pointer;
  }

  .title {
    font-size: 16px;
  }

  .flex {
    display: grid;

    grid-auto-flow: column;
    gap: 40px;

    margin-top: 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 40%;
    overflow: hidden;
  }

  &.about-container {
    @media screen and ${(props) => props.theme.breakpoints.xs} {
      width: 50%;
    }
  }

  &.mobile-container {
    @media screen and ${(props) => props.theme.breakpoints.xs} {
      width: 55%;
      margin-left: 10px;
    }
  }

  &.perusahaan-container {
    @media screen and ${(props) => props.theme.breakpoints.xs} {
      width: 29%;
    }
  }
  &.social-media {
    @media screen and ${(props) => props.theme.breakpoints.xs} {
      width: 30%;
      margin-left: 10px;
    }
  }
`;

export const Title = styled.h6`
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  margin: 0 0 12px 0;

  &.sosmed {
    margin-top: 20px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 10px 1px 10px 0;
    font-size: 14px;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 10px 1px 5px 0;
    font-size: 12px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  margin: 2px 0 16px 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #c4c4c4;
  cursor: pointer;
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 10px 1px 0 0;
    font-size: 14px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 10px 1px 0 0 !important;
    font-size: 12px;
  }

  &:hover {
    color: rgb(0, 222, 191);
  }
`;

export const Address = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 21px;
  letter-spacing: normal;
  text-align: left;
  color: #c4c4c4;
  margin: 0px 0 24px 0;
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 10px 1px 0 0;
    font-size: 14px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
  }
`;

export const LogoImage = styled.img`
  width: ${(props) => (props.width === 'playstore' ? '187px' : '161px')};
  height: ${(props) => (props.width === 'playstore' ? '78px' : '54px')};
  margin-bottom: -12px;
  ${({ width }) => width === 'playstore' && 'margin-left: -13px;'}

  cursor: pointer;

  &.logo {
    width: 100%;
    height: auto;
  }

  &.logo-none {
    cursor: auto;
    width: 100%;
    height: auto;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin-left: ${(props) => (props.width === 'playstore' ? '-12px' : '0')};
    float: left;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin-bottom: 2px;
    float: left;
  }
`;

export const ContainerLogo = styled.div`
  margin-right: auto;

  .app-store {
    width: 161px;
    height: 54px;
    margin-bottom: -12px;

    cursor: pointer;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: 0;
      float: left;
    }
    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  .playstore {
    width: 187px;
    height: 78px;
    margin-bottom: -12px;
    margin-left: -13px;

    cursor: pointer;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: -12px;
      float: left;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  .logo {
    width: 100%;
    height: auto;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: -12px;
      float: left;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  .logo-none {
    cursor: auto;
    width: 100%;
    height: auto;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: -12px;
      float: left;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    height: auto;
  }
`;
