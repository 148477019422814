import styled from 'styled-components';

export const Figure = styled.figure`
  position: relative;

  margin: 0;
  width: 43px;
  height: 43px;

  border-radius: 100%;

  background: #626262;

  background-image: url('${({ profile_pic }) => profile_pic}');
  background-size: cover;
  background-position: center;

  flex-shrink: 0;

  cursor: pointer;
`;

export const UserDetail = styled.div`
  position: absolute;
  top: 59px;
  right: 0%;

  width: 250px;

  border-radius: 5px;

  background: #202020;

  cursor: default;

  h6 {
    font-size: 24px;
    margin: 12px 0 8px 0;
    line-height: 26px;
    width: 200px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;

    margin: 0;

    line-height: 17px;

    color: #c4c4c4;
  }

  box-shadow: 0px 33px 292px rgba(0, 0, 0, 0.05), 0px 4.13211px 36.5629px rgba(0, 0, 0, 0.025);

  .button-profile {
    width: 100%;
    height: 37px;

    margin: 0;
    margin-top: 16px;

    font-size: 14px;
    font-family: Inter, sans-serif;
    line-height: 17px;

    background: transparent;

    color: ${({ theme }) => theme.color.primary};

    border: 1px solid ${({ theme }) => theme.color.primary};
  }

  .button-profile:hover {
    border: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

export const Profile = styled.div`
  display: grid;
  justify-items: center;
  padding: 24px 24px 16px;
  margin: 0 auto;
  width: 100%;
  border-bottom: 1px solid #626262;
  box-sizing: border-box;
`;

export const Progress = styled.div`
  padding: 12px 24px;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #626262;
  box-sizing: border-box;

  display: grid;
  align-items: center;

  &.upgrade {
    padding: 14px 24px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  &.upgrade:hover {
    background: #303030;
  }

  & > div {
    display: flex;
    align-items: center;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    & > svg {
      margin-right: 18px;
    }

    & > p {
      margin: 4px 0;
      font-size: 12px;
    }

    & > span {
      margin: 0 4px;
    }
  }

  a {
    text-decoration: none;
    display: flex;
    align-content: center;
    color: #fdfdfd;

    & > svg {
      margin-right: 18px;
    }
  }

  & span {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;

    color: ${({ theme }) => theme.color.primary};
  }
`;

export const Img = styled.img`
  width: 80px;
  height: 80px;

  border-radius: 100%;

  background: #626262;

  background-size: cover;
  background-position: center;
`;

export const Settings = styled.div`
  text-align: left;
  width: 100%;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  & svg {
    margin-right: 18px;
  }

  & > div,
  a {
    display: flex;
    align-items: center;
    padding: 14px 0;
    padding-left: 24px;

    cursor: pointer;

    text-decoration: none;
    color: #fdfdfd;
  }

  & > div:hover,
  a:hover {
    background: #303030;
  }

  & > div:nth-child(2) {
    border-radius: 0 0 5px 5px;
  }
`;
