import React from 'react';

import PopupModal from 'components/atoms/Modal';
import { Title, Separator } from '../KeluarModal/styled';
import { Content, Button, ButtonContainer } from '../SmallDarkModal/styled';
import { useHistory } from 'react-router-dom';
import { t } from 'hooks/intl/useLang';

const RetakeKuisModal = ({
  setShowRetakeHabis,
  setCurrentSection = null,
  currentSection = null,
  setType = null,
  moduleData,
  getUserTraining,
  getTrainingDetails,
  maxRetake = false,
  router,
  ...rest
}) => {
  const history = useHistory();
  const renderComponent = () => {
    if (maxRetake) {
      return (
        <>
          <Title>{t('Kamu Sudah Tidak Bisa Retake')}</Title>
          <Separator />
          <Content>
            <p>
              {t('Kamu sudah tidak lulus sebanyak 5 kali, sehingga kamu tidak bisa mendapatkan sertifikat. Tapi kamu masih tetap bisa melanjutkan menonton training selanjutnya dengan klik Training Selanjutnya.')}
            </p>
          </Content>
          <ButtonContainer className="mx-3 flex">
            <Button
              onClick={() => {
                // if (currentSection) {
                //   setCurrentSection({
                //     ...currentSection,
                //     training_order: 1,
                //     section_order: 1,
                //     training_id: moduleData?.TrainingChapters?.items[0].id,
                //     training_chapter_id: moduleData?.TrainingChapters?.items[0].sections[0].id,
                //   });
                //   setType('Material');
                // }
                history.push('/pelatihan-saya/proses');
                setShowRetakeHabis(false);
              }}
            >
              {t('Pelatihan Saya')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else {
      return (
        <>
          <Title>{t('Nilai Kamu Sudah Lulus')}</Title>
          <Separator />
          <Content>
            <p>{t('Nilai Kamu sudah memenuhi standar')}</p>
          </Content>
          <ButtonContainer className="mx-3 flex">
            <Button
              onClick={() => {
                // if (currentSection) {
                //   setCurrentSection({
                //     ...currentSection,
                //     training_order: 1,
                //     section_order: 1,
                //     training_id: moduleData?.TrainingChapters?.items[0].id,
                //     training_chapter_id: moduleData?.TrainingChapters?.items[0].sections[0].id,
                //   });
                //   setType('Material');
                // }
                setShowRetakeHabis(false);
              }}
            >
              {t('Lanjutkan Training')}
            </Button>
          </ButtonContainer>
        </>
      );
    }
  };
  return (
    <PopupModal dark medium {...rest}>
      {renderComponent()}
    </PopupModal>
  );
};

export default RetakeKuisModal;
