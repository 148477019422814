import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/';

export const Container = styled.figure`
  width: 100%;
  height: 200px;
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
  position: relative;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    height: 132px;
    margin: 6px 0;
  }
`;
export const ContainerContain = styled.div`
  width: 80%;
  height: 200px;
  flex-grow: 4;
  background-color: #373737;
  border-radius: 0 10px 10px 0;
  padding: 16px;
  box-sizing: border-box;
  position: relative;

  h2 {
    font-family: Archivo;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #fdfdfd;
    margin: 0;
  }

  .nilai-container {
    display: flex;
    align-items: center;
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: left;
    color: #c4c4c4;
    margin: 0;
    span {
      font-size: 18px;
      font-weight: bold;
      color: #00debf;
      margin: 0 16px 0 0;
    }
  }

  .nilai {
    font-size: 18px;
    font-weight: bold;
    color: #00debf;
    margin: 0 16px 0 3px;
  }

  h3 {
    font-family: Archivo;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #fdfdfd;
    margin: 16px 0 0 0;
    max-width: 80%;
    max-height: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  h4 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #c4c4c4;
    margin: 10px 0 0 0;
  }

  svg {
    margin-right: 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    h3 {
      font-size: 18px;
      padding-right: 80px;
      margin: 5px 0 0 0;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    h2 {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }

    h3 {
      font-family: Archivo;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 21px;
    }
    h4 {
      font-family: Archivo;
      font-style: normal;
      font-size: 13px;
      line-height: 21px;
    }
    p {
      font-size: 10px;
      line-height: 12px;

      span {
        font-size: 12px;
        line-height: 15px;
        color: #fdfdfd;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    height: 132px;
    padding: 8px;
    border-radius: 0 5px 5px 0;

    h4 {
      display: none;
    }
    h3 {
      margin: 10px 0 0 0;
      overflow: hidden;
      padding-right: 10px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      span {
        margin: 0 12px 0 0;
      }
    }

    .nilai-container {
      margin-top: 4px;
    }
    svg {
      margin-right: 12px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xxs} {
    height: 132px;
    padding: 8px;
    border-radius: 0 5px 5px 0;

    h4 {
      display: none;
    }
    h3 {
      margin: 5px 0 0 0;
      overflow: hidden;
      padding-right: 10px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      span {
        margin: 0 1px 0 0;
      }
    }

    .nilai-container {
      margin-top: 4px;
    }
    svg {
      margin-right: 5px;
    }
  }
`;

export const Image = styled.img`
  width: 230px;
  height: 200px;
  background-repeat: no-repeat;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 90px;
    height: 132px;
    border-radius: 5px 0px 0px 5px;
  }
`;

export const Button = styled(ButtonStyled)`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 204px;
  height: 51px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  margin: 0;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 14px;
    width: 164px;
    height: 51px;
    bottom: 10px;
    right: 10px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    background-color: transparent;
    color: #00debf;
    font-weight: 500;
    line-height: 21px;

    :hover {
      background-color: transparent;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 20px;
    bottom: 15px;
    right: 0;
  }
`;

export const LabelPrakerja = styled.div`
  background: #00debf;
  border-radius: 2px;
  padding: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
  position: absolute;
  top: 10px;
  left: 10px;
`;
