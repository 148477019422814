import facepaint from 'facepaint';

// Global Theme Variable
export const globalTheme = {
  color: {
    primary: '#00debf',
    secondary: '#000004',
    transparent: 'rgba(255,255,255, 0)',
    gray: '#1d1d1d',
    white: '#fdfdfd',
  },
  backgroundColor: {
    primary: '#141414',
    secondary: '##373737',
  },
  breakpoints: {
    xl: '(max-width: 1441px)',
    lg: '(max-width: 1281px)',
    lgs: '(max-width: 1390px)',
    full: '(max-width:1270px)',
    md: '(max-width: 993px)',
    sm: '(max-width: 769px)',
    xs: '(max-width: 577px)',
    xxs: '(max-width: 321px)',
    xxxs: '(max-width: 280px)',
  },

  font: {
    primary: {
      title: `font-family: Archivo;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fdfdfd;`,

      blueSky: ` font-family: Inter;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.9;
      letter-spacing: normal;
      text-align: center;
      color: #00debf;`,
    },
    secondary: {
      subtitle: `font-family: Inter;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #c4c4c4;`,

      subtitle2: `font-family: Inter;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;`,
    },
  },
};

// Media query template
export const mq = facepaint([
  '@media screen and (max-width: 1441px)',
  '@media screen and (max-width: 1281px)',
  '@media screen and (max-width: 993px)',
  '@media screen and (max-width: 769px)',
  '@media screen and (max-width: 577px)',
]);
