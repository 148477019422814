import styled from 'styled-components';

export const Header = styled.header`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 35px;
  display: none;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: block;
  }

  .header-link:focus {
    outline: none;
  }

  .header-link {
    display: block;
    color: #fdfdfd;
    text-decoration: none;
    margin-bottom: 16px !important;

    background: transparent;
    border: none;
    padding: 0;

    cursor: pointer;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 35px;
  }

  .header-link:hover {
    color: #ddd;
  }

  .notification {
    display: flex;
    align-items: center;
  }

  .notification-count {
    display: grid;
    place-items: center;

    margin: 0 9px;

    width: 19px;
    height: 19px;

    background: #f18f01;

    border-radius: 100%;
    font-size: 12px;
    line-height: 15px;

    color: #000000;
  }
  .pt-5 {
    padding-top: 16px;
  }

  .pt-7 {
    padding-top: 24px;
  }

  .py-5 {
    margin: 0 24px;
  }

  .pb-3 {
    padding-bottom: 12px !important;
  }

  .mb-3 {
    margin-bottom: 16px;
  }

  .start-subs {
    color: #00debf;
    font-weight: 700;
  }

  .space-vertical {
    height: 50px;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 65px;
  position: fixed;
  padding: 16px 24px;
  top: ${(props) => (props.second ? '50px' : '0')};
  font-size: 16px;
  background: ${(props) => props.theme.color.secondary};

  z-index: 100;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .button {
    cursor: pointer;
  }

  .close-icon {
    fill: white;
    color: #fdfdfd;
    background: #fdfdfd;
    margin-right: 21px;
    height: 12px;
  }
`;

export const LeftContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > a {
    display: grid;
    place-items: center;
  }

  span {
    display: grid !important;
    place-items: center !important;
  }
`;

export const RightContainer = styled.div`
  display: grid;
  place-items: center;

  .hidden {
    visibility: hidden;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #626262;
`;

export const Kategori = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid #626262;

  &:focus {
    outline: none;
  }

  img {
    max-width: 16px;
  }

  .header-link-kategori {
    display: flex;
    align-items: center;
    color: #fdfdfd;
    text-decoration: none;
    margin: 12px 0;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    font-family: Inter, sans-serif;
    line-height: 17px;

    & > div {
      width: 20px;
      height: 20px;
      margin-right: 12px;

      display: flex;
      justify-content: center;
    }
  }

  .header-link-kategori:hover {
    color: #ddd;
  }

  & > div {
    padding-top: 16px;

    border-top: 1px solid #626262;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    & h3 {
      font-weight: 400;
      font-family: Inter;
      font-size: 16px;
      margin: 0;
    }

    & .arrow-icon-show {
      transform: rotate(0deg);
    }

    & .arrow-icon-hidden {
      transform: rotate(-180deg);
    }
  }
`;

export const UserProfile = styled.div`
  padding: 24px;
  padding-bottom: 0;

  display: flex;
`;

export const ProfilePic = styled.div`
  width: 80px;
  height: 80px;

  flex-shrink: 0;

  border-radius: 100%;
  background: #626262;

  background-image: url('${({ src }) => src}');
  background-size: cover;
  background-position: center;

  margin-right: 16px;
`;

export const ProfileDetail = styled.div`
  font-family: Inter;
  font-style: normal;

  display: grid;
  align-content: space-between;

  h6 {
    font-family: Archivo;
    font-weight: bold;
    font-size: 20px !important;
    line-height: 22px;
    margin: 0;
    margin-bottom: 8px;

    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin: 0;

    color: #c4c4c4;
  }

  a {
    display: block;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;

    color: #00debf;
  }

  .detail {
    max-width: 140px;
  }
`;

export const Progress = styled.div`
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 35px;

  padding: 8px 24px;

  margin-top: 16px;

  border-top: 1px solid #626262;
  border-bottom: 1px solid #626262;

  & > div {
    display: flex;
    align-items: center;
  }

  p {
    margin: 0;

    font-weight: normal;

    color: #c4c4c4;
  }

  span {
    margin: 0 4px;
    font-weight: bold;

    color: #00debf;
  }
`;
