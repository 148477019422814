import styled from 'styled-components';

export const TitleLink = styled.p.attrs((props) => ({
  color: props.selected ? '#f18f01' : 'c4c4c4',
  fontWeight: props.selected ? 'bold' : 'normal',
  border: props.selected ? '3px solid #f18f01' : '0px solid #626262',
}))`
  font-family: Archivo;
  display: block;
  font-size: 16px;
  font-weight: ${(props) => props.fontWeight};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.color};
  cursor: pointer;
  margin: 0 50px -2px 0;
  border-bottom: ${(props) => props.border};
  padding-bottom: 11px;
  position: relative;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    margin: 0 50px -3px 0;
    white-space: nowrap;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 0 32px -2px 10px;
  }
`;
