import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  min-width: 120px;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
  }

  .icon-active {
    transform: rotate(0deg);
  }
  .active {
    color: #00debf;
    margin-top: 12px;
  }
`;

export const TextButton = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: #c4c4c4;
  text-align: left;
  cursor: pointer;

  :hover {
    color: #00debf;
  }

  .active {
    color: #00debf;
    margin-top: 12px;
  }
`;

export const ListDropDown = styled.div`
  width: 143px;
  height: 60px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  background: #202020;
  border-radius: 4px;
  position: absolute;
  top: 45px;
  right: 0%;
`;
