// import { combineReducers } from "redux";
import * as types from './types';
import { createReducer } from '../../utils';

const Subscribe = createReducer({
  dataSubscribe: [],
})({
  [types.SET_SUBSCRIBE]: (state, action) => {
    return {
      dataSubscribe: action.payload.data,
    };
  },
  [types.REMOVE_SUBSCRIBE]: (state, action) => {
    return {
      dataSubscribe: [],
    };
  },
});

export default Subscribe;
