import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { SIGNUP_TRAINER } from 'mutation/register';
import { useMutation } from '@apollo/client';
import SuccesRegister from 'components/molecule/Modal/ModalSuccesRegister';

// component
import { ContainerInput, ContainerForm, InputField, Button } from './styled';
import InputPhone from 'components/molecule/InputPhone/';
import FormError from 'components/atoms/FormError';
import CategoryDropdown from 'components/atoms/CategoryDropdown';
import Option from 'components/atoms/CategoryDropdown/Option';
import { t } from 'hooks/intl/useLang';

const dataModal = {
  title: t('Pendaftaran Diterima'),
  paragraf:
    t('Data Anda akan dicek terlebih dahulu oleh tim Terampil. Jika approve maka Anda akan menerima email dari tim Terampil untuk tahap selanjutnya'),
  link: '/jadi-trainer',
};
const FormInputJadiTrainer = ({ categoryData, loading, setErrorRegister }) => {
  const {
    register,
    watch,
    handleSubmit,
    errors: errorsInput,
    control,
  } = useForm({
    defaultValues: {
      kategori: [],
    },
  });

  const [daftarTrainer, { loading: loadingDaftarTrainer }] = useMutation(SIGNUP_TRAINER, {
    onCompleted(data) {
      setErrorRegister(null);
      if (data?.CreateTrainerSubmissions?.status === 'Submission') {
        setIsOpenModalSucces(true);
      }
    },
    onError(err) {
      setErrorRegister(err.message);
    },
  });

  const [isOpenModalSucces, setIsOpenModalSucces] = useState(false);
  const [textFile, setTextFile] = useState(t('File pdf, doc, png maksimal 5 MB'));
  const wacthFile = watch(['cv', 'kategori']);

  useEffect(() => {
    if (wacthFile?.cv !== undefined) {
      setTextFile(wacthFile?.cv?.[0]?.name);
    }
  }, [wacthFile]);

  const onSubmit = (data) => {
    const {
      fullname,
      city,
      phone_number,
      email,
      url_social_media,
      profession,
      position,
      working_experience,
    } = data;
    data.kategori = data.kategori.map((item) => item.value);
    daftarTrainer({
      variables: {
        input: {
          fullname,
          city,
          phone_number,
          email,
          url_social_media,
          profession,
          position,
          working_experience,
          category_ids: data.kategori,
        },
        file: data.cv[0],
      },
    });
  };

  const formatData = (data) => {
    if (data === undefined || data === null) return;

    const newData = [];

    for (let item of data) {
      const obj = {};
      obj.value = item.id;
      obj.label = item.name;
      obj.icon = item.icon_default;
      newData.push(obj);
    }

    return newData;
  };

  return (
    <ContainerForm>
      {isOpenModalSucces && (
        <SuccesRegister
          item={dataModal}
          setIsOpenModalSucces={setIsOpenModalSucces}
          isOpen={isOpenModalSucces}
          onRequestClose={isOpenModalSucces}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContainerInput className="name-input">
          <InputField
            register={register({ required: true })}
            name="fullname"
            danger={errorsInput.fullname}
            label={t("Nama Lengkap")}
            placeholder={t("Nama Lengkap")}
          />
          {errorsInput.fullname && <FormError>Nama Wajib di Isi</FormError>}
        </ContainerInput>
        <ContainerInput>
          <InputField
            register={register({ required: true })}
            name="city"
            label={t("Kota Tinggal")}
            placeholder={t("Kota Tinggal")}
            danger={errorsInput.city}
          ></InputField>
          {errorsInput.city && <FormError>Kota Wajib di Isi</FormError>}
        </ContainerInput>
        <ContainerInput>
          <Controller
            control={control}
            name="kategori"
            rules={{ validate: (value) => value.length !== 0, required: true }}
            render={({ onChange, value }) => (
              <CategoryDropdown
                label={t("Pilih Kategori")}
                isMulti
                value={watch('kategori')}
                isSelected={true}
                isLoading={loading}
                options={formatData(categoryData)}
                placeholder={t("Pilih Kategori Keahlian Anda")}
                closeMenuOnSelect={false}
                onChange={onChange}
                hideSelectedOptions={false}
                components={{ Option }}
                danger={errorsInput.kategori}
              />
            )}
          />
          {errorsInput.kategori && <FormError>{t('Kategori Wajib di Isi')}</FormError>}
        </ContainerInput>
        <ContainerInput>
          <InputPhone
            type={'tel'}
            name="phone_number"
            label={t("Nomor HP")}
            danger={errorsInput.phone_number}
            register={register({
              required: true,
              pattern: /^8\d{8,11}$/,
            })}
          />
          {errorsInput.phone_number && <FormError>{t('Nomor HP tidak boleh kosong')}</FormError>}
        </ContainerInput>
        <ContainerInput>
          <InputField
            register={register({
              required: true,
              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            })}
            name="email"
            label="Email"
            placeholder="Email"
            danger={errorsInput.email}
          />
          {errorsInput.email && (
            <FormError>{t('Format email salah. Contoh: terampil@gmail.com')}</FormError>
          )}
        </ContainerInput>
        <ContainerInput>
          <InputField
            register={register({
              required: true,
              pattern: /https:\/\/(www\.)?[\w]+\..+$/,
            })}
            name="url_social_media"
            label={t("Media Sosial")}
            placeholder={t("URL Media Sosial")}
            danger={errorsInput.url_social_media}
          />
          {errorsInput.url_social_media && (
            <FormError>{t('Sosial Media wajib di Isi dan Url formal')}</FormError>
          )}
        </ContainerInput>
        <ContainerInput>
          <InputField
            register={register({ required: true })}
            name="profession"
            label={t("Pekerjaan")}
            placeholder={t("Pekerjaan")}
            danger={errorsInput.profession}
          />
          {errorsInput.profession && <FormError>{t('Pekerjaan wajib di Isi')}</FormError>}
        </ContainerInput>
        <ContainerInput>
          <InputField
            register={register({ required: true })}
            name="position"
            label={t("Jabatan")}
            placeholder={t("Jabatan")}
            danger={errorsInput.position}
          />
          {errorsInput.position && <FormError>{t('Jabatan wajib di Isi')}</FormError>}
        </ContainerInput>
        <ContainerInput>
          <InputField
            register={register({ required: true })}
            name="working_experience"
            label={t("Lama Bekerja")}
            placeholder={t("Lama Bekerja. Contoh : 7 tahun 1 bulan")}
            danger={errorsInput.working_experience}
          />
          {errorsInput.working_experience && <FormError>{t('Lama bekerja wajib di Isi')}</FormError>}
        </ContainerInput>
        <ContainerInput>
          <InputField
            register={register({
              required: true,
              pattern: /.+\.(pdf|doc|docx|png|jpeg|jpg)$/,
              validate: (value) => value[0].size <= 5120000,
            })}
            name="cv"
            className="file-input"
            type="file"
            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf, .pdf, image/png, image/jpeg, image/jpg"
            danger={errorsInput.cv}
          />
          {errorsInput.cv && (
            <FormError>
              {t('CV tidak boleh kosong dan Format CV harus PDF, Maksimal ukuran 5MB')}
            </FormError>
          )}
          <div className={`input-box-file ${errorsInput.cv ? 'danger' : ''}`}>
            <div className="button-file">Unggah CV</div>
            <p>{!textFile ? t('File pdf, doc, png maksimal 5 MB') : textFile} </p>
          </div>
        </ContainerInput>
        <Button className="button-register" medium>
          {loadingDaftarTrainer ? 'loading...' : t('Daftar')}
        </Button>
      </form>
    </ContainerForm>
  );
};

export default FormInputJadiTrainer;
