import styled from 'styled-components';
import img from 'assets/png/banner-ygp.png';
import img2 from 'assets/png/gratyoBottom.png';
import img4 from 'assets/png/banner-ygp-mobile.png';
import img3 from 'assets/png/gratyoBottomMobile.png';
import { ReactComponent as Gratyo } from 'assets/svg/gratyo.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Button from '../../components/atoms/Button';

export const GratyoIcon = styled(Gratyo)`
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    width: 64px;
    height: auto;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    width: 80px;
    height: auto;
  }
`;

export const ButtonReferral = styled.a`
  font-family: Inter;
  background: #00debf;
  border-radius: 6px;
  font-size: 20px;
  width: 328px;
  padding: 8px;
  color: #1d1d1d;
  text-decoration: none;
  margin-top: 16px;
`;

export const ScrollButton = styled(Button)`
  margin: 0;
  width: 236px;
  height: 40px;
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    width: 94%;
  }
`;

export const BannerTop = styled.div`
  width: 100%;
  background-image: url(${img});
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 8% 0;
  @media only screen and ${(props) => props.theme.breakpoints.xl} {
    padding: 5% 0;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    background-image: url(${img4});
    height: 580px;
  }
`;

export const BannerTopMobile = styled.div`
  width: 100%;
  background-image: url(${img4});
  height: 360px;
  background-size: 100%;
  background-repeat: no-repeat;
`;

export const SmallText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  white-space: pre-wrap;
  letter-spacing: 0.003em;
  text-align: left;
  margin-top: 24px;
  color: #00debf;
  @media only screen and ${(props) => props.theme.breakpoints.xl} {
    font-size: 16px;
    line-height: 24px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.full} {
    font-size: 14px;
    line-height: 27px;
    margin-top: 18px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16.5px;
    line-height: 18px;
    margin-top: 10px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xxs} {
    font-size: 14px;
    line-height: 18px;
    margin-top: 8px;
  }
`;

export const LargeText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  white-space: pre-wrap;
  letter-spacing: 0.005em;
  text-align: left;
  color: #fdfdfd;
  margin-bottom: 40px;
  margin-top: 24px;
  @media only screen and ${(props) => props.theme.breakpoints.xl} {
    font-size: 32px;
    line-height: 45px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.full} {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 26px;
    line-height: 27px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xxs} {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
`;

export const BannerBottom = styled.div`
  width: 90%;
  background-image: url(${img2});
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  padding: 5% 0;
  margin: 8% 0;
  @media only screen and ${(props) => props.theme.breakpoints.full} {
    padding: 3% 0;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    border-radius: 6px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    background-image: url(${img3});
    margin-top: -36px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xxs} {
    margin-top: -120px;
  }
`;

export const BannerBottomTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  white-space: pre-wrap;
  text-align: left;
  color: #fdfdfd;
  padding-left: 60px;
  margin-top: 72px;
  margin-bottom: 23px;
  @media only screen and ${(props) => props.theme.breakpoints.full} {
    font-size: 24px;
    line-height: 27px;
    margin-top: 50px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 17px;
    padding-left: 24px;
    margin-bottom: 8px;
    margin-top: 54px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    margin-top: 20px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.xxs} {
    margin-top: 4px;
    padding-left: 12px;
  }
`;

export const BannerBottomSubTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  white-space: pre-wrap;
  text-align: left;
  color: #fdfdfd;
  padding-left: 60px;
  @media only screen and ${(props) => props.theme.breakpoints.full} {
    font-size: 24px;
    line-height: 27px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 17px;
    padding-left: 24px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xxs} {
    padding-left: 12px;
    font-size: 14px;
  }
`;

export const KodeText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  background: #f18f01;
  border-radius: 4px;
  text-align: center;
  padding: 8px;

  color: #ffffff;
  @media only screen and ${(props) => props.theme.breakpoints.full} {
    font-size: 24px;
    line-height: 27px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 17px;
  }
`;

export const BannerBottomTextSmall = styled(BannerBottomSubTitle)`
  font-size: 18px;
  line-height: 18px;

  color: #fdfdfd;
  margin-top: 42px;
  margin-bottom: 8px;
  @media only screen and ${(props) => props.theme.breakpoints.full} {
    font-size: 16px;
    line-height: 18px;
    margin-top: 24px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 15px;
    margin-top: 12px;
  }
`;

export const ButtonStoreContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 48px;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    margin-left: 10px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    margin-left: 16px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xxs} {
    margin-left: 4px;
    margin-top: -14px;
  }
`;

export const BannerTopTextContainer = styled.div`
  display: flex;
  margin-left: 60px;
  justify-content: flex-start;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    margin-left: 24px;
  }
`;

export const PlayStoreButton = styled(LazyLoadImage)`
  height: 77px;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    height: 40px;
    margin-top: 6px;
  }
`;

export const AppleStoreButton = styled(LazyLoadImage)`
  height: 53px;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    height: 28px;
  }
`;
