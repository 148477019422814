/* global google */
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import configureStore from './state';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Pages from './pages';
import { ThemeProvider } from 'styled-components';
import { createUploadLink } from 'apollo-upload-client';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { globalTheme } from 'assets/styles/theme';
import { useEffect } from 'react';

export const client = new ApolloClient({
  uri: process.env.REACT_APP_GATEWAY_URI,
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: process.env.REACT_APP_GATEWAY_URI,
  }),
  connectToDevTools: process.env.NODE_ENV !== 'production',
});

function App() {
  const store = configureStore(window.REDUX_INITIAL_DATA);
  const persistor = persistStore(store);

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={globalTheme}>
            <Router basename="/">
              <ScrollToTop />
              <Pages />
            </Router>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
