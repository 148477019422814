import styled from 'styled-components';

export const Container = styled.section`
  display: grid;
  place-items: center;
  padding-bottom: 60px;
  width: 100%;
`;

export const Title = styled.h2`
  font-family: Archivo;
  font-size: 30px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fdfdfd;

  &.faq-section {
    font-size: 20px;
    text-align: left;

    margin: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    flex-wrap: wrap;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;

    &.faq-section {
      margin-bottom: 4px;
    }
  }
`;
