import styled from 'styled-components';

export const CardContainer = styled.div`
  padding: 0 100px;
  margin: 0 auto;

  @media screen and ${({ theme }) => theme.breakpoints.md} {
    padding: 0 60px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 0 0px;
  }
`;
