import { useState } from "react";
import { LANGMAP } from "./language-map";

export function t(key) {
  if (!LANGMAP[key]) {
    return `<key '${key}' is not defined>`;
  }
  const language = 'EN';
  const value = LANGMAP[key][language];
  // return key;
  return value ?? `<key '${key}' on language '${language}' is not defined>`;
}

export function useLang() {
  const [language, setLanguage] = useState('ID');
  return { t, language, setLanguage };
}
