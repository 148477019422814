import { gql } from '@apollo/client';

export const COMPLETE_PROFILE = gql`
  mutation CompletingProfile($input: CompleteProfileInput) {
    CompletingProfile(input: $input) {
      email
      phone_number
      Profile {
        fullname
      }
    }
  }
`;
