import React, { useState, useEffect } from 'react';
import {
  Container,
  ContainerBottom,
  TextBenefit,
  Button,
  Icon,
  ContinerText,
  ContainerPrice,
  Line,
  WrapperTop,
  WrapperSub,
  FlagDiscount,
  WrapperPriceDisc,
} from './styled';

import CheckList from 'assets/svg/subcriptionIcon/checklist.svg';
import ReactTooltip from 'react-tooltip';
import { t } from 'hooks/intl/useLang';

const imageIcon = [CheckList];

const CardPriceSubs = ({
  promo,
  training,
  detailTraining,
  handleButtonMobile,
  selected,
  index,
  menu,
  setSelected,
  disabled,
  setTypePackage,
  quota = 0,
  freeTrial,
  noButton,
  isToken,
  ...rest
}) => {
  // const history = useHistory();
  const [disabledButton, setDisabled] = useState(false);

  const handleTraininButton = () => {
    const type = menu?.subscription_type
      ? menu?.subscription_type
      : menu?.package_type || menu?.slug;

    handleButtonMobile(type);
  };

  useEffect(() => {
    setDisabled(menu?.package_type === 'Bronze' && disabled);
  }, [disabled, menu]);

  const dataFiturTraining = [
    { name: t('Dapat diakses Selamanya') },
    { name: t('Forum Diskusi') },
    { name: t('Setifikat Pelatihan') },
    { name: t('Download & Nonton Offline di Smartphone') },
    { name: t('Akses training melalui Desktop & Smartphone') },
  ];
  const dataFiturSubscribe = [
    { name: t('Dapat diakses Selama 1 Tahun') },
    { name: t('Forum Diskusi') },
    { name: t('Setifikat Pelatihan') },
    { name: t('Download & Nonton Offline di Smartphone') },
    { name: t('Akses training melalui Desktop & Smartphone') },
  ];
  const benef = menu?.package_type === 'Bronze' ? dataFiturTraining : dataFiturSubscribe;

  return (
    <Container>
      {menu?.package_type === 'Bronze' ? null : (
        <FlagDiscount>
          <p className="text-head">
            {' '}
            {t('Lebih')} <br />
            {t('Hemat')}
          </p>
          <p className="text-large">
            43
            <br /> <span>% OFF</span>
          </p>
        </FlagDiscount>
      )}
      <ContainerBottom noButton={noButton}>
        <div>
          <WrapperTop>
            <TextBenefit>
              <p className="bold-top">
                {menu?.package_type === 'Bronze' ? '1 Module' : `${menu?.title}`}
              </p>
            </TextBenefit>
            {menu?.package_type === 'Bronze' && (
              <WrapperSub>
                <TextBenefit>
                  <p className="bold-top sub">{`${detailTraining?.title}`}</p>
                </TextBenefit>
              </WrapperSub>
            )}
            <Line />
          </WrapperTop>
          {benef?.map((item, i) => {
            return (
              <ContinerText key={i}>
                <Icon src={imageIcon[0]} alt="logo" />
                <TextBenefit bold={i === 0}>
                  {item?.text}
                  <p className="bold-price">{item?.name}</p>
                </TextBenefit>
              </ContinerText>
            );
          })}
        </div>
        <ContainerPrice>
          {((menu?.price > menu?.trainingPrice && menu?.price > 0) ||
            (menu?.price_per_month > menu?.total_price && menu?.price_per_month > 0)) && (
            <WrapperPriceDisc>
              <TextBenefit>
                <p className="disc-price">
                  Rp{' '}
                  {menu?.package_type === 'Bronze'
                    ? menu?.price?.toLocaleString('id') + ',-'
                    : menu?.price_per_month?.toLocaleString('id') + ',-'}
                </p>
              </TextBenefit>
            </WrapperPriceDisc>
          )}
          <ContinerText>
            <TextBenefit>
              <p className="bold-price bottom">
                Rp{' '}
                {menu?.package_type === 'Bronze'
                  ? menu?.trainingPrice?.toLocaleString('id') + ',-'
                  : menu?.total_price?.toLocaleString('id') + ',-'}
              </p>
            </TextBenefit>
          </ContinerText>
          {!noButton && (
            <Button
              disabled={disabledButton}
              onClick={() => handleTraininButton()}
              outline={menu?.package_type === 'Bronze'}
            >
              {!training
                ? t('Beli Sekarang')
                : menu?.package_type === 'Bronze' && training
                ? t('Beli Training')
                : menu?.subscription_type
                ? t('Mulai Berlangganan')
                : quota > 0 && !freeTrial
                ? t('Tambah Paket')
                : t('Beli Paket')}
            </Button>
          )}
        </ContainerPrice>
      </ContainerBottom>
      <ReactTooltip id="main" type="dark" place="bottom" multiline={true} className="tooltip" />
    </Container>
  );
};

export default CardPriceSubs;
