import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/index';

export const ContainerOuter = styled.section`
  a {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 0 16px;
  }
`;

export const CardContainer = styled.div`
  background-color: #202020;
  display: grid;
  place-items: center;
  border: 1px solid #00debf;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 32px 0;
  margin-top: 32px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 16px 0;
    margin-top: 24px;
  }
`;

export const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #ffffff;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  width: 100%;
  text-align: center;

  color: #999999;
  margin: 12px 0 0 0;
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 10px;
    line-height: 15px;
    margin: 6px 0 0 0;
    width: 95%;
  }
`;

export const QuotaTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #00debf;
  margin: 24px 0;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 18px;
    line-height: 22px;
    margin: 16px 0;
  }
`;

export const ButtonOuter = styled.button`
  background-color: inherit;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 0;
  width: 269px;
  margin-top: 24px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #00debf;
  cursor: pointer;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 90%;
    margin: 16px 16px 0 16px;
  }
`;

export const LineBreak = styled.div`
  border: 1px solid #373737;
  margin: 20px 0;
  width: 100%;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 12px 0;
  }
`;

export const BoxVoucher = styled.div`
  border: 1px dashed #707070;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 13px 32px;
  width: 500px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 90%;
  }
`;

export const KodeVoucher = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const ButtonCopy = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #00debf;
  cursor: pointer;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 14px;
    line-height: 17px;
  }
`;
