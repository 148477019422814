import React from 'react';
import PageLayout from '../../../components/pagelayout/Template';
import {
  BoldText,
  ButtonStart,
  ImageContainer,
  ListOrder,
  PanduanContainer,
  TextContainer,
  TextPanduan,
  TitlePanduan,
} from './styled';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { RETAKE_TBM } from '../../../mutation/TBM';
import { useSelector } from 'react-redux';
import { setContext } from '../../../utils';

const PanduanTBM = () => {
  const seo = {
    title: 'Tes Potensi Karir',
    description: 'Tes Potensi Karir',
    canonical: null,
  };

  const history = useHistory();

  const authData = useSelector((state) => {
    return state.Auth;
  });

  const [retakeTBM] = useMutation(RETAKE_TBM, {
    context: setContext(authData),
    onCompleted() {
      history.push('/tes-potensi-karir/mulai');
    },
  });

  return (
    <PageLayout seo={seo}>
      <PanduanContainer>
        <TitlePanduan>Tes Potensi Karir</TitlePanduan>
        <ImageContainer />
        <TextContainer>
          <TextPanduan>
            Pada tes kali ini, kamu akan mengetahui arah minat dan bakat kamu dengan menampilkan
            beberapa profesi yang paling cocok dengan kamu dan juga saran pelatihan yang dapat
            memaksimalkan skill sesuai minat dan bakat yang kamu miliki.
          </TextPanduan>
          <TextPanduan>Panduan mengikuti tes:</TextPanduan>
          <ListOrder>
            <li>
              <TextPanduan>
                Terdapat <BoldText>2 bagian</BoldText> tes yang harus kamu kerjakan tanpa terlewat.
              </TextPanduan>
            </li>
            <li>
              <TextPanduan>
                Soal <BoldText>Bagian 1 terdiri dari 36 soal</BoldText> dan{' '}
                <BoldText>Soal Bagian 2 terdiri dari 66 soal</BoldText>,
              </TextPanduan>
            </li>
            <li>
              <TextPanduan>Soal Bagian 1 dan 2 memiliki model soal yang berbeda.</TextPanduan>
            </li>
            <li>
              <TextPanduan>
                Setiap soal TIDAK memiliki jawaban BENAR atau SALAH maka kerjakan setiap soal
                berdasarkan kesesuaian dengan diri kamu.
              </TextPanduan>
            </li>
            <li>
              <TextPanduan>
                Ingat, jangan terlalu lama memikirkan jawabannya. Segeralah untuk memilih jawaban
                yang menurut kamu paling sesuai dengan diri kamu.
              </TextPanduan>
            </li>
            <li>
              <TextPanduan>
                Jika kamu keluar ditengah tes maka seluruh jawaban akan hilang sehingga kamu harus
                mengulang dari awal.
              </TextPanduan>
            </li>
            <li>
              <TextPanduan>
                Hasil tes akan muncul setelah kamu selesai mengisi seluruh tes.
              </TextPanduan>
            </li>
          </ListOrder>
        </TextContainer>
        <ButtonStart onClick={() => retakeTBM()}>Mulai Tes Sekarang</ButtonStart>
      </PanduanContainer>
    </PageLayout>
  );
};

export default PanduanTBM;
