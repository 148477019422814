import styled from 'styled-components';

export const Container = styled.section`
  background: ${({ bgNone }) => (bgNone ? '#141414' : '#202020')};

  border-radius: 5px;

  h6 {
    margin: 0px 24px 8px 0px;
    font-size: 20px;
  }

  .edit-profile {
    margin: 0 24px 24px 24px;
  }

  .profile-pic-form {
    display: inline-block;
    position: relative;

    padding: 0 24px;
  }

  .overlay-image {
    position: absolute;

    display: grid;
    place-items: center;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    user-select: none;

    width: 150px;
    height: 150px;

    background: rgb(20, 20, 20, 0.6);

    border-radius: 100%;

    overflow-wrap: break-word;

    box-sizing: border-box;
    padding: 0 28px;
  }

  .flex {
    display: flex;
    align-items: baseline;
  }

  .center {
    display: grid;
    margin: 0 auto;
  }

  .nowrap {
    white-space: nowrap;
  }

  .button {
    cursor: pointer;
    display: none;
  }

  .mr-0 {
    margin: 0;
  }

  .mt-4 {
    margin-top: 24px;
  }

  .mb-5 {
    display: block;
    margin-bottom: 32px;
  }

  .w-100 {
    width: 100%;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    min-height: 100vh;

    h6 {
      font-size: 16px !important;
    }

    p {
      font-size: 14px !important;
    }

    background: transparent;

    .profile-pic-form {
      padding: 0 16px;

      display: grid;
      justify-items: center;
    }

    .overlay-image {
      display: none;
    }

    #none {
      display: none;
    }

    .mb-5 {
      margin-bottom: 0;
    }

    .button {
      display: block;
    }

    .xs-mt-3 {
      margin-top: 16px;
    }

    &.xs-my-3,
    .xs-my-3 {
      margin-left: 16px;
      margin-right: 16px;
    }

    &.pb-5,
    .pb-5 {
      padding-bottom: 50px;
    }
  }
`;

export const FormSection = styled.form`
  padding: 32px 0;
  width: 100%;

  div.wraper {
    display: block;

    div.in-wraper {
      display: block;
    }
    div.wrapper-1 {
      display: block;
      margin-left: 160px;
    }
  }

  .hide-component {
    display: none;
    pointer-events: none;
  }

  h6.alert {
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #f18f01;
    margin-left: 25px;

    @media screen and ${({ theme }) => theme.breakpoints.xs} {
      font-size: 12px !important;
      line-height: 18px;
      margin-left: -160px;
    }
    /* margin-left: -20px; */
  }

  .bottom-form {
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding: 0 24px;
    padding-top: 16px;

    font-size: 12px;
    font-weight: 400;
    line-height: 15px;

    color: #c4c4c4;

    & a {
      display: inline-block;
      text-decoration: none;
      font-weight: 700;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  .disabled-button {
    color: #626262;
    background: transparent;
    cursor: default;
    border: 1px solid #626262;
  }

  .edit-icon {
    display: none;
    position: absolute;

    cursor: pointer;

    bottom: 0;
    right: 38%;
  }

  .save-button {
    width: 100px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
  }

  div.head {
    padding-left: 24px;
  }

  div.check-email {
    display: flex;

    h6.none {
      font-size: 20px;
      margin: 0;
    }

    span.email-null {
      font-family: Inter;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      color: #f18f01;
      margin-left: 16px;
      align-self: center;
    }
  }

  p.title {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding-top: 16px;

    h6 {
      font-size: 16px;
      padding-bottom: 10px;
      padding: 0 16px;
    }

    .disabled-button,
    .none {
      display: none;
    }

    .bottom-form {
      padding: 0 16px;
    }

    .edit-icon {
      display: block;
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;

  margin-top: 24px;
  margin-bottom: 32px;

  background: #626262;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export const InputAvatar = styled.input`
  position: absolute;
  top: 0;

  cursor: pointer;

  width: 150px;
  height: 150px;

  background: aqua;

  border-radius: 100%;

  opacity: 0;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    width: 100px;
    height: 100px;
  }
`;

export const InputContainer = styled.div`
  display: grid;
  position: relative;

  grid-template-columns: 130px auto;
  column-gap: 32px;

  padding: 8px 24px;

  .required {
    color: #f18f01;
  }

  .required::after {
    content: '*';
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: block;
    padding: 8px 16px;
  }
`;

export const Label = styled.label`
  position: relative;
  top: 16px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #c4c4c4;

  &.static {
    position: static;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: block;
    top: 5px;

    font-size: 14px;

    &.static {
      position: relative;
    }
  }
`;

export const InputUserSetting = styled.input`
  background: transparent;
  height: 50px;

  border: 1px solid #626262;

  color: #fdfdfd;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  padding: 0 16px;

  border-radius: 5px;

  &.disabled {
    color: #626262;
  }

  &.form-error {
    border: 1px solid #f18f01;
  }

  box-sizing: border-box;
  width: 100%;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    height: 40px;

    font-size: 14px;
    line-height: 21px;
  }
`;

export const RequiredLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #f18f01;

  position: absolute;
  right: 24px;
  top: -20px;

  &.static {
    position: static;
    font-style: italic;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    &.static {
      margin-top: 16px;
      text-align: center;
    }
  }
`;

export const Content = styled.div`
  padding: 16px 0;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #c4c4c4;

  h6 {
    margin: 0;
    font-size: 20px;
    color: ${({ theme }) => theme.color.white};
  }

  & > div {
    display: flex;
    justify-content: space-between;

    padding: 24px;

    padding-bottom: 0;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 0 16px;

    & > div {
      justify-content: space-between;
      padding: 24px 0;
      padding-bottom: 8px;
    }
  }
`;

export const ContentBg = styled.div`
  background: #202020;
  padding: 24px 32px;

  border-radius: 5px;

  margin: 16px 0;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #c4c4c4;

  p {
    margin: 0 24px 0 0;
  }

  h6 {
    margin: 0;
    font-size: 20px;
    color: ${({ theme }) => theme.color.white};

    display: inline;
  }

  ol {
    padding: 0;
    padding-left: 20px;

    margin: 0;
    margin-top: 24px;

    text-align: left;
  }

  li:not(:last-child) {
    margin-bottom: 5px;
  }

  &.flex,
  .flex {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .show-more {
    margin: 0 auto;
    display: grid;
    align-content: center;

    background: transparent;
    border: none;

    cursor: pointer;

    padding: 0;

    font-family: Inter;
    font-size: 16px;
    line-height: 19px;

    color: ${({ theme }) => theme.color.primary};
  }

  .empty-point {
    font-size: 16px;
    line-height: 36px;

    margin: 40px auto;
    display: grid;
    justify-items: center;
    color: #fdfdfd;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin-left: 16px;
    margin-right: 16px;

    padding: 16px;

    &.flex {
      flex-wrap: wrap;
    }

    ol {
      margin-top: 16px;
      font-size: 13px;
    }

    .referral {
      margin-top: 16px;
    }

    .none {
      display: none;
    }
  }
`;

export const Benefit = styled.div`
  display: grid;
  place-items: center;

  & > div {
    display: grid;
    place-items: center;

    width: 70px;
    height: 70px;

    border-radius: 100%;

    background: ${({ theme }) => theme.color.primary};

    font-family: Archivo;
    font-weight: bold;
    color: black;
    font-size: 14px;
  }

  & > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;

    text-align: center;

    color: #c4c4c4;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    & > div {
      font-size: 12px;
    }

    & > p {
      font-size: 10px !important;
      line-height: 15px;
    }
  }
`;

export const Referral = styled.div`
  width: 220px;
  height: 50px;

  margin: 0 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #626262;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #c4c4c4;

  padding: 16px 24px 16px 16px;

  &.w-100 {
    width: 100%;
    margin: 0;

    margin-top: 32px;
    margin-bottom: 24px;
  }

  & > button {
    background: transparent;
    color: ${({ theme }) => theme.color.primary};
    border: none;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    cursor: pointer;
  }

  &::selection,
  button::selection {
    color: none;
    background: none;
  }

  @media screen and (max-width: 1367px) {
    padding: 16px;
    font-size: 12px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin: 0;
    margin-right: 20px;

    width: 100%;

    padding: 12px;

    font-size: 12px;

    height: 40px;

    & > button {
      font-size: 12px;
    }
  }
`;

export const PoinDetail = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: flex-start;
  }

  margin: 24px 0;

  a {
    display: inline-block;
    color: #fdfdfd;
    text-decoration: none;
  }

  .point-highlight {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #00debf;
  }

  .col {
    display: grid;
    row-gap: 12px;
  }

  .detail {
    font-size: 16px;
    line-height: 19px;

    color: #c4c4c4;
  }

  .date {
    font-size: 14px;
  }

  strong {
    font-weight: 700;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 16px 0;
    margin: 0;

    .point-highlight,
    .detail {
      font-size: 14px;
      line-height: inherit;
    }

    .date {
      line-height: 17px;
    }

    .col {
      row-gap: 8px;
    }
  }
`;
