import React, { useEffect, useState } from 'react';
import { Headers, Image, BlogTitle, Container, Flex, CategorysName } from './styled';
import LogoHeader from 'assets/png/logo-terampil.png';
import SearchBlog from 'components/atoms/SearchBlog';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import { phonesize } from 'utils/checkMobile';

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { Link } from 'react-router-dom';

const HeadersBlog = () => {
  const [checkMobil, setCheckMobile] = useState(false);
  const [clickLogo, setClickLogo] = useState(true);

  const renderHeader = () => {
    if (!checkMobil) {
      return <SearchBlog />;
    } else if (checkMobil && clickLogo) {
      return <SearchIcon onClick={() => setClickLogo(false)} />;
    }
    if (!clickLogo) {
      return (
        <Flex>
          <CloseIcon onClick={() => setClickLogo(true)} />
          <SearchBlog mobile />
        </Flex>
      );
    }
  };

  const categorysName = [
    {
      name: 'Insight',
      route: '?category=1',
    },
    {
      name: 'Stories',
      route: '?category=2',
    },
    {
      name: 'News',
      route: '?category=3',
    },
  ];

  useEffect(() => {
    setCheckMobile(phonesize());
  }, []);
  return (
    <Headers>
      <Container>
        <div>
          {clickLogo ? (
            <div className="logo">
              <Link to="/">
                <Image src={LogoHeader} />
              </Link>
              <Link to="/blog">
                <BlogTitle>Blog Home</BlogTitle>
              </Link>
            </div>
          ) : (
            <div />
          )}
          {/* {renderHeader()} */}

          {checkMobil ? null : ( // <SearchIcon onClick={() => handleMobileClick()} />
            <SearchBlog
              blog
              mobile={true}
              setCheckMobile={setCheckMobile}
              clickLogo={clickLogo}
              setClickLogo={setClickLogo}
            />
          )}
        </div>

        <div className="category-box">
          {categorysName?.map((item, i) => {
            return (
              <Link to={`/blogs${item.route}&page=1`}>
                <CategorysName>{item.name}</CategorysName>
              </Link>
            );
          })}
        </div>
      </Container>
    </Headers>
  );
};

export default HeadersBlog;
