import React from 'react';
import { ReactComponent as VideoIcon } from 'assets/svg/undangteman/video.svg';
import { ReactComponent as TrainingFreeIcon } from 'assets/svg/undangteman/training.svg';

import { ContainerBenefeitUndangTeman } from './styled';
import { Link } from 'react-router-dom';
import { t } from 'hooks/intl/useLang';
const pointBenefit = [
  {
    title: 'Unlock Video',
    text: t('Ajak 3 teman untuk mendaftar di Terampil, dapatkan 1 video gratis untuk ditonton.'),
    icon: <VideoIcon />,
  },
  {
    title: 'Free Training',
    text: t('Ajak 1 teman untuk membeli training di Terampil, dapatkan training gratis sesuai paket yang dibeli temanmu.'),
    icon: <TrainingFreeIcon />,
  },
];

const BenefitUndangTeman = ({ login, button }) => {
  return (
    <ContainerBenefeitUndangTeman login={login}>
      <h1>{t('Manfaat Undang Teman')}</h1>
      <p>
        {t('Mari menjadi bagian dari misi Terampil dalam meningkatkan keterampilan di Indonesia. Sebagai apresiasi Terampil akan memberikan beberapa manfaat untuk kamu.')}
      </p>
      <div className="flex">
        {pointBenefit.map((item, i) => {
          return button ? (
            <Link to="/registrasi?invite=1">
              <div className="card-container" key={i}>
                <div className="card-contain">
                  <div>{item.icon}</div>

                  <div>
                    <h2 className="title">{item.title}</h2>
                    <p className="text">{item.text}</p>
                  </div>
                </div>
              </div>
            </Link>
          ) : (
            <div className="card-container" key={i}>
              <div className="card-contain">
                <div>{item.icon}</div>

                <div>
                  <h2 className="title">{item.title}</h2>
                  <p className="text">{item.text}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </ContainerBenefeitUndangTeman>
  );
};

export default BenefitUndangTeman;
