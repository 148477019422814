/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import MaxContainer from 'components/atoms/MaxContainer';
import { Container, Line, LiveSection } from './styled';
import TabLinkComponent from 'components/atoms/TabLink/';
import { Link, useRouteMatch, useLocation, useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_DETAIL_TRAINING, GET_TOTAL_COMENT, LIST_REVIEW_TRAINING } from 'queries/training';
import { checkMobile } from 'utils/checkMobile';
import { getValue, setContext } from 'utils';
import { useSelector } from 'react-redux';
import { checkToken } from 'utils/checkToken';
import { GET_USER_DETAIL } from 'queries/user';
import { t } from 'hooks/intl/useLang';

const TabLinkTrainingDetails = ({ onFollowTraining }) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(pathname);
  const [showTab, setShowTab] = useState(onFollowTraining);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { slug } = useParams();

  const { loading, data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    variables: {
      input: slug,
    },
    onCompleted: (data) => {
      getReviewTraining({ variables: { input: { trainingId: data?.Training?.id } } });
    },
  });

  const authData = useSelector((state) => {
    return state.Auth;
  });

  const token = getValue(authData, 'data.accessToken', '');

  useEffect(() => {
    checkToken(token, setIsLoggedIn);
  }, []);

  const { data: userTraining } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });
  const { data: totalComent } = useQuery(GET_TOTAL_COMENT, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
      },
    },
  });

  const [getReviewTraining, { data: listReviewTraining }] = useLazyQuery(LIST_REVIEW_TRAINING);

  useEffect(() => {
    if (onFollowTraining) {
      setShowTab(onFollowTraining);
    }
  }, [onFollowTraining]);

  const totalUlasan = listReviewTraining?.TrainingTestimonies?.total
    ? listReviewTraining?.TrainingTestimonies?.total
    : 0;

  return (
    <Container>
      <MaxContainer>
        <Line>
          {!checkMobile() ? (
            <>
              <Link to={{ pathname: `${url}/deskripsi`, state: 'deskripsi' }}>
                <TabLinkComponent
                  onClick={() => setSelected(`${url}/deskripsi`)}
                  selected={pathname === `${url}/deskripsi`}
                  className="link"
                >
                  {t('Deskripsi')}
                </TabLinkComponent>
              </Link>
              {checkMobile() && (
                <Link to={`${url}/materi`}>
                  <TabLinkComponent
                    onClick={() => setSelected(`${url}/materi`)}
                    selected={pathname === `${url}/materi`}
                    className="link"
                  >
                    {t('Materi')}
                  </TabLinkComponent>
                </Link>
              )}
            </>
          ) : (
            <>
              {onFollowTraining ? (
                <>
                  {checkMobile() && (
                    <Link to={`${url}/materi`}>
                      <TabLinkComponent
                        onClick={() => setSelected({ pathname: `${url}/materi`, state: 'materi' })}
                        selected={pathname === `${url}/materi`}
                        className="link"
                      >
                        {t('Materi')}
                      </TabLinkComponent>
                    </Link>
                  )}
                  <Link to={{ pathname: `${url}/deskripsi`, state: 'deskripsi' }}>
                    <TabLinkComponent
                      onClick={() => setSelected(`${url}/deskripsi`)}
                      selected={pathname === `${url}/deskripsi`}
                      className="link"
                    >
                      {t('Deskripsi')}
                    </TabLinkComponent>
                  </Link>
                </>
              ) : (
                <>
                  <Link to={{ pathname: `${url}/deskripsi`, state: 'deskripsi' }}>
                    <TabLinkComponent
                      onClick={() => setSelected(`${url}/deskripsi`)}
                      selected={pathname === `${url}/deskripsi`}
                      className="link"
                    >
                      {t('Deskripsi')}
                    </TabLinkComponent>
                  </Link>
                  {checkMobile() && (
                    <Link to={`${url}/materi`}>
                      <TabLinkComponent
                        onClick={() => setSelected({ pathname: `${url}/materi`, state: 'materi' })}
                        selected={pathname === `${url}/materi`}
                        className="link"
                      >
                        {t('Materi')}
                      </TabLinkComponent>
                    </Link>
                  )}
                </>
              )}
            </>
          )}
          <Link to={{ pathname: `${url}/ulasan`, state: 'ulasan' }}>
            <TabLinkComponent
              onClick={() => setSelected(`${url}/ulasan`)}
              selected={pathname === `${url}/ulasan`}
              className="link"
            >
              {t('Ulasan')} ({totalUlasan})
            </TabLinkComponent>
          </Link>
          <Link to={{ pathname: `${url}/diskusi`, state: 'diskusi' }}>
            <TabLinkComponent
              onClick={() => setSelected(`${url}/diskusi`)}
              selected={pathname === `${url}/diskusi`}
              className="link"
            >
              {t('Diskusi')} ({totalComent?.TrainingComments?.total})
              {detailTraining?.Training?.trainer?.slug === 'coach-yohanes-g-pauly' && (
                <LiveSection>Live</LiveSection>
              )}
            </TabLinkComponent>
          </Link>
          {showTab && (
            <>
              <Link to={`${url}/catatan`}>
                <TabLinkComponent
                  onClick={() => setSelected(`${url}/catatan`)}
                  selected={pathname === `${url}/catatan`}
                  className="link"
                >
                  {t('Catatan')}
                </TabLinkComponent>
              </Link>
              <Link to={`${url}/hasil`}>
                <TabLinkComponent
                  onClick={() => setSelected(`${url}/hasil`)}
                  selected={pathname === `${url}/hasil`}
                  className="link"
                >
                  {t('Hasil Training')}
                </TabLinkComponent>
              </Link>
            </>
          )}
        </Line>
      </MaxContainer>
    </Container>
  );
};

export default TabLinkTrainingDetails;
