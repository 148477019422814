import styled from 'styled-components';

export const Container = styled.section`
  padding: 33px 0 50px 50px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 33px 0 50px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 33px 16px 50px 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 16px;
  }

  .loadmore-button {
    width: 100%;
    height: auto;
    margin: 22px 0 0 0;
    padding: 15px 0;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 5px;
    background: transparent;
  }

  .hide-loader {
    display: none;
    pointer-events: none;
  }

  .wrapper-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-icon {
      width: 100px;
      height: 100px;
      margin-top: 50px;
    }

    .loading-loadmore {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hide-component {
    display: none;
    pointer-events: none;
  }

  span.isreview-mobile {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #00debf;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    .ulasan {
      width: 200px;

      span {
        font-size: 12px;
      }
    }
  }

  .ulasan {
    background: transparent;
    border: 2px solid #00debf;

    :hover {
      background-color: #00debf;
      span {
        color: black;
      }
    }

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #00debf;
      z-index: 2;
    }
  }
`;

export const Title = styled.h2`
  ${(props) => props.theme.font.primary.title};
  font-size: 20px;
  text-align: left;
  margin: 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16px;
    line-height: 21px;
  }
`;
