import styled from 'styled-components';
import Button from '../../../atoms/Button';

export const TitleModal = styled.h1`
  font-family: Archivo, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 35px;

  color: ${(props) => (props.lengkapiProfile ? '#fdfdfd' : '#101010')};
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 42px 0 0;
`;
