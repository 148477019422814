import React, { useState, useEffect } from 'react';
import {
  Container,
  CardContainer,
  CardContain,
  CardHeader,
  Paragraf,
  Flex,
  Order,
  Button,
  ButtonContainers,
  StatusName,
  ParagrafBottom,
} from './styled';
import succes from 'assets/svg/pembayaraan/sucess.svg';
import pending from 'assets/svg/pembayaraan/pending.svg';
import failed from 'assets/svg/pembayaraan/failed.svg';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { setContext } from 'utils';
import { GET_USER_ORDERS_DETAIL } from 'queries/orders';
import { useHistory } from 'react-router';
import { formatLocaleDateShort } from 'utils/date';
import LoadingDefaultLogo from 'components/atoms/Loading/LoadingDefaultLogo';
import { t } from 'hooks/intl/useLang';

const RincianKartuKredit = ({ invoice }) => {
  const authData = useSelector((state) => state.Auth);
  const history = useHistory();
  const [dataOrder, setDataOrder] = useState({});

  const { data: dataOrderDetail, loading: loadingOrderDetail } = useQuery(GET_USER_ORDERS_DETAIL, {
    context: setContext(authData),
    variables: { invoice: invoice },
  });

  const statusOrder = () => {
    switch (dataOrderDetail?.UserOrderDetail?.status) {
      case 'Pending':
        setDataOrder({
          ...dataOrder,
          title: 'Pending',
          image: pending,
          message:
            t('Pembayaran kamu lagi diproses. Silahkan tunggu konfirmasi email untuk menikmati konten kami.'),
          status: 'Pending',
        });
        break;
      case 'Failed':
        setDataOrder({
          ...dataOrder,
          title: t('Gagal'),
          image: failed,
          message: t('Kami tidak bisa memproses pembayaran kamu. Cek data kartu kamu dan coba lagi.'),
          status: t('Gagal Bayar'),
        });
        break;
      case 'Paid':
        setDataOrder({
          ...dataOrder,
          title: t('Berhasil'),
          image: succes,
          message:
            t('Terima kasih sudah membeli paket di Terampil dan selamat menikmati konten kami.'),
          status: t('Terbayar'),
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    statusOrder();
  }, [dataOrderDetail]);

  return (
    <Container>
      {loadingOrderDetail && <LoadingDefaultLogo />}
      <CardContainer>
        <CardHeader>
          <img src={dataOrder.image} alt="logo" />
          <h2>{t('Pembayaran')} {dataOrder.title}</h2>
          <p>{dataOrder.message}</p>
        </CardHeader>
        <CardContain>
          <h2>{t('Detail Pembayaran')}</h2>
          <Flex flex="column">
            <h3>{dataOrderDetail?.UserOrderDetail?.order_name}</h3>
            {dataOrderDetail?.UserOrderDetail?.payment_date && (
              <Paragraf show="hide">
                {t('Tanggal Pembayaran')}:{' '}
                <span>{formatLocaleDateShort(dataOrderDetail?.UserOrderDetail?.payment_date)}</span>
              </Paragraf>
            )}
          </Flex>
          {dataOrderDetail?.UserOrderDetail?.start_date &&
            dataOrderDetail?.UserOrderDetail?.end_date && (
              <p>
                {t('Langganan')} {dataOrder.subs}:{' '}
                {formatLocaleDateShort(dataOrderDetail?.UserOrderDetail?.start_date)} -{' '}
                {formatLocaleDateShort(dataOrderDetail?.UserOrderDetail?.end_date)}
              </p>
            )}
          <div className="data">
            <Paragraf>
              {t('Nama Pengguna')}:<span> {dataOrderDetail?.UserOrderDetail?.fullname}</span>
            </Paragraf>
            <Paragraf>
              {t('Metode Pembayaran')}:<span>{dataOrderDetail?.UserOrderDetail?.payment_method}</span>
            </Paragraf>
            <Paragraf>
              {t('Nomor Kartu')}: <span>{dataOrderDetail?.UserOrderDetail?.card_number}</span>
            </Paragraf>
            <Paragraf>
              {t('Nomor Tagihan')}:
              <span>
                {dataOrderDetail?.UserOrderDetail?.invoice_number.split('INV').join('/INV/')}
              </span>
            </Paragraf>
          </div>
          <Order>{t('Order Detail')}</Order>
          <Flex showMobile="column">
            <ParagrafBottom>{t('Tanggal Pembayaran')}:</ParagrafBottom>
            <h3>{formatLocaleDateShort(dataOrderDetail?.UserOrderDetail?.payment_date)}</h3>
          </Flex>
          <Flex>
            <ParagrafBottom>{dataOrderDetail?.UserOrderDetail?.order_name}</ParagrafBottom>
            <h3>
              Rp.{' '}
              {dataOrderDetail?.UserOrderDetail?.voucher_code &&
              dataOrderDetail?.UserOrderDetail?.voucher_id
                ? dataOrderDetail?.UserOrderDetail?.subscription.total_price.toLocaleString('id')
                : dataOrderDetail?.UserOrderDetail?.price.toLocaleString('id')}
            </h3>
          </Flex>
          {dataOrderDetail?.UserOrderDetail?.voucher_code &&
            dataOrderDetail?.UserOrderDetail?.voucher_id && (
              <Flex>
                <ParagrafBottom>Voucher</ParagrafBottom>
                <h3>
                  - Rp.{' '}
                  {(
                    dataOrderDetail?.UserOrderDetail?.subscription?.total_price -
                    dataOrderDetail?.UserOrderDetail?.price
                  ).toLocaleString('id')}
                </h3>
              </Flex>
            )}
          <Flex>
            <StatusName color={dataOrder.status}>{dataOrder.status}</StatusName>
            <h3>
              Rp.{' '}
              {dataOrderDetail?.UserOrderDetail?.payment_method !== 'point'
                ? dataOrderDetail?.UserOrderDetail?.price.toLocaleString('id')
                : 0}
            </h3>
          </Flex>
        </CardContain>
        <ButtonContainers>
          <Button secondary onClick={() => history.push('/home')}>
            {t('Kembali Ke Home')}
          </Button>
          {dataOrderDetail?.UserOrderDetail?.status === 'Paid' ? (
            <Button
              color="black"
              onClick={() =>
                window.open(`/user/invoice/${dataOrderDetail?.UserOrderDetail?.invoice_number}`)
              }
            >
              {t('Lihat Resi')}
            </Button>
          ) : (
            <Button color="black" onClick={() => history.push('/user/settings/pembayaran')}>
              {t('Lihat Riwayat Pembelian')}
            </Button>
          )}
        </ButtonContainers>
      </CardContainer>
    </Container>
  );
};

export default RincianKartuKredit;
