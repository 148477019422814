import { gql } from '@apollo/client';

export const CREATE_CERTIFICATE = gql`
  mutation CREATE_CERTIFICATE($input: CreateCertificateInput) {
    CreateCertificate(input: $input) {
      created_at
      file {
        link
      }
    }
  }
`;
