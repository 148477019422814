import styled from 'styled-components';
import InputFieldStyled from 'components/atoms/InputField/';
import ButtonStyled from 'components/atoms/Button';

export const ContainerForm = styled.div`
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0 25px;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;

  select {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    font-size: 16px;
    background-color: transparent;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    margin: 16px 0 4px;
    color: #626262;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .arrow-select {
    position: absolute;
    right: 20px;
    top: 36px;
  }

  div.input-box-file {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    width: 100%;
    height: 52px;
    font-size: 16px;

    padding: 9px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: absolute;
    top: 26px;

    .button-file {
      width: 30%;
      background-color: #f18f01;
      height: 31px;
      border-radius: 5px;
      font-size: 14px;
      font-family: Inter;
      text-align: center;
      padding: 7px;
      box-sizing: border-box;
      font-weight: normal;
      color: #fdfdfd;
    }

    p {
      font-family: Inter;
      font-size: 12px;
      color: #c4c4c4;
      margin: 0;
      font-weight: normal;
      margin-left: 10px;
    }
  }

  .tahun {
    font-family: Inter;
    font-size: 14px;
    color: #c4c4c4;
    position: absolute;
    right: 20px;
    top: 23px;
    font-weight: normal;
  }
  .danger {
    border: 2px solid red !important;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    div.input-box-file {
      .button-file {
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 320px) {
    div.input-box-file {
      .button-file {
        font-size: 9px;
        width: 35%;
      }

      p {
        font-size: 11px;
      }
    }

    p {
      font-size: 9px;
    }
  }
`;

export const InputField = styled(InputFieldStyled)`
  input[type='file'] {
    z-index: 2;
    position: relative;
    opacity: 0;
    cursor: pointer;

    @media screen and (max-width: 320px) {
      height: 45px;
    }
  }
`;

export const Button = styled(ButtonStyled)`
  &.button-register {
    width: 100%;
    height: 50px;
    padding: 10px 20px 10px 20px;
    margin: 22px 0 0px 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
  }
`;
