import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  justify-items: center;

  padding: 0 24px;

  svg {
    margin: 24px 0;
  }

  .w-100 {
    margin-bottom: 32px;
    width: 100%;
  }
`;

export const Title = styled.h6`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;

  text-align: center;

  margin: 0;
  margin-bottom: 16px;
`;

export const Text = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  margin: 0;
  margin-bottom: 32px;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 24px 0;

  .button {
    width: 100%;
    height: 50px;
  }

  .button-outline {
    border: 1px solid #c4c4c4;
    color: #c4c4c4;
  }
`;
