import styled from 'styled-components';

export const Container = styled.section`
  width: calc(100% - 50px);

  padding: 32px;

  background: #202020;

  border-radius: 5px;

  box-sizing: border-box;

  margin-left: auto;
  margin-top: 24px;
  margin-bottom: 40px;

  h6 {
    margin: 0;

    font-size: 24px;
    color: ${({ theme }) => theme.color.primary};
  }

  .mb-3 {
    margin-bottom: 24px;
  }

  .ml-auto {
    margin-left: auto;
    margin-right: 0;
    display: block;
  }

  .button {
    font-size: 14px;
    font-weight: 500;

    border-radius: 5px;

    width: 160px;
    height: 40px;
  }

  .disabled {
    background: transparent;
    border: solid 1px #626262;

    cursor: default;

    color: #626262;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    padding: 16px;

    margin: 0;
    margin-top: 70px;

    background: #141414;

    .button {
      width: 100%;
    }
  }
`;

export const Detail = styled.div`
  display: flex;

  justify-content: space-between;

  p,
  ol,
  li {
    font-family: Inter;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
  }

  ol {
    padding-left: 16px;
  }

  li {
    padding-bottom: 16px;
  }

  &.no-flex {
    display: block;
    text-align: left;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    p {
      font-size: 14px;
    }
  }
`;
export const Question = styled.div`
  display: flex;

  text-align: left;

  font-family: Inter;

  p {
    margin: 0;
  }

  .list {
    font-family: Archivo;
    font-weight: 700;
    font-size: 24px;
    padding-right: 16px;
  }

  .question {
    font-weight: 400;
    line-height: 30px;
    font-size: 16px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    .list {
      font-size: 16px;
      padding-right: 4px;
      padding-top: 2px;
    }

    .question {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const Answer = styled.div`
  padding-left: 32px;

  text-align: left;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 0;
  }
`;
