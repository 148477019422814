import React from 'react';
import { GridLayoutStyled } from './styled';

function GridLayout({ renderedItem, renderedData, ...rest }) {
  return (
    <GridLayoutStyled {...rest}>
      {renderedData?.map((data, index) => {
        return renderedItem(data, index);
      })}
    </GridLayoutStyled>
  );
}

export default GridLayout;
