import styled from 'styled-components';

export const Container = styled.div``;
export const Title = styled.p`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  text-align: center;
`;
export const Line = styled.hr`
  border: 1px solid #626262;
  margin: 16px 0 24px;
`;
export const WrapperContent = styled.div`
  padding: 0 16px;
`;
export const Content = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 32px;

  text-align: center;

  color: #c4c4c4;
`;
export const ButtonContainer = styled.div`
  margin: 16px 0;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  gap: 16px;
  padding: 0 24px;

  button {
    font-size: 16px;
    width: 180px;
    height: 50px;
  }

  .cancel {
    color: #000004;
    background: ${({ theme }) => theme.color.primary};
    border: none;
  }

  .accept {
    color: #c4c4c4;
    background: transparent;
    border: 1px solid #c4c4c4;
  }

  .accept:hover {
    border: 2px solid #c4c4c4;
  }

  .cancel:hover {
    background: #00f1cf;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    button {
      width: 145px;
      height: 45px;
    }
  }
`;
