import { gql } from '@apollo/client';

export const CREATE_WEB_TRAFFIC = gql`
  mutation CreateWebTraffics($uniqueId: String) {
    CreateWebTraffics(uniqueId: $uniqueId) {
      status
      statusText
    }
}
`;

export const CREATE_PAGE_VIEWS = gql`
  mutation CreatePageViews($pageName: String) {
    CreatePageViews(pageName: $pageName) {
      status
      statusText
    }
}
`;
