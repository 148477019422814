import React from 'react';

import { Button } from './styled';

// import { ReactComponent as FacebookIcon } from 'assets/svg/socialmedia/facebook.svg';
// import { ReactComponent as TwitterIcon } from 'assets/svg/socialmedia/twitter.svg';
import { ReactComponent as FacebookIcon } from 'assets/svg/blog/facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/svg/blog/twitter.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/blog/email.svg';
import { ReactComponent as Whatsapp } from 'assets/svg/blog/wa.svg';

const SocialMediaShare = ({ type, ...rest }) => {
  const setIcon = (type) => {
    switch (type) {
      case 'Facebook':
        return <FacebookIcon />;
      case 'Twitter':
        return <TwitterIcon />;
      case 'Email':
        return <EmailIcon />;
      case 'WhatsApp':
        return <Whatsapp />;
      default:
        return <FacebookIcon />;
    }
  };

  return (
    <Button className={type} {...rest}>
      {setIcon(type)}
    </Button>
  );
};

export default SocialMediaShare;
