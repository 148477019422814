import styled from 'styled-components';

export const Container = styled.main`
  background: #000004;
  padding: 100px 0px 80px 0px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 32px 0px;
    display: block;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #141414;
  padding: 80px 0px 100px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 32px 0px;
  }
`;

export const Title = styled.h5`
  color: #fdfdfd;
  font-size: 30px;
  margin: 0px 16px 50px;
  max-width: 900px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-size: 20px;
    line-height: 30px;
    margin: 0px 16px 24px;
  }
`;
