import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  height: 180px;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 40px auto 0px auto;
  padding: 40px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    margin: 20px auto;
    padding: 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 10px auto;
    padding: 10px;
  }
`;

export const Title = styled.div`
  font-family: Archivo;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 20px;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    flex-wrap: wrap;
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 17px;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 0px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ImageIcon = styled.img`
  width: 60%;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    max-width: 100%;
    height: auto;
  }
`;
export const ImageDiv = styled.div`
  margin: 0 50px;

  .image-icon {
    max-width: 70%;

    @media screen and ${(props) => props.theme.breakpoints.md} {
      max-width: 100%;
      height: auto;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 30%;
    height: auto;
    margin: 5px;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 30%;
    height: auto;
    margin: 5px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xxs} {
    margin: 1px;
  }
`;
