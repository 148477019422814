import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
`;

export const ProgressBar = styled.div`
  flex: 11;
  position: relative;
  border-radius: 40px;
  background-color: #464646;
  height: 8px;
  margin-right: 12px;
`;

export const FilledProgress = styled.div`
  width: ${(props) => props.percentage}%;
  background: #00debf;
  border-radius: inherit;
  height: 100%;
`;

export const ProgressPercentage = styled.p`
  flex: 1;
  font-family: Archivo, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  margin: 0;
  /* identical to box height, or 187% */

  color: #00debf;
  
  @media only screen and (max-width: 577px) {
    font-size: 12px;
    line-height: 24px;
  }
`;
