import React from 'react';
import TemplateHeadline from 'components/molecule/TemplateHeadline';

const headline = {
  image: 'https://d2jr0bw5daaf2g.cloudfront.net/assets/prakerja/prakerja-1.png',
  secondH2: '#AyoJadiTerampil dengan Kartu Prakerja',
  paragraf: 'Dapatkan pelatihan online gratis serta insentif senilai 2,55 juta.',
  link: '/check-voucher',
  button: 'Saya Punya Voucher',
};
const Prakerja = () => {
  return (
    <>
      <TemplateHeadline prakerja={true} kolaborasi={false} items={headline} />
    </>
  );
};

export default Prakerja;
