import styled from 'styled-components';
import GridLayout from 'components/atoms/GridLayout';

export const TransferVAStyled = styled.div`
  padding: 32px;
  display: ${(props) => (props.hide ? 'none' : 'block')};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
    padding: 0;
  }
`;

export const GridMenus = styled(GridLayout)`
  row-gap: 24px;
  column-gap: 21px;
  margin: 0;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    column-gap: 0;
    row-gap: 0;

    & :last-child {
      border-bottom: 0;
    }
  }
`;

export const TitleMobile = styled.h3`
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  display: none;
  margin: 24px 0 0 16px;
  text-align: left;

  color: ${(props) => props.theme.color.white};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
  }
`;
