import React, { useEffect, useState } from 'react';
import PageLayout from 'components/pagelayout/Template';
import { postLinkUrl } from 'utils/urlReferral';
import { Container } from '../WelcomePage/styled';
import {
  AppleStoreButton,
  BannerBottom,
  BannerBottomSubTitle,
  BannerBottomTextSmall,
  BannerBottomTitle,
  BannerTop,
  BannerTopTextContainer,
  ButtonStoreContainer,
  GratyoIcon,
  KodeText,
  LargeText,
  PlayStoreButton,
  SmallText,
  ScrollButton,
} from './styled';
import { phonesize } from 'utils/checkMobile';
import AppStoreLogo from '../../assets/png/PelatihanSaya/App Store.png';
import PlayStoreLogo from '../../assets/png/PelatihanSaya/PlayStore.png';
import { scrollMidle } from '../../utils/scrollPage';
import { t } from 'hooks/intl/useLang';

const ReferralRedirect = () => {
  const referralCode = 'gratyo';
  const [link, setLink] = useState();
  const isMobile = phonesize();

  const seo = {
    title: 'Terampil: Your FUTURE Starts NOW',
    description:
      t('Rencanakan dan bangun karir Anda bersama Terampil. Temukan berbagai training terbaik yang dapat membantu Anda meraih jenjang karir dambaan.'),
    canonical: null,
  };

  useEffect(() => {
    try {
      const getLinkReferral = async () => {
        const LinkUrl = await postLinkUrl(referralCode.toUpperCase());
        setLink(LinkUrl?.shortLink);
      };
      getLinkReferral();
    } catch (error) {}
  }, []);

  return (
    <PageLayout seo={seo}>
      <Container>
        <BannerTop>
          <BannerTopTextContainer>
            <GratyoIcon />
            <SmallText>
              {t('HADIAH SPESIAL dari Terampil No. 1 App')}{'\n'}for Building Wealth & Building Business
            </SmallText>
            <LargeText>{t('Gratis 5 Video Training')}{'\n'}Coach Yohanes G. Pauly</LargeText>
            <ScrollButton
              onClick={() => {
                scrollMidle('gratyoBottomBanner', isMobile ? 1.8 : 9);
              }}
            >
              {t('Bagaimana Caranya?')}
            </ScrollButton>
          </BannerTopTextContainer>
        </BannerTop>
        <BannerBottom id="gratyoBottomBanner">
          <BannerBottomTitle>{t('Download aplikasi Terampil sekarang')}</BannerBottomTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <BannerBottomSubTitle>{t('Daftar & pakai kode khusus')} </BannerBottomSubTitle>
            <KodeText>GRATYO</KodeText>
          </div>

          <BannerBottomTextSmall>
            {t('Download aplikasi Terampil untuk pengalaman belajar lebih menyenangkan.')}
          </BannerBottomTextSmall>
          <ButtonStoreContainer>
            <a target="blank" href="https://play.google.com/store/apps/details?id=com.terampil.app">
              <PlayStoreButton
                effect="blur"
                className="playstore"
                src={PlayStoreLogo}
                alt="google-playstore"
              />
            </a>
            <a href="https://apps.apple.com/id/app/terampil/id1559671480" target="blank">
              <AppleStoreButton
                effect="blur"
                className="app-store"
                src={AppStoreLogo}
                alt="apple-store"
              />
            </a>
          </ButtonStoreContainer>
        </BannerBottom>
      </Container>
    </PageLayout>
  );
};

export default ReferralRedirect;
