import PopupModal from 'components/atoms/Modal';
import React from 'react';
import { Container, Title, Text, ContainerButton } from './styled';
import { ReactComponent as SuccessIcon } from 'assets/svg/pembayaraan/sucess.svg';
import { ReactComponent as FailedIcon } from 'assets/svg/pembayaraan/failed.svg';
import Button from 'components/atoms/Button';

const PopupBerhasil = ({
  title,
  text,
  textButtonLeft,
  textButtonRight,
  onLeft,
  onRight,
  failed = false,
  ...rest
}) => {
  return (
    <PopupModal dark {...rest}>
      <Container>
        {failed ? <FailedIcon /> : <SuccessIcon />}
        <Title>{title}</Title>
        <Text>{text}</Text>
        <ContainerButton>
          {textButtonLeft && (
            <Button onClick={() => onLeft()} outline className="button button-outline" small>
              {textButtonLeft}
            </Button>
          )}
          {textButtonRight && (
            <Button onClick={() => onRight()} className="button" small>
              {textButtonRight}
            </Button>
          )}
        </ContainerButton>
      </Container>
    </PopupModal>
  );
};

export default PopupBerhasil;
