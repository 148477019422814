import React from 'react';
import {
  CheckList,
  OverviewDetail,
  TrainerName,
  TrainingOverviewStyled,
  TrainingTitle,
} from './TrainingHover.styled';

import checklist from 'assets/svg/checklist.svg';
import { ReactComponent as LeftOverview } from 'assets/svg/arrow-new1.svg';
import { ReactComponent as RightOverview } from 'assets/svg/arrow-new2.svg';

function TrainingOverview({ left, training }) {
  return (
    <TrainingOverviewStyled left={left}>
      {left ? (
        <LeftOverview className="arrow-bubble" />
      ) : (
        <RightOverview className="arrow-bubble" />
      )}
      <TrainingTitle>{training?.title}</TrainingTitle>
      <TrainerName>{training?.trainer?.fullname}</TrainerName>
      <OverviewDetail>
        <h3 className="title">Apa yang akan kamu pelajari :</h3>
        <CheckList icon={checklist}>
          {training?.benefits && training?.benefits.length > 3
            ? training?.benefits.slice(0, 2).map((benefit, index) => {
                return <li key={index} className="overview-list">{benefit.title}</li>;
              })
            : training?.benefits && training?.benefits.length === 3
            ? training?.benefits.map((benefit, index) => {
                return <li key={index} className="overview-list">{benefit.title}</li>;
              })
            : training?.benefits &&
              training?.benefits.map((benefit, index) => {
                return (
                  <li key={index} className="overview-list">
                    {benefit.title}
                  </li>
                );
              })}
          {training?.benefits && training?.benefits.length > 3 ? (
            <li className="overview-list">Dan lainnya...</li>
          ) : training?.benefits && training?.benefits.length === 3 ? null : null}
        </CheckList>
      </OverviewDetail>
    </TrainingOverviewStyled>
  );
}

export default TrainingOverview;
