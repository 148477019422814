import React from 'react';
import {
  Container,
  ContainerImage,
  ContainerContain,
  ContainerBottom,
  SubTitle,
  Paragraf,
  Title,
} from './styled';
import { checkMobile } from 'utils/checkMobile';

// asset
import { ReactComponent as Wifi } from 'assets/svg/mariBerkolborasi/wifi.svg';
import lineCirle from 'assets/png/mariBerkolaborasi/Vector 5.png';
import lineStraight from 'assets/png/mariBerkolaborasi/Line Responsive.png';
import MaxContainer from 'components/atoms/MaxContainer';

import Terampil from 'assets/png/TM-Logogram-FC-Transparent(1).png';
import { ReactComponent as Companies } from 'assets/svg/mariBerkolborasi/building.svg';
import { ReactComponent as University } from 'assets/svg/mariBerkolborasi/university.svg';
import { t } from 'hooks/intl/useLang';

const WeBridge = () => {
  return (
    <Container>
      <MaxContainer className="relative">
        <Title>{t('Kami Menjembatani Universitas dan Perusahaan')}</Title>
        <div className="container-line">
          <img src={checkMobile() ? lineStraight : lineCirle} alt="" />
        </div>
        <div className="container-up">
          <ContainerContain>
            <ContainerImage>
              {checkMobile() ? <Companies /> : <img src={Terampil} alt="logo-terampil" />}
            </ContainerImage>
            <SubTitle>{!checkMobile() ? 'Terampil' : t('Perusahaan Ternama')}</SubTitle>
            <Paragraf>
              {!checkMobile()
                ? t('Terampil mentransformasi kolaborasi menjadi pelatihan berkualitas yang disahkan oleh perusahaan ternama dan universitas terbaik.')
                : t('Perusahaan berkontribusi signifikan dalam mengembangkan kualitas pendidikan dan memiliki sumber ke manpower berkualitas.')}
            </Paragraf>
          </ContainerContain>
        </div>
        <ContainerBottom>
          <ContainerContain>
            <ContainerImage>
              {checkMobile() ? <img src={Terampil} alt="temapil" /> : <Companies />}
            </ContainerImage>
            <SubTitle> {checkMobile() ? 'Terampil' : t('Perusahaan Ternama')} </SubTitle>
            <Paragraf>
              {checkMobile()
                ? t('Terampil mentransformasi kolaborasi menjadi pelatihan berkualitas yang disahkan oleh perusahaan ternama dan universitas terbaik.')
                : t('Perusahaan berkontribusi signifikan dalam mengembangkan kualitas pendidikan dan memiliki sumber ke manpower berkualitas.')}
            </Paragraf>
          </ContainerContain>
          {!checkMobile() && <Wifi />}
          <ContainerContain>
            <ContainerImage>
              <University />
            </ContainerImage>
            <SubTitle>{t('Universitas')}</SubTitle>
            <Paragraf>
              {t('Universitas dapat mengembangkan kurikulum pembelajaran berdasarkan kebutuhan industri dengan sumber yang valid, serta memiliki koneksi langsung untuk program internship.')}
            </Paragraf>
          </ContainerContain>
        </ContainerBottom>
      </MaxContainer>
    </Container>
  );
};

export default WeBridge;
