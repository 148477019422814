import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/index';
import { mq } from '../../../assets/styles/theme';

export const TagLineStyled = styled.h1`
  position: relative;
  width: 80%;
  flex-direction: column;
  font-size: 80px;
  font-weight: bold;
  text-align: center;
  color: #fdfdfd;
  z-index: 2;
  font-family: Archivo, serif;
  margin-bottom: 0;

  ${mq({
    fontSize: [null, '60px', '60px', '40px', '30px'],
    width: ['80%', '80%', '70%', '65%', '80%'],
  })};
`;

export const TagLineSecond = styled.h1`
  position: relative;
  width: 80%;
  flex-direction: column;
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  color: #fdfdfd;
  z-index: 2;
  font-family: Archivo, serif;
  margin-top: 0;

  ${mq({
    fontSize: [null, '60px', '60px', '40px', '30px'],
    width: ['80%', '80%', '70%', '65%', '70%'],
  })};
`;
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 32px;

  .image {
    height: 355px;
    width: 242px;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    right: 15%;
    top: 30px;

    ${mq({
      height: [null, null, null, '180px', '130px'],
      width: [null, null, null, '120px', '90px'],
      right: [null, null, '5%', '130px', '30px'],
      top: [null, '60px', '100px', '15px', '-10px'],
    })};
  }

  @media screen and ${(props) => props.theme.xs} {
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const SubTagLine = styled.p`
  position: relative;
  width: 40%;
  font-weight: 300;
  font-style: normal;
  color: #c4c4c4;
  margin-bottom: 70px;
  z-index: 2;
  font-family: Inter;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  color: #c4c4c4;

  ${mq({
    fontSize: [null, '20px', '20px', '14px', '14px'],
    width: [null, '700px', '700px', '400px', '300px'],
    marginBottom: [null, null, '30px', '30px', '50px'],
  })};
`;

export const ButtonTagLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  box-sizing: border-box;
  @media screen and (max-width: 438px) {
    width: 85%;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const Button = styled(ButtonStyled)`
  width: 230px;
  font-size: 20px;
  height: 60px;
  @media screen and (max-width: 438px) {
    width: 100%;
    margin-left: 0;
    font-size: 18px;
  }
`;

export const ImageBackground = styled.img`
  height: 355px;
  width: 242px;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  right: 15%;
  top: 30px;

  ${mq({
    height: [null, null, null, '180px', '130px'],
    width: [null, null, null, '120px', '90px'],
    right: [null, null, '5%', '130px', '30px'],
    top: [null, '60px', '100px', '15px', '-10px'],
  })};
`;
