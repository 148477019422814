import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/atoms/Button';

import { Container, Content } from './BannerAds.styled';
import logo from 'assets/png/terampil.png';
import MaxContainer from '../../atoms/MaxContainer';
import { checkMobile } from 'utils/checkMobile';
import RedirectModalToMobile from 'components/molecule/Modal/RedirectToMobile';
import { t } from 'hooks/intl/useLang';

const BannerAds = ({ link, title, textParagraf, textButton }) => {
  const [openModal, setOpenModal] = useState(false);
  const isMobile = checkMobile();
  return (
    <Container>
      <MaxContainer>
        <div className="inside-content">
          <div className="side">
            <img className="img-large" src={logo} alt="Logo Terampil" />
          </div>
          <Content>
            <div className="text">
              <h2 className="title">{title ? title : t('Dapatkan Akses Training Gratis')}</h2>
              <h3 className="subtitle">{textParagraf ? textParagraf : 'Free 3 video training'}</h3>
            </div>
            <div className="button">
              {isMobile ? (
                <div className="button" onClick={() => setOpenModal(true)}>
                  <Button className="button" white large>
                    {textButton ? textButton : t('Mulai Training Gratis')}
                  </Button>
                </div>
              ) : (
                <Link className="button" to={link ? link : '/registrasi'}>
                  <Button className="button" white large>
                    {textButton ? textButton : t('Mulai Training Gratis')}
                  </Button>
                </Link>
              )}
            </div>
          </Content>
        </div>
        {openModal && (
          <RedirectModalToMobile isOpen={openModal} onRequestClose={() => setOpenModal(false)} />
        )}
      </MaxContainer>
    </Container>
  );
};

export default BannerAds;
