import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button';

export const Container = styled.section`
  margin-top: 77px;
  background-color: #141414;
  padding: 40px;
  display: grid;
  place-items: center;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 0;
    padding: 0;
  }
`;

export const CardContainer = styled.div`
  background-color: #202020;
  width: 75%;
  border-radius: 5px;
  padding: 40px 120px 0;
  box-sizing: border-box;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 90%;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 100%;
    padding: 40px 40px;
    height: 100vh;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    padding: 40px 40px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 32px 16px;
  }
`;

export const CardHeader = styled.div`
  & > h2 {
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #fdfdfd;
  }

  & > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #c4c4c4;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    img {
      width: 80px;
    }
    & > h2 {
      font-size: 20px;
      line-height: 30px;
      margin: 15px 0 8px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const TextLanganan = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 24px;
  color: #c4c4c4;
`;

export const CardContain = styled.div`
  border: solid 1px #626262;
  border-radius: 10px;
  padding: 32px;
  box-sizing: border-box;

  div.data {
    margin-top: 32px;
  }

  & > h2 {
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #fdfdfd;
    margin: 0 0 16px 0;
  }

  & > p {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #c4c4c4;
    margin: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    height: auto;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 12px;
    h3 {
      font-size: 16px;
      line-height: 17px;
      text-align: left;
      margin: 10px 0 4px 0;
    }

    div.data {
      margin-top: 24px;
    }

    & > h2 {
      margin: 0 0 32px 0;
    }
  }
`;

export const PriceText = styled.h3`
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  margin: 20px 0 4px 0;
`;

export const Order = styled.div`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: #fdfdfd;
  margin-top: 40px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16px;
    line-height: 17px;
    margin-top: 32px;
  }
`;

export const Paragraf = styled.h6`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  margin: 16px 0;

  span {
    text-transform: capitalize;
    font-weight: bold;
    margin: 12px;
  }

  .payment-method {
    text-transform: uppercase;
  }

  .prakerja {
    text-transform: unset;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 12px 0;
    display: ${(props) => (props.show === 'hide' ? 'none' : 'block')};
    font-size: 14px;
    line-height: 17px;
  }
`;

export const StatusName = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 8px 0;
  text-align: left;
  color: ${(props) => (props.color === 'Terbayar' ? '#00debf' : '#F18F01')};

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 12px 0;
  }
`;

export const PackageName = styled.h3`
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  margin: 10px 0 4px 0;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    margin: 10px 0 4px 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: ${(props) => (props.showMobile === 'column' ? 'none' : 'flex')};

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    flex-direction: ${(props) => (props.flex === 'column' ? 'column' : 'row')};
    align-items: ${(props) => (props.flex === 'column' ? 'flex-start' : 'center')};
    display: ${(props) => (props.showMobile === 'column' ? 'flex' : 'flex')};
  }
`;

export const ButtonContainers = styled.div`
  width: 100%;
  border-radius: 5px;
  margin: 40px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    position: fixed;
    bottom: 0;
    background-color: #000004;
    left: 0;
    margin: 0;
    justify-content: center;
  }
`;

export const Button = styled(ButtonStyled)`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 49%;
  margin: 0;
  height: 50px;
  border-color: ${(props) => (props.color !== 'black' ? '#C4C4C4' : 'none')};
  color: ${(props) => (props.color !== 'black' ? '#C4C4C4' : '#141414')};
  background-color: ${(props) => (props.color !== 'black' ? '#202020' : '#00DEBF')};

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 50%;
    margin: 10px;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const ParagrafBottom = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  margin: 9px 0;
  width: 65%;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    line-height: 17px;
    margin: 8px 0;
    overflow: hidden;
    width: 60%;
  }
`;
