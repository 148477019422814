import styled from 'styled-components';

export const Container = styled.section`
  width: 45%;
  min-height: 94vh;
  padding: 30px 100px 30px 95px;
  display: flex;
  max-width: 50vh;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  background-image: url('${({ imageBackgroud }) => imageBackgroud}');
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  gap: 40px;
  /* box-sizing: border-box; */

  .logo {
    max-width: 40%;
  }

  .logo-left {
    text-align: left;
    object-fit: contain;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    display: none;
  }
`;

export const ImageLogo = styled.img`
  text-align: left;
  object-fit: contain;
`;
