import styled from 'styled-components';
import Button from '../../../atoms/Button';

export const ContainerTestCardTBM = styled.div`
  background: #ffffff;

  box-shadow: 0 4px 10px rgba(98, 98, 98, 0.15);
  border-radius: 10px;
  padding: 24px 40px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    max-width: 280px;
    padding: 16px 24px;
  }
`;

export const ImageContainer = styled.div`
  width: 250px;
  height: 250px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
`;

export const TextDescCardTest = styled.p`
  padding: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 32px 0;
  /* or 150% */

  text-align: center;

  color: #707070;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const ButtonActionTest = styled(Button)`
  background: #f18f01;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  margin: 0;
  height: 50px;
  width: 80%;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    width: 95%;
    height: 40px;
  }
`;

export const TitleCardTest = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #187336;
  margin: 0 0 32px;
  padding: 0;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 24px;
  }
`;
