/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { formatLocaleDateShort } from 'utils/date';
import {
  ComentReplay,
  ProfileCard,
  ReplayCard,
  ReplayComent,
  ReplayDiv,
  ReplyAvatarImage,
  ContainerReplies,
  MainContainer,
  EditComentReplay,
} from './styled';
import { ReactComponent as Like } from 'assets/svg/trainingDetails/like.svg';
import { ReactComponent as Circle } from 'assets/svg/PelatihanSaya/circle.svg';
import { ReactComponent as LikeFilled } from 'assets/svg/trainingDetails/like-filled.svg';
import { ReactComponent as MoreButton } from 'assets/svg/Bar.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/delete-icon.svg';
import { ReactComponent as EditIcon } from 'assets/svg/edit-profile.svg';
import defaultUserPic from 'assets/svg/User Default.svg';
import {
  COMMENT_LIKED_TRAINING,
  COMMENT_UNLIKED_TRAINING,
  UPDATE_COMMENT,
} from 'mutation/training';
import { getValue, setContext } from 'utils';
import { useSelector } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_DETAIL_TRAINING, GET_TRAINING_COMMENTS } from 'queries/training';
import { checkToken } from 'utils/checkToken';
import toast, { LoaderIcon } from 'react-hot-toast';
import Toast from 'components/atoms/Notification/Toast';
import { GET_USER_DETAIL } from 'queries/user';
import HapusKomentarModal from 'components/molecule/Modal/HapusKomentarModal';
import KonfirmasiHapusKomentar from 'components/molecule/Modal/KonfirmasiHapusKomentar';
import { Controller, useForm } from 'react-hook-form';
import MentionExample from 'components/atoms/RichTextInput/Mention';
import { deserialize, serialize } from 'utils/serialize';
import Button from 'components/atoms/Button';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';

const RepliesComment = ({
  item,
  index,
  onShowBoxReply,
  deleteComment,
  userChildId,
  isFollowTraining,
  tagUserComment,
  commentUser,
}) => {
  const [isLikedReply, setIsLikedReply] = useState(item?.isLiked);
  const [showeditButton, setShoweditButton] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [moreAction, setMoreAction] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [text, setText] = useState('');
  const [isOpenHapusKomentarModal, setIsOpenHapusKomentar] = useState(false);
  const [isKonfirmasiHapusKomentarModal, setIsKonfirmasiHapusKomentar] = useState(false);

  const { handleSubmit, setValue, control } = useForm({
    defaultValues: {
      updateComment: deserialize(new DOMParser().parseFromString(commentUser, 'text/html').body),
    },
  });

  useEffect(() => {
    if (commentUser) {
      // FIXED RICH TEXT
      const document = new DOMParser().parseFromString(commentUser, 'text/html');
      const deserial = deserialize(document.body);
      setValue(
        'updateComment',
        deserial.length > 0
          ? deserial
          : deserialize(new DOMParser().parseFromString(commentUser, 'text/html').body),
      );
    }
  }, [commentUser]);

  const openModalKonfirmasiHapusKomentar = () => {
    setIsKonfirmasiHapusKomentar(true);
  };

  const authData = useSelector((state) => {
    return state.Auth;
  });

  const { slug } = useParams();

  const { data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    variables: {
      input: slug,
    },
  });

  const { data: getDiscussionComments } = useQuery(GET_TRAINING_COMMENTS, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        limit: 9999,
        // orderBy: selected,
        orderBy: 'created_at',
        orderType: 'desc',
      },
    },
  });

  //LIST OF USER THAT COMMENTED IN THIS TRAINING
  let saveUserName = [];
  let listOfUser = getDiscussionComments?.TrainingComments?.items.map((item, i) => {
    return saveUserName.push(item?.user?.fullname);
  });
  let listOfUserReplies = getDiscussionComments?.TrainingComments?.items.map((item, i) => {
    return item?.replies.map((items, i) => {
      saveUserName.push(items?.user?.fullname);
    });
  });
  let uniqueChars = [...new Set(saveUserName)];

  const [likedCommentReplies] = useMutation(COMMENT_LIKED_TRAINING, {
    context: setContext(authData),
    onCompleted: () => {
      toast(t('Diskusi berhasil disukai'));
    },
    onError(err) {
      console.error(err.message);
    },
  });

  const [unlikedCommentReplies] = useMutation(COMMENT_UNLIKED_TRAINING, {
    context: setContext(authData),
    onCompleted() {},
    onError(err) {
      console.error(err.message);
    },
  });

  const [fetchDiscussion] = useLazyQuery(GET_TRAINING_COMMENTS, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        limit: 9999,
        // orderBy: "like",
        orderBy: 'created_at',
        orderType: 'desc',
      },
    },
  });

  // LIKED COMMENT
  const onLikedCommentReplies = () => {
    likedCommentReplies({
      context: setContext(authData),
      variables: {
        input: {
          commentId: item?.id,
        },
      },
      refetchQueries: [
        {
          context: setContext(authData),
          query: GET_TRAINING_COMMENTS,
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              limit: 9999,
            },
          },
        },
      ],
    });
  };

  // UNLIKED COMMENT
  const onUnlikedCommentReplies = () => {
    unlikedCommentReplies({
      context: setContext(authData),
      variables: {
        input: {
          commentId: item?.id,
        },
      },
      refetchQueries: [
        {
          context: setContext(authData),
          query: GET_TRAINING_COMMENTS,
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              limit: 9999,
            },
          },
        },
      ],
    });
  };

  //LIKE/UNLIKED COMMENT REPLY
  const setLikedReply = () => {
    if (!isLikedReply) {
      onLikedCommentReplies({
        context: setContext(authData),
        variables: {
          input: {
            commentId: item?.id,
          },
        },
      });
    } else {
      onUnlikedCommentReplies({
        context: setContext(authData),
        variables: {
          input: {
            commentId: item?.id,
          },
        },
      });
    }
  };

  //DELETE COMMENT REPLIES
  const onDelete = () => {
    deleteComment({
      context: setContext(authData),
      variables: {
        input: {
          commentId: item?.id,
        },
      },
      onCompleted: () => {
        <HapusKomentarModal
          isOpen={isOpenHapusKomentarModal}
          setShowModal={setIsOpenHapusKomentar}
          onRequestClose={() => setIsOpenHapusKomentar(false)}
        />;
      },
    });
  };

  const [editRepliesComment, { loading }] = useMutation(UPDATE_COMMENT, {
    onCompleted: () => {
      toast(t('Komentar berhasil diperbarui'));
      setValue(
        'updateComment',
        deserialize(new DOMParser().parseFromString(commentUser, 'text/html').body),
      );
      setShoweditButton(false);
    },
  });

  //UPDATE COMMENT
  const editComment = ({ updateComment }) => {
    editRepliesComment({
      context: setContext(authData),
      variables: {
        input: {
          commentId: item?.id,
          comment: serialize({ children: updateComment }),
        },
      },
      refetchQueries: [
        {
          query: GET_TRAINING_COMMENTS,
          context: setContext(authData),
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              limit: 9999,
              // orderBy: selected,
              orderBy: 'created_at',
              orderType: 'desc',
            },
          },
        },
      ],
      onCompleted: () => {
        toast(t('Komentar berhasil diperbarui'));
        fetchDiscussion();
        setShoweditButton(!showeditButton);
      },
      onError: (error) => {
        console.error(error.message);
      },
    });
  };

  // const editData = (values) => {
  //   setText(values);
  // };

  const { data: userTraining } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  //check condition
  const isSubscribed = userTraining?.ProfileUser?.is_subscription;
  const isMyId = userTraining?.ProfileUser?.id;

  const token = getValue(authData, 'data.accessToken', '');

  useEffect(() => {
    checkToken(token, setIsLoggedIn);
    setText(item?.comment);
  }, []);

  const onShoweditButton = () => {
    setShoweditButton(!showeditButton);
  };

  //chec free trial
  const isFreeTrial = userTraining?.ProfileUser?.User?.free_trial;

  //check condition an user
  const isUserFollowTraining = isLoggedIn && isFreeTrial === true && isFollowTraining;

  return (
    <>
      <Toast position="top-right" />
      <KonfirmasiHapusKomentar
        isOpen={isKonfirmasiHapusKomentarModal}
        setDelete={() => {
          onDelete();
          setIsKonfirmasiHapusKomentar(false);
          setIsOpenHapusKomentar(true);
        }}
        setShowModal={setIsKonfirmasiHapusKomentar}
        onRequestClose={() => setIsKonfirmasiHapusKomentar(false)}
      />
      <ReplayComent>
        <ReplayCard key={index}>
          <ReplyAvatarImage image={item?.user?.avatar ? item?.user?.avatar : defaultUserPic} />
          <div className="container">
            {!showeditButton ? (
              <>
                <ProfileCard>
                  {!checkMobile() ? (
                    <div className="profile-name">
                      <h2>{item?.user?.fullname}</h2>
                      <Circle />
                      <p>{formatLocaleDateShort(item?.created_at)}</p>
                    </div>
                  ) : (
                    <div className="profile-name">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2>{item?.user?.fullname}</h2>
                        <Circle />
                        <p>{formatLocaleDateShort(item?.created_at)}</p>
                      </div>
                      <>
                        <MoreButton
                          className={isMyId === userChildId ? `show` : 'hide'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setMoreAction(!moreAction)}
                        />
                        <div className={moreAction ? 'more-action' : 'hide'}>
                          <div
                            id="edit-comment"
                            className="action-task"
                            onClick={() => {
                              onShoweditButton();
                              setMoreAction(!moreAction);
                              setShowContent(!showContent);
                            }}
                          >
                            <EditIcon />
                            <div>{t('Ubah')}</div>
                          </div>
                          <div
                            id="delete-comment"
                            className="action-task"
                            onClick={() => {
                              openModalKonfirmasiHapusKomentar();
                              setMoreAction(!moreAction);
                            }}
                          >
                            <DeleteIcon />
                            <div>{t('Hapus')}</div>
                          </div>
                        </div>
                      </>
                    </div>
                  )}
                  <ComentReplay dangerouslySetInnerHTML={{ __html: item?.comment }} />
                </ProfileCard>
                <MainContainer>
                  {showContent && (
                    <ReplayDiv replay={true}>
                      {isLoggedIn ? (
                        <>
                          {isLikedReply ? (
                            <LikeFilled
                              onClick={() => {
                                setIsLikedReply((prevLiked) => !prevLiked);
                                setLikedReply();
                              }}
                            />
                          ) : (
                            <Like
                              className="liked"
                              onClick={() => {
                                setIsLikedReply((prevLiked) => !prevLiked);
                                setLikedReply();
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <Like className="disable-item" />
                      )}
                      <p className="like">{item?.like > 0 ? item?.like : 0}</p>
                      <Circle />
                      <h6
                        className={isFollowTraining ? `text-reply` : `disable-item`}
                        onClick={() => {
                          onShowBoxReply();
                          setTimeout(() => {
                            tagUserComment(item?.user?.fullname);
                          }, 0);
                        }}
                      >
                        {t('Balas')}
                      </h6>
                    </ReplayDiv>
                  )}
                  {!checkMobile() && (
                    <>
                      {isUserFollowTraining ? (
                        <ContainerReplies
                          className={isMyId === userChildId ? `` : 'hide-component'}
                        >
                          <h6 className="action" onClick={openModalKonfirmasiHapusKomentar}>
                            {t('Hapus')}
                          </h6>
                          <Circle />
                          <h6 className="action" onClick={onShoweditButton}>
                            {t('Ubah')}
                          </h6>
                        </ContainerReplies>
                      ) : isSubscribed ? (
                        <ContainerReplies
                          className={isMyId === userChildId ? `` : 'hide-component'}
                        >
                          <h6 className="action" onClick={openModalKonfirmasiHapusKomentar}>
                            {t('Hapus')}
                          </h6>
                          <Circle />
                          <h6 className="action" onClick={onShoweditButton}>
                            {t('Ubah')}
                          </h6>
                        </ContainerReplies>
                      ) : null}
                    </>
                  )}
                </MainContainer>
              </>
            ) : (
              <>
                <form onSubmit={handleSubmit(editComment)}>
                  <ProfileCard>
                    {!checkMobile() ? (
                      <div className="profile-name">
                        <h2>{item?.user?.fullname}</h2>
                        <Circle />
                        <p>{formatLocaleDateShort(item?.created_at)}</p>
                      </div>
                    ) : (
                      <div className="profile-name">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <h2>{item?.user?.fullname}</h2>
                          <Circle />
                          <p>{formatLocaleDateShort(item?.created_at)}</p>
                        </div>
                        <>
                          <MoreButton
                            className={isMyId === userChildId ? `show` : 'hide'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setMoreAction(!moreAction)}
                          />
                          <div className={moreAction ? 'more-action' : 'hide'}>
                            <div
                              id="edit-comment"
                              className="action-task"
                              onClick={() => {
                                onShoweditButton();
                                setMoreAction(!moreAction);
                                setShowContent(!showContent);
                              }}
                            >
                              <EditIcon />
                              <div>{t('Ubah')}</div>
                            </div>
                            <div
                              id="delete-comment"
                              className="action-task"
                              onClick={() => {
                                openModalKonfirmasiHapusKomentar();
                                setMoreAction(!moreAction);
                              }}
                            >
                              <DeleteIcon />
                              <div>{t('Hapus')}</div>
                            </div>
                          </div>
                        </>
                      </div>
                    )}
                    <EditComentReplay>
                      <Controller
                        control={control}
                        name="updateComment"
                        render={({ onChange, value }) => (
                          <MentionExample
                            onChange={onChange}
                            listOfUsers={uniqueChars}
                            value={value}
                            type="plain"
                          />
                        )}
                      />
                    </EditComentReplay>
                  </ProfileCard>
                  <MainContainer>
                    {showContent && (
                      <ReplayDiv replay={true}>
                        {isLoggedIn ? (
                          <>
                            {isLikedReply ? (
                              <LikeFilled
                                onClick={() => {
                                  setIsLikedReply((prevLiked) => !prevLiked);
                                  setLikedReply();
                                }}
                              />
                            ) : (
                              <Like
                                className="liked"
                                onClick={() => {
                                  setIsLikedReply((prevLiked) => !prevLiked);
                                  setLikedReply();
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <Like className="disable-item" />
                        )}
                        <p className="like">{item?.like > 0 ? item?.like : 0}</p>
                        <Circle />
                        <h6
                          className={isFollowTraining ? `text-reply` : `disable-item`}
                          onClick={onShowBoxReply}
                        >
                          {t('Balas')}
                        </h6>
                      </ReplayDiv>
                    )}
                    {isUserFollowTraining ? (
                      <ContainerReplies>
                        <h6 className="action" onClick={onShoweditButton}>
                          {t('Batal')}
                        </h6>
                        <Circle />
                        {loading ? (
                          <LoaderIcon className="loading" />
                        ) : (
                          <Button small className="action">
                            {t('Simpan')}
                          </Button>
                        )}
                      </ContainerReplies>
                    ) : isSubscribed ? (
                      <ContainerReplies>
                        <h6 className="action" onClick={onShoweditButton}>
                          {t('Batal')}
                        </h6>
                        <Circle />
                        {loading ? (
                          <LoaderIcon className="loading" />
                        ) : (
                          <Button small className="save-button">
                            {t('Simpan')}
                          </Button>
                        )}
                      </ContainerReplies>
                    ) : null}
                  </MainContainer>
                </form>
              </>
            )}
          </div>
        </ReplayCard>
      </ReplayComent>
    </>
  );
};

export default RepliesComment;
