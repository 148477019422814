import React, { useEffect, useState } from 'react';
import {
  Card,
  SideCover,
  TrainingContent,
  Title,
  Trainer,
  Container,
  FlagBanner,
  FlagBannerPrakerja,
} from './CardYourTraining.styled';
import ProgressTraining from 'components/atoms/ProgressTraining';
import { Link, useHistory } from 'react-router-dom';
import { convertDurationSecond } from 'utils/duration';
import { useSelector } from 'react-redux';
import { setContext } from 'utils';
import { useQuery } from '@apollo/client';
import { TRAINING_PROGRES_DURATION } from 'queries/training';
import PopupUrutanVideoB2B from 'components/molecule/Modal/UrutanNontonVideoB2B';
import { t } from 'hooks/intl/useLang';

function CardYourTraining({ items, pelatihanSaya, expiredFreeTrial, training }) {
  const [totalUnlockVideo, setTotalUnlockVideo] = useState(0);
  const [haveFreeTrial, setHaveFreeTrail] = useState(false);
  const [haveTraining, setHaveTraining] = useState(false);
  const [lockVideoB2b, setLockVideoB2b] = useState(null);
  const [showModalLock, setShowModalLock] = useState(false);
  const history = useHistory();
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const renderBanner = () => {
    switch (true) {
      case totalUnlockVideo > 0:
        return (
          <FlagBanner pelatihanSaya={pelatihanSaya}>{totalUnlockVideo} {t('Video Terbuka')}</FlagBanner>
        );
      case expiredFreeTrial && !haveTraining:
        return (
          <FlagBanner pelatihanSaya={pelatihanSaya} freeTrial={haveFreeTrial}>
            {t('Free Trial Habis')}
          </FlagBanner>
        );
      case training?.training_prakerja:
        return <FlagBannerPrakerja pelatihanSaya={pelatihanSaya}>Prakerja</FlagBannerPrakerja>;
      default:
        return null;
    }
  };

  // get progress
  const { data: totalDuration } = useQuery(TRAINING_PROGRES_DURATION, {
    context: setContext(authData),
    variables: {
      trainingId: training?.id,
    },
    onCompleted: ({ TrainingUserDurationProgress }) => {
      // console.log(TrainingUserDurationProgress, 'DALAM');
    },
  });

  function onClickCard() {
    if (lockVideoB2b) {
      setShowModalLock(true);
    } else {
      history.push(`/training/${training?.slug}/deskripsi`);
    }
  }

  useEffect(() => {
    if (items?.training || items?.Training) {
      setLockVideoB2b(
        !items?.enable &&
          (items?.training_type === 'b2b-mandatory' || items?.training_type === 'b2b-elective'),
      );
    }
  }, [items]);

  useEffect(() => {
    setTotalUnlockVideo(items?.training_section_id?.length);
    setHaveFreeTrail(expiredFreeTrial && !haveTraining && !totalUnlockVideo);
    setHaveTraining(
      items?.training_type !== 'free-trial' || items?.training_type === 'package' ? true : false,
    );
  }, [items, expiredFreeTrial, haveTraining, totalUnlockVideo]);

  return (
    <>
      <Container onClick={onClickCard}>
        {/* <Link className="link-training" to={`/training/${training?.slug}/deskripsi`}> */}
        <Card pelatihanSaya={pelatihanSaya} freeTrial={haveFreeTrial} lockTraining={lockVideoB2b}>
          <SideCover freeTrial>
            <img className="image-cover" src={training?.thumbnail} alt="cover-training" />
          </SideCover>
          <TrainingContent>
            <Title>{training?.title}</Title>
            <Trainer>{training?.trainer?.fullname}</Trainer>
            <div className="row">
              <h3>
                {items?.last_training_chapter?.length > 0 &&
                items?.last_training_chapter?.includes('BAB')
                  ? items?.last_training_chapter?.split(':')[0]
                  : items?.last_training_chapter?.length >= 7
                  ? `${items?.last_training_chapter?.substring(0, 7)}...`
                  : 'Trailer'}
              </h3>
              {items?.last_training_section && <div className="circle" />}
              <h3>
                {items?.last_training_section?.length >= 17
                  ? `${items?.last_training_section?.substring(0, 14)}...`
                  : items?.last_training_section}{' '}
                <span>
                  |{' '}
                  {items?.last_training_section_duration
                    ? convertDurationSecond(items?.last_training_section_duration)
                    : '00:00'}
                </span>{' '}
              </h3>
            </div>
            <ProgressTraining
              percetage={totalDuration?.TrainingUserDurationProgress?.item?.progress || 0}
            />
          </TrainingContent>
        </Card>
        {/* </Link> */}
        {renderBanner()}
      </Container>
      {showModalLock && (
        <PopupUrutanVideoB2B
          router={history}
          setShowModalLock={setShowModalLock}
          isOpen={showModalLock}
          linkTraining={`/training/${training?.slug}/deskripsi`}
          onRequestClose={() => setShowModalLock(false)}
        />
      )}
    </>
  );
}

export default CardYourTraining;
