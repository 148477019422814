import styled from 'styled-components';

export const ErrorText = styled.p`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => (props.lengkapiProfile ? '#F18F01' : '#d51919')};
  text-align: left;
  float: left;
  margin: 6px 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 11px;
  }
`;
