import React from 'react';
import { Container, Point, SectionUp, BannerClubhouse } from './styled';
import { ReactComponent as Checklist } from 'assets/svg/trainingDetails/checklis.svg';
import { ReactComponent as Circle } from 'assets/svg/PelatihanSaya/circle.svg';
import { formatLocaleDateShort } from 'utils/date';
import { convertDuration } from 'utils/duration';
import imageYGP from 'assets/png/ygp/img.png';
import { useHistory } from 'react-router-dom';
import { t } from 'hooks/intl/useLang';

const KelasDeskripsi = ({ item }) => {
  const history = useHistory();
  const imageYGPToBeEnter =
    'https://terampil-public.s3.ap-southeast-1.amazonaws.com/assets/banners/tobeEntre_TrainingPage_web+(2)+1.png';
  const imageYGPBeEnter =
    'https://terampil-public.s3.ap-southeast-1.amazonaws.com/assets/banners/Entre_TrainingPage_web+(1)+1.png';
  const imageBg =
    'https://terampil-public.s3.ap-southeast-1.amazonaws.com/assets/banners/banner_sebelum+ikuti_website+2.png';
  return (
    <Container>
      <SectionUp>
        <h2>{t('Deskripsi Training')}</h2>
        <div>
          <p>{convertDuration(item?.durations)}</p>
          <Circle />
          <p>{item?.category?.name}</p>
          <Circle />
          <p>{t('Tanggal Rilis')}: {formatLocaleDateShort(item?.created_at)}</p>
          <Circle className="none" />
          <p className="none">{t('Diperbarui')}: {formatLocaleDateShort(item?.updated_at)}</p>
        </div>
        {/* TODO:Be a replace */}
        <h6>{item?.description}</h6>
      </SectionUp>
      {item?.trainer?.slug === 'coach-yohanes-g-pauly' && (
        <>
          {' '}
          {/* <BannerClubhouse image={imageYGPToBeEnter} /> <BannerClubhouse image={imageYGPBeEnter} /> */}
          {/* <BannerClubhouse onClick={() => history.push('/training-to-be-enterepreneur')}>
            <img src={imageYGPToBeEnter} alt="" style={{ width: '100%' }} />
          </BannerClubhouse> */}
          {/* <BannerClubhouse onClick={() => history.push('/training-enterepreneur')}>
            <img src={imageYGPBeEnter} alt="" style={{ width: '100%' }} />
          </BannerClubhouse> */}
        </>
      )}
      {item?.trainer?.slug === 'syammas-p-syarbini' && (
        <BannerClubhouse onClick={() => history.push('/event')}>
          <img
            src={
              'https://terampil-public.s3.ap-southeast-1.amazonaws.com/assets/banners/event-syammas-3.png'
            }
            alt=""
            style={{ width: '100%' }}
          />
        </BannerClubhouse>
      )}
      <div>
        <h2>{t('Apa yang akan kamu pelajari')}: </h2>
        <Point>
          {item?.benefits?.map((item, i) => (
            <li key={i}>
              <Checklist /> <p>{item?.title}</p>
            </li>
          ))}
        </Point>
      </div>
    </Container>
  );
};

export default KelasDeskripsi;
