/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Container,
  AvatarImage,
  ReplyAvatarImage,
  TitleName,
  CardComent,
  ReplayDiv,
  ContainerAddReply,
  ContainerReplies,
  MainContainer,
} from './styled';
import { ReactComponent as Like } from 'assets/svg/trainingDetails/like.svg';
import { ReactComponent as MoreButton } from 'assets/svg/Bar.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/delete-icon.svg';
import { ReactComponent as EditIcon } from 'assets/svg/edit-profile.svg';
import { ReactComponent as LikeFilled } from 'assets/svg/trainingDetails/like-filled.svg';
import { ReactComponent as Circle } from 'assets/svg/PelatihanSaya/circle.svg';
import defaultUserPic from 'assets/svg/User Default.svg';
import { formatLocaleDateShort } from 'utils/date';
import Button from 'components/atoms/Button';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ADD_COMMENT_TRAINING,
  COMMENT_LIKED_TRAINING,
  COMMENT_UNLIKED_TRAINING,
  DELETE_COMMENT,
  UPDATE_COMMENT,
} from 'mutation/training';
import { GET_DETAIL_TRAINING, GET_TRAINING_COMMENTS } from 'queries/training';
import { getValue, setContext } from 'utils';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import RepliesComment from '../RepliesComment';
import { checkToken } from 'utils/checkToken';
import toast, { LoaderIcon } from 'react-hot-toast';
import Toast from 'components/atoms/Notification/Toast';
import { GET_USER_DETAIL } from 'queries/user';
import HapusKomentarModal from 'components/molecule/Modal/HapusKomentarModal';
import KonfirmasiHapusKomentar from 'components/molecule/Modal/KonfirmasiHapusKomentar';
import MentionExample from 'components/atoms/RichTextInput/Mention';
import { deserialize, serialize } from 'utils/serialize';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';

const ComentSection = ({
  item,
  id,
  index,
  selected,
  deleteCommentParent,
  userId,
  isFollowTraining,
  haveTrainingFreeTrail,
  commentUser,
}) => {
  const [isLiked, setIsLiked] = useState(item?.isLiked);
  const [showBoxReply, setShowBoxReply] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [moreAction, setMoreAction] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [tagUser, setTagUser] = useState(false);

  const onShowBoxReply = () => {
    setShowBoxReply(true);
    setTagUser(true);
  };

  const { handleSubmit, setValue, control, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      updateComment: deserialize(new DOMParser().parseFromString(commentUser, 'text/html').body),
      replyComment: [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ],
    },
  });

  useEffect(() => {
    if (commentUser) {
      // FIXED RICH TEXT
      const document = new DOMParser().parseFromString(commentUser, 'text/html');
      const deserial = deserialize(document.body);
      setValue(
        'updateComment',
        deserial.length > 0
          ? deserial
          : deserialize(new DOMParser().parseFromString(commentUser, 'text/html').body),
      );
    }
  }, [commentUser]);

  const tagUserComment = (username) => {
    setValue('replyComment', [
      {
        type: 'paragraph',
        children: [
          {
            type: 'mention',
            character: username,
            children: [
              {
                text: '',
              },
            ],
          },
          { text: '' },
        ],
      },
    ]);
  };

  const [isOpenHapusKomentarModal, setIsOpenHapusKomentar] = useState(false);
  const [isKonfirmasiHapusKomentarModal, setIsKonfirmasiHapusKomentar] = useState(false);
  const [showeditButton, setShoweditButton] = useState(false);

  const openModalKonfirmasiHapusKomentar = () => {
    setIsKonfirmasiHapusKomentar(true);
  };

  const [submitReplyComment, { loading: replyLoading }] = useMutation(ADD_COMMENT_TRAINING, {
    onCompleted: () => {
      reset();
      setShowBoxReply(false);
      toast(t('Balasan diskusi berhasil ditambah'));
    },
  });

  const authData = useSelector((state) => {
    return state.Auth;
  });

  const [likedComment] = useMutation(COMMENT_LIKED_TRAINING, {
    context: setContext(authData),
    onCompleted: () => toast(t('Diskusi berhasil disukai')),
    onError(err) {
      console.error(err.message);
    },
  });

  const [unlikedComment] = useMutation(COMMENT_UNLIKED_TRAINING, {
    context: setContext(authData),
    onCompleted() {},
    onError(err) {
      console.error(err.message);
    },
  });

  const { slug } = useParams();

  const { data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    variables: {
      input: slug,
    },
  });

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    context: setContext(authData),
    onCompleted: () => {
      toast(t('Komentar berhasil dihapus'));
      fetchDiscussion();
    },
    refetchQueries: [
      {
        query: GET_TRAINING_COMMENTS,
        context: setContext(authData),
        variables: {
          input: {
            trainingId: detailTraining?.Training?.id,
            limit: 9999,
            orderBy: 'created_at',
            // orderBy: selected,
            orderType: 'desc',
          },
        },
      },
    ],
    onError: (error) => console.error(error.message),
  });

  const [fetchDiscussion] = useLazyQuery(GET_TRAINING_COMMENTS, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        limit: 9999,
        // orderBy: "like",
        orderBy: 'created_at',
        orderType: 'desc',
      },
    },
  });

  const { data: getDiscussionComments } = useQuery(GET_TRAINING_COMMENTS, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        limit: 9999,
        // orderBy: selected,
        orderBy: 'created_at',
        orderType: 'desc',
      },
    },
  });

  //LIST OF USER THAT COMMENTED IN THIS TRAINING
  let saveUserName = [];
  let listOfUser = getDiscussionComments?.TrainingComments?.items.map((item, i) => {
    return saveUserName.push(item?.user?.fullname);
  });
  let listOfUserReplies = getDiscussionComments?.TrainingComments?.items.map((item, i) => {
    return item?.replies.map((items, i) => {
      saveUserName.push(items?.user?.fullname);
    });
  });
  let uniqueChars = [...new Set(saveUserName)];

  //REPLY COMMENT
  const onReplyComment = ({ replyComment }) => {
    submitReplyComment({
      context: setContext(authData),
      variables: {
        input: {
          trainingId: detailTraining?.Training?.id,
          comment: serialize({ children: replyComment }),
          replyTo: id,
        },
      },
      refetchQueries: [
        {
          query: GET_TRAINING_COMMENTS,
          context: setContext(authData),
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              limit: 9999,
            },
          },
        },
      ],
    });
  };

  // LIKED COMMENT
  const onLikedComment = () => {
    likedComment({
      context: setContext(authData),
      variables: {
        input: {
          commentId: id,
        },
      },
      refetchQueries: [
        {
          query: GET_TRAINING_COMMENTS,
          context: setContext(authData),
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              limit: 9999,
            },
          },
        },
      ],
    });
  };

  // UNLIKED COMMENT
  const onUnlikedComment = () => {
    unlikedComment({
      context: setContext(authData),
      variables: {
        input: {
          commentId: id,
        },
      },
      refetchQueries: [
        {
          query: GET_TRAINING_COMMENTS,
          context: setContext(authData),
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              limit: 9999,
            },
          },
        },
      ],
    });
  };

  //LIKED/UNLIKED COMMENT
  const setLiked = () => {
    if (!isLiked) {
      onLikedComment({
        context: setContext(authData),
        variables: {
          input: {
            commentId: id,
          },
        },
      });
    } else {
      onUnlikedComment({
        context: setContext(authData),
        variables: {
          input: {
            commentId: id,
          },
        },
      });
    }
  };

  //DELETE COMMENT
  const onDelete = () => {
    deleteCommentParent({
      context: setContext(authData),
      variables: {
        input: {
          commentId: item?.id,
        },
      },
      onCompleted: () => {
        <HapusKomentarModal
          isOpen={isOpenHapusKomentarModal}
          setShowModal={setIsOpenHapusKomentar}
          onRequestClose={() => setIsOpenHapusKomentar(false)}
        />;
      },
    });
  };

  const [editRepliesComment, { loading }] = useMutation(UPDATE_COMMENT, {
    onCompleted: () => {
      toast(t('Komentar berhasil diperbarui'));
      setValue(
        'updateComment',
        deserialize(new DOMParser().parseFromString(commentUser, 'text/html').body),
      );
      setShoweditButton(false);
    },
  });

  //UPDATE COMMENT
  const editCommentParent = ({ updateComment }) => {
    editRepliesComment({
      context: setContext(authData),
      variables: {
        input: {
          commentId: item?.id,
          comment: serialize({ children: updateComment }),
        },
      },
      refetchQueries: [
        {
          query: GET_TRAINING_COMMENTS,
          context: setContext(authData),
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              limit: 9999,
              // orderBy: selected,
              orderBy: 'created_at',
              orderType: 'desc',
            },
          },
        },
      ],
      onCompleted: () => {
        toast(t('Komentar berhasil diperbarui'));
        fetchDiscussion();
        setShoweditButton(!showeditButton);
      },
      onError: (error) => {
        console.error(error.message);
      },
    });
  };

  // const editDataParent = (value) => {
  //   setText(value);
  // };

  const { data: userTraining } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  //check the condition
  const isSubscribed = userTraining?.ProfileUser?.is_subscription;
  const isMyId = userTraining?.ProfileUser?.id;
  const isAvatar = userTraining?.ProfileUser?.avatar;

  const {
    headers: { token },
  } = setContext(authData);

  useEffect(() => {
    checkToken(token, setIsLoggedIn);
  }, [token]);

  const onShoweditButton = () => {
    setShoweditButton(!showeditButton);
  };

  //check free trial
  const isFreeTrial = userTraining?.ProfileUser?.User?.free_trial;

  //check the length of comment
  // const checkComment = replyComment.split('').length <= 0;

  //check condition an user
  const isUserFollowTraining =
    isLoggedIn && isFreeTrial === true && isFollowTraining && !haveTrainingFreeTrail;

  return (
    <>
      <Toast position="top-right" />
      <KonfirmasiHapusKomentar
        isOpen={isKonfirmasiHapusKomentarModal}
        setDelete={() => {
          onDelete();
          setIsKonfirmasiHapusKomentar(false);
          setIsOpenHapusKomentar(true);
        }}
        setShowModal={setIsKonfirmasiHapusKomentar}
        onRequestClose={() => setIsKonfirmasiHapusKomentar(false)}
      />
      <Container>
        {!showeditButton ? (
          <>
            <div className="container-profile">
              <AvatarImage image={item?.user?.avatar ? item?.user?.avatar : defaultUserPic} />
              {!checkMobile() ? (
                <div className="container-title">
                  <TitleName>{item?.user?.fullname}</TitleName>
                  <p>{formatLocaleDateShort(item?.created_at)}</p>
                </div>
              ) : (
                <div className="container-title">
                  <div className="container-user">
                    <TitleName>{item?.user?.fullname}</TitleName>
                    <p>{formatLocaleDateShort(item?.created_at)}</p>
                  </div>
                  <>
                    <MoreButton
                      className={isMyId === userId ? `show` : 'hide'}
                      style={{ cursor: 'pointer', transform: 'rotate(90deg)' }}
                      onClick={() => setMoreAction(!moreAction)}
                    />
                    <div className={moreAction ? 'more-action' : 'hide'}>
                      <div
                        id="edit-comment"
                        className="action-task"
                        onClick={() => {
                          onShoweditButton();
                          setMoreAction(!moreAction);
                          setShowContent(!showContent);
                        }}
                      >
                        <EditIcon />
                        <div>{t('Ubah')}</div>
                      </div>
                      <div
                        id="delete-comment"
                        className="action-task"
                        onClick={() => {
                          openModalKonfirmasiHapusKomentar();
                          setMoreAction(!moreAction);
                        }}
                      >
                        <DeleteIcon />
                        <div>{t('Hapus')}</div>
                      </div>
                    </div>
                  </>
                </div>
              )}
            </div>
            <div className="grid">
              <CardComent dangerouslySetInnerHTML={{ __html: item?.comment }} />
            </div>
            <MainContainer>
              <ReplayDiv>
                {isLoggedIn ? (
                  <>
                    {isLiked ? (
                      <LikeFilled
                        onClick={() => {
                          setIsLiked((prevLiked) => !prevLiked);
                          setLiked();
                        }}
                      />
                    ) : (
                      <Like
                        className="liked"
                        onClick={() => {
                          setIsLiked((prevLiked) => !prevLiked);
                          setLiked();
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Like className="disable-item" />
                )}
                <p className="like">{item?.like}</p>
                <Circle />
                <h6
                  className={
                    isFollowTraining && !haveTrainingFreeTrail ? `text-reply` : `disable-item`
                  }
                  onClick={() => {
                    onShowBoxReply();
                    setTimeout(() => {
                      tagUserComment(item?.user?.fullname);
                    }, 0);
                  }}
                >
                  {t('Balas')}
                </h6>
                <p className="like">{item?.total_replies ? item?.total_replies : 0} {t('balasan')}</p>
              </ReplayDiv>
              {!checkMobile() && (
                <>
                  {isUserFollowTraining ? (
                    <ContainerReplies className={isMyId === userId ? `` : 'hide-component'}>
                      <h6 onClick={openModalKonfirmasiHapusKomentar}>{t('Hapus')}</h6>
                      <Circle />
                      <h6 onClick={onShoweditButton}>Ubah</h6>
                    </ContainerReplies>
                  ) : isSubscribed ? (
                    <ContainerReplies className={isMyId === userId ? `` : 'hide-component'}>
                      <h6 onClick={openModalKonfirmasiHapusKomentar}>{t('Hapus')}</h6>
                      <Circle />
                      <h6 onClick={onShoweditButton}>Ubah</h6>
                    </ContainerReplies>
                  ) : null}
                </>
              )}
            </MainContainer>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(editCommentParent)}>
              <div className="container-profile">
                <AvatarImage image={item?.user?.avatar ? item?.user?.avatar : defaultUserPic} />
                {!checkMobile() ? (
                  <div className="container-title">
                    <TitleName>{item?.user?.fullname}</TitleName>
                    <p>{formatLocaleDateShort(item?.created_at)}</p>
                  </div>
                ) : (
                  <div className="container-title">
                    <div className="container-user">
                      <TitleName>{item?.user?.fullname}</TitleName>
                      <p>{formatLocaleDateShort(item?.created_at)}</p>
                    </div>
                    <>
                      <MoreButton
                        className={isMyId === userId ? `` : 'hide'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setMoreAction(!moreAction)}
                      />
                      <div className={moreAction ? 'more-action' : 'hide'}>
                        <div
                          id="edit-comment"
                          className="action-task"
                          onClick={() => {
                            onShoweditButton();
                            setMoreAction(!moreAction);
                            setShowContent(!showContent);
                          }}
                        >
                          <EditIcon />
                          <div>{t('Ubah')}</div>
                        </div>
                        <div
                          id="delete-comment"
                          className="action-task"
                          onClick={() => {
                            openModalKonfirmasiHapusKomentar();
                            setMoreAction(!moreAction);
                          }}
                        >
                          <DeleteIcon />
                          <div>{t('Hapus')}</div>
                        </div>
                      </div>
                    </>
                  </div>
                )}
              </div>
              <div className="grid">
                <CardComent>
                  {/* <Input
                  type="text"
                  defaultValue={commentUser ? commentUser : text}
                  name="text"
                  id="text"
                  autoComplete="off"
                  onChange={(e) => editDataParent(e.target.value)}
                ></Input> */}
                  <Controller
                    control={control}
                    name="updateComment"
                    render={({ onChange, value }) => (
                      <MentionExample
                        onChange={onChange}
                        listOfUsers={uniqueChars}
                        value={value}
                        type="plain"
                      />
                    )}
                  />
                </CardComent>
              </div>
              <MainContainer>
                {showContent && (
                  <ReplayDiv>
                    {isLoggedIn ? (
                      <>
                        {isLiked ? (
                          <LikeFilled
                            onClick={() => {
                              setIsLiked((prevLiked) => !prevLiked);
                              setLiked();
                            }}
                          />
                        ) : (
                          <Like
                            className="liked"
                            onClick={() => {
                              setIsLiked((prevLiked) => !prevLiked);
                              setLiked();
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <Like className="disable-item" />
                    )}
                    <p className="like">{item?.like}</p>
                    <Circle />
                    <h6
                      className={
                        isFollowTraining && !haveTrainingFreeTrail ? `text-reply` : `disable-item`
                      }
                      onClick={onShowBoxReply}
                    >
                      {t('Balas')}
                    </h6>
                    <p className="like">{item?.total_replies ? item?.total_replies : 0} balasan</p>
                  </ReplayDiv>
                )}
                {isUserFollowTraining ? (
                  <ContainerReplies>
                    <h6 className="action" onClick={onShoweditButton}>
                      {t('Batal')}
                    </h6>
                    <Circle />
                    {loading ? (
                      <LoaderIcon className="loading" />
                    ) : (
                      <Button small className="action-save">
                        {t('Simpan')}
                      </Button>
                    )}
                  </ContainerReplies>
                ) : isSubscribed ? (
                  <ContainerReplies>
                    <h6 className="action" onClick={onShoweditButton}>
                      {t('Batal')}
                    </h6>
                    <Circle />
                    {loading ? (
                      <LoaderIcon className="loading" />
                    ) : (
                      <Button small className="action-save">
                        {t('Simpan')}
                      </Button>
                    )}
                  </ContainerReplies>
                ) : null}
              </MainContainer>
            </form>
          </>
        )}
        {showBoxReply ? (
          <ContainerAddReply>
            <form onSubmit={handleSubmit(onReplyComment)}>
              <div className="container-profile">
                <ReplyAvatarImage image={isAvatar ? isAvatar : defaultUserPic} />
                {/* <InputField
                  dark={true}
                  className="input-comment"
                  placeholder="Masukan balasan diskusi kamu disini..."
                  value={replyComment}
                  onChange={(e) => setReplyComment(e.target.value)}
                  ref={register({
                    pattern: {
                      value: /^[\w\-\s]+$/,
                      message: 'Entered value does not match name format',
                    },
                  })}
                /> */}
                <Controller
                  control={control}
                  name="replyComment"
                  render={({ onChange, value }) => (
                    <MentionExample
                      onChange={onChange}
                      listOfUsers={uniqueChars}
                      value={value}
                      type="plain"
                    />
                  )}
                />
              </div>
              <div className="container-button">
                <Button medium className={`cancel-button`} onClick={() => setShowBoxReply(false)}>
                  {t('Batal')}
                </Button>
                {replyLoading ? (
                  <LoaderIcon className="loading" />
                ) : (
                  <Button
                    type="submit"
                    medium
                    disabled={!formState.isValid}
                    className={`${!formState.isValid ? 'disabled-button' : ''} none save-button`}
                  >
                    {t('Balas')}
                  </Button>
                )}
              </div>
            </form>
          </ContainerAddReply>
        ) : null}
        {item?.length <= 0 ? (
          <></>
        ) : getDiscussionComments && getDiscussionComments?.TrainingComments?.items[index] ? (
          getDiscussionComments?.TrainingComments?.items[index]?.replies.map((item, i) => {
            return (
              <RepliesComment
                isFollowTraining={isFollowTraining}
                selected={selected}
                deleteComment={deleteComment}
                item={item}
                userChildId={item?.user?.id}
                index={i}
                key={item?.id}
                onShowBoxReply={onShowBoxReply}
                tagUserComment={tagUserComment}
                commentUser={item?.comment}
              />
            );
          })
        ) : null}
      </Container>
    </>
  );
};

export default ComentSection;
