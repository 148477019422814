import React, { useEffect, useState } from 'react';
import {
  Container,
  ContainerQrCode,
  Title,
  DateTitle,
  CardDetails,
  PaymentName,
  PaymentDetails,
  Paragraf,
  TotalPayment,
  InputField,
} from './styled';
import gopay from 'assets/png/dompet/image 20.png';
import shopee from 'assets/png/dompet/image 19.png';
import dana from 'assets/png/dompet/image 36.png';
import { formatDateFullTime } from 'utils/date';
import { useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PAYMENT_INFO } from 'queries/pembayaran';
import { GET_USER_LAST_ORDER_ACTIVE, GET_USER_ORDERS_DETAIL } from 'queries/orders';
import { getValue, setContext } from 'utils';
import { useHistory } from 'react-router';
import { GET_NOTIFICATION, GET_NOTIFICATION_COUNT } from 'queries/notification';
import { t } from 'hooks/intl/useLang';

let intervalID = null;

const RincianDompetOnline = ({
  setPaymentMethod,
  setLoading,
  setDisableButton,
  setPhoneNumber,
  phoneNumber,
  invoice,
  isMobile,
  user,
}) => {
  const authData = useSelector((state) => state.Auth);
  const history = useHistory();
  const [dataOrder, setDataOrder] = useState();

  const { data: paymentInfo, loading: loadingPaymentInfo } = useQuery(GET_PAYMENT_INFO, {
    context: setContext(authData),
    variables: { invoice: invoice },
    onCompleted: () => {
      setLoading(false);
    },
  });

  const [getUserOrder, { data: dataOrderDetail, loading: loadingOrderDetail }] = useLazyQuery(
    GET_USER_ORDERS_DETAIL,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (userOrder) => {
        setLoading(false);
        if (userOrder.UserOrderDetail.status === 'Paid') {
          getUserOrderLastActive({
            context: setContext(authData),
          });
          getNotifications({
            context: setContext(authData),
            variables: {
              orderBy: 'created_at',
              orderType: 'desc',
            },
          });
          getNotificationCount({
            context: setContext(authData),
            variables: {
              slug: getValue(authData, 'data.slug'),
            },
          });
          history.push(`/detail-riwayat-pembayaran/${userOrder.UserOrderDetail.invoice_number}`);
        }
      },
    },
  );

  const [getUserOrderLastActive] = useLazyQuery(GET_USER_LAST_ORDER_ACTIVE);
  const [getNotifications] = useLazyQuery(GET_NOTIFICATION);
  const [getNotificationCount] = useLazyQuery(GET_NOTIFICATION_COUNT);

  const checkChange = () => {
    intervalID = setInterval(() => {
      getUserOrder({
        context: setContext(authData),
        variables: { invoice: invoice },
      });
    }, 5000);
  };

  useEffect(() => {
    getUserOrder({
      context: setContext(authData),
      variables: { invoice: invoice },
    });
    if (loadingPaymentInfo && loadingOrderDetail) {
      setLoading(true);
    }
    if (dataOrderDetail) {
      if (!dataOrder) {
        setDataOrder(dataOrderDetail);
        setPaymentMethod(dataOrderDetail?.UserOrderDetail?.payment_method);
      }
      if (dataOrderDetail?.UserOrderDetail?.status === 'Pending') {
        if (intervalID) clearInterval(intervalID);
        checkChange();
      }
      setLoading(false);
      if (phoneNumber.length >= 8) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [dataOrderDetail, setLoading, phoneNumber, setDisableButton]);

  let dataOrdered = {};
  switch (dataOrder?.UserOrderDetail?.payment_method) {
    case 'gopay':
      dataOrdered = {
        ...dataOrdered,
        name: 'Gopay',
        qr_name: 'Gojek',
        logo: gopay,
      };
      break;
    case 'shopeepay':
      dataOrdered = {
        ...dataOrdered,
        name: 'Shopeepay',
        qr_name: 'Shopee',
        logo: shopee,
      };
      break;
    case 'dana':
      dataOrdered = {
        ...dataOrdered,
        name: 'DANA',
        qr_name: 'Dana',
        logo: dana,
      };
      break;
    case 'dana_recurring':
      dataOrdered = {
        ...dataOrdered,
        name: 'DANA',
        qr_name: 'Dana',
        logo: dana,
      };
      break;
    default:
      break;
  }

  switch (dataOrder?.UserOrderDetail?.subscription?.subscription_type) {
    case 'Monthly':
      dataOrdered = {
        ...dataOrdered,
        sub: 'Bulanan',
      };
      break;
    case 'Semester':
      dataOrdered = {
        ...dataOrdered,
        sub: 'Semesteran',
      };
      break;
    case 'Yearly':
      dataOrdered = {
        ...dataOrdered,
        sub: 'Tahunan',
      };
      break;
    default:
      dataOrdered = {
        ...dataOrdered,
        sub: 'Training',
      };
      break;
  }

  return (
    <Container>
      {dataOrder && (
        <>
          <Title>{t('Batas Akhir Pembayaran')}</Title>
          <DateTitle>{formatDateFullTime(dataOrder.UserOrderDetail.due_date)}</DateTitle>
          <CardDetails>
            <PaymentName>
              <h2>{dataOrdered.name}</h2>
              <img src={dataOrdered.logo} alt={dataOrdered.name} className="logo-dompet-online" />
            </PaymentName>
            <PaymentDetails>
              <Paragraf>{t('Nomor Tagihan')}</Paragraf>
              <h4>{dataOrder.UserOrderDetail.invoice_number.split('INV').join('/INV/')}</h4>
              <Paragraf>{t('Paket yang Dibeli')}</Paragraf>
              <h4>{dataOrder?.UserOrderDetail?.order_name}</h4>
              <div className="rincian">
                <div>
                  <Paragraf>{t('Total Pembayaraan')}</Paragraf>
                  <TotalPayment>
                    Rp {new Intl.NumberFormat('id-Id').format(dataOrder?.UserOrderDetail?.price)}
                  </TotalPayment>
                </div>
              </div>
            </PaymentDetails>
          </CardDetails>
          {isMobile ? (
            <ContainerQrCode>
              <Paragraf text="left">{t('Masukkan Nomor HP dari akun')} {dataOrdered.name}</Paragraf>
              <InputField
                name="nama"
                type="tel"
                dark
                placeholder={t("Nomor HP")}
                defaultValue={user && '0' + user?.ProfileUser?.User?.phone_number}
                onChange={(val) => {
                  setPhoneNumber(val.target.value);
                }}
              />
            </ContainerQrCode>
          ) : (
            <>
              {paymentInfo?.PaymentInfo?.qr_code && (
                <ContainerQrCode>
                  {' '}
                  <Paragraf text="center">
                    {t('Scan QR Code berikut dengan aplikasi')} {dataOrdered.qr_name}
                  </Paragraf>
                  <img className="qr-code" src={paymentInfo?.PaymentInfo?.qr_code} alt="qr-code" />
                </ContainerQrCode>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default RincianDompetOnline;
