import ButtonStyled from 'components/atoms/Button/';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
`;
export const Title = styled.h6`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;

  text-align: center;

  color: #fdfdfd;

  margin: 16px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16px;
    line-height: 24px;
    color: #00debf;
  }
`;

export const Separator = styled.div`
  background: #626262;

  width: 100%;
  height: 1px;
`;

export const Description = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  text-align: center;

  color: #fdfdfd;

  margin-top: 24px;
  margin-bottom: 32px;

  & + div {
    margin-bottom: 32px;

    display: flex;
    justify-content: space-between;

    padding: 0 32px;
  }
`;

export const Button = styled(ButtonStyled)`
  width: 100%;
  margin: 0;
`;
