import styled from 'styled-components';

export const Container = styled.section`
  padding: 13px 100px;
  background-color: #141414;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xxs} {
    padding: 8px;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
`;
