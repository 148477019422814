import styled from 'styled-components';

export const Container = styled.section`
  padding: 0 0px 70px 0px;
  text-align: center;
  margin: 0 auto;
  background-color: ${(props) => (props.home ? '#141414' : '#000004')};

  .shaka-play-button {
    display: none;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => (props.home ? '0' : '8rem')};
    flex-direction: row-reverse;
  }

  @media screen and ${({ theme }) => theme.breakpoints.md} {
    padding: 0px 40px;

    .content {
      margin-bottom: 4rem;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 0px 24px;
    .content {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 2rem;
    }
  }
`;

export const Title = styled.h2`
  font-family: Archivo;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fdfdfd;
  padding: 30px 0;
  margin: ${(props) => (props.home ? '0' : '5rem')} 0 0;

  @media screen and ${({ theme }) => theme.breakpoints.md} {
    margin: 1rem;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: ${(props) => (props.home ? '0' : '1rem')} 0 0;
    padding: 15px 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 20px;
  }
`;

export const ChatContainer = styled.div`
  width: 55%;
  height: auto;
  padding-left: 30px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 50px;
  padding: 0 80px;

  /* @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0px 70px;
  } */
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 0px;
    margin-top: 0;
  }
`;

export const ContainerSlide = styled.div`
  position: relative;
  margin-top: 2rem;
  padding-bottom: 50px;
  margin-bottom: ${(props) => (props.home ? '0' : '1rem')};

  @media screen and ${(props) => props.theme.breakpoints.md} {
    margin-top: 12px;
    padding-bottom: 10px;
    .slider {
      display: none;
    }
  }
`;

export const ButtonSlider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: none;
  }
`;
