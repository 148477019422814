import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Kategori } from './styled';
import { CategoryLoading } from 'components/atoms/Loading';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
import TerampilLogo from 'assets/png/logo-terampil.png';
import RedirectModalToMobile from 'components/molecule/Modal/RedirectToMobile';
import { t } from 'hooks/intl/useLang';

const NotLogin = ({ loading, showCategory, setShowCategory, categoryData, setIsOpen }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Link to="/" className="header-link pt-5">
        <img
          src={TerampilLogo}
          alt="logo-terampil"
          style={{ width: '50%' }}
          className="button py-5"
        />
      </Link>
      <div
        style={{ color: '#00DEBF' }}
        className="header-link py-5 mb-3"
        onClick={() => {
          setOpenModal(true);
          setIsOpen(false);
        }}
      >
        {t('Masuk / Daftar')}
      </div>
      <Kategori className="py-5 pb-3 mb-3">
        <div onClick={() => setShowCategory(!showCategory)}>
          <h3>{t('Kategori')}</h3>{' '}
          <ArrowIcon className={showCategory ? 'arrow-icon-show' : 'arrow-icon-hidden'} />
        </div>
        {showCategory &&
          loading &&
          [...Array(3)].map((data, idx) => <CategoryLoading className="mobile py-5" key={idx} />)}
        {showCategory &&
          categoryData?.Categories?.items?.map((data) => {
            return (
              <Link
                to={`/trainings?category=${data.id}&page=1`}
                className="header-link-kategori py-5"
                key={data.id}
              >
                <div>
                  <img src={data.icon_default} alt={data.name} />
                </div>
                {data.name}
              </Link>
            );
          })}
      </Kategori>
      <Link to="https://prakerja.terampil.com" className="header-link py-5">
        Prakerja
      </Link>
      <Link to="/jadi-trainer" className="header-link py-5">
        {t('Jadi Trainer')}
      </Link>
      <RedirectModalToMobile isOpen={openModal} onRequestClose={() => setOpenModal(false)} />
    </>
  );
};

export default NotLogin;
