import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/index';

export const Container = styled.section`
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
  /* padding: 40px 12px 50px 50px; */

  background-color: ${(props) => props.theme.backgroundColor.primary};

  h2 {
    margin-top: 30px;

    ${(props) => props.theme.font.primary.title};
    font-size: 20px;
    text-align: left;
    margin: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 40px 0 30px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 40px 30px 30px 30px;
    background-color: #232323;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 16px;
  }
`;

export const FlexCard = styled.div`
  /* background-color: blue; */
  // width: 100%;
  // display: grid;
  // gap: 24px;
  // margin-top: 32px;
  /* justify-content: center; */
  // margin-bottom: 30px;
  // /* height: 500px; */

  // @media screen and ${(props) => props.theme.breakpoints.sm} {
  //   gap: 8px;
  // }

  // modify by Agung //
  padding-left: 80px;
  padding-right: 80px;
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  row-gap: 50px;
  column-gap: 40px;
  margin-top: 32px;
`;

export const StartSubscriptionStyled = styled.div`
  width: 100%;

  box-sizing: border-box;
  padding: 40px 12px 50px 50px;

  background-color: ${(props) => props.theme.backgroundColor.primary};

  h2 {
    margin-top: 30px;

    ${(props) => props.theme.font.primary.title};
    font-size: 20px;
    text-align: left;
    margin: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 40px 0 30px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 40px 30px 30px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 16px;
  }
`;

export const Title = styled.h1`
  margin: 24px 0 16px;
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: ${(props) => (props.isAuth ? 'left' : 'center')};
  /* identical to box height, or 125% */

  color: #fdfdfd;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 32px 0 16px;
    font-size: 20px;
  }
`;

export const SubTitle = styled.p`
  margin: 0;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 24px;
`;

export const PriceMenu = styled.div`
  display: flex;

  & :last-child {
    margin-right: 0;
  }
`;

export const ButtonContainer = styled.div`
  position: sticky;
  padding: 0;
  width: 100%;
  height: fit-content;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    position: fixed;
    padding: 12px 16px;
    left: 0;
    bottom: 00;
    box-sizing: border-box;
    background-color: #000004;
  }

  .button-next {
    width: 100%;
    margin: 24px 0 0;
    height: 50px;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      margin: 0;
    }
  }
  .btn-cancel-subs {
    display: none;
    width: 100%;
    margin: 24px 0 0;
    height: 50px;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      display: block;
      border: 1px solid #c4c4c4;
      color: #c4c4c4;
      background: transparent;
      margin: 0;
    }
  }
`;

export const DetailBenefitMobile = styled.div`
  display: none;

  @media only screen and ${(props) => props.theme.breakpoints.md} {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      svg {
        margin-right: 10px;
      }
    }
  }
`;

export const TextBenefit = styled.h3`
  font-family: Inter;
  margin: 0;
  font-style: normal;
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  text-align: left;
  color: ${(props) => (props.bold ? '#fdfdfd' : '#c4c4c4')};

  span {
    font-size: 20px;
    font-weight: 600;
    color: #f1902b;
  }
`;

export const Button = styled(ButtonStyled)`
  height: 45px;
  margin: 0;
  width: 100%;

  border: 1px solid
    ${(props) => (props.disabled ? '#626262' : props.outline ? '#00debf' : '#141414')};
  margin: 0;
  align-self: center;
  background-color: ${(props) =>
    props.outline ? 'inherit' : props.disabled ? '#626262' : '#00debf'};
  color: ${(props) =>
    props.outline && !props.disabled
      ? '#00debf'
      : props.disabled && props.outline
      ? '#626262'
      : '141414'};
`;

export const Icon = styled.img`
  padding-top: 4px;
  align-self: flex-start;

  @media only screen and ${(props) => props.theme.breakpoints.lg} {
    width: 20px;
    margin-right: 10px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    width: unset;
  }
`;
