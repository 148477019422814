import styled from 'styled-components';

export const ContainerTitle = styled.div`
  position: relative;
  padding-right: 60px;
  display: none;
  margin: 0;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    display: inherit;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0;
  }
`;

export const Title = styled.h2`
  ${(props) => props.theme.font.primary.title};
  font-size: 20px;
  text-align: left;
  line-height: 24px;
  margin-top: 0;

  span {
    padding: 4px;
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    color: #141414;
    background-color: ${(props) => props.theme.color.primary};
    border-radius: 3px;
    margin-top: 8px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16px;
    line-height: 24px;

    span {
      max-width: 40%;
      display: block;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
    }
  }
`;

export const Paragraf = styled.p`
  ${(props) => props.theme.font.secondary.subtitle};
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

export const ButtonIkutiTraining = styled.button`
  display: none;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    height: 45px;
    display: block;
    background-color: #f18f01;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    text-align: center;

    color: #fdfdfd;
    border-radius: 5px;
    border: none;
  }
`;
