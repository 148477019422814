import styled from 'styled-components';
import { mq } from 'assets/styles/theme';

export const YourTrainingStyled = styled.section`
  width: 100%;
  background-color: #141414;
  padding-top: 50px;

  display: ${(props) => (props.data ? '' : 'none')};

  ${mq({
    padding: [null, null, '20px 0', '20px 0', '16px 0'],
  })}
`;

export const YourTrainingSliderStyled = styled.section`
  width: 100%;
  background-color: #141414;

  ${mq({
    padding: [null, null, '20px 0', '20px 0', '16px 0'],
  })}

  .button-slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slider {
    position: relative;
    margin-bottom: 8px;
  }

  .overlay {
    overflow-x: scroll;
  }

  .inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    padding-right: 16px;
    box-sizing: border-box;
    width: fit-content;
  }
`;
