import styled from 'styled-components';

export const Head = styled.div`
  font-family: Archivo;
  h5 {
    font-size: 30px;

    margin: 0;
    margin-bottom: 16px;

    color: ${({ theme }) => theme.color.primary};
  }

  h6 {
    font-size: 18px;
    margin: 0;
    margin-bottom: 70px;
  }

  .danger {
    color: #f18f01;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 0 16px;

    h5,
    h6 {
      font-size: 16px;
    }
  }
`;

export const Detail = styled.div`
  font-family: Archivo;

  display: grid;
  justify-items: center;

  p {
    font-size: 18px;

    margin-bottom: 57px;

    color: #c4c4c4;
  }

  span {
    font-size: 20px;
    color: ${({ theme }) => theme.color.primary};
  }

  .text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #c4c4c4;

    width: 60%;

    margin-bottom: 32px;
  }

  .danger {
    color: #f18f01;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 0 16px;
    p {
      font-size: 14px;
    }

    span {
      font-size: 16px;
    }

    text {
      font-size: 12px;
      width: 100%;
    }
  }
`;

export const Footer = styled.div`
  padding: 16px 24px;

  border-top: 1px solid #373737;

  .button {
    float: right;
    font-size: 14px;
    height: 40px;
    width: 140px;
  }

  .secondary {
    border: 1px solid #c4c4c4;
    background: transparent;
    color: #c4c4c4;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: flex;
    flex-direction: row-reverse;

    .button {
      border-radius: 4px;
    }
    padding: 8px 8px;
  }
`;

export const LoadingWrapper = styled.div`
  &.wrapper-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-icon {
      width: 100px;
      height: 100px;
      margin-top: 50px;
    }

    .loading-loadmore {
      width: 20px;
      height: 20px;
    }
  }
`;
