import React from 'react';
import { Contianer, CardBottom } from './styled';
// import { ReactComponent as Star } from 'assets/svg/trainingDetails/star.svg';
const BelumAdaDiskusi = ({ selected }) => {
  return (
    <Contianer>
      <CardBottom>
        <h4>Belum Ada Diskusi</h4>
        <p>
          Belum ada diskusi untuk training ini. Jadilah orang pertama yang memberikan diskusi untuk
          training ini
        </p>
      </CardBottom>
    </Contianer>
  );
};

export default BelumAdaDiskusi;
