import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/index';

export const Container = styled.div`
  position: relative;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    flex: 1;
  }
  .tooltip {
    width: 230px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */

    color: #c4c4c4;
  }
`;

export const FlagDiscount = styled.div`
  position: absolute;
  background-color: ${({ blue }) => (blue ? '#00E27D' : '#F5A52F')};
  width: 130px;
  top: 75px;
  right: -60px;
  height: 157px;
  box-sizing: border-box;
  border-radius: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-size: 12px;
  line-height: 30px;
  /* or 250% */
  z-index: 1;
  text-align: center;
  padding: 14px 0;

  color: #fffff;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    display: none;
  }

  .text-head {
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    /* or 100% */
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
    margin: 0;
  }

  .text-large {
    font-weight: 900;
    font-size: 64px;
    line-height: 24px;
    /* or 38% */
    text-align: center;
    color: #ffffff;
    margin: 20px 0 0 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

    span {
      font-size: 20px;
      line-height: 32px;
    }
  }
`;

export const Icon = styled.img`
  padding-top: 4px;
  align-self: flex-start;
  cursor: pointer;

  @media only screen and ${(props) => props.theme.breakpoints.lg} {
    width: 16px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    width: unset;
  }
`;

export const ContainerBottom = styled.div`
  position: relative;
  // max-width: 280px;
  background-color: #04cf7e;
  width: 380px;
  height: ${(props) => (props.noButton ? '' : '480px')};
  box-sizing: border-box;
  padding: 25px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 31px;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    display: none;
  }
`;

export const ContainerPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  bottom: 0;
  left: 0;
  border-radius: 31px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    display: none;
  }
`;

export const ContinerText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;

  .icon-hover {
    cursor: pointer;
    position: relative;
    margin: 0;
    /* z-index: 10; */

    /* :hover::after {
      content: 'Kata sandi minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, simbol dan angka.';
      background-color: whitesmoke;
      width: 100%;
      border: 1px solid red;
      height: auto;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      border-radius: 5px;
      color: #d51919;
      position: absolute;
      bottom: -48px;
      left: 0;
      z-index: 99999;
      box-sizing: border-box;
      padding: 8px;
    } */
  }
`;

export const WrapperTop = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Line = styled.hr`
  width: 50%;
  border: 0.5px solid #ffffff;
  margin-bottom: 16px;
`;

export const DialogBox = styled.div`
  background-color: #202020;
  border-radius: 3px;
  width: 230px;
  position: absolute;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  padding: 12px;
  /* or 150% */
  /* z-index: 1; */

  color: #c4c4c4;
  right: -20px;
  bottom: -29px;
`;

export const WrapperSub = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperPriceDisc = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background-color: #b50000cc;
  padding: 0px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
`;

export const TextBenefit = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 'Bold' : 'normal')};
  font-size: ${(props) => (props.bold ? '18px' : '14px')};
  line-height: 21px;
  /* or 150% */
  margin: 0;
  text-align: left;
  color: ${(props) => (props.bold ? '#fdfdfd' : '#c4c4c4')};

  .bold-price {
    font-size: 12px;
    font-weight: 400;
    color: #fdfdfd;
    margin: 0;
  }
  .bold-top {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 8px;
    margin-top: 0px;
    text-align: center;
    line-height: 36px;
  }
  .bold-top.sub {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: -5px;
    text-align: center;
    max-width: 30ch;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .bold-price.bottom {
    font-size: 32px;
    font-weight: 700;
    color: #000;
    margin-bottom: 16px;
  }
  .disc-price {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    line-height: 0px;
    text-decoration: line-through;
  }
  span {
    color: #00debf;
  }
`;

export const Button = styled(ButtonStyled)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 80%;
  font-family: Inter;
  font-style: normal;
  border: 2px solid
    ${(props) => (props.disabled ? '#626262' : props.outline ? '#04CCB0' : '#04CCB0')};
  border-radius: 35px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  align-self: center;
  background-color: ${(props) =>
    props.outline ? 'inherit' : props.disabled ? '#626262' : '#04CCB0'};
  color: ${(props) =>
    props.outline && !props.disabled
      ? '#04CCB0'
      : props.disabled && props.outline
      ? '#626262'
      : '#ffffff'};

  &:hover {
    background-color: ${(props) => (props.disabled ? 'inherit' : props.outline ? '' : '#00debf')};
  }
`;
