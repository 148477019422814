import styled from 'styled-components';

export const PopupModal = styled.div`
  width: 100vw;
`;

export const HeaderTitle = styled.span`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0 16px;
  color: #fdfdfd;
`;

export const Container = styled.div`
  background: #141414;
  padding: 20px 100px;

  .close-icon {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    z-index: 999999;
  }

  .wrap-headertitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 8px 34px 8px;
  }

  .wrap-headercontent {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  span.header-close {
    font-size: 16px;
  }

  span.header-save {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #00debf;
    cursor: pointer;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    background: #141414;
    padding: 20px 5px;
  }
`;

export const Title = styled.h6`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  margin: 16px 270px;
  text-align: left;
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 16px 10px;
  }
`;

export const Separator = styled.div`
  background: #626262;

  width: 1px;
  height: auto;
`;

export const Description = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  text-align: center;

  color: #fdfdfd;

  margin-top: 24px;
  margin-bottom: 32px;

  & + div {
    margin-bottom: 32px;

    display: flex;
    justify-content: space-between;

    padding: 0 32px;
  }
`;

export const CardProfile = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
  }
`;

export const Profile = styled.div`
  display: flex;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    border-bottom: 1px solid #626262;
    padding: 12px;
  }
`;

export const TitleTraining = styled.div`
  width: 200px;
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fdfdfd;
  text-align: left;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
    font-family: Archivo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
`;

export const Rating = styled.div`
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;

  .give-rating {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #c4c4c4;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: flex;
    width: auto;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #626262;
    padding: 0 12px;
  }
`;

export const Wrapper = styled.div`
  margin: 20px 270px;
  background: #202020;
  border-radius: 5px;
  padding: 24px 24px 0px 24px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 10px 10px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0;
  }
`;

export const Avatar = styled.div`
  background-image: url('${(props) => props.image}');
  width: 97px;
  height: 97px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 40px;
    height: 40px;
  }
`;

export const AboutTrainer = styled.div`
  text-align: left;
  display: block;
  margin: 5px 16px;
`;

export const ProfessionTrainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #c4c4c4;
`;

export const InputContainer = styled.div`
  .input-comment {
    color: #fdfdfd;
    @media screen and ${(props) => props.theme.breakpoints.xs} {
      border: none;
    }
  }

  div {
    display: flex;
    justify-content: flex-end;

    .loading-icon {
      width: 30px;
      height: 30px;
      margin: 11px 0px;
    }
  }

  button {
    width: 102px;
    height: 40px;
    margin: 6px 0px 6px 24px;
  }
`;
