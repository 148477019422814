import { gql } from '@apollo/client';

export const GET_BLOG_DETAILS = gql`
  query GET_BLOG_DETAILS($slug: String) {
    Blog(slug: $slug) {
      id
      tag
      title
      slug
      image_headline
      publish_date
      body
      status
      read_count
      text_button
      link_button
      created_at
      category_blog_id
    }
  }
`;

export const GET_ALL_BLOGS = gql`
  query GET_BLOGS($limit: Int) {
    Blogs(input: { limit: $limit }) {
      total
      items {
        id
        image_headline
        tag
        title
        slug
        publish_date
        text_button
        link_button
        Category {
          name
        }
      }
    }
  }
`;

export const BLOGS_HIGHLIGHTS = gql`
  query blogHighlights {
    BlogHighlights {
      status
      total
      statusText
      items {
        blog_id
        Blog {
          id
          title
          Category {
            name
          }
          image_headline
          tag
          slug
          publish_date
          body
          status
          read_count
          text_button
          link_button
        }
        order
      }
    }
  }
`;

export const GET_BLOGS_PER_CATEGORIES = gql`
  query BLOG_PERCAT($limit: Int, $categoryBlogId: Int) {
    Blogs(input: { limit: $limit, categoryBlogId: $categoryBlogId }) {
      items {
        title
        id
        image_headline
        tag
        title
        slug
        publish_date
        text_button
        link_button
        Category {
          name
        }
      }
    }
  }
`;

export const ALL_PAGES_BLOGS = gql`
  query ALL_BLOGS_PAGES($input: BlogRequest, $id: Int) {
    CategoryBlog(id: $id) {
      name
    }
    Blogs(input: $input) {
      filtered
      items {
        title
        tag
        read_count
        slug
        publish_date
        status
        id
        image_headline
        Category {
          name
        }
      }
    }
  }
`;
