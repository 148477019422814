/* DEFAULT VALUE */
export const checkRatedTraining = (validId, rated, editRated, checkMyRating) => {
  if (validId === false) {
    return rated;
  } else if (validId === true) {
    return editRated ? editRated : parseInt(checkMyRating);
  }
};

export const checkReviewTraining = (validId, review, editReview, checkMyReview) => {
  if (validId === false) {
    return review;
  } else if (validId === true) {
    return editReview ? editReview : checkMyReview.toString();
  }
};

export const checkRatedTrainer = (
  isGiveTrainerReview,
  ratedTrainer,
  editRatedTrainer,
  checkTrainerRating,
) => {
  if (isGiveTrainerReview === true) {
    return ratedTrainer;
  } else if (isGiveTrainerReview === false) {
    return editRatedTrainer ? editRatedTrainer : checkTrainerRating;
  }
};

export const checkReviewTrainer = (
  isGiveTrainerReview,
  reviewTrainer,
  editReviewTrainer,
  checkTrainerReview,
) => {
  if (isGiveTrainerReview === true) {
    return reviewTrainer;
  } else if (isGiveTrainerReview === false) {
    return editReviewTrainer ? editReviewTrainer : checkTrainerReview;
  }
};

/* onChange */

// TRAINING
export const onChangeRatingTraining = (validId, rating, setRating, editRating, setEditRating) => {
  if (validId === false) {
    return (rating) => setRating(rating);
  } else if (validId === true) {
    return (editRating) => setEditRating(editRating);
  }
};

export const onChangeReviewTraining = (validId, event, setReviews, setEditReviews) => {
  if (validId === false) {
    return setReviews(event.target.value);
  } else if (validId === true) {
    return setEditReviews(event.target.value);
  }
};

// TRAINER
export const onChangeRatingTrainer = (
  isGiveTrainerReview,
  rating,
  setRating,
  editRating,
  setEditRating,
) => {
  if (isGiveTrainerReview === true) {
    return (rating) => setRating(rating);
  } else if (isGiveTrainerReview === false) {
    return (editRating) => setEditRating(editRating);
  }
};

export const onChangeReviewTrainer = (isGiveTrainerReview, event, setReviews, setEditReviews) => {
  if (isGiveTrainerReview === true) {
    return setReviews(event.target.value);
  } else if (isGiveTrainerReview === false) {
    return setEditReviews(event.target.value);
  }
};
