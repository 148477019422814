import React from 'react';
import {
  Container,
  BoxContainer,
  FlexContain,
  HeadNotification,
  MainNotification,
  EmptyState,
} from './styled';
import { dateCompare } from 'hooks/FormatDateNotif';
import { MARK_READ_NOTIFICATION, MARK_ALL_NOTIFICATION } from 'mutation/notification';
import { useMutation } from '@apollo/client';
import { GET_NOTIFICATION, GET_NOTIFICATION_COUNT } from 'queries/notification';
// asset
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as GiftIcon } from 'assets/svg/gift.svg';
import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import { formatShortFullDate } from 'utils/date';
import { t } from 'hooks/intl/useLang';

const NotificationMenu = ({
  data: notificationData = [],
  showNotif,
  getNotificationCount,
  notif = [],
}) => {
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const [markOneById] = useMutation(MARK_READ_NOTIFICATION, {
    context: setContext(authData),
    refetchQueries: [
      {
        query: GET_NOTIFICATION,
        context: setContext(authData),
        variables: {
          orderBy: 'created_at',
          orderType: 'desc',
        },
      },
      {
        query: GET_NOTIFICATION_COUNT,
        context: setContext(authData),
        variables: {
          slug: getValue(authData, 'data.slug'),
        },
      },
    ],
    onCompleted: () => getNotificationCount(),
    onError: (err) => console.error(err.message),
  });

  const [markAllNotif] = useMutation(MARK_ALL_NOTIFICATION, {
    context: setContext(authData),
    refetchQueries: [
      {
        query: GET_NOTIFICATION,
        context: setContext(authData),
        variables: {
          orderBy: 'created_at',
          orderType: 'desc',
        },
      },
      {
        query: GET_NOTIFICATION_COUNT,
        context: setContext(authData),
        variables: {
          slug: getValue(authData, 'data.slug'),
        },
      },
    ],
    onCompleted: () => getNotificationCount(),
    onError: (err) => console.error(err.message),
  });

  const markAsRead = (id, link) => {
    if (link) window.location.assign(link);
    markOneById({
      variables: {
        input: {
          type: 'One',
          notification_id: id,
        },
      },
    });
  };

  const markAll = () => {
    markAllNotif({
      variables: {
        input: {
          type: 'All',
        },
      },
    });
  };

  return (
    <Container>
      <HeadNotification>
        <h5>{t('Notifikasi')}</h5>
        <CloseIcon onClick={showNotif} />
      </HeadNotification>
      <MainNotification>
        <div className="notification-container">
          {notificationData.length === 0 && (
            <EmptyState>
              <h4>{t('Tidak Ada Notifikasi')}</h4>
              <p>{t('Tidak ada notifikasi untuk kamu.')}</p>
            </EmptyState>
          )}
          {notif?.map((item, i) => {
            return (
              <BoxContainer isread={item?.is_read} onClick={() => markAsRead(item?.id, item?.link)}>
                <FlexContain>
                  <div>
                    <GiftIcon />
                  </div>
                  <div className="box-title">
                    <p className="primary">
                      {item?.notification_title} <strong className="button"></strong>
                    </p>
                    <p className="secondary">
                      {item.notification_text} <strong className="button"></strong>
                    </p>
                  </div>
                  <p className="date">
                    {formatShortFullDate(item?.created_at || '2021-02-11T05:04:28.258Z')}
                  </p>
                </FlexContain>
              </BoxContainer>
            );
          })}
          {/* {notificationData.map((data, i) => {
            return (
              <>
                <div className="notification-date">
                  <h6>{dateCompare(data.date)}</h6>
                  {i === 0 && (
                    <p className="secondary" onClick={markAll}>
                      Tandai semua dibaca
                    </p>
                  )}
                </div>
                {data.notif.map((notif) => {
                  return (
                    <>
                      <div
                        className={`notification-item ${notif.is_read && 'disabled'}`}
                        key={notif.id}
                        onClick={() => markAsRead(notif.id, notif.link)}
                      >
                        <GiftIcon />
                        <p className="primary">
                          {notif.notification_title} <strong className="button"></strong>
                        </p>
                      </div>
                      <p className="secondary">
                        {notif.notification_text} <strong className="button"></strong>
                      </p>
                    </>
                  );
                })}
              </>
            );
          })} */}
        </div>
      </MainNotification>
    </Container>
  );
};

export default NotificationMenu;
