import React from 'react';
import { Content, Title, Paragraf } from './styled';

function SyaratKetentuan() {
  return (
    <>
      <Content>
        <Paragraf>
          Anda setuju bahwa dengan mengklik “Mulai Berlangganan”, “Mulai Training Gratis”, “Daftar”,
          atau yang serupa dengan itu, mendaftar, mengakses, atau menggunakan layanan kami, Anda
          setuju untuk terikat secara hukum dalam kontrak dengan Terampil (meskipun Anda menggunakan
          Layanan kami mewakili suatu perusahaan). Jika Anda tidak setuju dengan kontrak ini
          (“Kontrak” atau “Perjanjian Pengguna”), jangan klik “Mulai Berlangganan” (atau yang serupa
          dengan itu) dan jangan mengakses atau menggunakan Layanan apa pun dari kami dengan cara
          lain. Jika Anda ingin menghentikan kontrak ini. Anda bisa melakukannya kapan saja dengan
          menutup akun dan tidak lagi mengakses atau menggunakan Layanan kami.
        </Paragraf>
        <Paragraf>
          Sebagai Pengunjung atau Anggota Layanan kami, pengumpulan, penggunaan, dan pembagian data
          pribadi Anda tunduk pada Syarat & Ketentuan dan Kebijakan Privasi beserta pembaruannya.
        </Paragraf>
        <Paragraf>
          Ketika Anda mendaftar dan bergabung dengan Terampil, Anda menjadi Anggota. Jika Anda
          memilih untuk tidak mendaftar ke Layanan kami, Anda dapat mengakses fitur tertentu sebagai
          “Pengunjung”
        </Paragraf>
        <Paragraf>
          Kami bisa memodifikasi Syarat dan Ketentuan ini dari waktu ke waktu. Jika kami melakukan
          perubahan material terhadap kontrak ini, kami akan menyampaikan pemberitahuan melalui
          Layanan atau melalui cara lain guna memberi Anda kesempatan untuk meninjau perubahan
          tersebut sebelum diberlakukan. Kedua belah pihak menyepakati bahwa perubahan tidak berlaku
          surut (retroaktif). Jika Anda tidak menyetujui perubahan ini, Anda dapat menutup akun.
          Jika Anda terus menggunakan Layanan kami setelah kami mempublikasikan atau mengirim
          pemberitahuan tentang perubahan atas ketentuan ini, artinya Anda menyetujui ketentuan yang
          diperbarui tersebut sejak tanggal berlakunya.
        </Paragraf>
      </Content>
      <Content>
        <Title>Akun</Title>
        <Paragraf>
          Anggota adalah pemilik akun. Anda setuju untuk: (1) menggunakan kata sandi yang kuat dan
          menjaga kerahasiaannya; (2) tidak mentransfer bagian mana pun dari akun Anda (misalnya
          koneksi), dan (3) anda bertanggung jawab atas semua yang terjadi melalui akun Anda,
          kecuali jika Anda menutupnya atau melaporkan penyalahgunaan.
        </Paragraf>
        <Title>Pembayaran</Title>
        <Paragraf>
          Jika Anda berlangganan paket kami yang mana pun, Anda setuju untuk membayar biaya dan
          pajak yang sesuai kepada kami dan menyetujui syarat tambahan yang berlaku spesifik untuk
          Layanan berbayar tersebut. Kegagalan dalam membayar biaya ini dapat berakibat
          dihentikannya Layanan berbayar Anda. Kami dapat menyimpan dan terus menagih ke metode
          pembayaran Anda (misalnya, kartu kredit) meskipun sudah kedaluwarsa, guna menghindari
          interupsi pada Layanan Anda. Jika Anda membayar langganan, metode pembayaran Anda akan
          menerima tagihan secara otomatis pada setiap awal masa langganan atas biaya dan pajak yang
          berlaku untuk masa langganan tersebut. Untuk menghindari tagihan lebih lanjut, batalkan
          sebelum tanggal perpanjangan. Kami mungkin memperhitungkan pajak yang harus Anda bayar
          dalam informasi tagihan yang Anda berikan kepada kami pada saat pembelian. Anda bisa
          mendapatkan salinan faktur melalui pengaturan akun Terampil Anda di bagian “Pembayaran”.
        </Paragraf>
      </Content>
      <Content>
        <Title>Notifikasi</Title>
        <Paragraf>
          Anda setuju untuk menerima pemberitahuan dan pesan dari kami menggunakan cara berikut: (1)
          di dalam Layanan, atau (2) yang dikirimkan ke informasi kontak yang Anda berikan kepada
          kami (misalnya email, nomor ponsel, alamat fisik). Anda setuju untuk selalu memberikan
          informasi kontak yang terbaru.
        </Paragraf>
      </Content>
      <Content>
        <Title>Ketersediaan Layanan</Title>
        <Paragraf>
          Kami dapat mengubah, menangguhkan, atau menghentikan Layanan kami. Kami juga dapat
          mengubah harga layanan kami di masa depan dengan memberikan pemberitahuan terlebih dahulu
          dalam jangka waktu yang wajar.
        </Paragraf>
      </Content>
      <Content>
        <Title>Hak Milik Intelektual</Title>
        <Paragraf>Terampil memiliki semua hak milik intelektual dalam Layanan.</Paragraf>
      </Content>
      <Content>
        <Title>Pemrosesan Otomatis</Title>
        <Paragraf>
          Kami menggunakan informasi dan data yang Anda berikan untuk membuat rekomendasi mengenai
          training yang mungkin berguna bagi Anda. Dengan menjaga keakuratan dan kekinian profil,
          Anda membantu kami membuat rekomendasi ini lebih akurat dan relevan.
        </Paragraf>
      </Content>
      <Content>
        <Title>Tidak Ada Jaminan</Title>
        <Paragraf>
          Terampil tidak memberikan pernyataan atau jaminan terkait layanan, termasuk setiap
          pernyataan bahwa layanan tidak akan terganggu atau bebas kesalahan. Sejauh yang diizinkan
          berdasarkan hukum yang berlaku, Terampil menyanggah garansi tersirat atau tersurat,
          termasuk garansi tersirat atas kepemilikan, akurasi data, penyalahgunaan hak, dan jual
          atau kesesuaian untuk tujuan tertentu.
        </Paragraf>
      </Content>
      <Content>
        <Title>Imbauan dan Larangan</Title>
        <Title>Anda setuju bahwa Anda akan:</Title>
        <Paragraf>
          1. Mematuhi semua hukum yang berlaku, termasuk tetapi tidak terbatas pada hukum privasi,
          hukum hak milik intelektual, hukum anti-spam, hukum perpajakan, dan persyaratan
          pengaturan;
        </Paragraf>
        <Paragraf>2. Memberikan informasi akurat kepada kami dan tetap memperbaruinya;</Paragraf>
        <Paragraf>3. Menggunakan nama asli di Profil Anda; dan</Paragraf>
        <Paragraf>4. Menggunakan Layanan secara profesional.</Paragraf>
        <Title>Anda setuju bahwa Anda tidak akan:</Title>
        <Paragraf>
          1. Membuat identitas palsu di Terampil, membuat profil Anggota untuk siapa pun kecuali
          Anda sendiri (seorang manusia), atau menggunakan atau berusaha untuk menggunakan akun
          orang lain;
        </Paragraf>
        <Paragraf>
          2. Mengembangkan, mendukung atau menggunakan perangkat lunak, perangkat, skrip, robot,
          atau dengan cara atau proses lain apa pun, (termasuk crawler, plugin dan add-on browser,
          atau teknologi lainnya) untuk mengekstrak data Layanan atau menyalin profil dan data
          lainnya dari Layanan;
        </Paragraf>
        <Paragraf>
          3. Menyalin, menggunakan, mengungkapkan, atau mendistribusikan informasi apa pun yang
          didapatkan dari Layanan, baik secara langsung atau melalui pihak ketiga (misalnya, mesin
          pencari), tanpa seizin Terampil;
        </Paragraf>
        <Paragraf>
          4. Melanggar hak milik intelektual atau hak lain Terampil, termasuk, tidak terbatas pada,
          (i) menyalin atau mendistribusikan video pembelajaran kami atau materi lainnya, atau (ii)
          menyalin atau mendistribusikan teknologi kami, kecuali jika teknologi tersebut dirilis
          berdasarkan lisensi sumber terbuka; (iii) menggunakan kata “Terampil” atau logo kami pada
          kartu nama, email, atau URL;
        </Paragraf>
        <Paragraf>
          5. Merekayasa balik, mengubah, membongkar, menguraikan, atau dengan cara lain berusaha
          memperoleh kode sumber untuk Layanan ini, atau teknologi terkait apa pun yang bukan
          merupakan sumber terbuka;
        </Paragraf>
        <Paragraf>
          6. Menyiratkan atau menyatakan bahwa Anda berafiliasi dengan atau didukung oleh Terampil
          tanpa persetujuan resmi dari (misalnya, menyatakan diri Anda sebagai seorang pelatih yang
          terakreditasi oleh Terampil);
        </Paragraf>
        <Paragraf>
          7. Menyewakan, mengontrakkan, meminjamkan, menjual/menjual kembali atau dengan kata lain
          memonetisasi Layanan atau data yang terkait atau aksesnya, tanpa persetujuan Terampil;
        </Paragraf>
        <Paragraf>
          8. Melakukan deep-link ke Layanan kami untuk tujuan tertentu selain mempromosikan profil
          Anda tanpa persetujuan Terampil;
        </Paragraf>
        <Paragraf>
          9. Menggunakan bot atau metode otomatis lainnya untuk mengakses Layanan, menambah atau
          mendownload kontak, mengirim atau mengarahkan kembali pesan;
        </Paragraf>
        <Paragraf>
          10. Terlibat dalam “pembingkaian (framing)”, “pencerminan (mirroring)”, atau simulasi lain
          atas tampilan atau fungsi Layanan;
        </Paragraf>
      </Content>
      <Content>
        <Title>Pusat Bantuan</Title>
        <Paragraf>
          Anda dapat menghubungi kami dengan akses menu bantuan di layanan kami atau email{' '}
          <a href="mailto:halo@terampil.com">halo@terampil.com</a>
        </Paragraf>
      </Content>
    </>
  );
}

export default SyaratKetentuan;
