import React from 'react';

import PopupModal from 'components/atoms/Modal';
import { Container, Title, Text } from './styled';
import Button from 'components/atoms/Button';

import { ReactComponent as SuccessIcon } from 'assets/svg/pembayaraan/sucess.svg';
import { t } from 'hooks/intl/useLang';

const SuccessJoinTrainingModal = ({
  setShowSuccessModal,
  retake = false,
  resetQuestion,
  ...rest
}) => {
  const renderText = () => {
    switch (true) {
      case retake:
        return (
          <>
            <Title>{t('Ikuti Ulang Training Berhasil')}</Title>
            <Text>{t('Selamat belajar dan menikmati Training dari Terampil.')}</Text>
            <Button
              className="w-100"
              small
              onClick={() => {
                setShowSuccessModal(false);
                resetQuestion();
              }}
            >
              {t('Tutup')}
            </Button>
          </>
        );
      default:
        return (
          <>
            <Title>{t('Ikuti Training Berhasil')}</Title>
            <Text>{t('Selamat belajar dan menikmati Training dari Terampil.')}</Text>
            <Button className="w-100" small onClick={() => setShowSuccessModal(false)}>
              {t('Tutup')}
            </Button>
          </>
        );
    }
  };

  return (
    <PopupModal dark {...rest}>
      <Container>
        <SuccessIcon />
        {renderText()}
      </Container>
    </PopupModal>
  );
};

export default SuccessJoinTrainingModal;
