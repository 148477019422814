import React from 'react';
import { useQuery } from '@apollo/client';
import { Container, Image, Text } from './Collaboration.styled';
import { GET_COLLABORATION } from 'queries/collaboration';
import { LoaderDualRing } from 'components/atoms/Loading/';
import MaxContainer from 'components/atoms/MaxContainer';
import { LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';

//logo cooperations
import AMGLogo from 'assets/png/Cooperations/amg.png';
// import CicilsewaLogo from 'assets/png/Cooperations/cicilsewa.png';
// import GoldsgymLogo from 'assets/png/Cooperations/goldsgym.png';
import MrmontirLogo from 'assets/png/Cooperations/mrmontir.png';
import LondonschoolLogo from 'assets/png/Cooperations/londonschool.png';
import PrasetiyaLogo from 'assets/png/Cooperations/Prasmul.png';
import { t } from 'hooks/intl/useLang';

function Collaboration({ scrollPosition }) {
  const { loading } = useQuery(GET_COLLABORATION, {
    variables: {
      input: {
        limit: 6,
        orderBy: 'id',
        is_active: false,
      },
    },
  });

  const CollaborationsImg = [
    {
      id: 1,
      name: 'AMG',
      image: AMGLogo,
    },
    {
      id: 2,
      name: 'Prasmul',
      image: PrasetiyaLogo,
    },
    //{
    //  id: 3,
    //  name: "Cicil Sewa",
    //  image: CicilsewaLogo,
    //},
    //{
    //  id: 4,
    //  name: "Golds Gym",
    //  image: GoldsgymLogo,
    //},
    {
      id: 5,
      name: 'Mr Montir',
      image: MrmontirLogo,
    },
    {
      id: 6,
      name: 'London School',
      image: LondonschoolLogo,
    },
  ];

  //filtering the company
  // const activeCompany = data?.Cooperations?.items;
  // let listCompany = [];

  // const filteredCompany = activeCompany && activeCompany.map((n, i) => {
  //   if(n?.name === "Cicil Sewa"){
  //     return listCompany.push(n);
  //   }else if(n?.name === "Golds Gym"){
  //     return listCompany.push(n);
  //   }else if(n?.name === "Mr Montir"){
  //     return listCompany.push(n);
  //   }else if(n?.name === "AMG" ){
  //     return listCompany.push(n)
  //   }else if(n?.name === "LSAF" ){
  //     return listCompany.push(n)
  //   }else if(n?.name === "Universitas Prasetya Mulya" ){
  //     return listCompany.push(n)
  //   }
  // });

  if (loading) return <LoaderDualRing></LoaderDualRing>;

  return (
    <Container>
      <MaxContainer>
        <div>
          <Text>
            {t('Kolaborasi dengan')}<span> {t('Pemimpin Industri dan Universitas Terbaik')}</span>
          </Text>
        </div>
        <div className="image-icon">
          {CollaborationsImg.map((item, index) => {
            return (
              <LazyLoadComponent scrollPosition={scrollPosition}>
                <Image src={item?.image} alt="logo" key={index} />
              </LazyLoadComponent>
            );
          })}
        </div>
      </MaxContainer>
    </Container>
  );
}

export default trackWindowScroll(Collaboration);
