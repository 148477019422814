import React from 'react';
import { Container } from './UniqueValue.styled';
import UniqueImage from 'components/molecule/UniqueImage/';
import UniqueTitle from 'components/molecule/UniqueTerampil';
import MaxContainer from '../../atoms/MaxContainer';

function UniqueValue() {
  return (
    <MaxContainer>
      <Container>
        <UniqueImage />
        <UniqueTitle />
      </Container>
    </MaxContainer>
  );
}

export default UniqueValue;
