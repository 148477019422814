import Toast from 'components/atoms/Notification/Toast';
import React from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { copyToClipboard } from 'utils/copyToClipboard';
import {
  ContainerOuter,
  CardContainer,
  Title,
  Paragraf,
  QuotaTitle,
  ButtonOuter,
  LineBreak,
  BoxVoucher,
  KodeVoucher,
  ButtonCopy,
} from './styled';
import { t } from 'hooks/intl/useLang';

const VoucherUndangTeman = ({ dataPackage, dataUnlock = [] }) => {
  return (
    <ContainerOuter>
      <Toast position="bottom-left" />
      {dataPackage && (
        <>
          <CardContainer>
            <Title>{t('Training Gratis Untuk Kamu')}</Title>
            <QuotaTitle>{dataPackage?.remaining_quota} Modul Training</QuotaTitle>
            <Paragraf>
              {t('Kuota modul training bisa langsung kamu gunakan tanpa perlu klaim terlebih dahulu.')}
            </Paragraf>
            <Link to="/trainings">
              <ButtonOuter outer>{t('Pilih Training')}</ButtonOuter>
            </Link>
          </CardContainer>
        </>
      )}
      {dataUnlock?.length > 0 && (
        <CardContainer>
          <Title>{t('Unlock Video Gratis untuk Kamu')}</Title>
          <Paragraf>{t('Gunakan kode di bawah untuk klaim video gratis.')}</Paragraf>
          <LineBreak />
          {dataUnlock?.map((item, i) => {
            return (
              <BoxVoucher key={i}>
                <KodeVoucher>{item?.code}</KodeVoucher>
                <ButtonCopy
                  onClick={() => {
                    toast(t('Berhasil salin kode unlock video'));
                    copyToClipboard('kodeVoucher', item?.code);
                  }}
                >
                  {t('Salin')}
                </ButtonCopy>
              </BoxVoucher>
            );
          })}
        </CardContainer>
      )}
    </ContainerOuter>
  );
};

export default VoucherUndangTeman;
