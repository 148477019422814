import React from 'react';
import { Contianer, CardBottom, Button } from './styled';
import { t } from 'hooks/intl/useLang';
// import EmptyState from 'components/atoms/';
// asset
const BelumAdaHasilTraining = () => {
  return (
    <Contianer>
      <CardBottom>
        <h2>
          {t('Belum Ada Hasil Training')}
        </h2>
        <p>
          {t('Selesaikan semua materi dan kerjakan tes training untuk mendapatkan hasil training dan sertifikat dari Terampil')}
        </p>
        <Button>{t('Lanjut Materi')}</Button>
      </CardBottom>
    </Contianer>
  );
};

export default BelumAdaHasilTraining;
