import React, { useEffect, useState } from 'react';
import { checkMobile } from 'utils/checkMobile.js';

import OptionFaq from 'components/atoms/CategoryDropdown/OptionFaq';
import Accordion from 'components/atoms/Accordion/index.js';
import MaxContainer from 'components/atoms/MaxContainer';
import { Container, Title } from './styled.js';
import CategoryDropdown from 'components/atoms/CategoryDropdown';

const FAQ = ({ loading, data, faqSection = false, selectOption = [], currentValue, ...rest }) => {
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    setIsMobile(checkMobile());
  }, []);

  return (
    <MaxContainer>
      <Container {...rest}>
        <Title {...rest}>Frequently Asked Question</Title>
        {faqSection && isMobile && (
          <CategoryDropdown
            isSearchable={false}
            value={currentValue?.label}
            defaultValue={currentValue}
            dark
            isSelected={true}
            options={selectOption}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            components={{ Option: OptionFaq }}
            isClearable={false}
          />
        )}
        <div className="accordion-container" {...rest}>
          {data?.map((data, idx) => (
            <Accordion dark loading={loading} key={idx} data={data} {...rest} />
          ))}
        </div>
      </Container>
    </MaxContainer>
  );
};

export default FAQ;
