import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation UPDATE_PROFILE($input: UpdateProfileRequest, $file: Upload) {
    UpdateProfile(input: $input, file: $file) {
      slug
      updated_at
    }
  }
`;

export const CREATE_PASSWORD = gql`
  mutation CREATE_PASSWORD($input: CreatePasswordInput) {
    CreatePassword(input: $input) {
      accessToken
      email
      client_id
      id
      Profile {
        slug
        fullname
        all_access
        all_access_end_date
        id
      }
    }
  }
`;
