import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 103px;
`;

export const Flex = styled.div`
  display: flex;
  .container {
    width: 100%;
  }
  /* justify-content: space-between; */
`;

export const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: left;

  color: #fdfdfd;
  position: relative;

  span {
    color: #00debf;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #c4c4c4;
`;

export const Layout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -webkit-column-gap: 20px;
  column-gap: 20px;
  margin-top: 24px;
  row-gap: 50px;
`;
