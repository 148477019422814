import { gql } from '@apollo/client';

export const GET_QUIZ_USER = gql`
  query GET_QUIZ_USER($trainingId: Int, $trainingSectionId: Int) {
    ResultQuizUser(input: { trainingId: $trainingId, trainingSectionId: $trainingSectionId }) {
      summary
    }
  }
`;

export const GET_POST_TEST_USER = gql`
  query GET_QUIZ_USER($trainingId: Int, $trainingSectionId: Int) {
    ResultPostTestUser(input: { trainingId: $trainingId, trainingSectionId: $trainingSectionId }) {
      summary
    }
  }
`;
