import React from 'react';
import { Detail, HeadContainer, MainContainer } from './styled';
import { t } from 'hooks/intl/useLang';

const DetailUser = ({ userData = {}, userDataLoading }) => {
  return (
    <Detail>
      <HeadContainer>
        <h3>{userData?.fullname}</h3>
        {userData?.is_subscribed && <button>Upgrade</button>}
      </HeadContainer>
      <p className="profession">{userData?.profession}</p>
      <MainContainer>
        <p className="align-center">
          {t('Poin kamu')}:{' '}
          <span className={`${userData?.total_point === 0 ? 'point-secondary' : ''} point`}>
            {userData?.total_point}
          </span>
        </p>
      </MainContainer>
    </Detail>
  );
};

export default DetailUser;
