import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 80px 0;
  padding: 0 120px;
  box-sizing: border-box;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 100%;
    margin-bottom: 30px;
    padding: 0;
  }
`;

export const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  /* identical to box height */

  color: #fdfdfd;
`;

export const LayoutCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1.1fr 1fr 1fr;
  gap: 20px 15px;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    grid-template-columns: 1fr;
    grid-template-rows: 1.1fr 1fr;
    gap: 20px 15px;
  }
`;

export const Button = styled(ButtonStyled)`
  width: 500px;
  height: 50px;
  border: 1px solid #00debf;
  margin-top: 60px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  color: #00debf;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 100%;
    margin: 40px 0;
  }
`;
