import React, { useState } from 'react';
import loadable from '@loadable/component';
import { trackWindowScroll, LazyLoadComponent } from 'react-lazy-load-image-component';
import { SwiperSlide } from 'swiper/react';
import { checkMobile } from 'utils/checkMobile';
import { GET_CATEGORIES } from 'queries/categories';
import { useQuery } from '@apollo/client';
import { GET_POPULAR_TRAINING, GET_TRAINING_POPULER_REAL } from 'queries/training';

import {
  Container,
  CardContainer,
  CategoryText,
  CategoryContainer,
  Title,
  HeaderText,
  ContentText,
  ContainerEmptyState,
} from './CategoryPopularTraining.styled';

import { SliderControlPrimary } from 'components/atoms/Slider/Slider.styled';
import { LoaderDualRing } from 'components/atoms/Loading';
import MaxContainer from 'components/atoms/MaxContainer';

import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';
import { t } from 'hooks/intl/useLang';

const Slider = loadable(() => import('components/atoms/Slider'));
const SliderTraining = loadable(() => import('components/molecule/SliderTraining'));

const CategoryPopularTraining = ({ scrollPosition }) => {
  const palingPopuler = { name: 'Paling Populer', id: undefined };
  const [selectedCategory, setSelectedCategory] = useState(undefined);

  const { data: categoriesData = [] } = useQuery(GET_CATEGORIES, {
    variables: {
      is_active: true,
      order_by: 'id',
      limit: 8,
    },
  });

  const { data: trainingsData, loading } = useQuery(GET_POPULAR_TRAINING, {
    variables: {
      id: selectedCategory,
      limit: 15,
    },
  });

  const { data: populerTraining, loadingPopuler } = useQuery(GET_TRAINING_POPULER_REAL);

  function setCategory(categoryId) {
    setSelectedCategory(categoryId);
  }

  const emptyStateTrainings = (
    <ContainerEmptyState>
      <HeaderText>Belum Ada Training</HeaderText>
      <div className="container-content">
        <ContentText>
          Nantikan training baru untuk kategori{' '}
          {trainingsData?.Trainings?.items <= 0
            ? selectedCategory === 1
              ? 'Communication'
              : selectedCategory === 2
              ? 'Entrepreunership'
              : selectedCategory === 3
              ? 'IT & Software Development'
              : selectedCategory === 4
              ? 'Marketing & Sales'
              : selectedCategory === 5
              ? 'Personal Development'
              : selectedCategory === 6
              ? 'Grapic Design & Video Editor'
              : ''
            : ''}
          . Saat ini, silahkan pilih kategori lain untuk mencari training yang ingin kamu pelajari.
        </ContentText>
      </div>
    </ContainerEmptyState>
  );

  const renderCategories = () => {
    let categories = [];
    if (categoriesData?.Categories?.items?.length > 0) {
      categories = [palingPopuler, ...categoriesData?.Categories?.items];

      if (checkMobile()) {
        return (
          <>
            <div className="overlay">
              <div className="inner">
                {categories?.map((category, index) => {
                  return (
                    <CategoryContainer onClick={setCategory.bind(this, category.id)}>
                      <CategoryText selected={selectedCategory === category.id}>
                        {category.name}
                      </CategoryText>
                    </CategoryContainer>
                  );
                })}
              </div>
            </div>
          </>
        );
      }
      return (
        <>
          {/*Categories*/}
          <div className="slider">
            <CardContainer style={{ position: 'relative', zIndex: 3 }}>
              <Slider next="next-category" prev="prev-category" count={'auto'} spaceBetween={0}>
                {categories?.map((category, index) => {
                  return (
                    <SwiperSlide className="swiper-slider" key={index}>
                      <CategoryContainer onClick={setCategory.bind(this, category.id)}>
                        <CategoryText selected={selectedCategory === category.id}>
                          {category.name}
                        </CategoryText>
                      </CategoryContainer>
                    </SwiperSlide>
                  );
                })}
              </Slider>
            </CardContainer>
            <div className="button-slider category" style={{ top: '75%', zIndex: 2 }}>
              <SliderControlPrimary
                className="next-secondary"
                style={{ background: 'transparent', zIndex: 2 }}
                id="prev-category"
              >
                <PrevIcon />
              </SliderControlPrimary>

              <SliderControlPrimary
                className="next-secondary"
                style={{ background: 'transparent', zIndex: 2 }}
                id="next-category"
              >
                <NextIcon />
              </SliderControlPrimary>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <Container>
      <MaxContainer>
        <Title>{t('Kategori Training')}</Title>
        {renderCategories()}
        {loading || loadingPopuler ? (
          <LoaderDualRing />
        ) : trainingsData && trainingsData?.Trainings?.items?.length > 0 ? (
          <LazyLoadComponent scrollPosition={scrollPosition}>
            <SliderTraining
              row
              populer={!selectedCategory}
              trainings={
                selectedCategory
                  ? trainingsData?.Trainings?.items
                  : populerTraining?.TrainingPopuler?.items
              }
              id="category-training"
            />
          </LazyLoadComponent>
        ) : (
          emptyStateTrainings
        )}
      </MaxContainer>
    </Container>
  );
};

export default trackWindowScroll(CategoryPopularTraining);
