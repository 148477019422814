import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query getCategories($is_active: Boolean, $orderBy: CategoryOrder, $limit: Int) {
    Categories(input: { is_active: $is_active, orderBy: $orderBy, limit: $limit }) {
      status
      statusText
      total
      items {
        id
        name
        slug
        icon_default
        icon_hover
      }
    }
  }
`;
