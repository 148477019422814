import styled from 'styled-components';
import { mq } from 'assets/styles/theme';

export const Container = styled.section`
  width: 100%;
  background-color: #202020;
  padding: 40px 0;

  ${mq({
    padding: [null, null, '20px 0', '20px 0', '16px 0'],
  })}

  .see-all {
    position: absolute;
    top: 0;
    right: 0;
    background: #202020;
    z-index: 999;
    opacity: 0.5;
  }

  .button-slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slider {
    position: relative;
    margin-bottom: 8px;
  }

  .overlay {
    overflow-x: scroll;
  }

  .inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    padding-right: 16px;
    box-sizing: border-box;
    width: fit-content;
  }
`;

export const CardContainer = styled.div`
  justify-content: center;
  vertical-align: center;
  align-items: center;

  /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px; */

  .swiper-slider {
    justify-content: flex-start;
    width: fit-content;
  }

  ${mq({
    margin: ['0 100px', null, '0 60px'],
  })}
`;

export const Title = styled.h2`
  font-family: Archivo, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-align: center;

  color: #fdfdfd;
  ${mq({
    fontSize: [null, null, '20px', '20px', '20px'],
  })}
`;

export const CategoryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 22px 0 0;
  cursor: pointer;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    height: 40px;
    margin: 0;
  }
`;

export const CategoryText = styled.a`
  flex: 1;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: ${(props) => (props.selected ? '#00DEBF' : '#C4C4C4')};
  margin-left: 10px;
  margin-right: 40px;
  cursor: pointer;
  white-space: nowrap;
  g &:hover {
    color: white;
  }

  ${mq({
    fontSize: [null, null, null, '14px', '14px'],
    marginLeft: [null, null, null, null, '20px'],
    marginRight: [null, null, null, null, '0'],
  })}
`;

export const ContainerEmptyState = styled.div`
  margin: 150px 0;

  .container-content {
    display: flex;
    justify-content: center;
  }
`;

export const HeaderText = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin: 0;
  color: #fdfdfd;
`;

export const ContentText = styled.p`
  width: 463px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  margin: 16px 0 0 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #c4c4c4;
`;
