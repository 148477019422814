import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import NewLoggedInHeader from './headerLoggedIn';
import { Header, Container, GreenRoundedButton } from './Headers.styled';
import InputComponent from 'components/atoms/Input';
import Dropdown from 'components/atoms/Dropdown';
import A from 'components/atoms/A';
import Button from 'components/atoms/Button';

import LogoHeader from 'assets/png/logo-terampil-white.png';
import LogoHeaderBusiness from 'assets/png/logo terampil enterprise-03 1.png';

import { useSelector } from 'react-redux';
import { getValue } from 'utils';
import NewDropdown from '../../atoms/NewDropdown';
import NewInputComponent from '../../atoms/NewInput';
import MaxContainer from '../../atoms/MaxContainer';
import { t } from 'hooks/intl/useLang';

function NewHeaders(props) {
  const {
    blank = false,
    userData,
    notificationData,
    sticky,
    loadingUserData,
    logoB2b = false,
    isScrolled,
    ...rest
  } = props;

  const authData = useSelector((state) => {
    return state.Auth;
  });

  function checkBlankHeader(args) {
    return args ? null : (
      <>
        {getValue(authData, 'data.accessToken', '') ? (
          <NewLoggedInHeader loadingUserData={loadingUserData} userData={userData} />
        ) : (
          <>
            <NewDropdown>{t('Kategori Pembelajaran')}</NewDropdown>
            <NewInputComponent />
            <nav className="header-link">
              <A to={'https://prakerja.terampil.com'} target="_blank" primary>
                Prakerja
              </A>
              <span></span>
              {userData?.User?.roles?.includes('Trainer') ? (
                <A to="/jadi-trainer" primary>
                  {t('Dashboard Trainer')}
                </A>
              ) : (
                <A to="/business-page" primary>
                  For Enterprise
                </A>
              )}
            </nav>
            <nav className="header-button">
              <Link to="/registrasi">
                <GreenRoundedButton>{t('Daftar')}</GreenRoundedButton>
              </Link>
              <Link to="/masuk">
                <GreenRoundedButton secondary>{t('Masuk')}</GreenRoundedButton>
              </Link>
            </nav>
          </>
        )}
      </>
    );
  }
  return (
    <Header
      isScrolled={isScrolled}
      {...rest}
      className={`${getValue(authData, 'data.accessToken', '') ? 'log-in' : ''} ${sticky ? 'sticky' : ''
        }`}
    >
      <Container className={`header ${getValue(authData, 'data.accessToken', '') ? 'log-in' : ''}`}>
        <Link to={getValue(authData, 'data.accessToken', '') ? '/home' : '/'} className="logo-link">
          <LazyLoadImage
            effect="blur"
            src={!logoB2b ? LogoHeader : LogoHeaderBusiness}
            alt="logo"
            width={137}
            height={54}
            className="image"
          />
        </Link>
        {checkBlankHeader(blank)}
      </Container>
    </Header>
  );
}

export default NewHeaders;
