import React from 'react';
import { Container, ProfilePic } from './styled';
import DetailTrainer from './DetailTrainer';
import DetailUser from './DetailUser';
import DefaultPic from 'assets/svg/User Default.svg';

const ProfileDetail = ({ data = {}, userSettings = false, userProfile, userData }) => {
  return (
    <Container userSettings={userSettings}>
      <ProfilePic
        src={data?.avatar ? data?.avatar : DefaultPic}
        className={`${userProfile || userSettings ? '' : 'trainer'}`}
      />
      {userSettings ? (
        <DetailUser userData={userData} />
      ) : (
        <DetailTrainer data={data} userProfile={userProfile} />
      )}
    </Container>
  );
};

export default ProfileDetail;
