import React from 'react';
import { Toaster } from 'react-hot-toast';

const Toast = ({ ...rest }) => {
  return (
    <Toaster
      {...rest}
      position="top-right"
      toastOptions={{
        style: {
          marginTop: '80px',
          fontWeight: '500',
          fontSize: '16px',
          fontFamily: 'Inter',
          borderRadius: '4px',
          color: '#141414',
          background: '#00debf',
          padding: '8px',
        },
        duration: 2000,
      }}
    />
  );
};

export default Toast;
