const dateSorting = (date) => {
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  const result = `${da} ${mo} ${ye}`;
  return result;
};

export const dateCompare = (notifDate) => {
  const formattedDate = dateSorting(new Date());
  const format = dateSorting(notifDate);
  return formattedDate === format ? 'Hari Ini' : format;
};
