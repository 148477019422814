import React, { useEffect, useState } from 'react';
import { Container } from './styled';
import EmptyState from 'components/molecule/EmptyState/';
import { GET_BOOKMARK } from 'queries/training';
import { useQuery } from '@apollo/client';
import CardTraining from 'components/molecule/Cards/CardTraining/';
import { LoaderDualRing } from 'components/atoms/Loading/index';
import { useSelector } from 'react-redux';
import { setContext } from 'utils';

const emptyStateData = {
  title: 'Belum Ada Training yang Ditandai',
  paragraf: 'Silahkan tandai Training yang ingin Kamu pelajari',
  button: 'Lihat Training',
  to: '/trainings',
};
const BookmarkTab = () => {
  const [dataTraining, setFecthData] = useState([]);
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const { loading, data: dataBookmark } = useQuery(GET_BOOKMARK, {
    context: setContext(authData),
  });

  useEffect(() => {
    setFecthData(dataBookmark?.Bookmarks?.items);
  }, [dataBookmark]);

  if (loading) return <LoaderDualRing />;
  if (dataBookmark?.Bookmarks?.items?.length <= 0) return <EmptyState item={emptyStateData} />;
  return (
    <Container>
      {dataTraining?.map((item, index) => {
        return <CardTraining grid training={item.training} key={index} />;
      })}
    </Container>
  );
};

export default BookmarkTab;
