import styled from 'styled-components';

export const Container = styled.div.attrs((props) => ({
  bgColor: props.bgColor ? '#141414' : '#00004',
}))`
  /* border-top: ${(props) => (props.noLine ? 'none' : '2px solid #626262')}; */
  /* border-bottom: 2px solid #626262; */

  width: 100%;
  /* padding: 60px 50px 50px 125px; */
  padding: ${(props) => (props.isAuth ? '60px 50px 50px 125px;' : '20px 50px 50px 125px')};
  height: auto; /**364px; */
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  justify-items: center;
  justify-content: space-around;
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 25px 12px 40px 12px;
    row-gap: 12px;
    height: auto;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 25px 12px 40px 12px;
    /* margin-top: 20px; */
    row-gap: 12px;
    height: auto;
  }
`;

export const ContainerSection = styled.footer.attrs((props) => ({
  bgColor: props.bgColor ? '#141414' : props.white ? '#fff' : '#00004',
}))`
  border-top: ${(props) => (props.noLine ? 'none' : '2px solid #626262')};
  /* border-bottom: 2px solid #626262; */
  background-color: ${(props) => props.bgColor};

  width: 100%;
  /* padding: 60px 50px 50px 125px; */
  /* padding: ${(props) => (props.isAuth ? '60px 50px 50px 125px;' : '20px 50px 50px 125px')}; */
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid #303030;
  padding: 0;
  background-color: ${(props) => (props.bgColor ? '#141414' : '#00004')};
`;

export const Font = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 0px;
  padding: 24px 0 58px 0;
  background-color: ${(props) => (props.bgColor ? '#141414' : '#00004')};
  /* identical to box height */

  color: #c4c4c4;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    line-height: 14px;
    margin: 0px;
    padding: 24px 0 38px 0;
  }
`;
