import React from 'react';
import { ContainerImage, Image, BackgroundImage } from './UniqueImage.styled';
import { trackWindowScroll, LazyLoadComponent } from 'react-lazy-load-image-component';

const imageUnique = `https://public.terampil.com/assets/testimonies/Keuntungan_2_terampil.png`;

function UniqueImage({ scrollPosition }) {
  return (
    <ContainerImage>
      <LazyLoadComponent scrollPosition={scrollPosition}>
        <Image image={imageUnique} />
      </LazyLoadComponent>
    </ContainerImage>
  );
}

export default trackWindowScroll(UniqueImage);
