import React from 'react';

import { LoadingEllipsis } from '../index.js';

const LoadingEllipsisComponent = ({ ...rest }) => {
  return (
    <div
      style={{ width: '100vw', height: '100vh', display: 'grid', placeItems: 'center' }}
      {...rest}
    >
      <LoadingEllipsis className="center lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingEllipsis>
    </div>
  );
};

export default LoadingEllipsisComponent;
