import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/index';

export const BackgroundContainer = styled.section`
  width: 100%;
  height: ${({ page }) => (page ? '550px' : '392px')};
  background-image: url('${(props) => props.img}');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  margin: ${({ page }) => (page ? '97px 0 0  0' : '')};

  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 225px;
    border-radius: 0;
    margin-top: 65px;
    svg {
      width: 60px;
      height: 50px;
    }
  }
`;

export const Title = styled.h1`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ page }) => (page ? '40px' : '24px')};
  line-height: 26px;
  text-align: center;
  margin: ${({ page }) => (page ? '43px 0 0 0' : '33px 0 0 0')};
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 14px;
    line-height: 17px;
    margin-top: 12px;
  }
`;

export const TextParagraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ page }) => (page ? '24px' : '14px')};
  line-height: ${({ page }) => (page ? '36px' : '21px')};
  /* or 150% */
  width: 53%;
  text-align: center;
  margin: ${({ page }) => (page ? '36px 0 0 0' : '24px 0 0 0')};
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 12px;
    width: 90%;
  }
`;

export const ContainerButton = styled.div`
  width: 300px;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
  }
`;

export const Button = styled(ButtonStyled)`
  margin: 47px 0 0 0;
  width: 100%;
  padding: 0 20px;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    margin-top: 12px;
    width: 100%;
    height: 47px;
  }
`;
