// import { combineReducers } from "redux";
import * as types from './types';
import { createReducer } from '../../utils';

const userTrainingReducer = createReducer({
  data: undefined,
  error: null,
})({
  [types.SET_USER_TRAINING]: (state, action) => {
    return {
      data: action.payload,
      error: null,
    };
  },
  [types.REMOVE_USER_TRAINING]: (state, action) => {
    return {
      data: undefined,
    };
  },

  //MODULE FREE TRIAL
  [types.SET_MODULE_FREE_TRIAL]: (state, action) => {
    return {
      data: action.payload.data,
      error: null,
    };
  },
  [types.REMOVE_MODULE_FREE_TRIAL]: (state, action) => {
    return {
      data: undefined,
    };
  },
});

// export default combineReducers( {
//     auth: authReducer
// } );

export default userTrainingReducer;
