import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Search } from './styled';
import SearchIcon from 'assets/png/Search.png';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';

function SearchBlog({ blog }) {
  const [search, setSearch] = useState('');

  const history = useHistory();
  const mobile = checkMobile();

  const pushRoute = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      if (search !== '') {
        history.replace(`/blogs?search=${search}&page=1`);
      } else {
        history.replace(`/blogs?page=1`);
      }
    }
  };

  return mobile ? (
    <Search className="mobile">
      <input
        className="mobile"
        autoComplete={false}
        autoFocus
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        placeholder={t("Cari Artikel")}
      />
      <img
        src={SearchIcon}
        alt="search"
        className="button"
        onClick={pushRoute}
        onKeyPress={pushRoute}
      />
    </Search>
  ) : (
    <>
      <Search>
        <img src={SearchIcon} value={search} alt="search" className="button" onClick={pushRoute} />
        <input
          id="searchBar"
          type="text"
          placeholder={t("Cari Artikel")}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={pushRoute}
        />
      </Search>
    </>
  );
}

// Input.propTypes = {};

export default SearchBlog;
