import React from 'react';
import { Container, Title, LayoutCard, Button } from './styled';
import CardBlog from 'components/molecule/Cards/CardBlog';
import { Link } from 'react-router-dom';
import { t } from 'hooks/intl/useLang';

const SemuaArtikel = ({ data = [] }) => {
  return (
    <Container>
      <Title>{t('Semua Artikel')}</Title>
      <LayoutCard>
        {data?.map((item, i) => {
          return <CardBlog item={item} key={i} index={i + 70} />;
        })}
      </LayoutCard>
      <Link to="/blogs?page=1">
        <Button outline>{t('Lihat Lainnya')}</Button>
      </Link>
    </Container>
  );
};

export default SemuaArtikel;
