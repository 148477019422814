import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 100%;
  background-color: #202020;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 24px;
  margin-top: 24px;
  display: flex;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 12px;
    height: 121px;
    margin-top: 16px;
  }
`;

export const InsideBox = styled.div`
  height: 211px;
  background-color: #373737;
  width: 350px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 5px;

  h2 {
    ${(props) => props.theme.font.primary.title};
    font-size: 100px;
    margin: 0;
    color: ${(props) => props.theme.color.primary};

    span {
      font-size: 30px;
    }
  }

  p {
    ${(props) => props.theme.font.secondary.subtitle2};
    font-size: 18px;
    line-height: 22px;
    margin: 16px 0 0 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    h2 {
      ${(props) => props.theme.font.primary.title};
      font-size: 30px;
      margin: 0;
      color: ${(props) => props.theme.color.primary};

      span {
        font-size: 16px;
      }
    }

    p {
      font-size: 13px;
      line-height: 16px;
      margin: 2px 0 0 0;
    }

    .star-ratings {
      height: 30px;
    }

    .star-container {
      margin-top: -30px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 130px;
    height: 97px;
    padding: 8px;
  }
`;

export const ResultBar = styled.div`
  padding: 12px 14px 12px 34px;
  display: grid;
  grid-template-columns: 25px 25px auto;
  align-content: space-between;
  width: 58%;
  box-sizing: border-box;
  .ulasan {
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #c4c4c4;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    svg {
      margin-top: -2px;
      width: 10px;
    }
    .ulasan {
      font-size: 12px;
      line-height: 14.52px;
    }

    grid-template-columns: 15px 15px auto;
    align-content: space-between;
    width: 58%;
    padding: 6px;
  }
`;

export const ProgresInside = styled.div`
  width: 100%;
  padding-left: 16px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding-left: 4px;
  }
`;
