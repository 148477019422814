import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 106px 0;
  z-index: 2;
  position: relative;

  @media only screen and ${(props) => props.theme.breakpoints.lg} {
    margin: 0 68px 24px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 0 20px 8px;
  }
`;

export const Title = styled.h2`
  font-family: Archivo, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 33px;
  margin: 8px 0;
  text-align: left;
  /* identical to box height */

  color: #ffffff;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    margin: 0;
    line-height: 22px;
  }
`;

export const SeeAll = styled.a`
  font-family: Archivo, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-align: right;
  text-decoration: none;

  color: #00debf;
  cursor: pointer;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 15px;
  }

  :hover {
    color: white;
  }
`;
