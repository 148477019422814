import React from 'react';
import { Container, RealtedTraining, ContainerCard } from './styled';
// component
import TentangTrainer from 'components/molecule/Deskripsi/TentangTrainer';
import KelasDeskripsi from 'components/molecule/Deskripsi/KelasDeskripisi';
import TestimoniDeskripsi from 'components/molecule/Deskripsi/Testimoni/';
import CardTraining from 'components/molecule/Cards/CardTraining/';
import MaxContainer from 'components/atoms/MaxContainer';
import TitleTraining from 'components/molecule/Deskripsi/Title';
import TrainingPackagePrice from 'components/molecule/Deskripsi/TrainingPackagePrice/';

// asset
import { useQuery } from '@apollo/client';
import { GET_DETAIL_TRAINING, GET_FILTERED_CATEGORY_TRAINING } from 'queries/training';
import { useParams } from 'react-router-dom';

const Deskripsi = ({
  training,
  isFollowTraining,
  haveTraining,
  userDetail,
  expiredFreeTrial,
  is_subscription,
  quota,
  setShowModal,
  haveTrainingFreeTrail,
  haveUnlockVideo,
  allAccessTraining,
}) => {
  const { slug } = useParams();

  const { data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    variables: {
      input: slug,
    },
  });

  const { data: categoryTrainingId } = useQuery(GET_FILTERED_CATEGORY_TRAINING, {
    variables: {
      input: {
        categoryId: detailTraining?.Training?.category?.id,
      },
    },
  });

  return (
    <Container>
      <MaxContainer>
        <TitleTraining
          training={training}
          quota={quota}
          is_subscription={is_subscription}
          haveTraining={haveTraining}
          expiredFreeTrial={expiredFreeTrial}
          setShowModal={setShowModal}
          haveTrainingFreeTrail={haveTrainingFreeTrail}
          haveUnlockVideo={haveUnlockVideo}
        />
        <TentangTrainer isFollowTraining={isFollowTraining} item={detailTraining?.Training} />
        <KelasDeskripsi item={detailTraining?.Training} />
        <TestimoniDeskripsi item={detailTraining?.Training} />
        {!allAccessTraining && (
          <TrainingPackagePrice
            userDetail={userDetail}
            haveTraining={haveTraining}
            detailTraining={detailTraining?.Training}
            quota={quota}
            setShowModal={setShowModal}
            haveTrainingFreeTrail={haveTrainingFreeTrail}
            haveUnlockVideo={haveUnlockVideo}
          />
        )}
        {categoryTrainingId && categoryTrainingId?.Trainings?.items?.length > 0 && (
          <RealtedTraining>
            <h2>Related Training</h2>
            <ContainerCard>
              {categoryTrainingId?.Trainings?.items.slice(0, 6).map((training, i) => {
                return <CardTraining module className="card" key={i} training={training} />;
              })}
            </ContainerCard>
          </RealtedTraining>
        )}
      </MaxContainer>
    </Container>
  );
};

export default Deskripsi;
