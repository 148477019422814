import styled from 'styled-components';

export const Container = styled.div`
  /* background-color: red; */
  padding: 0 120px;
  height: 400px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px 16px;
  grid-template-areas:
    'satu satu dua tiga'
    'satu satu empat lima';

  a {
    text-decoration: none;
  }

  & > h1 {
    cursor: pointer;
  }

  .satu {
    grid-area: satu;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    text-align: left;
    color: #fdfdfd;
  }
  .dua {
    grid-area: dua;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    text-align: left;

    color: #fdfdfd;
  }
  .tiga {
    grid-area: tiga;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    margin: 0;
    color: #fdfdfd;
  }
  .empat {
    grid-area: empat;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    text-align: left;

    color: #fdfdfd;
  }
  .lima {
    grid-area: lima;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    text-align: left;

    color: #fdfdfd;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 0;
    height: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.1fr 0.9fr 1fr 1fr;
    gap: 6px 6px;
    grid-template-areas:
      'satu satu'
      'satu satu'
      'dua tiga'
      'empat lima';

    .satu {
      grid-area: satu;
      font-size: 18px;
      line-height: 24px;
    }
    .dua {
      grid-area: dua;
      font-size: 16px;
      line-height: 24px;
    }
    .tiga {
      grid-area: tiga;
      font-size: 14px;
      line-height: 21px;
    }
    .empat {
      grid-area: empat;
      font-size: 14px;
      line-height: 21px;
    }
    .lima {
      grid-area: lima;
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const CardHeadline = styled.div`
  width: 100%;
  background-color: #202020;
  border-radius: 5px;
  height: 100%;
  box-sizing: border-box;
  background-image: url('${({ bgImage }) => bgImage}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;

  .background {
    background: linear-gradient(180deg, rgba(0, 0, 4, 0) 0%, #141414 100%);
    width: 100%;
    padding: ${(props) => (props.big ? '24px' : '12px')};
    box-sizing: border-box;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    .background {
      padding: 8px;
    }
  }
`;
