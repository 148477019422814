import React from 'react';

import { Detail } from './styled';
import { t } from 'hooks/intl/useLang';

const Prequisite = ({ type = 'PostTest', length = 0 }) => {
  function renderList(typeArgs) {
    switch (typeArgs) {
      case 'Pretest':
        return (
          <>
            <li>
              {t('Tidak ada nilai minimum dalam pretes, pretes berfungsi untuk melihat kemampuan kamu sebelum memulai training.')}
            </li>
            <li>
              {t('Kamu harus menjawab semua soal pretes sebelum memulai training.')}
            </li>
          </>
        );
      default:
        return (
          <>
            <li>
              {t('Kamu harus mendapatkan nilai minimum')}{' '}
              {type === 'PostTest'
                ? t('60 untuk bisa download sertifikat')
                : t('80 untuk lanjut ke materi berikutnya.')}
              .
            </li>
            <li>
              {t('Jika nilai kamu kurang dari')} {type === 'PostTest' ? '60' : '80'}, {t('maka kamu bisa mengikuti')} {type === 'PostTest' ? 'tes akhir' : 'kuis'} {t('lagi sampai kamu memperoleh nilai minimum')} {type === 'PostTest' ? '60' : '80'}.
            </li>
          </>
        );
    }
  }

  return (
    <Detail className="no-flex">
      <p className="mb-3">
        {t('Berikut beberapa panduan dalam mengikuti')} {type === 'PostTest' ? t('Test Akhir') : t('Kuis')}:
      </p>
      <ol className="mb-3">
        <li>
          {type === 'PostTest' ? t('Test Akhir') : t('Kuis')} {t('terdiri dari')} {length} {t('soal.')}
        </li>
        <li>{t('Setiap soal diberikan waktu pengerjaan selama 1 menit.')}</li>
        <li>
          {t('Jika waktu kamu habis sebelum memilih jawaban, maka pertanyaan tersebut dianggap salah.')}
        </li>
        {renderList(type)}
        <li>{t('Jawab pertanyaan dengan hati-hati.')}</li>
        <li>{t('Good luck!')}</li>
      </ol>
    </Detail>
  );
};

export default Prequisite;
