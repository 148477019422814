import styled from 'styled-components';
import ButtonStyle from 'components/atoms/Button';

export const ContainerTitle = styled.div`
  width: 50%;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 70px 0px 75px;
  margin-top: 40px;
  box-sizing: border-box;

  .flex {
    display: flex;
    gap: 12px;
    margin-top: 24px;
    align-items: center;
  }

  .price-tag {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .button-container {
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    margin-top: -6px;

    .link {
      width: 100%;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      margin: 10px 0 0 -5px;
      align-items: flex-start;
      box-sizing: content-box;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 50%;
    padding: 0px 30px 0px 20px;
  }
  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 100%;
    min-height: 40vh;
    padding: 0px 50px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    /* .flex {
      flex-direction: column;
      justify-content: center;
      width: 100%;
    } */
    width: 100%;
    min-height: 60%;
    padding: 0px 23px 0px 28px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xxs} {
    padding: 0px 15px;
  }
`;

export const Button = styled(ButtonStyle)`
  width: 100%;
  font-size: 20px;
  height: 58px;
  padding: 16px;
  @media screen and (max-width: 576px) {
    width: 98%;
    font-size: 18px;
  }
`;

export const Title = styled.h2`
  width: 85%;
  font-family: Archivo;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px 0;
  text-align: left;
  color: #fdfdfd;
  line-height: 36px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 18px;
    text-align: center;
    line-height: 27px;
    margin: 0 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 18px;
    text-align: center;
    line-height: 27px;
    margin: 0 16px;
  }
`;

export const PointTitle = styled.p`
  margin: 0;
  font-family: Inter;
  font-size: ${({ font }) => (font === 'smaller' ? 16 : 18)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  text-align: left;
  color: #949d9c;
  display: inline-block;

  @media screen and (max-width: 576px) {
    font-size: ${({ font }) => (font === 'smaller' ? 14 : 16)}px;
    margin-left: -5px;
  }
`;

export const Icon = styled.img`
  margin-right: 15px;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 28px;
  }
`;

export const ContainerPoint = styled.div`
  display: flex;
  align-items: center;
  row-gap: 24px;
`;

export const TitlePrice = styled.h6`
  margin: 0;
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 576px) {
    font-size: 20px;
    margin-left: -5px;
    margin-bottom: 4px;
  }
`;

export const ContainerLogo = styled.div`
  margin-right: auto;

  .app-store {
    width: 161px;
    height: 54px;
    /* margin-bottom: -12px; */

    cursor: pointer;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      /* margin-left: 0;
      float: left; */
      width: 150px;
      height: 50px;
    }
    @media screen and ${(props) => props.theme.breakpoints.xs} {
      /* margin-bottom: 2px;
      float: left; */
    }
  }

  .playstore {
    width: 187px;
    height: 78px;
    /* margin-bottom: -12px; */
    margin-left: -13px;

    cursor: pointer;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      /* margin-left: -12px;
      float: left; */
      width: 176px;
      height: 70px;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      /* margin-bottom: 2px;
      float: left; */
    }
  }

  .logo {
    width: 100%;
    height: auto;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: -12px;
      float: left;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  .logo-none {
    cursor: auto;
    width: 100%;
    height: auto;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: -12px;
      float: left;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    height: auto;
  }
`;
