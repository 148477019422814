import styled from 'styled-components';

export const ContainerBenefeitUndangTeman = styled.section`
  display: grid;
  place-items: center;
  padding-bottom: 40px;
  a {
    text-decoration: none;
  }
  & > h1 {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    /* identical to box height */

    text-align: center;

    color: #fdfdfd;
  }

  & > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: ${({ login }) => (login ? '16px' : '24px')};
    line-height: ${({ login }) => (login ? '24px' : '36px')};
    /* or 150% */
    width: 80%;
    text-align: center;

    color: #999999;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    flex: 1;
  }

  .card-container {
    border-radius: 10px;
    background-color: ${({ login }) => (login ? '#202020' : '#373737')};
    /* max-width: 100%px; */
    max-width: ${({ login }) => (login ? '' : '550px')};
    ${({ login }) => (login ? 'flex 1' : '')};
    padding: ${({ login }) => (login ? '32px' : '32px')};
    box-sizing: border-box;
    height: ${({ login }) => (login ? '168px' : '180px')};

    :hover {
      background-color: ${({ login }) => (!login ? '#202020' : '')};
    }
  }

  .card-contain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .title {
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    margin: 0;
    color: #fdfdfd;
    text-align: left;
  }

  .text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: ${(props) => (props.login ? '14px' : '16px')};
    line-height: 24px;
    /* or 150% */
    margin: ${({ login }) => (login ? '12px 0 0 0' : '24px 0 0 0')};
    color: #999999;
    padding: 0 10px 0 0;
    text-align: left;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    & > h1 {
      font-size: 24px;
      line-height: 26px;
      font-weight: 500;
    }
    & > p {
      font-size: 14px;
      line-height: 21px;
    }
    .flex {
      flex-direction: column;
    }
    .card-container {
      margin: 0 16px;
      padding: 16px;
      height: 92px;
    }
    .title {
      font-size: 12px;
      line-height: 21px;
    }
    .text {
      font-size: 10px;
      line-height: 15px;
      margin
    }
  }
`;
