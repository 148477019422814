import styled from 'styled-components';

export const Container = styled.div`
  background-color: #141414;
  height: 100vh;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  flex-direction: column;
  padding: 32px 16px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    .title-mobile {
      margin-top: 16px;
    }
  }
`;

export const Description = styled.div`
  text-align: left;

  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    color: #c4c4c4;
    margin: 0;
  }

  svg {
    margin-right: 12px;
    flex-shrink: 0;
  }

  &.mt-5 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  &.mb-4 {
    margin-bottom: 24px;
  }

  &.my-3 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const ButtonContainer = styled.div`
  margin: 16px 0;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  gap: 16px;

  button {
    width: 210px;
    height: 50px;
  }

  .cancel {
    color: #000004;
    background: ${({ theme }) => theme.color.primary};
    border: none;
  }

  .accept {
    color: #c4c4c4;
    background: transparent;
    border: 1px solid #c4c4c4;
  }

  .accept:hover {
    border: 2px solid #c4c4c4;
  }

  .cancel:hover {
    background: #00f1cf;
  }

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    button {
      width: 160px;
      height: 50px;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    gap: 8px;
  }
`;
