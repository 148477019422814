import * as types from './types';

export const setSubscribe = (data) => ({
  type: types.SET_SUBSCRIBE,
  payload: { data },
});

export const removeSubscribe = () => ({
  type: types.REMOVE_SUBSCRIBE,
});
