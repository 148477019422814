import React from 'react';
import { Container, Title, ContainerCard, Paragraf, Button } from './styled';
import { Link } from 'react-router-dom';

const EmptyState = ({ item }) => {
  return (
    <Container>
      <ContainerCard>
        <Title>{item.title}</Title>
        <Paragraf>{item.paragraf}</Paragraf>
        <div className="container-button">
          <Link to={item.to}>
            <Button>{item.button}</Button>
          </Link>
        </div>
      </ContainerCard>
    </Container>
  );
};

export default EmptyState;
