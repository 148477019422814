import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  /* background-color: #202020; */
  box-sizing: border-box;
  padding: 50px 0 60px 0;
  position: relative;
  background-color: ${(props) => (props.home ? '#141414' : '#202020')};

  .button-slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slider {
    position: relative;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    padding: 30px 0;
  }
`;

export const ButtonEnd = styled.div`
  background-color: #373737;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 375px;
  width: 100vw;
  margin: 10px 8px;

  .none{
    display: none;
  }

  .wrap-button{
    display: block;
    cursor: pointer;

    div.content{
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      color: #00DEBF;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    border-radius: 12px;
    height: 400px;
    width: 100vw;

    .wrap-button{
      svg{
        width: 100px;
      }

      div.content{
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 35px;
        text-align: center;
        color: #00DEBF;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    border-radius: 4px;
    height: 290px;
    width: 90vw;

    .wrap-button{
      svg{
        width: 50px;
      }

      div.content{
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 35px;
        text-align: center;
        color: #00DEBF;
      }
    }
  }

`

export const ContainerCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 100px;
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 0;
  }
`;

export const Title = styled.h6`
  font-family: Archivo;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fdfdfd;
  margin: 0 0 50px 0;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 0 0 20px 0;
    font-size: 20px;
  }
`;

export const SliderPagination = styled.div`
  position: relative;
  text-align: center;
  transition: all 300ms ease;
  transform: translate3d(0, 0, 0);
  z-index: 1;
  /* margin-top: 20px; */

  span {
    margin: 0 5px;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: #c4c4c4;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    width: 24px;
    height: 6px;
    border-radius: 20px;
    background-color: #ffffff;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin-top: 0px;

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
    }

    .swiper-pagination-bullet-active {
      width: 20px;
    }
  }
`;
