import { gql } from '@apollo/client';

export const GET_SUBS_PRICE = gql`
  query SUB_FECTH {
    Subscriptions(input: { isActive: true, orderBy: "id", limit: 2 }) {
      items {
        id
        title
        subscription_type
        duration
        total_price
        price_per_month
        promo
        quota
        benefits {
          text
          is_checked
        }
      }
    }
  }
`;

export const GET_SUBS_ONE = gql`
  query SUB_FECTH($subscriptionType: subscriptionEnum) {
    Subscriptions(input: { isActive: true, subscriptionType: $subscriptionType }) {
      items {
        id
        title
        subscription_type
        duration
        total_price
        price_per_month
        promo
        quota
        benefits {
          text
          is_checked
        }
      }
    }
  }
`;

export const GET_PAYMENT_INFO = gql`
  query GET_PAYMENT_INFO($invoice: String) {
    PaymentInfo(input: { invoiceNumber: $invoice }) {
      payment_method
      invoice_number
      va_number
      bill_key
      biller_code
      qr_code
      deep_link
      subscription_type
      total_price
      redirect_url
      id
    }
  }
`;
