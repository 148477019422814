import styled from 'styled-components';

export const Container = styled.section`
  background: #141414;
  padding-top: 77px;
  padding-bottom: ${(props) => (props.trainerDetail ? '50px' : '')};
  ${({ userProfile }) =>
    userProfile ? 'padding-bottom: 80px; border-bottom: 2px solid #626262;' : ''}
  .container {
    padding: 0px 100px;
  }

  .user {
    position: relative;

    margin: 0 auto;
    margin-top: 32px;

    padding: 0px 50px;
    background: #202020;

    width: 70%;
    min-height: 600px;

    border-radius: 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 2;
  }

  .edit-profile {
    text-decoration: none;
    display: grid;
    place-items: center;
    border-radius: 5px;
    border: 1px solid #c4c4c4;

    position: absolute;
    top: 50px;
    right: 50px;

    width: 140px;
    height: 40px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #c4c4c4;

    & > svg {
      display: none;
    }
  }

  .edit-profile::after {
    content: 'Ubah Profil';
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding-top: 37px;
    .container {
      padding: 0 16px;
    }

    .user {
      margin: 0;
      padding: 0 16px;

      background: transparent;

      width: auto;
      height: auto;
    }

    .edit-profile {
      top: 70px;
      right: 20px;

      width: auto;
      height: auto;
      border: none;

      & > svg {
        display: block;
      }
    }

    .edit-profile::after {
      content: none;
    }
  }
`;

export const EditProfilButton = styled.button``;
