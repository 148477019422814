import styled from 'styled-components';

export const Container = styled.div`
  .mb-4 {
    margin-bottom: 32px;
  }

  p {
    color: #c4c4c4;
  }

  strong {
    color: #fdfdfd;
  }
`;

export const ButtonContainer = styled.div`
  margin: 0 32px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  div {
    flex-shrink: 0;
    margin-right: 24px;
  }

  div > p {
    color: #fdfdfd;
    font-size: 20px;
    margin: 0;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 6px;
  }

  .button {
    width: 90%;
  }

  .secondary {
    border: 1px solid #c4c4c4;
    background: transparent;
    color: #c4c4c4;
  }

  .secondary-text {
    color: #c4c4c4;
    font-size: 16px;
    font-weight: 400;
  }
`;
