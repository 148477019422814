import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressBar = styled.div`
  flex: 11;
  position: relative;
  border-radius: 40px;
  background-color: #464646;
  height: 8px;
  margin-right: 12px;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    height: 4px;
  } ;
`;

export const FilledProgress = styled.div`
  width: ${(props) => props.percentage}%;
  background: #fdfdfd;
  border-radius: inherit;
  height: 100%;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    height: 4px;
  } ;
`;

export const ProgressPercentage = styled.p`
  flex: 1;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: 0;
  text-align: left;

  @media only screen and (max-width: 577px) {
    font-size: 12px;
    line-height: 15px;
  }
`;
