import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/';

export const Container = styled.div``;
export const ContainerButton = styled.div`
  padding: 20px;
`;

export const Title = styled.h2`
  font-family: Archivo;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #039735;
  margin: 12px 0;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 21px;
  }
`;

export const Separator = styled.hr`
  width: 500px;
  margin-left: -24px;
  border: 1px solid #e5e5e5;

  @media only screen and (max-width: 577px) {
    width: 116%;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: center;
  color: #626262;
  margin: 12px 0;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
  }
`;

export const Button = styled(ButtonStyled)`
  width: 100%;
  height: 50px;
`;
export const Image = styled.img`
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 120px;
    height: 120px;
  }
`;
