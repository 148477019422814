import styled from 'styled-components';

export const Container = styled.div`
  padding: 80px 150px;
  background-color: #141414;
  margin-top: 100px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 50px 80px;
    margin-top: 80px;
   }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 32px 16px;
    margin-top: 32px;
  }
`;

export const Title = styled.h5`
  color: #fdfdfd;
  font-size: 30px;
  padding: 0px 15px;
  margin: 0;
  margin-bottom: 50px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 24px;
  }
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  gap: 110px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    gap: 60px;
   }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: column;
    gap: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin-bottom: 12px;
  }
`;

export const List = styled.li`
  margin: 8px 0;
  padding: 0;
  font-family: 'Inter';
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin: 16px 0 0 0;
  }
`;

export const OrderList = styled.ol`
  margin: 0;
  font-family: 'Inter';
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding-left: 20px;
   }
  
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 0;
  }
`;

export const UnorderList = styled.ul`
  margin: 0;
  font-family: 'Inter';
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  list-style-type: disc;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding-left: 20px;
   }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 0 0 0 20px;
  }
`;

export const Sides = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Point = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  margin-right: 24px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    margin-right: 16px;
   }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin-right: 12px;
  }
`;

export const Body = styled.p`
  align-self: center;
  margin: 0;
  font-family: 'Inter';
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
`;
