import React, { useEffect, useState } from 'react';
import Toast from 'components/atoms/Notification/Toast';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { getValue } from 'utils';
import { copyToClipboard } from 'utils/copyToClipboard';
import { postLinkUrl } from 'utils/urlReferral';
import {
  Container,
  Title,
  Paragraf,
  BoxUrl,
  Flex,
  Button,
  ButtonOutline,
  MobileSharedContainer,
} from './styled';
import SharedButton from 'components/molecule/SharedButton';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';
const SharedCodeSection = () => {
  const [registerLink, setRegisterLink] = useState('');
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const referralCode = getValue(authData, 'data.referralCode');

  const mobileShare = async () => {
    try {
      if (navigator.share) {
        navigator.share({
          title: t('Undang Teman Kamu'),
          text: t(`Hai, Terampil people! Ada platform training bagus nih buat upgrade karir dan bisnis kamu!\nDapatkan 1 video training gratis dan penawaran spesial! Daftar sekarang melalui link ini:`),
          url: registerLink,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    try {
      const getLinkReferral = async () => {
        const LinkUrl = await postLinkUrl(referralCode);

        setRegisterLink(LinkUrl?.shortLink);
      };
      getLinkReferral();
    } catch (error) {}
  }, []);

  return (
    <Container>
      <Toast position="bottom-left" />

      <Flex>
        <Title>{t('Share link kamu')}</Title>
        <BoxUrl>
          <div>{registerLink}</div>
          <button
            onClick={() => {
              toast(t('Berhasil salin link referral'));
              copyToClipboard('referral-code', registerLink);
            }}
          >
            {t('Salin')}
          </button>
        </BoxUrl>
      </Flex>
      <Flex>
        <Paragraf>{t('Atau Undang Via')}</Paragraf>
        {!checkMobile() && <SharedButton urlLink={registerLink} />}
        <MobileSharedContainer>
          <ButtonOutline
            outline
            onClick={() => {
              toast(t('Berhasil salin link referral'));
              copyToClipboard('referral-code', registerLink);
            }}
          >
            {t('Salin')}
          </ButtonOutline>
          <Button onClick={() => mobileShare()}>{t('Bagikan')}</Button>
        </MobileSharedContainer>
      </Flex>
    </Container>
  );
};

export default SharedCodeSection;
