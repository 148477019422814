import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled.img`
  width: 240px;
  @media only screen and ${(props) => props.theme.breakpoints.md} {
    width: 200px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    width: 120px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100px;
  }
`;
