import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FETCH_VIDEOADS } from 'queries/adsVideos';
import { useQuery } from '@apollo/client';
import {
  Container,
  BgPhoneContainer,
  ImageLaptop,
  ImageIphone,
  GifIphone,
  ContainerLogo,
} from './styled';
import MaxContainer from 'components/atoms/MaxContainer';
import { LoaderDualRing } from 'components/atoms/Loading';
import Player from 'components/etc/Player';
import BgPhone from 'assets/png/video_laptop/bg_phone.png';
import Laptop from 'assets/png/video_laptop/laptop_app.png';
import Iphone from 'assets/png/video_laptop/iphone_app.png';
import IphoneGif from 'assets/gif/phone-v2.gif';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AppStoreLogo from 'assets/png/PelatihanSaya/App Store.png';
import PlayStoreLogo from 'assets/png/PelatihanSaya/PlayStore.png';

function VideoAdds({ type, campaign, cover = '', linkSafari = '', link = '', scrollPosition }) {
  const [show, setShow] = useState(false);

  const location = useLocation();
  const imageCover = campaign
    ? cover
    : 'https://public.terampil.com/assets/images/landing_page_video2.jpg';

  const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const { loading } = useQuery(FETCH_VIDEOADS, {
    variables: {
      input: {
        is_active: true,
        type: type,
        limit: 1,
      },
    },
  });

  useEffect(() => {
    setShow(true);
    // setUrl(
    //   is_safari
    //     ? 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/homepage/hls/master.m3u8'
    //     : 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/landing-new/landingpage/dash/index.mpd',
    // );

    return () => {
      setShow(false);
    };
  }, [location]);

  if (loading)
    return (
      <Container>
        {' '}
        <LoaderDualRing />{' '}
      </Container>
    );
  return (
    <Container type={type}>
      <MaxContainer>
        <BgPhoneContainer bgPhone={BgPhone}>
          <div className="container-laptop">
            <div className="video-adds">
              {show && (
                <Player
                  className="full"
                  manifestUrl={
                    campaign
                      ? is_safari
                        ? linkSafari
                        : link
                      : is_safari
                      ? 'https://public.terampil.com/assets/ad_videos/landing-new/landingpage/hls/master.m3u8'
                      : 'https://public.terampil.com/assets/ad_videos/landing-new/landingpage/dash/index.mpd'
                  }
                  licenseServer={
                    'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=5BC068'
                  }
                  // loop
                  // autoPlay={true}
                  poster={imageCover}
                  showControls
                  videoAds
                  muted
                />
              )}
            </div>
            <ImageLaptop src={Laptop} />
          </div>
          <ImageIphone bgPhone={Iphone}>
            {/* <ImageIphone src={Iphone} /> */}
            <div className="container-gif-iphone">
              <GifIphone src={IphoneGif} />
            </div>
          </ImageIphone>
          <div className="available">
            <p>Available in:</p>
            <ContainerLogo>
              <a href="https://apps.apple.com/id/app/terampil/id1559671480" target="blank">
                <LazyLoadImage
                  effect="blur"
                  className="app-store"
                  scrollPosition={scrollPosition}
                  src={AppStoreLogo}
                  alt="apple-store"
                />
              </a>
            </ContainerLogo>
            <ContainerLogo>
              <a
                target="blank"
                href="https://play.google.com/store/apps/details?id=com.terampil.app"
              >
                <LazyLoadImage
                  effect="blur"
                  className="playstore"
                  scrollPosition={scrollPosition}
                  src={PlayStoreLogo}
                  alt="google-playstore"
                />
              </a>
            </ContainerLogo>
          </div>
        </BgPhoneContainer>
      </MaxContainer>
    </Container>
  );
}

export default VideoAdds;
