import React from 'react';

import { Section } from './styled';
import MaxContainer from 'components/atoms/MaxContainer';
import ThumbnailCard from 'components/atoms/ThumbnailCard';
import Button from 'components/atoms/Button';
import { Link } from 'react-router-dom';

const pic = `https://d2jr0bw5daaf2g.cloudfront.net/assets/jadi_trainer/banner2.png`;
const DaftarJadiTrainerBawah = () => {
  return (
    <Section>
      <MaxContainer>
        <div className="text-container">
          <h2>Ayo Jadi Trainer Sekarang</h2>
          <p>Bergabung dalam platform pelatihan online profesional terkini di Indonesia</p>
          <Link to="/daftar-jadi-trainer">
            <Button>Daftar Jadi Trainer</Button>
          </Link>
        </div>
        <ThumbnailCard src={pic} trainer={true} />
      </MaxContainer>
    </Section>
  );
};

export default DaftarJadiTrainerBawah;
