import PopupModal from 'components/atoms/Modal';
import React from 'react';

import { Title, Separator } from '../KeluarModal/styled';
import { Content, Button, ButtonContainer } from './styled';
import { t } from 'hooks/intl/useLang';

const SmallDarkModal = ({ title = '', children, modalState, ...rest }) => {
  return (
    <PopupModal dark medium={false} {...rest}>
      <Title>{title}</Title>
      <Separator />
      <Content>{children}</Content>
      <ButtonContainer className="mx-3">
        <Button onClick={() => modalState(false)}>{t('Tutup')}</Button>
      </ButtonContainer>
    </PopupModal>
  );
};

export default SmallDarkModal;
