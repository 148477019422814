import React from 'react';
import {
  DescriptionItem,
  DescriptionMobile,
  ItemPembayaran,
  Logo,
  LogoContainer,
  PembayaranItemStyled,
  RadioIcon,
  RecurringContainer,
  RecurringText,
  TextItemContainer,
  RadioCheck,
  RadioCheckMobile,
} from './styled';
import checkbox from 'assets/svg/checkbox/check.svg';
import uncheckbox from 'assets/svg/checkbox/uncheck.svg';
import selectedIcon from 'assets/svg/option/selected-round.svg';
import { checkMobile } from 'utils/checkMobile';

function PembayaranItem({
  mobile,
  dompet,
  selected,
  payment,
  isRecurring,
  setIsRecurring,
  ...rest
}) {
  return (
    <PembayaranItemStyled selected={selected} {...rest}>
      {checkMobile() ? (
        <>{selected ? <RadioCheckMobile src={selectedIcon} alt="check" /> : <RadioCheck />}</>
      ) : (
        <RadioCheck selected={selected}>{selected ? <div /> : ''}</RadioCheck>
      )}
      <ItemPembayaran dompet={dompet}>
        <LogoContainer>
          <Logo dompet={dompet} src={payment.logo} alt={payment.name} />
          {/* takeoff recurring */}
          {/* {payment.hasRecurring && (
            <RecurringContainer onClick={() => setIsRecurring(!isRecurring)}>
              <RecurringText>Recurring</RecurringText>
              <RadioIcon requrring src={isRecurring && selected ? checkbox : uncheckbox} />
            </RecurringContainer>
          )} */}
        </LogoContainer>
        <TextItemContainer>
          <DescriptionItem mobile>{payment.name}</DescriptionItem>
          {mobile && <DescriptionMobile>Tautkan akun {payment.name}</DescriptionMobile>}
          {/* {payment.hasRecurring && (
            <RecurringContainer mobile onClick={() => setIsRecurring(!isRecurring)}>
              <RecurringText>Recurring</RecurringText>
              <RadioIcon requrring src={isRecurring && selected ? checkbox : uncheckbox} />
            </RecurringContainer>
          )} */}
        </TextItemContainer>
        <DescriptionItem>{payment.desc}</DescriptionItem>
      </ItemPembayaran>
    </PembayaranItemStyled>
  );
}

export default PembayaranItem;
