import styled from 'styled-components';

export const Container = styled.div.attrs((props) => ({
  border: props.danger ? '#D51919' : '#C4C4C4',
  borderSize: props.danger ? '2px' : '1px',
}))`
  margin: 0 auto;
  width: 100%;

  .react-select__control {
    position: relative;
    z-index: 0;
    margin: ${({ dark }) => (dark ? '0px' : '16px 0 4px')};
    padding: 7px;
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    background: transparent;

    border: ${(props) => props.borderSize} solid ${(props) => props.border};

    ${({ dark }) => (dark ? 'border: 1px solid #626262;' : '')}

    color: ${({ dark }) => (dark ? '#fdfdfd' : '#626262')};
    height: auto;
  }

  .react-select__value-container {
    width: 100%;
  }
  .react-select__single-value {
    ${({ dark }) => dark && 'color: #fdfdfd;'}
  }
  .react-select__menu {
    background: ${({ dark }) => (dark ? '#373737' : '#fdfdfd')};
    text-align: left;
    font-size: 16px;
    line-height: 19px;
  }

  .react-select__multi-value {
    font-size: 14px;
    line-height: 17px;
    color: #fdfdfd;
    background: ${({ dark }) => (dark ? '#626262' : '#f1f0f0')};
    border-radius: 5px;

    margin-right: 8px;
  }

  .react-select__multi-value__label {
    color: ${({ dark }) => (dark ? '#fdfdfd' : '#141414')};
    padding: 7px 8px;
    font-size: 14px;
  }

  .react-select__multi-value__remove {
    color: ${({ dark }) => (dark ? '#fdfdfd' : '#141414')};
  }

  .react-select__multi-value__remove:hover {
    background-color: ${({ dark }) => (dark ? '#626262' : '#f1f0f0')};
    color: ${({ dark }) => (dark ? '#fdfdfd' : '#141414')};
    border-radius: 5px;
  }

  .react-select__input {
    color: ${({ dark }) => (dark ? '#fdfdfd' : '#141414')};
  }

  .react-select__input > input {
    font-family: Inter, sans-serif;
  }

  .react-select__option {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${({ dark }) => (dark ? '#fdfdfd' : '#141414')};
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
  }

  .react-select__option:hover {
    background: ${({ dark }) => (dark ? '#474747' : '#f1f0f0')};
  }

  .react-select__loading-message {
    font-size: 22px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  @media screen and ${(props) => props.theme.breakpoints.xl} {
    .react-select__control,
    .react-select__menu,
    .react-select__loading-message,
    .react-select__single-value,
    .react-select__option {
      font-size: 16px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    .react-select__value-container {
      max-width: 650px;
      width: 100%;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    .react-select__value-container {
      max-width: 300px;
      ${({ dark }) => dark && 'padding-left: 16px;'}
      width: 100%;
    }

    .react-select__control {
      padding: ${({ dark }) => (dark ? '1px; margin-top: 8px;' : '5px 10px')};
      height: auto;
    }

    .react-select__control {
    }

    .react-select__multi-value {
      ${({ dark }) => dark && 'border-radius: 2px;'}
    }

    .react-select__multi-value__label {
      ${({ dark }) => dark && 'padding: 4px;'}
      font-size: 10px;
    }

    .react-select__control,
    .react-select__menu,
    .react-select__loading-message,
    .react-select__single-value,
    .react-select__option {
      font-size: ${({ dark }) => (dark ? '14px' : '16px')};
    }
  }
`;

export const ContainerOption = styled.div`
  .col {
    display: flex;
    align-items: flex-end;
  }
  .container-image {
    width: 22px;
    height: 20px;
    margin-right: 20px;
    display: grid;
    place-items: center;

    & > img {
      max-width: 100%;
    }
  }
`;

export const LabelInput = styled.div.attrs((props) => ({
  show: props.focus ? 'none' : 'hidden',
}))`
  position: absolute;
  visibility: ${(props) => props.show};
  background-color: white;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  margin-left: 18px;
  top: 10px;
  z-index: 2;
  color: #626262;
`;
