import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  z-index: ${(props) => (props.prakerja ? '1' : '')};

  &.relative {
    position: relative;
  }
`;
