import React from 'react';
import PopupModal from 'components/atoms/Modal';
import { ReactComponent as IconClose } from 'assets/svg/modalclose.svg';
import { Title, Description, Button, Container } from './styled';
import { t } from 'hooks/intl/useLang';

const PopupUrutanVideoB2B = ({ linkTraining, setShowModalLock, router, ...rest }) => {
  return (
    <PopupModal dark medium {...rest}>
      <Container>
        <div>
          <IconClose />
        </div>
        <Title>{t('Kamu Belum Bisa Tonton Training Ini')}</Title>
        <Description>
          {t('Kamu harus menyelesaikan training sebelumnya untuk lanjut menonton training ini.')}
        </Description>
        <Button onClick={() => setShowModalLock(false)}>{t('Tutup')}</Button>
      </Container>
    </PopupModal>
  );
};

export default PopupUrutanVideoB2B;
