import React from 'react';
import {
  BannerTBMContainer,
  BoldTitleTBM,
  ButtonBannerTBM,
  ContentBannerTBM,
  DescBannerTBM,
  TitleBannerTBM,
} from './styled';
import { scrollMidle } from '../../../../utils/scrollPage';
import { phonesize } from '../../../../utils/checkMobile';

const BannerTBM = () => {
  return (
    <BannerTBMContainer>
      <ContentBannerTBM>
        <TitleBannerTBM>
          Cari Tahu Potensi Karir <BoldTitleTBM>Terbaikmu</BoldTitleTBM>{' '}
        </TitleBannerTBM>
        <DescBannerTBM>
          Ikuti Terampil Career Aptitude Test bisa membantu kamu lebih mudah menemukan dan
          memaksimalkan Potensi Karier Terbaikmu. Ikuti Tes Sekarang & Dapatkan 1 Modul Training!
        </DescBannerTBM>
        <ButtonBannerTBM
          onClick={() => {
            window.scrollTo(0, phonesize() ? 1350 : 1200);
          }}
        >
          Mulai Tes
        </ButtonBannerTBM>
      </ContentBannerTBM>
    </BannerTBMContainer>
  );
};

export default BannerTBM;
