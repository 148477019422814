import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;
  h2 {
    ${(props) => props.theme.font.primary.title};
    font-size: 20px;
    text-align: left;
    margin: 8px 0;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    h2 {
      font-size: 16px;
      line-height: 21px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin-top: 10px;
  }
`;

export const SectionUp = styled.div`
  margin-bottom: 50px;

  div {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      ${(props) => props.theme.font.primary.title};
      font-size: 14px;
      font-weight: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #c4c4c4;
      margin: 4px 0;
    }
  }
  h6 {
    ${(props) => props.theme.font.secondary.subtitle2};
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #c4c4c4;
    margin: 16px 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    div {
      width: 80%;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    div {
      width: 100%;

      p {
        font-size: 12px;
        line-height: 24px;
        margin: 2px;
      }

      p.none,
      svg.none {
        display: none;
      }
    }
    h6 {
      font-size: 14px;
      line-height: 20px;
      margin: 8px 0;
    }

    margin-bottom: 24px;
  }
`;

export const Point = styled.ul`
  ${(props) => props.theme.font.secondary.subtitle2};
  list-style: none;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  padding-left: 0;
  margin: 8px 0;

  li {
    padding: 6px 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: 15px;
    }
    p {
      margin: 0;
      color: #c4c4c4;
      line-height: 24px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    line-height: 20px;

    svg {
      margin-right: 11px;
    }

    li {
      padding: 8px 0px;
    }
  }
`;

export const BannerClubhouse = styled.div`
  /* background-image: url('${(props) => props.image}');
  border-radius: 5px;
  background-position: center center;
  background-size: cover; */
  width: 100%;
  /* height: 300px; */
  margin-bottom: 50px;
  cursor: pointer;

  /* @media screen and ${(props) => props.theme.breakpoints.xl} {
    height: 240px;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 100%;
    height: 210px;
  } */

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin-bottom: 20px;
  }
`;
