import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  .link-training {
    text-decoration: none;
  }
`;

export const Card = styled.div`
  display: flex;
  background: #202020;
  opacity: ${(props) => (props.lockTraining ? '40%' : '')};
  border-radius: 10px;
  box-sizing: border-box;
  width: ${(props) => (props.pelatihanSaya ? '100' : '97')}%;
  height: 200px;
  margin: ${(props) => (props.pelatihanSaya ? '0' : '0 10')}px;
  flex-shrink: 0;
  cursor: pointer;
  ${({ freeTrial }) => (freeTrial ? 'background: rgba(253, 253, 253, 0.3);' : '')}
  /* box-sizing: border-box; */

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background: ${({ freeTrial }) => (freeTrial ? '' : '#373737')};
    }
  }

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    height: 140px;
    width: ${(props) => (props.pelatihanSaya ? '100%' : '300px')};
    border-radius: 5px;
    margin: 0 0 0 16px;

    &:first-child {
      margin-left: ${(props) => (props.pelatihanSaya ? '0px' : '20px')};
    }
  }
`;

export const SideCover = styled.div`
  flex: 3;
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;

  .image-cover {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;
    object-fit: cover;
    ${({ freeTrial }) => (freeTrial ? 'opacity:0.6' : '')}
  }
`;

export const TrainingContent = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  padding: 16px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    flex: 7;
    padding: 8px;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
  }

  h3 {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    color: #fdfdfd;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      font-size: 14px;
      line-height: 16px;
    }
  }

  span {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #fdfdfd;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .circle {
    height: 4px;
    width: 4px;
    background-color: #fdfdfd;
    border-radius: 50%;
    float: left;
    margin: 0 12px;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      margin: 0 4px;
      height: 3px;
      width: 3px;
    }
  }
`;

export const Title = styled.p`
  font-family: Archivo, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  color: #fdfdfd;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 0 8px 0;
  min-height: 50px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 13px;
    line-height: 21px;
    margin: 0 0 8px;
  }
`;

export const Trainer = styled.p`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin: 0;

  color: #c4c4c4;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  padding-bottom: 4px;
  margin-bottom: 24px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 10px;
    line-height: 15px;
  }
`;

export const FlagBanner = styled.div`
  background: #141414;
  border-radius: 2px;
  padding: 10px;
  color: #fdfdfd;
  position: absolute;
  top: 10px;
  left: ${({ pelatihanSaya }) => (pelatihanSaya ? '10px' : '20px')};
  font-family: Inter;
  opacity: 0.8;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: ${({ freeTrial }) => (freeTrial ? '#F18F01' : '#fdfdfd')};

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 10px;
    padding: 5px;
    top: 5px;
    left: ${({ pelatihanSaya }) => (pelatihanSaya ? '5px' : '10px')};
  }
`;

export const FlagBannerPrakerja = styled.div`
  background: #00debf;
  border-radius: 2px;
  padding: 10px;
  color: #141414;
  position: absolute;
  top: 10px;
  left: ${({ pelatihanSaya }) => (pelatihanSaya ? '10px' : '20px')};
  font-family: Inter;
  opacity: 0.8;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 10px;
    padding: 5px;
    top: 5px;
    left: ${({ pelatihanSaya }) => (pelatihanSaya ? '5px' : '10px')};
  }
`;
