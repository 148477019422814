import styled from 'styled-components';

export const Container = styled.div`
  & > div {
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #626262;
    color: #fdfdfd;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    line-height: 24px;
    min-height: 120px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    & > div {
      font-size: 14px;
      padding: 8px 16px;

      margin-top: 8px;
    }
  }
`;
