import React, { useEffect, useState } from 'react';
import { ButtonCircle, PaginationContainer, PaginationItem } from './styled';

import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';

function Pagination({ countPage, firstPage = 1, setPageLoad }) {
  const [selected, setSelected] = useState(firstPage);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    for (let page = 1; page <= countPage; page++) {
      if (page === 1) continue;
      if (page === countPage) continue;
      if ((page <= selected + 2 && page >= selected) || (page === selected - 1 && page > 1)) {
        setPages((prevPages) => [
          ...prevPages,
          <PaginationItem onClick={() => changePage(page)} selected={page === selected}>
            {page}
          </PaginationItem>,
        ]);
      }
    }
  }, [selected]);

  function changePage(page) {
    setSelected(page);
    setPageLoad(page);
    setPages([]);
  }

  //update
  return (
    <PaginationContainer>
      {selected !== 1 && (
        <ButtonCircle onClick={() => changePage(selected - 1)}>
          <PrevIcon />
        </ButtonCircle>
      )}
      <PaginationItem onClick={() => changePage(1)} selected={1 === selected}>
        {1}
      </PaginationItem>
      {1 <= selected - 3 && <PaginationItem>...</PaginationItem>}
      {pages}
      {selected + 3 <= countPage && <PaginationItem>...</PaginationItem>}
      <PaginationItem onClick={() => changePage(countPage)} selected={countPage === selected}>
        {countPage}
      </PaginationItem>
      {selected !== countPage && (
        <ButtonCircle onClick={() => changePage(selected + 1)}>
          <NextIcon />
        </ButtonCircle>
      )}
    </PaginationContainer>
  );
}

export default Pagination;
