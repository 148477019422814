import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  padding: 0 92px 0 100px;
  margin-top: 50px;

  .shaka-play-button {
    padding: 4%;
  }
  .shaka-spinner {
    padding: 4.3%;
  }

  video[poster] {
    object-fit: cover;
    border-radius: 10px;
    @media screen and ${(props) => props.theme.breakpoints.md} {
      border-radius: 5px;
    }
  }

  .video-adds {
    border-radius: 10px;
    width: 85%;
    position: absolute;
    top: 2.2rem;
    right: 32px;
  }

  p {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 200;
    font-size: 12.5541px;
    line-height: 14px;
    text-align: center;

    color: #606060;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0 60px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 0 40px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0 25px;
    margin-top: ${({ type }) => (type === 'landing_page' ? 0 : 32)}px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
`;

export const Player = styled.video`
  height: 100%;
  width: 100%;
`;

export const VideoPlayer = styled.video`
  width: inherit;
  height: 702px;
  cursor: pointer;

  :active {
    outline: none;
  }

  :focus {
    outline: none;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 100%;
    height: 520px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 420px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    height: 180px;
  }
`;

export const BgPhoneContainer = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  height: 573px;
  background-image: url('${({ bgPhone }) => bgPhone}');
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;

  .container-laptop {
    position: relative;
  }
  .container-iphone {
    position: relative;
  }

  .container-gif-iphone {
    position: absolute;
    left: -4px;
  }

  .available {
    align-self: center;
    margin: 0 12px;
    margin-top: 10rem;
  }
`;

export const ImageLaptop = styled.img`
  width: 700px;
  background-image: url('${({ bgPhone }) => bgPhone}');

  /* height: 384px; */
`;

export const ImageIphone = styled.div`
  width: 184px;
  height: 384px;
  background-image: url('${({ bgPhone }) => bgPhone}');
  position: relative;
`;

export const GifIphone = styled.img`
  width: 194px;
  height: 370px;
`;

export const ContainerLogo = styled.div`
  margin-right: auto;

  .app-store {
    width: 161px;
    height: 54px;
    margin-bottom: -12px;

    cursor: pointer;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: 0;
      float: left;
    }
    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  .playstore {
    width: 187px;
    height: 78px;
    margin-bottom: -12px;

    cursor: pointer;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: -12px;
      float: left;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  .logo {
    width: 100%;
    height: auto;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: -12px;
      float: left;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  .logo-none {
    cursor: auto;
    width: 100%;
    height: auto;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      margin-left: -12px;
      float: left;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin-bottom: 2px;
      float: left;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    height: auto;
  }
`;
