import React from 'react';
import PopupModal from 'components/atoms/Modal';
import { Container, Image } from './CekEmailModal.styled';
import { emailVar } from 'utils/makeVarAuth';
import Button from 'components/atoms/Button';
import emailPopUp from 'assets/svg/mail-pop-up.svg';

function CekEmailModal({ setCheckRegisterEmail, ...rest }) {
  return (
    <PopupModal withoutCloseButton onRequestClose {...rest}>
      <Container>
        <Image src={emailPopUp} alt="icon-mail" />
        <h2 className="title">Cek Email</h2>
        <p className="desc">
          Kami telah mengirimkan sebuah email ke {emailVar()}. Klik link verifikasi untuk
          menyelesaikan proses registrasi.
        </p>
        <Button onClick={() => setCheckRegisterEmail(1)} className="button-oke" primary large>
          Oke
        </Button>
      </Container>
    </PopupModal>
  );
}

export default CekEmailModal;
