import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 0px;
  background-color: #141414;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 58px;
  padding: 80px 100px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 40px 60px;
    gap: 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    flex-direction: column;
    padding: 55px 16px 32px;
    gap: 0;
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  width: 550px;
  height: 312px;
  background-color: #000004;
  border-radius: 20px;
  transform: translate(-15px, 30px);

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 402px;
    height: 260px;
    padding: 0;
    transform: translate(-10px, 20px);
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 362px;
    height: 220px;
    padding: 0;
    transform: translate(-10px, 20px);
    border-radius: 5px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 312px;
    height: 180px;
    transform: translate(-8px, 16px);
  }
`;

export const Image = styled.img`
  position: relative;
  width: 550px;
  height: 312px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 410px;
    height: 270px;
    padding: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 370px;
    height: 230px;
    padding: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 320px;
    height: 180px;
  }
`;

export const Points = styled.div`
  display: grid;
  flex-direction: column;
  gap: 16px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    gap: 14px;
  }
`;

export const Title = styled.h5`
  margin: 0;
  margin-bottom: 33px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 30px;
    margin-bottom: 20px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    margin-top: 32px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const IconRoundChekList = styled.img`
  align-self: flex-start;
  margin: 7px;
`;

export const Body = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #c4c4c4;
  text-align: left;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 14px;
    font-family: 'Inter';
    line-height: 21px;
  }
`;
