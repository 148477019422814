import React from 'react';
import loadingDefault from 'assets/gif/default-loading.gif';
import { Container, Loading } from './styled';

const LoadingDefaultLogo = () => {
  return (
    <Container>
      <Loading src={loadingDefault} alt="loading" />
    </Container>
  );
};

export default LoadingDefaultLogo;
