import React, { useState, useEffect } from 'react';
import { Container, Icon, FlexCard, TextBenefit, DetailBenefitMobile, Button } from './styled';
import CardPriceHybrid from 'components/molecule/Cards/CardPriceHybrid';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { ReactComponent as PriceTagIcon } from 'assets/svg/subcriptionIcon/priceTag.svg';
import {
  GET_PACKAGE_TRAININGS,
  GET_SUBSCRIBE_TRAININGS,
  GET_TRAININGS,
} from 'queries/packageTraining';
import QuotaIcons from 'assets/svg/subcriptionIcon/quota.svg';
import ForumIcon from 'assets/svg/subcriptionIcon/forum.svg';
import CertificateIcon from 'assets/svg/subcriptionIcon/sertifikat.svg';
import DownloadOfflineIcon from 'assets/svg/subcriptionIcon/downloadOffline.svg';
import MobileDeskIcon from 'assets/svg/subcriptionIcon/mobileDesktop.svg';
import { ReactComponent as Triangle } from 'assets/svg/triangleDown.svg';
import { getValue, setContext } from 'utils';
import { useSelector } from 'react-redux';
import LoadingEllipsisComponent from 'components/atoms/Loading/LoadingEllipsis';
import LengkapiProfilModal from 'components/molecule/Modal/LengkapiProfilModal';
import OTPModal from 'components/molecule/Modal/OTPModal';
import { dataSubsNotAuth } from 'utils/makeVarAuth';
import { COMPLETE_PROFILE } from '../../../../mutation/profile';
import { GET_USER_DETAIL } from '../../../../queries/user';
import { t } from 'hooks/intl/useLang';

const imageIcon = [
  QuotaIcons,
  ForumIcon,
  CertificateIcon,
  DownloadOfflineIcon,
  MobileDeskIcon,
  Triangle,
];

const TrainingPackagePrice = ({
  detailTraining,
  haveTraining,
  userDetail,
  setShowModal,
  quota,
  haveTrainingFreeTrail,
  haveUnlockVideo,
}) => {
  const history = useHistory();
  const [disabledTraining, setDisabledTraining] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalOTP, setOpenModalOTP] = useState(false);
  const [selectPackage, setSelectPackage] = useState('');
  const [dataLengkapiProfile, setDataLengkapiProfile] = useState({});

  const authData = useSelector((state) => {
    return state.Auth;
  });

  const { data: dataPackageTraining, loading: loadingPackage } = useQuery(GET_PACKAGE_TRAININGS, {
    onCompleted: ({ PackageTrainings }) => {
      setSelected(PackageTrainings?.items[0]);
    },
  });

  const { data: dataTraining, loading: loadingTraining } = useQuery(GET_TRAININGS, {
    variables: { slug: detailTraining?.slug },
    onCompleted: ({ PackageTrainings }) => {
      setSelected(PackageTrainings?.items[0]);
    },
  });

  const { data: dataSubsTraining } = useQuery(GET_SUBSCRIBE_TRAININGS, {
    onCompleted: ({ PackageTrainings }) => {
      setSelected(PackageTrainings?.items[0]);
    },
  });

  const buttonDisabledHandle = () => {
    (haveTraining && !haveTrainingFreeTrail && !haveUnlockVideo) ||
    getValue(authData, 'data.allAccess')
      ? setDisabledTraining(true)
      : setDisabledTraining(false);
  };

  const handleButton = (type) => {
    setSelectPackage(type);
    // if(getValue(authData, "data.allAccess")){

    // }
    if (getValue(authData, 'data.accessToken')) {
      if (!userDetail?.User?.email || !userDetail?.User?.phone_number || !userDetail?.fullname) {
        setOpenModal(true);
      } else {
        switch (type) {
          case 'Bronze':
            if (quota > 0 && !userDetail?.User?.free_trial) {
              setShowModal(true);
            } else {
              history.push(`/pembayaran/${detailTraining?.slug}`);
            }
            break;
          case 'Silver':
          case 'Gold':
            history.push(`/pembayaran/${type}`);
            break;
          case 'Yearly-2':
            history.push(`/pembayaran/${type}`);
            break;
          default:
            break;
        }
      }
    } else {
      switch (type) {
        case 'Bronze':
          dataSubsNotAuth(detailTraining?.slug);
          break;
        case 'Silver':
        case 'Gold':
          dataSubsNotAuth(type);
          break;
        case 'Yearly-2':
          dataSubsNotAuth(type);
          break;
        default:
          break;
      }
      history.push('/registrasi');
    }
  };

  const [completeProfile, { error: errorCompleteProfile }] = useMutation(COMPLETE_PROFILE, {
    refetchQueries: [
      {
        query: GET_USER_DETAIL,
        context: setContext(authData),
        variables: {
          slug: getValue(authData, 'data.slug'),
        },
      },
    ],
    onCompleted({ CompletingProfile }) {
      const selected = selectPackage === 'Bronze' ? detailTraining?.slug : selectPackage;
      if (selected !== 'trainings' && selected) {
        history.push(`/pembayaran/${selected ?? ''}`);
      } else if (selected === 'trainings') {
        history.push(`/trainings`);
      }
      setOpenModalOTP(false);
    },
  });

  useEffect(() => {
    buttonDisabledHandle();
  }, [dataPackageTraining, haveTraining, haveTrainingFreeTrail, haveUnlockVideo]);

  if (loadingPackage) return <LoadingEllipsisComponent />;

  return (
    <Container id="ikutikelas">
      <h2>{t('Ikuti Training ini')}</h2>
      <FlexCard>
        {/* {dataPackageTraining?.PackageTrainings?.items?.map((menu, i) => {
          return (
            <CardPriceHybrid
              key={i}
              handleButtonMobile={handleButton}
              setSelected={setSelected}
              selected={selected === menu}
              onClick={() => setSelected(menu)}
              quota={quota}
              disabled={disabledTraining}
              index={i}
              menu={menu}
              freeTrial={userDetail?.User?.free_trial}
              benefit={menu?.benefit}
              promo={menu?.promo}
              training
            />
          );
        })} */}
        {dataPackageTraining?.PackageTrainings?.items?.map((menu, i) => {
          return menu?.package_type === 'Bronze' ? (
            <CardPriceHybrid
              key={i}
              handleButtonMobile={handleButton}
              setSelected={setSelected}
              selected={selected === menu}
              onClick={() => setSelected(menu)}
              quota={quota}
              disabled={disabledTraining}
              index={i}
              menu={{
                ...menu,
                price: dataTraining?.Training?.price,
                trainingPrice: dataTraining?.Training?.training_price,
              }}
              freeTrial={userDetail?.User?.free_trial}
              benefit={menu?.benefit}
              promo={menu?.promo}
              training
              detailTraining={detailTraining}
            />
          ) : null;
        })}
        {dataSubsTraining?.Subscriptions?.items?.map((menu, i) => {
          return (
            <CardPriceHybrid
              key={i}
              handleButtonMobile={handleButton}
              setSelected={setSelected}
              quota={quota}
              disabled={disabledTraining}
              menu={menu}
              freeTrial={userDetail?.User?.free_trial}
              training
              detailTraining
            />
          );
        })}
      </FlexCard>
      <DetailBenefitMobile>
        <div>
          <PriceTagIcon />
          <TextBenefit>
            {t('Harga')} Rp{' '}
            <span>
              {
                // ? detailTraining?.training_price?.toLocaleString('id')
                selected?.price?.toLocaleString('id')
              }
            </span>{' '}
            {t('untuk Training ini')}
          </TextBenefit>
        </div>
        {selected?.benefits?.map((item, i) => {
          return (
            <div key={i}>
              <Icon src={imageIcon[i]} alt="logo" />
              <TextBenefit bold={i === 0}>{item?.text}</TextBenefit>
            </div>
          );
        })}
        <Button
          disabled={haveTraining && selected?.package_type === 'Bronze'}
          onClick={() => handleButton(selected?.package_type)}
          outline={selected?.package_type === 'Bronze'}
        >
          {t('Beli Training')}
        </Button>
      </DetailBenefitMobile>
      {userDetail && (
        <LengkapiProfilModal
          setOpenModalOTP={setOpenModalOTP}
          setOpenModal={setOpenModal}
          isOpen={openModal}
          profil={userDetail}
          setDataLengkapiProfile={setDataLengkapiProfile}
          onRequestClose={() => setOpenModal(false)}
          selected={selectPackage === 'Bronze' ? detailTraining?.slug : selectPackage}
        />
      )}
      {openModalOTP && (
        <OTPModal
          lengkapiProfile
          dark
          dataLengkapiProfile={dataLengkapiProfile}
          isOpen={openModalOTP}
          setOpenModalOTP={setOpenModalOTP}
          onRequestClose={() => setOpenModalOTP(false)}
          selected={selectPackage === 'Bronze' ? detailTraining?.slug : selectPackage}
          onSuccessOTP={() => {
            completeProfile({
              context: setContext(authData),
              variables: {
                input: {
                  fullname: dataLengkapiProfile.fullname,
                  email: dataLengkapiProfile.email,
                  phoneNumber: dataLengkapiProfile.phoneNumber,
                },
              },
            });
          }}
        />
      )}
    </Container>
  );
};

export default TrainingPackagePrice;
