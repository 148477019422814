import styled from 'styled-components';
import InputField from 'components/atoms/InputField';
import PembayaranItem from '../../../molecule/PembayaranItem';

export const KartuKreditTabStyled = styled.div`
  padding: 24px 32px 72px;
  display: ${(props) => (props.hide ? 'none' : 'block')};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
    padding: 0 16px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 100px;
  object-fit: contain;

  @media only screen and ${(props) => props.theme.breakpoints.md} {
    width: 72px;
  }
`;

export const FormCcContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: none;
  }
`;

export const FormLabel = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: ${(props) => (props.secondary ? 'right' : 'left')};
  flex: 1;
  margin: ${(props) => (props.secondary ? '0 30px 0 0' : '0')};

  color: ${(props) => props.theme.color.white};

  @media only screen and ${(props) => props.theme.breakpoints.md} {
    margin: ${(props) => (props.secondary ? '0 12px 0 0' : '0')};
  }
`;

export const InputCcField = styled(InputField)`
  flex: ${(props) => (props.small ? '1' : '4')};
  margin: 0;
  color: ${(props) => props.theme.color.white};
  border-color: #626262;

  ::placeholder {
    color: #626262;
  }
`;

export const DoubleForms = styled.div`
  display: flex;
  flex: 4;
  align-items: center;
`;

export const TitleMobile = styled.h3`
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  display: none;
  margin: 24px 0 0;
  text-align: left;

  color: ${(props) => props.theme.color.white};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
  }
`;

export const CcItemMobile = styled(PembayaranItem)`
  display: none;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: flex;
    border-bottom: 0;
    padding-bottom: 4px;
  }
`;
