import Button from 'components/atoms/Button';
import React, { useEffect, useState } from 'react';
import { Separator } from '../MetodePembayaran/styled';
import { FieldVoucher, VoucherContainer } from '../RingkasanPembayaran/styled';
import {
  Container,
  SeparatorMobile,
  TableTraining,
  TitleTraining,
  TrainerName,
  ImageTraining,
  BackText,
} from './styled';
import { ReactComponent as CheckVoucher } from 'assets/svg/valid-voucher.svg';
import { useHistory } from 'react-router';
import { checkMobile } from 'utils/checkMobile';
import FormError from 'components/atoms/FormError';
import { useLazyQuery } from '@apollo/client';
import { GET_VOUCHER_PRAKERJA } from 'queries/voucher';
import { useSelector } from 'react-redux';
import { setContext } from 'utils';
import { ReactComponent as BackArrow } from 'assets/svg/arrow-voucher.svg';
import { LoaderIcon } from 'react-hot-toast';
import { t } from 'hooks/intl/useLang';

const LanggananPrakerja = ({
  createOrder,
  dataPrakerja,
  user,
  errorVoucherPrakerja,
  setErrorVoucherPrakerja,
  loadingCreateOrder = false,
  isToken,
  name,
}) => {
  const [codeVoucher, setCodeVoucher] = useState(null);
  const [voucherError, setVoucherError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [validVoucher, setValidVoucher] = useState(false);
  const history = useHistory();
  const isMobile = checkMobile();

  const authData = useSelector((state) => state.Auth);
  let timeoutId = null;

  const [checkVoucherPrakerja, { loading: loadingVoucherPrakerja, data: dataTraining }] =
    useLazyQuery(GET_VOUCHER_PRAKERJA, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ CheckPrakerjaVoucher }) => {
        if (CheckPrakerjaVoucher?.item?.Training?.slug === name || name === 'Prakerja') {
          setValidVoucher(true);
          setVoucherError(false);
        } else {
          setVoucherError(true);
          setErrorMessage(t('Kode Voucher tidak valid untuk training ini'));
        }

        console.log();
      },
      onError: (error) => {
        console.error(error.message);
        setVoucherError(true);
        setErrorMessage(t('Kode Voucher tidak valid'));
      },
    });

  useEffect(() => {
    if (codeVoucher === '') {
      setVoucherError(false);
      setCodeVoucher(null);
      setValidVoucher(false);
      setErrorVoucherPrakerja(null);
    }
    if (checkMobile() && codeVoucher) {
      checkVoucherPrakerja({
        variables: {
          voucherCode: codeVoucher,
        },
      });
    }
  }, [codeVoucher]);

  const createOrderPrakerja = () => {
    if (isToken) {
      dataPrakerja['isMobile'] = isMobile;
      dataPrakerja['phoneNumber'] = user?.ProfileUser?.User?.phone_number;
      dataPrakerja['voucherCode'] = codeVoucher;
      dataPrakerja['fullname'] = user?.ProfileUser?.fullname;

      return createOrder({
        variables: {
          input: dataPrakerja,
        },
      });
    } else {
      history.push(`/registrasi?checkvoucher=${codeVoucher}`);
    }
  };

  return (
    <Container>
      {isToken ? (
        <div className="wrapper-top">
          <div className="flex-back" onClick={() => history.goBack()}>
            <BackArrow /> <BackText> {t('Kembali')}</BackText>
          </div>

          <h6>{t('Beli Training Prakerja')}</h6>
        </div>
      ) : (
        <h6>{t('Beli Training Prakerja')}</h6>
      )}

      <div className="wrapper">
        <div className={isMobile ? 'container-mobile' : 'container-box'}>
          <div className={isMobile ? 'wrap-mobile' : ''}>
            <div className="wrapper-content">
              <span className="title">Prakerja</span>
              {/* <p onClick={() => history.push('/berlangganan')} className="ganti-plan">
                Ganti Plan
              </p> */}
            </div>
            <div className="wrapper-kode">
              <p className="voucher-kode">
                {t('Tambahkan kode voucher yang sudah kamu beli di Tokopedia dan MauBelajarApa')}
              </p>
              <VoucherContainer className={voucherError || errorVoucherPrakerja ? 'error' : ''}>
                <FieldVoucher
                  placeholder={t("Tambah Kode Voucher")}
                  onChange={(val) => {
                    if (checkMobile()) {
                      if (timeoutId) clearTimeout(timeoutId);
                      timeoutId = setTimeout(() => {
                        setCodeVoucher(val.target.value);
                      }, 1000);
                    } else {
                      setCodeVoucher(val.target.value);
                    }
                    if (codeVoucher) {
                      setVoucherError(false);
                      setValidVoucher(false);
                      setErrorVoucherPrakerja(null);
                    }
                  }}
                />
                {!checkMobile() &&
                  (validVoucher ? (
                    <div>
                      <CheckVoucher />
                    </div>
                  ) : loadingVoucherPrakerja ? (
                    <div>
                      <LoaderIcon />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        if (codeVoucher && codeVoucher.length >= 5) {
                          checkVoucherPrakerja({
                            variables: {
                              voucherCode: codeVoucher,
                            },
                          });
                        }
                      }}
                    >
                      {t('Cek')}
                    </div>
                  ))}
              </VoucherContainer>
              {voucherError && <FormError lengkapiProfile>{errorMessage}</FormError>}
              {errorVoucherPrakerja && (
                <FormError lengkapiProfile>{errorVoucherPrakerja}</FormError>
              )}
            </div>
          </div>
          <div className="wrapper-btn">
            <Button
              onClick={() => createOrderPrakerja()}
              className={!validVoucher ? 'btn-redeem-disable' : 'btn-redeem'}
            >
              {loadingCreateOrder ? (
                <div className="loader-icon">
                  <LoaderIcon className="loading-icon" />
                </div>
              ) : (
                'Redeem Voucher'
              )}
            </Button>
          </div>
          {validVoucher && !loadingVoucherPrakerja && (
            <div className="wrapper-kode training-margin">
              <TableTraining>
                <ImageTraining
                  img={dataTraining?.CheckPrakerjaVoucher?.item?.Training?.thumbnail}
                />
                <div className="content-table">
                  <TitleTraining>
                    {dataTraining?.CheckPrakerjaVoucher?.item?.Training?.title}
                  </TitleTraining>
                  <TrainerName>
                    {dataTraining?.CheckPrakerjaVoucher?.item?.Training?.trainer?.fullname}
                  </TrainerName>
                </div>

                {/* <div className="head-table">
                  <h2 className="detail-training">Detail Training</h2>
                </div>
                <div className="content-table">
                  <TitleTraining>
                    Silence & Curiosity: A Guide to Self-Reflection and Self-Discovery
                  </TitleTraining>
                  <TrainerName>James Gwe</TrainerName>
                </div> */}
              </TableTraining>
            </div>
          )}
          {isMobile ? <SeparatorMobile /> : <Separator />}
          <div className="wrapper-kode">
            <p className="voucher-kode">
              {t('Jika kamu belum punya voucher, silahkan beli voucher Terampil di Karier.mu, Tokopedia, dan Pintar.co')}
            </p>
            <a
              href="https://app.karier.mu/mitra/terampil"
              alt="Karier.mu"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="btn-link-voucher">Karier.mu</Button>
            </a>
            <a
              href="https://www.tokopedia.com/kartu-prakerja/partner/terampil/ "
              alt="tokopedia"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="btn-link-voucher">Tokopedia</Button>
            </a>
            <a
              href="https://pintar.co/kartuprakerja#/"
              alt="Pintar.co"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="btn-link-voucher">Pintar.co</Button>
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LanggananPrakerja;
