import styled from 'styled-components';

export const Container = styled.div`
  display: block;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    height: 100vh;
  }
  .training-margin {
    margin: 24px 0;
  }

  .wrapper-top {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 68%;
    gap: 20%;
  }

  .flex-back {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  h6 {
    /* padding-top: 24px; */
    text-align: center;
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #fdfdfd;
    margin: 24px;
  }

  div.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    div.container-mobile {
      display: block;
      background: transparent;
      width: 100%;
    }

    div.wrap-mobile {
      background: #202020;
      border-radius: 4px;
      margin: 16px;
    }

    div.container-box {
      display: block;
      background: #202020;
      width: 730px;
      border-radius: 5px;
    }

    div.wrapper-content {
      display: flex;
      padding: 25px 24px 0 25px;

      @media screen and ${(props) => props.theme.breakpoints.xs} {
        justify-content: space-between;
      }

      span.title {
        display: inline-block;
        font-family: Archivo;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #fdfdfd;
        text-align: left;

        @media screen and ${(props) => props.theme.breakpoints.xs} {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #fdfdfd;
        }
      }

      p.ganti-plan {
        font-family: Archivo;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        margin: 5px 0 0 24px;
        color: #00debf;
        cursor: pointer;

        @media screen and ${(props) => props.theme.breakpoints.xs} {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          color: #00debf;
        }
      }
    }

    div.wrapper-kode {
      padding: 0px 24px 16px 24px;
      display: block;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #c4c4c4;
      text-align: left;

      a {
        text-decoration: none;
      }

      p.voucher-kode {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #c4c4c4;

        @media screen and ${(props) => props.theme.breakpoints.xs} {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .btn-link-voucher {
        display: block;
        width: 100%;
        margin: 16px 0;
        border: 1px solid #626262;
        background: transparent;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fdfdfd;
      }
    }
  }

  div.wrapper-btn {
    margin: 0 24px;
    left: -5px;
    position: relative;

    .loader-icon {
      display: flex;
      justify-content: center;

      .loading-icon {
        width: 30px;
        height: 30px;
      }
    }

    .btn-redeem {
      width: 100%;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000;
      height: 50px;
    }

    .btn-redeem-disable {
      width: 100%;
      height: 50px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000;
      background-color: #626262;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    h6 {
      text-align: left;
      font-family: Archivo;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      color: #fdfdfd;
      padding: 0;
      margin-bottom: 0;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    /* div.wrapper {
      div.container-box {
        width: 630px;
      }
    } */
    margin-top: 70px;
  }
`;

export const BackText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
  /* identical to box height, or 214% */
  margin: 0;
  color: #fdfdfd;
  align-items: center;

  span {
    font-size: 30px;
  }
`;

export const SeparatorMobile = styled.hr`
  border: 5px solid #202020;
  margin: 24px 0 0;
  display: ${(props) => (props.mobile ? 'none' : 'block')};
`;

export const TableTraining = styled.div`
  width: 100%;
  height: 164px;

  border-radius: 10px;
  box-sizing: border-box;

  display: flex;

  .head-table {
    background: #373737;
    border-radius: 5px 5px 0px 0px;
    padding: 9px 24px;
  }
  .detail-training {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    /* identical to box height, or 169% */
    margin: 0;
    color: #fdfdfd;
  }

  .content-table {
    padding: 8px 24px;
    background-color: #303030;
    box-sizing: border-box;
    height: inherit;
    width: 70%;
    border-radius: 0px 10px 10px 0px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    .content-table {
      padding: 8px;
    }
  }
`;

export const TitleTraining = styled.h2`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TrainerName = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  /* identical to box height, or 193% */
  margin: 16px 0 0 0;
  color: #c4c4c4;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    line-height: 24px;
    margin-top: 8px;
  }
`;

export const ImageTraining = styled.div`
  background-image: url('${(props) => props.img}');
  background-size: cover;
  background-position: center;
  width: 257px;
  height: inherit;
  border-radius: 10px 0px 0px 10px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 150px;
  }
`;
