import React from 'react';
import {
  ButtonActionTest,
  ContainerTestCardTBM,
  ImageContainer,
  TextDescCardTest,
  TitleCardTest,
} from './styled';
import { Link } from 'react-router-dom';

const CardTestTBM = ({ title, image, desc, action, ...rest }) => {
  return (
    <ContainerTestCardTBM {...rest}>
      <TitleCardTest>{title}</TitleCardTest>
      <ImageContainer>{image}</ImageContainer>
      <TextDescCardTest>{desc}</TextDescCardTest>
      <Link style={{ width: '100%' }} to={action}>
        <ButtonActionTest>Ikuti Tes Ini Sekarang</ButtonActionTest>
      </Link>
    </ContainerTestCardTBM>
  );
};

export default CardTestTBM;
