import React from 'react';

import { Container } from '../TimeoutModal/styled.js';
import PopupModal from 'components/atoms/Modal';
import { ReactComponent as BelumAmbilIcon } from 'assets/svg/Kuis/belumambil.svg';
import Button from 'components/atoms/Button';
import { t } from 'hooks/intl/useLang.js';

const BelumAmbilKuisModal = ({
  setCurrentSection,
  moduleData,
  currentSection,
  setType,
  type,
  onRequestClose,
  ...rest
}) => {
  return (
    <PopupModal medium dark {...rest}>
      <Container>
        <BelumAmbilIcon />
        <h6>{t('Kamu Belum Bisa Ambil Tes Akhir')}</h6>
        <p className="mb-5">
          {t('Kamu harus menyelesaikan semua video materi dan kuis sebelum mengikuti tes akhir.')}
        </p>
        <Button small className="w-100" onClick={() => onRequestClose(false)}>
          {t('Oke')}
        </Button>
      </Container>
    </PopupModal>
  );
};

export default BelumAmbilKuisModal;
