/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Container, AvatarImage, ContainerName, Coment } from './styled';
import { ReactComponent as Star } from 'assets/svg/trainingDetails/star.svg';
import { ReactComponent as Circle } from 'assets/svg/PelatihanSaya/circle.svg';
import { formatLocaleDateShort } from 'utils/date';
import defaultUserPic from 'assets/svg/User Default.svg';
import { useQuery } from '@apollo/client';
import { GET_DETAIL_TRAINING, GET_TRAINING_COMMENTS, GET_TRAINING_RATING } from 'queries/training';
import { getValue, setContext } from 'utils';
import { useSelector } from 'react-redux';
import { GET_USER_DETAIL } from 'queries/user';
import { useParams } from 'react-router-dom';
import Toast from 'components/atoms/Notification/Toast';
import KonfirmasiHapusUlasanModal from 'components/molecule/Modal/KonfirmasiHapusUlasanModal';
import HapusUlasanModal from 'components/molecule/Modal/HapusUlasanModal';

const CardUlasanComent = ({ item, rate, userId, testimonyId, selected }) => {
  const [ulasan, setUlasan] = useState('');
  const [rating, setRating] = useState(0);
  const [isOpenHapusUlasanModal, setIsOpenHapusUlasan] = useState(false);
  const [isKonfirmasiHapusUlasanModal, setIsKonfirmasiHapusUlasan] = useState(false);
  const [showeditButton, setShoweditButton] = useState(false);

  const { data: getListUlasan } = useQuery(GET_TRAINING_COMMENTS);
  const { data: getListRatingUlasan } = useQuery(GET_TRAINING_RATING);

  const openModalKonfirmasiHapusUlasan = () => {
    setIsKonfirmasiHapusUlasan(true);
  };

  const onShoweditButton = () => {
    setShoweditButton(!showeditButton);
  };

  const authData = useSelector((state) => {
    return state.Auth;
  });

  const { slug } = useParams();

  const { data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    variables: {
      input: slug,
    },
  });

  const { data: userTraining } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const isMyId = userTraining?.ProfileUser?.id;

  return (
    <>
      <Toast position="top-right" />
      <Container>
        <div className="container">
          <AvatarImage image={item?.profile?.avatar ? item?.profile?.avatar : defaultUserPic} />
          <ContainerName>
            <h2>{item?.profile?.fullname}</h2>
            <div>
              <Star />
              <p>{item?.rating}</p> <Circle className="circle" />
              <p className="date">{formatLocaleDateShort(item?.created_at)}</p>
            </div>
          </ContainerName>
        </div>
        <Coment>{item?.text}</Coment>
      </Container>
    </>
  );
};

export default CardUlasanComent;
