import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { SET_BOOKMARK, UNSET_BOOKMARK } from 'mutation/training';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import useMousePosition from 'utils/useMousePosition';
import { checkMobile } from 'utils/checkMobile';
import { useSelector } from 'react-redux';
import { setContext } from 'utils';
import { convertDuration } from 'utils/duration';
import { trackWindowScroll, LazyLoadImage } from 'react-lazy-load-image-component';

import ShareModal from 'components/molecule/Modal/ShareModal';
import Toast from 'components/atoms/Notification/Toast';
import TrainingOverview from '../TrainingOverview';

import {
  CardTrainingStyled,
  Flag,
  FlagContainer,
  FlagBannerPrakerja,
  PromoTraining,
} from './CardTraining.styled';

import { ReactComponent as ShareIcon } from 'assets/svg/share.svg';
import star from 'assets/svg/star.svg';
import bookmark from 'assets/svg/bookmark.svg';
import bookmarked from 'assets/svg/bookmarked.svg';
import { t } from 'hooks/intl/useLang';

const CardTraining = ({
  training,
  grid,
  prakerja = false,
  trainer = false,
  scrollPosition,
  module,
  row,
  white = false,
  disabled = false,
  ...rest
}) => {
  const [isBookmarked, setIsBookmark] = useState(training?.isBookmark);
  const history = useHistory();
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const [showModal, setShowModal] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const [dataSetBookmark] = useMutation(SET_BOOKMARK, {
    context: setContext(authData),
    onCompleted: () => {
      window.Moengage.track_event('Bookmark Training', {
        training_name: training?.title,
        trainer_name: training?.trainer?.fullname,
      });
      toast(t('Training berhasil dibookmark'));
    },
    onError() {
      history.push('/masuk');
    },
  });

  const [dataUnSetBookmark] = useMutation(UNSET_BOOKMARK, {
    context: setContext(authData),
    onCompleted: () => toast(t('Training telah di unbookmark')),
    onError() {
      history.push('/masuk');
    },
  });

  const setBookmark = () => {
    if (!isBookmarked) {
      dataSetBookmark({
        variables: {
          idTraining: training?.id,
        },
      }).then((_) => { });
    } else {
      dataUnSetBookmark({
        variables: {
          idTraining: training?.id,
        },
      }).then((_) => { });
    }
  };

  const { clientX } = useMousePosition();

  let collab;

  if (training?.collaboration?.name && training?.cooperation?.name) {
    collab = <Flag collab>Collaboration with {training?.collaboration?.name}</Flag>;
  } else if (training?.cooperation?.name) {
    collab = <Flag review>Reviewed by {training?.cooperation?.name}</Flag>;
  }
  if (training?.training_prakerja) {
    collab = <Flag review>PRAKERJA</Flag>;
  }

  return (
    <>
      <Toast position="bottom-left" />
      <CardTrainingStyled
        row={row}
        trainer={trainer}
        prakerja={prakerja}
        grid={grid}
        promo={training?.promo}
        onMouseEnter={() => {
          if (!checkMobile() && !training?.training_prakerja) setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        {...rest}
      >
        {isHover && <TrainingOverview left={clientX < window.innerWidth / 2} training={training} />}
        {disabled ? (
          <>
            <div>
              <div className="cover-container">
                <LazyLoadImage
                  effect="blur"
                  placeholderSrc="cover-training"
                  scrollPosition={scrollPosition}
                  className="cover"
                  src={training?.thumbnail}
                  alt="Cover Trainnig"
                />
                <FlagContainer>{collab}</FlagContainer>
              </div>
              <div
                className="bookmark-container-popular"
                onClick={() => {
                  setIsBookmark((prevBookmark) => !prevBookmark);
                  setBookmark();
                }}
              >
                <img
                  className="bookmark"
                  src={isBookmarked ? bookmarked : bookmark}
                  alt="Button Bookmark"
                />
              </div>
              <div className="row">
                {/* <span>{training.viewers} viewers</span>
            <div className="circle" /> */}
                <span>{convertDuration(training?.durations)}</span>
                <ShareIcon className="share" onClick={() => setShowModal(true)} />
              </div>
            </div>
            <div className="training-info">
              <h1 style={{ color: white ? '#202002' : '' }}>{training?.title}</h1>
              <h2 style={{ color: white ? '#707070' : '' }}>{training?.trainer?.fullname}</h2>
              <div className="row">
                <img className="tinyIcon" src={star} alt={'Star Icon'} />
                <span className="penilaian-trainer">
                  {training?.trainer?.rating ? training?.trainer?.rating?.toFixed(1) : 0} (
                  {training?.total_rating} {t('penilaian')})
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <Link
                target={module ? '_blank' : ''}
                to={'/training/' + training?.slug + '/deskripsi'}
              >
                <div className="cover-container">
                  <LazyLoadImage
                    effect="blur"
                    placeholderSrc="cover-training"
                    scrollPosition={scrollPosition}
                    className="cover"
                    src={training?.thumbnail}
                    alt="Cover Trainnig"
                  />
                  <FlagContainer>{collab}</FlagContainer>
                </div>
              </Link>
              <div
                className="bookmark-container-popular"
                onClick={() => {
                  setIsBookmark((prevBookmark) => !prevBookmark);
                  setBookmark();
                }}
              >
                <img
                  className="bookmark"
                  src={isBookmarked ? bookmarked : bookmark}
                  alt="Button Bookmark"
                />
              </div>
              <div className="row">
                {/* <span>{training.viewers} viewers</span>
            <div className="circle" /> */}
                <span>{convertDuration(training?.durations)}</span>
                <ShareIcon className="share" onClick={() => setShowModal(true)} />
              </div>
            </div>
            <div className="training-info">
              <Link
                target={module ? '_blank' : ''}
                to={'/training/' + training?.slug + '/deskripsi'}
              >
                <h1 style={{ color: white ? '#202002' : '' }}>{training?.title}</h1>
              </Link>
              <Link target={module ? '_blank' : ''} to={'/trainer/' + training?.trainer?.slug}>
                <h2 style={{ color: white ? '#707070' : '' }}>{training?.trainer?.fullname}</h2>
              </Link>
              {training?.promo && (
                <PromoTraining>
                  <p className="price-discount">
                    Rp {training?.training_price.toLocaleString('id')}
                  </p>
                  <p className="price-normal">Rp {training?.price.toLocaleString('id')}</p>
                  <div className="percent-box">{training?.promo}% off</div>
                </PromoTraining>
              )}
              <div className="row">
                <img className="tinyIcon" src={star} alt={'Star Icon'} />
                <span className="penilaian-trainer">
                  {training?.trainer?.rating ? training?.trainer?.rating?.toFixed(1) : 0} (
                  {training?.total_rating} {t('penilaian')})
                </span>
              </div>
            </div>
          </>
        )}
      </CardTrainingStyled>
      <ShareModal
        onRequestClose={() => setShowModal(false)}
        urlPath={`https://www.terampil.com/training/${training?.slug}`}
        isOpen={showModal}
      />
    </>
  );
};

export default trackWindowScroll(CardTraining);
