import React from 'react';
import { components } from 'react-select';
import { ContainerOption } from './styled';

import { ReactComponent as NotSelectedIcon } from 'assets/svg/option/unselected-round.svg';
import { ReactComponent as SelectedIcon } from 'assets/svg/option/selected-round.svg';
import { useHistory } from 'react-router-dom';

const OptionFAQ = (props) => {
  const history = useHistory();

  return (
    <ContainerOption onClick={() => history.push(props.data.url)}>
      <components.Option {...props}>
        <div className="col">{props.data.label}</div>
        {!props.isSelected ? <NotSelectedIcon /> : <SelectedIcon />}
      </components.Option>
    </ContainerOption>
  );
};

export default OptionFAQ;
