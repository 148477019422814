import styled from 'styled-components';

export const Container = styled.div`
  display: grid;

  h2 {
    ${(props) => props.theme.font.primary.title} {
      font-size: 20px;
      text-align: left;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    h2 {
      font-size: 16px;
      line-height: 21px;
    }
  }
`;

export const AvatarImage = styled.div`
  background-image: url('${(props) => props.image}');
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  width: 97px;
  height: 97px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 47px;
    height: 47px;
    margin-top: 5px;
  }
`;

export const ContentTrainer = styled.div`
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    & > div {
      margin-bottom: 16px;
    }
  }

  .container-profile {
    display: flex;

    @media screen and ${({ theme }) => theme.breakpoints.xs} {
      display: block;
    }
  }
`;

export const DivTrainer = styled.div`
  display: flex;
  position: relative;

  .mobile-profile {
    display: block;
  }

  .wrap-button {
    margin-top: -50px;

    @media screen and ${({ theme }) => theme.breakpoints.xs} {
      margin: 1px 0 0 0;
    }
  }

  .button-unduh {
    margin-left: 15px;

    @media screen and ${({ theme }) => theme.breakpoints.xs} {
      margin-left: 0px;
    }

    .btn-dokumen {
      background: transparent;
      border: 1px solid #00debf;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #00debf;
      margin-left: 28px;

      @media screen and ${({ theme }) => theme.breakpoints.lg} {
        height: 60px;
      }

      @media screen and ${({ theme }) => theme.breakpoints.xs} {
        width: 140px;
        height: 39px;
        border: 1px solid #00debf;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }

    .btn-dokumen-none {
      font-family: Inter;
      border: 1px solid #626262;
      background: transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #626262;
      margin-left: 28px;
      pointer-events: none;
      cursor: auto;

      @media screen and ${({ theme }) => theme.breakpoints.lg} {
        height: 60px;
      }

      @media screen and ${({ theme }) => theme.breakpoints.xs} {
        width: 140px;
        height: 39px;
        border: 1px solid #626262;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }

    .btn-sertif {
      background: transparent;
      border: 1px solid #00debf;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #00debf;
      margin-left: 24px;

      @media screen and ${({ theme }) => theme.breakpoints.lg} {
        height: 60px;
      }

      @media screen and ${({ theme }) => theme.breakpoints.xs} {
        width: 140px;
        height: 39px;
        border: 1px solid #00debf;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }

    .btn-sertif-none {
      font-family: Inter;
      border: 1px solid #626262;
      background: transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #626262;
      margin-left: 24px;
      pointer-events: none;
      cursor: auto;

      @media screen and ${({ theme }) => theme.breakpoints.lg} {
        height: 60px;
      }

      @media screen and ${({ theme }) => theme.breakpoints.xs} {
        width: 140px;
        height: 39px;
        border: 1px solid #626262;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }

    .related-document {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      color: #c4c4c4;
      margin-top: 10px;
    }

    .ikon {
      margin-top: 10px;
      width: auto;
      height: 24px;
      margin-right: 16px;
    }
  }

  div.text {
    padding-left: 16px;
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 5px;

    @media screen and ${({ theme }) => theme.breakpoints.xs} {
      margin-top: -43px;
      margin-left: 47px;
    }

    & h3 {
      ${(props) => props.theme.font.secondary.subtitle2};
      font-size: 16px;
      font-weight: 500;
      margin: 3px 0 0 0;
      text-align: left;
      color: #fdfdfd;
      text-decoration: none;
    }

    & h4 {
      ${(props) => props.theme.font.secondary.subtitle2};
      font-size: 14px;
      color: #c4c4c4;
      line-height: 1.71;
      margin: 8px 0;
      text-align: left;
      width: 350px;
    }

    p {
      ${(props) => props.theme.font.secondary.subtitle2};
      font-size: 12px;
      color: #707070;
      margin: 0 10px;
      line-height: ${(props) => (props.line === 'line' ? '24' : '15')}px;
    }

    div.container-start {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 9px;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      justify-content: center;

      .container-start {
        position: absolute;
        width: 100vw;

        top: 50px;
        left: 0px;
      }

      & h3 {
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        text-align: left;
        margin: 0;
      }

      h4 {
        width: 300px;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        margin: 0;
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #c4c4c4;
      }

      padding-left: 8px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    .button-unduh {
      display: none;
    }
  }
`;

export const ContentRelated = styled.div``;

export const ContentDeskripsi = styled.div`
  p {
    ${(props) => props.theme.font.secondary.subtitle2};
    margin-top: 24px;
    font-size: 16px;
    text-align: left;
    color: #c4c4c4;
    line-height: 24px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    p {
      width: 350px;
      font-size: 14px;
      line-height: 20px;
      color: #c4c4c4;
    }

    p.mobile-paragraph {
      width: 350px;
      font-size: 14px;
      line-height: 20px;
      color: #c4c4c4;
      margin-top: 40px;
    }
  }
`;
