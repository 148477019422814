import styled from 'styled-components';

export const Title = styled.h2`
  font-family: 'Archivo';
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 45px;
  text-align: center;
  color: #fdfdfd;
  margin-top: 100px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin-top: 31px;
  }
`;

export const Container = styled.div`
  background-color: #000004;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 80px;

  margin-top: 80px;
  margin-bottom: 149px;
  padding: 0 300px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0 150px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    display: grid;
    grid-template-columns: auto;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 50px;
    grid-gap: 50px;
  }
`;

export const GridItem = styled.div`
  & > div {
    margin: 0 auto;
    margin-bottom: 30px;

    height: 80px;
    width: 80px;
    grid-gap: 80px;

    display: grid;
    place-items: center;
  }

  h6 {
    margin: 20px 0;
    line-height: 22px;
    font-size: 20px;
  }

  p {
    font-family: Inter, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #c4c4c4;
    margin: 0 auto;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    h6 {
      margin: 16px 0;
      font-size: 16px;
      line-height: 24px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 16px;
    }
    img {
      width: 70px;
      height: 70px;
    }
  }
`;
