import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  gap: 16px;
  padding: 0 20px;
  margin-bottom: 100px;

  @media screen and ${({ theme }) => theme.breakpoints.lg} {
    grid-template-columns: auto auto;
    gap: 50px;
    padding: 0;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: none;
    gap: 50px;
    margin-bottom: 32px;
    padding: 0;
  }

  @media screen and ${({ theme }) => theme.breakpoints.xs} {
    align-items: center;
    gap: 60px;
  }
`;

export const Title = styled.h5`
  color: #fdfdfd;
  font-size: 30px;
  padding: 0px 15px;
  margin: 0;
  margin-bottom: 85px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 60px;
  }
`;
