import styled from 'styled-components';
import InputField from 'components/atoms/InputField';
import Button from 'components/atoms/Button';

export const Container = styled.div`
  margin: 60px 0;
  width: 30%;
  box-sizing: border-box;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    width: 70%;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    margin: 8px 0;
  } ;
`;

export const InputContainer = styled.div`
  position: relative;

  .valid-indicator {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .error-text {
  }
`;

export const InputRefferal = styled(InputField)`
  width: 100%;
  color: #fdfdfd;
  border-color: ${(props) => (props.valid ? '' : '#F18F01')};
  text-transform: uppercase;
  font-weight: 600;

  ::placeholder {
    text-transform: lowercase;
    font-weight: normal;
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 0;
  height: 50px;
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;
  background-color: ${(props) => (props.disabled ? '#626262' : '')};
  :hover {
    background-color: ${(props) => (props.disabled ? '#626262' : '')};
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    line-height: 24px;
    margin-top: 24px;
  }
`;

export const CekButton = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: -3px;
  right: 20px;

  .text {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #00debf;
  }

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    .text {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const SkipText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  color: #fdfdfd;
  margin: 16px 0;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const ErrorText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0;
  text-align: left;
  color: #f18f01;
`;
