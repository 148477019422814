export const urlReferral = (code) => {
  if (process.env.REACT_APP_STAGING === 'dev') {
    return `https://dev.terampil.com/registrasi?referral=${code || ''}`;
  } else {
    return `https://terampil.com/registrasi?referral=${code || ''}`;
  }
};
const url =
  'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBFp2Y7V7Y3H4AVGhJqmBM3mewXokET_tU';
export const postLinkUrl = async (code) => {
  const data = {
    dynamicLinkInfo: {
      domainUriPrefix: 'https://terampil.page.link',
      link:
        process.env.REACT_APP_STAGING === 'dev'
          ? `https://dev.terampil.com/registrasi?referral=${code}`
          : `https://terampil.com/registrasi?referral=${code}`,
      androidInfo: {
        androidPackageName: 'com.terampil.app',
      },
      iosInfo: {
        iosBundleId: 'com.terampil.app',
        iosAppStoreId: '1559671480',
      },
      navigationInfo: {
        enableForcedRedirect: true,
      },
      socialMetaTagInfo: {
        socialImageLink:
          'https://d2jr0bw5daaf2g.cloudfront.net/assets/images/landing_page_video2.jpg',
        socialTitle: 'Your FUTURE Starts NOW',
        socialDescription: 'No 1 App For Building Wealth and Building Business',
      },
    },
  };
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
      //   {
      //   data: {
      //     dynamicLinkInfo: {
      //       domainUriPrefix: 'https://terampil.page.link',
      //       link:
      //         process.env.REACT_APP_STAGING === 'dev'
      //           ? `https://dev.terampil.com/registrasi?referral=${code} `
      //           : `https://terampil.com/registrasi?referral=${code}`,
      //       androidInfo: {
      //         androidPackageName: 'com.terampil.android',
      //       },
      //       iosInfo: {
      //         iosBundleId: 'com.terampil.ios',
      //       },
      //     },
      //   },
      // }
      // body data type must match "Content-Type" header
    });
    return response.json();
  } catch (error) {
    return error;
  }
};
