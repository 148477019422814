import styled from 'styled-components';

export const Container = styled.div`
  background-color: #202020;
  height: 218px;
  padding: 24px;
  margin-top: 24px;
  border-radius: 10px;
  box-sizing: border-box;

  div.container {
    display: flex;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin-top: 16px;
    height: auto;
    padding: 12px;
  }
`;

export const AvatarImage = styled.div`
  background-image: url('${(props) => props.image}');
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 50px;
    height: 50px;
  }
`;

export const ContainerName = styled.div`
  padding-left: 16px;
  div {
    margin-top: 6px;
    display: flex;
    align-items: flex-end;

    span{
      font-size: 16px;
      margin: 0 10px;
    }

    .hide-component{
      display: none;
      pointer-events: none;
    }
  }
  h2 {
    ${(props) => props.theme.font.primary.title};
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: left;
    margin: 0;
  }

  p {
    ${(props) => props.theme.font.secondary.subtitle2}
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;
    margin: 0 5px;
    color: #fdfdfd;
  }

  p.date {
    ${(props) => props.theme.font.secondary.subtitle2}
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin: 0 5px;
    color: #c4c4c4;
  }
  svg {
    width: 19px;
    flex-shrink: 0;
  }

  svg.circle {
    margin-bottom: 5px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    div {
      margin-top: 3px;
    }

    h2 {
      font-size: 13px;
      line-height: 16px;
    }

    svg {
      width: 10px;
    }

    p {
      font-size: 12px;
      line-height: 15px;
    }

    p.date {
      font-size: 10px;
      line-height: 15px;
    }

    padding-left: 8px;
    border-radius: 5px;
  }
`;

export const Coment = styled.div`
  height: 104px;
  background-color: #373737;
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #fdfdfd;
  padding: 16px;
  text-align: left;
  box-sizing: border-box;
  overflow: auto;
  margin: 21px 0 0 66px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    background-color: transparent;
    margin: 0;
    font-size: 13px;
    line-height: 20px;
    padding: 12px 10px 0 0;
  }
`;
