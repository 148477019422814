import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 24px;

  background: #202020;

  border-radius: 5px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin: 16px 0;

  p {
    margin: 4px 0;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    color: #c4c4c4;
  }

  .color-berhasil {
    color: #00debf;
  }
  .color-failed {
    color: #f18f01;
  }

  strong {
    color: #fdfdfd;
  }

  & > div {
    display: flex;
  }

  .mb-3 {
    margin-bottom: 16px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 16px;
    align-items: center;

    border-radius: 0px;

    h6 {
      font-size: 14px;
    }

    .x-5 {
      position: relative;
      right: 10px;
    }

    .price {
      margin: 8px 0;
    }
  }
`;

export const Icon = styled.div`
  display: grid;
  align-items: center;

  margin-right: 24px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;

export const Detail = styled.div``;

export const Button = styled.button`
  width: 130px;
  height: 40px;

  position: relative;
  bottom: 8px;

  border: none;

  cursor: pointer;

  border-radius: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  &.primary {
    background: ${({ theme }) => theme.color.primary};
    color: #141414;
  }

  &.secondary {
    background: transparent;
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
  }

  &.primary:hover {
    background: #00f1cf;
  }

  &.secondary:hover {
    border: 2px solid #c4c4c4;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    border-radius: 3px;

    font-size: 12px;

    width: 80px;
    height: 32px;
  }
`;
