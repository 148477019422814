export class InputFieldProps {
  static checkTheme(props) {
    switch (true) {
      case props.dark:
        return { bgColor: '#191919', labelColor: '#C4C4C4', textColor: '#fdfdfd' };
      case props.grey:
        return { bgColor: '#373737', labelColor: '#C4C4C4', textColor: '#FDFDFD' };
      default:
        return { bgColor: 'white', labelColor: '#626262', textColor: '#000004' };
    }
  }
}
