import React, { useEffect, useState } from 'react';
import { Container, Title, Paragraf, Layout, Flex } from './styled';
import CardBlog from 'components/molecule/Cards/CardBlog';
import Pagination from 'components/atoms/Pagination';
import { useUrlQuery } from 'hooks';
import { useHistory } from 'react-router-dom';
import { ALL_PAGES_BLOGS } from 'queries/blog';
import { useLazyQuery } from '@apollo/client';
import SearchNotFound from 'components/atoms/SearchNotFound';
import { LoadingEllipsis } from 'components/atoms/Loading';
import SortDropDown from 'components/molecule/SortDropDown';
import { t } from 'hooks/intl/useLang';

const BlogResult = () => {
  const queryUrl = useUrlQuery();
  const history = useHistory();
  const [page, setPage] = useState(parseInt(queryUrl.get('page')) || 1);
  const [categoryName, setCategoryName] = useState('');
  const [dataAllBlog, setDataAllBlog] = useState([]);
  const [resultPages, setResultPages] = useState('');
  const [filtered, setfiltered] = useState(0);
  const [getAllBlog, { loading }] = useLazyQuery(ALL_PAGES_BLOGS, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    onCompleted: ({ Blogs, CategoryBlog }) => {
      setCategoryName(CategoryBlog?.name);
      setDataAllBlog(Blogs?.items);
      setfiltered(Blogs?.filtered);
    },
    onError: (e) => {
      setCategoryName(false);
    },
  });
  let search = queryUrl.get('search');
  let categoryId = queryUrl.get('category');

  useEffect(() => {
    getAllBlog({
      variables: {
        input: {
          page: page,
          limit: 9,
          search: search || undefined,
          categoryBlogId: +categoryId || null,
        },
        id: +categoryId || null,
      },
    });
  }, [page, search, categoryId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setfiltered(0);
    setCategoryName(false);

    if (queryUrl.get('page') === 'NaN') {
      setPage(1);
      queryUrl.set('page', '1');
    } else {
      queryUrl.set('page', `${page}`);
      setPage(parseInt(queryUrl.get('page')));
    }

    history.replace('/blogs?' + queryUrl.toString());
  }, [page]);

  return (
    <Container>
      <Flex>
        <div className="container">
          <Title>
            {loading ? (
              <LoadingEllipsis />
            ) : categoryName ? (
              <span> {categoryName} </span>
            ) : (
              <>
                {t('Hasil Pencarian untuk')} <span>{search ?? t('Semua Pencarian')}</span>{' '}
              </>
            )}
          </Title>
          <Paragraf>{filtered} {t('Hasil')}</Paragraf>
        </div>
        {/* <SortDropDown route={'blogs'} /> */}
      </Flex>

      {!categoryName && !loading && dataAllBlog.length === 0 && <SearchNotFound blog />}
      <Layout>
        {dataAllBlog?.map((item, i) => {
          return <CardBlog item={item} />;
        })}
      </Layout>
      {Math.ceil(filtered / 9) > 1 && (
        <Pagination setPageLoad={setPage} firstPage={page} countPage={Math.ceil(filtered / 9)} />
      )}
    </Container>
  );
};

export default BlogResult;
