import React from 'react';
import { Container, Title, Grid, GridItem } from './styled';

// import assests
import Icon1 from 'assets/svg/Prakerja/MateriBerkualitas.svg';
import Icon2 from 'assets/svg/Prakerja/VideoInteraktif.svg';
import Icon3 from 'assets/svg/Prakerja/TrainerBerpengalaman.svg';
import Icon4 from 'assets/svg/Prakerja/Sertifikat.svg';
import { t } from 'hooks/intl/useLang';

function MengapaTerampil() {
  return (
    <Container>
      <Title>{t('Mengapa Terampil merupakan pilihan terbaik?')}</Title>
      <Grid>
        <GridItem>
          <div>
            <img src={Icon1} alt="icon-1" />
          </div>
          <h6>{t('Materi Berkualitas')}</h6>
          <p>
            {t('Video training berkualitas tinggi dan dilengkapi dengan berbagai animasi, gambar dan video ilustrasi')}
          </p>
        </GridItem>
        <GridItem>
          <div>
            <img src={Icon2} alt="icon-1" />
          </div>
          <h6>{t('Video Interaktif')}</h6>
          <p>
            {t('Video training berkualitas tinggi dan dilengkapi dengan berbagai animasi, gambar dan video ilustrasi')}
          </p>
        </GridItem>
        <GridItem>
          <div>
            <img src={Icon3} alt="icon-1" />
          </div>
          <h6>{t('Trainer Berpengalaman')}</h6>
          <p>{t('Materi disusun dan dibawakan oleh praktisi berdasarkan pengalaman dan kesuksesannya')}</p>
        </GridItem>
        <GridItem>
          <div>
            <img src={Icon4} alt="icon-1" />
          </div>
          <h6>{t('Bersertifikat')}</h6>
          <p>
            {t('Anda akan mendapatkan Sertifikat Kelulusan Training yang bisa meningkatkan Value Anda ketika melamar pekerjaan')}
          </p>
        </GridItem>
      </Grid>
    </Container>
  );
}

export default MengapaTerampil;
