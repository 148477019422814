import React from 'react';
import { Container, CardHeadline } from './styled';
import { BLOGS_HIGHLIGHTS, GET_ALL_BLOGS } from 'queries/blog';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';

const HeadlineBlog = () => {
  const { loading, data } = useQuery(BLOGS_HIGHLIGHTS);
  const classNameGrid = (index) => {
    switch (index) {
      case 1:
        return t('satu');
      case 2:
        return t('dua');
      case 3:
        return t('tiga');
      case 4:
        return t('empat');
      case 5:
        return t('lima');

      default:
        break;
    }
  };
  return (
    <Container>
      {data?.BlogHighlights?.items?.map((item, i) => {
        return (
          <CardHeadline
            className={classNameGrid(i + 1)}
            bgImage={`https://picsum.photos/id/${i + 100}/200/300`}
            big={i === 0}
          >
            <Link to={`/blog/${item?.Blog?.slug}`} target="_blank">
              <div className="background">
                <h1 className={classNameGrid(i + 1)}>{item?.Blog?.title}</h1>
              </div>
            </Link>
          </CardHeadline>
        );
      })}
    </Container>
  );
};

export default HeadlineBlog;
