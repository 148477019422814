import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkMobile } from 'utils/checkMobile';
import { useSelector } from 'react-redux';
import { getValue } from 'utils';

const PrivateRoute = ({
  component: Component,
  redirectTo = '/',
  accessFromMobile = false,
  args,
  ...rest
}) => {
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const linkMobile = 'https://terampil.page.link/start';

  // const [checkAuth] = useMutation(HEART_BEAT, {
  //   onCompleted: (data) => {},
  //   onError: async (err) => {
  //     if (err.message === 'Session has been expired.' || err.message === 'jwt must be provided') {
  //       await dispatch(authOperations.removeAuth());
  //       await dispatch(subscribeOperations.removeSubscribe());
  //       history.push('/');
  //     }
  //   },
  // });

  function auth() {
    if (getValue(authData, 'data.accessToken')) {
      return true;
    }

    return false;
  }

  const redirectPath = (location) => {
    if (!checkMobile()) {
      return (
        <Redirect
          to={{
            pathname: args?.checkMobile || args?.notMobile || args?.notAuth ? redirectTo : '/',
            state: {
              from: location,
            },
          }}
        />
      );
    } else if (checkMobile() || args?.redirectToApps) {
      return window.open(linkMobile);
    }
  };

  const checkArgs = (args) => {
    switch (true) {
      case args?.auth && !checkMobile():
        return auth();
      case args?.auth && checkMobile() && !accessFromMobile:
        return false;
      case args?.auth && checkMobile() && accessFromMobile:
        return true;
      case args?.redirectToApps && checkMobile():
        return false;
      case args?.preference:
        return (
          getValue(authData, 'data.accessToken') !== undefined &&
          localStorage.userPreferences === undefined
        );
      case args?.checkMobile:
        return checkMobile();
      case args?.notMobile:
        return !checkMobile();
      case args?.notAuth:
        return !getValue(authData, 'data.accessToken');
      default:
        return auth();
    }
  };

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return checkArgs(args) ? <Component /> : redirectPath(location);
      }}
    />
  );
};

export default PrivateRoute;
