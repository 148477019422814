import styled from 'styled-components';
import Button from '../../../atoms/Button';

export const Title = styled.h1`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #101010;
  margin-bottom: 31px;
`;

export const InputContainer = styled.div``;
export const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 48px;
  box-sizing: border-box;
  margin-left: -1px;
  height: 50px;
`;
export const IconPassword = styled.img`
  position: absolute;
  right: 22px;
  top: 50%;
  cursor: pointer;
  color: #626262;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
`;

export const ContainerPassword = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;

  :hover::after {
    content: 'Kata sandi minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, simbol dan angka.';
    background-color: whitesmoke;
    width: 100%;
    border: 1px solid red;
    height: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;
    color: #d51919;
    position: absolute;
    bottom: -48px;
    left: 0;
    box-sizing: border-box;
    padding: 8px;
  }
`;
