import styled from 'styled-components';

export const GridLayoutStyled = styled.div`
  width: 100%;
  display: ${(props) => (props.isOpenFilter ? 'none' : 'grid')};
  grid-template-columns: repeat(${(props) => props.column || 3}, 1fr);
  //grid-auto-rows: minmax(10px, auto);
  column-gap: 20px;
  margin-top: 24px;
  row-gap: 50px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    grid-template-columns: repeat(${(props) => props.columnMobile || 1}, 1fr);
    row-gap: 16px;
    padding: 0 16px;
    box-sizing: border-box;
  }
`;
