import { gql } from '@apollo/client';

export const GET_PACKAGE_TRAININGS = gql`
  query PACKAGE_TRAININGS {
    PackageTrainings(input: { isActive: true }) {
      items {
        name
        package_type
        quota
        point_price
        id
        price
        promo
        benefits {
          is_checked
          text
          tooltip
          strong_text
        }
      }
    }
  }
`;

export const GET_TRAININGS = gql`
  query TRAININGS($slug: String) {
    Training(slug: $slug) {
      price
      training_price
      title
    }
  }
`;

export const GET_SUBSCRIBE_TRAININGS = gql`
  query SUBSCRIBE_TRAININGS {
    Subscriptions(input: { isActive: true }) {
      items {
        id
        quota
        title
        duration
        total_price
        price_per_month
        subscription_type
      }
    }
  }
`;

export const GET_PACKAGE_TRAINING_ONE = gql`
  query PACKAGE_TRAINING($packageType: packageEnum) {
    PackageTrainings(input: { isActive: true, packageType: $packageType }) {
      items {
        name
        package_type
        quota
        point_price
        id
        price
        promo
        benefits {
          is_checked
          text
          tooltip
        }
      }
    }
  }
`;

export const GET_BUNDLING_TRAINING = gql`
  query PACKAGE_BUNDLING {
    PackageBundling(input: { isActive: true }) {
      items {
        id
        name
        slug
        price
        promo
        point_price
        training_ids
        starting_price
        benefits {
          text
          tooltip
          strong_text
          is_checked
        }
      }
    }
  }
`;

export const DETAIL_BUNDLING_TRAINING = gql`
  query DETAIL_PACKET_BUNDLING($slug: String) {
    DetailPackageBundling(input: { slug: $slug }) {
      id
      name
      slug
      price
      training_ids
      promo
      is_active
      benefits {
        text
        tooltip
        strong_text
      }
    }
  }
`;
