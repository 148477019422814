import styled from 'styled-components';

export const Container = styled.section`
  position: sticky;
  top: 109px;

  text-align: left;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  background: #202020;

  border-radius: 5px;

  max-height: 256px;

  &.faq {
    max-height: 155px;
  }

  a {
    display: block;

    text-decoration: none;

    padding: 16px 0 16px 24px;

    color: #fdfdfd;

    border-radius: 5px;
  }

  a:hover {
    background: #373737;
  }

  .active,
  .active:hover {
    background: #266960;
  }
  .disabled {
    background: #373737;
  }

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.color.primary};
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;
