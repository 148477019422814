import styled from 'styled-components';

export const Button = styled.button`
  height: 50px;

  cursor: pointer;

  /* border-radius: 50%; */

  /* padding: 0 16px; */
  /* margin-right: 24px; */

  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  border: none;
  background-color: inherit;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;

  &.Facebook {
    /* background: #1977f3; */
    cursor: pointer;
  }

  &.Twitter {
    /* background: #55acee; */
    cursor: pointer;
  }

  /* &.w-100 {
    width: 100%;
  } */

  svg {
    margin-right: 5px;
  }
`;

// import styled from 'styled-components';

// export const Button = styled.button`
//   height: 50px;

//   cursor: pointer;

//   border-radius: 5px;

//   padding: 0 16px;
//   margin-right: 24px;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   border: none;

//   font-family: Inter;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 19px;

//   color: #ffffff;

//   &.Facebook {
//     background: #1977f3;
//     cursor: pointer;
//   }

//   &.Twitter {
//     background: #55acee;
//     cursor: pointer;
//   }

//   &.w-100 {
//     width: 100%;
//   }

//   svg {
//     margin-right: 8px;
//   }
// `;
