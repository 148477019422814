import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  width: 100%;

  .line {
    border-bottom: 3px solid #01a856;
    width: 70%;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    min-height: 100vh;
  }
`;

export const Title = styled.h2`
  width: 90%;
  font-family: Archivo;
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  color: #ffffff;
  line-height: 45px;
  margin: 0 0 16px 0;

  span {
    color: #01a856;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  margin: 70px 0;
`;
