import React from 'react';
import { Container, ImageLogo } from './styled';
import LogoTerampil from 'assets/png/logo-terampil.png';
import LeftSectionImage from 'assets/png/new-register.png';

function LeftSection({ training, children, background, withoutLogo = true, ...rest }) {
  const imageBackground = !training
    ? 'https://d2jr0bw5daaf2g.cloudfront.net/assets/images/sign_pic.png'
    : 'https://d2jr0bw5daaf2g.cloudfront.net/assets/images/SignUp_2.png';
  return (
    <Container imageBackgroud={background ? background : LeftSectionImage} {...rest}>
      {!withoutLogo && (
        <a href="/" className="logo-left">
          <ImageLogo className="logo" src={LogoTerampil} alt="logo" />
        </a>
      )}
      {children}
    </Container>
  );
}

export default LeftSection;
