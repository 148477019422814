import { gql } from '@apollo/client';

export const RETAKE_ALL_TRAINING = gql`
  mutation RETAKE_ALL_TRAINING($input: CreateUserTrainingInput) {
    RetakeUserTraining(input: $input) {
      status
      statusText
    }
  }
`;
