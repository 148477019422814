import { gql } from '@apollo/client';

export const LOGIN_BY_EMAIL_PHONE = gql`
  mutation LOGIN_BY_EMAIL_PHONE($input: SignInByEmailOrPhoneInput) {
    DoSignInByEmailOrPhone(input: $input) {
      free_trial
      phone_number
      accessToken
      id
      Profile {
        profession
        slug
        gender
        website
        twitter
        facebook
        linkedin
        referral_code
        total_point
        fullname
        is_skip
        all_access
        all_access_end_date
        categories {
          id
          name
        }
      }
    }
  }
`;

export const GOOGLE_SIGNUP = gql`
  mutation GOOGLE_SIGN($input: SignGoogleInput) {
    DoSignByGoogle(input: $input) {
      id
      accessToken
      isNewRecord
      Profile {
        user_id
        slug
        is_skip
        referral_code
        all_access
        all_access_end_date
      }
    }
  }
`;

export const REQ_RESET_PASSWORD = gql`
  mutation SEND_EMAIL_RESET_PASSWORD($input: ResetPassword) {
    RequestResetPassword(input: $input) {
      id
      link
    }
  }
`;
