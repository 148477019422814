import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import FontFaceObserver from 'fontfaceobserver';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import TagManager from 'react-gtm-module';
// import ReactPixel from 'react-facebook-pixel';

// Loading font async and checking the font cache
const archivoObserver = new FontFaceObserver('Archivo', {});
const OpenSansOberserver = new FontFaceObserver('Open Sans', {});
const interObserver = new FontFaceObserver('Inter', {});
const crimsonObserver = new FontFaceObserver('Crimson Text', {});
const html = document.documentElement;

if (typeof sessionStorage !== 'undefined' && sessionStorage.fontsLoaded) {
  html.classList.add('root-fonts-loaded');
  html.classList.remove('root-fonts-loading');
} else {
  html.classList.add('root-fonts-loading');

  Promise.all([
    archivoObserver.load(),
    OpenSansOberserver.load(),
    interObserver.load(),
    crimsonObserver.load(),
  ])
    .then(() => {
      sessionStorage.fontsLoaded = true;

      html.classList.remove('root-fonts-loading');
      html.classList.add('root-fonts-loaded');
    })
    .catch(() => {
      sessionStorage.fontsLoaded = false;
    });
}

// const tagManagerArgs = {
//   gtmId: 'GTM-N63FTXG',
// };
// ReactPixel.init('203489291848120', {}, { debug: true, autoConfig: false });
// ReactPixel.pageView();
// ReactPixel.fbq('track', 'PageView');

// TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// test change