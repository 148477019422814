import { gql } from '@apollo/client';

export const UNSUBSCRIPTION = gql`
  mutation unsubscription {
    Unsubscription {
      id
      user_id
      fullname
      about
      profession
      gender
      website
      twitter
      facebook
      linkedin
      referral_code
      total_point
      is_skip
      unread_notification
      slug
      avatar
      avatar_id
      instagram
      is_subscription
      is_unsubscription
      training_quota
      subscription_end_date
      subscription_type
    }
  }
`;
