import styled from 'styled-components';

export const Container = styled.section`
  padding: 32px 100px 0;
  /* background-color: #202020; */

  div.line {
    display: flex;
    border-bottom: 1px solid #626262;
  }

  a {
    text-decoration: none;
    display: block;
    color: white;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 30px 30px 0;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 30px 0px 0;
    overflow: auto;
    margin-top: 65px;
  }
`;
