import React from 'react';

import Tittle from 'components/atoms/Tittle';
import { Section, Grid, GridItem } from './styled';
import MaxContainer from 'components/atoms/MaxContainer';

import Icon1 from 'assets/png/JadiTrainer/Section Benefit - 1.png';
import Icon2 from 'assets/png/JadiTrainer/Section Benefit - 2.png';
import Icon3 from 'assets/png/JadiTrainer/Section Benefit - 3.png';
import { t } from 'hooks/intl/useLang';

const Benefit = () => {
  return (
    <Section>
      <MaxContainer>
        <Tittle>{t('Beragam Manfaat Menjadi Trainer')}</Tittle>
        <Grid>
          <GridItem>
            <div>
              <img src={Icon1} alt="icon-1" />
            </div>
            <h6>{t('Menginspirasi')}</h6>
            <p>
              {t('Trainer yang menginspirasi dapat menghidupkan harapan, menyalakan imajinasi, dan memperkuat motivasi')}
            </p>
          </GridItem>
          <GridItem>
            <div>
              <img src={Icon2} alt="icon-1" />
            </div>
            <h6>{t('Membangun Bangsa')}</h6>
            <p>
              {t('Dengan mengajar Anda berperan dalam membangun SDM bangsa Indonesia menjadi lebih baik')}
            </p>
          </GridItem>
          <GridItem>
            <div>
              <img src={Icon3} alt="icon-1" />
            </div>
            <h6>{t('Menambah Penghasilan')}</h6>
            <p>
              {t('Dapatkan penghasilan tambahan dengan memaksimalkan potensi Anda sebagai seorang trainer')}
            </p>
          </GridItem>
        </Grid>
      </MaxContainer>
    </Section>
  );
};

export default Benefit;
