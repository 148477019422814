import React from 'react';
import { Container, ProgressBar, FilledProgress, ProgressPercentage } from './styled';
const ProgressRating = ({ totalUlasan, percentage }) => {
  return (
    <Container>
      <ProgressBar>
        <FilledProgress percentage={percentage} />
      </ProgressBar>
      <ProgressPercentage>{totalUlasan}</ProgressPercentage>
    </Container>
  );
};

export default ProgressRating;
