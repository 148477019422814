import React from 'react';
import { SwiperSlide } from 'swiper/react';
import { checkMobile } from 'utils/checkMobile';
import { useHistory } from 'react-router-dom';
import loadable from '@loadable/component';
import { LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';

// import Slider from 'components/atoms/Slider';
// import { CardTraining } from 'components/molecule/Cards/CardTraining';

import { SliderControlPrimary } from 'components/atoms/Slider/Slider.styled';
import { CardContainer } from 'components/organisms/CategoryPopularTraining/CategoryPopularTraining.styled';
import { SliderPagination } from 'components/organisms/PopularTrainer/PopularTrainer.styled';
import { SliderTrainingContainer, ButtonEnd } from './styled';

import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';
import { ReactComponent as SeeItemsIcon } from 'assets/svg/see-items.svg';
import MaxContainer from '../../atoms/MaxContainer';
import { t } from 'hooks/intl/useLang';

const Slider = loadable(() => import('components/atoms/Slider/index'));
const CardTraining = loadable(() => import('components/molecule/Cards/CardTraining'));

function SliderTraining({
  trainings,
  id,
  scrollPosition,
  populer = false,
  row,
  count = 4,
  bundling,
}) {
  const history = useHistory();

  const listTrainingPopuler = (item) => {
    if (row) {
      return [...trainings, { reachEnd: true }];
    } else {
      return trainings;
    }
  };

  if (!checkMobile()) {
    return (
      <MaxContainer>
        <SliderTrainingContainer row={row}>
          <div className="slider">
            <div className="button-slider">
              <SliderControlPrimary className="prev-primary" id={`prev-${id}`}>
                <PrevIcon />
              </SliderControlPrimary>

              <SliderControlPrimary className="next-primary" id={`next-${id}`}>
                <NextIcon />
              </SliderControlPrimary>
            </div>

            <CardContainer>
              <Slider
                row={row}
                count={count}
                next={`next-${id}`}
                prev={`prev-${id}`}
                pagination={`page-${id}`}
              >
                {listTrainingPopuler(trainings)?.map((training, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {training?.reachEnd ? (
                        <ButtonEnd
                          row={row}
                          onClick={() => history.push('/trainings?category=all&page=1')}
                        >
                          <div className="wrap-button">
                            <SeeItemsIcon />
                            <div className="content">{t('Lihat Semua')}</div>
                          </div>
                        </ButtonEnd>
                      ) : (
                        <LazyLoadComponent scrollPosition={scrollPosition}>
                          <CardTraining
                            row={row}
                            training={
                              populer
                                ? training?.training
                                : bundling
                                ? training?.Training
                                : training
                            }
                            key={index}
                          />
                        </LazyLoadComponent>
                      )}
                    </SwiperSlide>
                  );
                })}
              </Slider>
            </CardContainer>
          </div>
          {trainings !== null && (
            <SliderPagination className="training-pagination" id={`page-${id}`} />
          )}
        </SliderTrainingContainer>
      </MaxContainer>
    );
  } else {
    return (
      <SliderTrainingContainer>
        <div className="overlay">
          <div className="inner">
            {listTrainingPopuler(trainings)?.map((training, index) => {
              return training?.reachEnd ? (
                <ButtonEnd>
                  <div className="wrap-button">
                    <SeeItemsIcon />
                    <div className="content">{t('Lihat Semua')}</div>
                  </div>
                </ButtonEnd>
              ) : (
                <LazyLoadComponent scrollPosition={scrollPosition}>
                  <CardTraining
                    training={
                      populer ? training?.training : bundling ? training?.Training : training
                    }
                    key={index}
                  />
                </LazyLoadComponent>
              );
            })}
          </div>
        </div>
      </SliderTrainingContainer>
    );
  }
}

export default trackWindowScroll(SliderTraining);
