import React from 'react';
import {
  Container,
  Title,
  LayoutCard,
  ContainerSlide,
  SliderButton,
  CardContainer,
  ButtonLihatSemua,
  FlexTitle,
} from './styled';
import { SwiperSlide } from 'swiper/react';

import CardBlog from 'components/molecule/Cards/CardBlog';
import { useQuery } from '@apollo/client';
import { GET_BLOGS_PER_CATEGORIES } from 'queries/blog';
import { SliderControlPrimary } from 'components/atoms/Slider/Slider.styled';
import loadable from '@loadable/component';

import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';
import { checkMobile } from 'utils/checkMobile';
import { useHistory } from 'react-router';
import { t } from 'hooks/intl/useLang';
const Slider = loadable(() => import('components/atoms/Slider'));

const ArtikelFavorite = ({ data = [] }) => {
  const { data: dataInsige, loading } = useQuery(GET_BLOGS_PER_CATEGORIES, {
    variables: {
      limit: 100,
      categoryBlogId: 1,
    },
  });
  const isMobile = checkMobile();
  const history = useHistory();

  return (
    <Container>
      <FlexTitle>
        <Title>Insight</Title>
        <ButtonLihatSemua onClick={() => history.push('/blogs?category=1&page=1')}>
          {t('Lihat Semua')}
        </ButtonLihatSemua>
      </FlexTitle>
      {!isMobile ? (
        <ContainerSlide>
          <SliderButton className="slider">
            <SliderControlPrimary className="prev-primary" id="prev-testimoni">
              <PrevIcon />
            </SliderControlPrimary>
            <SliderControlPrimary className="next-primary" id="next-testimoni">
              <NextIcon />
            </SliderControlPrimary>
          </SliderButton>

          <LayoutCard>
            <Slider next="next-testimoni" prev="prev-testimoni" pagination="testimoni" count={3}>
              {dataInsige?.Blogs?.items.map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    <CardBlog slide item={item} key={i} index={i} />
                  </SwiperSlide>
                );
              })}
            </Slider>
          </LayoutCard>
        </ContainerSlide>
      ) : (
        <ContainerSlide>
          <LayoutCard>
            <div className="box-mobile" />
            {dataInsige?.Blogs?.items.map((item, i) => {
              return <CardBlog slide item={item} key={i} index={i} />;
            })}
          </LayoutCard>
        </ContainerSlide>
      )}
    </Container>
  );
};

export default ArtikelFavorite;
