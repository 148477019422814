import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button';

export const Container = styled.section`
  padding: 80px;
  display: grid;
  place-items: center;
  background: white;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: block;
    margin: 80px;
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const BoxQuestion = styled.div`
  border-radius: 5px;
  width: 60%;
  margin-top: 24px;
  padding: 24px;
  background: #fbfafa;
  .flex {
    display: flex;
    justify-content: space-between;
  }

  .img-mobile {
    width: 100%;
    background-size: contain;
  }

  @media screen and ${({ theme }) => theme.breakpoints.xl} {
    width: 75%;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 16px;
    width: 100%;
    box-sizing: border-box;

    .img-mobile {
      width: 100%;
      background-size: contain;
    }
  }
`;

export const Title = styled.h2`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #141414;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-size: 14px;
    line-height: 26px;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 187% */
  text-align: left;
  color: #141414;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 24px;
  }
`;

export const RulesBox = styled.div`
  & > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    /* or 187% */
    text-align: left;
    color: #626262;
    margin: 6px 0;
    strong {
      color: #141414;
    }
  }

  .contoh {
    margin-top: 16px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    & > p {
      font-size: 12px;
      line-height: 24px;
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
  margin-top: 16px;
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    justify-content: center;
  }
`;

export const SelectOptionName = styled.div`
  width: 97px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 187% */

  text-align: center;

  color: #141414;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    width: 50%;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
  gap: 15px;
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    justify-content: center;
    gap: 10px;
  }
`;

export const Button = styled(ButtonStyled)`
  margin: 0;
  height: 39px;
  font-size: 14px;
  width: 145px;
  background: ${(props) => (props.disabled ? '#626262' : '#187336')};
  color: white;

  :hover {
    background: ${(props) => (props.disabled ? '#626262' : '#2fb960')};
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
  }
`;

export const ButtonSecondary = styled(ButtonStyled)`
  margin: 0;
  height: 39px;
  font-size: 14px;
  width: 145px;
  background-color: inherit;
  border: 1px solid #187336;
  color: #187336;
  :hover {
    background-color: transparent;
  }
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
  }
`;
