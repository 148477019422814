export const data = [
  {
    question: 'Apa itu undang teman?',
    answer: [
      <p>
        Undang teman adalah program dari Terampil untuk kamu bisa mengajak teman-temanmu daftar
        ataupun membeli training di Terampil. Sebagai apresiasi, Terampil akan memberikan beberapa
        manfaat untuk kamu.',
      </p>,
    ],
  },
  {
    question: 'Manfaat apa yang akan saya dapatkan?',
    answer: [
      <p>
        1. Voucher untuk unlock 1 video jika berhasil mengajak 3 temanmu mendaftar ke Terampil.
      </p>,
      <p>
        2. Free Training jika kamu berhasil mengajak temanmu untuk membeli training ataupun paket di
        Terampil.
      </p>,
    ],
  },
  {
    question: 'Bagaimana cara mengundang teman?',
    answer: [
      <p>1. Silahkan daftar ke Terampil dengan cara klik tombol “Daftar Gratis” di atas</p>,
      <p>2. Selesaikan proses pendaftaran</p>,
      <p>
        3. Setelah kamu berhasil login kamu bisa juga akses menu undang teman yang terdapat pada
        profile.
      </p>,
      <p>
        4. Bagikan link undangan yang kamu miliki ke teman - temanmu via social media atau WhatsApp.
      </p>,
    ],
  },
  {
    question: 'Bagaimana cara mendapatkan manfaat voucher unlock 1 video?',
    answer: [
      <p>1. Pastikan teman-temanmu mendaftar menggunakan link undangan yang telah kamu bagikan</p>,
      <p>
        2. Jika temanmu ada yang mendaftar menggunakan link tersebut maka kamu akan diinformasikan
        melalui notifikasi.
      </p>,
      <p>
        3. Jika sudah ada 3 teman yang berhasil mendaftar, maka kode voucher untuk unlock 1 video
        akan dikirimkan melalui email ataupun notifikasi.
      </p>,
      <p>4. Kode voucher hanya berlaku selama 3 bulan sejak kamu mendapatkan kode tersebut.</p>,
      <p>5. Untuk menggunakan kode voucher silahkan salin kode tersebut.</p>,
      <p>6. Akses dan klik video training apapun yang masih terkunci.</p>,
      <p>
        7. Pada tampilan popup yang muncul, silahkan masukkan kode voucher dan klik “Gunakan Kode”
      </p>,
      <p>8. Pastikan kode voucher benar dan kodemu berhasil digunakan.</p>,
      <p>9. Jika berhasil maka video tersebut dapat kamu saksikan secara gratis.</p>,
      <p>
        10. Kode voucher hanya bisa digunakan sekali dan tidak bisa diberikan kepada orang lain.
      </p>,
    ],
  },
  {
    question: 'Bagaimana cara mendapatkan manfaat Free Training?',
    answer: [
      <p>1. Pastikan temanmu mendaftar menggunakan link undangan yang telah kamu bagikan.</p>,
      <p>
        2. Ajak temanmu untuk melakukan pembelian training ataupun paket yang tersedia di Terampil.
      </p>,
      <p>
        3. Jika temanmu melakukan pembelian perdana dan berhasil menyelesaikan pembayaran maka kamu
        akan diinformasikan mendapatkan Free Training ataupun Free Paket sesuai yang temanmu beli
        melalui email dan notifikasi.
      </p>,
      <p>
        4. Pastikan kuotamu bertambah setelah mendapatkan informasi tersebut, dengan cara melihatnya
        pada menu undang teman di profil.
      </p>,
      <p>
        5. Kuota gratismu hanya berlaku selama 3 bulan sejak kamu mendapatkan notifikasi ketika
        temanmu berhasil melakukan pembelian.
      </p>,
      <p>
        6. Untuk menggunakan kuota gratis tersebut, silahkan kamu pilih judul training yang ingin
        kamu ikuti dengan cara klik “Ikuti Training”
      </p>,
      <p>
        7. Jika berhasil diikuti maka kuotamu akan terpakai dan kamu dapat mengakses training
        tersebut selamanya.
      </p>,
    ],
  },
];

export const dataLogin = [
  {
    question: 'Cara melakukan undang teman',
    answer: [
      <p>Salin atau bagikan link undangan yang kamu miliki ke teman-temanmu via social media.</p>,
    ],
  },

  {
    question: 'Bagaimana cara mendapatkan manfaat voucher unlock 1 video?',
    answer: [
      <p>1. Pastikan teman-temanmu mendaftar menggunakan link undangan yang telah kamu bagikan</p>,
      <p>
        2. Jika temanmu ada yang mendaftar menggunakan link tersebut maka kamu akan diinformasikan
        melalui notifikasi.
      </p>,
      <p>
        3. Jika sudah ada 3 teman yang berhasil mendaftar, maka kode voucher untuk unlock 1 video
        akan dikirimkan melalui email ataupun notifikasi.
      </p>,
      <p>4. Kode voucher hanya berlaku selama 3 bulan sejak kamu mendapatkan kode tersebut.</p>,
      <p>5. Untuk menggunakan kode voucher silahkan salin kode tersebut.</p>,
      <p>6. Akses dan klik video training apapun yang masih terkunci.</p>,
      <p>
        7. Pada tampilan popup yang muncul, silahkan masukkan kode voucher dan klik “Gunakan Kode”
      </p>,
      <p>8. Pastikan kode voucher benar dan kodemu berhasil digunakan.</p>,
      <p>9. Jika berhasil maka video tersebut dapat kamu saksikan secara gratis.</p>,
      <p>
        10. Kode voucher hanya bisa digunakan sekali dan tidak bisa diberikan kepada orang lain.
      </p>,
    ],
  },
  {
    question: 'Bagaimana cara mendapatkan manfaat Free Training?',
    answer: [
      <p>1. Pastikan temanmu mendaftar menggunakan link undangan yang telah kamu bagikan.</p>,
      <p>
        2. Ajak temanmu untuk melakukan pembelian training ataupun paket yang tersedia di Terampil.
      </p>,
      <p>
        3. Jika temanmu melakukan pembelian perdana dan berhasil menyelesaikan pembayaran maka kamu
        akan diinformasikan mendapatkan Free Training ataupun Free Paket sesuai yang temanmu beli
        melalui email dan notifikasi.
      </p>,
      <p>
        4. Pastikan kuotamu bertambah setelah mendapatkan informasi tersebut, dengan cara melihatnya
        pada menu undang teman di profil.
      </p>,
      <p>
        5. Kuota gratismu hanya berlaku selama 3 bulan sejak kamu mendapatkan notifikasi ketika
        temanmu berhasil melakukan pembelian.
      </p>,
      <p>
        6. Untuk menggunakan kuota gratis tersebut, silahkan kamu pilih judul training yang ingin
        kamu ikuti dengan cara klik “Ikuti Training”
      </p>,
      <p>
        7. Jika berhasil diikuti maka kuotamu akan terpakai dan kamu dapat mengakses training
        tersebut selamanya.
      </p>,
    ],
  },
];
