import styled from 'styled-components';
import { InputFieldProps } from './propsComponent';

export const InputFieldStyled = styled.input.attrs((props) => ({
  rounded: props.rounded ? '50px' : '5px',
  border: props.danger ? '#D51919' : '#C4C4C4',
  borderSize: props.danger ? '2px' : '1px',
  color: InputFieldProps.checkTheme(props),
}))`
  border: ${(props) => props.borderSize} solid ${(props) => props.border};
  ${({ success }) => success && 'border: 2px solid #00DEBF'};
  border-radius: ${(props) => props.rounded};
  width: 100%;
  height: 50px;
  font-size: 16px;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: transparent;
  color: ${(props) => props.color.textColor};

  :focus {
    outline: none;
  }

  :focus::placeholder {
    color: transparent;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 16px 0 4px;
  box-sizing: border-box;
  .code::placeholder {
    color: ${(props) => (props.lengkapiProfile ? '' : '#101010')};
  }
`;

export const LabelInput = styled.div.attrs((props) => ({
  show: props.focus ? 'none' : 'hidden',
  color: InputFieldProps.checkTheme(props),
}))`
  position: absolute;
  visibility: ${(props) => props.show};
  background-color: ${(props) => props.color.bgColor};
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  margin-left: 18px;
  padding: 0 2px;
  top: -3px;

  /* identical to box height */

  color: ${(props) => props.color.labelColor};

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0 0;
  }
`;
