import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 80px;
  position: relative;

  /* display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px auto;
  padding: 0px 30px;
  box-sizing: border-box; */

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    .swiper-wrapper {
      margin-left: 16px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 100%;
    margin-bottom: 100px;
  }
`;

export const FlexTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

export const ButtonLihatSemua = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  margin: 0;
  padding: 0 120px 0 0;
  /* identical to box height */

  color: #00debf;
`;

export const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  padding: 0 120px;
  /* identical to box height */

  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 16px;
    margin: 12px 0;
  }
`;

export const LayoutCard = styled.div`
  /* display: flex;
  gap: 15px;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: column;
    gap: 40px;
  } */

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 50px;
  padding: 0 100px;
  gap: 10px;

  .box-mobile {
    display: none;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0px 80px;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 0px;
    flex-direction: row;
    width: fit-content;
    gap: 12px;
    margin-top: 0;

    .box-mobile {
      width: 500px;
      height: 40px;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 50px;
  padding: 0 120px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0px;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 0px;
    overflow-x: scroll;
    width: fit-content;
    margin-top: 20px;
  }
`;

export const ContainerSlide = styled.div`
  position: relative;
  /* margin-top: -5re; */

  @media screen and ${(props) => props.theme.breakpoints.md} {
    .slider {
      display: none;
    }
    overflow-x: scroll;
  }
`;

export const SliderButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: none;
  }
`;
