export const LANGMAP = {
  ['Bantuan']: { EN: "Help" },
  ['Halaman Bantuan']: { EN: "Help page" },
  ['Chat dengan Tim Support Terampil']: { EN: "Chat with a skilled support team" },
  ['Chat Sekarang']: { EN: "Chat now" },
  ['Membuat Tiket Bantuan']: { EN: "Make a help ticket" },
  ['Mengirim email ke Tim Support Terampil']: { EN: "Send an email to a skilled support team" },
  ['Buat Tiket']: { EN: "Create a ticket" },
  ['Blog Terampil']: { EN: "Skilled blog" },
  ['blog terampil']: { EN: "skilled blog" },
  ['Cari Artikel']: { EN: "Find Articles" },
  ['satu']: { EN: "One" },
  ['dua']: { EN: "two" },
  ['tiga']: { EN: "three" },
  ['empat']: { EN: "four" },
  ['lima']: { EN: "five" },
  ['Lihat Semua']: { EN: "See all" },
  ['Semua Artikel']: { EN: "All articles" },
  ['Lihat Lainnya']: { EN: "See more" },
  ['Dafar Jadi Trainer']: { EN: "Dafar became a trainer" },
  ['daftar sekaarang untuk menjadi trainer di terampil']: { EN: "List of now to become a trainer in skilled" },
  ['Hasil Pencarian untuk']: { EN: "Search Results for" },
  ['Hasil']: { EN: "Results" },
  ['Semua Pencarian']: { EN: "All search" },
  ['Hasil Pencarian tidak ditemukan']: { EN: "Search results not found" },
  ['Coba cari artikel menggunkan kata kunci yang lain']: { EN: "Try to find articles using other keywords" },
  ['Coba cari training atau trainer menggunkan kata kunci yang lain']: { EN: "Try to find training or trainer using other keywords" },
  ['Training To Be Entrepreneur']: { EN: "Training to be entrepreneur" },
  ['Pelajari Langkah-Langkah Membangun Bisnis dari Nol']: { EN: "Learn the steps to build a business from zero" },
  ['1 Modul Training']: { EN: "1 Training Module" },
  ['Akses Selamanya']: { EN: "Access forever" },
  ['Kamu dapat mengakses 1 Training pilihan kamu selamanya']: { EN: "You can access 1 training of your choice forever" },
  ['Sertifikat Training']: { EN: "Training certificate" },
  ['Kamu akan mendapatkan sertifikat setelah menyelesaikan Training']: { EN: "You will get a certificate after completing training" },
  ['Forum Diskusi']: { EN: "discussion forum" },
  ['Bagikan pengetahuan dan pengalaman kamu di forum diskusi']: { EN: "Share your knowledge and experience in the discussion forum" },
  ['Akses Training melalui Desktop & Smartphone']: { EN: "Access Training via Desktop & Smartphone" },
  ['13 Modul Training']: { EN: "13 Training Module" },
  ['Harga per training menjadi']: { EN: "Price per training becomes" },
  ['Rp 30 ribuan']: { EN: "Rp. 30 thousand" },
  ['Kamu dapat mengakses 13 Training pilihan selamanya']: { EN: "You can access 13 choice training forever" },
  ['Mencapai Peringkat No. 1 Business Coach Terbaik di Top 100 Business Coach in the World']: { EN: "Reach rank No. 1 Best Business Coach in Top 100 Business Coach in the World" },
  ['Memecahkan Rekor Dunia Guinness World Record™ untuk The Largest Practical Business Seminar']: { EN: "Broke the world record of Guinness World Record ™ for The Largest Practical Business Seminar" },
  ['Penulis Buku Best Seller Business is FUN!™ dan Selalu Masuk di Top 10 Best Selling Book']: { EN: "Author of the Best Seller Business Is Fun! ™ Book and Always Enter the Top 10 Best Selling Book" },
  ['Founder dari GRATYO® yang masuk ke Top 10 Business Coaching Company in The World']: { EN: "Founders of Gratyo® who enter the Top 10 Business Coaching Company in the World" },
  ['Owner of FREXOR® World Class Business Automation Software Company']: { EN: "Owner of Frexor® World Class Business Automation Software Company" },
  ['Owner dari bisnis-bisnis lain di GRATYO® 360° Companies & Investor di beberapa Digital Start-Ups']: { EN: "Owners from other business-business in gratyo® 360 ° Compans & Investors in several digital start-ups" },
  ['Ketahui Strategi Yang Tepat Agar']: { EN: "Know the right strategy in order" },
  ['Bisnis Kamu Semakin Profitable']: { EN: "Your business is increasingly profitable" },
  ['Bersama Yohanes G. Pauly (Global Awards Winning Business Coach)']: { EN: "Together with Yohanes G. Pauly (Global Awards Winning Business Coach)" },
  ['Kamu akan mempelajari strategi-strategi membangun bisnis yang profitable.']: { EN: "You will learn strategies to build profitable businesses." },
  ['Bagaimana Caranya?']: { EN: "How to?" },
  ['adalah Spesial Program']: { EN: "is a special program" },
  ['untuk para Entrepreneur yang ingin Mengembangkan Bisnis']: { EN: "for entrepreneurs who want to develop a business" },
  ['Berkolaborasi dengan']: { EN: "Collaborate with" },
  ['Apakah Program Ini Cocok Untuk Saya?']: { EN: "Is this program suitable for me?" },
  ['yang akan Kamu dapatkan']: { EN: "that you will get" },
  ['ketika membeli paket program']: { EN: "When buying a program package" },
  ['Beli Sekarang & Mulai Bisnismu']: { EN: "Buy now & start your business" },
  ['Klik Beli Sekarang!']: { EN: "Click Buy Now!" },
  ['Beli Sekarang']: { EN: "Buy now" },
  ['Dapatkan 1 Video Training Gratis']: { EN: "Get 1 Free Video Training" },
  ['Upgrade Keterampilan']: { EN: "Upgrade skills" },
  ['Untuk Masa Depan']: { EN: "For the future" },
  ['Training di Terampil bisa upgrade keterampilanmu dengan Metode Active Learning dan belajar dari Trainer Berpengalaman.']: { EN: "Training in skilled can upgrade your skills with active learning methods and learn from experienced trainers." },
  ['Hanya']: { EN: "Only" },
  ['Beli Paket ini Sekarang']: { EN: "Buy this package now" },
  ['Dapatkan Akses Training Gratis']: { EN: "Get free training access" },
  ['Mulai Training Gratis']: { EN: "Start free training" },
  ['Dengan membeli Paket Terampil for To-Be Entrepreneurs']: { EN: "By buying a skilled package for to-be entrepreneurs" },
  ['Harga per training menjadi']: { EN: "Price per training becomes" },
  ['30 Ribuan']: { EN: "30 thousand" },
  ['dan Kamu']: { EN: "and you" },
  ['Lebih Hemat Rp 740.000']: { EN: "More efficient IDR 740,000" },
  ['Beli Training']: { EN: "Buy training" },
  ['Beli Paket']: { EN: "Buy a package" },
  ['Harga']: { EN: "Price" },
  ['Diajarkan Langsung oleh Trainer Berpengalaman']: { EN: "Taught directly by experienced trainers" },
  ['Trainer Populer']: { EN: "Popular Trainer" },
  ['Pelajari Langkah-Langkah']: { EN: "Learn the steps" },
  ['Membangun Bisnis dari Nol']: { EN: "Building a business from scratch" },
  ['Kamu akan mempelajari bagaimana cara yang tepat membangun bisnis dari nol.']: { EN: "You will learn the right way to build a business from zero." },
  ['Kode Voucher tidak valid untuk training ini']: { EN: "Invalid voucher code for this training" },
  ['Kode Voucher tidak valid']: { EN: "Invalid voucher code" },
  ['Kembali']: { EN: "Return" },
  ['Beli Training Prakerja']: { EN: "Buy Prakja Work Training" },
  ['Tambahkan kode voucher yang sudah kamu beli di Tokopedia dan MauBelajarApa']: { EN: "Add the voucher code that you have bought at Tokopedia and Maubelajarapa" },
  ['Tambah Kode Voucher']: { EN: "Add voucher code" },
  ['Jika kamu belum punya voucher, silahkan beli voucher Terampil di Karier.mu, Tokopedia, dan Pintar.co']: { EN: "If you don't have a voucher yet, please buy a skilled voucher in your career. Tokopedia, and Smart.co" },
  ['Cek']: { EN: "Check" },
  ['Kartu Kredit']: { EN: "Credit card" },
  ['Paket']: { EN: "Package" },
  ['Total Tagihan']: { EN: "Total bill" },
  ['Isi Data Kartu Kredit']: { EN: "Fill in the Credit Card Data" },
  ['Nama Sesuai Kartu']: { EN: "Name according to the card" },
  ['Nama']: { EN: "Name" },
  ['Nomor Kartu']: { EN: "Card number" },
  ['Tanggal Kadaluarsa']: { EN: "Expired date" },
  ['Kode Keamanan']: { EN: "Security code" },
  ['Bayar']: { EN: "Pay" },
  ['3 angka di belakang kartu']: { EN: "3 numbers behind the card" },
  ['Contoh: 1234 5678 9012 3456']: { EN: "Example: 1234 5678 9012 3456" },
  ['Daftar Jadi Trainer']: { EN: "List of being a trainer" },
  ['Menjadi Trainer di Terampil']: { EN: "Become a trainer in skilled" },
  ['Temukan banyak pelatihan berkualitas yang diajarkan langsung oleh para Trainer terbaik dan dapatkan sertifikat hanya di Terampil.']: { EN: "Find a lot of quality training taught directly by the best trainers and get certificates only in skilled." },
  ['Daftar Menjadi Trainer']: { EN: "Register to be a trainer" },
  ['Pendaftaran Diterima']: { EN: "Registration is received" },
  ['Data Anda akan dicek terlebih dahulu oleh tim Terampil. Jika approve maka Anda akan menerima email dari tim Terampil untuk tahap selanjutnya']: { EN: "Your data will be checked first by a skilled team. If approve then you will receive an email from a skilled team for the next stage" },
  ['File pdf, doc, png maksimal 5 MB']: { EN: "Pdf file, doc, png maximum 5 mb" },
  ['Nama Lengkap']: { EN: "Full name" },
  ['Kota Tinggal']: { EN: "The city lives" },
  ['Pilih Kategori']: { EN: "Select the category" },
  ['Kategori Wajib di Isi']: { EN: "Mandatory category in filling" },
  ['Nomor HP']: { EN: "Mobile phone number" },
  ['Nomor HP tidak boleh kosong']: { EN: "Mobile number may not be empty" },
  ['Format email salah. Contoh: terampil@gmail.com']: { EN: "Wrong email format. Example: skilled@gmail.com" },
  ['Media Sosial']: { EN: "Social media" },
  ['URL Media Sosial']: { EN: "Social Media URL" },
  ['Sosial Media wajib di Isi dan Url formal']: { EN: "Social media must be filled in and formal url" },
  ['Pekerjaan']: { EN: "Work" },
  ['Pekerjaan wajib di Isi']: { EN: "Mandatory work in filling" },
  ['Jabatan']: { EN: "Position" },
  ['Jabatan wajib di Isi']: { EN: "Position must be contents" },
  ['Lama Bekerja']: { EN: "Length of work" },
  ['Lama Bekerja. Contoh : 7 tahun 1 bulan']: { EN: "Length of work. Example: 7 years 1 month" },
  ['Lama bekerja wajib di Isi']: { EN: "Long work must be filled in" },
  ['CV tidak boleh kosong dan Format CV harus PDF, Maksimal ukuran 5MB']: { EN: "CV must not be empty and the CV format must be PDF, a maximum size of 5MB" },
  ['Daftar']: { EN: "Register" },
  ['Pendaftaran Anda sudah kami terima.']: { EN: "We have received your registration." },
  ['Pembayaran Pending']: { EN: "Pending payment" },
  ['Pembayaran kamu lagi diproses. Pindah ke halaman Cara Bayar untuk melanjutkan proses pembayaran.']: { EN: "Your payment is processed again. Move to the page how to pay to continue the payment process." },
  ['Pembayaran Gagal']: { EN: "Payment fails" },
  ['Kami tidak bisa memproses pembayaran kamu.']: { EN: "We cannot process your payment." },
  ['Gagal Bayar']: { EN: "Failure to pay" },
  ['Pembayaran Berhasil']: { EN: "Successful payment" },
  ['Terima kasih sudah membeli paket di Terampil dan selamat menikmati konten kami.']: { EN: "Thank you for buying a package in skilled and enjoy our content." },
  ['Pembayaran kamu berhasil diterima pada tanggal']: { EN: "Your payment was successfully received on the date" },
  ['Bulanan']: { EN: "Monthly" },
  ['Semesteran']: { EN: "Semester" },
  ['Tahunan']: { EN: "Annual" },
  ['Paket Pembayaran']: { EN: "Payment package" },
  ['Tanggal Pembayaran']: { EN: "Payment date" },
  ['Modul Training']: { EN: "Training Module" },
  ['Nama Pengguna']: { EN: "Username" },
  ['Metode Pembayaran']: { EN: "Payment method" },
  ['Nomor Virtual Account']: { EN: "Virtual Account Number" },
  ['Nomor Tagihan']: { EN: "Bill number" },
  ['Order Detail']: { EN: "Order details" },
  ['Kembali ke Home']: { EN: "Back to home" },
  ['Riwayat Pembelian']: { EN: "Purchase history" },
  ['Kembali Ke Riwayat Pembelian']: { EN: "Back to the purchase history" },
  ['Lihat Resi']: { EN: "See Receipt" },
  ['Terbayar']: { EN: "Paid off" },
  ['Redeem Voucher Berhasil']: { EN: "Redeem voucher was successful" },
  ['Voucher Prakerja']: { EN: "Prakja Work Voucher" },
  ['Ikuti Webinar bersama Pemateri Berpengalaman']: { EN: "Follow the webinar with experienced speakers" },
  ['Preview Webinar']: { EN: "Webinar Preview" },
  ['Apa yang akan kamu pelajari']: { EN: "What will you learn" },
  ['Deskripsi']: { EN: "Description" },
  ['Sudah Terdaftar']: { EN: "Already registered" },
  ['Daftar Sekarang']: { EN: "Sign up now" },
  ['Join Webinar Sekarang']: { EN: "Join webinar now" },
  ['Ingatkan Saya']: { EN: "Remind me" },
  ['Pengiriman OTP sudah melebihi batas kirim. Mohon untuk menunggu 1 jam lagi.']: { EN: "OTP shipments have exceeded the sending limit. Please wait 1 more hour." },
  ['Nomor telepon telah terdaftar']: { EN: "Telephone number has been registered" },
  ['Lengkapi Profil Kamu']: { EN: "Complete your profile" },
  ['Sebelum mulai pembayaran, silahkan lengkapi profil kamu terlebih dahulu.']: { EN: "Before starting payment, please complete your profile first." },
  ['Nama wajib diisi']: { EN: "Name must be filled in" },
  ['Pastikan nama sudah benar. Nama akan digunakan untuk Sertifikat']: { EN: "Make sure the name is correct. The name will be used for certificates" },
  ['Email wajib diisi']: { EN: "Email must be filled in" },
  ['Email']: { EN: "E-mail" },
  ['Nomor HP tidak valid']: { EN: "Invalid cellphone number" },
  ['Simpan']: { EN: "Save" },
  ['1. Soal terdiri dari 24 soal.']: { EN: "1. Questions consist of 24 questions." },
  ['2. Baca 4 kalimat yang terdapat di setiap soal.']: { EN: "2. Read the 4 sentences found in each question." },
  ['3. Klik lingkaran di bawah']: { EN: "3. Click the circle below" },
  ['di samping kalimat yang']: { EN: "Next to that sentence" },
  ['menggambarkan diri kamu.']: { EN: "Describe yourself." },
  ['PALING']: { EN: "MOST" },
  ['4. Klik lingkaran di bawah']: { EN: "4. Click the circle below" },
  ['KURANG']: { EN: "NOT ENOUGH" },
  ['5. Untuk masing-masing soal pilih satu respon']: { EN: "5. For each question select one response" },
  ['dan']: { EN: "And" },
  ['Apa itu Assesment Test?']: { EN: "What is an assessment test?" },
  ['Assesment Test adalah tes yang digunakan untuk mengukur kepribadian seseorang berdasarkan perilaku mereka dalam menghadapi berbagai situasi. Misalnya, ketika menghadapi tantangan, menanggapi aturan, lingkungan, orang lain, atau suatu masalah. Hasil Test ini sering digunakan oleh banyak perusahaan untuk mengenal kepribadian karyawan sehingga bisa digunakan untuk merancang strategi meningkatkan kinerja karyawan.']: { EN: "Assessment test is a test used to measure a person's personality based on their behavior in dealing with various situations. For example, when facing challenges, responding to rules, the environment, others, or a problem. The results of this test are often used by many companies to recognize employee personality so that it can be used to design strategies to improve employee performance." },
  ['Berikut beberapa panduan dalam mengikuti']: { EN: "Here are some guidelines in following" },
  ['Contoh']: { EN: "Example" },
  ['Batal']: { EN: "Cancelled" },
  ['Mulai Tes']: { EN: "Start the test" },
  ['Paling sesuai dengan kamu']: { EN: "Most suits you" },
  ['Kurang sesuai dengan kamu']: { EN: "Not according to you" },
  ['Pertanyaan']: { EN: "Question" },
  ['dari']: { EN: "from" },
  ['Selanjutnya']: { EN: "Furthermore" },
  ['Rencanakan dan bangun karir Anda bersama Terampil. Temukan berbagai training terbaik yang dapat membantu Anda meraih jenjang karir dambaan.']: { EN: "Plan and build your career together skilled. Find a variety of the best training that can help you achieve a long -term career path." },
  ['Yuk ikuti Kompetisi ini dan menangkan hadiah Voucher Training dengan total jutaan rupiah dan Voucher Gopay untuk 10 orang pemenang.']: { EN: "Let's follow this competition and win a training voucher prize with a total of millions of rupiah and Gopay vouchers for 10 winners." },
  ['Ikuti Langkah-Langkah berikut:']: { EN: "Follow the following steps:" },
  ['Share informasi #KompetisiSertifikat ini di IG Story, Tag 3 teman kamu dan Terampil']: { EN: "Share this #KomPomtifikatikat information on IG Story, Tag 3 Your Friends and Skilled" },
  ['Download aplikasi Terampil dan daftar dengan Kode Khusus berikut']: { EN: "Download the skilled application and register with the following special code" },
  ['Kemudian Ikuti Training ini hanya di Terampil']: { EN: "Then follow this training only in skilled" },
  ['Desain Konten Promosi Produk Dengan Canva bersama Syammas P. Syarbini']: { EN: "Design of product promotion content with Canva with Syammas P. Syarbini" },
  ['Selesaikan Training']: { EN: "Complete training" },
  ['Share foto Sertifikat kamu di IG Story dan tag Terampil']: { EN: "Share your certificate photos on IG Story and Skilled Tags" },
  ['Download aplikasi Terampil untuk pengalaman belajar lebih menyenangkan.']: { EN: "Download skilled applications for learning experiences are more fun." },
  ['Pertanyaan Umum']: { EN: "Common question" },
  ['Murid']: { EN: "Student" },
  ['Trainer']: { EN: "Trainer" },
  ['Silahkan Beri Feedback Kamu']: { EN: "Please give your feedback" },
  ['Feedback Untuk Webinar']: { EN: "Feedback for webinar" },
  ['Tulis feedback kamu untuk webinar disini...']: { EN: "Write your feedback for webinar here ..." },
  ['Kirim']: { EN: "Send" },
  ['Webinar untukmu bulan ini']: { EN: "Webinar for you this month" },
  ['Undang Teman dapat Training Gratis']: { EN: "Invite friends to get free training" },
  ['Hanya dengan mengundang 3 teman, kamu bisa mendapatkan Full Access Training Gratis.']: { EN: "Only by inviting 3 friends, you can get Full Access Training for free." },
  ['Undang Teman']: { EN: "Invite friends" },
  ['Webinar yang akan diikuti']: { EN: "Webinar that will be followed" },
  ['Pelatihan Saya']: { EN: "My training" },
  ['Selesaikan Training Kamu']: { EN: "Complete your training" },
  ['Belum Punya Voucher?']: { EN: "Don't have a voucher yet?" },
  ['Beli Disini']: { EN: "Buy here" },
  ['Training Baru']: { EN: "New training" },
  ['Training Terbaru']: { EN: "Latest Training" },
  ['Rekomendasi untuk Kamu']: { EN: "Recommendations for you" },
  ['Training Populer']: { EN: "Popular training" },
  ['Forum Teraktif Bulan Ini']: { EN: "The Most Active Forum This Month" },
  ['Sebagai seorang leader saya sering banget mengalami kesulitan ketika mengarahkan team. Training ini membantu saya banget untuk paham tentang leadership. Saya ada 1 pertanyaan, menurut kalian gaya kepemimpinan itu baiknya disesuaikan dengan team atau bagaimana?']: { EN: "As a leader I often experience difficulties when directing the team. This training really helps me to understand about leadership. I have 1 question, according to you, the leadership style is good to be adjusted to the team or what?" },
  ['Kalau nurut saya sih sesuai team, karena kita manage people']: { EN: "If I think it's according to the team, because we manage people" },
  ['Gaya kepemimpinan orang berbeda, tapi sebagai leader harus cerdas baca situasi & baca team, harus bisa menyesuaikan diri juga, shg tidak ada miscom yang terjadi']: { EN: "The leadership style of people is different, but as a leader must be smart in reading the situation & reading the team, must be able to adjust too, so no miscom happened" },
  ['Kalau menurut saya gaya kepemimpinan disesuaikan dengan team, karena bagaimana pun juga yang menjalankan misi adalah team.']: { EN: "In my opinion, the leadership style is adjusted to the team, because after all the mission is the team." },
  ['Good training anw, untuk bisnis retail dengan segmentasi anak muda, sebaiknya socmed apa aja yg digunakan ya? Apakah ada insight dari teman2?']: { EN: "Good training anw, for retail businesses with young segmentation, what should you be used? Is there an insight from friends?" },
  ['Kalau menurut saya untuk segmentasi anak muda saat ini platform yang cocok ada Instagram dan Tiktok, Instagram bisa untuk foto atau branding produk dan Tiktok lebih ke Content Engagement.']: { EN: "In my opinion, for the segmentation of young people today the suitable platform is Instagram and Tiktok, Instagram can be used for photos or branding products and tiktok more to content engagement." },
  ['Kalau menurut saya socmed seperti Facebook Marketplace, Instagram dan Tiktok cocok untuk segmentasi anak muda. Yang terpenting bagaimana memaksimalkan social media tersebut dengan target yang dituju.']: { EN: "In my opinion, Socmed like Facebook Marketplace, Instagram and Tiktok is suitable for young segmentation. The most important thing is how to maximize the social media with the intended target." },
  ['FB, IG sih itu udah fit banget.']: { EN: "FB, IG is already very fit." },
  ['Mau nanya, kalau mimpi ada nilanya, tetapi itu produk gak diminati market? itu gimana ya?']: { EN: "Want to ask, if there is a dream there is a value, but that product is not in demand by the market? What is that?" },
  ['Hmm nice question mba Rani. Kalau menurut saya, edukasi produk itu yang terpenting, shg market pun pelan2 bisa mengetahui nilai dari produk tersebut. Ini mungkin sering terjadi pada startup2 yg marketnya masih ngawang.']: { EN: "Hmm nice question mba Rani. In my opinion, the education of the product is the most important, so the market can slowly know the value of the product. This may often occur in startups whose markets are still ngawang." },
  ['Kalau menurut saya ini kembali lagi ke Strategi Business nya, kalau memang Market Opportunity nya bagus berarti harus mengedukasi market, atau bisa juga pilih market yang sudah jelas ada sehingga produknya juga banyak peminatnya.']: { EN: "If in my opinion this is back to the business strategy, if indeed the market opportunity is good, it means you have to educate the market, or you can also choose a market that is clearly there so that the products are also many enthusiasts." },
  ['Kalau menurut saya seharusnya mungkin bisa riset terlebih dahulu produk seperti apa yang diinginkan oleh masyarakat, agar business plannya lebih jelas dan produknya diminati.']: { EN: "If in my opinion it should be possible to be able to research first what kind of products they want by the community, so that the business plus is clearer and the product is in demand." },
  ['Kamu mendapatkan paket']: { EN: "You get a package" },
  ['karena telah mendaftar ulang ke Terampil']: { EN: "Because it has re -registered to skilled" },
  ['Kamu mendapatkan akses']: { EN: "You get access" },
  ['selama 1 hari']: { EN: "for 1 day" },
  ['Selama free trial kamu memiliki']: { EN: "During the free trial you have" },
  ['3 kuota']: { EN: "3 quota" },
  ['untuk mengikuti video training manapun secara']: { EN: "to follow any video training" },
  ['GRATIS']: { EN: "FREE" },
  ['Detail Invoice']: { EN: "Invoice details" },
  ['Invoice pembelian']: { EN: "Purchase invoice" },
  ['Detail Pembeli']: { EN: "Buyer details" },
  ['Nama Pembeli']: { EN: "Buyer's name" },
  ['Email Pembeli']: { EN: "Buyer's email" },
  ['No. HP Pembeli']: { EN: "No. Buyer's cellphone" },
  ['Detail Pembayaran']: { EN: "Payment details" },
  ['Waktu Pembayaran']: { EN: "Payment time" },
  ['Jenis Pembelian']: { EN: "Type of purchase" },
  ['Total']: { EN: "Total" },
  ['Maksimalkan Pengajaran Anda dalam 3 Langkah']: { EN: "Maximize your teaching in 3 steps" },
  ['Rencanakan Training']: { EN: "Plan training" },
  ['Pilih judul training, susun rencana pembelajarannya dan persiapkan materi ajar beserta kebutuhan pendukung lainnya.']: { EN: "Select the title training, arrange the learning plan and prepare teaching materials along with other supporting needs." },
  ['Rekam Video Training']: { EN: "Video Training Record" },
  ['Tampil secara memukau dan antusias di depan kamera dengan penuh percaya diri']: { EN: "Appear stunning and enthusiastic in front of the camera with confidence" },
  ['Bangun Interaksi']: { EN: "Build interaction" },
  ['Hidupkan forum diskusi dengan menyapa peserta training dan jawablah pertanyaan-pertanyaan mereka secara rutin.']: { EN: "Turn on the discussion forum by greeting training participants and answer their questions regularly." },
  ['Jadi Trainer']: { EN: "So trainer" },
  ['Jadilah trainer di terampil sekarang juga!']: { EN: "Be a trainer in skilled right now!" },
  ['Mari Bergabung Dalam']: { EN: "Let's join in" },
  ['Gerakan Menuju Indonesia Terampil']: { EN: "Movement Towards Skilled Indonesia" },
  ['Bagikan pengalaman dan keahlian Anda dalam video pelatihan online berkualitas untuk memajukan masyarakat Indonesia sesuai dengan kebutuhan dunia']: { EN: "Share your experience and expertise in quality online training videos to advance Indonesian people according to world needs" },
  ['Apa itu Prakerja ?']: { EN: "What is Prakerja?" },
  ['Belajar lebih nyaman menggunakan aplikasi Terampil']: { EN: "Learn more comfortable using skilled applications" },
  ['Belajar lebih nyaman bersama Trainer terbaik secara online & offline hanya di aplikasi Terampil.']: { EN: "Learn more comfortable with the best trainers online & offline only in skilled applications." },
  ['Buka Aplikasi Terampil']: { EN: "Open a skilled application" },
  ['Video selanjutnya']: { EN: "Next video" },
  ['Putar Video Selanjutnya']: { EN: "Play the next video" },
  ['Putar Ulang']: { EN: "Rewind" },
  ['Beragam Manfaat Menjadi Trainer']: { EN: "Various benefits of becoming a trainer" },
  ['Menginspirasi']: { EN: "Inspire" },
  ['Trainer yang menginspirasi dapat menghidupkan harapan, menyalakan imajinasi, dan memperkuat motivasi']: { EN: "Inspiring trainers can revive expectations, light their imagination, and strengthen motivation" },
  ['Membangun Bangsa']: { EN: "Building a nation" },
  ['Dengan mengajar Anda berperan dalam membangun SDM bangsa Indonesia menjadi lebih baik']: { EN: "By teaching you play a role in building the human resources of the Indonesian people for the better" },
  ['Menambah Penghasilan']: { EN: "Increasing income" },
  ['Dapatkan penghasilan tambahan dengan memaksimalkan potensi Anda sebagai seorang trainer']: { EN: "Get additional income by maximizing your potential as a trainer" },
  ['Pembawa Acara dan Politikus']: { EN: "Host and politician" },
  ['Saya senang sekali bisa menjadi bagian dari Terampil. Pemateri-pemateri di Terampil adalah para profesional yang kita percaya sangat bermanfaat untuk diikuti atau disimak, jadi nggak rugi gabung di Terampil. Semoga kehadiran Terampil bisa bermanfaat untuk mendukung profesionalisme di dunia kerja maupun dunia usaha.']: { EN: "I am very happy to be a part of skilled. The speakers in skilled are professionals who we believe are very useful to be followed or listened to, so they don't lose to join in skilled. Hopefully the presence of skilled can be useful to support professionalism in the world of work and the business world." },
  ['Terampil adalah cara baru untuk kita mengembangkan diri dengan berbagai pelatihan yang ada. Menjadi trainer di Terampil seru banget karena team yang sangat kooperatif. Proses syuting video pembelajaran pun sangat lancar. Semoga ke depannya bisa mengajar materi lain di Terampil. Sukses untuk Terampil!']: { EN: "Skillful is a new way for us to develop ourselves with various existing training. Being a trainer in a very exciting skill because of a very cooperative team. The learning video shooting process is very smooth. Hopefully in the future you can teach other materials in skilled. Success for skilled!" },
  ['Terampil sangat mengerti apa yang market butuhkan saat ini. Saat proses shooting video pembelajaran, Terampil team is very helpful since the day 1. Mereka bantu mengarahkan materi dan skill apa yang saat ini dibutuhkan oleh market dan trend. Selain itu prosesnya digarap dengan serius dan profesional']: { EN: "Skilled really understand what the market needs right now. During the process of shooting learning videos, skilled team is very helpful since 1. They help direct what material and skills that are currently needed by the market and trend. Besides that, the process is worked on seriously and professionally" },
  ['Pengalaman yang pleasant jadi trainer di Terampil! Aku nggak nyangka proses produksi dan hasil videonya sangat berkualitas. LO-nya juga sangat informatif, dipandu dalam pembuatan modul agar materi bisa tersampaikan dengan baik. Terampil ini platform untuk semua orang dengan harga terjangkau dan pemateri profesional. Sangat mempermudah semua kalangan untuk mendapat keterampilan baru!']: { EN: "A pleasant experience being a trainer in skilled! I didn't expect the production process and the video results were very high quality. The LO is also very informative, guided in making modules so that the material can be conveyed properly. This skilled platform for everyone at affordable prices and professional speakers. It is very easy for all people to get new skills!" },
  ['Ulasan untuk']: { EN: "Review for" },
  ['Kata Para Trainer Terampil']: { EN: "Said the skilled trainers" },
  ['Trainer dari berbagai disiplin ilmu sudah membagikan cerita dan pengalamannya di Terampil.']: { EN: "Trainers from various disciplines have shared their stories and experiences in skilled." },
  ['Kebijakan Privasi']: { EN: "Privacy Policy" },
  ['Kebijakan Privasi ini berlaku jika Anda menggunakan Layanan kami. Kami menawarkan pilihan kepada pengguna tentang data yang kami kumpulkan, gunakan, dan bagikan sebagaimana dijelaskan dalam Kebijakan Privasi ini, Pengaturan, dan Pusat Bantuan kami.']: { EN: "This privacy policy applies if you use our services. We offer choices to users about the data we collect, use, and share as explained in this Privacy Policy, Settings, and Our Help Center." },
  ['Terampil dapat memodifikasi Kebijakan Privasi ini, dan jika kami melakukan perubahan material terhadap kebijakan ini, kami akan menyampaikan pemberitahuan melalui Layanan, atau melalui cara lain, guna memberi Anda kesempatan untuk meninjau perubahan tersebut sebelum diberlakukan. Jika Anda tidak menyetujui perubahan ini, Anda dapat menutup akun.']: { EN: "Skillful can modify this privacy policy, and if we make material changes to this policy, we will submit notifications through services, or through other means, in order to give you the opportunity to review these changes before it is enforced. If you do not approve of this change, you can close the account." },
  ['Anda menyatakan bahwa jika Anda meneruskan penggunaan Layanan kami setelah kami memublikasikan atau mengirim pemberitahuan tentang perubahan terhadap Kebijakan Privasi ini, pengumpulan, penggunaan, dan pembagian data pribadi Anda diatur oleh Kebijakan Privasi yang diperbarui ini, dimulai sejak tanggal berlakunya.']: { EN: "You state that if you continue the use of our services after we publish or send notifications about changes to this Privacy Policy, the collection, use, and distribution of your personal data is regulated by this updated Privacy Policy, starting from the date of its current date." },
  ['Data yang Anda Berikan kepada Kami']: { EN: "The data you give us" },
  ['Pendaftaran']: { EN: "Registration" },
  ['Untuk membuat akun, Anda perlu memberikan data yang mencakup nama, alamat email dan/atau nomor ponsel, dan kata sandi. Jika Anda berlangganan, Anda perlu memberikan informasi pembayaran (misalnya, kartu kredit) dan penagihan.']: { EN: "To create an account, you need to provide data that includes names, email addresses and/or mobile numbers, and passwords. If you subscribe, you need to provide payment information (for example, credit card) and billing." },
  ['Profil']: { EN: "Profile" },
  ['Anda memiliki pilihan tentang informasi yang ada di profil, seperti pengalaman kerja, kategori training, foto dan rekomendasi training. Informasi profil akan membantu Anda mendapatkan lebih banyak manfaat dari Layanan kami.']: { EN: "You have a choice about information in the profile, such as work experience, training category, photo and training recommendations. Profile information will help you get more benefits from our services." },
  ['Penggunaan Layanan']: { EN: "Service use" },
  ['Kami mencatat data penggunaan ketika Anda mengunjungi atau dengan cara lain menggunakan Layanan kami, termasuk situs, aplikasi, dan teknologi platform, seperti ketika Anda melihat atau mengklik konten (misalnya, video training), melakukan pencarian, menginstal atau memperbarui salah satu aplikasi seluler kami, memberikan link training. Kami menggunakan informasi login, perangkat, dan alamat protokol internet (“IP”) untuk mengidentifikasi dan mencatat penggunaan Anda.']: { EN: "We record the use of data when you visit or in other ways using our services, including sites, applications, and platform technology, such as when you see or click content (for example, video training), search, install or update one of our cellular applications, provide link training. We use login information, devices, and address of the Internet Protocol (\"IP\") to identify and record your use." },
  ['Lainnya']: { EN: "Other" },
  ['Layanan kami bersifat dinamis dan kami sering memperkenalkan fitur baru yang mungkin membutuhkan pengumpulan informasi baru. Jika kami mengumpulkan data pribadi yang materinya berbeda atau melakukan perubahan material dalam cara kami mengumpulkan, menggunakan, atau membagikan data Anda, kami akan memberitahukan Anda dan mungkin memodifikasi Kebijakan Privasi ini juga.']: { EN: "Our services are dynamic and we often introduce new features that might require new information gathering. If we collect personal data with different materials or make material changes in the way we collect, use, or share your data, we will tell you and maybe modify this privacy policy as well." },
  ['Cara Kami Menggunakan Data Anda']: { EN: "How we use your data" },
  ['Kami menggunakan data yang kami miliki tentang Anda untuk menyediakan dan mempersonalisasi Layanan, termasuk dengan bantuan sistem otomatis dan inferensi yang kami buat, agar Layanan kami menjadi lebih relevan dan berguna bagi Anda.']: { EN: "We use our data about you to provide and personalize services, including the help of the automatic system and inference that we make, so that our services become more relevant and useful for you." },
  ['Dengan Layanan kami, Anda dapat terus mengikuti informasi terbaru tentang training terkait yang penting bagi Anda, serta dari trainer yang direkomendasikan. Dengan Layanan kami, Anda juga dapat meningkatkan keahlian profesional atau mempelajari keahlian baru. Kami menggunakan data yang kami miliki tentang Anda (misalnya data yang Anda berikan, data yang kami kumpulkan dari interaksi Anda dengan Layanan dan inferensi yang kami buat dari data yang kami miliki tentang Anda) untuk mempersonalisasikan Layanan kami untuk Anda, misalnya dengan merekomendasikan atau memberikan peringkat kepada konten training dan percakapan forum yang relevan di Layanan kami.']: { EN: "With our service, you can continue to follow the latest information about related training that is important for you, as well as from the recommended trainer. With our services, you can also improve professional expertise or learn new expertise. We use the data that we have about you (for example the data you provide, the data we collect from your interactions with the services and inferences that we make from the data we have about you) to personalize our services for you, for example by recommending or rating To the content of training and relevant forum conversations on our services." },
  ['Komunikasi']: { EN: "Communication" },
  ['Kami akan menghubungi Anda melalui email, telepon seluler atau melalui cara lain dalam Layanan kami, termasuk SMS dan notifikasi push. Kami akan mengirimi Anda pesan mengenai ketersediaan Layanan, keamanan, atau masalah lain yang berhubungan dengan layanan. Kami juga mengirimkan pesan mengenai cara menggunakan Layanan, pembaruan jaringan, pengingat dan pesan promosi dari kami. Anda dapat mengubah preferensi komunikasi kapan saja. Harap perhatikan bahwa Anda tidak dapat menolak menerima layanan pesan dari kami, termasuk pemberitahuan keamanan.Kami juga memungkinkan komunikasi antara Anda dan orang lain melalui Layanan kami, termasuk misalnya diskusi forum.']: { EN: "We will contact you by email, cell phone or through other ways in our service, including SMS and Push notifications. We will send you a message about the availability of services, security, or other problems related to services. We also send messages on how to use services, network updates, reminder and promotional messages from us. You can change the preferences of communication at any time. Please note that you cannot refuse to accept messages from us, including security notifications. We also allow communication between you and others through our services, including forum forum discussions." },
  ['Pengembangan Layanan']: { EN: "Service development" },
  ['Kami menggunakan data, termasuk masukan publik, untuk melakukan riset dan pengembangan untuk Layanan agar kami dapat menyediakan pengalaman yang lebih baik, lebih intuitif, dan lebih personal kepada Anda dan orang lain.']: { EN: "We use data, including public input, to conduct research and development for services so that we can provide better, more intuitive, and more personal experiences to you and others." },
  ['Layanan Pelanggan']: { EN: "Customer service" },
  ['Kami menggunakan data (yang mungkin mencakup komunikasi Anda) untuk menyelidiki, menanggapi, dan menyelesaikan keluhan dan masalah Layanan (misalnya bug).']: { EN: "We use data (which may include your communication) to investigate, respond, and resolve complaints and service problems (for example bugs)." },
  ['Data Analitik']: { EN: "Analytical data" },
  ['Kami menggunakan data Anda untuk membuat data analitik. Contohnya, kami mungkin menggunakan data Anda untuk membuat statistik mengenai pengguna, profesi atau industri mereka, untuk mengkalkulasi halaman yang sering dikunjungi atau untuk memublikasikan demografi pengunjung.']: { EN: "We use your data to create analytic data. For example, we may use your data to make statistics about users, their profession or industry, to calculate pages that are often visited or to publish demographic visitors." },
  ['Keamanan']: { EN: "Security" },
  ['Kami menggunakan data untuk keamanan, pencegahan penipuan, dan penyelidikan.']: { EN: "We use data for security, fraud prevention, and investigation." },
  ['Penyimpanan Data']: { EN: "Data storage" },
  ['Pada umumnya kami menyimpan data pribadi Anda selama akun Anda tetap aktif atau selama dibutuhkan untuk menyediakan Layanan kepada Anda. Dalam beberapa kasus, kami memilih untuk mempertahankan informasi tertentu (misalnya wawasan tentang Layanan yang Anda gunakan) dalam bentuk umum atau agregat.']: { EN: "In general, we save your personal data as long as your account remains active or as long as it is needed to provide services to you. In some cases, we choose to maintain certain information (for example insights about the services you use) in general or aggregate forms." },
  ['Hak Mengakses dan Mengontrol Data Pribadi']: { EN: "The right to access and control personal data" },
  ['Anda dapat mengakses dan mengelola data pribadi anda yang kami miliki, seperti']: { EN: "You can access and manage your personal data that we have, such as" },
  ['Menghapus Data']: { EN: "Delete data" },
  ['Anda dapat menghapus semua data pribadi Anda (misalnya, jika Anda sudah tidak memerlukan Layanan kami).']: { EN: "You can delete all your personal data (for example, if you don't need our service)." },
  ['Mengubah atau Mengoreksi Data']: { EN: "Change or correct data" },
  ['Anda dapat mengedit beberapa data pribadi melalui akun Anda. Anda juga dapat meminta kami mengubah, memperbarui, atau memperbaiki data Anda dalam kasus tertentu, terutama jika data tidak akurat.']: { EN: "You can edit some personal data through your account. You can also ask us to change, update, or improve your data in certain cases, especially if the data is not accurate." },
  ['Penutupan Akun']: { EN: "Account closure" },
  ['Jika Anda memilih untuk menutup akun, Kami tetap menyimpan data pribadi Anda. Hal tersebut diperlukan secara wajar untuk menyelesaikan perselisihan, mempertahankan keamanan, mencegah penipuan dan penyalahgunaan.']: { EN: "If you choose to close your account, we still save your personal data. This is needed naturally to resolve disputes, maintain security, prevent fraud and abuse." },
  ['Verifikasi Email']: { EN: "Email verification" },
  ['Verifikasi Email User']: { EN: "User email verification" },
  ['Konfirmasi Email Gagal!']: { EN: "Confirmation of the email failed!" },
  ['Link Konfirmasi Email invalid. Silahkan klik tombol “Kirim Ulang” untuk meminta link konfirmasi email baru']: { EN: "In invalid email confirmation link. Please click the \"Re -Send\" button to request a new email confirmation link" },
  ['Link Konfirmasi telah dikirim']: { EN: "The confirmation link has been sent" },
  ['Kirim Ulang']: { EN: "Resending" },
  ['Konfirmasi Email Berhasil!']: { EN: "Email confirmation successful!" },
  ['telah berhasil dikonfirmasi. Silahkan kembali masuk ke Terampil untuk menonton ribuan training training dari Profesional.']: { EN: "has been confirmed. Please go back to skilled to watch thousands of training training from professionals." },
  ['Email kamu']: { EN: "Your email" },
  ['Masuk']: { EN: "Login" },
  ['Jadi']: { EN: "So" },
  ['sekarang']: { EN: "Now" },
  ['kemudian']: { EN: "Then" },
  ['Become an expert in any industry']: { EN: "Become an expert in any industry" },
  ['Coba Gratis!']: { EN: "Try free!" },
  ['Lihat Paket']: { EN: "See the package" },
  ['Akun anda terdaftar menggunakan Google. Silahkan masuk dengan Google.']: { EN: "Your account is registered using Google. Please enter with Google." },
  ['daftar sekaarang']: { EN: "List of now" },
  ['Masuk Menggunakan Nomor HP']: { EN: "Enter using the cellphone number" },
  ['Masuk Menggunakan Google']: { EN: "Enter using Google" },
  ['atau']: { EN: "or" },
  ['Kata Sandi']: { EN: "Password" },
  ['Kata sandi minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, simbol dan angka.']: { EN: "A minimum password of 8 characters, consisting of capital letters, lowercase letters, symbols and numbers." },
  ['Lanjutkan']: { EN: "Continue" },
  ['Lupa Kata Sandi?']: { EN: "Forgot password?" },
  ['Belum punya akun?']: { EN: "Don't have an account yet?" },
  ['Login Gagal']: { EN: "Login fails" },
  ['Nomor telepon belum terdaftar. Silahkan klik tombol daftar untuk melakukan pendaftaran.']: { EN: "Telephone number has not been registered. Please click the Register button to register." },
  ['Akun anda belum terdaftar. Silahkan klik tombol daftar untuk melakukan pendaftaran.']: { EN: "Your account has not been registered. Please click the Register button to register." },
  ['Kuota Daftar Ulang Penuh']: { EN: "Full re -registration quota" },
  ['Kuota pendaftaran untuk tanggal']: { EN: "Registration quota for date" },
  ['sudah penuh. Silakan ganti ke tanggal lain untuk lanjut ke proses Daftar Ulang.']: { EN: "already full. Please change to another date to continue to the re -registration process." },
  ['Ganti Tanggal']: { EN: "Change date" },
  ['Pendaftaran Gagal']: { EN: "Registration fails" },
  ['Anda telah memiliki akun dengan nomor telepon ini. Silahkan klik tombol Login untuk masuk']: { EN: "You already have an account with this telephone number. Please click the Login button to enter" },
  ['Anda telah memiliki akun dengan email ini. Silahkan klik tombol Login untuk masuk']: { EN: "You already have an account with this email. Please click the Login button to enter" },
  ['Ragam Kolaborasi dengan Terampil']: { EN: "Collaboration variety with skilled" },
  ['Identifikasi Kebutuhan Skill Sesuai dengan Industri Perusahaan']: { EN: "Identification of skill needs in accordance with the company's industry" },
  ['8 dari 10 perusahaan di Indonesia kesulitan menemukan pekerja muda yang siap bekerja dan memiliki skill mumpuni sesuai industri perusahaan.']: { EN: "8 out of 10 companies in Indonesia have difficulty finding young workers who are ready to work and have qualified skills according to the company's industry." },
  ['Produksi Modul Training']: { EN: "Production of Training Module" },
  ['Kolaborasi dalam pembuatan modul training berkualitas sesuai dengan kebutuhan industri.']: { EN: "Collaboration in making quality training modules according to industrial needs." },
  ['Evaluasi dan Verifikasi Modul Training']: { EN: "Evaluation and verification of the training module" },
  ['Tingkatkan kualitas dan kepercayaan dengan kolaborasi dalam evaluasi dan verifikasi modul training sesuai standar kualitas pelatihan.']: { EN: "Improve the quality and trust with collaboration in the evaluation and verification of the training module according to training quality standards." },
  ['Kerjasama dengan Universitas Terbaik']: { EN: "Cooperation with the best universities" },
  ['Kolaborasi industri dan universitas, bersama-sama tingkatkan skill pekerja dan pengusaha muda.']: { EN: "Industrial and university collaboration, together to increase the skills of young workers and entrepreneurs." },
  ['Mari Berkolaborasi']: { EN: "Let's collaborate" },
  ['Mari Berkolaborasi bersama terampil']: { EN: "Let's collaborate together with skilled" },
  ['Mari Berkolaborasi dengan Terampil']: { EN: "Let's collaborate with skilled" },
  ['Cari tahu bagaimana Terampil dapat memberikan solusi pengembangan karir untuk perusahaan Anda.']: { EN: "Find out how skilled can provide career development solutions for your company." },
  ['Kolaborasi dengan']: { EN: "Collaboration with" },
  ['Pemimpin Industri dan Universitas Terbaik']: { EN: "The best industry and university leaders" },
  ['Kami Menjembatani Universitas dan Perusahaan']: { EN: "We bridge universities and companies" },
  ['Perusahaan Ternama']: { EN: "Well -known company" },
  ['Terampil mentransformasi kolaborasi menjadi pelatihan berkualitas yang disahkan oleh perusahaan ternama dan universitas terbaik.']: { EN: "Skilled transforming collaboration into quality training ratified by well -known companies and the best universities." },
  ['Perusahaan berkontribusi signifikan dalam mengembangkan kualitas pendidikan dan memiliki sumber ke manpower berkualitas.']: { EN: "The company contributes significantly in developing the quality of education and having a source to quality manpower." },
  ['Universitas']: { EN: "University" },
  ['Universitas dapat mengembangkan kurikulum pembelajaran berdasarkan kebutuhan industri dengan sumber yang valid, serta memiliki koneksi langsung untuk program internship.']: { EN: "Universities can develop learning curriculum based on industrial needs with valid sources, and have a direct connection for internship programs." },
  ['Data Anda akan dicek & diverifikasi terlebih dahulu oleh tim Terampil. Selanjutnya tim Terampil akan menghubungi Anda via phone dan SMS.']: { EN: "Your data will be checked & verified in advance by a skilled team. Then the skilled team will contact you via phone and SMS." },
  ['Mari Berkolaborasi Mewujudkan Indonesia Terampil']: { EN: "Let's collaborate to realize a skilled Indonesia" },
  ['Kirim email ke']: { EN: "Send an email to" },
  ['atau isi form di bawah ini untuk meminta demo secara langsung, kerja sama atau hal lainnya.']: { EN: "Or fill in the form below to ask for a direct demonstration, cooperation or other things." },
  ['Isi Data Anda']: { EN: "Fill in your data" },
  ['Nama PIC']: { EN: "PIC name" },
  ['Nama Wajib Di Isi']: { EN: "Mandatory names in filling" },
  ['Saya mewakili (perusahaan)']: { EN: "I represent (company)" },
  ['Saya mewakili']: { EN: "I represent" },
  ['Nama Perusahaan Wajib di Isi']: { EN: "Company name must be filling" },
  ['Email Wajib di Isi']: { EN: "Mandatory email in filling" },
  ['No HP tidak boleh kosong']: { EN: "No HP No Empty" },
  ['Hal yang ingin disampaikan']: { EN: "Things to be conveyed" },
  ['Pesan Wajib di Isi']: { EN: "Mandatory Message Filling" },
  ['Beli Per Training']: { EN: "Buy per training" },
  ['Intra-Active adalah metode yang dirancang untuk mendorong Terampil People lebih aktif dalam meningkatkan kapasitas dirinya, sedangkan Inter-Active adalah  metode yang memungkinkan Terampil People untuk berperan secara aktif berinteraksi langsung dengan Trainer dan dengan Terampil People lainnya di Forum Diskusi dan Live Q&A.']: { EN: "Intra-Active is a method designed to encourage people's skilled more active in increasing his capacity, while Inter-Active is a method that allows people to be skilled to actively interact directly with trainers and with other people skilled in discussion forums and live Q&A." },
  ['Setiap materi pelatihan dirancang berdasarkan nilai-nilai entrepreneurship sehingga siapapun yang belajar di Terampil akan mampu memotivasi dirinya sendiri, proaktif, dan berorientasi pada tindakan yang mengambil inisiatif untuk menunjang karir dan masa depan.']: { EN: "Each training material is designed based on entrepreneurship values ​​so that anyone who studies in skilled will be able to motivate themselves, proactively, and oriented actions that take initiatives to support career and future." },
  ['Setiap modul pelatihan di Terampil bukan teori, melainkan dapat langsung diterapkan secara efektif dan efisien di lingkup profesional ataupun bisnis.']: { EN: "Every training module in skilled is not theory, but can be directly applied effectively and efficiently in the scope of professional or business." },
  ['Akses Training Selamanya']: { EN: "Training access forever" },
  ['Kini untuk setiap pembelian modul pelatihan di Terampil, dapat diakses selamanya oleh user.']: { EN: "Now for every purchase of training modules in skilled, can be accessed forever by the user." },
  ['*syarat&ketentuan berlaku']: { EN: "*Terms & Conditions apply" },
  ['Terampil adalah Active Learning Platform yang menyediakan pelatihan-pelatihan yang bersifat on-demand dengan keunikan yang menjadikan Terampil berbeda dengan yang lain yaitu']: { EN: "Skillful is an active learning platform that provides on-demand training with uniqueness that makes skilled different from the others, namely" },
  ['Hybrid Learning: Intra-Active & Inter-Active, Intrapreneurship and Beyond, dan Practical and Proven Skills']: { EN: "Hybrid Learning: Intra-Active & Inter-Active, Intrapreneurship and Beyond, and Practical and Proven Skills" },
  ['Apa saja yang akan didapatkan?']: { EN: "What will you get?" },
  ['Seperti apa sistem di Terampil?']: { EN: "What is the system like in skilled?" },
  ['Kamu bisa mengikuti pelatihan di Terampil dengan cara melakukan pembelian Paket Bronze (1 Modul Training), Paket Silver (3 Modul Training) & Paket Gold (12 Modul Training).']: { EN: "You can attend training in skilled by purchasing a bronze package (1 training module), silver package (3 training module) & gold package (12 training modules)." },
  ['untuk Training ini']: { EN: "For this training" },
  ['Notifikasi']: { EN: "Notification" },
  ['Detail Halaman Notifikasi']: { EN: "Notification page details" },
  ['Tidak Ada Notifikasi']: { EN: "No notification" },
  ['Tidak ada notifikasi untuk kamu.']: { EN: "There is no notification for you." },
  ['Tandai semua dibaca']: { EN: "Mark everything read" },
  ['Pembayaran']: { EN: "Payment" },
  ['Pilih Metode Pembayaran']: { EN: "Select the payment method" },
  ['Kamu bisa lakukan pembayaran melalui ATM, Internet Banking & Mobile Banking.']: { EN: "You can make payments via ATM, Internet Banking & Mobile Banking." },
  ['Dompet Online']: { EN: "Online Wallet" },
  ['Pastikan kamu memiliki aplikasi dompet online yang dipilih untuk scan QR Code.']: { EN: "Make sure you have an online wallet application selected to scan QR Code." },
  ['Kartu Prakerja']: { EN: "Work Card" },
  ['Lengkapi data kartu kredit kamu sebelum klik Bayar.']: { EN: "Complete your credit card data before clicking pay." },
  ['Contoh: 1234567890123456']: { EN: "Example: 1234567890123456" },
  ['Tambah Kode Promo']: { EN: "Add promo code" },
  ['Gunakan']: { EN: "Use" },
  ['Kode Voucher Tidak Valid']: { EN: "Invalid voucher code" },
  ['Paket Subscription 1 Tahun']: { EN: "1 year subscription package" },
  ['Ganti']: { EN: "Change" },
  ['Kirim Ulang Kode']: { EN: "Re -send the code" },
  ['Masukkan Kode Verifikasi']: { EN: "Enter the verification code" },
  ['Masukkan kode yang dikirim melalui SMS ke nomor handphone kamu']: { EN: "Enter the code sent via SMS to your mobile number" },
  ['Code OTP Tidak Valid']: { EN: "Invalid OTP code" },
  ['Voucher tidak bisa digunakan']: { EN: "Vouchers Cannot Be Used" },
  ['Point tidak bisa digunakan']: { EN: "Points cannot be used" },
  ['Point tidak cukup']: { EN: "Point is not enough" },
  ['Tidak ada point']: { EN: "There is no point" },
  ['Ganti Plan']: { EN: "Change plan" },
  ['Cara Bayar']: { EN: "How to pay" },
  ['Aplikasi Gojek']: { EN: "Gojek application" },
  ['1. Buka aplikasi Gojek Anda']: { EN: "1. Open your Gojek application" },
  ['2. Pindai kode QR yang ada di monitor']: { EN: "2. Scan the QR code in the monitor" },
  ['3. Cek detail pembayaran di aplikasi, kemudian ketuk Bayar']: { EN: "3. Check the payment details in the application, then tap paying" },
  ['4. Masukkan PIN Anda']: { EN: "4. Enter your PIN" },
  ['5. Transaksi Anda selesai']: { EN: "5. Your transaction is complete" },
  ['Aplikasi Shopee']: { EN: "Shopee application" },
  ['1. Buka aplikasi Shopee Anda']: { EN: "1. Open your Shopee application" },
  ['Bayar Dengan']: { EN: "Pay with" },
  ['Kembali Ke Home']: { EN: "Back to home" },
  ['Lihat Riwayat Pembelian']: { EN: "See purchase history" },
  ['Batas Akhir Pembayaran']: { EN: "The deadline for payment" },
  ['Paket yang Dibeli']: { EN: "Package purchased" },
  ['Total Pembayaraan']: { EN: "Total payment" },
  ['Masukkan Nomor HP dari akun']: { EN: "Enter the cellphone number of the account" },
  ['Scan QR Code berikut dengan aplikasi']: { EN: "QR Code Scan the following with the application" },
  ['Cara Pembayaran']: { EN: "Payment method" },
  ['Pembayaran kamu lagi diproses. Silahkan tunggu konfirmasi email untuk menikmati konten kami.']: { EN: "Your payment is processed again. Please wait for the email confirmation to enjoy our content." },
  ['Kami tidak bisa memproses pembayaran kamu. Cek data kartu kamu dan coba lagi.']: { EN: "We cannot process your payment. Check your card data and try again." },
  ['Gagal']: { EN: "Fail" },
  ['Berhasil']: { EN: "Succeed" },
  ['Langganan']: { EN: "Subscriber" },
  ['1. Masukkan kartu ATM dan PIN']: { EN: "1. Enter the ATM and PIN card" },
  ['2. Pilih menu Transaksi Lainnya']: { EN: "2. Select another transaction menu" },
  ['3. Pilih Transfer']: { EN: "3. Select Transfer" },
  ['4. Pilih Transfer ke BCA Virtual Account']: { EN: "4. Select Transfer to BCA Virtual Account" },
  ['5. Masukkan Nomor Virtual Account (11 digit kode), lalu klik Benar']: { EN: "5. Enter the virtual account number (11 digit code), then click correctly" },
  ['6. Masukkan jumlah pembayaran dan klik Benar']: { EN: "6. Enter the amount of payment and click correctly" },
  ['7. Akan muncul konfirmasi pembayaran lalu klik Ya']: { EN: "7. Payment confirmation will appear then click yes" },
  ['8. Simpan struck sebagai bukti pembayaran Anda']: { EN: "8. Save Struck as proof of your payment" },
  ['1. Pilih menu Transfer']: { EN: "1. Select the transfer menu" },
  ['2. Pilih Transfer ke BCA Virtual Account']: { EN: "2. Select Transfer to BCA Virtual Account" },
  ['3. Masukkan Nomor Virtual Account atau pilih dari List Transfer, lalu klik Selanjutnya']: { EN: "3. Enter the virtual account number or select from the transfer list, then click Next" },
  ['4. Akan muncul konfirmasi pembayaran, jika sudah benar, klik Lanjutan']: { EN: "4. Payment confirmation will appear, if it is correct, click continued" },
  ['5. Anda akan mendapatkan BCA Token dan masukkan KEYBCA Response APPLI 1 dan klik Submit']: { EN: "5. You will get a BCA token and enter Keybca Response Appli 1 and click Submit" },
  ['6. Transaksi selesai, simpan struk sebagai bukti pembayaran Anda']: { EN: "6. Transaction Completed, save the receipt as proof of your payment" },
  ['1. Login ke aplikasi BCA Mobile App']: { EN: "1. Login to the BCA Mobile App Application" },
  ['2. Pilih m-BCA, kemudian masukkan PIN m-BCA']: { EN: "2. Select M-BCA, then enter the M-BCA pin" },
  ['3. Pilih m-Transfer, kemudian pilih BCA Virtual Account']: { EN: "3. Select M-transfer, then select BCA Virtual Account" },
  ['4. Masukkan Nomor Virtual Account atau pilih akun yang sudah ada di Daftar Transfer']: { EN: "4. Enter the virtual account number or select the account that is already on the transfer list" },
  ['5. Masukkan Nominal Pembayaran']: { EN: "5. Enter the nominal payment" },
  ['6. Masukkan PIN m-BCA']: { EN: "6. Enter M-BCA PIN" },
  ['7. Transaksi selesai, simpan struk sebagai bukti pembayaran Anda']: { EN: "7. Transaction Completed, Save the receipt as proof of your payment" },
  ['2. Di menu utama pilih Lainnya']: { EN: "2. On the main menu select other" },
  ['4. Pilih Saving Account']: { EN: "4. Select Saving Account" },
  ['5. Pilih ke BNI Account']: { EN: "5. Select to BNI Account" },
  ['6. Masukkan Nomor Payment Account, dan pilih Ya']: { EN: "6. Enter the payment account number, and select yes" },
  ['7. Masukkan jumlah pembayaran, jika jumlah yang dimasukkan tidak sama dengan jumlah invoice, maka transaksi akan ditolak']: { EN: "7. Enter the amount of payment, if the amount entered is not the same as the amount of the invoice, then the transaction will be rejected" },
  ['8. Akan muncul jumlah pmebayaran, nomor account dan nama merchant di halaman konfrimasi. Jika sudah benar, pilih Ya']: { EN: "8. There will be a number of payments, account numbers and merchant names on the confirmation page. If it's correct, choose yes" },
  ['9. Transaksi selesai, simpan struk sebagai bukti pembayaran Anda']: { EN: "9. Transaction Completed, Save the receipt as proof of your payment" },
  ['1. Pergi ke halaman']: { EN: "1. Go to the page" },
  ['dan kemudian klik Login']: { EN: "and then click Login" },
  ['2. Lanjut login dengan memasukkan User ID dan Password']: { EN: "2. Continue to log in by entering the User ID and Password" },
  ['3. Klik Transfer dan kemudian tambah Akun Favorit dan pilih Antar Rekening BNI']: { EN: "3. Click transfer and then add a favorite account and select between BNI accounts" },
  ['4. Masukkan nama akun, nomor akun, dan email dan kemudian klik Selanjutnya']: { EN: "4. Enter the account name, account number, and email and then click Next" },
  ['5. Masukkan Authentification Code dari token Anda dan klik Selanjutnya']: { EN: "5. Enter the authentification code from your token and click Next" },
  ['6. Kembali ke menu utama dan pilih Transfer dan kemudian Transfer Antar Rekening BNI']: { EN: "6. Return to the main menu and select transfer and then transfer between BNI accounts" },
  ['7. Pilih akun yang sudah Anda buat sebelumnya sebagai Rekening Tujuan dan klik Selanjutnya']: { EN: "7. Select the account that you created previously as the destination account and click next" },
  ['8. Periksa apakah detailnya sudah benar, jika sudah masukkan Authentification Code dan klik Selanjutnya dan Selesai']: { EN: "8. Check whether the details are correct, if you have entered the authentification code and click next and complete" },
  ['1. Buka BNI Mobile Banking dan Login']: { EN: "1. Open BNI Mobile Banking and Login" },
  ['2. Pilih menu Transfer']: { EN: "2. Select the transfer menu" },
  ['3. Pilih menu Virtual Account Billing']: { EN: "3. Select the Virtual Account Billing menu" },
  ['4. Pilih akun bank yang ingin digunakan']: { EN: "4. Select the bank account you want to use" },
  ['5. Masukkan 16 digit Nomor Virtual Account']: { EN: "5. Enter 16 digit virtual accounts numbers" },
  ['6. Informasi billing akan muncul di halaman validasi pembayaran']: { EN: "6. Billing information will appear on the payment validation page" },
  ['7. Jika informasinya sudah benar, masukkan password Anda dan proses pembayaran']: { EN: "7. If the information is correct, enter your password and the payment process" },
  ['8. Transaksi Anda akan diproses']: { EN: "8. Your transaction will be processed" },
  ['2. Pilih menu Bayar atau Beli']: { EN: "2. Select the Pay or Buy menu" },
  ['3. Pilih menu Lainnya, hingga menemukan menu Midtrans']: { EN: "3. Select another menu, to find the midtrans menu" },
  ['4. Masukkan kode Biller Terampil (70012), lalu pilih Benar']: { EN: "4. Enter the skilled biller code (70012), then select correctly" },
  ['5. Masukkan Nomor Virtual Account (11 digit kode), lalu pilih tombol Benar']: { EN: "5. Enter the virtual account number (11 digit code), then select the correct button" },
  ['6. Akan muncul konfirmasi pembayaran lalu pilih tombol Ya']: { EN: "6. Payment confirmation will appear then select the button yes" },
  ['7. Simpan struck sebagai bukti pembayaran Anda']: { EN: "7. Save the Struck as proof of your payment" },
  ['1. Login Mandiri Online dengan memasukkan Usernam dan Password']: { EN: "1. Mandiri online login by entering the usernam and password" },
  ['2. Pilih menu Pembayaran']: { EN: "2. Select the payment menu" },
  ['3. Pilih penyedia jasa Midtrans']: { EN: "3. Select the midtrans service provider" },
  ['4. Masukkan Nomor Virtual Account dan Nominal yang akan dibayarkan, lalu pilih Lanjut']: { EN: "4. Enter the virtual account number and nominal to be paid, then select further" },
  ['5. Setelah muncul tagihan, pilih Konfirmasi']: { EN: "5. After the bill appears, select confirmation" },
  ['6. Masukkan PIN/Challenge Code Token']: { EN: "6. Enter the PIN/Challenge Code Token" },
  ['7. Transaksi selesai, simpan bukti bayar Anda']: { EN: "7. Transaction Completed, save your proof of payment" },
  ['8. Simpan struk sebagai bukti pembayaran Anda']: { EN: "8. Save the receipt as proof of your payment" },
  ['1. Di menu utama, pilih Transaksi Lain']: { EN: "1. On the main menu, select another transaction" },
  ['2. Pilih Pembayaran']: { EN: "2. Select payment" },
  ['3. Pilih Pembayaran Lain']: { EN: "3. Select another payment" },
  ['4. Pilih Virtual Account']: { EN: "4. Select Virtual Account" },
  ['5. Masukkan 16 digit nomor account dan klik Benar']: { EN: "5. Enter 16 digit accounts and click correctly" },
  ['6. Muncul jumlah pmebayaran, nomor account dan nama merchant di halaman konfrimasi. Jika sudah benar, klik Benar']: { EN: "6. The number of payments, account numbers and merchant names emerged on the confirmation page. If it's correct, click correctly" },
  ['7. Pilih akun pembayaran Anda dan klik Benar']: { EN: "7. Select your payment account and click correctly" },
  ['Training Saya']: { EN: "My training" },
  ['Yuk selesaikan training yang sudah kamu mulai. Semangat!']: { EN: "Let's finish the training that you have started. Spirit!" },
  ['Ayo mulai belajar dengan memilih training yang kamu inginkan untuk menjadi Terampil']: { EN: "Let's start learning by choosing the training you want to be skilled" },
  ['Sertifikat']: { EN: "Certificate" },
  ['Belum Ada Training yang Diikuti']: { EN: "No training has been followed" },
  ['Silahkan pilih Paket Training dan mulai untuk menonton ribuan Video Training dari profesional']: { EN: "Please select the training package and start to watch thousands of video training from professionals" },
  ['Lihat Training']: { EN: "See training" },
  ['Belum Ada Paket Training Ini']: { EN: "There is no this training package" },
  ['Silakan beli paket training To-Be Entrepreneurship untuk belajar langkah-langkah membangun bisnis dari nol.']: { EN: "Please buy a training to-be entrepreneurship package to learn the steps to build a business from scratch." },
  ['Silakan beli paket training Entrepreneurship untuk  mempelajari strategi-strategi membangun bisnis yang profitable']: { EN: "Please buy an entrepreneurship training package to study the strategies of building a profitable business" },
  ['Hasil Pencarian']: { EN: "Search result" },
  ['Urutkan']: { EN: "Sort" },
  ['Pilih Paket']: { EN: "Choose a package" },
  ['Cari Judul Training']: { EN: "Find Training Title" },
  ['Video Terbuka']: { EN: "Open video" },
  ['Free Trial Habis']: { EN: "Free trial runs out" },
  ['Kamu Belum Bisa Tonton Training Ini']: { EN: "You can't watch this training" },
  ['Kamu harus menyelesaikan training sebelumnya untuk lanjut menonton training ini.']: { EN: "You have to finish the training beforehand to continue watching this training." },
  ['Tutup']: { EN: "Closed" },
  ['Training berhasil dibookmark']: { EN: "Training was successfully dibookmarking" },
  ['Training telah di unbookmark']: { EN: "Training has been at Unbookmark" },
  ['penilaian']: { EN: "appraisal" },
  ['Bagikan Training Ini']: { EN: "Share this training" },
  ['Belum Ada Sertifikat']: { EN: "There is no certificate" },
  ['Silahkan ikuti dan selesaikan training kamu untuk mendapatkan sertifikat']: { EN: "Please follow and finish your training to get a certificate" },
  ['Nilai']: { EN: "Mark" },
  ['Tanggal Dikeluarkan']: { EN: "Date issued" },
  ['Unduh Sertifikat']: { EN: "Download the certificate" },
  ['Download & Nonton Offline Kapanpun Dimanapun']: { EN: "Download & watch offline anytime" },
  ['Nikmati nonton pelatihan tanpa internet di Smartphone kamu!']: { EN: "Enjoy watching training without internet on your smartphone!" },
  ['Mulai Berlangganan']: { EN: "Start subscribed" },
  ['Dapatkan full akses ribuan training untuk meningkatkan kualitas diri Kamu di Terampil.']: { EN: "Get full access of thousands of training to improve the quality of yourself in skilled." },
  ['Pilih Paket Training']: { EN: "Select the Training Package" },
  ['Mulai Training']: { EN: "Start training" },
  ['Beli Training Ini']: { EN: "Buy this training" },
  ['Tambah Paket']: { EN: "Add package" },
  ['Daftar Prakerja']: { EN: "List of Prakja" },
  ['Daftar Prakerja di terampil sekarang juga!']: { EN: "List of Prakja Work in Skill now!" },
  ['Training Populer Prakerja']: { EN: "Popular Prakerja Training" },
  ['Apa itu Kartu Prakerja?']: { EN: "What is a Prakja Work Card?" },
  ['Kartu Prakerja adalah program pengembangan kompetensi berupa bantuan biaya yang ditujukan untuk WNI usia 18 tahun ke atas']: { EN: "The Praking Card is a competency development program in the form of cost assistance aimed at Indonesian citizens aged 18 years and over" },
  ['Bantuan yang didapat berupa subsidi non-tunai sebesar Rp 1 Juta untuk mengikuti Online Training di Terampil']: { EN: "The assistance obtained in the form of non-cash subsidies of Rp. 1 million to take part in online training in skilled" },
  ['Merespon dampak COVID-19, Kartu Prakerja akan diprioritaskan bagi pekerja maupun pelaku usaha mikro/kecil yang terdampak penghidupannya']: { EN: "Responding to the impact of the Covid-19, the Praking Card will be prioritized for workers and micro/small businesses affected by their livelihoods" },
  ['Pemegang Kartu Prakerja juga akan mendapatkan insentif langsung sebesar Rp 600 ribu / bulan selama 4 bulan setelah menyelesaikan Online Training pertama dan tambahan Rp 150 ribu setelah mengisi survei di www.prakerja.go.id']: { EN: "The PraKer Work Card holder will also get a direct incentive of Rp 600 thousand / month for 4 months after completing the first online training and an additional Rp 150 thousand after filling out the survey at www.prjaja.go.id" },
  ['Cara Mendapatkan Kode Voucher di Tokopedia & MauBelajarApa untuk Mengikuti Online Training Gratis di Terampil']: { EN: "How to get a voucher code at Tokopedia & Maubelajarapa to take free online training in skilled" },
  ['4 Langkah #JadiTerampil dengan Kartu Prakerja']: { EN: "4 Steps #Jaditerrampil with Prakja Work Card" },
  ['Dapatkan Kode Voucher Training Terampil di Maubelajarapa.com dan Tokopedia']: { EN: "Get a skilled training voucher code at Maubelajarapa.com and Tokopedia" },
  ['Pilih online training yang sesuai dengan pilihan kamu']: { EN: "Select online training that suits your choice" },
  ['Selesaikan training dan dapatkan insentif senilai 2,55 juta dari Kartu Prakerja']: { EN: "Complete the training and get an incentive worth 2.55 million from the Prakja Work Card" },
  ['Mengapa Terampil merupakan pilihan terbaik?']: { EN: "Why is skilled is the best choice?" },
  ['Materi Berkualitas']: { EN: "Quality material" },
  ['Video training berkualitas tinggi dan dilengkapi dengan berbagai animasi, gambar dan video ilustrasi']: { EN: "High quality video training and equipped with various animations, images and video illustrations" },
  ['Trainer Berpengalaman']: { EN: "Experienced trainer" },
  ['Materi disusun dan dibawakan oleh praktisi berdasarkan pengalaman dan kesuksesannya']: { EN: "The material is compiled and delivered by practitioners based on their experiences and success" },
  ['Bersertifikat']: { EN: "Certified" },
  ['Anda akan mendapatkan Sertifikat Kelulusan Training yang bisa meningkatkan Value Anda ketika melamar pekerjaan']: { EN: "You will get a training certificate that can increase your value when applying for a job" },
  ['Preferensi Topik']: { EN: "Topic preference" },
  ['Atur preferensi pilihan training yang ingin kamu ambil.']: { EN: "Adjust the preference for the training of training you want to take." },
  ['Rekomendasi Training untuk Kamu ']: { EN: "Training Recommendation for You" },
  ['Kami akan membantu kamu menemukan training sesuai dengan minat kamu']: { EN: "We will help you find training according to your interests" },
  ['Pilih profesi']: { EN: "Choose a profession" },
  ['Pilih minat kamu! (maksimal 3)']: { EN: "Choose your interests! (maximum 3)" },
  ['Detail Trainer']: { EN: "Trainer details" },
  ['6 Langkah Praktis Membuat Strategi Marketing yang Mendatangkan Banyak Customer ke Bisnis Anda']: { EN: "6 Practical Steps to Make Marketing Strategies that bring a lot of customers to your business" },
  ['Selamat Datang Di Terampil']: { EN: "Welcome to Skilled" },
  ['Apakah kamu punya Referral Code?']: { EN: "Do you have a referral code?" },
  ['Masukkan referral code dari temanmu untuk mendapatkan poin.']: { EN: "Enter the referral code from your friend to get points." },
  ['HADIAH SPESIAL dari Terampil No. 1 App']: { EN: "Special prizes from skilled No. 1 app" },
  ['Gratis 5 Video Training']: { EN: "Free 5 Video Training" },
  ['Download aplikasi Terampil sekarang']: { EN: "Download skilled application now" },
  ['Daftar & pakai kode khusus']: { EN: "List & Use Special Code" },
  ['Kebijakan Refund']: { EN: "Refund policy" },
  ['Registrasi Assessment']: { EN: "Assessment registration" },
  ['Daftar & Tes Sekarang']: { EN: "List & Test Now" },
  ['Lengkapi data Kamu terlebih dahulu sebelum ikuti tes.']: { EN: "Complete your data first before taking the test." },
  ['Email Wajib diisi']: { EN: "Email must be filled in" },
  ['Contoh 0812345679']: { EN: "Example 0812345679" },
  ['Nomor Hp wajib diisi']: { EN: "Mobile phone number must be filled" },
  ['Format Nomor HP Belum Sesuai']: { EN: "The format of the cellphone number is not appropriate" },
  ['Kata Sandi Wajib Diisi']: { EN: "Password Must Fill" },
  ['Asal Institusi']: { EN: "Origin of the institution" },
  ['Pilih Instusi']: { EN: "Select Institutions" },
  ['Insitute Wajib Disi']: { EN: "Mandatory Incitute" },
  ['Mohon Tunggu...']: { EN: "Please wait..." },
  ['Registrasi']: { EN: "Registration" },
  ['Registrasi di terampil']: { EN: "Registration in skilled" },
  ['Daftar Menggunakan No HP']: { EN: "Register using cellphone number" },
  ['Daftar Menggunakan Google']: { EN: "Register using Google" },
  ['Daftar Menggunakan Email']: { EN: "Register using email" },
  ['Dengan mendaftar, Anda menyetujui']: { EN: "By registering, you agree" },
  ['Ketentuan Penggunaan']: { EN: "Terms of Use" },
  ['Sudah punya akun?']: { EN: "Already have an account?" },
  ['Reset Kata Sandi Berhasil']: { EN: "Reset Password Success" },
  ['Silahkan buat Kata Sandi baru untuk masuk ke Terampil']: { EN: "Please create a new password to enter skilled" },
  ['Buat Kata Sandi Baru']: { EN: "Create a new password" },
  ['Kata Sandi Baru']: { EN: "New password" },
  ['Buat Kata Sandi Baru Berhasil']: { EN: "Make a new password successful" },
  ['Silahkan klik tombol masuk untuk masuk ke Terampil menggunakan kata sandi baru']: { EN: "Please click the Entry button to enter skilled using a new password" },
  ['Training Selesai']: { EN: "The training is complete" },
  ['Tes Akhir']: { EN: "Final test" },
  ['Selamat! sudah menyelesaikan training']: { EN: "Happy! has completed training" },
  ['Kamu berhasil menjawab']: { EN: "You managed to answer" },
  ['pertanyaan dengan benar']: { EN: "Questions correctly" },
  ['Silahkan lihat ringkasan Hasil Training dan download Sertifikat Kompetensi Lulusan di tab Hasil Training atau di Tab Sertifikat di Training Saya.']: { EN: "Please see the summary of the results of training and download the graduate competency certificate in the training tab or in the certificate tab in my training." },
  ['Download Sertifikat']: { EN: "Download certificate" },
  ['Lihat Hasil Training']: { EN: "See the results of training" },
  ['Nilai tes akhir kamu kurang dari 60 :(']: { EN: "Your final test scores are less than 60 :(" },
  ['Kamu harus mendapatkan nilai Tes Akhir minimum 60 untuk bisa download Sertifikat Komptensi Lulusan. Tetapi kamu sudah berhak mendapatkan Sertifikat Penyelesaian karena sudah menyelesaikan training di Terampil. Klik retake untuk ikuti Tes Akhir lagi untuk bisa download Sertifikat Kompetensi Lulusan. Semangat!']: { EN: "You have to get a minimum final test score of 60 to be able to download graduate competency certificates. But you have the right to get a settlement certificate for completing training in skilled. Click Case to take the final test again to be able to download graduate competency certificates. Spirit!" },
  ['Retake']: { EN: "Cracked" },
  ['Kamu sudah pernah mengikuti']: { EN: "You've followed" },
  ['Kuis']: { EN: "Quiz" },
  ['Test Akhir']: { EN: "Final test" },
  ['sebelumnya']: { EN: "previously" },
  ['yang diambil adalah nilai']: { EN: "what is taken is the value" },
  ['terakhir']: { EN: "final" },
  ['Kamu sudah lulus tes akhir dan mendapatkan sertifikat di training ini. Jika kamu mengikuti tes akhir lagi, maka nilai yang diambil adalah nilai terakhir.']: { EN: "You have passed the final test and get a certificate at this training. If you take the final test again, then the value taken is the last value." },
  ['Ya']: { EN: "Yes" },
  ['Tidak']: { EN: "No" },
  ['Kamu Sudah Tidak Bisa Retake']: { EN: "You can't crack" },
  ['Kamu sudah tidak lulus sebanyak 5 kali, sehingga kamu tidak bisa mendapatkan sertifikat. Tapi kamu masih tetap bisa melanjutkan menonton training selanjutnya dengan klik Training Selanjutnya.']: { EN: "You haven't passed 5 times, so you can't get a certificate. But you can still continue watching the next training by clicking the next training." },
  ['Nilai Kamu Sudah Lulus']: { EN: "Your grades have graduated" },
  ['Nilai Kamu sudah memenuhi standar']: { EN: "Your value meets the standards" },
  ['Lanjutkan Training']: { EN: "Continue training" },
  ['Nonton Training Lainnya']: { EN: "Watch other training" },
  ['Profil Settings']: { EN: "Settings profile" },
  ['Lihat Profil']: { EN: "View profile" },
  ['Pengaturan']: { EN: "Arrangement" },
  ['Edit Profil']: { EN: "Edit Profile" },
  ['Atur Kata Sandi']: { EN: "Set the password" },
  ['Dapatkan sekarang']: { EN: "Get it now" },
  ['Hai, Sob! Ada platform training bagus nih buat upgrade karir kamu!']: { EN: "Hi, friend! There is a good training platform for upgrading your career!" },
  ['Kumpulkan poin dan dapatkan training gratis! Pakai kode referal ini ya']: { EN: "Collect points and get free training! Use this referral code" },
  ['Undang Teman Dapat Training Gratis']: { EN: "Invite friends to get free training" },
  ['Ajak 1 teman kamu untuk beli salah satu paket, dan dapatkan']: { EN: "Invite 1 of your friends to buy one of the packages, and get" },
  ['1 paket yang sama secara gratis!']: { EN: "1 Package is the same for free!" },
  ['Undang Teman Kamu']: { EN: "Invite your friends" },
  ['Hai, Terampil people! Ada platform training bagus nih buat upgrade karir dan bisnis kamu!']: { EN: "Hi, skilled people! There is a good training platform for upgrading your career and business!" },
  ['Dapatkan 1 video training gratis dan penawaran spesial! Daftar sekarang melalui link ini:']: { EN: "Get 1 Free Video Training and Special Offers! Register now through this link:" },
  ['Share link kamu']: { EN: "Share your link" },
  ['Berhasil salin link referral']: { EN: "Successfully copy the referral link" },
  ['Salin']: { EN: "Copy" },
  ['Atau Undang Via']: { EN: "Or invite via" },
  ['Bagikan']: { EN: "Share" },
  ['Ganti Langganan']: { EN: "Change subscription" },
  ['Pilih Langganan']: { EN: "Select subscription" },
  ['Paket Langganan']: { EN: "Subscription package" },
  ['Dapatkan full akses ribuan Training untuk meningkatkan kualitas diri Anda di Terampil']: { EN: "Get full access of thousands of training to improve the quality of yourself in skilled" },
  ['Pembayaran khusus pemegang Kartu Prakerja']: { EN: "Special Payment of Prakja Work Card Holders" },
  ['bulan']: { EN: "month" },
  ['Batal Langganan']: { EN: "Cancel subscription" },
  ['Pembatalan langganan akun kamu berhasil. Sekarang akun kamu sudah downgrade menjadi free.']: { EN: "Cancellation of your account subscription success. Now your account is downgrade to be free." },
  ['Kamu Yakin Mau Batal Berlangganan?']: { EN: "Are you sure you want to cancel subscription?" },
  ['Jika kamu membatalkan langgananmu hari ini, akun kamu akan downgrade menjadi free. Kamu akan kehilangan hal-hal berikut:']: { EN: "If you cancel your subscription today, your account will downgrade become free. You will lose the following things:" },
  ['Kebebasan dalam mengakses video']: { EN: "Freedom in accessing videos" },
  ['Kesempatan untuk meningkatkan skill']: { EN: "Opportunity to improve skills" },
  ['Mengerjakan kuis dan tes akhir']: { EN: "Working on quizzes and final tests" },
  ['Diskusi materi dengan Trainer dan pengguna lain']: { EN: "Discussion of material with trainers and other users" },
  ['Download sertifikat']: { EN: "Download certificate" },
  ['Kamu Yakin Upgrade Langganan?']: { EN: "Are you sure the subscription upgrade?" },
  ['Jika kamu upgrade langgananmu ke']: { EN: "If you upgrad your subscription to" },
  ['dapatkan berbagai keuntungan']: { EN: "Get various benefits" },
  ['Kuota training kamu menjadi']: { EN: "Your training quota becomes" },
  ['Masa berlangganan kamu selanjutnya menjadi']: { EN: "Your subsequent subscription period becomes" },
  ['bulan, dan berakhir pada']: { EN: "moon, and ends on" },
  ['Kamu Yakin Downgrade Langganan?']: { EN: "Are you sure the subscription downgrade?" },
  ['Kamu akan kehilangan berbagai keuntungan jika downgrade langganan:']: { EN: "You will lose various advantages if the subscription downgrade:" },
  ['Jika kamu upgrade langgananmu ke Yearly, dapatkan berbagai keuntungan:']: { EN: "If you upgrad your subscription to Yearly, get various advantages:" },
  ['Batal Langganan Berhasil']: { EN: "Cancel successful subscription" },
  ['Syarat dan Ketentuan']: { EN: "Terms and Conditions" },
  ['Tentang Kami']: { EN: "About Us" },
  ['Membangun Generasi Terampil yang siap bersaing secara Global']: { EN: "Building a Skilled Generation who is ready to compete globally" },
  ['Terampil menciptakan SDM yang Terampil dan Unggul yang siap masuk, bersaing dan bertumbuh di dalam dunia bisnis dan profesional.']: { EN: "Skillful creates skilled and superior human resources who are ready to enter, compete and grow in the business world and professional." },
  ['Platform Pelatihan & Ekosistem Bisnis untuk UMKM']: { EN: "Training Platform & Business Ecosystem for MSMEs" },
  ['Kami membantu pelaku UMKM dan pekerjanya meningkatkan pengetahuan dan keterampilan dengan menghubungkan mereka kepada trainer, mentor, dan ahli berpengalaman serta menyediakan ekosistem bisnis terbaik untuk memajukan usaha mereka.']: { EN: "We help MSMEs and their workers increase knowledge and skills by connecting them to trainers, mentors, and experienced experts and provide the best business ecosystems to advance their business." },
  ['Terampil adalah platform pembelajaran aktif yang menyediakan berbagai pelatihan on-demand yang disusun berdasarkan kebutuhan dan nilai-nilai kewirausahaan yang dikemas dalam modul praktis dan diajarkan oleh trainer terbaik.']: { EN: "Skillful is an active learning platform that provides a variety of on-demand training compiled based on the needs and entrepreneurial values ​​that are packaged in practical modules and taught by the best trainers." },
  ['Misi Terampil']: { EN: "Skilled mission" },
  ['Menyediakan pelatihan berkualitas yang diajarkan oleh praktisi dan ahli berpengalaman berdasarkan kebutuhan dunia industri dan dunia usaha.']: { EN: "Providing quality training taught by practitioners and experienced experts based on the needs of the industrial world and the business world." },
  ['Amrullah adalah seorang psikolog industri & organisasi dengan pengalaman lebih dari sembilan tahun di People Development. Sebelum di Terampil, beliau bekerja di Perusahaan Jepang sebagai People Development Coordinator dan di Zethra Consulting sebagai Co-founder & Consultant. Di Zethra, beliau berhasil menangani beberapa perusahaan multinasional dan mengalahkan beberapa perusahaan konsultan internasional di beberapa HR Project.']: { EN: "Amrullah is an industrial & organization psychologist with more than nine years of experience at People Development. Before being skilled, he worked in a Japanese company as a People Development Coordinator and at Zethra Consulting as Co-Founder & Consultant. In Zethra, he managed to handle several multinational companies and defeated several international consulting companies in several HR Projects." },
  ['Alloysius adalah salah satu pembuat video hebat di Indonesia. Pengalamannya menakjubkan dari Thailand, Singapura, dan Indonesia. Sebelum di Terampil, beliau bekerja di Traveloka Group dan membuat beberapa iklan video yang bagus. Di Terampil, beliau berhasil meningkatkan kualitas video secara signifikan dan pengalaman menonton yang luar biasa.']: { EN: "Allloysius is one of the great video makers in Indonesia. His experience was amazing from Thailand, Singapore and Indonesia. Before being skilled, he worked at Traveloka Group and made some good video ads. In skilled, he managed to significantly improve video quality and extraordinary watching experience." },
  ['Telah berkecimpung dalam bidang properti, manufaktur dan IT selama lebih dari satu dekade. Davy Makimian adalah Chief Executive Officer dari Alternative Media Group. Beliau memperoleh gelar Sarjana dari Bond University Australia dan mengejar gelar Magister Manajemen dan Hubungan Internasional.']: { EN: "Has been involved in property, manufacturing and IT for more than a decade. Davy Makimian is a Chief Executive Officer from Alternative Media Group. He obtained a bachelor's degree from Bond University Australia and pursued a Master of Management and International Relations." },
  ['Coach Yohanes G. Pauly dikenal sebagai Global Awards Winning Business Coach, mencapai peringkat No. 1 Business Coach Terbaik di Tingkat Dunia dalam daftar prestisius Top 100 Business Coach in the World. Founder dari GRATYO®️ Group yang salah satu bisnisnya di bidang edukasi melatih para Entrepreneur & calon Pebisnis bangun Bisnis yang Profitable & AUTO-PILOT. Coach YGP juga Co-Founder TERAMPIL Edutech dan SELERA KAPITAL Brands. Merupakan investor di Digital Start-Up Companies seperti YummyCorp, Wahyoo, Attention Holding Pte. Ltd., dan start-up lainnya. FUN Facts: Coach YGP juga seorang National Best Selling Author dari buku bisnis “Business is FUN!” dan pemecah rekor dunia dari Guinness World Records.']: { EN: "Coach Yohanes G. Pauly is known as the Winning Business Coach Global Awards, reaching ranking No. 1 Best Business Coach on the World Level in the Top 100 Business Coach in the World List. The founder of GRATYO®️ Group, which is one of its businesses in the field of education, trains entrepreneurs & prospective business people who build profitable & auto-pilot business. Coach YangP is also a skilled co-founder EduTech and Capital Brands taste. Is an investor in digital start-up compans such as Yummycorp, Wahyoo, Attention Holding Pte. Ltd., and other start-ups. Fun Facts: Coach YangP is also a National Best Selling Author from the business book \"Business Is Fun!\" and the world record breaker from Guinness World Records." },
  ['Bapak Thomas adalah seorang bankir dengan pengalaman operasional yang signifikan mulai dari pemrosesan pinjaman back office hingga pemasaran front office & manajemen aset dengan Mizuho Corporate Bank, Ltd di New York. Saat ini, beliau menjabat sebagai CEO PT Tigaka Distrindo Perkasa dan PT Kirana Jaya Lestari, distributor terkemuka produk farmasi dan alat kesehatan dengan omset penjualan tahunan 1 T IDR. Beliau lulus dari program MBA University of Chicago Booth School of Business dari New York University.']: { EN: "Mr. Thomas is a banker with significant operational experience ranging from processing back office loans to marketing front office & asset management with Mizuho Corporate Bank, Ltd. New York. At present, he serves as CEO of PT Tigaka District Perkasa and PT Kirana Jaya Lestari, a leading distributor of pharmaceutical products and medical devices with an annual sales turnover of 1 T IDR. He graduated from the MBA University of Chicago Booth School of Business from New York University." },
  ['Mr. Roy adalah seorang veteran IT di Indonesia. Beliau memiliki pengalaman lebih dari 20 tahun dalam pengembangan IT seperti ERP, MRP, Smart Card dan masih banyak lagi. Perjalanan proyek IT-nya dari Indonesia, Hongkong, hingga Afrika Barat. Ia lulus Program Doktor dari Institut Teknologi Bandung dan saat ini fokus pada beberapa bisnis dan startup.']: { EN: "Mr. Roy is an IT veteran in Indonesia. He has more than 20 years of experience in the development of IT such as ERP, MRP, Smart Card and many more. The IT project journey from Indonesia, Hong Kong, to West Africa. He passed the doctoral program from the Bandung Institute of Technology and is currently focused on several businesses and startups." },
  ['Diliput Oleh']: { EN: "Covered by" },
  ['Diajarkan Langsung oleh Pakar Profesional']: { EN: "Taught directly by professional experts" },
  ['Keseruan di Terampil']: { EN: "Excitement in skilled" },
  ['Terpilih sebagai 100 startup terbaik di antara 130.000 Startup dari 150 negara.']: { EN: "Elected as the 100 best startups among 130,000 startups from 150 countries." },
  ['Berpartisipasi sebagai salah satu dari 50 finalis startup pendidikan terbaik di antara ratusan peserta di Asia Pasifik']: { EN: "Participate as one of the 50 best education startup finalists among hundreds of participants in the Asia Pacific" },
  ['Penghargaan dari Acara Training Dunia']: { EN: "Awards from world training events" },
  ['Semua Trainer']: { EN: "All trainers" },
  ['Semua Training']: { EN: "All training" },
  ['Kategori']: { EN: "Category" },
  ['Terapkan']: { EN: "Apply" },
  ['Waktu Telah Habis']: { EN: "Time has run out" },
  ['Selamat! sudah menyelesaikan Pretes']: { EN: "Happy! have completed the pretest" },
  ['*Kamu bisa ambil ulang pretes ini dengan klik button Retake di bawah atau klik button Lanjut Materi untuk ke materi berikutnya.']: { EN: "*You can re -take this pretest by clicking the cracked button below or click the further the material to the next material." },
  ['Lanjut Materi']: { EN: "Continued material" },
  ['Selamat! sudah menyelesaikan kuis']: { EN: "Happy! already completed the quiz" },
  ['*Kamu bisa ambil ulang kuis ini dengan klik button Retake di bawah atau klik button Lanjut Materi untuk ke materi berikutnya.']: { EN: "*You can re -take this quiz by clicking the cracked button below or click the further the material to the next material." },
  ['Nilai kuis kamu kurang dari 80 :(']: { EN: "Your quiz value is less than 80 :(" },
  ['Kamu harus mendapatkan nilai kuis minimum 80 untuk lanjut ke materi selanjutnya. Klik retake untuk ikuti kuis lagi. Semangat!']: { EN: "You have to get a minimum quiz value of 80 to continue to the next material. Click cracked to follow the quiz again. Spirit!" },
  ['Kembali ke Materi']: { EN: "Back to the material" },
  ['Waktu Kamu Habis']: { EN: "When you run out" },
  ['Waktu kamu habis untuk menjawab pertanyaan ini. Klik selanjutnya untuk lanjut ke pertanyaan berikutnya.']: { EN: "When you run out to answer this question. Click the next to continue to the next question." },
  ['Ikuti Ulang Training Berhasil']: { EN: "Follow the training successfully" },
  ['Selamat belajar dan menikmati Training dari Terampil.']: { EN: "Happy learning and enjoying training from skilled." },
  ['Kamu Belum Bisa Ambil Tes Akhir']: { EN: "You can't take the final test" },
  ['Kamu harus menyelesaikan semua video materi dan kuis sebelum mengikuti tes akhir.']: { EN: "You have to complete all video material and quizzes before taking the final test." },
  ['Oke']: { EN: "Okay" },
  ['Ulasan']: { EN: "Review" },
  ['Diskusi']: { EN: "Discussion" },
  ['Catatan']: { EN: "Notes" },
  ['Hasil Training']: { EN: "Training results" },
  ['Kode Voucher Salah']: { EN: "The voucher code is wrong" },
  ['Kode Voucher Sudah digunakan']: { EN: "The voucher code has been used" },
  ['Catatan Berhasil Ditambah']: { EN: "Note successfully added" },
  ['Yakin Mengikuti']: { EN: "Sure to follow" },
  ['Waktu:']: { EN: "Time:" },
  ['Tidak ada nilai minimum dalam pretes, pretes berfungsi untuk melihat kemampuan kamu sebelum memulai training.']: { EN: "There is no minimum value in pretest, pretest serves to see your abilities before starting training." },
  ['Kamu harus menjawab semua soal pretes sebelum memulai training.']: { EN: "You have to answer all the pretest questions before starting training." },
  ['Kamu harus mendapatkan nilai minimum']: { EN: "You have to get a minimum value" },
  ['60 untuk bisa download sertifikat']: { EN: "60 to be able to download certificates" },
  ['80 untuk lanjut ke materi berikutnya.']: { EN: "80 to continue to the next material." },
  ['Jika nilai kamu kurang dari']: { EN: "If your value is less than" },
  ['maka kamu bisa mengikuti']: { EN: "then you can follow" },
  ['lagi sampai kamu memperoleh nilai minimum']: { EN: "again until you get a minimum value" },
  ['terdiri dari']: { EN: "consist of" },
  ['soal.']: { EN: "question." },
  ['Setiap soal diberikan waktu pengerjaan selama 1 menit.']: { EN: "Each question is given the work time for 1 minute." },
  ['Jika waktu kamu habis sebelum memilih jawaban, maka pertanyaan tersebut dianggap salah.']: { EN: "If your time runs out before choosing an answer, then the question is considered wrong." },
  ['Jawab pertanyaan dengan hati-hati.']: { EN: "Answer the question carefully." },
  ['Good luck!']: { EN: "Good luck!" },
  ['Ikuti Training Berhasil']: { EN: "Follow the training successful" },
  ['Materi']: { EN: "Material" },
  ['Ikuti Training Ini']: { EN: "Follow this training" },
  ['Ikuti Training']: { EN: "Follow the training" },
  ['Tentang Trainer']: { EN: "About Trainer" },
  ['Unduh Dokumen']: { EN: "Download documents" },
  ['Sertifikat Pelatihan']: { EN: "Training certificate" },
  ['Deskripsi Training']: { EN: "Description of Training" },
  ['Tanggal Rilis']: { EN: "Release date" },
  ['Testimoni dari Profesional']: { EN: "Testimonials from professionals" },
  ['Ikuti Training ini']: { EN: "Follow this training" },
  ['Dapat diakses Selamanya']: { EN: "Can be accessed forever" },
  ['Setifikat Pelatihan']: { EN: "Training setform" },
  ['Download & Nonton Offline di Smartphone']: { EN: "Download & watch offline on a smartphone" },
  ['Akses training melalui Desktop & Smartphone']: { EN: "Access Training via Desktop & Smartphone" },
  ['Dapat diakses Selama 1 Tahun']: { EN: "Can be accessed for 1 year" },
  ['Lebih']: { EN: "More" },
  ['Hemat']: { EN: "Economical" },
  ['Tambah Catatan']: { EN: "Add notes" },
  ['Tambah']: { EN: "Plus" },
  ['Tambah Catatan Baru']: { EN: "Add new notes" },
  ['Daftar Materi Training']: { EN: "List of training material" },
  ['BAB']: { EN: "CHAPTER" },
  ['Kamu Yakin Tidak Melanjutkan Test Akhir?']: { EN: "Are you sure you don't continue the final test?" },
  ['Kamu harus menyelesaikan semua Test Akhir di Training untuk bisa lanjut ke Tes Akhir dan mendapatkan Sertifikat.']: { EN: "You have to complete all the final tests on training to be able to continue to the final test and get a certificate." },
  ['Kamu harus menyelesaikan semua video pada materi sebelumnya untuk lanjut ke materi ini.']: { EN: "You have to complete all the videos on the previous material to continue to this material." },
  ['Kamu mempunyai']: { EN: "You have" },
  ['kuota']: { EN: "quota" },
  ['Kuota']: { EN: "Quota" },
  ['tersisa untuk mengikuti training. Kamu bisa menambah kuota training kamu untuk ikuti training lebih banyak.']: { EN: "remaining to follow the training. You can increase your training quota to follow more training." },
  ['Kamu memiliki akses Free Trial sampai']: { EN: "You have free trial access" },
  ['Silakan klik salah satu video yang ingin Kamu tonton atau langsung beli training ini dengan klik button Detail Paket di bawah.']: { EN: "Please click on one of the videos you want to watch or directly buy this training by clicking the Package Detail Button below." },
  ['Silakan beli paket training terlebih dahulu untuk ikuti training dan menambah skill kamu.']: { EN: "Please buy a training package in advance to follow the training and add your skills." },
  ['Detail Paket']: { EN: "Package details" },
  ['Cari Video Training']: { EN: "Find Video Training" },
  ['Ya, ikuti Training dengan']: { EN: "Yes, follow the training with" },
  ['Kamu Yakin Mengikuti Training Ini?']: { EN: "Are you sure to follow this training?" },
  ['Kamu punya']: { EN: "You have" },
  ['Video Training Gratis']: { EN: "Free training video" },
  ['Ingin Nonton Video Ini ?']: { EN: "Want to watch this video?" },
  ['Kamu bisa menonton video training ini dengan klaim kode kamu atau membagikan link kamu ke 3 orang teman.']: { EN: "You can watch this video training with your code claim or share your link with 3 friends." },
  ['Gunakan Kode']: { EN: "Use the code" },
  ['Masukkan Kode']: { EN: "Enter the code" },
  ['Bagikan Link']: { EN: "Share the link" },
  ['Link url berhasil disalin']: { EN: "URL link successfully copied" },
  ['Ingin lihat video ini?']: { EN: "Want to see this video?" },
  ['Kamu memiliki akses']: { EN: "You have access" },
  ['Lihat Ulasan Lainnya']: { EN: "See other reviews" },
  ['Beri ulasan untuk modul berhasil']: { EN: "Give a review for a successful module" },
  ['Beri ulasan untuk trainer berhasil']: { EN: "Give a review for a successful trainer" },
  ['Ulasan untuk modul berhasil diperbarui']: { EN: "Reviews for Modules have been successfully updated" },
  ['Ulasan untuk Trainer berhasil diperbarui']: { EN: "Reviews for Trainer were successfully updated" },
  ['Beri Ulasan']: { EN: "Give a review" },
  ['Beri Ulasan untuk Modul']: { EN: "Give a review for the module" },
  ['Perbarui Ulasan untuk Modul']: { EN: "Update reviews for modules" },
  ['Beri Penilaian']: { EN: "Give an assessment" },
  ['Tulis ulasan kamu untuk training disini...']: { EN: "Write your review for training here ..." },
  ['Beri Ulasan untuk Trainer']: { EN: "Give a review for the trainer" },
  ['Perbarui Ulasan untuk Trainer']: { EN: "Update reviews for trainers" },
  ['Tulis ulasan kamu untuk trainer disini...']: { EN: "Write your review for the trainer here ..." },
  ['Diskusi berhasil ditambahkan']: { EN: "The discussion was successfully added" },
  ['Komentar berhasil dihapus']: { EN: "Comments were successfully deleted" },
  ['Komentar berhasil diperbarui']: { EN: "Comments were successfully updated" },
  ['Ubah']: { EN: "Change" },
  ['Hapus']: { EN: "Wipe" },
  ['Balas']: { EN: "Reply" },
  ['balasan']: { EN: "reply" },
  ['Diskusi berhasil disukai']: { EN: "The discussion was successfully liked" },
  ['Catatan Untuk Kamu']: { EN: "Note for you" },
  ['Catatan adalah fitur yang membantu kamu mencatat hal-hal penting dari modul training yang dipelajari. Kamu tinggal klik icon tambah catatan, ketik catatan yang mau ditambah dan simpan.']: { EN: "Note is a feature that helps you record important things from the training module being studied. You just click the icon Add notes, type the notes you want to add and save." },
  ['Catatan Saya Untuk Modul']: { EN: "My note for the module" },
  ['Unduh Catatan']: { EN: "Download notes" },
  ['Belum Ada Hasil Training']: { EN: "There are no training results" },
  ['Selesaikan semua materi dan kerjakan tes training untuk mendapatkan hasil training dan sertifikat dari Terampil']: { EN: "Complete all the material and do the training test to get the results of training and certificates from skilled" },
  ['Level Learning']: { EN: "Learning level" },
  ['Pengetahuan diperoleh sejak Anda pertama kali mengikuti pelatihan']: { EN: "Knowledge is gained since you first attended the training" },
  ['Level Reaction']: { EN: "Reaction level" },
  ['Total waktu yang Anda habiskan dalam pelatihan ini']: { EN: "The total time you spent in this training" },
  ['Kamu harus mendapatkan nilai Tes Akhir']: { EN: "You have to get the final test score" },
  ['untuk bisa download sertifikat']: { EN: "to be able to download certificates" },
  ['Silakan Retake untuk ikuti Tes Akhir lagi. Semangat!']: { EN: "Please crack to take the final test again. Spirit!" },
  ['Untuk mengunduh sertifikat silahkan tekan tombol']: { EN: "To download the certificate, please press the button" },
  ['dibawah']: { EN: "under" },
  ['Sertifikat bisa diunduh jika 70% jawaban kamu dari seluruh pertanyaan benar, jika belum benar kamu bisa ikuti lagi kusinya.']: { EN: "Certificates can be downloaded if 70% of your answers from all questions are correct, if not true you can follow the KUSA again." },
  ['Rekomendasi']: { EN: "Recommendation" },
  ['Rating & Ulasan']: { EN: "Rating & review" },
  ['Berikan Rating dan Ulasan untuk Trainer dan Training']: { EN: "Give ratings and reviews for trainers and training" },
  ['Berikan Ulasan Kamu']: { EN: "Give your review" },
  ['Beri Ulasan Untuk Modul Training']: { EN: "Give a review for the training module" },
  ['Beri Ulasan Untuk Trainer']: { EN: "Give a review for the trainer" },
  ['Berhasil Tambah Ulasan']: { EN: "Successfully added a review" },
  ['Ulasan masih bisa Kamu ubah di menu ulasan modul training.']: { EN: "You can still change the review in the Training Module Review menu." },
  ['Link Tugas Berhasil Diterima']: { EN: "The task link was successfully accepted" },
  ['Tambahkan link tugasmu disini']: { EN: "Add your assignment link here" },
  ['*Upload tugas kamu di GDrive, Dropbox dan sejenisnya. Setelah itu share link tugas kamu di field input di atas. Pastikan link yang kamu share bisa diakses secara publik, supaya bisa dicek sama admin Terampil.']: { EN: "*Upload your duties in GDrive, Dropbox and the like. After that share your assignment link in the input field above. Make sure the link you share can be accessed publicly, so that it can be checked with a skilled admin." },
  ['Konsultasi']: { EN: "Consultation" },
  ['Jadwal']: { EN: "Timetable" },
  ['Tempat']: { EN: "Place" },
  ['Sembunyikan']: { EN: "Hide" },
  ['Lihat Selengkapnya']: { EN: "View more" },
  ['Dokumen Pelatihan']: { EN: "Training documents" },
  ['Test Awal']: { EN: "Early test" },
  ['Semua']: { EN: "All" },
  ['Belum Ada Ulasan']: { EN: "There are no reviews" },
  ['Belum ada ulasan untuk training ini. Jadi orang pertama yang memberikan ulasan untuk training ini']: { EN: "There are no reviews for this training. So the first person to give a review for this training" },
  ['Balasan diskusi berhasil ditambah']: { EN: "The discussion reply was successful" },
  ['Cari Training dan Trainer']: { EN: "Find Training and Trainer" },
  ['Durasi']: { EN: "Duration" },
  ['Terampil adalah platform pelatihan praktikal dengan metode pembelajaran aktif yang diajarkan oleh Trainer Terbaik.']: { EN: "Skilled is a practical training platform with active learning methods taught by the best trainers." },
  ['Apa itu Terampil']: { EN: "What is skilled" },
  ['Ajak 1 teman kamu untuk beli salah satu paket, dan dapatkan 1 paket yang sama secara gratis!']: { EN: "Invite 1 of your friends to buy one of the packages, and get the same package for free!" },
  ['Ajak Temanmu Sekarang']: { EN: "Invite your friends now" },
  ['Ajak 3 teman untuk mendaftar di Terampil, dapatkan 1 video gratis untuk ditonton.']: { EN: "Invite 3 friends to register at skilled, get 1 free video to watch." },
  ['Ajak 1 teman untuk membeli training di Terampil, dapatkan training gratis sesuai paket yang dibeli temanmu.']: { EN: "Invite 1 friend to buy training in skilled, get free training according to the package that your friends buy." },
  ['Manfaat Undang Teman']: { EN: "Benefits of invite friends" },
  ['Mari menjadi bagian dari misi Terampil dalam meningkatkan keterampilan di Indonesia. Sebagai apresiasi Terampil akan memberikan beberapa manfaat untuk kamu.']: { EN: "Let's be part of a skilled mission in improving skills in Indonesia. As a skilled appreciation will provide some benefits for you." },
  ['Frequently Asked Questions']: { EN: "Frequently asked questions" },
  ['Poin kamu']: { EN: "Your point" },
  ['Ubah Profil']: { EN: "Change the profile" },
  ['Aktivitas Saya']: { EN: "My activity" },
  ['Pembelian']: { EN: "Purchase" },
  ['Email belum terverifikasi']: { EN: "Email has not been verified" },
  ['Upload Foto Profil']: { EN: "Upload profile photo" },
  ['Wajib Diisi']: { EN: "Must be filled in" },
  ['Minat']: { EN: "Interest" },
  ['Tentang Kamu']: { EN: "About you" },
  ['Link Sosial Media']: { EN: "Social media link" },
  ['Akun Saya']: { EN: "My account" },
  ['Tambahkan Email']: { EN: "Add email" },
  ['Nomor HP wajib diisi']: { EN: "Mobile phone number must be filled" },
  ['Tambahkan No. Telp']: { EN: "Add No. Tel" },
  ['Untuk mengubah Email dan Nomor HP, silahkan']: { EN: "To change email and cellphone number, please" },
  ['klik disini']: { EN: "Click here" },
  ['Kata sandi lama salah.']: { EN: "Said the old password." },
  ['Belum ada email, tambah email terlebih dahulu']: { EN: "There is no email yet, add the email first" },
  ['Kata Sandi Lama']: { EN: "Old password" },
  ['Masukkan Kata Sandi Lama']: { EN: "Enter the old password" },
  ['Password Tidak Boleh Sama dengan Password Lama']: { EN: "Passwords must not be the same as the old password" },
  ['Kata sandi minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, simbol dan angka']: { EN: "A minimum password of 8 characters, consisting of capital letters, lowercase letters, symbols and numbers" },
  ['Konfirmasi Kata Sandi Baru']: { EN: "Confirm the New Password" },
  ['Kata Sandi Harus Sama']: { EN: "The password must be the same" },
  ['Buat Kata Sandi']: { EN: "Make a password" },
  ['Rincian Undang Teman']: { EN: "Details of invite friends" },
  ['Belum ada poin']: { EN: "There are no points" },
  ['Training Gratis Untuk Kamu']: { EN: "Free training for you" },
  ['Kuota modul training bisa langsung kamu gunakan tanpa perlu klaim terlebih dahulu.']: { EN: "You can use the quota of training modules without the need for claims first." },
  ['Pilih Training']: { EN: "Choose training" },
  ['Unlock Video Gratis untuk Kamu']: { EN: "Unlock Free Video For You" },
  ['Gunakan kode di bawah untuk klaim video gratis.']: { EN: "Use the code below for free video claims." },
  ['Berhasil salin kode unlock video']: { EN: "Successfully copy the video unlock video" },
  ['Tidak ada data yang ditampilkan']: { EN: "No data displayed" },
  ['Tanggal pembayaran']: { EN: "Payment date" },
  ['Tagihan sudah lewat batas pembayaran']: { EN: "Bills have passed the payment limit" },
  ['Belum bayar']: { EN: "Not yet paid" },
  ['Bayar sebelum tanggal']: { EN: "Pay before the date" },
  ['Transaksi pada Tanggal']: { EN: "Transaction on date" },
  ['Halaman ini berisi semua Aktivitas yang telah Kamu ikuti.']: { EN: "This page contains all the activities that you have participated in." },
  ['Sekarang']: { EN: "Now" },
  ['Akan Datang']: { EN: "Will come" },
  ['Selesai']: { EN: "Finished" },
  ['Sedang Berlangsung']: { EN: "Ongoing" },
  ['Halaman Verifikasi Sertifikat']: { EN: "Certificate verification page" },
  ['Sertifikat ini dikeluarkan oleh Terampil']: { EN: "This certificate was issued by skilled" },
  ['Tanggal Download Sertifikat']: { EN: "Certificate Download Date" },
  ['Nama Penerima Sertifikat']: { EN: "The name of the certificate recipient" },
  ['Nama Trainer']: { EN: "Trainer name" },
  ['Berhasil menjawab']: { EN: "Managed to answer" },
  ['Yakin keluar dari Live Webinar ini?']: { EN: "Are you sure you get out of this live webinar?" },
  ['Setelah keluar dari room webinar ini, kamu masih bisa ikut lagi selama live masih berlangsung.']: { EN: "After leaving the webinar room, you can still participate again as long as live is still ongoing." },
  ['Selamat! Kamu mendapatkan akses Free Trial selama 3 hari']: { EN: "Happy! You get free trial access for 3 days" },
  ['Selamat']: { EN: "Happy" },
  ['Kamu mendapatkan Free Trial']: { EN: "You get free trial" },
  ['bebas pilih modul apapun secara']: { EN: "free to choose any module" },
  ['Video Training']: { EN: "Video Training" },
  ['Berlaku di hari yang sama.']: { EN: "Valid on the same day." },
  ['*kecuali modul prakerja']: { EN: "*Except the Prakja Work Module" },
  ['Menonton sambil belajar lebih nyaman dan mudah melalui aplikasi Terampil']: { EN: "Watching while learning more comfortable and easier through skilled applications" },
  ['Lanjut Berlangganan']: { EN: "Continue subscription" },
  ['Progres Belajar']: { EN: "Learning Progress" },
  ['Sisa Kuota']: { EN: "The remaining quota" },
  ['Keluar']: { EN: "Go out" },
  ['Masuk / Daftar']: { EN: "Enter / list" },
  ['Kategori Pembelajaran']: { EN: "Learning category" },
  ['Dashboard Trainer']: { EN: "Dashboard Trainer" },
  ['Progress Belajar']: { EN: "Learning Progress" },
  ['Nikmati berbagai keuntungan belajar di Terampil']: { EN: "Enjoy various advantages of learning in skilled" },
  ['1200+ Video Training']: { EN: "1200+ Video Training" },
  ['100+ Trainer Profesional']: { EN: "100+ Professional Trainer" },
  ['Bersertifikat Terampil']: { EN: "Skilled certified" },
  ['Download dan tampilkan secara offline']: { EN: "Download and display offline" },
  ['Live Q&A session dengan Trainer']: { EN: "Live Q & A Session with Trainer" },
  ['Coba Gratis 3 Video Sekarang']: { EN: "Try free 3 videos now" },
  ['Sudah Dipercaya oleh Perusahaan Ternama']: { EN: "Has been trusted by a well -known company" },
  ['Kategori Training']: { EN: "Training category" },
  ['Apa Kata Mereka?']: { EN: "What are they saying?" },
  ['Anda memiliki kualifikasi khusus dan ingin membagikan ilmu yang Anda miliki?']: { EN: "You have a special qualification and want to share the knowledge you have?" },
  ['Lebih Lanjut']: { EN: "Furthermore" },
  ['Menjadi Trainer']: { EN: "Become a trainer" },
  ['Mari Bergabung']: { EN: "Join us" },
  ['Berkolaborasi']: { EN: "Collaborate" },
  ['Sosial Media']: { EN: "Social media" },
  ['Perusahaan']: { EN: "Company" },
  ['Syarat & Ketentuan']: { EN: "Terms and conditions" },
  ['Blog']: { EN: "Blog" },
  ['Kurang dari 1 jam']: { EN: "Less than 1 hour" },
  ['1 - 2 jam']: { EN: "12 hours" },
  ['Lebih dari 3 jam']: { EN: "More than 3 hours" },
  ['jam']: { EN: "hour" },
  ['menit']: { EN: "minute" },
  ['Populer']: { EN: "Popular" },
  ['Terbaru']: { EN: "Latest" },
  ['Tentang']: { EN: "About" },
  ['Training Bersama']: { EN: "Training with" },
  ['Diperbarui']: { EN: 'Updated' },
  ['Video Interaktif']: { EN: 'Interactive Video' },
  ['Sisa Modul']: { EN: 'Module Credits' },
};

Object.keys(LANGMAP).map(z => console.log(z));