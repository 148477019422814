import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 50px;
  h2 {
    margin-top: 30px;

    ${(props) => props.theme.font.primary.title};
    font-size: 20px;
    text-align: left;
    margin: 8px 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    h2 {
      font-size: 16px;
      line-height: 21px;
    }
    margin-top: 24px;
  }
`;

export const CardContiner = styled.div`
  width: 100%;
  background-color: #202020;
  border-radius: 10px;
  height: 213px;
  box-sizing: border-box;
  padding: 24px;
  margin-top: 24px;
  display: flex;
  position: relative;

  div.container {
    padding-left: 16px;
  }
  & h2 {
    ${(props) => props.theme.font.primary.title};
    font-size: 20px;
    text-align: left;
    margin-bottom: 8px;
  }

  h3 {
    ${(props) => props.theme.font.secondary.subtitle2};
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: #c4c4c4;
    margin: 0;
  }

  div.coment {
    ${(props) => props.theme.font.secondary.subtitle2};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #fdfdfd;
    overflow: auto;
    background-color: #373737;
    padding: 16px;
    border-radius: 5px;
    height: 104px;
    box-sizing: border-box;
    margin-top: 16px;
  }

  div.none {
    display: none;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 95%;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 100%;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    padding: 8px;

    div.container {
      padding-left: 8px;
    }

    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin: 0 0 4px 0;
    }

    h3 {
      font-weight: 300;
      font-size: 10px;
      line-height: 15px;
    }
    div.coment {
      visibility: hidden;
    }

    div.none {
      display: block;
      ${(props) => props.theme.font.secondary.subtitle2};
      font-size: 14px;
      line-height: 20px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #fdfdfd;
      position: absolute;
      top: 50px;
    }

    height: auto;
    margin-top: 12px;
  }
`;

export const AvatarImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  background-image: url('${(props) => props.image}');

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 40px;
    height: 40px;
  }
`;
