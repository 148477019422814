import styled from 'styled-components';

export const Container = styled.div`
  background: #202020;

  width: 60%;

  margin: 0 auto;

  display: grid;
  justify-content: center;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  line-height: 36px;

  text-align: center;

  border-radius: 10px;

  padding: 24px 24px 32px 24px;

  display: grid;
  align-content: center;

  h6 {
    font-size: 24px;
    margin: 0;
    margin-bottom: 16px;

    @media screen and ${({ theme }) => theme.breakpoints.xs} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  p {
    margin: 0;
    margin-bottom: 24px;

    font-weight: 400;
    font-size: 16px;

    @media screen and ${({ theme }) => theme.breakpoints.xs} {
      margin-bottom: 46px;
      line-height: 24px;
    }
  }
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.color.primary};

  color: black;
  cursor: pointer;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  border-radius: 5px;
  border: none;

  width: 187px;
  height: 48px;

  margin: 0 auto;
`;
