import { createStore, applyMiddleware, combineReducers } from 'redux';
// import expireReducer from 'redux-persist-expire';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import * as reducers from './modules';
import { apiService, createLogger } from './middlewares';

export default function configureStore(initialState) {
  const appReducer = combineReducers(reducers);
  const rootPersistConfig = {
    key: 'root',
    storage: storage,
  };

  const rootReducer = (state, action) => {
    // if (action.type === LOGOUT) {
    //     state = undefined
    // }
    return appReducer(state, action);
  };

  const persist = persistReducer(rootPersistConfig, rootReducer);

  return createStore(
    persist,
    initialState,
    applyMiddleware(apiService, thunkMiddleware, createLogger(true)),
  );
}
