import styled from 'styled-components';

export const Container = styled.section`
  min-width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #141414;
  margin-top: 77px;

  video[poster] {
    object-fit: cover;
  }

  .shaka-play-button {
    padding: 4%;
  }
  .shaka-spinner {
    padding: 4.3%;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin-top: 65px;
  }
`;

export const VideoPlayer = styled.video`
  width: inherit;
  height: 450px;
  object-fit: fill;
  cursor: pointer;

  :focus {
    outline: none;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    height: 420px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    height: 187px;
  }
`;
