import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 60px;
  right: 0%;

  width: 450px;
  max-height: 500px;

  cursor: default;

  padding-top: 24px;

  border-radius: 5px;

  background: #202020;

  box-sizing: border-box;

  text-align: left;

  h5 {
    font-size: 20px;
    margin: 0;
  }

  h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    margin: 0;
  }

  p {
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .primary {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #fdfdfd;
  }

  .secondary {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */
    margin-top: 8px;

    color: #c4c4c4;
  }

  .date {
    align-self: flex-start;
    justify-self: self-end;
  }

  .button {
    cursor: pointer;
  }
`;

export const HeadNotification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  padding-bottom: 18px;

  h5 {
    font-family: 'Inter' !important;
  }

  border-bottom: 1px solid #626262;

  box-sizing: border-box;

  & > svg {
    fill: #c4c4c4;
    cursor: pointer;
  }
`;

export const EmptyState = styled.div`
  min-height: 415px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 0 16px 0;
    color: #fdfdfd;
  }

  & > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #fdfdfd;
    margin: 0;
  }
`;

export const MainNotification = styled.div`
  /* padding: 18px 24px; */
  padding-bottom: 0;
  overflow: auto;
  max-height: 400px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #141414;
  }

  ::-webkit-scrollbar-thumb {
    background: #202020;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* .notification-container {
    padding-bottom: 8px;
  }

  .notification-container > div {
    padding-bottom: 16px;
  } */

  .notification-date {
    display: flex;
    justify-content: space-between;
  }

  .notification-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > svg {
      flex-shrink: 0;
      margin-right: 18px;
    }
  }

  .disabled {
    & > svg,
    p {
      fill: #626262 !important;
      color: #626262;
    }

    & rect {
      filter: brightness(50%) !important;
    }
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    .notification-date {
      padding-bottom: 24px;
    }
  }
`;

export const BoxContainer = styled.div`
  height: 96px;
  width: 100%;
  border-bottom: 1px solid #626262;
  position: relative;
  cursor: pointer;
  background: ${(props) => (props.isread ? '#202020' : 'rgba(98, 98, 98, 0.1)')};

  :hover {
    background-color: #141414;
  }
  .primary {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: ${(props) => (props.isread ? '#999999' : '#fdfdfd')};
  }

  .secondary {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */
    margin-top: 8px;

    color: ${(props) => (props.isread ? '#999999' : '#fdfdfd')};
  }

  .date {
    align-self: flex-start;
    justify-self: self-end;
    color: ${(props) => (props.isread ? '#999999' : '#c4c4c4')};
  }

  svg {
    color: ${(props) => (props.isread ? '#999999' : '#c4c4c4')};
  }
`;

export const FlexContain = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0 12px 1px;
  height: inherit;
  box-sizing: border-box;
  justify-content: space-evenly;

  .box-title {
    width: 70%;
  }
`;
