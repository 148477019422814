import { Text } from 'slate';
import { jsx } from 'slate-hyperscript';
import escapeHtml from 'escape-html';

export const serialize = (node) => {
  if (Text.isText(node)) {
    return escapeHtml(node.text);
  }

  const children = node.children.map((n) => serialize(n)).join('');

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case 'mention':
      return `<span style="padding: '3px 3px 2px';margin: '0 1px';vertical-align: baseline;display:inline-block;border-radius: 4px;color: #00debf;font-weight: bold;font-size: 0.9em">@${node?.character}</span> `;
    default:
      return children;
  }
};

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  const children = Array.from(el.childNodes).map(deserialize);

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'quote' }, children);
    case 'P':
      const child = children.length > 0 ? children : [''];
      return jsx('element', { type: 'paragraph' }, child);
    case 'A':
      return jsx('element', { type: 'link', url: el.getAttribute('href') }, children);
    case 'SPAN':
      const childe = children.length > 0 ? children : [''];
      return jsx('element', { type: 'mention' , character: childe[0].slice(1,childe[0].length)}, [{text: ""}]);
    default:
      return jsx('element', { type: 'paragraph' }, [{ text: '' }]);
  }
};
