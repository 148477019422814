import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getValue } from 'utils';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { scrollMidle } from 'utils/scrollPage';
import { ContainerTitle, Title, ButtonIkutiTraining } from './styled';
import { checkMobile } from 'utils/checkMobile';
import RedirectModalToMobile from 'components/molecule/Modal/RedirectToMobile';
import { t } from 'hooks/intl/useLang';

const TitleTraining = ({
  training,
  is_subscription,
  quota,
  haveTraining,
  expiredFreeTrial,
  setShowModal,
  haveTrainingFreeTrail,
  haveUnlockVideo,
}) => {
  const history = useHistory();
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const [openModal, setOpenModal] = useState(false);
  const isMobile = checkMobile();
  const { url } = useRouteMatch();

  const renderIkutiTraining = () => {
    if (isMobile) {
      return (
        <ButtonIkutiTraining onClick={() => setOpenModal(true)}>
          {t('Ikuti Training Ini')}
        </ButtonIkutiTraining>
      );
    } else {
      if ((quota === 0 && !haveTraining) || haveTrainingFreeTrail || haveUnlockVideo) {
        return (
          <ButtonIkutiTraining
            onClick={() => {
              if (getValue(authData, 'data.accessToken')) {
                const element = document.getElementById('ikutikelas');
                if (element === null) {
                  history.push({ pathname: `${url}/deskripsi` });
                  setTimeout(() => {
                    scrollMidle('ikutikelas', 10);
                  }, 300);
                } else {
                  scrollMidle('ikutikelas', 10);
                }
              } else {
                history.push('/masuk');
              }
            }}
          >
            {t('Ikuti Training')}
          </ButtonIkutiTraining>
        );
      } else if (!haveTraining) {
        return (
          <ButtonIkutiTraining
            onClick={() => {
              if (getValue(authData, 'data.accessToken')) {
                setShowModal(true);
              } else {
                history.push('/masuk');
              }
            }}
          >
            {t('Ikuti Training Ini')}
          </ButtonIkutiTraining>
        );
      } else {
        return null;
      }
    }
  };
  return (
    <ContainerTitle>
      <Title>
        {training?.Training?.title} <span>{training?.Training?.category?.name}</span>
      </Title>
      {openModal && (
        <RedirectModalToMobile isOpen={openModal} onRequestClose={() => setOpenModal(false)} />
      )}
      {renderIkutiTraining()}
    </ContainerTitle>
  );
};

export default TitleTraining;
