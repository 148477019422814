import React, { useState } from 'react';
import PageLayout from '../../components/pagelayout/Template';
import { FeedbackWebinarStyled, TitleFeedBack } from './styled';
import {
  AboutTrainer,
  Avatar,
  CardProfile,
  Container,
  ContainerButton,
  ContainerOuter,
  InputContainer,
  Profile,
  Rating,
  Separator,
  Title,
  TitleTraining,
  Wrapper,
} from '../../components/organisms/TrainingDetails/GiveRating&Ulasan/styled';
import { Controller, useForm } from 'react-hook-form';
import StarRatings from 'react-star-ratings';
import InputField from '../../components/atoms/InputField';
import Button from '../../components/atoms/Button';
import PopupBerhasil from '../../components/molecule/Modal/PopupBerhail';
import { useMutation, useQuery } from '@apollo/client';
import { GET_WEBINAR_BY_ID } from '../../queries/webinar';
import { setContext } from '../../utils';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CREATE_FEEDBACK_WEBINAR } from '../../mutation/webinar';
import { t } from 'hooks/intl/useLang';

const FeedbackWebinar = () => {
  const seo = {
    title: 'Webinar Live',
    description: 'Webinar Live',
    canonical: null,
  };

  const history = useHistory();
  const { roomId } = useParams();
  const authData = useSelector((state) => state.Auth);

  const [showModal, setShowModal] = useState(false);
  const { handleSubmit, register, control, errors } = useForm();

  const { data: webinarData } = useQuery(GET_WEBINAR_BY_ID, {
    context: setContext(authData),
    variables: {
      room_id: roomId,
    },
  });

  const [createFeedback] = useMutation(CREATE_FEEDBACK_WEBINAR, {
    onCompleted(data) {
      history.push('/');
    },
    onError(err) {
      console.log(err);
    },
  });

  const submitForm = (data) => {
    createFeedback({
      context: setContext(authData),
      input: {
        webinar_id: webinarData?.Webinar?.id,
        text: data.commentWebinar,
        rating: data.ratingTraining,
      },
    });
  };

  return (
    <PageLayout seo={seo} blank noFooter disableLogo>
      <FeedbackWebinarStyled>
        <TitleFeedBack>{t('Silahkan Beri Feedback Kamu')}</TitleFeedBack>
        <Container>
          <form onSubmit={handleSubmit(submitForm)}>
            <ContainerOuter>
              <Title style={{ marginLeft: 44 }}>{t('Feedback Untuk Webinar')}</Title>
              <Wrapper>
                <CardProfile>
                  <Profile>
                    <Avatar image={webinarData?.Webinar?.trainer?.avatar} />
                    <AboutTrainer>
                      <TitleTraining>{webinarData?.Webinar?.name}</TitleTraining>
                    </AboutTrainer>
                    <Separator />
                    <Rating>
                      <Controller
                        control={control}
                        name="ratingTraining"
                        defaultValue={0}
                        register={register({
                          required: true,
                        })}
                        render={({ onChange, value }) => (
                          <StarRatings
                            required
                            rating={value}
                            changeRating={onChange}
                            starRatedColor="#F5A52F"
                            numberOfStars={5}
                            starHoverColor="#F5A52F"
                            name="rating"
                            starDimension={'30px'}
                            svgIconPath="M5.73321 29.7001C5.57721 30.5881 6.45321 31.2821 7.22521 30.8861L16.0052 26.3741L24.7832 30.8861C25.5552 31.2821 26.4312 30.5881 26.2752 29.7021L24.6152 20.2421L31.6612 13.5301C32.3192 12.9021 31.9772 11.7541 31.0952 11.6301L21.2992 10.2381L16.9312 1.58409C16.848 1.40867 16.7167 1.26046 16.5526 1.15668C16.3885 1.05289 16.1984 0.997803 16.0042 0.997803C15.8101 0.997803 15.6199 1.05289 15.4558 1.15668C15.2917 1.26046 15.1604 1.40867 15.0772 1.58409L10.7092 10.2401L0.913213 11.6321C0.0312131 11.7561 -0.310787 12.9041 0.347213 13.5321L7.39321 20.2441L5.73321 29.7041V29.7001ZM15.5432 24.1661L8.17121 27.9541L9.55921 20.0401C9.59173 19.8579 9.57901 19.6705 9.52216 19.4944C9.46532 19.3183 9.3661 19.1589 9.23321 19.0301L3.42121 13.4901L11.5252 12.3381C11.693 12.3127 11.8522 12.2471 11.9891 12.1468C12.126 12.0465 12.2365 11.9145 12.3112 11.7621L16.0052 4.44609L19.6972 11.7621C19.7719 11.9145 19.8825 12.0465 20.0193 12.1468C20.1562 12.2471 20.3154 12.3127 20.4832 12.3381L28.5872 13.4881L22.7752 19.0281C22.6419 19.157 22.5424 19.3168 22.4855 19.4933C22.4286 19.6698 22.4162 19.8576 22.4492 20.0401L23.8372 27.9541L16.4652 24.1661C16.3227 24.0926 16.1646 24.0542 16.0042 24.0542C15.8438 24.0542 15.6858 24.0926 15.5432 24.1661Z"
                            svgIconViewBox="0 0 32 31"
                            starEmptyColor="white"
                            starSpacing={'7px'}
                          />
                        )}
                      />
                    </Rating>
                  </Profile>
                </CardProfile>

                <InputContainer>
                  <InputField
                    dark={true}
                    required
                    defaultValue={''}
                    className="input-comment"
                    name="commentWebinar"
                    placeholder={t("Tulis feedback kamu untuk webinar disini...")}
                    register={register({
                      pattern: {
                        required: true,
                        value: /^[\w\-\s]+$/,
                        message: 'Entered value does not match name format',
                      },
                    })}
                  />
                </InputContainer>
              </Wrapper>
            </ContainerOuter>

            <ContainerButton>
              {/* <Button outline className="outline-button" small>
            Batal
          </Button> */}
              <Button id="rated-trainer" type="submit" small>
                {t('Kirim')}
              </Button>
            </ContainerButton>
          </form>
          <PopupBerhasil
            onRequestClose={() => setShowModal(!showModal)}
            isOpen={showModal}
            title={t('Berhasil Tambah Ulasan')}
            text={t('Ulasan masih bisa Kamu ubah di menu ulasan modul training.')}
            textButtonLeft={t('Kembali ke Materi')}
            textButtonRight={t('Kembali ke Home')}
          />
        </Container>
      </FeedbackWebinarStyled>
    </PageLayout>
  );
};

export default FeedbackWebinar;
