import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from 'components/atoms/Button';
import NavigationHeader from 'components/atoms/NavigationHeader';

import {
  Container,
  FormSection,
  InputUserSetting,
  InputContainer,
  Label,
} from '../EditProfil/styled';
import { useMutation, useQuery } from '@apollo/client';
import { RESET_PASSWORD } from 'mutation/resetpassword';
import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import UbahPasswordModal from 'components/molecule/Modal/UbahSandiModal';
import { GET_USER_DETAIL } from 'queries/user';
import TambahKataSandiModal from 'components/molecule/Modal/TambahKataSandiModal';
import { t } from 'hooks/intl/useLang';

const AturKataSandi = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const authData = useSelector((state) => {
    return state.Auth;
  });

  // FETCHING USER DATA
  const { data: userData } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
    errors,
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });

  const [message, setMessage] = useState(null);
  const [submitNewPassword] = useMutation(RESET_PASSWORD, {
    context: setContext(authData),
    onError(errors) {
      if (errors.message === 'Old password not match.') {
        setMessage(t('Kata sandi lama salah.'));
      } else {
        setMessage(errors.message);
      }
    },
    onCompleted: () => {
      setIsOpenModal(true);
      reset();
    },
  });

  const [submitPassword] = useMutation(RESET_PASSWORD, {
    context: setContext(authData),
    onCompleted: () => {
      setIsOpenModal(true);
      reset();
    },
  });

  const submitData = ({ newPassword, oldPassword }) => {
    // DO SUBMIT DATA HERE
    submitNewPassword({
      variables: {
        oldPassword,
        newPassword,
      },
    });
  };

  const submitDataPassword = ({ newPassword, oldPassword }) => {
    // DO SUBMIT DATA HERE
    submitPassword({
      context: setContext(authData),
      variables: {
        newPassword,
        oldPassword: '',
      },
      onCompleted: () => {
        setIsOpenModal(true);
        reset();
      },
    });
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  }, [message]);

  //check email
  const isEmailRegistered = userData?.ProfileUser?.User?.email !== null;
  const isUserEmptyPassword = userData?.ProfileUser?.User?.isEmptyPassword === false;

  return (
    <Container>
      <NavigationHeader
        form="atur-password-form"
        title={t('Atur Kata Sandi')}
        checkChanges={!isValid}
      />
      <TambahKataSandiModal
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        setIsOpenModal={setIsOpenModal}
      />
      <UbahPasswordModal
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        setIsOpenModal={setIsOpenModal}
      />
      {isUserEmptyPassword ? (
        <FormSection id="atur-password-form" onSubmit={handleSubmit(submitData)}>
          <div className="head">
            <div className="check-email">
              <h6 className="none">{t('Kata Sandi')}</h6>
              <span className={isEmailRegistered ? 'hide-component' : 'email-null'}>
                ({t('Belum ada email, tambah email terlebih dahulu')})
              </span>
            </div>
            <p className="title">
              {t('Kata sandi minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, simbol dan angka.')}
            </p>
          </div>
          <div className="wraper">
            <div className="in-wraper">
              <InputContainer>
                <Label for="oldPassword">{t('Kata Sandi Lama')}</Label>
                <InputUserSetting
                  type="password"
                  ref={register({ required: true })}
                  name="oldPassword"
                  id="oldPassword"
                  placeholder={t("Masukkan Kata Sandi Lama")}
                />
              </InputContainer>
            </div>
            <div className="wrapper-1">
              <h6 className="alert">{message}</h6>
            </div>
          </div>

          <div className="wraper">
            <div className="in-wraper">
              <InputContainer>
                <Label for="newPassword">{t('Kata Sandi Baru')}</Label>
                <InputUserSetting
                  type="password"
                  ref={register({
                    required: true,
                    validate: {
                      checkValue: (value) => value !== watch('oldPassword'),
                      checkPattern: (value) =>
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,30}$/g.test(
                          value,
                        ),
                    },
                  })}
                  name="newPassword"
                  id="newPassword"
                  placeholder={t("Buat Kata Sandi Baru")}
                />
              </InputContainer>
            </div>
            <div className="wrapper-1">
              {errors.newPassword?.type === 'checkValue' && (
                <h6 className="alert">{t('Password Tidak Boleh Sama dengan Password Lama')}</h6>
              )}
              {errors.newPassword?.type === 'checkPattern' && (
                <h6 className="alert">
                  {t('Kata sandi minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, simbol dan angka')}
                </h6>
              )}
            </div>
          </div>

          <div className="wraper">
            <div className="in-wraper">
              <InputContainer>
                <Label className="static" for="confirmnewpassword">
                  {t('Konfirmasi Kata Sandi Baru')}
                </Label>
                <InputUserSetting
                  type="password"
                  ref={register({
                    required: true,
                    validate: (value) => {
                      return value === watch('newPassword');
                    },
                  })}
                  name="confirmnewpassword"
                  id="confirmnewpassword"
                  placeholder="Konfirmasi Kata Sandi Baru"
                />
              </InputContainer>
            </div>
            <div className="wrapper-1">
              {errors.confirmnewpassword && <h6 className="alert">{t('Kata Sandi Harus Sama')}</h6>}
            </div>
          </div>
          <div className="bottom-form">
            <div></div>
            <Button
              type="submit"
              disabled={!isValid}
              medium
              className={`${!isValid ? 'disabled-button' : ''} none save-button`}
            >
              {t('Simpan')}
            </Button>
          </div>
        </FormSection>
      ) : (
        <FormSection id="atur-password-form" onSubmit={handleSubmit(submitDataPassword)}>
          <div className="head">
            <div className="check-email">
              <h6 className="none">Kata Sandi</h6>
              <span className={isEmailRegistered ? 'hide-component' : 'email-null'}>
                ({t('Belum ada email, tambah email terlebih dahulu')})
              </span>
            </div>
            <p className="title">
              {t('Kata sandi minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, simbol dan angka.')}
            </p>
          </div>
          <div className="wraper">
            <div className="in-wraper">
              <InputContainer>
                <Label for="newPassword">{t('Kata Sandi')}</Label>
                <InputUserSetting
                  disabled={isEmailRegistered ? false : true}
                  type="password"
                  ref={register({
                    required: true,
                    validate: {
                      checkPattern: (value) =>
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,30}$/g.test(value),
                    },
                  })}
                  name="newPassword"
                  id="newPassword"
                  placeholder={t("Buat Kata Sandi")}
                />
              </InputContainer>
            </div>
            <div className="wrapper-1">
              {/* {errors.newPassword?.type === 'checkValue' && (
              <h6 className="alert">Password Tidak Boleh Sama dengan Password Lama</h6>
            )} */}
              {errors.newPassword?.type === 'checkPattern' && (
                <h6 className="alert">
                  {t('Kata sandi minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, simbol dan angka.')}
                </h6>
              )}
            </div>
          </div>

          <div className="wraper">
            <div className="in-wraper">
              <InputContainer>
                <Label className="static" for="confirmnewpassword">
                  {t('Konfirmasi Kata Sandi')}
                </Label>
                <InputUserSetting
                  disabled={isEmailRegistered ? false : true}
                  type="password"
                  ref={register({
                    required: true,
                    validate: (value) => {
                      return value === watch('newPassword');
                    },
                  })}
                  name="confirmnewpassword"
                  id="confirmnewpassword"
                  placeholder={t("Konfirmasi Kata Sandi")}
                />
              </InputContainer>
            </div>
            <div className="wrapper-1">
              {errors.confirmnewpassword && <h6 className="alert">{t('Kata Sandi Harus Sama')}</h6>}
            </div>
          </div>
          <div className="bottom-form">
            <div></div>
            <Button
              type="submit"
              disabled={!isValid}
              medium
              className={`${!isValid ? 'disabled-button' : ''} none save-button`}
            >
              {t('Simpan')}
            </Button>
          </div>
        </FormSection>
      )}
    </Container>
  );
};

export default AturKataSandi;
