import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Title, Paragraf, Button, ContainerButton, Image } from './styled';
import PopupModal from 'components/atoms/Modal/';
import succesRegister from 'assets/svg/pendaftaran-succes.svg';
import { t } from 'hooks/intl/useLang';

const ModalSuccsesRegister = ({ setIsOpenModalSucces, kolaborasi, item, ...rest }) => {
  const history = useHistory();
  return (
    <PopupModal medium {...rest}>
      <Container>
        <Image src={succesRegister} alt="icon-succes-register" />
        <Title>{item?.title}</Title>
      </Container>
      <Container>
        <Paragraf>
          {t('Pendaftaran Anda sudah kami terima.')} <br />
          {item?.paragraf}
        </Paragraf>
      </Container>
      <ContainerButton>
        {kolaborasi ? (
          <Button large onClick={() => setIsOpenModalSucces(false)}>
            Ok
          </Button>
        ) : (
          <Button large onClick={() => history.push(`${item?.link}`)}>
            Ok
          </Button>
        )}
      </ContainerButton>
    </PopupModal>
  );
};

export default ModalSuccsesRegister;
