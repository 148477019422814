import styled from 'styled-components';

export const Container = styled.section`
  background-color: #141414;
  padding: 33px 100px;
  background-color: #141414;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xxs} {
    padding: 8px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    grid-template-columns: auto;
    row-gap: 12px;
  }
`;
