import styled from 'styled-components';

export const DescTBMContainer = styled.section`
  background: #f2fdfc;
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 20px 0;
  }
`;

export const TitleDescTBM = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #187336;
  white-space: pre-line;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const TextDescTBM = styled.p`
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  padding: 24px 0;
  max-width: 830px;

  color: #626262;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 18px;
    padding: 16px;
  }
`;

export const ItemsDescTBM = styled.div`
  display: flex;
  gap: 40px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    flex-direction: column;
    gap: 16px;
  }
`;
