import ButtonStyled from 'components/atoms/Button/';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  place-items: center;
  padding: 30px 0;
  background-color: inherit;

  a {
    text-decoration: none;
    color: inherit;
  }
  div.container-button {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #fdfdfd;
  margin: 0;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ContainerCard = styled.div`
  background-color: #202020;
  display: grid;
  place-items: center;
  width: 600px;
  height: 228px;
  border-radius: 10px;
  padding: 24px 60px;
  box-sizing: border-box;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    background-color: #000004;
    width: 100%;
    padding: 24px 30px;
  }
`;

export const Button = styled(ButtonStyled)`
  width: 187px;
  height: 48px;
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    margin: 0;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 14px;
    line-height: 21px;
  }
`;
