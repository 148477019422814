import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 55%;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 90%;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    overflow: auto;
    margin-top: 12px;
    justify-content: flex-start;
  }
`;

export const Button = styled.button.attrs((props) => ({
  width: props.all ? '84px' : '58px',
  background: props.selected ? '#373737' : '#202020',
}))`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#373737' : '#202020')};
  border: none;
  border-radius: 30px;
  padding: 9px;
  width: ${(props) => props.width};
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fdfdfd;

  svg {
    width: 16px;
    margin-right: 9px;
  }

  :hover {
    background-color: #373737;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    line-height: 15px;
    flex-shrink: 0;
    width: ${(props) => (props.all ? '59px' : '48px')};
    margin-right: 12px;
    height: 31px;
  } ;
`;
