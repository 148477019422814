import styled from 'styled-components';

export const Section = styled.section`
  background: #202020;
  padding: 20px 100px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 20px 60px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 20px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 20px 15px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0px 15px;
    padding-bottom: 1px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 40px;

  margin-bottom: 80px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-gap: 50px;
    margin-bottom: 36px;
  }
`;

export const GridItem = styled.div`
  & > div {
    margin: 0 auto;
    margin-bottom: 30px;

    height: 80px;
    width: 80px;

    display: grid;
    place-items: center;
  }

  h6 {
    margin: 20px 0;
    font-size: 20px;
  }

  p {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 27px;
    text-align: center;

    color: #c4c4c4;

    width: 80%;
    margin: 0 auto;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    h6 {
      margin: 16px 0;
      font-size: 18px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;
