import styled from 'styled-components';

export const Container = styled.div`
  color: #fdfdfd;

  h6 {
    font-weight: normal;
    font-size: 18px;
  }
`;

export const Exit = styled.button`
  background: transparent;
  font-size: 16px;

  width: 100%;

  color: #fdfdfd;

  cursor: pointer;

  margin-top: 32px;

  border-radius: 5px;

  padding: 16px 26px;

  border: 1px solid #c4c4c4;

  font-family: Inter;
  font-weight: normal;

  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
