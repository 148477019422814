/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_TRAINING_SUMMARY } from 'queries/training';
import { GET_POST_TEST_USER } from 'queries/kuis';
import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { CREATE_CERTIFICATE } from 'mutation/certificate';
import { GET_USER_CERTIFICATE } from 'queries/certificate';
import { RETAKE_TRAINING } from 'mutation/training';
import { GET_USER_FREE_TRIAL } from 'queries/user';
import { RETAKE_ALL_TRAINING } from 'mutation/retake';
import { LoaderIcon } from 'react-hot-toast';

import { Section, Head, Detail, Bottom } from './styled';
import Button from 'components/atoms/Button';
import RetakeHabisModal from 'components/molecule/Modal/RetakeHabisModal';
import RetakeKuisModal from 'components/molecule/Modal/RetakeKuisModal';
import IkutiKelasModal from 'components/molecule/Modal/IkutiKelasModal';
import { LoadingWrapper } from 'components/molecule/Modal/KuisSelesaiModal/styled';

import { ReactComponent as WinnerIcon } from 'assets/svg/Kuis/winner-postest.svg';
import { ReactComponent as FailIcon } from 'assets/svg/Kuis/fail-postest.svg';

const TrainingSummary = ({
  isMobile,
  trainingId,
  title,
  trainingData,
  b2bTraining,
  totalRetake,
}) => {
  const [succeed, setSucceed] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [certificateLink, setCertificateLink] = useState('');
  const [maximumRetake, setMaximumRetake] = useState(false);
  const [showRetakeModal, setShowRetakeModal] = useState(false);
  const [showRetakeHabis, setShowRetakeHabis] = useState(false);
  const [showIkutiKelasModal, setShowIkutiKelasModal] = useState(false);

  const location = useLocation();

  const authData = useSelector((state) => {
    return state.Auth;
  });

  const { slug } = useParams();

  const history = useHistory();

  const { data: userFreeTrial } = useQuery(GET_USER_FREE_TRIAL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const isFreeTrial = userFreeTrial?.ProfileUser?.User?.free_trial;

  const [getUserPostTest, { data: postTest, loading: loadingPostTest = true }] = useLazyQuery(
    GET_POST_TEST_USER,
    {
      fetchPolicy: 'no-cache',
      context: setContext(authData),
      variables: {
        trainingId: trainingId,
        trainingSectionId: location.state.sectionId,
      },
      onCompleted: (data) => {
        const summary = data?.ResultPostTestUser?.summary.split('/');

        if (+summary[0] / +summary[1] >= 0.6) {
          setCompleted(true);
        }
      },
    },
  );

  // GET POST TEST RESULT
  const [getTrainingSummary, { data: dataTrainingSumary, loading }] = useLazyQuery(
    GET_TRAINING_SUMMARY,
    {
      fetchPolicy: 'no-cache',
      context: setContext(authData),
      variables: {
        trainingId: trainingId,
        trainingSectionId: location.state.sectionId,
      },
    },
  );

  // RETAKE TRAINING
  const [retakeTraining] = useMutation(RETAKE_TRAINING, {
    context: setContext(authData),
    onCompleted: (data) => {
      if (data?.RetakeQuizPostTest?.status === 200) {
        history.push(`/training/${slug}/deskripsi`, { retakePostTest: true });
      } else if (data?.RetakeQuizPostTest?.status === 400) {
        setShowIkutiKelasModal(false);
        setShowRetakeHabis(true);
      }
    },
    onError() {
      setShowIkutiKelasModal(false);
      setShowRetakeHabis(true);
    },
  });

  const retakeWithoutHit = () => {
    if (totalRetake === 6) {
      history.push(`/training/rating-ulasan/${slug}`);
    } else {
      history.push(`/training/${slug}/deskripsi`, { retakePostTest: true });
    }
  };

  //RETAKE ALL TRAINING
  // const [retakeAllTraining] = useMutation(RETAKE_ALL_TRAINING, {
  //   context: setContext(authData),
  //   onError: () => {
  //     setMaximumRetake(true);
  //   },
  //   onCompleted: (data) => {
  //     if (data?.RetakeQuizPostTest?.status === 200) {
  //       history.push(`/training/${slug}`);
  //     } else {
  //       setMaximumRetake(true);
  //     }
  //   },
  // });

  // GET USER CERTIFICATE
  const [getCertificate, { data: userCertificate, error }] = useLazyQuery(GET_USER_CERTIFICATE, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      trainingId: trainingId,
    },
    onCompleted: (data) => {
      setSucceed(true);
      setCertificateLink(data?.UserCertificate?.file?.link);
    },
  });
  // CREATE CERTIFICATE
  const [createCertificate] = useMutation(CREATE_CERTIFICATE, {
    context: setContext(authData),
    onCompleted: (data) => {
      setSucceed(true);
      setCertificateLink(data?.CreateCertificate?.file?.link);
    },
  });

  const pushLocation = () => {
    return history.push(`/training/${slug}/deskripsi`, { retakePostTest: true });
  };

  useEffect(() => {
    if (trainingId) {
      getTrainingSummary();
      getCertificate();
      getUserPostTest();
    }
  }, [trainingId]);

  useEffect(() => {
    if (completed && !userCertificate && error && !isFreeTrial) {
      createCertificate({
        variables: {
          input: {
            trainingId: trainingId,
            trainingTitle: trainingData?.title,
            trainingCollaboration: trainingData?.collaboration?.image || '',
            trainingCooporation: trainingData?.cooporation?.image || '',
            trainerFullname: trainingData?.trainer?.fullname,
            trainerProfession: trainingData?.trainer?.profession,
          },
        },
      });
    }
  }, [userCertificate, error, completed]);

  const downloadCertificate = () => {
    window.open(certificateLink);
  };

  const retake = () => {
    retakeTraining({
      variables: {
        input: {
          retakeType: 'PostTest',
          trainingId: trainingId,
          trainingSectionId: location.state.sectionId,
        },
      },
    });
  };

  // const submitRetakeTraining = async () => {
  //   try {
  //     const data = await retakeAllTraining({
  //       variables: {
  //         input: {
  //           trainingId: trainingId,
  //         },
  //       },
  //     });

  //     setShowRetakeModal(false);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const renderTextFailed = () => {
    if (totalRetake >= 6) {
      return (
        <p className="secondary">
          Kamu sudah <span>tidak lulus sebanyak 5 kali,</span> sehingga kamu tidak bisa mendapatkan
          sertifikat untuk modul training ini. Tapi kamu masih tetap bisa melanjutkan menonton
          training selanjutnya.
        </p>
      );
    } else {
      return (
        <p className="secondary">
          Kamu harus mendapatkan nilai Tes Akhir <span>minimum 60</span> untuk bisa mendapatkan
          sertifikat. Kamu mempunyai <span>{6 - totalRetake} kali kesempatan</span> untuk ikut tes
          akhir. Klik <span> retake</span> untuk ikuti Tes Akhir lagi. Semangat!'
        </p>
      );
    }
  };

  const renderTextSuccess = () => {
    if (b2bTraining?.training_type?.includes('b2b')) {
      return (
        <p className="secondary">
          Selamat kamu sudah menyelesaikan training dan mendapatkan sertifikat. Silakan download
          <span> Sertifikat Kompetensi Lulusan</span> dan lanjut training lain.
        </p>
      );
    } else {
      return (
        <p className="secondary">
          Silahkan lihat ringkasan <span>Hasil Training</span> dan download sertifikat, jika belum
          bisa unduh sertifikat silahkan retake kuis dan post test untuk memenuhi passing grade 60%
        </p>
      );
    }
  };

  const renderData = () => {
    if (loadingPostTest || loading) {
      return (
        <LoadingWrapper className="wrapper-loading">
          <LoaderIcon className="loading-icon" />
        </LoadingWrapper>
      );
    }

    if (completed) {
      return (
        <Section>
          <Head>
            <h5>Selamat! sudah menyelesaikan training</h5>
            <h6>{title}</h6>
          </Head>
          <Detail>
            <WinnerIcon />
            <p>
              Kamu berhasil menjawab <strong>{postTest?.ResultPostTestUser?.summary}</strong>{' '}
              pertanyaan dengan benar
            </p>
            {renderTextSuccess()}
          </Detail>
          <Bottom>
            {!isMobile && !isFreeTrial && (
              <Button
                medium
                className="button secondary"
                onClick={downloadCertificate}
                disabled={!succeed}
              >
                Unduh Sertifikat
              </Button>
            )}
            {/* <Button medium className="button danger" onClick={() => setShowRetakeModal(true)}>
              Retake
            </Button> */}
            {/* {b2bTraining?.training_type?.includes('b2b') ? (
              <Button
                medium
                className="button"
                onClick={() => history.replace(`/pelatihan-saya/proses`)}
              >
                Lihat Pelatihan Saya
              </Button>
            ) : (
              <Button
                medium
                className="button"
                onClick={() => history.replace(`/training/rating-ulasan/${slug}`)}
              >
                Beri Ulasan
              </Button>
            )} */}
            <Button
              medium
              className="button"
              onClick={() => history.replace(`/training/rating-ulasan/${slug}`)}
            >
              Beri Ulasan
            </Button>
          </Bottom>
        </Section>
      );
    } else {
      return (
        <Section>
          <Head>
            <h5 className="danger">Nilai tes akhir kamu kurang dari 60 :(</h5>
            <h6>{title}</h6>
          </Head>
          <Detail>
            <FailIcon />
            <p>
              Kamu berhasil menjawab{' '}
              <strong className="danger">{postTest?.ResultPostTestUser?.summary}</strong> pertanyaan
              dengan benar
            </p>
            {renderTextFailed()}
          </Detail>
          <Bottom>
            {!userCertificate && error ? (
              <>
                {totalRetake < 6 && (
                  <Button
                    medium
                    className="button secondary"
                    onClick={() => history.push(`/training/${slug}/deskripsi`)}
                  >
                    Kembali ke Materi
                  </Button>
                )}

                <Button medium className="button" onClick={() => retakeWithoutHit()}>
                  {totalRetake === 6 ? 'Beri Ulasan' : 'Retake'}
                </Button>
              </>
            ) : (
              <>
                {/* <Button medium className="button danger" onClick={() => setShowRetakeModal(true)}>
                  Retake
                </Button>
                {!isMobile && !isFreeTrial && (
                  <Button
                    medium
                    className="button secondary"
                    onClick={downloadCertificate}
                    disabled={!succeed}
                  >
                    Unduh Sertifikat
                  </Button>
                )}
                <Button
                  medium
                  className="button"
                  onClick={() => history.push(`/training/${slug}/hasil`)}
                >
                  Lihat Hasil Training
                </Button> */}
              </>
            )}
          </Bottom>
        </Section>
      );
    }
  };

  return (
    <>
      {renderData()}
      {showRetakeModal && (
        <RetakeKuisModal
          isOpen={true}
          trainingId={trainingId}
          currentSection={{ training_chapter_id: location.state.sectionId }}
          onRequestClose={() => setShowRetakeModal(false)}
          submitRetake={retake}
          pushLocation={pushLocation}
          postTest
          submitRetakeTraining={retake}
          setShowRetakeModal={setShowRetakeModal}
        />
      )}
      <RetakeHabisModal
        isOpen={showRetakeHabis}
        router={history}
        maxRetake={true}
        onRequestClose={() => setShowRetakeHabis(false)}
        setShowRetakeHabis={setShowRetakeHabis}
      />
      {/*<IkutiKelasModal*/}
      {/*  quota={0}*/}
      {/*  freeTrial={true}*/}
      {/*  isOpen={showIkutiKelasModal}*/}
      {/*  onRequestClose={() => setShowIkutiKelasModal(false)}*/}
      {/*/>*/}
    </>
  );
};

export default TrainingSummary;
