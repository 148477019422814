import styled from 'styled-components';
import Button from '../../../components/atoms/Button';
import AnswerOption from '../../../components/atoms/AnswerOption';

export const MBTIPage = styled.div`
  padding: 32px;
  margin-top: 77px;
  background: white;
`;

export const MBTIContainer = styled.div`
  padding: 60px;
`;

export const TitleMBTI = styled.h1`
  margin: 0 0 16px;
  padding: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #141414;
`;

export const TextMBTI = styled.p`
  margin: 0;
  padding: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: left;

  color: #626262;
`;

export const BoldTextMBTI = styled.span`
  margin: 0;
  padding: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #141414;
`;

export const ProgressMBTI = styled.div`
  background: #e5e5e5;
  border-radius: 30px;
  height: 8px;
  width: 100%;
  margin-bottom: 12px;
`;

export const RunningProgressMBTI = styled.div`
  background: #187336;
  border-radius: 30px;
  height: 8px;
  width: ${(props) => props.progress + '%'};
`;

export const TestContainer = styled.div`
  margin-top: 32px;
  width: 100%;
`;

export const TestTopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonNext = styled(Button)`
  width: 400px;
  height: 50px;

  background: ${(props) => (props.disabled ? '#c4c4c4' : '#187336')};
  border-radius: 40px;
  color: white;

  :hover {
    background: ${(props) => (props.disabled ? '#c4c4c4' : '#2fb960')};
  }
`;

export const ListOrderQuestion = styled.ol`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  padding-left: 28px;

  color: #202020;

  li {
    margin-bottom: 32px;
  }
`;

export const PRKContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 48px;
  margin-bottom: -24px;
`;
