import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation funcCreateOrder($input: CreateOrderInput) {
    CreateOrder(input: $input) {
      id
      payment_method
      invoice_number
      va_number
      bill_key
      biller_code
      qr_code
      deep_link
      subscription_type
      total_price
      redirect_url
      due_date
      dana_recurring_url
      order_name
    }
  }
`;

export const CREATE_ORDER_EWALLET = gql`
  mutation createOrderEwallet($input: CreateOrderEwalletInput) {
    CreateOrderEwallet(input: $input) {
      id
      invoice_number
      price
      order_name
      quota
      start_date
      end_date
      due_date
      payment_date
      updated_at
      payment_method
      fullname
      card_number
      voucher_id
      voucher_code
      dana_recurring_url
      file {
        id
        filename
        link
      }
      subscription {
        id
        subscription_type
      }
    }
  }
`;

export const REQUEST_PAYMENT_EWALLET = gql`
  mutation requestPaymentEwallet($input: RequestPaymentEwalletInput) {
    RequestPaymentEwallet(input: $input) {
      id
      payment_method
      invoice_number
      va_number
      bill_key
      biller_code
      deep_link
      subscription_type
      total_price
      redirect_url
      due_date
      dana_recurring_url
    }
  }
`;
