import styled from 'styled-components';
import InputField from 'components/atoms/InputField';

export const Description = styled.text`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  margin-bottom: 16px;

  color: ${(props) => (props.lengkapiProfile ? '#c4c4c4' : '#626262')};
`;

export const CodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) => (props.lengkapiProfile ? '#c4c4c4' : 'black')};
  justify-content: center;
  align-items: center;
  font-size: 14px;

  span {
    flex: 1;
  }
`;

export const InputSingle = styled(InputField)`
  flex: 5;
  text-align: center;
  margin-bottom: 6px;
  color: ${(props) => props.lengkapiProfile && '#c4c4c4'};

  @media only screen and (max-width: 577px) {
    height: 42px;
  }
`;

export const TextBottom = styled.p`
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;

  color: #f18f01;
  margin: 20px 0 8px;
`;
