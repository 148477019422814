import styled from 'styled-components';
import Button from 'components/atoms/Button';

export const TabMetodeStyled = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 24px 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: none;
  }
`;

export const TabMetodeItem = styled(Button)`
  width: 220px;
  border-radius: 5px;
  border: ${(props) =>
    props.selected ? '2px solid ' + props.theme.color.primary : '1px solid #c4c4c4'};
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background-color: transparent;
  margin: 0;

  color: ${(props) => (props.selected ? props.theme.color.primary : '#c4c4c4')};

  :hover {
    color: ${(props) => props.theme.color.primary};
    border: ${(props) => '2px solid ' + props.theme.color.primary};
    background-color: transparent;
  }

  @media only screen and ${(props) => props.theme.breakpoints.md} {
    width: 172px;
    font-size: 13px;
  }
`;
