import React from 'react';
import { AdsTrainerStyled, Line, Section } from './AdsTrainer.styled';
import logo from 'assets/png/terampil-transparent 2.png';
import Button from 'components/atoms/Button';
import { checkMobile } from 'utils/checkMobile';
import MaxContainer from 'components/atoms/MaxContainer';
import { Link } from 'react-router-dom';
import { t } from 'hooks/intl/useLang';

const AdsTrainer = ({ prakerja, ...rest }) => {
  return (
    <Section {...rest} >
          {!checkMobile() && <Line />}
          <MaxContainer>
            <AdsTrainerStyled prakerja={prakerja}>
              <div className="first">
                <img className="imgFirst" src={logo} alt="logo terampil" />
                <h2>{t('Mari Berkolaborasi')}</h2>
                <p>
                  {t('Cari tahu bagaimana Terampil dapat memberikan solusi pengembangan karir untuk perusahaan Anda.')}
                </p>
                <Link to="/mari-berkolaborasi">
                  <Button className="button" outline rounded={true} medium>
                    {t('Lebih Lanjut')}
                  </Button>
                </Link>
              </div>
              <div className="second">
                {/* {!checkMobile() && <img className="imgSecond" src={logo} alt="logo terampil" />} */}
                <h2>{t('Menjadi Trainer')}</h2>
                <p>{t('Anda memiliki kualifikasi khusus dan ingin membagikan ilmu yang Anda miliki?')}</p>
                <Link to="/jadi-trainer">
                  <Button className="button" outline rounded={true} medium>
                    {t('Lebih Lanjut')}
                  </Button>
                </Link>
              </div>
            </AdsTrainerStyled>
          </MaxContainer>
    </Section>
  );
};

export default AdsTrainer;
