import styled from 'styled-components';

export const ContainerImage = styled.div`
  width: 70%;
  height: 650px;
  position: relative;
  box-sizing: border-box;

  /* @media screen and ${(props) => props.theme.breakpoints.lg} {
    right: 70px;
    width: 75%;
    height: 400px;
  } */

  @media screen and ${(props) => props.theme.breakpoints.md} {
    right: 40px;
    width: 100%;
    height: 400px;
    background-position: cover;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    height: 230px;
    background-position: cover;
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  top: 70px;
  left: 50px;
  width: 80%;
  height: 424px;
  background-color: #202020;
  border-radius: 10px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    position: relative;
    height: 324px;
    /* left: 100px; */
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    position: relative;
    width: 60%;
    height: 324px;
    left: 100px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    position: relative;
    width: 70%;
    height: 166px;
    left: 60px;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 650px;
  background-image: url('${(props) => props.image}');
  border-radius: 10px;
  background-size: contain;
  margin-top: 30px;
  background-repeat: no-repeat;
  /* 
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 80%;
    height: 550px;
  } */
  @media screen and ${(props) => props.theme.breakpoints.md} {
    right: 0px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    position: absolute;
    top: 20px;
    left: 20%;
    width: 85%;
    height: 195px;
    background-size: cover;
    box-sizing: border-box;
    border-radius: 5px;
  }
`;
