import styled from 'styled-components';

export const Text = styled.div`
  & > p {
    margin: 0;
    text-overflow: ellipsis;
    z-index: 1;
    max-width: 600ch;
    overflow: hidden;
  }
  margin: 16px 0 0 0;
  text-align: left;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #c4c4c4;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-bottom: 32px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-size: 14px;
  }
`;
