import React from 'react';
import { Content, Title, Paragraf } from './styled';

function RefundPolicy() {
  return (
    <>
      <Content>
        <Paragraf>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus enim, nec ut non pharetra
          cras. At dapibus fusce in nisl diam sed nec ultrices. Ornare pellentesque nisl, erat eros,
          justo id sed fringilla egestas. Cras mi enim donec odio fames leo commodo. Gravida urna
          ullamcorper auctor consectetur quisque viverra. Lacus aenean lorem morbi commodo sed eget
          duis massa. A adipiscing sed sed imperdiet ac nec ac aenean. Volutpat semper scelerisque
          leo accumsan odio. Elit sit fermentum sed adipiscing lectus congue at dolor. Volutpat
          ultrices diam nec mollis vitae, platea vitae.
        </Paragraf>
        <Paragraf>
          Varius mi natoque sed luctus condimentum turpis mattis. In cursus felis felis neque est
          facilisis. Dolor enim aenean non sed convallis tortor volutpat diam in. Est sagittis
          porttitor a facilisis quam viverra est. Sit condimentum morbi quis sed ut sapien.
          Tristique venenatis blandit sollicitudin convallis faucibus felis. Massa sit semper justo
          nibh morbi donec. Massa nec mauris at in. Mollis viverra lorem enim congue nam elit nec
          iaculis lorem. Viverra dolor nunc egestas in viverra id pellentesque eu. In nibh at dolor
          hendrerit. Egestas nisl donec integer sed.
        </Paragraf>
      </Content>
      <Content>
        <Title>a. Syarat</Title>
        <Paragraf>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus enim, nec ut non pharetra
          cras. At dapibus fusce in nisl diam sed nec ultrices. Ornare pellentesque nisl, erat eros,
          justo id sed fringilla egestas. Cras mi enim donec odio fames leo commodo. Gravida urna
          ullamcorper auctor consectetur quisque viverra. Lacus aenean lorem morbi commodo sed eget
          duis massa. A adipiscing sed sed imperdiet ac nec ac aenean. Volutpat semper scelerisque
          leo accumsan odio. Elit sit fermentum sed adipiscing lectus congue at dolor. Volutpat
          ultrices diam nec mollis vitae, platea vitae.
        </Paragraf>
      </Content>
      <Content>
        <Title>b. Ketentuan</Title>
        <Paragraf>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus enim, nec ut non pharetra
          cras. At dapibus fusce in nisl diam sed nec ultrices. Ornare pellentesque nisl, erat eros,
          justo id sed fringilla egestas. Cras mi enim donec odio fames leo commodo. Gravida urna
          ullamcorper auctor consectetur quisque viverra. Lacus aenean lorem morbi commodo sed eget
          duis massa. A adipiscing sed sed imperdiet ac nec ac aenean. Volutpat semper scelerisque
          leo accumsan odio. Elit sit fermentum sed adipiscing lectus congue at dolor. Volutpat
          ultrices diam nec mollis vitae, platea vitae.
        </Paragraf>
      </Content>
    </>
  );
}

export default RefundPolicy;
