import React, { useEffect, useState } from 'react';
import MaxContainer from 'components/atoms/MaxContainer';
import { checkMobile } from 'utils/checkMobile';
import { SliderControlPrimary } from 'components/atoms/Slider/Slider.styled';
import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';
import { CardContainer } from 'components/organisms/CategoryPopularTraining/CategoryPopularTraining.styled';
import CardYourTraining from 'components/molecule/Cards/CardYourTraining';
import Slider from 'components/atoms/Slider';
import { SwiperSlide } from 'swiper/react';
import { SliderPagination } from 'components/organisms/PopularTrainer/PopularTrainer.styled';
import TitleSection from 'components/molecule/TitleSectionHome';
import { YourTrainingSliderStyled, YourTrainingStyled } from './styled';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_USER_TRAININGS } from 'queries/training';
import { getValue, setContext } from 'utils';
import { GET_USER_DETAIL } from 'queries/user';
import { isDateExpired } from 'utils/date';
import { t } from 'hooks/intl/useLang';

function renderComponent(data, expiredFreeTrial) {
  if (!checkMobile()) {
    return (
      <YourTrainingSliderStyled>
        <div className="slider">
          <div className="button-slider">
            <SliderControlPrimary className="prev-primary" id="prev-your-training">
              <PrevIcon />
            </SliderControlPrimary>

            <SliderControlPrimary className="next-primary" id="next-your-training">
              <NextIcon />
            </SliderControlPrimary>
          </div>

          <CardContainer>
            <Slider
              next="next-your-training"
              prev="prev-your-training"
              pagination="page-your-training"
              count={2}
            >
              {data?.UserTrainings?.items?.map((training, index) => {
                return (
                  <SwiperSlide key={index}>
                    <CardYourTraining
                      training={training?.training}
                      items={training}
                      expiredFreeTrial={expiredFreeTrial}
                    />
                  </SwiperSlide>
                );
              })}
            </Slider>
          </CardContainer>
        </div>

        <SliderPagination id="page-your-training" />
      </YourTrainingSliderStyled>
    );
  } else {
    return (
      <YourTrainingSliderStyled>
        <div className="overlay">
          <div className="inner">
            {data?.UserTrainings?.items?.map((training, index) => {
              return (
                <CardYourTraining
                  training={training?.training}
                  items={training}
                  key={index}
                  expiredFreeTrial={expiredFreeTrial}
                />
              );
            })}
          </div>
        </div>
      </YourTrainingSliderStyled>
    );
  }
}

function YourTraining() {
  const [expiredFreeTrial, setExpiredFreeTrial] = useState(false);

  const authData = useSelector((state) => state.Auth);

  const { data } = useQuery(GET_USER_TRAININGS, {
    context: setContext(authData),
    onError: () => ({}),
  });

  const { data: userTrainingDetail } = useQuery(GET_USER_DETAIL, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  useEffect(() => {
    setExpiredFreeTrial(isDateExpired(userTrainingDetail?.ProfileUser?.subscription_end_date));
  }, [userTrainingDetail]);
  return (
    <YourTrainingStyled data={data?.UserTrainings?.items.length > 0}>
      {data?.UserTrainings && data?.UserTrainings?.items.length > 0 && (
        <MaxContainer>
          <TitleSection destination="/pelatihan-saya" seeOther={t("Pelatihan Saya")}>
            {t('Selesaikan Training Kamu')}
          </TitleSection>
          {renderComponent(data, expiredFreeTrial)}
        </MaxContainer>
      )}
    </YourTrainingStyled>
  );
}

export default YourTraining;
