import React from 'react';
import MaxContainer from 'components/atoms/MaxContainer';
import { Container, Title, Flex } from './styled';
import { ReactComponent as ActiveIcon } from 'assets/svg/newterampil/active.svg';
import { ReactComponent as EntrepreneurIcon } from 'assets/svg/newterampil/dna.svg';
import { ReactComponent as ModuleIcon } from 'assets/svg/newterampil/module.svg';

const dataPlatform = [
  {
    title: 'Hybrid Learning: Intra-Active & Inter-Active',
    text: 'Intra-Active adalah metode yang dirancang untuk mendorong Terampil People lebih aktif dalam meningkatkan kapasitas dirinya, sedangkan Inter-Active adalah metode yang memungkinkan Terampil People untuk berperan secara aktif berinteraksi langsung dengan Trainer dan dengan Terampil People lainnya di Forum Diskusi dan Live Q&A.',
    icon: <ActiveIcon />,
  },
  {
    title: 'Intrapreneurship and Beyond',
    text: 'Setiap materi pelatihan dirancang berdasarkan nilai-nilai entrepreneurship sehingga siapapun yang belajar di Terampil akan mampu memotivasi dirinya sendiri, proaktif, dan berorientasi pada tindakan yang mengambil inisiatif untuk menunjang karir dan masa depan.',
    icon: <EntrepreneurIcon />,
  },
  {
    title: 'Practical and Proven Skills',
    text: 'Setiap modul pelatihan di Terampil bukan teori, melainkan dapat langsung diterapkan secara efektif dan efisien di lingkup profesional ataupun bisnis.',
    icon: <ModuleIcon />,
  },
];

const PlatfromTerbaik = () => {
  return (
    <Container>
      <MaxContainer>
        <Title>Platform Pelatihan Terbaik</Title>
        <Flex>
          {dataPlatform.map((item, i) => {
            return (
              <div>
                {item.icon}
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
            );
          })}
        </Flex>
      </MaxContainer>
    </Container>
  );
};

export default PlatfromTerbaik;
