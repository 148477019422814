import styled from 'styled-components';
import { mq } from '../../../assets/styles/theme';

export const PopularSection = styled.section`
  width: 100%;
  padding: 50px 0;
  background-color: #141414;

  ${mq({
    padding: [null, null, '20px 0', '20px 0', '16px 0'],
  })}
`
