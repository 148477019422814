import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';

import { CREATE_WEB_TRAFFIC } from 'mutation/analytics';
import { DeviceUUID } from 'device-uuid';
import { useMutation } from '@apollo/client';

import PrivateRoute from 'components/atoms/PrivateRoute';
import LoadingEllipsis from 'components/atoms/Loading/LoadingEllipsis';
import LoadingDefaultLogo from 'components/atoms/Loading/LoadingDefaultLogo';
import DetailWebinar from './DetailWebinar';
import ReferralRedirect from './ReferralRedirect';
import B2BLogin from './B2BLogin';
import B2BPassword from './B2BPassword';
import FeedbackWebinar from './FeedbackWebinar';
import TMBLandingPage from './MinatTesPage/Landing';
import PanduanTBM from './MinatTesPage/PanduanTBM';
import MBTITest from './MinatTesPage/MBTITest';
import ResultMBTI from './MinatTesPage/ResultMBTI';
/*
   CODE SPLITTING ROUTE BASED (SLOWER BUT SMALLER BUNDLE SIZE)
*/
const LandingPage = loadable(() => import('./LandingPage'), {
  fallback: <LoadingEllipsis />,
});
const RegisterPage = loadable(() => import('./RegisterPage'), {
  fallback: <LoadingEllipsis />,
});
const KonfirmasiEmailPage = loadable(() => import('./KonfirmasiPage'), {
  fallback: <LoadingEllipsis />,
});
const PreferencePage = loadable(() => import('./PreferencePage'), {
  fallback: <LoadingEllipsis />,
});
const LoginPage = loadable(() => import('./LoginPage'), { fallback: <LoadingEllipsis /> });
const HomePage = loadable(() => pMinDelay(import('./HomePage'), 1000), {
  fallback: <LoadingEllipsis />,
});
const TrainingsPage = loadable(() => import('./TrainingsPage'), { fallback: <LoadingEllipsis /> });
const TrainersPage = loadable(() => import('./TrainersPage'), { fallback: <LoadingEllipsis /> });
const JadiTrainerPage = loadable(() => import('pages/JadiTrainerPage'), {
  fallback: <LoadingEllipsis />,
});
const DaftarJadiTrainerPage = loadable(() => import('pages/DaftarJadiTrainer'), {
  fallback: <LoadingEllipsis />,
});
const ProfileTrainerPage = loadable(() => import('pages/ProfileTrainer'), {
  fallback: <LoadingEllipsis />,
});
const UserPage = loadable(() => import('./UserPage'), { fallback: <LoadingEllipsis /> });
const SettingOptionsPage = loadable(() => import('./SettingOptionPage'), {
  fallback: <LoadingEllipsis />,
});
const MariBerkolaborasiPage = loadable(() => import('pages/MariBerkolaborasi'), {
  fallback: <LoadingEllipsis />,
});
const PelatihanSayaPage = loadable(() => import('pages/PelatihanSaya'), {
  fallback: <LoadingEllipsis />,
});
const UserSettingsPage = loadable(() => import('./UserSettingsPage'), {
  fallback: <LoadingEllipsis />,
});
const StartSubsPage = loadable(() => import('./StartSubsPage'), { fallback: <LoadingEllipsis /> });
const TrainingPage = loadable(() => import('pages/TrainingDetailPage/'), {
  fallback: <LoadingEllipsis />,
});
const PaymentPage = loadable(() => import('./PaymentPage'), { fallback: <LoadingEllipsis /> });
const CreditCardMobile = loadable(() => import('./CreditCardMobile'), {
  fallback: <LoadingEllipsis />,
});
const RincianTransferVAPage = loadable(() => import('pages/PaymentPage/RincianTransferVA/'), {
  fallback: <LoadingEllipsis />,
});
const RincianKartuKredit = loadable(() => import('pages/PaymentPage/RincianKartuKredit'), {
  fallback: <LoadingEllipsis />,
});
const RincianDompetOnline = loadable(() => import('pages/PaymentPage/RincianDompetOnline/'), {
  fallback: <LoadingEllipsis />,
});
const BlogTerampil = loadable(() => import('./Blog'), { fallback: <LoadingEllipsis /> });
const TentangKami = loadable(() => import('pages/TentangKami'), { fallback: <LoadingEllipsis /> });
const InvoicePage = loadable(() => import('pages/InvoicePage'), { fallback: <LoadingEllipsis /> });
const BantuanPage = loadable(() => import('./BantuanPage'), { fallback: <LoadingEllipsis /> });
const PrakerjaPage = loadable(() => import('./PrakerjaPage'), { fallback: <LoadingEllipsis /> });
const KebijakanPrivasiPage = loadable(() => import('./KebijakanPrivasiPage'), {
  fallback: <LoadingEllipsis />,
});
const SyaratKetentuanPage = loadable(() => import('./SyaratKetentuanPage'), {
  fallback: <LoadingEllipsis />,
});
const RefundPolicyPage = loadable(() => import('./RefundPolicyPage'), {
  fallback: <LoadingEllipsis />,
});
const FAQPage = loadable(() => import('./FAQPage'), { fallback: <LoadingEllipsis /> });
const ResetPasswordPage = loadable(() => import('./ResetPasswordPage'), {
  fallback: <LoadingEllipsis />,
});
const DetailPembayaran = loadable(() => import('./DetailPembayaran'), {
  fallback: <LoadingEllipsis />,
});
const SelesaiTraining = loadable(() => import('./SelesaiTraining'), {
  fallback: <LoadingEllipsis />,
});
const NotifikasiPage = loadable(() => import('./NotifikasiPage'), {
  fallback: <LoadingEllipsis />,
});
const VerifyCertificate = loadable(() => import('./VerifyCertificate'), {
  fallback: <LoadingEllipsis />,
});
const DetailBanner = loadable(() => import('./DetailBannerPage'), {
  fallback: <LoadingEllipsis />,
});
const BlogDetails = loadable(() => import('./BlogDetails'), {
  fallback: <LoadingEllipsis />,
});

const BlogPages = loadable(() => import('./BlogPages'), {
  fallback: <LoadingEllipsis />,
});
const Referral = loadable(() => import('./ReferralPage'), {
  fallback: <LoadingEllipsis />,
});
const Event = loadable(() => import('./Event'), {
  fallback: <LoadingEllipsis />,
});
const NewTerampilPage = loadable(() => import('./NewTerampilPage'), {
  fallback: <LoadingEllipsis />,
});
const PilihLangganan = loadable(() => import('./PilihLanganan'), {
  fallback: <LoadingEllipsis />,
});
const SharedCodePage = loadable(() => import('./SharedCodePage'), {
  fallback: <LoadingEllipsis />,
});

const UndangTemanPage = loadable(() => import('./UndangTemanPage'), {
  fallback: <LoadingEllipsis />,
});

const DiscTestPage = loadable(() => import('./DiscTestPage'), {
  fallback: <LoadingEllipsis />,
});

const DiscResult = loadable(() => import('./DiscResultPage'), {
  fallback: <LoadingEllipsis />,
});

const WebinarLivePage = loadable(() => import('./WebinarLive'), {
  fallback: <LoadingEllipsis />,
});

const TrainingDetailsChoice = loadable(() => import('./TrainingDetailsChoice'), {
  fallback: <LoadingEllipsis />,
});

const CheckVoucherBeforeLogin = loadable(() => import('./CheckVoucherBeforeLogin'), {
  fallback: <LoadingEllipsis />,
});

const CampaignPage = loadable(() => import('./CampaignPage/PersonalBranding'), {
  fallback: <LoadingEllipsis />,
});

const ToBeEnterepreneur = loadable(() => import('./CampaignPage/ToBeEntrepreneur'), {
  fallback: <LoadingEllipsis />,
});

const CampaignEnterepreneur = loadable(() => import('./CampaignPage/CampaignEnterepreneur'), {
  fallback: <LoadingEllipsis />,
});

const BundlingPageToBeEntepreneur = loadable(() => import('./BundlingPage/ToBeEnterepreneurYGP'), {
  fallback: <LoadingEllipsis />,
});

const BundlingPageEntepreneur = loadable(() => import('./BundlingPage/EnterepreneurYGP'), {
  fallback: <LoadingEllipsis />,
});

const WelcomeTerampilPage = loadable(() => import('./WelcomePage'), {
  fallback: <LoadingEllipsis />,
});

const RatingUlasanTrainerTrainingPage = loadable(() => import('./TrainingDetailPage/UlasanPage'), {
  fallback: <LoadingEllipsis />,
});

const B2bLandingPagePrimary = loadable(() => import('./B2BLandingPage/PrimaryLanding'), {
  fallback: <LoadingEllipsis />,
});

const PaymentPageV2 = loadable(() => import('./PaymentNewPage'), {
  fallback: <LoadingEllipsis />,
});

const B2bRedirectPage = loadable(() => import('./B2BRedirect'), {
  fallback: <LoadingDefaultLogo />,
});

const RegisterAssessmentPage = loadable(() => import('./RegisterAssessment'), {
  fallback: <LoadingDefaultLogo />,
});

const KlinikPintar = loadable(() => import('./KlinikPintar'), {
  fallback: <LoadingEllipsis />,
});

const QasirPage = loadable(() => import('./Qasir'), {
  fallback: <LoadingEllipsis />,
});

const Page = () => {
  const [createWebTraffic] = useMutation(CREATE_WEB_TRAFFIC, {
    onError: () => ({}),
  });

  useEffect(() => {
    const uuid = new DeviceUUID().get();
    createWebTraffic({
      variables: {
        uniqueId: uuid,
      },
    });
  }, []);

  return (
    <Switch>
      <PrivateRoute
        exact
        default
        path="/"
        args={{ notAuth: true }}
        redirectTo="/home"
        component={LandingPage}
      />
      <PrivateRoute args={{ auth: true }} exact path="/home" component={HomePage} />
      <PrivateRoute
        exact
        path="/masuk"
        redirectTo="/home"
        args={{ notAuth: true, redirectToApps: true }}
        component={LoginPage}
      />
      <Route exact path="/daftar-jadi-trainer" component={DaftarJadiTrainerPage} />
      <PrivateRoute
        exact
        path="/registrasi"
        component={RegisterPage}
        redirectTo="/home"
        args={{ notAuth: true, redirectToApps: false }}
      />
      {/* <PrivateRoute args={{ auth: true }} path="/referral" component={Referral} /> */}
      <PrivateRoute args={{ auth: true }} path="/shared-code" component={SharedCodePage} />
      <Route exact path="/prakerja" component={PrakerjaPage} />
      <PrivateRoute args={{ auth: true }} path="/pelatihan-saya" component={PelatihanSayaPage} />
      {/* <Route path="/berlangganan2" component={StartSubsPage} /> */}
      <Route path="/berlangganan" component={PilihLangganan} />
      <Route path="/verify" component={KonfirmasiEmailPage} />
      <Route exact path="/jadi-trainer" component={JadiTrainerPage} />
      <PrivateRoute
        args={{ auth: true }}
        path="/training/rating-ulasan/:slug"
        component={RatingUlasanTrainerTrainingPage}
      />
      <Route path="/training/selesai/:slug" component={SelesaiTraining} />
      <Route path="/training/:slug" component={TrainingDetailsChoice} />
      <Route path="/trainer/:slug" component={ProfileTrainerPage} />
      <PrivateRoute
        args={{ preference: true }}
        path="/user/preferensi"
        component={PreferencePage}
      />
      <PrivateRoute
        exact
        path="/user/settings"
        args={{ checkMobile: true }}
        redirectTo="/user/settings/profil"
        component={SettingOptionsPage}
      />

      <PrivateRoute
        args={{ auth: true }}
        exact
        path="/user/invoice/:param"
        component={InvoicePage}
      />
      <PrivateRoute
        args={{ auth: true }}
        path="/user/settings/:param"
        component={UserSettingsPage}
      />

      <PrivateRoute exact args={{ auth: true }} path="/user/:slug" component={UserPage} />
      <Route path="/mari-berkolaborasi" component={MariBerkolaborasiPage} />
      <Route exact path="/trainings" component={TrainingsPage} />
      <Route exact path="/trainers" component={TrainersPage} />
      <PrivateRoute
        exact
        path="/pembayaran/kredit"
        args={{ checkMobile: true }}
        redirectTo="/pembayaran"
        component={CreditCardMobile}
      />
      <PrivateRoute
        args={{ auth: true }}
        exact
        path="/pembayaran/:name"
        component={PaymentPageV2}
      />
      <PrivateRoute
        args={{ auth: true }}
        exact
        path="/pembayaran/webinar/:roomId"
        component={PaymentPageV2}
      />
      <PrivateRoute
        args={{ auth: true }}
        path="/rincian-transfer/:param"
        component={RincianTransferVAPage}
      />
      <PrivateRoute
        args={{ auth: true }}
        exact
        path="/rincian-kartu-kredit/:param"
        component={RincianKartuKredit}
      />
      <PrivateRoute
        args={{ auth: true }}
        exact
        path="/rincian-online-payment/:param"
        component={RincianDompetOnline}
      />

      <Route exact path="/tentang-kami" component={TentangKami} />
      <Route exact path="/faq/:param" component={FAQPage} />
      <Route exact path="/bantuan" component={BantuanPage} />
      <Route exact path="/kebijakan-privasi" component={KebijakanPrivasiPage} />
      <Route exact path="/syarat-ketentuan" component={SyaratKetentuanPage} />
      <Route exact path="/kebijakan-refund" component={RefundPolicyPage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <PrivateRoute
        exact
        args={{ auth: true }}
        path="/detail-riwayat-pembayaran/:params"
        component={DetailPembayaran}
      />
      <PrivateRoute
        exact
        args={{ checkMobile: true }}
        path="/notifikasi"
        component={NotifikasiPage}
      />
      <Route path="/verify-certificate/:param" component={VerifyCertificate} />
      <PrivateRoute
        redirectTo="/home"
        args={{ notAuth: true }}
        path="/banner/:param"
        component={DetailBanner}
      />
      <Route exact path="/blog" component={BlogTerampil} />
      <Route path="/blog/:slug" component={BlogDetails} />
      <Route path="/blogs" component={BlogPages} />
      <Route path="/new-terampil" component={NewTerampilPage} />
      <Route path="/undang-teman" component={UndangTemanPage} />
      <PrivateRoute exact args={{ auth: true }} path="/disc-test" component={DiscTestPage} />
      <PrivateRoute exact args={{ auth: true }} path="/disc-result" component={DiscResult} />
      <PrivateRoute path="/webinar/live/:roomId" component={WebinarLivePage} />
      <Route path="/webinar/detail/:roomId" component={DetailWebinar} />
      <PrivateRoute path="/webinar/feedback/:roomId" component={FeedbackWebinar} />
      <Route path="/check-voucher" component={CheckVoucherBeforeLogin} />
      <Route path="/campaign-page" component={CampaignPage} />
      <Route path="/to-be-enterepreneur" component={ToBeEnterepreneur} />
      <Route path="/campaign-enterepreneur" component={CampaignEnterepreneur} />
      <Route path="/training-to-be-enterepreneur" component={BundlingPageToBeEntepreneur} />
      <Route path="/training-enterepreneur" component={BundlingPageEntepreneur} />
      <PrivateRoute
        exact
        args={{ auth: true, redirectToApps: false }}
        path="/welcome-terampil"
        accessFromMobile={true}
        component={WelcomeTerampilPage}
      />
      <Route path="/business-page" component={B2bLandingPagePrimary} />
      <PrivateRoute
        path="/redirect-b2b"
        component={B2bRedirectPage}
        redirectTo="/home"
        args={{ notAuth: true }}
      />
      <Route path="/registrasi-assessment" component={RegisterAssessmentPage} />
      <Route path="/klinik-pintar" component={KlinikPintar} />
      <Route path="/login-b2b" component={B2BLogin} />
      <Route path="/login-email" component={() => <B2BLogin regular />} />
      <Route path="/password-b2b" component={B2BPassword} />
      <Route path="/password-input" component={() => <B2BPassword regular />} />
      <Route exact path="/gratyo" component={ReferralRedirect} />
      <Route exact path="/qasir" component={QasirPage} />
      <Route exact path="/event" component={Event} />
      <Route exact path="/aptitude-test" component={TMBLandingPage} />
      <PrivateRoute exact path="/tes-potensi-karir" component={PanduanTBM} />
      <PrivateRoute exact path="/tes-potensi-karir/mulai" component={MBTITest} />
      <PrivateRoute exact path="/tes-potensi-karir/hasil" component={ResultMBTI} />
    </Switch>
  );
};
export default Page;
