/* eslint-disable no-unused-vars */
import loadable from '@loadable/component';

import ForumBubble from 'components/atoms/ForumBubble';
import React, { useEffect, useState } from 'react';
import {
  Container,
  Title,
  ChatContainer,
  CardContainer,
  ContainerSlide,
  ButtonSlider,
} from './styled';
import { SliderControlPrimary } from 'components/atoms/Slider/Slider.styled';
import MaxContainer from '../../atoms/MaxContainer';
import { GET_MOST_ACTIVE_FORUM } from 'queries/mostactiveforum';
import { useQuery } from '@apollo/client';
import UserDefault from 'assets/svg/User Default.svg';
import image1 from 'assets/png/forumterakhir/image1.png';
import image2 from 'assets/png/forumterakhir/image2.png';
import image3 from 'assets/png/forumterakhir/image3.png';
import image4 from 'assets/png/forumterakhir/image4.png';
import { checkMobile } from 'utils/checkMobile';
import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';

import Player from 'components/etc/Player';
import { SwiperSlide } from 'swiper/react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import CardForumTeraktif from 'components/molecule/Cards/CardForumTeraktif';
import { SliderPagination } from 'components/organisms/PopularTrainer/PopularTrainer.styled';
import { BlackBackgriundImage } from 'components/molecule/Cards/CardForumTeraktif/styled';
import { t } from 'hooks/intl/useLang';
const Slider = loadable(() => import('components/atoms/Slider'));
// Mau nanya, kalau mimpi ada nilanya, tetapi itu produk gak diminati market? itu gimana ya?
// Hmm nice question mba Rani. Kalau menurut saya, edukasi produk itu yang terpenting, shg market pun pelan2 bisa mengetahui nilai dari produk tersebut. Ini mungkin sering terjadi pada startup2 yg marketnya masih ngawang.
// Kalau menurut saya ini kembali lagi ke Strategi Business nya, kalau memang Market Opportunity nya bagus berarti harus mengedukasi market, atau bisa juga pilih market yang sudah jelas ada sehingga produknya juga banyak peminatnya.
// Kalau menurut saya seharusnya mungkin bisa riset terlebih dahulu produk seperti apa yang diinginkan oleh masyarakat, agar business plannya lebih jelas dan produknya diminati.

const data = [
  {
    img: 'https://public.terampil.com/assets/images/forum_DrVidi+1.png',
    datas: [
      {
        image: image1,
        message:
          t('Sebagai seorang leader saya sering banget mengalami kesulitan ketika mengarahkan team. Training ini membantu saya banget untuk paham tentang leadership. Saya ada 1 pertanyaan, menurut kalian gaya kepemimpinan itu baiknya disesuaikan dengan team atau bagaimana?'),
      },
      {
        image: image2,
        message: t('Kalau nurut saya sih sesuai team, karena kita manage people'),
      },
      {
        image: image3,
        message:
          t('Gaya kepemimpinan orang berbeda, tapi sebagai leader harus cerdas baca situasi & baca team, harus bisa menyesuaikan diri juga, shg tidak ada miscom yang terjadi'),
      },
      {
        image: image4,
        message:
          t('Kalau menurut saya gaya kepemimpinan disesuaikan dengan team, karena bagaimana pun juga yang menjalankan misi adalah team.'),
      },
    ],
  },
  {
    img: 'https://public.terampil.com/assets/images/forum_Jejouw.png',
    datas: [
      {
        image: 'https://public.terampil.com/assets/images/jejuo/Default+Cowo+1.png',
        message:
          t('Good training anw, untuk bisnis retail dengan segmentasi anak muda, sebaiknya socmed apa aja yg digunakan ya? Apakah ada insight dari teman2?'),
      },
      {
        image: 'https://public.terampil.com/assets/images/jejuo/Ellipse+103.png',
        message:
          t('Kalau menurut saya untuk segmentasi anak muda saat ini platform yang cocok ada Instagram dan Tiktok, Instagram bisa untuk foto atau branding produk dan Tiktok lebih ke Content Engagement.'),
      },
      {
        image: 'https://public.terampil.com/assets/images/jejuo/Ellipse+105.png',
        message:
          t('Kalau menurut saya socmed seperti Facebook Marketplace, Instagram dan Tiktok cocok untuk segmentasi anak muda. Yang terpenting bagaimana memaksimalkan social media tersebut dengan target yang dituju.'),
      },
      {
        image: 'https://public.terampil.com/assets/images/jejuo/Ellipse+107.png',
        message: t('FB, IG sih itu udah fit banget.'),
      },
    ],
  },
  {
    img: 'https://public.terampil.com/assets/images/forum_JamesGwee.png',
    datas: [
      {
        image: 'https://public.terampil.com/assets/images/jamesgwe/Ellipse+102.png',
        message:
          t('Mau nanya, kalau mimpi ada nilanya, tetapi itu produk gak diminati market? itu gimana ya?'),
      },
      {
        image: 'https://public.terampil.com/assets/images/jamesgwe/Ellipse+106.png',
        message:
          t('Hmm nice question mba Rani. Kalau menurut saya, edukasi produk itu yang terpenting, shg market pun pelan2 bisa mengetahui nilai dari produk tersebut. Ini mungkin sering terjadi pada startup2 yg marketnya masih ngawang.'),
      },
      {
        image: 'https://public.terampil.com/assets/images/jamesgwe/Ellipse+108.png',
        message:
          t('Kalau menurut saya ini kembali lagi ke Strategi Business nya, kalau memang Market Opportunity nya bagus berarti harus mengedukasi market, atau bisa juga pilih market yang sudah jelas ada sehingga produknya juga banyak peminatnya.'),
      },
      {
        image: 'https://public.terampil.com/assets/images/jamesgwe/Ellipse+109.png',
        message:
          t('Kalau menurut saya seharusnya mungkin bisa riset terlebih dahulu produk seperti apa yang diinginkan oleh masyarakat, agar business plannya lebih jelas dan produknya diminati.'),
      },
    ],
  },
];
const ForumTeraktif = ({ home, scrollPosition }) => {
  const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const [isMobile, setCheckMobile] = useState(false);

  useEffect(() => {
    setCheckMobile(checkMobile());
  }, []);

  const { data: forumData } = useQuery(GET_MOST_ACTIVE_FORUM);

  return (
    <Container home={home}>
      <MaxContainer>
        <Title home={home}>{t('Forum Teraktif Bulan Ini')}</Title>
        {/* <div className="content"> */}
        {/* <div style={{ width: isMobile ? '100%' : '70%', marginLeft: 10, borderRadius: '5px' }}>
            <Player
              manifestUrl={
                is_safari
                  ? 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/forum/hls/master.m3u8'
                  : 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/forum/no-dash/index.mpd'
              }
              licenseServer={
                'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=5BC068'
              }
              autoPlay={true}
              muted
              loop
              fullScreen={false}
              showControls={false}
              style={
                isMobile
                  ? {
                      height: '250px',
                      width: '100%',
                    }
                  : {
                      height: 'auto',
                      width: '90%',
                      maxHeight: '433px',
                    }
              }
            />
          </div> */}
        {/* <ChatContainer>
            {data
              .slice(0, 4)
              .map((d, i) =>
                (i + 1) % 2 !== 0 ? (
                  <ForumBubble
                    src={d.image || UserDefault}
                    msg={d.message}
                    key={i}
                    className="left"
                  />
                ) : (
                  <ForumBubble
                    src={d.image || UserDefault}
                    msg={d.message}
                    key={i}
                    className="right"
                  />
                ),
              )}
          </ChatContainer> */}
        <ContainerSlide home={home}>
          <ButtonSlider className="slider">
            <SliderControlPrimary className="prev-primary" id="prev-forumteraktif">
              <PrevIcon />
            </SliderControlPrimary>
            <SliderControlPrimary className="next-primary" id="next-forumteraktif">
              <NextIcon />
            </SliderControlPrimary>
          </ButtonSlider>

          <CardContainer>
            <Slider
              next="next-forumteraktif"
              prev="prev-forumteraktif"
              pagination="forumteraktif"
              count={1}
            >
              {data.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <LazyLoadComponent scrollPosition={scrollPosition}>
                      <CardForumTeraktif data={item} />
                      <BlackBackgriundImage />
                    </LazyLoadComponent>
                  </SwiperSlide>
                );
              })}
            </Slider>
          </CardContainer>
        </ContainerSlide>
        {/* </div> */}
        <SliderPagination id="forumteraktif" />
      </MaxContainer>
    </Container>
  );
};

export default ForumTeraktif;
