import styled from 'styled-components';

export const Contianer = styled.section`
  background-color: #141414;
  display: flex;
  justify-content: center;
`;

export const CardBottom = styled.div`
  width: 600px;
  height: auto;
  border-radius: 10px;
  background-color: #202020;
  margin-top: 24px;
  font-size: 24px;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;

  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin: 0;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
`;
