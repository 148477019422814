import styled from 'styled-components';
import { mq } from 'assets/styles/theme';

export const Section = styled.section`
  position: ${(props) => (props.prakerja ? 'absolute' : 'relative')};
`;

export const AdsTrainerStyled = styled.section`
  display: flex;
  background: linear-gradient(90.08deg, #0cc375 0.07%, #187336 100.9%);
  margin: 80px 100px;
  margin-top: ${(props) => (props.prakerja ? '-138px' : '60px')};
  border-radius: 20px;
  flex-direction: row;
  padding: 40px 100px;

  ${mq({
    flexDirection: [null, null, null, null, 'column'],
    margin: [null, null, null, '80px 40px', '20px 24px'],
    padding: [null, null, null, '40px 40px', '0'],
    borderRadius: [null, null, null, null, '5px'],
    background: [null, null, null, null, 'linear-gradient(90deg, #00E099 0%, #00DEBF 100%)'],
  })}
  h2 {
    flex: 1;
    font-family: Archivo, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #101010;
    float: left;
    text-align: left;
    z-index: 2;
    margin: 0;

    ${mq({
      fontSize: [null, null, null, null, '18px'],
      marginBottom: [null, null, null, null, '9px'],
      marginTop: [null, null, null, null, '16px'],
    })}
  }

  p {
    flex: 4;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 31px;
    float: left;
    color: #101010;
    z-index: 2;
    text-align: left;
    margin: 23px 0;

    ${mq({
      fontSize: [null, null, null, null, '12px'],
      lineHeight: [null, null, null, null, '18px'],
      margin: [null, null, null, null, '0 0 16px'],
    })}
  }

  .first {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right-color: #000004;
    border-right-width: 1px;
    border-right-style: solid;
    padding-right: 48px;

    ${mq({
      borderRightWidth: [null, null, null, null, '0'],
      borderBottomWidth: [null, null, null, null, '1px'],
      borderBottomStyle: [null, null, null, null, 'solid'],
      borderBottomColor: [null, null, null, null, '#039735'],
      padding: [null, null, null, null, '0 20px 16px'],
    })}
  }

  .second {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 48px;
    position: relative;
    ${mq({
      paddingLeft: [null, null, null, null, '0'],
      padding: [null, null, null, null, '0 20px 16px'],
    })}
  }

  .imgFirst {
    position: absolute;
    width: 88px;
    height: 128px;
    left: -69px;
    top: 0;

    ${mq({
      left: [null, null, null, '-20px', '75%'],
      top: [null, null, null, null, '58px'],
      width: [null, null, null, null, '50px'],
      height: [null, null, null, null, '70px'],
    })}
  }

  .imgSecond {
    position: absolute;
    width: 88px;
    height: 128px;
    right: 23px;
    bottom: 0;
  }

  .button {
    float: left;
    transition: all 0.2s ease-in-out;
    margin: 0;

    &:hover {
      border-width: 2px;
    }

    ${mq({
      width: [null, null, '120px', '120px', '120px'],
      fontSize: [null, null, null, null, '14px'],
    })}
  }
`;

export const Line = styled.hr`
  position: absolute;
  width: 100%;
  border-color: #626262;
  bottom: 100px;
  z-index: -1;
  box-sizing: border-box;
`;
