import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/';

export const Container = styled.div`
  padding: 20px 290px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and ${(props) => props.theme.breakpoints.xl} {
    padding: 10px 240px 0px;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 10px 140px 0px;
  }
  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 10px 110px 0px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0px 16px 0px;
  }
`;

export const ContainerInput = styled.div.attrs((props) => ({
  border: props.danger ? 'red' : '#626262',
}))`
  display: flex;
  flex-direction: column;
  margin: 0;

  .phone {
    border-color: ${(props) => props.border};
    color: #c4c4c4;
    flex: 4;
  }

  .input {
    border-color: ${(props) => props.border};
    color: #c4c4c4;
    box-sizing: border-box;
  }
`;

export const Button = styled(ButtonStyled)`
  width: 100%;
  margin: 0;
  height: 50px;
  font-size: 16px;
  margin-top: 32px;
`;
