import styled from 'styled-components';

export const RatingFilterItem = styled.div`
  padding: 0 24px;
  display: flex;
  align-items: center;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const CheckRadioIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 0;
  }
`;

export const ContainerRating = styled.div`
  display: flex;
  align-items: center;
`;

export const RatingFilterLabel = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #c4c4c4;
`;

export const StarIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`;
