import styled from 'styled-components';
import { mq } from 'assets/styles/theme';
import Button from '../../atoms/Button';
import MaxContainer from '../../atoms/MaxContainer';

export const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 77px;
  padding: 15px 100px 14px 100px;
  background-color: ${(props) => (props.isScrolled ? '#00000499' : 'transparent')};
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  -webkit-backface-visibility: hidden;
  z-index: 100;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }

  &.log-in {
    background: #141414;
    padding: 15px 50px 14px 50px;
  }

  &.sticky {
    position: sticky;
  }

  .image {
    width: 137px;
    height: 54px;
    object-fit: contain;

    @media screen and ${({ theme }) => theme.breakpoints.md} {
      margin-right: 12px;
    }
  }

  ${mq({
    padding: [
      '15px 100px 14px 100px',
      null,
      '15px 60px 14px 60px',
      '15px 30px 14px 30px',
      '15px 15px 14px 15px',
    ],
    '&.log-in': {
      padding: [null, null, null, '15px 0px 14px 30px', '15px 0px 14px 15px'],
    },
  })}
`;

export const Image = styled.img`
  width: 137px;
  height: 54px;
  margin: 11px 30px 12px 0;
  object-fit: contain;

  @media screen and ${({ theme }) => theme.breakpoints.md} {
    margin-right: 12px;
  }
`;

export const Container = styled(MaxContainer)`
  height: inherit;
  margin: 0 auto;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  &.log-in {
    justify-content: flex-start;
  }

  & > div {
    display: flex;
  }

  .header-link {
    min-width: 120px;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
    gap: 8px;

    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .header-link-logged-in {
    min-width: 260px;
    display: flex;
    justify-content: space-around;
    gap: 8px;
    align-items: center;
  }

  .logo-link {
    display: flex;
    align-items: center;
  }

  .header-button {
    flex-shrink: 0;
    margin: 0;
  }

  ${mq({
    '.header-link': {
      minWidth: ['180px', null, null, '160px'],
      display: ['flex', null, null, 'none'],
    },
    '.header-link-logged-in': {
      display: ['flex', null, null, 'none'],
    },
  })}
`;

export const LoadingButton = styled.div`
  height: 45px;

  width: 150px;
  border-radius: 5px;

  animation: pulse 1s infinite;
  animation-direction: alternate;

  @keyframes pulse {
    0% {
      background: #626262;
    }

    100% {
      background: #7a7a7a;
    }
  }
`;

export const LoggedInContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;

  box-sizing: border-box;

  margin: 0 15px;

  margin-right: 32px;
  a {
    text-decoration: none;
  }

  .button-subscribe {
    width: 150px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.xl} {
    margin: 0 10px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.lg} {
    .button-subscribe {
      width: 150px;
    }
  }

  @media screen and ${({ theme }) => theme.breakpoints.md} {
    .button-subscribe {
      display: none;
    }

    margin: 0;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;

  box-sizing: border-box;

  @media screen and ${({ theme }) => theme.breakpoints.xl} {
    width: 40%;
  }

  @media screen and ${({ theme }) => theme.breakpoints.md} {
    width: 100%;
  }
`;

export const ButtonTrainerDashboard = styled.div`
  color: ${(props) => props.color};
  /* text-decoration: none; */
  font-size: 16px;
  font-family: 'Archivo';
  font-weight: ${({ normal }) => (normal ? '400' : '600')};
  margin: 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;

  flex-shrink: 0;

  .button {
    flex-shrink: 0;

    cursor: pointer;
  }

  .user-container {
    display: flex;
    width: 120px;
    align-items: center;
    /* justify-content: space-between; */
    margin: 0 24px;

    height: 100%;

    box-sizing: border-box;
    gap: 32px;
    flex-shrink: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    .user-container {
      width: 100px;
      margin: 0;
      margin-right: 15px;
    }
  }
`;

export const Notification = styled.div`
  position: relative;

  .notif-count {
    position: absolute;

    width: 19px;
    height: 19px;

    border-radius: 100%;

    background: #f18f01;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    text-align: center;

    color: #000000;

    top: 15%;
    right: 0%;

    display: grid;
    place-items: center;
  }
`;

export const GreenRoundedButton = styled(Button)`
  background: ${(props) => (!props.secondary ? '#52ff9d' : '#fdfdfd')};
  width: 110px;
  border-radius: 35px;
  border: 0;
  height: 44px;
  color: #626262;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  :hover {
    background: ${(props) => (props.secondary ? '#52ff9d' : '#fdfdfd')};
    color: ${(props) => (!props.secondary ? '#52ff9d' : '#fdfdfd')};
    border: 0;
  }
`;
