import React from 'react';

import { Header } from './styled';

import { ReactComponent as BackIcon } from 'assets/svg/prev-slider.svg';
import { useHistory } from 'react-router-dom';

const NavigationHeader = ({ title, saveButton = true, checkChanges = false, form }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Header>
      <div onClick={goBack}>
        <BackIcon fill="#fdfdfd" />
        {title}
      </div>
      {saveButton && (
        <button
          type="submit"
          form={form}
          className={`${checkChanges ? 'disabled' : ''}`}
          disabled={checkChanges}
        >
          Save
        </button>
      )}
    </Header>
  );
};

export default NavigationHeader;
