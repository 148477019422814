import React, { useState } from 'react';
import { Container, Content, Title, Paragraf } from './styled';
import CollaborationForm from 'components/molecule/Form/CollaborationForm/';
import { useMutation } from '@apollo/client';
import { SUBMIT_COLLABORATION } from 'mutation/register';
import FormError from 'components/atoms/FormError';
import MaxContainer from 'components/atoms/MaxContainer';
import { t } from 'hooks/intl/useLang';

const dataModal = {
  title: t('Pendaftaran Diterima'),
  paragraf:
    t('Data Anda akan dicek & diverifikasi terlebih dahulu oleh tim Terampil. Selanjutnya tim Terampil akan menghubungi Anda via phone dan SMS.'),
  link: '/mari-berkolaborasi',
};

const FormMariBerkolabarasi = () => {
  const [errMessage, setErrMessage] = useState(null);
  const [openModal, setIsOpenModal] = useState(false);
  const [submitForm, { loading }] = useMutation(SUBMIT_COLLABORATION, {
    onCompleted: ({ CollaborationSubmissionSubmit }) => {
      if (CollaborationSubmissionSubmit.status === 'Submission') {
        setErrMessage(null);

        setIsOpenModal(true);
      }
    },
    onError: (err) => {
      setErrMessage(err.message);
    },
  });

  const submitProps = (payload) => {
    const { code_area, fullname, corporate, email, phone_number, question } = payload;
    submitForm({
      variables: {
        input: {
          code_area,
          fullname,
          corporate,
          email,
          phone_number,
          question,
        },
      },
    });
  };
  return (
    <Container id="form-register">
      <MaxContainer>
        <Title>{t('Mari Berkolaborasi Mewujudkan Indonesia Terampil')}</Title>
        <Paragraf>
          {t('Kirim email ke')} <span>halo@terampil.com</span> {t('atau isi form di bawah ini untuk meminta demo secara langsung, kerja sama atau hal lainnya.')}{' '}
        </Paragraf>
        <Content>
          <Title hide="hide">{t('Isi Data Anda')}</Title>
          {errMessage && <FormError>{errMessage}</FormError>}
          <CollaborationForm
            openModal={openModal}
            loadingForm={loading}
            dataModal={dataModal}
            submitProps={submitProps}
          />
        </Content>
      </MaxContainer>
    </Container>
  );
};

export default FormMariBerkolabarasi;
