import React, { useEffect, useState } from 'react';
import { Container, OptionSelec, ListQuestion } from './styled';

const SecondTestOption = ({ data, onChangeSelected = () => {} }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    onChangeSelected(selected);
  }, [selected]);

  return (
    <Container>
      <OptionSelec onClick={() => setSelected('P')}>
        <div className={selected === 'P' ? 'circle-select' : 'circle'} />
      </OptionSelec>
      <OptionSelec onClick={() => setSelected('R')}>
        <div className={selected === 'R' ? 'circle-select' : 'circle'} />
      </OptionSelec>
      <OptionSelec onClick={() => setSelected('K')}>
        <div className={selected === 'K' ? 'circle-select' : 'circle'} />
      </OptionSelec>
      <ListQuestion>
        <p>{data.state}</p>
      </ListQuestion>
    </Container>
  );
};

export default SecondTestOption;
