import { gql } from '@apollo/client';

export const webinarFields = ` 
  name
  room_id
  room_available
  banner_img
  description
  is_free
  live_at
  end_at
  is_active
  trainer_id
  price
  benefits {
    title
  }
  trainer {
    fullname
    profession
    avatar
    about
    rating
    slug
    total_score
  }
  id
`;

export const GET_WEBINARS = gql`
  query GET_WEBINARS {
    Webinars {
      items {
        ${webinarFields}
      }
      total
      filtered
      status
    }
  }
`;

export const GET_WEBINAR_BY_ID = gql`
  query GET_WEBINAR_BY_ID($room_id: String) {
    Webinar(room_id: $room_id) {
      ${webinarFields}
    }
  }
`;

export const GET_USER_WEBINARS = gql`
  query GET_USER_WEBINARS {
    UserWebinars {
      items {
        user_id
        webinar_id
        access_room_id
        is_attend
        add_gcalendar
        webinar {
          ${webinarFields}
        }
        id
      }
      total
      filtered
      status
    }
  }
`;

export const GET_USER_WEBINAR_BY_ID = gql`
  query GET_USER_WEBINAR_BY_ID($webinar_id: Int) {
    UserWebinar(webinar_id: $webinar_id) {
      user_id
      webinar_id
      access_room_id
      add_gcalendar
      is_attend
      add_gcalendar
      webinar {
        ${webinarFields}
      }
      id
    }
  }
`;

export const GET_USER_WEBINAR_FEEDBACK = gql`
  query GET_USER_WEBINAR_FEEDBACK($webinar_id: Int!) {
    WebinarFeedback(webinar_id: $webinar_id) {
      id
      webinar_id
      rating
      text
    }
  }
`;

export const CHECK_EMAIL_USER = gql`
  query checkUserWebinar($input: CheckUserWebinarInput) {
    CheckUserWebinar(input: $input) {
      status
      statusText
      item {
        email
        phone_number
        accessToken
        Profile {
          fullname
          id
          referral_code
          slug
          all_access
          all_access_end_date
        }
        client_id
        id
      }
    }
  }
`;

export const WEBINAR_ACTIVITY = gql`
  query WEBINAR_ACTIVITY($filter: UserWebinarActivityFilter) {
    UserWebinarActivity(filter: $filter) {
      items {
        user_id
        webinar_id
        access_room_id
        add_gcalendar
        is_attend
        add_gcalendar
        webinar {
          ${webinarFields}
        }
        id
      }
      total
      filtered
    } 
  }
`;
