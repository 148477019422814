import styled from 'styled-components';

export const Container = styled.section`
  background-image: url('${(props) => props.image}');
  width: 100%;
  height: 430px;
  margin-top: 77px;
  box-sizing: border-box;
  background-position: center;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 100px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 30px 30px 0;
  }
`;

export const CardContainer = styled.div`
  width: 500px;
  height: 350px;
  border-radius: 10px;
  background-color: #373737;
  padding: 24px 40px;
  box-sizing: border-box;
`;

export const Title = styled.h2`
  ${(props) => props.theme.font.primary.title}
  margin:0;
`;

export const Paragraf = styled.p`
  ${(props) => props.theme.font.secondary.subtitle}
  margin:0;
`;
