import styled from 'styled-components';

export const TrainingOverviewStyled = styled.div`
  width: 400px;
  background: #303030;
  border: 1px solid #626262;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 99999999999;
  padding: 24px;
  margin: 0 24px;
  box-sizing: border-box;
  position: absolute;
  left: ${(props) => (props.left ? '98%' : '-440px')};
  animation: 1000ms fadeIn;
  animation-fill-mode: forwards;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 1;

  visibility: hidden;

  .arrow-bubble {
    position: absolute;
    z-index: 100;
    left: ${(props) => (props.left ? '-18px' : '392px')};
    top: 50%;
    transform: translate(0, -50%);
    animation: 1000ms fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
  }

  @keyframes fadeIn {
    90% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }
`;

export const OverviewDetail = styled.div`
  width: 352px;
  background: #373737;
  border-radius: 5px;
  padding: 16px;
  box-sizing: border-box;

  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    margin: 0 0 16px;

    color: #fdfdfd;
  }
`;

export const TrainingTitle = styled.div`
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;

  color: #ffffff;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }
`;

export const CheckList = styled.ul`
  margin: 0;
  padding-left: 16px;
  list-style-image: url(${(props) => props.icon});

  .overview-list {
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    margin: 0 0 12px;
    padding-left: 8px;

    color: #fdfdfd;
    @media screen and ${(props) => props.theme.breakpoints.lg} {
      font-size: 13px;
      margin: 0 0 8px;
    }
  }
`;

export const TrainerName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;

  color: #c4c4c4;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
  }
`;
