import React from 'react';

import PopupModal from 'components/atoms/Modal';
import { Container } from './styled';
import Button from 'components/atoms/Button';

import { ReactComponent as TimeoutIcon } from 'assets/svg/Kuis/timeout.svg';
import { t } from 'hooks/intl/useLang';

const TimeoutModal = ({ setShowModal, updateQuestion, setSelectedAnswer, timeoutID, ...rest }) => {
  return (
    <PopupModal dark medium {...rest}>
      <Container>
        <TimeoutIcon />
        <h6>{t('Waktu Kamu Habis')}</h6>
        <p className="mb-2">
          {t('Waktu kamu habis untuk menjawab pertanyaan ini. Klik selanjutnya untuk lanjut ke pertanyaan berikutnya.')}
        </p>
        <Button
          onClick={() => {
            setSelectedAnswer('');
            updateQuestion();
            setShowModal(false);
          }}
          medium
          className="w-100"
        >
          {t('Selanjutnya')}
        </Button>
      </Container>
    </PopupModal>
  );
};

export default TimeoutModal;
