import Button from 'components/atoms/Button';
import PopupModal from 'components/atoms/Modal';
import React from 'react';
import { Container, Title, Line, Content, ButtonContainer, WrapperContent } from './styled';

const RecurringModal = ({ onClick, setOpenModalRecurring, ...rest }) => {
  return (
    <PopupModal medium {...rest}>
      <Container>
        <Title>Lanjutkan Proses Pembayaran Recurring?</Title>
        <Line />
        <WrapperContent>
          <Content>
            Pembayaran recurring akan otomatis mendebet saldo DANA kamu. Pastikan saldo kamu cukup
            setiap bulannya. Recurring bisa dibatalkan kapan saja dan tanpa biaya apapun.
          </Content>
        </WrapperContent>
        <ButtonContainer>
          <Button className="accept" onClick={() => setOpenModalRecurring(false)}>
            Tidak
          </Button>
          <Button className="cancel" onClick={onClick}>
            Lanjutkan
          </Button>
        </ButtonContainer>
      </Container>
    </PopupModal>
  );
};

export default RecurringModal;
