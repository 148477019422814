import React from 'react';
import loadable from '@loadable/component';
import { LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';

import {
  Container,
  Title,
  CardContainer,
  SliderPagination,
  ButtonEnd,
} from './PopularTrainer.styled';

// component
// import Slider from 'components/atoms/Slider';
import { SliderControlPrimary } from 'components/atoms/Slider/Slider.styled';
import { SwiperSlide } from 'swiper/react';
// import CardTrainer from 'components/molecule/Cards/CardTrainer/CardTrainer';
import { checkMobile } from 'utils/checkMobile';
import { LoaderDualRing } from 'components/atoms/Loading/';

// aset
import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';
import { ReactComponent as SeeItemsIcon } from 'assets/svg/see-items.svg';
import TitleSection from 'components/molecule/TitleSectionHome';
import MaxContainer from 'components/atoms/MaxContainer';
import { useHistory, useLocation } from 'react-router';
import { t } from 'hooks/intl/useLang';

const Slider = loadable(() => import('components/atoms/Slider'));
const CardTrainer = loadable(() => import('components/molecule/Cards/CardTrainer/CardTrainer'));

function PopularTrainer({ data = [], loading, mobile, home, scrollPosition, ...rest }) {
  const history = useHistory();
  const location = useLocation();

  //check the list of trainer
  const listTrainerPopuler = (item) => {
    if (item.length >= 8 && location.pathname !== '/home') {
      return [...data, { reachEnd: true }];
    } else {
      return data;
    }
  };

  function pushHistory() {
    history.push('/trainers?page=1');

    return;
  }

  function renderSlider() {
    if (checkMobile()) {
      return (
        <Slider
          next="next-trainer"
          prev="prev-trainer"
          pagination="trainer-popular"
          mobile={mobile}
          count={2}
        >
          {listTrainerPopuler(data).map((trainer, index) => {
            return (
              <SwiperSlide key={index}>
                {trainer?.reachEnd ? (
                  <ButtonEnd onClick={pushHistory}>
                    <div className="wrap-button">
                      <SeeItemsIcon />
                      <div className="content">{t('Lihat Semua')}</div>
                    </div>
                  </ButtonEnd>
                ) : (
                  <LazyLoadComponent scrollPosition={scrollPosition}>
                    <CardTrainer
                      trainer={trainer?.trainer}
                      next="next-trainer"
                      prev="prev-trainer"
                    />
                  </LazyLoadComponent>
                )}
              </SwiperSlide>
            );
          })}
        </Slider>
      );
    } else {
      return (
        <Slider
          next="next-trainer"
          prev="prev-trainer"
          pagination="trainer-popular"
          mobile={mobile}
          count={4}
        >
          {listTrainerPopuler(data).map((trainer, index) => {
            return (
              <SwiperSlide key={index}>
                {trainer?.reachEnd ? (
                  <ButtonEnd onClick={pushHistory}>
                    <div className="wrap-button">
                      <SeeItemsIcon />
                      <div className="content">{t('Lihat Semua')}</div>
                    </div>
                  </ButtonEnd>
                ) : (
                  <LazyLoadComponent scrollPosition={scrollPosition}>
                    <CardTrainer
                      trainer={trainer?.trainer}
                      next="next-trainer"
                      prev="prev-trainer"
                    />
                  </LazyLoadComponent>
                )}
              </SwiperSlide>
            );
          })}
        </Slider>
      );
    }
  }
  return (
    <Container home={home} {...rest}>
      <MaxContainer>
        {home ? (
          <TitleSection destination="/trainers?category=all&sort=0">{t('Trainer Populer')}</TitleSection>
        ) : (
          <Title>{t('Diajarkan Langsung oleh Trainer Berpengalaman')}</Title>
        )}
        <div className="slider">
          <div className="button-slider">
            <SliderControlPrimary className="prev-primary" id="prev-trainer">
              <PrevIcon />
            </SliderControlPrimary>
            <SliderControlPrimary className="next-primary" id="next-trainer">
              <NextIcon />
            </SliderControlPrimary>
          </div>
          {loading ? <LoaderDualRing /> : <CardContainer>{renderSlider()}</CardContainer>}
        </div>
        <SliderPagination id="trainer-popular" />
      </MaxContainer>
    </Container>
  );
}

export default trackWindowScroll(PopularTrainer);
