import React, { useState, useEffect } from 'react';
import {
  Container,
  InputContainer,
  InputRefferal,
  SubmitButton,
  CekButton,
  SkipText,
  ErrorText,
} from './styled';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { REFERRAL_CODE_CHECK, REFERRAL_CODE_SUBMIT } from 'mutation/register';
import { useForm } from 'react-hook-form';
import { setContext } from 'utils';
import { useDispatch } from 'react-redux';
import CekEmailModal from 'components/molecule/Modal/CekEmailModal';
import { authOperations } from 'state/modules/auth';

const RefferalComponent = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isValid, setIsValid] = useState(true);
  const [checkRegisterEmail, setCheckRegisterEmail] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [isOpenCekEmail, setIsOpenCekEmail] = useState(false);
  const [textChange, setTextChange] = useState('');

  const authData = {
    data: location?.state?.registerData,
  };

  const [referralCheck, { data: dataCheck }] = useMutation(REFERRAL_CODE_CHECK, {
    context: setContext(authData),
    onCompleted({ ReferralCodeCheck }) {
      setDisabled(false);
      setIsValid(true);
    },
    onError(e) {
      setIsValid(false);
      setDisabled(true);
    },
  });

  const [referralSubmit, { loading: loadingSubmit }] = useMutation(REFERRAL_CODE_SUBMIT, {
    context: setContext(authData),
    onCompleted({ ReferralCodeSubmit }) {
      if (ReferralCodeSubmit) {
        if (location?.state?.signUpByGoogle || location?.state?.signUpByPhone) {
          setCheckRegisterEmail(200);
        } else {
          setIsOpenCekEmail(true);
        }
      } else {
        setIsValid(false);
      }
    },
    onError(e) {
      setIsValid(false);
      setDisabled(true);
    },
  });

  const checkRefferal = () => {
    if (textChange === '') {
      setIsValid(true);
    } else {
      referralCheck({
        variables: {
          code: textChange,
        },
      });
    }
  };

  const onSubmit = (data) => {
    if (data.referralCode !== '') {
      referralSubmit({
        variables: {
          code: data.referralCode.toUpperCase(),
        },
      });
    }
  };

  useEffect(() => {
    switch (checkRegisterEmail) {
      case 200: {
        // move to page
        setIsOpenCekEmail(false);
        // dispatch(
        //   authOperations.setAuth({
        //     accessToken: registerData.accessToken,
        //     slug: registerData.Profile.slug,
        //     userId: registerData.id,
        //     isFirstRegist: true,
        //   }),
        // );
        history.replace('/user/preferensi');
        break;
      }
      default:
        setCheckRegisterEmail(null);
    }
  }, [checkRegisterEmail]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <InputRefferal
            dark
            rounded
            valid={isValid}
            name="referralCode"
            className="input-referral"
            register={register}
            placeholder="Isi referral code"
            onChange={(val) => {
              setTextChange(val.target.value.toUpperCase());
            }}
          />
          <CekButton onClick={() => checkRefferal()}>
            <p className="text">Cek</p>
          </CekButton>
        </InputContainer>
        {!isValid && <ErrorText className="error-text">Refferal code tidak valid</ErrorText>}
        <SubmitButton disabled={disabled} type="submit">
          Gunakan Refferal Code
        </SubmitButton>
      </form>
      <SkipText onClick={() => history.push('/user/preferensi')}>Skip</SkipText>
      {isOpenCekEmail && (
        <CekEmailModal setCheckRegisterEmail={setCheckRegisterEmail} isOpen={isOpenCekEmail} />
      )}
    </Container>
  );
};

export default RefferalComponent;
