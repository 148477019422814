import React, { useState } from 'react';
import { Container, Title, Paragraf, Button, ContainerButton, ButtonOutline } from './styled';
import { Link } from 'react-router-dom';
import MaxContainer from 'components/atoms/MaxContainer';
import RedirectModalToMobile from '../Modal/RedirectToMobile';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';

const TemplateHeadline = ({ kolaborasi, items, prakerja = false, ...rest }) => {
  const [openModal, setOpenModal] = useState(false);
  const isMobile = checkMobile();
  return (
    <Container image={items.image}>
      <MaxContainer prakerja={prakerja}>
        <div className="container-upside">
          <Title prakerja={prakerja} {...rest}>
            {items?.firstH1 && (
              <>
                <span>{items?.firstH1}</span>
                <br />
              </>
            )}
            {items.secondH2}
          </Title>
          <Paragraf>{items.paragraf}</Paragraf>
          {prakerja ? (
            <ContainerButton>
              <a href="#about">
                <ButtonOutline>{t('Apa itu Prakerja ?')}</ButtonOutline>
              </a>
              {isMobile ? (
                <div>
                  <Button onClick={() => setOpenModal(true)}>{items.button}</Button>
                </div>
              ) : (
                <Link to={items.link}>
                  <Button>{items.button}</Button>
                </Link>
              )}
            </ContainerButton>
          ) : (
            <div className="container-button">
              <Link to={items.link}>
                <Button>{items.button}</Button>
              </Link>
            </div>
          )}
        </div>
        {openModal && (
          <RedirectModalToMobile isOpen={openModal} onRequestClose={() => setOpenModal(false)} />
        )}
      </MaxContainer>
    </Container>
  );
};

export default TemplateHeadline;
