// import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const exampleReducer = createReducer( {
    loading: false,
    // #di-set null jika data nya adalah object, jika sebuah array maka dibuat kan array
    // data: [],
    data: null,
    error : null
} )( {
    [ types.FETCH_EXAMPLE_COMPLETED ]: ( state, action ) => {
        return {
            loading: false,
            data: action.payload,
            error: null
        }
    },
    [ types.FETCH_EXAMPLE_BEGIN ]: ( state, action ) => {
        return {
            loading: true,
            data: null,
            error: null
        }
    },
    [ types.FETCH_EXAMPLE_FAILED ]: ( state, action ) => {
        return {
            loading: false,
            data: null,
            error: action.payload.data
        }
    }
} );

// export default combineReducers( {
//     example: exampleReducer
// } );

export default exampleReducer
