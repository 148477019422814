import Button from 'components/atoms/Button';
import PopupModal from 'components/atoms/Modal';
import React from 'react';
import { Title, Paragraf, Container } from './styled';
import { t } from 'hooks/intl/useLang';

const RedirectModalToMobile = ({ onRequestClose, ...rest }) => {
  return (
    <PopupModal dark mobileClose onRequestClose={onRequestClose} {...rest}>
      <Container>
        <Title>{t('Belajar lebih nyaman menggunakan aplikasi Terampil')}</Title>
        <Paragraf>
          {t('Belajar lebih nyaman bersama Trainer terbaik secara online & offline hanya di aplikasi Terampil.')}
        </Paragraf>
        <div>
          <Button
            onClick={() => {
              onRequestClose(false);
              window.open('https://terampil.page.link/start');
            }}
            className="button"
          >
            {t('Buka Aplikasi Terampil')}
          </Button>
        </div>
      </Container>
    </PopupModal>
  );
};

export default RedirectModalToMobile;
