import React from 'react';
import { Text } from './styled';

const ProfileDescription = ({ text }) => {
  return (
    <>
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </>
  );
};

export default ProfileDescription;
