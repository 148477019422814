import React, { useEffect, useState } from 'react';
import { ContainerSortDropDown, Text } from './styled';
import Select from 'react-select';
import OptionDropdownSort from 'components/atoms/OptionDropdownSort';
import { useHistory } from 'react-router-dom';
import { useUrlQuery } from 'hooks';
import {
  CheckRadioIcon,
  RatingFilterItem,
  RatingFilterLabel,
} from 'components/organisms/Filter/RatingFilter/styled';
import { CategoryFilterStyled } from 'components/organisms/Filter/CategoryFilter/styled';

import check from 'assets/svg/radio/check.svg';
import uncheck from 'assets/svg/radio/uncheck.svg';

function SortDropDown({ label, options, onChangeSelected = () => {}, initialSelected = 0 }) {
  const [selectedOption, setSelectedOption] = useState(options[initialSelected]);
  const [optionsIsClicked, setOptionsIsClicked] = useState(false);

  useEffect(() => {
    onChangeSelected(selectedOption, options, optionsIsClicked);
  }, [selectedOption]);

  const handleChange = (selected) => {
    setOptionsIsClicked(true);
    setSelectedOption(selected);
  };

  return (
    <ContainerSortDropDown>
      <CategoryFilterStyled className="mobile">
        <Text>{label}</Text>
        {options.map((option) => {
          return (
            <RatingFilterItem onClick={() => handleChange(option)}>
              <CheckRadioIcon src={selectedOption.value === option.value ? check : uncheck} />
              <RatingFilterLabel>{option.label}</RatingFilterLabel>
            </RatingFilterItem>
          );
        })}
      </CategoryFilterStyled>
      <Text className="web">{label}</Text>
      <Select
        className="web"
        value={selectedOption}
        options={options}
        isClearable={false}
        classNamePrefix="react-select"
        onChange={handleChange}
        isSearchable={false}
        components={{ OptionDropdownShort: OptionDropdownSort }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#f1f0f0',
            primary25: '#f1f0f0',
          },
        })}
      />
    </ContainerSortDropDown>
  );
}

export default SortDropDown;
