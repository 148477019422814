import { gql } from '@apollo/client';

export const GET_SESSIONS = gql`
  query Session {
    Sessions {
      status
      statusText
      items {
        id
        unique_id
        type
        device_name
        device_version
        status
        last_active
      }
    }
  }
`;
