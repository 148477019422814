import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import Toast from 'components/atoms/Notification/Toast';
import {
  Container,
  NomorVirtual,
  Title,
  DateTitle,
  CardDetails,
  PaymentName,
  PaymentDetails,
  TotalPayment,
  TotalContainer,
} from './styled';
import bca from 'assets/png/bank/image 15.png';
import bni from 'assets/png/bank/image 16.png';
import mandiri from 'assets/png/bank/image 34.png';
import permata from 'assets/png/bank/image 35.png';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { formatDateFullTime } from 'utils/date';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PAYMENT_INFO } from 'queries/pembayaran';
import { setContext } from 'utils';
import { GET_USER_ORDERS_DETAIL } from 'queries/orders';

let intervalID = null;

const RincianTransferVA = ({ setPaymentMethod, invoice, setLoading }) => {
  const history = useHistory();
  const authData = useSelector((state) => state.Auth);
  const [dataOrder, setDataOrder] = useState();
  const [dataBank, setDataBank] = useState({});
  const { data: paymentInfo, loading: loadingPaymentInfo } = useQuery(GET_PAYMENT_INFO, {
    context: setContext(authData),
    variables: { invoice: invoice },
  });

  const [getUserOrder, { data: dataOrderDetail, loading: loadingOrderDetail }] = useLazyQuery(
    GET_USER_ORDERS_DETAIL,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (userOrder) => {
        if (userOrder.UserOrderDetail.status === 'Paid') {
          history.push(`/detail-riwayat-pembayaran/${userOrder.UserOrderDetail.invoice_number}`);
        } else {
          dataBankHandler(userOrder?.UserOrderDetail?.payment_method);
        }
      },
    },
  );

  const dataBankHandler = (payment) => {
    switch (payment) {
      case 'bca':
        setDataBank({
          name: 'BCA',
          logo: bca,
        });

        break;
      case 'bni':
        setDataBank({
          name: 'BNI',
          logo: bni,
        });

        break;
      case 'mandiri':
        setDataBank({
          name: 'Mandiri',
          logo: mandiri,
        });
        break;
      case 'permata':
        setDataBank({
          name: 'Permata',
          logo: permata,
        });

        break;
      default:
        break;
    }
  };

  let subType = {};
  switch (dataOrder?.UserOrderDetail?.subscription?.subscription_type || 'Training') {
    case 'Monthly':
      subType.name = 'Bulanan';
      break;
    case 'Semester':
      subType.name = 'Semesteran';
      break;
    case 'Yearly':
      subType.name = 'Tahunan';
      break;
    case 'Yearly-2':
      subType.name = '1 Tahun';
      break;
    default:
      subType.name = dataOrderDetail?.UserOrderDetail?.order_name;
      break;
  }

  const onCopyToClipboard = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => console.log('Copied!'), toast('Nomor Virtual Account berhasil disalin'))
      .catch(() => console.log('Failed!'));
  };

  const checkChange = () => {
    intervalID = setInterval(() => {
      getUserOrder({
        context: setContext(authData),
        variables: { invoice: invoice },
      });
    }, 5000);
  };

  useEffect(() => {
    dataBankHandler(dataOrder?.UserOrderDetail?.payment_method);
  }, []);

  useEffect(() => {
    getUserOrder({
      context: setContext(authData),
      variables: { invoice: invoice },
    });
    if (loadingPaymentInfo && loadingOrderDetail) {
      setLoading(true);
    }

    if (dataOrderDetail) {
      if (!dataOrder) {
        setDataOrder(dataOrderDetail);
        setPaymentMethod(dataOrderDetail?.UserOrderDetail?.payment_method);
      }
      if (dataOrderDetail?.UserOrderDetail?.status === 'Pending') {
        if (intervalID) clearInterval(intervalID);
        checkChange();
      }
      setLoading(false);
    }
  }, [setLoading, dataOrderDetail]);

  return (
    <Container>
      <Toast position="bottom-left" />
      {dataOrder && paymentInfo && (
        <>
          <Title>Batas Akhir Pembayaran</Title>
          <DateTitle>{formatDateFullTime(dataOrder?.UserOrderDetail?.due_date)}</DateTitle>
          <CardDetails>
            <PaymentName>
              <h2>{dataBank?.name} Virtual Account</h2>
              <img src={dataBank?.logo} className="logo-bank" alt="logo" />
            </PaymentName>
            <PaymentDetails>
              <p>Nomor Tagihan</p>
              <NomorVirtual>
                {dataOrder?.UserOrderDetail?.invoice_number.split('INV').join('/INV/')}
              </NomorVirtual>
              <p>Nomor Virtual Account</p>
              <div>
                <NomorVirtual>
                  {dataOrder?.UserOrderDetail?.payment_method === 'mandiri'
                    ? paymentInfo?.PaymentInfo?.bill_key
                    : paymentInfo?.PaymentInfo?.va_number}
                </NomorVirtual>
                <NomorVirtual
                  salin="copy"
                  onClick={() =>
                    onCopyToClipboard(
                      dataOrder?.UserOrderDetail?.payment_method === 'mandiri'
                        ? paymentInfo?.PaymentInfo?.bill_key
                        : paymentInfo?.PaymentInfo?.va_number,
                    )
                  }
                >
                  Salin
                </NomorVirtual>
              </div>
              <p>Paket yang Dibeli</p>
              <NomorVirtual>
                {dataOrder?.UserOrderDetail?.subscription ? 'Langganan' : ''} {subType?.name}{' '}
              </NomorVirtual>
              <TotalContainer>
                <div className="container">
                  <p>Total Pembayaraan</p>
                  <TotalPayment>
                    Rp {new Intl.NumberFormat('id-ID').format(dataOrder?.UserOrderDetail?.price)}
                  </TotalPayment>
                </div>
              </TotalContainer>
            </PaymentDetails>
          </CardDetails>
        </>
      )}
    </Container>
  );
};

export default RincianTransferVA;
