import React, { useRef } from 'react';
import { client } from 'App';

import { Figure, UserDetail, Img, Settings, Progress, Profile } from './styled';
import Button from 'components/atoms/Button';

import { ReactComponent as SettingIcon } from 'assets/svg/ic_pengaturan.svg';
import { ReactComponent as LogoutIcon } from 'assets/svg/ic_keluar.svg';
// import { ReactComponent as UpgradeIcon } from 'assets/svg/ic_upgrade.svg';
import { ReactComponent as InviteIcon } from 'assets/svg/icon-invite.svg';
import { ReactComponent as PaymentIcon } from 'assets/svg/icon-payment.svg';
import { Link, useHistory } from 'react-router-dom';
import { DO_SIGNOUT } from 'mutation/logout';
import { useMutation, useQuery } from '@apollo/client';
import { useGoogleLogout } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { authOperations } from 'state/modules/auth';
import handleClickOutside from './clickoutside';
import ProfilePic from 'assets/svg/User Default.svg';
import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import { subscribeOperations } from 'state/modules/payment';
import { TRAINING_PROGRES_DURATION } from 'queries/training';
import { isDateExpired } from '../../../utils/date';
import { t } from 'hooks/intl/useLang';

const UserProfile = ({ profile, showUserDetail, setShowUserDetail, freeTrial }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const isExpired = isDateExpired(profile?.subscription_end_date);

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_ID,
    onLogoutSuccess: () => ({}),
    onFailure: (e) => console.error(e, 'ERROR FROM GOOGLE LOGIN'),
  });

  const [fetchSignout] = useMutation(DO_SIGNOUT, {
    onCompleted: async () => {
      //SIGNING OUT OF GOOGLE
      client.clearStore();
      signOut();

      await dispatch(authOperations.removeAuth());
      await dispatch(subscribeOperations.removeSubscribe());

      history.push('/');
      window.Moengage.destroy_session();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  // get progress
  const { data: totalDuration } = useQuery(TRAINING_PROGRES_DURATION, {
    context: setContext(authData),
    variables: {
      summary: true,
    },
  });

  const percentage = Math.ceil(totalDuration?.TrainingUserDurationProgress?.item?.progress || 0);

  const doSignout = () => {
    fetchSignout({
      context: setContext(authData),
    });
  };
  const ref = useRef(null);
  handleClickOutside(ref, (e) => {
    setShowUserDetail(!showUserDetail);
    e.stopPropagation();
  });

  return (
    <Figure
      profile_pic={`${profile?.avatar !== '' ? profile?.avatar : ProfilePic}`}
      onClick={(e) => {
        setShowUserDetail(!showUserDetail);
        e.stopPropagation();
      }}
    >
      {showUserDetail}
      {showUserDetail && (
        <UserDetail ref={ref}>
          <Profile>
            <Img
              src={`${profile?.avatar !== '' ? profile?.avatar : ProfilePic}`}
              alt="profile-pic"
            />
            <h6>{profile?.fullname}</h6>
            <p>{profile?.profession}</p>
            <Button className="button-profile" onClick={() => history.push('/user/me')}>
              {t('Lihat Profil')}
            </Button>
          </Profile>
          <Progress>
            <>
              <div>
                <p>{t('Progress Belajar')}</p>
                <span>{percentage ?? 0}%</span>
              </div>
              <div>
                <p>{t('Sisa Modul')}</p>
                <span style={{ fontSize: getValue(authData, 'data.allAccess') ? '16px' : '' }}>
                  {getValue(authData, 'data.allAccess') || profile?.training_quota > 9999
                    ? 'all acceess'
                    : profile?.training_quota > 0 && profile?.User?.free_trial
                      ? '0'
                      : profile?.training_quota}
                </span>
              </div>
              {profile?.User?.free_trial && !isExpired && (
                <div>
                  <p>{t('Sisa Video')}</p>
                  <span>{profile?.video_quota}</span>
                </div>
              )}
            </>

            {/* <Link to="/berlangganan">
                <UpgradeIcon />
                Upgrade
              </Link>
            )} */}
          </Progress>
          <Settings>
            <Link to="/user/settings/invite">
              <InviteIcon /> {t('Undang Teman')}
            </Link>
            <Link to="/user/settings/pembayaran">
              <PaymentIcon /> {t('Pembayaran')}
            </Link>
            <Link to="/user/settings">
              <SettingIcon /> {t('Pengaturan')}
            </Link>
            <div onClick={doSignout}>
              <LogoutIcon /> {t('Keluar')}
            </div>
          </Settings>
        </UserDetail>
      )}
    </Figure>
  );
};

export default UserProfile;
