import React, { useEffect, useState } from 'react';

import { Container, Title, ContainerCard } from './styled';
import CardNewTraining from 'components/molecule/Cards/CardNewTraining';
import Slider from 'components/atoms/Slider/index';
import MaxContainer from 'components/atoms/MaxContainer';
import TitleSection from 'components/molecule/TitleSectionHome';

import { SwiperSlide } from 'swiper/react';
import { SliderControlPrimary } from '../../atoms/Slider/Slider.styled';
import { SliderPagination, ButtonEnd } from './styled';
import { checkTablet } from 'utils/checkMobile';
import { GET_NEW_TRAINING } from 'queries/training';
import { useQuery } from '@apollo/client';

// asset
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';
import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as SeeItemsIcon } from 'assets/svg/see-items.svg';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getValue } from 'utils';
import { checkToken } from 'utils/checkToken';
import { t } from 'hooks/intl/useLang';

function NewTraining({ home }) {
  const [mobileWindow, setMobileWindow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dataNewTrainings, setDataNewTrainings] = useState([]);

  const history = useHistory();

  const authData = useSelector((state) => {
    return state.Auth;
  });

  const token = getValue(authData, 'data.accessToken', '');

  const { loading, data } = useQuery(GET_NEW_TRAINING, {
    variables: {
      input: {
        orderBy: 'created_at',
        orderType: 'desc',
      },
    },
    onCompleted: (data) => {
      setDataNewTrainings(data?.Trainings?.items);
    },
  });

  //check the list of trainer
  const listNewTraining = (item) => {
    if (item.length >= 6) {
      return [...data?.Trainings?.items.slice(0, 9), { reachEnd: true }];
    } else {
      return data?.Trainings?.items;
    }
  };

  useEffect(() => {
    checkToken(token, setIsLoggedIn);
    setMobileWindow(checkTablet());
  }, []);

  function pushHistory() {
    history.push('/trainings?page=1&category=all');

    return;
  }

  if (loading) return null;
  return (
    <Container home={home}>
      <MaxContainer>
        {home ? (
          <TitleSection destination="/trainings?category=all&sort=1">{t('Training Baru')}</TitleSection>
        ) : (
          <Title>{t('Training Terbaru')} </Title>
        )}
        {!mobileWindow ? (
          <div className="slider">
            <div className="button-slider">
              <SliderControlPrimary className="prev-primary" id="new-prev-training">
                <PrevIcon />
              </SliderControlPrimary>
              <SliderControlPrimary className="next-primary" id="new-next-training">
                <NextIcon />
              </SliderControlPrimary>
            </div>
            <ContainerCard>
              <Slider
                next="new-next-training"
                prev="new-prev-training"
                pagination="new-training"
                count={3}
              >
                {!isLoggedIn ? (
                  <>
                    {dataNewTrainings &&
                      listNewTraining(dataNewTrainings).map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            {item?.reachEnd ? (
                              <ButtonEnd onClick={pushHistory}>
                                <div className="wrap-button">
                                  <SeeItemsIcon />
                                  <div className="content">{t('Lihat Semua')}</div>
                                </div>
                              </ButtonEnd>
                            ) : (
                              <CardNewTraining newTraining={item} />
                            )}
                          </SwiperSlide>
                        );
                      })}
                  </>
                ) : (
                  <>
                    {data?.Trainings?.items.slice(0, 9).map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <CardNewTraining newTraining={item} />
                        </SwiperSlide>
                      );
                    })}
                  </>
                )}
              </Slider>
            </ContainerCard>
          </div>
        ) : (
          <ContainerCard>
            <Slider
              next="new-next-training"
              prev="new-prev-training"
              pagination="new-training"
              count={1}
            >
              {!isLoggedIn ? (
                <>
                  {dataNewTrainings &&
                    listNewTraining(dataNewTrainings).map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          {item?.reachEnd ? (
                            <ButtonEnd onClick={pushHistory}>
                              <div className="wrap-button">
                                <SeeItemsIcon />
                                <div className="content">{t('Lihat Semua')}</div>
                              </div>
                            </ButtonEnd>
                          ) : (
                            <CardNewTraining newTraining={item} />
                          )}
                        </SwiperSlide>
                      );
                    })}
                </>
              ) : (
                <>
                  {data?.Trainings?.items.slice(0, 9).map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <CardNewTraining newTraining={item} />
                      </SwiperSlide>
                    );
                  })}
                </>
              )}
            </Slider>
          </ContainerCard>
        )}
        <SliderPagination id="new-training" />
      </MaxContainer>
    </Container>
  );
}

export default NewTraining;
