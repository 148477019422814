import PopupModal from 'components/atoms/Modal';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authOperations } from 'state/modules/auth';

import { Title, Separator, Description, Button } from './styled';

const TambahKataSandiModal = ({ setShowModal, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <PopupModal dark {...rest}>
      <Title>Tambah Kata Sandi Berhasil</Title>
      <Separator />
      <Description>
        Kata sandi berhasil ditambah. Silahkan masuk ke Terampil menggunakan kata sandi baru.
      </Description>
      <div>
        <Button onClick={async () => {
          await dispatch(authOperations.removeAuth());

          history.push('/')
          }}>Tutup</Button>
      </div>
    </PopupModal>
  );
};

export default TambahKataSandiModal;