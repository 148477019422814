import { t } from "hooks/intl/useLang";

export const convertDuration = (duration) => {
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration % 3600) / 60);

  return hours + ` ${t('jam')} ` + minutes + ` ${t('menit')}`;
};

export const convertDurationSecondProgress = (duration) => {
  let minutes = Math.floor(duration / 60);
  let second = (duration % 60).toString();

  if (second.length === 1) {
    second = '0'.concat(second);
  }

  return minutes + ' m ' + second + ' s';
};

export const convertDurationSecond = (duration) => {
  let hours = Math.floor(duration / 3600).toString();
  let minutes = Math.floor((duration % 3600) / 60).toString();
  let second = (duration % 60).toString();

  if (second.length === 1) {
    second = '0'.concat(second);
  }

  if (minutes.length === 1) {
    minutes = '0'.concat(minutes);
  }
  if (hours.length === 1 && hours !== '0') {
    hours = '0'.concat(hours);
  }

  if (hours === '0') {
    return minutes + ':' + second;
  } else {
    return hours + ':' + minutes + ':' + second;
  }
};
