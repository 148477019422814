import ButtonStyled from 'components/atoms/Button/index';
import styled from 'styled-components';

export const Container = styled.section`
  background: #202020;
  border-radius: 5px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: column;
    background-color: inherit;
    padding: 16px;
  }
`;

export const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #fdfdfd;
  margin: 0;
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    text-align: left;
    color: #fdfdfd;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #c4c4c4;
  margin: 0 16px 0 0;
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;

export const BoxUrl = styled.div`
  width: 300px;
  height: 50px;

  margin: 0 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #626262;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #c4c4c4;

  padding: 16px 24px 16px 16px;

  & > div {
    overflow: hidden;
  }

  & > button {
    background: transparent;
    color: ${({ theme }) => theme.color.primary};
    border: none;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    cursor: pointer;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    border: 1px dashed #00debf;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    margin: 12px 0 0 0;

    & > button {
      display: none;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

export const MobileSharedContainer = styled.div`
  display: none;
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 12px;
  }
`;

export const ButtonOutline = styled(ButtonStyled)`
  border: 1px solid #c4c4c4;
  height: 40px;
  margin: 0;
  color: #c4c4c4;
  font-size: 14px;
  line-height: 17px;
`;

export const Button = styled(ButtonStyled)`
  border: 1px solid #c4c4c4;
  height: 40px;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
`;
