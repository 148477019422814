import React from 'react';
import { Container } from './styled';
import { FETCH_VIDEOADS } from 'queries/adsVideos';
import { useQuery } from '@apollo/client';
import MaxContainer from 'components/atoms/MaxContainer';
import Player from 'components/etc/Player';

const VideoBaner = ({ type }) => {
  const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const { loading } = useQuery(FETCH_VIDEOADS, {
    variables: {
      input: {
        is_active: true,
        limit: 1,
        type,
      },
    },
  });

  if (loading) return null;
  return (
    <Container>
      <MaxContainer>
        <Player
          width
          manifestUrl={
            is_safari
              ? 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/landingpage/hls/master.m3u8'
              : 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/landingpage/dash/index.mpd'
          }
          licenseServer={
            'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=5BC068'
          }
          autoPlay={true}
          muted
          loop
          // showControls
          style={{
            width: '100%',
            maxHeight: '100%',
          }}
        />
      </MaxContainer>
    </Container>
  );
};

export default VideoBaner;
