import React from 'react';
// import EmptyState from 'components/atoms/';
// asset
import BelumBerlanggananTraining from 'components/organisms/BelumBerlanggananTraining';
import { getValue, setContext } from 'utils';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { GET_USER_DETAIL, GET_USER_FREE_TRIAL } from 'queries/user';
import HasilTrainingSertif from 'components/organisms/HasilTrainingSertif';
import BelumAdaHasilTraining from 'components/organisms/BelumAdaHasilTraining';

const HasilTrainingTab = ({ trainingId = null, allowPostTest, postTestSectionId }) => {
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const { data: userTraining } = useQuery(GET_USER_DETAIL, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const { data: userFreeTrial } = useQuery(GET_USER_FREE_TRIAL, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const isFreeTrial = userFreeTrial?.ProfileUser?.User?.free_trial;

  return (
    <>
      {!allowPostTest ? (
        <BelumAdaHasilTraining />
      ) : !isFreeTrial ? (
        <HasilTrainingSertif trainingId={trainingId} postTestSectionId={postTestSectionId} />
      ) : (
        <BelumBerlanggananTraining trainingId={trainingId} />
      )}
    </>
  );
};

export default HasilTrainingTab;
