import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  position: relative;

  max-width: 390px;

  a {
    text-decoration: none;
  }
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    /* width: 315px; */
    max-width: ${(props) => (props.slide ? '345px' : '100%')};
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    /* width: 315px; */
    width: ${(props) => (props.slide ? '345px' : '100%')};
  }
`;

export const LabelCategory = styled.div`
  background: #00debf;
  border-radius: 2px;
  padding: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #fdfdfd;
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const ImageCard = styled.div`
  background-image: url('${(props) => props.url}');
  max-width: 100%;
  height: 200px;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 168px;
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  color: #fdfdfd;
  height: 53px;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 16px;
    line-height: 24px;
    margin: 5px 0;
  }
`;

export const Datefont = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #7a7a7a;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 12px;
    line-height: 15px;
    margin: 0;
  }
`;
