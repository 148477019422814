import React from 'react';
import PropTypes from 'prop-types';
import StyledDropwdown from './Dropdown.styled';
import { Link, useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from 'queries/categories';
import { CategoryLoading } from '../Loading';
import { ReactComponent as ArrowGreenIcon } from 'assets/svg/arrow-green.svg';
import { t } from 'hooks/intl/useLang';

function Dropdown({ children, ...rest }) {
  const history = useHistory();

  const { loading, data: categoryData } = useQuery(GET_CATEGORIES, {
    variables: {
      is_active: true,
      orderBy: 'id',
      limit: 8,
    },
  });

  return (
    <StyledDropwdown {...rest}>
      <div className="dropdown">
        <div className="kategori">
          {children}
          <ArrowGreenIcon className="svg" />
        </div>
        <div className="dropdown-content">
          <div className="first-content">
            {loading &&
              [...Array(6)].map((data, idx) => <CategoryLoading className="desktop" key={idx} />)}
            {categoryData?.Categories?.items?.map((data) => (
              <Link
                to={`/trainings?category=${data.id}&page=1`}
                className="dropdown-content-1 mb"
                replace={history.location.pathname === '/trainings'}
                key={data.id}
              >
                <div>
                  <img src={data.icon_default} alt={data.name} />
                </div>
                {data.name}
              </Link>
            ))}
          </div>
          <div className="second-content">
            <Link
              to="/trainings?category=all&page=1"
              className="dropdown-content-2 mb"
              replace={history.location.pathname === '/trainings'}
            >
              {t('Semua Training')}
            </Link>
            <Link
              to="/trainings?category=recommend&page=1"
              className="dropdown-content-2 mb"
              replace={history.location.pathname === '/trainings'}
            >
              {t('Rekomendasi')}
            </Link>
            <Link
              to="/trainings?category=all&sort=0"
              className="dropdown-content-2 mb"
              replace={history.location.pathname === '/trainings'}
            >
              {t('Populer')}
            </Link>
            <Link
              to="/trainings?category=all&sort=1"
              className="dropdown-content-2 mb"
              replace={history.location.pathname === '/trainings'}
            >
              {t('Terbaru')}
            </Link>
          </div>
        </div>
      </div>
    </StyledDropwdown>
  );
}

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.element,
  ]),
};

export default Dropdown;
