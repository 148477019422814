/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_USER_DETAIL, GET_USER_POINT } from 'queries/user';
import { formatDateLong } from 'utils/date';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from 'react-share';
import toast from 'react-hot-toast';
import { GET_VOUCHER_UNDANG_TEMAN } from 'queries/voucher';
import { Container, Content, Benefit, ContentBg, Referral, PoinDetail } from '../EditProfil/styled';
import ProfileDetail from 'components/atoms/ProfileDetail';
import SocialMediaShare from 'components/atoms/SocialMediaShare';
import NavigationHeader from 'components/atoms/NavigationHeader';
import BenefitUndangTeman from 'components/organisms/UndangTeman/BennefitUndangTeman';
import Toast from 'components/atoms/Notification/Toast';
import BannerSectionUndangTeman from 'components/organisms/UndangTeman/BannerSection';
import VoucherUndangTeman from 'components/organisms/UndangTeman/VoucherUndangTeman';
import { ReactComponent as ShareIcon } from 'assets/svg/share.svg';
import { Link } from 'react-router-dom';
import { copyToClipboard } from 'utils/copyToClipboard';
import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import { urlReferral, postLinkUrl } from 'utils/urlReferral';
import SharedCodeSection from 'components/organisms/UndangTeman/SharedCodeUrl';
import FAQUndangTeman from 'components/organisms/UndangTeman/FAQ';
import { t } from 'hooks/intl/useLang';

const imageBackground =
  'https://d2jr0bw5daaf2g.cloudfront.net/assets/undang-teman/undangtman_small.png';

const UndangTeman = ({ urlPath, ...rest }) => {
  const [page, setPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [copyText, setCopyText] = useState('Salin');
  const [dataUnlockVoucher, setDataUnlockVoucher] = useState([]);
  const [dataUserPackage, setDataUserPackage] = useState(false);
  const [registerLink, setRegisterLink] = useState('');
  const authData = useSelector((state) => {
    return state.Auth;
  });

  // FETCHING USER POINTS
  const [getUserPoint, { loading: userPointsLoading, data: userPointsData }] = useLazyQuery(
    GET_USER_POINT,
    {
      context: setContext(authData),
      variables: {
        limit: 5,
      },
      onCompleted: (data) => setCurrentData([...currentData, ...data?.UserPoints?.items]),
    },
  );

  // FETCHING USER DETAIL
  const { loading: userLoading = true, data: userData } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const { loading: loadingVoucher, data: dataVoucher } = useQuery(GET_VOUCHER_UNDANG_TEMAN, {
    context: setContext(authData),
    onCompleted: ({ UserVideoVouchers, UserPackages }) => {
      setDataUnlockVoucher(UserVideoVouchers?.items);
      setDataUserPackage(UserPackages?.items[0]);
    },
  });
  const referralCode = getValue(authData, 'data.referralCode');

  useEffect(() => {
    getUserPoint({
      variables: {
        page: page,
      },
    });
  }, [page]);

  useEffect(() => {
    try {
      const getLinkReferral = async () => {
        const LinkUrl = await postLinkUrl(referralCode);

        setRegisterLink(LinkUrl?.shortLink);
      };
      getLinkReferral();
    } catch (error) {}
  }, []);

  return (
    <Container bgNone className="pb-5">
      <Toast position="bottom-left" />
      <NavigationHeader title={t("Undang Teman")} saveButton={false} />
      <BannerSectionUndangTeman img={imageBackground} />
      <SharedCodeSection />
      <VoucherUndangTeman dataPackage={dataUserPackage} dataUnlock={dataUnlockVoucher} />
      <BenefitUndangTeman login />
      <ContentBg className="mb-5">
        <h6 className="nowrap mb-5">{t('Rincian Undang Teman')}</h6>
        {userPointsLoading ? (
          <text className="empty-points">Getting your user point</text>
        ) : currentData.length > 0 ? (
          <>
            {currentData.map((data) => (
              <PoinDetail className="px-5" key={data.id}>
                <div className="col">
                  <text className="detail">
                    {data.description}{' '}
                    <strong>
                      <Link to={`/user/${data.referring?.slug}`}>{data.referring?.fullname}</Link>
                    </strong>
                  </text>
                  <text className="date">{formatDateLong(data.created_at)}</text>
                </div>
                <div>
                  <text className="point-highlight">
                    {data.count_register ? `${data.count_register}/3` : data.message}
                  </text>
                </div>
              </PoinDetail>
            ))}
            {userPointsData?.total > 5 && (
              <button className="show-more" onClick={() => setPage(2)}>
                Show More
              </button>
            )}
          </>
        ) : (
          <text className="empty-point">{t('Belum ada poin')}</text>
        )}
      </ContentBg>
      <FAQUndangTeman login />
    </Container>
  );
};

export default UndangTeman;
