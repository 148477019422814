import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_FILTERED_CATEGORY_TRAINING } from 'queries/training';

import { Section, ContainerCard } from './styled';
import CardTraining from 'components/molecule/Cards/CardTraining';
import { t } from 'hooks/intl/useLang';

const TrainingLainnya = ({ categoryId }) => {
  const [getTraining, { data: categoryTrainingId }] = useLazyQuery(GET_FILTERED_CATEGORY_TRAINING, {
    variables: {
      input: {
        categoryId: categoryId,
        limit: 8,
      },
    },
  });

  useEffect(() => {
    if (categoryId) {
      getTraining();
    }
  }, [categoryId]);

  return (
    <Section>
      <h6>{t('Nonton Training Lainnya')}</h6>
      <ContainerCard>
        {categoryTrainingId?.Trainings?.items.map((training, i) => {
          return <CardTraining className="card" key={i} training={training} />;
        })}
      </ContainerCard>
    </Section>
  );
};

export default TrainingLainnya;
