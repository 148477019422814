import React from 'react';
import PageLayout from '../../../components/pagelayout/Template';
import { LandingContainer } from './styles';
import BannerTBM from '../../../components/organisms/TMBSection/BannerTBM';
import DescSectionTBM from '../../../components/organisms/TMBSection/DescSectionTBM';
import TestSection from '../../../components/organisms/TMBSection/TestSection';

const TMBLandingPage = () => {
  const seo = {
    title: 'Tes Minat Bakat',
    description: 'Tes Minat Bakat',
    canonical: null,
  };

  return (
    <PageLayout seo={seo}>
      <LandingContainer>
        <BannerTBM />
        <DescSectionTBM />
        <TestSection />
      </LandingContainer>
    </PageLayout>
  );
};

export default TMBLandingPage;
