import React from 'react';
import { Container, Title, Link } from './index.styled';
import { t } from 'hooks/intl/useLang';

function PerusahaanLink({ isAuth }) {
  return (
    <Container className="perusahaan-container">
      <Title>{t('Perusahaan')}</Title>
      <Link href="/tentang-kami">{t('Tentang Kami')}</Link>
      <Link href="/faq/umum">FAQ</Link>
      <Link href="/bantuan" target="blank">
        {t('Bantuan')}
      </Link>
      <Link href="/kebijakan-privasi" target="blank">
        {t('Kebijakan Privasi')}
      </Link>
      <Link href="/syarat-ketentuan" target="blank">
        {t('Syarat & Ketentuan')}
      </Link>
      <Link href="/blog" target="blank">
        {t('Blog')}
      </Link>
      {isAuth ? (
        <Link href="/user/settings/invite">{t('Undang Teman')}</Link>
      ) : (
        <Link href="/undang-teman">{t('Undang Teman')}</Link>
      )}
    </Container>
  );
}

export default PerusahaanLink;
