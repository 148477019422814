import styled from 'styled-components';

export const Container = styled.section`
  padding: 24px 0px 30px 50px;
  background-color: ${(props) => props.theme.backgroundColor.primary};
  position: relative;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    z-index: 100;
    position: sticky;
    top: 0;
  }
`;

export const TitleTraining = styled.h2`
  ${(props) => props.theme.font.primary.title};
  font-size: 20px;
  text-align: left;
  line-height: 24px;
  margin-top: 0;

  span {
    padding: 4px;

    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    color: #141414;
    background-color: ${(props) => props.theme.color.primary};
    border-radius: 3px;
  }
`;

export const Paragraf = styled.p`
  ${(props) => props.theme.font.secondary.subtitle};
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

export const Video = styled.video`
  width: 100%;
  height: 505px;
  border-radius: 5px;
  object-fit: fill;

  video[poster] {
    width: 100%;
    border-radius: 5px;
    background-position: center;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    height: 327px;
    border-radius: 0px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    height: 227px;
  }
`;
export const ContainerTitle = styled.div`
  position: relative;
  padding-right: 100px;
  margin-top: 24px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    display: none;
  }
`;
export const ContainerSvg = styled.div`
  position: absolute;
  top: -12px;
  right: -1px;
  /* width: 150px; */
  display: flex;
  justify-content: space-between;

  div.left {
    margin-right: 15px;
  }
  svg {
    cursor: pointer;
  }
`;
export const ToolTipText = styled('span')({
  visibility: 'hidden',
  width: '120px',
  fontSsize: '12px',
  backgroundColor: '#202020',
  color: '#fff',
  textAlign: 'center',
  borderRadius: '6px',
  padding: '5px 0',
  position: 'absolute',
  zIndex: 1,
  bottom: '150%',
  left: '50%',
  marginLeft: '-60px',
  ':after': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: '#202020 transparent transparent transparent',
  },
});

export const ToolTip = styled('div')({
  position: 'relative',
  display: 'inline-block',
  borderBottom: '1px dotted #202020',
  ':hover span': {
    visibility: 'visible',
  },
});
