import React from 'react';
import { Wrapper, Title, Content } from './styled';

const CardPointPrakerja = ({ point, content }) => {
  return (
    <Wrapper>
      <Title>{point}</Title>
      <Content>{content}</Content>
    </Wrapper>
  );
};

export default CardPointPrakerja;
