import { gql } from '@apollo/client';

export const SUBMIT_QUIZ = gql`
  mutation SUBMIT_QUIZ(
    $trainingId: Int
    $trainingSectionId: Int
    $trainingQuizId: Int
    $answer: String
  ) {
    SubmitUserQuizAnswer(
      input: {
        trainingId: $trainingId
        trainingSectionId: $trainingSectionId
        trainingQuizId: $trainingQuizId
        answer: $answer
      }
    ) {
      updated_at
    }
  }
`;

export const SUBMIT_POST_TEST = gql`
  mutation SUBMIT_POST_TEST($input: UserAnswerPostTestInput) {
    SubmitUserPostTestAnswer(input: $input) {
      updated_at
    }
  }
`;

export const SUBMIT_TRAINING_UPLOAD_USER = gql`
  mutation SUBMITUSERHW($input: UserHWSubmissionInput) {
    SubmitUserHW(input: $input) {
      id
      training_id
    }
  }
`;
