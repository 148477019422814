/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';

import {
  Container,
  FormSection,
  Separator,
  InputAvatar,
  Label,
  InputUserSetting,
  InputContainer,
  RequiredLabel,
} from './styled';
import { ProfilePic } from 'components/atoms/ProfileDetail/styled';
import Button from 'components/atoms/Button';
import CategoryDropdown from 'components/atoms/CategoryDropdown';
import Option from 'components/atoms/CategoryDropdown/Option';
import Menu from 'components/atoms/CategoryDropdown/Menu';
import NavigationHeader from 'components/atoms/NavigationHeader';
import Toast from 'components/atoms/Notification/Toast';

import { ReactComponent as EditIcon } from 'assets/svg/edit-profile-round.svg';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CATEGORIES } from 'queries/categories';
import RichTextInput from 'components/atoms/RichTextInput';
import { getValue, setContext } from 'utils';
import { GET_USER_DETAIL } from 'queries/user';
import { useDispatch, useSelector } from 'react-redux';

import ProfilePicDefault from 'assets/svg/User Default.svg';
import { checkMobile } from 'utils/checkMobile';
import { deserialize, serialize } from 'utils/serialize';
import { UPDATE_PROFILE } from 'mutation/user';
import { authOperations } from 'state/modules/auth';
import LoadingDefaultLogo from 'components/atoms/Loading/LoadingDefaultLogo';
import { useHistory } from 'react-router-dom';
import FormError from 'components/atoms/FormError';
import { t } from 'hooks/intl/useLang';

const EditProfil = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [image_blob, setImageBlob] = useState(null);
  const [image, setImage] = useState([]);

  const authData = useSelector((state) => {
    return state.Auth;
  });
  const dispatch = useDispatch();

  const history = useHistory();

  // FETCHING CATEGORIES DATA
  const { loading: categoryLoading = true, data: categoryData } = useQuery(GET_CATEGORIES, {
    variables: {
      is_active: true,
      order_by: 'id',
      limit: 8,
    },
  });

  // FETCHING USER DATA
  const { loading: userLoading = true, data: userData } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  // UPDATE PROFILE
  const [updateProfile, { loading: loadingUpdateProfile }] = useMutation(UPDATE_PROFILE, {
    context: setContext(authData),
    onCompleted: (Profile) => {
      toast('Profil saya berhasil diubah');
      dispatch(
        authOperations.setAuth({
          ...authData.data,
          slug: Profile.UpdateProfile.slug,
        }),
      );
      reset();
      setTimeout(() => {
        history.push(`/user/me`);
      }, 2000);
    },
    refetchQueries: (Profile) => {
      return [
        {
          query: GET_USER_DETAIL,
          context: setContext(authData),
          variables: {
            slug: Profile.data.UpdateProfile.slug,
          },
        },
        {
          query: GET_CATEGORIES,
          variables: {
            is_active: true,
            order_by: 'id',
            limit: 8,
          },
        },
      ];
    },
    onError: (err) => {
      // console.log(err.message);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    errors,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      avatar: userData?.ProfileUser?.avatar || '',
      fullname: userData?.ProfileUser?.fullname || '',
      profession: userData?.ProfileUser?.profession || '',
      about: [
        {
          type: 'paragraph',
          children: [{ text: `Hello World!` }],
        },
      ],
      facebook: userData?.ProfileUser?.facebook || '',
      website: userData?.ProfileUser?.website || '',
      linkedin: userData?.ProfileUser?.linkedin || '',
      instagram: userData?.ProfileUser?.instagram || '',
      twitter: userData?.ProfileUser?.twitter || '',
      email: userData?.ProfileUser?.User?.email || '',
      phone_number: userData?.ProfileUser?.User?.phone_number || '',
      category_ids: userData?.ProfileUser?.categories || [],
    },
  });

  const checkChanges = !(
    dirtyFields?.profession ||
    dirtyFields?.fullname ||
    dirtyFields?.profession ||
    dirtyFields?.facebook ||
    dirtyFields?.instagram ||
    dirtyFields?.website ||
    dirtyFields?.linkedin ||
    dirtyFields?.twitter ||
    dirtyFields?.about ||
    dirtyFields?.avatar ||
    dirtyFields?.category_ids ||
    dirtyFields?.phone_number ||
    dirtyFields?.email ||
    image.length > 0
  );

  const formatData = (data) => {
    if (data === undefined || data === null) return;

    const newData = [];

    for (let item of data) {
      const obj = {};
      obj.value = item.id;
      obj.label = item.name;
      obj.icon = item.icon_default;
      newData.push(obj);
    }

    return newData;
  };

  const submitData = ({
    fullname,
    profession,
    category_ids = [],
    about,
    facebook,
    linkedin,
    email,
    instagram,
    twitter,
    website,
    phone_number,
  }) => {
    // DO SUBMIT DATA HERE
    if (typeof category_ids[0] !== 'number') {
      category_ids = category_ids.map((x) => x.value);
    }

    if (image) {
      updateProfile({
        variables: {
          input: {
            fullname,
            profession,
            category_ids: category_ids,
            about: serialize({ children: about }),
            facebook,
            linkedin,
            instagram,
            email,
            twitter,
            website,
            phoneNumber: phone_number,
          },
          file: image[0],
        },
      });
    } else {
      updateProfile({
        variables: {
          input: {
            fullname,
            profession,
            category_ids: category_ids,
            about: serialize({ children: about }),
            facebook,
            linkedin,
            instagram,
            twitter,
            website,
            email,
            phoneNumber: phone_number,
          },
        },
      });
    }
  };

  const normalizeNumber = (value) => {
    return (
      value
        .replace(/\s/g, '')
        .match(/\d{1,4}/g)
        ?.join('')
        .substr(0, 14) || ''
    );
  };

  useEffect(() => {
    imagePreview();
    setIsMobile(checkMobile());

    if (userData) {
      // FIXED RICH TEXT
      const document = new DOMParser().parseFromString(userData?.ProfileUser?.about, 'text/html');
      const deserial = deserialize(document.body);

      [
        { name: 'fullname', value: userData?.ProfileUser?.fullname || '' },
        { name: 'profession', value: userData?.ProfileUser?.profession || '' },
        {
          name: 'about',
          value:
            deserial.length > 0
              ? deserial
              : [
                  {
                    type: 'paragraph',
                    children: [{ text: `Hi! my name is ${userData?.ProfileUser?.fullname}` }],
                  },
                ],
        },
        { name: 'facebook', value: userData?.ProfileUser?.facebook || '' },
        { name: 'website', value: userData?.ProfileUser?.website || '' },
        { name: 'linkedin', value: userData?.ProfileUser?.linkedin || '' },
        { name: 'instagram', value: userData?.ProfileUser?.instagram || '' },
        { name: 'twitter', value: userData?.ProfileUser?.twitter || '' },
        { name: 'email', value: userData?.ProfileUser?.User?.email || '' },
        { name: 'phone_number', value: userData?.ProfileUser?.User?.phone_number || '' },
        {
          name: 'category_ids',
          value: userData?.ProfileUser?.categories
            ? userData?.ProfileUser?.categories.map((x) => x.id)
            : [],
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [userData]);

  const imagePreview = () => {
    if (userData?.ProfileUser?.avatar !== '' && watch('avatar').length < 1) {
      return userData?.ProfileUser?.avatar;
    } else if (!userData?.ProfileUser?.avatar && watch('avatar').length < 1) {
      return ProfilePicDefault;
    }
  };

  function onImageChange(e) {
    setImage(e.target.files);
    setImageBlob(URL.createObjectURL(e.target.files[0]));
    return e.target.files;
  }

  return (
    <>
      <Toast position="bottom-left" />
      {userLoading && <LoadingDefaultLogo />}
      {userData && categoryData && (
        <Container>
          <NavigationHeader
            form="edit-profile-form"
            title={'Edit Profil'}
            checkChanges={checkChanges}
          />
          <FormSection id="edit-profile-form" onSubmit={handleSubmit(submitData)}>
            <div className="flex none">
              <h6 className="none edit-profile">Profil Saya</h6>
              {userData?.ProfileUser?.User?.is_active === false && (
                <RequiredLabel className="static">
                  <span className="required" />
                  <i>({t('Email belum terverifikasi')})</i>
                </RequiredLabel>
              )}
            </div>
            <div className="profile-pic-form">
              <div className="overlay-image">{t('Upload Foto Profil')}</div>
              <ProfilePic
                className="mr-0"
                src={
                  image_blob
                    ? image_blob
                    : userData?.ProfileUser?.avatar
                    ? userData?.ProfileUser?.avatar
                    : ProfilePic
                }
              />
              <InputAvatar
                onChange={onImageChange}
                type="file"
                accept="image/*"
                name="avatar"
                id="avatar"
              />
              <EditIcon className="edit-icon" />
            </div>
            {userData?.ProfileUser?.User?.is_active === false && isMobile && (
              <RequiredLabel className="static">
                <span className="required" />
                <i>({t('Email belum terverifikasi')})</i>
              </RequiredLabel>
            )}
            <InputContainer>
              <RequiredLabel className="none">
                <span className="required" />
                {t('Wajib Diisi')}
              </RequiredLabel>
              <Label for="fullname">
                {t('Nama')} <span className="required" />
              </Label>
              <InputUserSetting
                type="text"
                ref={register({ required: true })}
                name="fullname"
                id="fullname"
                placeholder="e.g Marley Franci"
                autoComplete="off"
              />
            </InputContainer>
            <InputContainer>
              <Label for="profession">{t('Pekerjaan')}</Label>
              <InputUserSetting
                type="text"
                ref={register}
                name="profession"
                id="profession"
                placeholder="e.g Karyawan Swasta"
                autoComplete="off"
              />
            </InputContainer>
            <InputContainer>
              <Label for="category_ids">{t('Minat')} (max. 3)</Label>
              <Controller
                control={control}
                name="category_ids"
                render={({ onChange, value }) => {
                  return (
                    <CategoryDropdown
                      blank={true}
                      isMulti
                      defaultValue={formatData(userData?.ProfileUser?.categories)}
                      isSelected={true}
                      isLoading={categoryLoading}
                      options={formatData(categoryData?.Categories?.items)}
                      placeholder="Pilih Minat"
                      closeMenuOnSelect={false}
                      onChange={onChange}
                      hideSelectedOptions={false}
                      components={{ Option, Menu }}
                      dark
                    />
                  );
                }}
              />
            </InputContainer>
            <InputContainer>
              <Label for="about">{t('Tentang Kamu')}</Label>
              {/* ALREADEY FIXED RICH TEXT */}
              <Controller
                control={control}
                name="about"
                render={({ onChange, value }) => (
                  <RichTextInput onChange={onChange} value={value} type="plain" />
                )}
              />
            </InputContainer>
            <Separator />
            <h6 className="edit-profile">{t('Link Sosial Media')}</h6>
            <InputContainer>
              <Label for="facebook">Facebook</Label>
              <InputUserSetting
                type="text"
                ref={register}
                name="facebook"
                id="facebook"
                placeholder="e.g https://www.facebook.com"
                autoComplete="off"
              />
            </InputContainer>
            <InputContainer>
              <Label for="linkedin">LinkedIn</Label>
              <InputUserSetting
                type="text"
                ref={register}
                name="linkedin"
                id="linkedin"
                placeholder="e.g https://www.linkedin.com"
                autoComplete="off"
              />
            </InputContainer>
            <InputContainer>
              <Label for="instagram">Instagram</Label>
              <InputUserSetting
                type="text"
                ref={register}
                name="instagram"
                id="instagram"
                placeholder="e.g https://www.instagram.com"
                autoComplete="off"
              />
            </InputContainer>
            <InputContainer>
              <Label for="twitter">Twitter</Label>
              <InputUserSetting
                type="text"
                ref={register}
                name="twitter"
                id="twitter"
                placeholder="e.g https://www.twitter.com"
                autoComplete="off"
              />
            </InputContainer>
            <Separator />
            <h6 className="edit-profile">{t('Akun Saya')}</h6>
            <InputContainer>
              <Label for="email">
                Email <span className="required" />
              </Label>
              <Controller
                control={control}
                name="email"
                render={({ onChange, value }) => (
                  <InputUserSetting
                    className={
                      userData?.ProfileUser?.User?.email !== null
                        ? 'disabled'
                        : errors.email
                        ? 'form-error'
                        : ''
                    }
                    disabled={userData?.ProfileUser?.User?.email !== null ? true : false}
                    type="email"
                    ref={register({
                      required: t('Email wajib diisi'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t('Format email salah. Contoh: terampil@gmail.com'),
                      },
                    })}
                    name="email"
                    id="email"
                    value={value ? value : userData?.ProfileUser?.User?.email}
                    onChange={onChange}
                    placeholder={t("Tambahkan Email")}
                  />
                )}
              />
              <Label />
              {errors.email && <FormError lengkapiProfile>{errors.email.message}</FormError>}
            </InputContainer>
            <InputContainer>
              <Label for="phone_number">
                {t('Nomor HP')} <span className="required" />
              </Label>
              <InputUserSetting
                className={`${
                  userData?.ProfileUser?.User?.phone_number !== null
                    ? 'disabled'
                    : errors.phone_number
                    ? 'form-error'
                    : ''
                }`}
                disabled={userData?.ProfileUser?.User?.phone_number !== null ? true : false}
                type="tel"
                ref={register({
                  required: t('Nomor HP wajib diisi'),
                  patter: {
                    value: /^8\d{8,11}$/,
                    message: t('Nomor HP tidak valid'),
                  },
                })}
                name="phone_number"
                id="phone_number"
                placeholder={t("Tambahkan No. Telp")}
                onChange={(val) => {
                  const { value } = val.target;
                  val.target.value = normalizeNumber(value);
                }}
              />
              <Label />
              {errors.phone_number && (
                <FormError lengkapiProfile>{errors.phone_number.message}</FormError>
              )}
            </InputContainer>
            <div className="bottom-form">
              <p>
                {t('Untuk mengubah Email dan Nomor HP, silahkan')} &nbsp;
                <a
                  href={`mailto:halo@terampil.com?subject=Request Perubahan Nomor ${userData?.ProfileUser?.User?.phone_number} HP nama ${userData?.ProfileUser?.fullname} &body=Nomor HP lama %0D%0A No Hp Baru %0D%0A`}
                >
                  {' '}
                  <u>{t('klik disini')}</u>
                </a>
              </p>
              <Button
                type="submit"
                disabled={checkChanges}
                medium
                className={`${checkChanges ? 'disabled-button' : ''} none save-button`}
              >
                {t('Simpan')}
              </Button>
            </div>
          </FormSection>
        </Container>
      )}
    </>
  );
};

export default EditProfil;
