import styled from 'styled-components';

export const TitleStyled = styled.h2`
  font-family: Archivo;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fdfdfd;
  margin: 0 0px 30px;
  padding: 30px 0 50px 0;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 20px;
    padding: 30px 0 40px 0;
    margin: 0;
  }
`;
