import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 160px;
  margin: 32px 0;
  display: flex;
  justify-content: center;

  text.CircularProgressbar-text {
    font-family: Inter;
    font-size: 20px;
  }
`;
