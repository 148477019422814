import CaraGetKodeVoucherPrakerja from 'components/molecule/CaraGetKodeVoucherPrakerja';
import PointLangkahPrakerja from 'components/molecule/PointLangkahPrakerja';
import React from 'react';
import { Container, Title, Wrapper } from './styled';
import { t } from 'hooks/intl/useLang';

const LangkahPrakerja = () => {
  return (
    <Container>
      <PointLangkahPrakerja />
      <Wrapper>
        <Title>
          {t('Cara Mendapatkan Kode Voucher di Tokopedia & MauBelajarApa untuk Mengikuti Online Training Gratis di Terampil')}
        </Title>
        <CaraGetKodeVoucherPrakerja />
      </Wrapper>
    </Container>
  );
};

export default LangkahPrakerja;
