import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';

const styles = {
  bmMenuWrap: {
    position: 'fixed',
    background: '#373737',
    top: '0',
    overflowX: 'hidden !important',
    overflowY: 'auto !important',
  },
  bmBurgerButton: {
    display: 'none',
  },
  bmMenu: {
    background: '#373737',
  },
  bmItemList: {
    fontSize: '16px',
    overflowY: 'hidden !important',
    lineHeight: '35px',
    textAlign: 'left',
  },
  bmItem: {
    textAlign: 'left',
    paddingBottom: '16px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
  },
  bmOverlay: {
    top: '0',
  },
  bmCross: { height: '100%', width: '30%' },
};

const SideMenu = ({ isOpen, setIsOpen, children }) => {
  return (
    <Menu
      pageWrapId={'page-wrap'}
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      width={'280px'}
      customCrossIcon={
        <CloseIcon className="button" style={{ height: '100%', background: 'none' }} />
      }
      styles={{
        ...styles,
        bmCrossButton: {
          width: '40px',
          height: '40px',
          borderRadius: '100%',
          backgroundColor: '#373737',
          top: '15px',
          right: '-60px',
          display: `${isOpen ? 'block' : 'none'}`,
        },
      }}
    >
      {children}
    </Menu>
  );
};

export default SideMenu;
