import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  font-weight: 400;
`;

export const Icon = styled.div`
  width: 50px;
  height: 50px;

  flex-shrink: 0;

  margin-right: 16px;

  border-radius: 5px;

  background: #c4c4c4;

  display: grid;
  place-items: center;

  svg {
    max-width: 30px;
  }
`;

export const Detail = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 22px 0;

  border-bottom: 1px solid #626262;

  & > div {
    display: grid;
  }

  .primary {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .secondary {
    font-size: 12px;
    color: #c4c4c4;
  }

  .button-container {
    cursor: pointer;

    padding: 0px;

    font-size: 16px;

    background: transparent;
    border: none;

    font-family: Inter;
    color: #fdfdfd;

    display: grid;
    place-items: center;
    grid-auto-flow: column;

    & > svg {
      margin-right: 10px;
    }
  }
`;
