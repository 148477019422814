import React from 'react';
import { checkMobile } from 'utils/checkMobile';

import { Container, Button } from './styled';
import { t } from 'hooks/intl/useLang';

const SearchNotFound = ({ setFocusBarMobile, blog }) => {
  const onClickButton = () => {
    if (!checkMobile()) {
      document.getElementById('searchBar').focus();
    } else {
      setFocusBarMobile(true);
    }
  };
  return (
    <Container>
      <h6>{t('Hasil Pencarian tidak ditemukan')}</h6>
      <p>
        {blog
          ? t('Coba cari artikel menggunkan kata kunci yang lain')
          : t('Coba cari training atau trainer menggunkan kata kunci yang lain')}
      </p>
      <Button onClick={onClickButton}>Ganti Kata Kunci</Button>
    </Container>
  );
};

export default SearchNotFound;
