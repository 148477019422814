import { gql } from '@apollo/client';

export const SIGNUP_EMAIL = gql`
  mutation SIGNUP_BY_EMAIL($input: SignupEmailInput) {
    DoSignupByEmail(input: $input) {
      id
      accessToken
      Profile {
        user_id
        slug
        referral_code
        all_access
        all_access_end_date
      }
    }
  }
`;

export const SIGNUP_PHONE = gql`
  mutation signUpPhone($input: SignupPhoneInput) {
    DoSignUpByPhone(input: $input) {
      free_trial
      phone_number
      accessToken
      id
      Profile {
        profession
        slug
        gender
        website
        twitter
        facebook
        linkedin
        referral_code
        total_point
        fullname
        is_skip
        all_access
        all_access_end_date
        categories {
          id
          name
        }
      }
    }
  }
`;

export const REFERRAL_CODE_CHECK = gql`
  mutation checkReferral($code: String) {
    ReferralCodeCheck(input: { referralCode: $code }) {
      # status
      # statusText
      fullname
      referral_code
    }
  }
`;

export const REFERRAL_CODE_SUBMIT = gql`
  mutation submitReferral($code: String) {
    ReferralCodeSubmit(input: { referral_code: $code }) {
      # status
      # statusText
      fullname
      referral_code
    }
  }
`;

export const SIGNUP_TRAINER = gql`
  mutation FORM_MENJADI_TRAINER($input: TrainerSubmissionInput, $file: Upload) {
    CreateTrainerSubmissions(input: $input, file: $file) {
      status
    }
  }
`;

export const SUBMIT_COLLABORATION = gql`
  mutation SUBMIT_COLLABORATION($input: CollaborationSubmissionInput) {
    CollaborationSubmissionSubmit(input: $input) {
      status
    }
  }
`;

export const SIGNUP_USER_B2B = gql`
  mutation SIGNUP_USER_B2B($input: SignupUserB2BInput) {
    SignupUserB2b(input: $input) {
      id
      accessToken
      Profile {
        user_id
        slug
        referral_code
        all_access
        all_access_end_date
      }
    }
  }
`;
