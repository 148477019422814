import React from 'react';
import { Wrapper, Content, Point, Body } from './styled';

const CaraGetKodeVoucherPrakerja = () => {
  return (
    <Wrapper>
      <Content>
        <Point>1</Point>
        <Body>
          Anda sebagai pemegang Kartu Prakerja bisa membeli voucher training prakerja dengan
          mengklik link berikut{' '}
          <a href="https://www.tokopedia.com/" target="blank">
            Tokopedia
          </a>{' '}
          &{' '}
          <a href="https://www.maubelajarapa.com/" target="blank">
            MauBelajarApa
          </a>
          .
        </Body>
      </Content>
      <Content>
        <Point>2</Point>
        <Body>Cari dan pilih Online Training yang disediakan oleh Terampil sesuai minat Anda.</Body>
      </Content>
      <Content>
        <Point>3</Point>
        <Body>
          Bayar Online Training tersebut menggunakan Nomor Kartu Prakerja Anda untuk mendapatkan
          Kode Voucher-nya.
        </Body>
      </Content>
      <Content>
        <Point>4</Point>
        <Body>
          Masuk ke website terampil atau mobile apps Terampil di IOS & Android. Jika belum punya
          akun terampil Anda harus mendaftar dulu. Tombol daftar ada di pojok kanan atas.
        </Body>
      </Content>
      <Content>
        <Point>5</Point>
        <Body>
          Kalau sudah masuk ke akun terampil, klik Mulai Berlangganan di kanan atas. Kemudian pilih
          Langganan Prakerja ikuti instruksi yang ada, lalu masukkan Kode Voucher di kolom
          pembayaran khusus Pemegang Kartu Prakerja.
        </Body>
      </Content>
    </Wrapper>
  );
};

export default CaraGetKodeVoucherPrakerja;
