import styled from 'styled-components';

export const Container = styled.section`
  top: 77px;
  background-color: #131414;
  position: sticky;
  z-index: 2;

  .link {
    margin: 0 0 -2px 50px;
  }

  a {
    text-decoration: none;
    color: #c4c4c4;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    .link {
      margin: 0 0 -2px 30px;
    }
  }
  @media screen and ${(props) => props.theme.breakpoints.md} {
    overflow: auto;

    .link {
      padding: 16px;
      margin: 0 27px 0 30px;
    }
    top: 180px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    top: 210px;

    .link {
      padding: 16px;
      margin: 0 23px 0 0px;
    }
  }
`;

export const Line = styled.div`
  border-bottom: 1px solid #626262;
  display: flex;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: inline-flex;
  }
`;

export const LiveSection = styled.div`
  background: #00debf;
  border: 1px solid #141414;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  /* identical to box height */

  color: #141414;
  position: absolute;
  top: -10px;
  right: -20px;
  width: 40px;
  padding: 3px 8px;
`;
