import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 36px 35px 30px 32px;
  margin: 0px 20px;
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    gap: 0;
    border: 0;
    padding: 0;
    margin: 0 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 0;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin-bottom: 24px;
  }
`;

export const Point = styled.h6`
  margin: 0;
  margin-right: 24px;
  font-size: 30px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin-right: 12px;
    align-self: flex-start;
  }
`;

export const Body = styled.p`
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  line-height: 24px;
  margin: 0;
  max-width: 970px;

  a {
    text-decoration: none;
    color: #00debf;
    border-bottom: 1px solid #00debf;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-size: 14px;
    line-height: 21px;
  }
`;
