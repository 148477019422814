import styled from 'styled-components';

export const Section = styled.div`
  background: #373737;

  padding: 16px;

  margin: 16px 0;
  margin-bottom: 50px;

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }

  h6 {
    margin: 0;
  }

  .billing-membership {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 0;
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Inter';
    }
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    border-radius: 0px;
    padding: 12px 16px;
    margin-bottom: 32px;

    h6 {
      font-weight: 700;
      font-size: 16px;
      margin: 0;
    }
    .billing-membership {
      p {
        margin-top: 8px;
      }
    }
  }
`;

export const Button = styled.button`
  background: transparent;

  width: 140px;
  height: 40px;

  padding: 0;

  border-radius: 5px;

  margin: 0 6px;

  cursor: pointer;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  &.change {
    color: #f18f01;
    border: 1px solid #f18f01;
  }

  &.cancel {
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
  }

  &.choose {
    color: ${({ theme }) => theme.color.primary};
    border: 1px solid ${({ theme }) => theme.color.primary};
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    height: 35px;

    border-radius: 3px;
  }
`;
