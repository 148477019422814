import { gql } from '@apollo/client';

export const GET_USER_CERTIFICATE = gql`
  query GET_USER_CERTIFICATE($trainingId: Int) {
    UserCertificate(trainingId: $trainingId) {
      is_prakerja
      type
      link
      score
      Result {
        summary
      }
      file {
        link
      }
    }
  }
`;

export const DETAIL_CERTIFICATE = gql`
  query getDetailCertificate($serialNumber: String) {
    DetailCertificate(serialNumber: $serialNumber) {
      Profile {
        fullname
      }
      id
      score
      user_id
      training {
        id
        title
        category {
          id
          name
          slug
        }
        trainer {
          id
          fullname
        }
      }
      file {
        id
        filename
        link
        created_at
      }
      Result {
        levelLearning
        levelReaction {
          minutes
          seconds
        }
        summary
        isPassed
      }
      serial_number
    }
  }
`;
