import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 20px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    gap: 10px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 2rem;
    gap: 10px;
  }
`;

export const Image = styled.img`
  width: 94%;
  height: 433px;
  z-index: 2;
  position: relative;
  /* object-fit: contain; */
  border-radius: 5px;
  background-size: contain;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    height: 401px;
    width: 95%;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    height: 210px;
    object-fit: contain;
  }
`;

export const ContainerImage = styled.div`
  position: relative;
  padding-right: 10px;
  width: 80%;
  height: 500px;
  img {
    border-radius: 5px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    padding-right: 0;
    height: auto;
  }
`;

export const BlackBackgriundImage = styled.div`
  width: 35rem;
  height: 380px;
  position: absolute;
  background-color: #1d1d1d;
  border-radius: 20px;
  /* z-index: -1; */
  top: 100px;
  right: 0px;
  /* z-index: 30;
  overflow: visible; */

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    height: 330px;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    display: none;
  }
`;

export const ChatContainer = styled.div`
  width: 55%;
  height: auto;
  padding-left: 30px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 56%;
    padding-left: 10px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    padding-left: 0;
  }
`;
