import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 16px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    flex-wrap: wrap;
    background: #e5e5e5;
    margin: 24px 0;
    padding: 16px 6px;
    justify-content: center;
    gap: 8px;
  }
`;

export const OptionSelec = styled.button`
  outline: none;
  border: none;
  width: 90px;
  box-sizing: border-box;
  height: 40px;
  background-color: ${(props) => (props.select ? '#266960' : '#e5e5e5')};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  border: ${(props) => (props.select ? '2px solid #00DEBF' : '')};
  cursor: pointer;

  .circle {
    width: 20px;
    height: 20px;

    border-radius: 100%;

    border: solid 1px #626262;
  }

  .circle-select {
    width: 20px;
    height: 20px;

    border-radius: 100%;
    border: 3px solid #00debf;
    box-sizing: border-box;
  }
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    width: 40%;
  }
`;

export const ListQuestion = styled.div`
  flex: 1;
  background-color: #e5e5e5;
  border-radius: 5px;
  box-sizing: border-box;
  min-height: 40px;
  margin-top: 16px;
  padding: 0 0 0 24px;
  display: flex;
  align-items: center;

  & > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    /* identical to box height, or 187% */

    color: #202020;
    margin: 0px;
    text-align: left;
  }
  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-size: 14px;
    line-height: 28px;
    margin-top: 4px;
  }
`;
