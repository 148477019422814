import { gql } from '@apollo/client';

export const GET_TOTAL_RETAKE = gql`
  query GET_TOTAL_RETAKE($input: CheckTotalRetakeRequest) {
    CheckTotalRetake(input: $input) {
      status
      statusText
      count
    }
  }
`;
