import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_USER_DETAIL } from 'queries/user';
import { GET_USER_ORDERS, GET_USER_LAST_ORDER_ACTIVE } from 'queries/orders';
import { getValue, setContext } from 'utils';

import NavigationHeader from 'components/atoms/NavigationHeader';
import Langganan from 'components/molecule/Langganan';
import BatalLanggananModal from 'components/molecule/Modal/BatalLanggananModal';
import SmallDarkModal from 'components/molecule/Modal/SmallDarkModal';
import PembayaranCard from 'components/molecule/PembayaranCard';
import { Container } from '../EditProfil/styled';
import { EmptyState } from './styled';
import LoadingDefaultLogo from 'components/atoms/Loading/LoadingDefaultLogo';
import { t } from 'hooks/intl/useLang';

const Pembayaran = () => {
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const [isPaid, setIsPaid] = useState(false);

  const [getUserDetail, { data, loading }] = useLazyQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });
  const { data: dataOrders, loading: loadingUserOrders } = useQuery(GET_USER_ORDERS, {
    context: setContext(authData),
  });

  const { data: dataLastOrder, loading: loadingOrderActive } = useQuery(
    GET_USER_LAST_ORDER_ACTIVE,
    {
      fetchPolicy: 'cache-and-network',
      context: setContext(authData),
      onCompleted: (data) => {
        if (data.UserOrders.items.length > 0) {
          if (data.UserOrders.items[0].status === 'Paid') {
            setIsPaid(true);
          }
        }
      },
    },
  );

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    getUserDetail();
    if (isPaid) {
      getUserDetail();
    }
  }, [isPaid, getUserDetail]);

  return (
    <Container bgNone>
      {(loadingUserOrders || loading || loadingOrderActive) && <LoadingDefaultLogo />}
      <NavigationHeader title={'Pembayaran'} saveButton={false} />
      {data && dataLastOrder && dataOrders && (
        <>
          <div className="xs-mt-3">
            {/* <div>
              <h6 className="xs-my-3">Billing Membership</h6>
            </div> */}
            {/* {data && dataLastOrder && (
              <Langganan
                is_unsubscription={data?.ProfileUser?.is_unsubscription}
                is_subscription={data?.ProfileUser?.is_subscription}
                setShowCancelModal={setShowCancelModal}
                data={dataLastOrder.UserOrders}
              />
            )} */}
          </div>
          <div>
            <div>
              <h6 className="xs-my-3">{t('Riwayat Pembelian')}</h6>
            </div>
            {dataOrders?.UserOrders?.items?.length <= 0 && (
              <EmptyState className="xs-my-3">{t('Tidak ada data yang ditampilkan')}</EmptyState>
            )}
            {dataOrders?.UserOrders &&
              dataOrders?.UserOrders?.items?.map((data, index) => (
                <PembayaranCard data={data} key={index} invoice={data.invoice_number} />
              ))}
          </div>
        </>
      )}
      <BatalLanggananModal
        isOpen={showCancelModal}
        onRequestClose={() => setShowCancelModal(false)}
        setShowCancelModal={setShowCancelModal}
        setShowSuccessModal={setShowSuccessModal}
      />
      <SmallDarkModal
        title="Batal Langganan Berhasil"
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        modalState={setShowSuccessModal}
      >
        <p>
          {t('Pembatalan langganan akun kamu berhasil. Sekarang akun kamu sudah downgrade menjadi free.')}
        </p>
      </SmallDarkModal>
    </Container>
  );
};

export default Pembayaran;
