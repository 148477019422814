import { gql } from '@apollo/client';

export const MARK_READ_NOTIFICATION = gql`
  mutation MARKASREAD($input: MarkReadNotificationInput) {
    MarkNotificationById(input: $input) {
      id
    }
  }
`;

export const MARK_ALL_NOTIFICATION = gql`
  mutation MARKALL($input: MarkReadNotificationInput) {
    MarkNotifications(input: $input) {
      status
    }
  }
`;
