import React from 'react';
import StyledLink from './A.styled.js';
import { Link } from 'react-router-dom';

function A({ children, to, normal, ...rest }) {
  if (to.startsWith('https://')) {
    return (
      <a style={{
        color: '#FFF',
        textDecoration: 'unset'
      }} href={to} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} {...rest} normal={normal} component={StyledLink}>
      {children}
    </Link>
  );
}

export default A;
