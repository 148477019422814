/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { checkMobile } from 'utils/checkMobile';
import loadable from '@loadable/component';

import 'shaka-player/dist/controls.css';

import forward_10 from 'assets/png/video/forward_10.png';
import rewind_10 from 'assets/png/video/rewind_10.png';
import skip from 'assets/png/video/skip.png';

import './style.css';
import { useSelector } from 'react-redux';
import { t } from 'hooks/intl/useLang';
// import { loadCertificate } from './fps-request';
const shaka = require('shaka-player/dist/shaka-player.ui.js');
// const Shaka = loadable.lib(() => import('shaka-player/dist/shaka-player.ui.js'));

let player;
let intervalID;

export function Player2({
  manifestUrl,
  licenseServer,
  posterUrl,
  showControls = false,
  isOverlay = false,
  currentSection,
  setCurrentSection,
  moduleData,
  setType,
  setCurrentPlayer,
  width = false,
  fullControls = false,
  rewindFunction,
  skipFunction,
  forwardFunction,
  videoAds = false,
  haveTrainingFreeTrail,
  haveUnlockVideo,
  autoPlayVideo,
  setAutoPlayVideo,
  completedSection,
  setShowRetakeHabis,
  setShowModallPostTest,
  allowPostTest,
  onPlayVideo,
  setShowRetakeModal,

  // shaka,
  ...rest
}) {
  const video = useRef();
  const videoContainer = useRef();
  const userTraining = useSelector((state) => state.UserTraining);

  const [showOverlay, setShowOverlay] = useState(false);
  const [time, setTime] = useState(null);

  function insertAfter(referenceNode, src, id, callback) {
    const el = document.createElement('button');
    el.addEventListener('click', callback);

    const img = document.createElement('img');
    img.src = src;
    img.id = id;

    el.appendChild(img);
    referenceNode.insertAdjacentElement('afterend', el);
  }

  function initPlayer(video_manifest_uri) {
    // initialize shaka
    const _video = video.current;
    const _videoContainer = videoContainer.current;
    player = new shaka.Player(_video);

    if (setCurrentPlayer) setCurrentPlayer(player);

    const ui = new shaka.ui.Overlay(player, _videoContainer, _video);

    // default config controls
    const config = {
      addSeekBar: false,
      addPlayButton: true,
    };

    // customize controls
    const videoPlayer = document.getElementById('video');
    const uiPlayer = videoPlayer['ui'];
    const _config = showControls
      ? {
          addBigPlayButton: true,
          addSeekBar: !checkMobile(),
          overflowMenuButtons: ['quality'],
          seekBarColors: {
            base: 'rgba(196, 196, 196, 0.3)',
            buffered: '#c4c4c4',
            played: '#00debf',
          },
          enableKeyboardPlaybackControls: false,
        }
      : {
          addBigPlayButton: true,
          addSeekBar: false,
          controlPanelElements: [],
          overflowMenuButtons: [],
          seekBarColors: {
            base: 'rgba(196, 196, 196, 0.3)',
            overflowMenuButtons: ['quality', 'playback_rate'],
            buffered: '#c4c4c4',
            played: '#00debf',
          },
        };

    if (typeof uiPlayer?.configure !== 'undefined') {
      uiPlayer.configure(_config);
    }

    const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // swap the player controls
    swapControls();

    // fullcontrols to Video player
    if (fullControls) addControls();

    if (is_safari) {
      // loadCertificate(videoPlayer);
      player
        .load(video_manifest_uri)
        .then(function () {
          console.log('The video has now been loaded!');
        })
        .catch((error) => {
          console.error('Error code', error.code, 'object', error);
        });
    } else {
      if (typeof player?.configure !== 'undefined') {
        player.configure({
          drm: {
            servers: { 'com.widevine.alpha': licenseServer },
          },
        });
      }
      player.addEventListener('error', (err) => console.log('ERR', err));
      player
        .load(video_manifest_uri)
        .then(function () {
          console.log('The video has now been loaded!');
        })
        .catch((error) => {
          console.error('Error code', error.code, 'object', error);
        });
    }

    return player;
  }

  function swapControls() {
    const control = document.querySelector('.shaka-bottom-controls');
    control.appendChild(control.firstElementChild);
    control.appendChild(control.firstElementChild);
  }

  const addControls = () => {
    const el = document.createElement('button');
    let div = document.getElementsByClassName('shaka-small-play-button')[0];

    if (!div) div = document.getElementsByClassName('shaka-current-time')[0];

    insertAfter(div, skip, 'skip-button', skipFunction);
    insertAfter(div, forward_10, 'forward-button', forwardFunction);
    insertAfter(div, rewind_10, 'rewind-button', rewindFunction);

    const seekBar = document.getElementsByClassName('shaka-seek-bar')[0];

    if (seekBar) {
      seekBar.disabled = false;
    }
  };

  function reload(newMpdUrl) {
    if (!newMpdUrl) {
      return;
    }

    initPlayer(newMpdUrl);

    if (currentSection?.duration) {
      const video = document.getElementById('video');
      video.currentTime = currentSection?.duration || 0;
    }
  }

  const countDownVideo = () => {
    const countDownDate = new Date().getTime() + 11200;

    intervalID = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTime(seconds);

      if (distance < 500) {
        clearInterval(intervalID);

        nextVideo();
      }
    }, 500);
  };

  const endedVideo = () => {
    setShowOverlay(true);
    // setAutoPlayVideo(false);

    countDownVideo();
  };

  const replayVideo = () => {
    setShowOverlay(false);

    clearInterval(intervalID);
    intervalID = null;

    const video = document.getElementById('video');
    video.currentTime = 0;
    video.play();
  };

  const nextVideo = () => {
    setShowOverlay(false);

    const nextSectionId =
      moduleData[currentSection?.training_order - 1]?.sections[currentSection?.section_order]?.id;
    const type =
      moduleData[currentSection?.training_order - 1]?.sections[currentSection?.section_order]?.type;

    if (nextSectionId) {
      if (type === 'PostTest' && !allowPostTest) {
        setShowModallPostTest(true);
      } else if (
        !haveTrainingFreeTrail &&
        !haveUnlockVideo &&
        type !== 'Material' &&
        completedSection[currentSection?.training_id]?.[nextSectionId]?.completed
      ) {
        if (type === 'PostTest') {
          setShowRetakeHabis(true);
        } else if (type === 'Test') {
          setShowRetakeModal(true);
        }
      } else if (
        (!haveTrainingFreeTrail &&
          !haveUnlockVideo &&
          type === 'Material' &&
          completedSection[currentSection?.training_id]?.[nextSectionId]?.completed) ||
        userTraining?.data?.sections_freetrial?.includes(nextSectionId) ||
        userTraining?.data?.training_section_id?.includes(nextSectionId) ||
        (!haveTrainingFreeTrail &&
          !haveUnlockVideo &&
          !completedSection[currentSection?.training_id]?.[nextSectionId]?.completed)
      ) {
        setCurrentSection({
          ...currentSection,
          training_order: currentSection.training_order,
          section_order: currentSection.section_order + 1,
          training_chapter_id: nextSectionId,
          duration: 0,
        });

        setType(type);
      }
    } else {
      setType('Material');
      setCurrentSection({
        ...currentSection,
        training_id: moduleData[currentSection?.training_order]?.id,
        training_chapter_id: moduleData[currentSection?.training_order]?.sections[0]?.id,
        training_order: currentSection?.training_order + 1,
        section_order: 1,
      });
    }
  };

  useEffect(() => {
    reload(manifestUrl);
    setTime(null);

    return () => {
      if (typeof player !== 'undefined' && player.destroy) {
        player.destroy();
      }
    };
  }, [manifestUrl]);

  return (
    <div
      className="shadow-lg mx-auto max-w-full"
      ref={videoContainer}
      style={{
        width: '100%',
        justifyContent: 'center',
        height: width ? '450px' : '100%',
      }}
    >
      <video
        id="video"
        style={{
          margin: '0 auto',
          width: '100%',
          height: videoAds ? '100%' : 'auto',
        }}
        ref={video}
        className="w-full h-full"
        autoPlay={false}
        poster={posterUrl}
        onEnded={endedVideo}
        onPlay={onPlayVideo}
        {...rest}
      ></video>
      {isOverlay && showOverlay && (
        <div
          style={{
            width: '100%',
            height: '100%',
            background: 'rgba(20, 20, 20, 0.9)',
            position: 'absolute',
            zIndex: 2,
            display: 'grid',
            alignContent: 'center',
            justifyItems: 'center',
          }}
        >
          <p
            style={{
              fontSize: '16px',
              color: '#626262',
              position: 'absolute',
              left: '50px',
              top: '40px',
            }}
          >
            {t('Video selanjutnya')} <span style={{ color: '#fdfdfd' }}>{time}</span>
          </p>
          <button
            className="overlay-button"
            style={{
              borderRadius: '5px',
              fontSize: '16px',
              height: '50px',
              width: '250px',
              backgroundColor: '#00debf',
              color: '#000004',
              fontWeight: '600',
            }}
            onClick={() => {
              nextVideo();
              // setAutoPlayVideo(true);
            }}
          >
            {t('Putar Video Selanjutnya')}
          </button>
          <button
            style={{
              borderRadius: '5px',
              fontSize: '16px',
              height: '50px',
              width: '250px',
              backgroundColor: 'transparent',
              border: '1px solid #c4c4c4',
              color: '#c4c4c4',
              fontWeight: '600',
            }}
            className="overlay-button"
            onClick={replayVideo}
          >
            {t('Putar Ulang')}
          </button>
        </div>
      )}
    </div>
  );
}

export default function Player({ manifestUrl, ...rest }) {
  const props = { manifestUrl, ...rest };
  const shaka = useRef();

  const [isShow, setShow] = useState(true);

  useEffect(() => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
      if (intervalID) {
        clearInterval(intervalID);
      }
      intervalID = null;
    }, 200);
  }, [manifestUrl, shaka.current]);

  return (
    <>
      {isShow && (
        <>
          <Player2 {...props} />
        </>
      )}
    </>
  );
}
