import React from 'react';
import { InputContainer, InputFieldStyled, LabelInput } from './InputField.styled';

function InputField({ register, label, lengkapiProfile, dark, ...rest }) {
  const [isFocus, setIsFocus] = React.useState(false);
  const [value /*, setValue*/] = React.useState();
  return (
    <InputContainer lengkapiProfile={lengkapiProfile} {...rest}>
      <LabelInput dark={dark} {...rest} focus={isFocus}>
        {label}
      </LabelInput>
      <InputFieldStyled
        ref={register}
        value={value}
        onFocus={() => setIsFocus(true)}
        onBlur={(e) => {
          if (e.target.value === '') {
            setIsFocus(false);
          }
        }}
        {...rest}
      />
    </InputContainer>
  );
}

export default InputField;
