const getValue = (obj, path, defaultValue = undefined, index = 0) => {
  const paths = path.split('.');

  if (obj) {
    const newObj = Object.assign(obj, {});

    if (newObj[paths[index]]) {
      if (index < paths.length - 1) {
        const check = getValue(newObj[paths[index]], path, defaultValue, index + 1);

        if (check) return check;

        return defaultValue;
      }
      return newObj[paths[index]];
    }
    return defaultValue;
  }
  return defaultValue;
};

exports.getValue = getValue;

exports.setContext = (data) => ({
  headers: {
    token: getValue(data, 'data.accessToken'),
  },
});
