import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px 16px;

  margin-bottom: 16px;

  border-radius: 5px;

  display: flex;
  align-items: center;

  transition: background 0.1s ease;

  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;

  background: #e5e5e5;

  .list {
    width: 19px;
    height: 19px;

    border-radius: 100%;

    border: solid 1px #626262;
  }

  .list-selected {
    width: 9px;
    height: 9px;
    flex-shrink: 1;
    border-radius: 100%;
    background: rgba(0, 222, 191, 0.2);
    border: 2px solid #187336;
    padding: 3px;
    background-clip: content-box;
  }

  &.selected {
    background: rgba(0, 222, 191, 0.2);
    border: 2px solid #187336;
    padding: 6px 14px;
  }

  .option {
    padding-left: 16px;
  }

  &:hover {
    background: rgba(0, 222, 191, 0.2);

    .list {
      border: 1px solid #187336;
    }

    .list-selected {
      border: 2px solid #187336;
    }
  }
`;
