import PopupModal from 'components/atoms/Modal';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authOperations } from 'state/modules/auth';

import { Title, Separator, Description, Button } from './styled';

const UbahSandiModal = ({ setShowModal, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <PopupModal dark {...rest}>
      <Title>Atur Kata Sandi Berhasil</Title>
      <Separator />
      <Description>
        Kata sandi baru berhasil dibuat. Silahkan masuk ke Terampil menggunakan kata sandi baru.
      </Description>
      <div>
        <Button
          onClick={async () => {
            await dispatch(authOperations.removeAuth());
            history.push('/');
          }}
        >
          Tutup
        </Button>
      </div>
    </PopupModal>
  );
};

export default UbahSandiModal;
