import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 121px;
  border: 1px solid #626262;
  border-radius: 10px;
  position: relative;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 60px;
  color: #fdfdfd;
  position: absolute;
  top: -30%;
  left: 36px;
`;

export const Content = styled.p`
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  line-height: 20px;
  max-width: 220px;
  margin: 0;
`;
