import styled from 'styled-components';
import { mq } from 'assets/styles/theme';

export const CardTrainingStyled = styled.div.attrs((props) => ({
  width: props.grid ? '100%' : '95%',
  margin: props.grid ? '0' : props.row ? '20px 10px' : '100px 10px',
}))`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
  cursor: ${(props) => (props.prakerja ? 'default' : 'pointer')};
  position: relative;
  box-sizing: border-box;
  max-width: ${(props) => (props.trainer ? '295px' : '')};

  // kondisi jika digunakan di prakerja page
  ${(props) => {
    if (props.prakerja && props.grid) {
      return `
        @media only screen and ${props.theme.breakpoints.lg} {
          max-width: 230px;
          margin: 0;
        }

        @media only screen and ${props.theme.breakpoints.md} {
          max-width: 300px;
          margin: 0;
        }

        @media only screen and ${props.theme.breakpoints.sm} {
          max-width: 275px;
          margin: 0;
        }

        @media only screen and ${props.theme.breakpoints.xs} {
          max-width: 165px;
        }
      `;
    } else {
      return `
        @media only screen and ${props.theme.breakpoints.sm} {
          width: ${props.grid ? '100%' : '180px'};
          margin: ${props.grid ? '0' : '0 0 8px 20px'};
          flex-direction: ${props.grid ? 'row' : 'column'};
        }
      `;
    }
  }}

  &.trainer-profile {
    margin: 0;
  }

  .cover-container {
    position: relative;
    width: 100%;
    height: 200px;
    margin-bottom: 12px;

    // kondisi jika digunakan di prakerja page
    ${(props) => {
      if (props.prakerja && props.grid) {
        return `
          @media only screen and ${props.theme.breakpoints.lg} {
            width: 230px;
            height: 150px;
            margin-bottom: 10px;
          }

          @media only screen and ${props.theme.breakpoints.md} {
            width: 300px;
            height: 200px;
            margin-bottom: 10px;
          }

          @media only screen and ${props.theme.breakpoints.sm} {
            width: 275px;
            height: 170px;
            margin-bottom: 10px;
          }

          @media only screen and ${props.theme.breakpoints.xs} {
            width: 165px;
            height: 120px;
            margin-bottom: 10px;
          }
        `;
      } else {
        return `
          @media only screen and ${props.theme.breakpoints.sm} {
            width: ${props.grid ? '116px' : '100%'};
            height: ${props.grid ? '77px' : '120px'};
            margin-right: ${props.grid ? '8px' : '0'};
          }
        `;
      }
    }}
  }

  .bookmark {
    width: 15px;
    height: 18px;
    flex: 1;
    object-fit: contain;
    ${mq({
      width: [null, null, null, null, '8.4px'],
      height: [null, null, null, null, '10.8px'],
    })}
  }

  .bookmark-container {
    background-color: rgba(16, 16, 16, 0.2);
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      width: 24px;
      height: 24px;
      top: 8px;
      right: 8px;
    }

    @media only screen and ${(props) => props.theme.breakpoints.xs} {
      width: 24px;
      height: 24px;
      top: 3px;
      left: 88px;
    }
  }

  .bookmark-container-popular {
    background-color: rgba(16, 16, 16, 0.2);
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      width: 24px;
      height: 24px;
      top: 8px;
      right: 8px;
    }

    @media only screen and ${(props) => props.theme.breakpoints.xs} {
      width: 24px;
      height: 24px;
    }
  }

  .cover {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      border-radius: 4px;
    }
  }

  span {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-right: 10px;
    color: #707070;
    float: left;
    text-align: left;

    ${mq({
      fontSize: [null, null, '10px'],
      marginRight: [null, null, '4px'],
    })}
  }

  .share {
    width: 18px;
    height: 18px;
    object-fit: contain;
    position: absolute;
    right: 11px;
    cursor: ${(props) => (props.prakerja ? 'default' : 'pointer')};

    & :hover {
      fill: white;
    }

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      width: 11px;
      height: 11px;
      display: ${(props) => (props.grid ? 'none' : '')};
    }
  }

  h1 {
    font-family: Archivo, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    float: left;
    text-align: left;
    margin-bottom: 0;
    cursor: ${(props) => (props.prakerja ? 'default' : 'pointer')};
    overflow: hidden;
    color: #fdfdfd;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    height: 60px;

    ${mq({
      fontSize: [null, null, '14px'],
      lineHeight: [null, null, '18px'],
      height: [null, null, '38px'],
    })}
    :hover {
      color: white;
    }

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      margin-top: ${(props) => (props.grid ? '0' : '')};
    }

    // kondisi jika digunakan di prakerja page
    ${(props) => {
      if (props.prakerja && props.grid) {
        return `
          @media only screen and ${props.theme.breakpoints.lg} {
            max-width: 240px;
          }
        `;
      }
    }}
  }

  h2 {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    max-lines: 2;
    font-size: 16px;
    line-height: 24px;
    float: left;
    color: #c4c4c4;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin: 16px 0;
    overflow: hidden;

    ${mq({
      fontSize: [null, null, '12px'],
      lineHeight: [null, null, '20px'],
    })}

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      -webkit-line-clamp: 1;
      height: 16px;
      margin: 8px 0;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;

    // kondisi jika digunakan di prakerja page
    ${(props) => {
      if (props.prakerja && props.grid) {
        return `
          @media only screen and ${props.theme.breakpoints.sm} {
            margin-bottom: 8px;
          }    
        `;
      }
    }}

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      margin-top: -7px;
    }
  }

  .tinyIcon {
    object-fit: contain;
    float: left;
    margin-right: 8px;
  }

  .circle {
    height: 4px;
    width: 4px;
    background-color: #707070;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
    ${mq({
      marginRight: [null, null, '4px'],
    })}

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      height: 2px;
      width: 2px;
      margin-left: 2px;
    }
  }

  .training-info {
    display: flex;
    flex-direction: column;

    h2 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      margin: ${(props) => (props.promo ? '2px 0 8px 0' : '16px 0')};
      color: #c4c4c4;

      @media only screen and ${(props) => props.theme.breakpoints.sm} {
        font-size: 12px;
        line-height: 19px;
        color: #c4c4c4;
      }

      @media only screen and ${(props) => props.theme.breakpoints.xs} {
        margin-top: 0px;
      }
    }
  }
`;

export const Flag = styled.div`
  padding: 4px 8px;
  background-color: ${(props) => (props.collab ? '#F18F01' : '#00DEBF')};
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  width: fit-content;
  line-height: 17px;
  color: #141414;
  border-radius: 5px;
  text-align: left;
  z-index: 2;
  /* position: absolute; */
  /* margin-bottom: 22px; */

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    left: 4px;
    bottom: 4px;
    margin-top: 4px;
  }

  ${mq({
    fontSize: [null, null, '10px'],
    padding: [null, null, '5px 4px'],
    lineHeight: [null, null, '12px'],
  })}
`;

export const FlagContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 12px;
  left: 12px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    left: 4px;
    top: 4px;
  }
`;

export const FlagBannerPrakerja = styled.div`
  background: #00debf;
  border-radius: 2px;
  padding: 10px;
  color: #141414;
  position: absolute;
  top: 10px;
  left: ${({ pelatihanSaya }) => (pelatihanSaya ? '10px' : '20px')};
  font-family: Inter;
  opacity: 0.8;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 10px;
    padding: 5px;
    top: 5px;
    left: ${({ pelatihanSaya }) => (pelatihanSaya ? '5px' : '10px')};
  }
`;

export const PromoTraining = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  text-decoration: none;
  justify-content: space-between;
  font-family: 'Inter';
  font-style: normal;
  align-items: start;
  margin: 8px 0;

  .price-normal {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: line-through;
    color: #c4c4c4;
    margin: 0;
  }

  .price-discount {
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: #0dc274;
    margin: 0;
  }

  .percent-box {
    position: absolute;
    width: 67px;
    height: 27px;
    background: #f5a52f;
    border-radius: 5px;
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */
    color: #ffffff;
    right: 0;
    top: -35px;
  }
`;
