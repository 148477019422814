import React, { useState } from 'react';
import {
  ButtonPay,
  DetailMetodeBayar,
  MetodePembayaranStyled,
  SectionSeparator,
  Separator,
  TitleMobile,
} from './styled';
import MaxContainer from 'components/atoms/MaxContainer';
import TabMetode from '../TabMetode';
import KartuKreditTab from '../KartuKreditTab';
import TransferVA from '../TransferVA';
import DompetOnline from '../DompetOnline';
import { checkMobile, phonesize } from 'utils/checkMobile';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_DETAIL } from 'queries/user';
import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import { CREATE_ORDER_EWALLET } from 'mutation/payment';
import RecurringModal from 'components/molecule/Modal/RecurringModal';

function MetodePembayaran(props) {
  const {
    selected_method,
    onSetSelectedMethod,
    data_kartu_kredit,
    onKartuKreditChange,
    data_va,
    onVAChange,
    data_dompet_online,
    onDompetOnlineChange,
    dataOrder,
    onDataOrderChange,
    dataOrderByPoint,
    createOrder,
    isRecurring,
    setIsRecurring,
    voucher,
    discountVoucher,
    price,
    dataSubs,
    user,
    detailTraining,
    packageTrainings,
  } = props;
  const [isMobile] = useState(phonesize() || false);

  const history = useHistory();
  const authData = useSelector((state) => state.Auth);
  const [openModalRecurring, setOpenModalRecurring] = useState(false);

  const exceptMethod = ['Point', 'Partner Voucher', 'Full Discount'];

  const { data } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const [createOrderEwallet] = useMutation(CREATE_ORDER_EWALLET, {
    onCompleted: (data) => {
      window.Moengage.track_event('Add To Cart', {
        package: data?.CreateOrderEwallet?.order_name,
      });
      history.push(`/rincian-online-payment/${data.CreateOrderEwallet.invoice_number}`);
    },
  });
  const onClickBayar = () => {
    switch (selected_method) {
      case 'Kartu Kredit': {
        if (checkMobile()) {
          const location = {
            pathname: '/pembayaran/kredit',
            state: {
              data_kartu_kredit: data_kartu_kredit,
              nameTraining: packageTrainings?.package_type || detailTraining?.title,
              dataSubs: dataSubs,
              phoneNumber: data?.ProfileUser?.User?.phone_number,
              voucher: voucher,
              discount: discountVoucher,
              price: price,
              packageId: packageTrainings?.id,
            },
          };
          history.push(location);
        } else {
          data_kartu_kredit['isMobile'] = isMobile;
          data_kartu_kredit['phoneNumber'] = data?.ProfileUser?.User?.phone_number;
          data_kartu_kredit.cardNumber = data_kartu_kredit.cardNumber.split(' ').join('');
          data_kartu_kredit['voucherCode'] = voucher;

          createOrder({
            context: setContext(authData),
            variables: {
              input: data_kartu_kredit,
            },
          });
        }
        break;
      }
      case 'Transfer Virtual Account': {
        if (checkMobile()) {
          dataOrder['isMobile'] = isMobile;
          dataOrder['phoneNumber'] = data?.ProfileUser?.User?.phone_number;
          dataOrder['voucherCode'] = voucher;

          createOrder({
            context: setContext(authData),
            variables: {
              input: dataOrder,
            },
          });
        } else {
          data_va['isMobile'] = isMobile;
          data_va['phoneNumber'] = data?.ProfileUser?.User?.phone_number;
          data_va['voucherCode'] = voucher;
          createOrder({
            context: setContext(authData),
            variables: {
              input: data_va,
            },
          });
        }
        break;
      }
      case 'Dompet Online': {
        if (
          (data_dompet_online['paymentMethod'] || dataOrder['paymentMethod']) === 'dana' &&
          isRecurring
        ) {
          data_dompet_online['paymentMethod'] = 'dana_recurring';
          dataOrder['paymentMethod'] = 'dana_recurring';
        }

        if (checkMobile()) {
          dataOrder['voucherCode'] = voucher;
          createOrderEwallet({
            context: setContext(authData),
            variables: {
              input: dataOrder,
            },
          });
        } else {
          data_dompet_online['isMobile'] = isMobile;
          data_dompet_online['voucherCode'] = voucher;
          data_dompet_online['phoneNumber'] = data_dompet_online.phoneNumber
            ? data_dompet_online.phoneNumber
            : data?.ProfileUser?.User?.phone_number;
          createOrder({
            context: setContext(authData),
            variables: {
              input: data_dompet_online,
            },
          });
        }
        break;
      }
      case 'Point': {
        dataOrderByPoint.isMobile = isMobile;
        dataOrderByPoint.phoneNumber = dataOrderByPoint.phoneNumber
          ? dataOrderByPoint.phoneNumber
          : data?.ProfileUser?.User?.phone_number;

        createOrder({
          context: setContext(authData),
          variables: {
            input: dataOrderByPoint,
          },
        });
        break;
      }
      case 'Partner Voucher':
      case 'Full Discount': {
        dataOrder.isMobile = isMobile;
        dataOrder.phoneNumber = dataOrder.phoneNumber
          ? dataOrder.phoneNumber
          : data?.ProfileUser?.User?.phone_number;
        dataOrder.voucherCode = voucher;

        createOrder({
          context: setContext(authData),
          variables: {
            input: dataOrder,
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <MaxContainer>
      <MetodePembayaranStyled>
        {!exceptMethod.includes(selected_method) && (
          <>
            <TabMetode
              isPrakerja={detailTraining?.training_prakerja}
              eventSelect={onSetSelectedMethod}
              select={selected_method}
            />
            <Separator />
            <SectionSeparator />
            <TitleMobile>Pilih Metode Pembayaran</TitleMobile>
            <TransferVA
              isMobile={isMobile}
              setTab={onSetSelectedMethod}
              hide={selected_method !== 'Transfer Virtual Account'}
              data_va={data_va}
              onVAChange={onVAChange}
              dataOrder={dataOrder}
              onDataOrderChange={onDataOrderChange}
            />
            <SectionSeparator />
            <KartuKreditTab
              isMobile={isMobile}
              setTab={onSetSelectedMethod}
              hide={selected_method !== 'Kartu Kredit'}
              data_kartu_kredit={data_kartu_kredit}
              onKartuKreditChange={onKartuKreditChange}
              dataOrder={dataOrder}
              onDataOrderChange={onDataOrderChange}
            />
            <SectionSeparator />
            <DompetOnline
              isMobile={isMobile}
              isRecurring={isRecurring}
              setIsRecurring={setIsRecurring}
              setTab={onSetSelectedMethod}
              hide={selected_method !== 'Dompet Online'}
              data_dompet_online={data_dompet_online}
              onDompetOnlineChange={onDompetOnlineChange}
              dataOrder={dataOrder}
              onDataOrderChange={onDataOrderChange}
              user={user}
            />
            <SectionSeparator />
          </>
        )}
        <DetailMetodeBayar>
          <ButtonPay
            point={exceptMethod.includes(selected_method)}
            onClick={() => {
              if (isRecurring) {
                setOpenModalRecurring(true);
              } else {
                onClickBayar();
              }
            }}
          >
            {selected_method === 'Partner Voucher' ? 'Redeem Voucher' : 'Bayar'}
          </ButtonPay>
        </DetailMetodeBayar>
      </MetodePembayaranStyled>
      <RecurringModal
        dark
        isOpen={openModalRecurring}
        onRequestClose={() => setOpenModalRecurring(false)}
        setOpenModalRecurring={setOpenModalRecurring}
        onClick={() => onClickBayar()}
      />
    </MaxContainer>
  );
}

export default MetodePembayaran;
