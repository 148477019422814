// import { combineReducers } from "redux";
import * as types from './types';
import { createReducer } from '../../utils';

const authReducer = createReducer({
  data: '',
  error: null,
})({
  [types.SET_AUTH]: (state, action) => {
    return {
      data: action.payload.data,
      error: null,
    };
  },
  [types.REMOVE_AUTH]: (state, action) => {
    return {
      data: '',
    };
  },
});

// export default combineReducers( {
//     auth: authReducer
// } );

export default authReducer;
