import React from 'react';
import { Container, Link, Address, Title } from './index.styled';

function About({ isAuth, ...rest }) {
  return (
    <Container className="about-container">
      <Title>Head Office</Title>
      <Address>Jl. Tanah Abang II No. 19, Petojo Selatan, Gambir, Jakarta Pusat</Address>
      <Title>Studio Office</Title>
      <Address>
        Komplek Buncit Indah, Cluster Kasturi, Kavling 17, Pasar Minggu, Jakarta Selatan
      </Address>
      <Link href="mailto: halo@terampil.com" target="blank">
        halo@terampil.com
      </Link>
      <Link href="https://api.whatsapp.com/send?phone=628176060490" target="_blank">
        0817 6060 490
      </Link>
    </Container>
  );
}
export default About;
