import PopupModal from 'components/atoms/Modal';
import React from 'react';
import Player from 'components/etc/Player';

import { Container } from './styled';

const VidioTrainingModal = ({ data, setShowModal, setDelete, ...rest }) => {
  return (
    <PopupModal videoTraining dark video {...rest}>
      <Container>
        <Player
          manifestUrl={data?.video_non_apple_url}
          posterUrl={data?.thumbnail}
          licenseServer={
            'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=5BC068'
          }
          autoPlay={true}
          muted
        />
      </Container>
    </PopupModal>
  );
};

export default VidioTrainingModal;
