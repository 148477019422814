import styled from 'styled-components';

export const ContainerSortDropDown = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .mobile {
    display: none;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      display: block;
    }
  }

  .web {
    display: block;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      display: none;
    }
  }

  .react-select__control {
    width: 170px;
    height: 45px;
    position: relative;
    z-index: 0;
    margin: 0;
    padding: 0 7px;
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    background: transparent;
    border: 1px solid #626262;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
    }
  }

  .react-select__value-container {
    font-size: 16px;
  }

  .react-select__menu {
    background: #202020;
    text-align: left;
    font-size: 16px;
    line-height: 19px;
    margin: 4px 0 0;
    border-radius: 0 0 5px 5px;
  }

  .react-select__dropdown-indicator {
    color: #c4c4c4;
  }

  .react-select__option:hover {
    background: transparent;
  }

  .react-select__option {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #fdfdfd;
    padding: 12px 24px;
    display: flex;
    background-color: transparent;
    justify-content: space-between;
    border-left: 2px solid transparent;
  }

  .react-select__indicator-separator {
    background-color: transparent;
  }

  .react-select__single-value {
    color: #fdfdfd;
  }

  .react-select__option--is-selected {
    font-weight: 600;
    border-left: 2px solid #00debf;
  }

  .react-select__menu-list {
    padding: 24px 0;
    border-radius: 0 0 5px 5px;
  }
`;

export const Text = styled.p`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-right: 16px;

  color: #ffffff;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 17px;
    border-bottom: 0;
    margin: 0 24px 12px;
  }
`;
