import styled from 'styled-components';

export const BlueTextStyled = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.003em;
  text-align: left;
  white-space: pre-wrap;
  color: #1ab1e5;

  @media only screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 24px;
    line-height: 28px;
  }
`;
