import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 50px 0;

  @media only screen and ${props => props.theme.breakpoints.xs} {
    margin: 16px 0;
  }
`;

export const PaginationItem = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  font-size: 16px;
  line-height: 19px;

  color: ${(props) => (props.selected ? '#00debf' : '#fdfdfd')};
  margin: 0 12px;
  cursor: pointer;

  :hover {
    color: #00debf;
  }
`;

export const ButtonCircle = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #626262;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;

  :hover {
    background-color: #424242;
  }
`;
