import React from 'react';
import { Container, WrapperTitle, Title, SubTitle, Training, Box, TableContent } from './styled';
import HasilTrainingSertif from '../HasilTrainingSertif';
import { formatDateLong } from 'utils/date';
import { t } from 'hooks/intl/useLang';

const LevelLearningInfo = ({ detailCertificate, user }) => {
  let serialNumber = detailCertificate?.serial_number;
  return (
    <Container>
      <WrapperTitle>
        <Title>{serialNumber}</Title>
        <SubTitle>{t('Sertifikat ini dikeluarkan oleh Terampil')}</SubTitle>
      </WrapperTitle>
      <Training>{detailCertificate?.training?.title}</Training>
      <Box>
        <TableContent>
          <tbody>
            <tr>
              <td>{t('Tanggal Download Sertifikat')}</td>
              <td className="mid">:</td>
              <td>
                <strong>
                  {detailCertificate?.file?.created_at &&
                    formatDateLong(detailCertificate.file.created_at)}
                </strong>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('Nama Penerima Sertifikat')}</td>
              <td className="mid">:</td>
              <td>
                <strong>{user?.Profile?.fullname}</strong>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('Nama Trainer')}</td>
              <td className="mid">:</td>
              <td>
                <strong>{detailCertificate?.training?.trainer?.fullname}</strong>
              </td>
            </tr>
          </tbody>
        </TableContent>
      </Box>
      <Box second>
        <Training level>{t('Nilai')} {detailCertificate?.score}</Training>
        <SubTitle level>
          {t('Berhasil menjawab')} {detailCertificate?.Result?.summary} {t('pertanyaan dengan benar')}
        </SubTitle>
        <HasilTrainingSertif verifyCertificate={detailCertificate} />
      </Box>
    </Container>
  );
};

export default LevelLearningInfo;
