import styled from 'styled-components';

export const Section = styled.section`
  padding: 0 100px;
  padding-top: calc(77px + 50px);

  background: #141414;

  .danger {
    color: #f18f01;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    height: calc(100vh - 65px);

    padding: 0 16px;
    padding-top: 16px;
  }
`;

export const Head = styled.div`
  font-family: Archivo;

  h5 {
    font-size: 30px;
    color: ${({ theme }) => theme.color.primary};

    margin: 0;
    margin-bottom: 16px;
  }

  h6 {
    font-size: 24px;

    margin: 0;
    margin-bottom: 50px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    h5,
    h6 {
      font-size: 16px;
    }

    h6 {
      margin-bottom: 20px;
    }
  }
`;

export const Detail = styled.div`
  margin: 0 auto;

  display: grid;
  justify-items: center;

  svg {
    margin-bottom: 24px;
  }

  strong {
    font-size: 30px;
    color: ${({ theme }) => theme.color.primary};
  }

  .secondary {
    font-size: 16px;
    color: #c4c4c4;
    font-weight: 400;
    line-height: 24px;

    margin-bottom: 50px;
  }

  span {
    font-weight: 700;
    color: #fdfdfd;
  }

  p {
    font-size: 24px;
    color: #c4c4c4;
    margin-bottom: 80px;
  }

  width: 70%;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;

    svg {
      width: 144px;
      height: 144px;

      margin-bottom: 0px;
    }

    p {
      margin-top: 0;
      font-size: 14px;
    }

    strong {
      font-size: 16px;
    }

    .secondary {
      font-size: 12px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-bottom: 50px;

  .button {
    width: 210px;
  }

  .secondary {
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
    background: transparent;
  }

  .danger {
    color: #f18f01;
    background: transparent;
    border: 1px solid #f18f01;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    box-sizing: border-box;
    width: 90%;

    .button {
      width: 48%;
      margin: 0;
    }

    position: fixed;
    bottom: 16px;

    justify-content: space-between;

    padding-bottom: 0px;
  }
`;
