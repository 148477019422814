/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from 'react-share';

import PopupModal from 'components/atoms/Modal';
import { Title, ShareContainer } from './styled';
import SocialMediaShare from 'components/atoms/SocialMediaShare';
import { Referral } from 'components/organisms/ProfileUser/EditProfil/styled';
import { t } from 'hooks/intl/useLang';

const ShareModal = ({ urlPath, ...rest }) => {
  const [copyText, setCopyText] = useState('Copy');
  const [copyToClipboard, setCopyToClipboard] = useState();

  const onCopyToClipboard = (value) => {
    navigator.clipboard.writeText(value).then(
      () => setCopyToClipboard('Copied!'),
      () => setCopyToClipboard('Failed!'),
    );
  };

  return (
    <PopupModal dark medium {...rest}>
      <Title>{t('Bagikan Training Ini')}</Title>
      <ShareContainer>
        <Referral id="referral-code" className="w-100">
          {urlPath}
          <button
            onClick={() => {
              setCopyText('Copied');
              setTimeout(() => {
                setCopyText('Copy');
              }, 2000);
              // copyToClipboard('referral-code');
              onCopyToClipboard(urlPath);
            }}
          >
            {copyText}
          </button>
        </Referral>
      </ShareContainer>
      <ShareContainer className="mb-3">
        <FacebookShareButton url={urlPath}>
          <SocialMediaShare type="Facebook" className="w-100 Facebook" />
        </FacebookShareButton>
        <TwitterShareButton url={urlPath}>
          <SocialMediaShare type="Twitter" className="w-100 Twitter" />
        </TwitterShareButton>
        <EmailShareButton subject={t("Bagikan Training ini")} url={urlPath}>
          <SocialMediaShare type="Email" className="w-100 Twitter" />
        </EmailShareButton>
        <WhatsappShareButton url={urlPath}>
          <SocialMediaShare type="WhatsApp" className="w-100 Twitter" />
        </WhatsappShareButton>
      </ShareContainer>
    </PopupModal>
  );
};

export default ShareModal;
