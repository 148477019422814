/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import InputComponent from 'components/atoms/Input';
import Dropdown from 'components/atoms/Dropdown';
import A from 'components/atoms/A';
import Button from 'components/atoms/Button';
import NotificationMenu from 'components/atoms/NotificationMenu';
import {
  LoggedInContainer,
  LeftContainer,
  RightContainer,
  Notification,
  LoadingButton,
  ButtonTrainerDashboard,
} from './Headers.styled';
import UserProfile from 'components/atoms/UserProfile';

import { ReactComponent as BellIcon } from 'assets/svg/bell-icon.svg';
import { GET_NOTIFICATION, GET_NOTIFICATION_COUNT } from 'queries/notification';
import { useLazyQuery, useQuery } from '@apollo/client';

import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import { GET_USER_LAST_ORDER_ACTIVE } from 'queries/orders';
import DropDownNavigation from 'components/atoms/NavigationDropdwon';
import { t } from 'hooks/intl/useLang';

const LoggedInHeader = ({ userData = {}, loadingUserData }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [showNavigationWeb, setShowNavigationWeb] = useState(false);
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const {
    data: notificationData,
    loading: notificationDataLoading,
    errors: notificationDataErrors,
  } = useQuery(GET_NOTIFICATION, {
    fetchPolicy: 'cache-and-network',
    context: setContext(authData),
    variables: {
      orderBy: 'created_at',
      limit: 20,
      orderType: 'desc',
    },
    onCompleted: () => {
      getNotificationCount();
    },
  });

  const [getNotificationCount, { data: notificationCount, loading: notificationCountLoading }] =
    useLazyQuery(GET_NOTIFICATION_COUNT, {
      context: setContext(authData),
      variables: {
        slug: getValue(authData, 'data.slug'),
      },
    });

  const { data: orderActive } = useQuery(GET_USER_LAST_ORDER_ACTIVE, {
    context: setContext(authData),
  });

  const history = useHistory();
  const formatNotification = (data) => {
    if (data === null || data === undefined) {
      return [];
    }
    const groups = data.reduce((groups, obj) => {
      const date = obj.created_at.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(obj);
      return groups;
    }, {});

    const groupsArray = Object.keys(groups).map((date) => {
      const newDate = new Date(date).toLocaleDateString();
      return {
        date: newDate,
        notif: groups[date],
      };
    });

    return groupsArray;
  };
  const del = useRef();

  const showNotif = () => {
    if (showUserDetail) setShowUserDetail(!showUserDetail);
    setShowNotification(!showNotification);
    if (showNavigationWeb) setShowNavigationWeb(!showNavigationWeb);
  };

  const handleClick = (e) => {
    if (del?.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowNavigationWeb(false);
    setShowNotification(false);
  };

  const renderSubsButton = (quota, freetrial) => {
    if (quota > 0 && !freetrial) {
      return (
        <nav className="header-button">
          <Button
            onClick={() => history.push('/pembayaran/Yearly-2')}
            primary
            small
            className="button-subscribe"
          >
            {t('Mulai Berlangganan')}
          </Button>
        </nav>
      );
    } else if (quota === 0 || freetrial) {
      return (
        <nav className="header-button">
          <Button
            onClick={() => history.push('/pembayaran/Yearly-2')}
            primary
            small
            className="button-subscribe"
          >
            {t('Mulai Berlangganan')}
          </Button>
        </nav>
      );
    }

    return;
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('click', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <LoggedInContainer>
      <LeftContainer>
        <Dropdown>{t('Kategori')}</Dropdown>
        <InputComponent />
      </LeftContainer>
      <RightContainer ref={del}>
        <nav className="header-link-logged-in">
          {userData?.User?.roles?.includes('Trainer') ? (
            <ButtonTrainerDashboard
              onClick={() =>
                window.open(
                  ` ${
                    process.env.REACT_APP_STAGING !== 'dev'
                      ? 'https://dashboard.terampil.com'
                      : 'https://dev-dashboard.terampil.com'
                  }/login?accessToken=${getValue(authData, 'data.accessToken')}`,
                )
              }
              primary
            >
              {t('Dashboard Trainer')}
            </ButtonTrainerDashboard>
          ) : (
            <A to="/business-page" primary>
              For Enterprise
            </A>
          )}
          <A to="/pelatihan-saya/proses" primary normal>
            {t('Training Saya')}
          </A>
        </nav>
        <div className="user-container">
          <Notification className="button">
            <BellIcon className="button" onClick={showNotif} />
            {notificationCount?.ProfileUser?.unread_notification > 0 && (
              <div className="notif-count" onClick={showNotif}>
                {notificationCount?.ProfileUser?.unread_notification}
              </div>
            )}
            {showNotification && (
              <NotificationMenu
                getNotificationCount={getNotificationCount}
                showNotif={showNotif}
                data={formatNotification(notificationData?.Notifications?.items)}
                loading={notificationDataLoading}
                errors={notificationDataErrors}
                notif={notificationData?.Notifications?.items}
              />
            )}
          </Notification>
          {/* <DropDownNavigation
            showNavigationWeb={showNavigationWeb}
            setShowNavigationWeb={setShowNavigationWeb}
          /> */}
          <UserProfile
            profile={userData}
            showUserDetail={showUserDetail}
            showNotification={showNotification}
            setShowUserDetail={setShowUserDetail}
            setShowNotification={setShowNotification}
          />
        </div>
        {loadingUserData ? (
          <LoadingButton />
        ) : (
          renderSubsButton(userData?.training_quota, userData?.subscription_type === 'FreeTrial')
        )}

        {/* {renderSubsButton(userData?.training_quota, userData?.subscription_type === 'FreeTrial')} */}
      </RightContainer>
    </LoggedInContainer>
  );
};

export default LoggedInHeader;
