export const randomizeArray = (array) => {
  if (!array) return [];

  const randArray = [];
  const arr = [...array];

  while (arr.length > 0) {
    const rand = Math.floor(Math.random() * arr.length);
    randArray.push(arr[rand]);
    arr.splice(rand, 1);
  }

  return randArray;
};
