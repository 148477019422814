import styled from 'styled-components';

export const ContainerOption = styled.div`
  height: 40px;

  .indicator {
    background-color: #00debf;
    width: 2px;
  }
`;
