import styled from 'styled-components';
import BlueText from '../../../atoms/KlinikPintar/BlueText';
import loadable from '@loadable/component';
const CardTraining = loadable(() => import('components/molecule/Cards/CardTraining'));

export const GreyAccent = styled.div`
  background: #fbfafa;
  border-radius: 10px 0 0 10px;
  position: absolute;
  right: 0;
  top: 100px;
  height: 473px;
  width: 50%;
`;

export const ModuleSectionStyled = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

export const TitleModule = styled(BlueText)`
  text-align: center;
  margin-top: 120px;
  margin-bottom: 32px;

  @media only screen and ${(props) => props.theme.breakpoints.lg} {
    margin: 100px 60px 32px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 64px 40px 20px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 64px 32px 20px;
  }
`;

export const ModuleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 0 50px;
  gap: 12px;

  @media only screen and ${(props) => props.theme.breakpoints.lg} {
    flex-basis: 37%;
    gap: 12px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 16px 0;
    row-gap: 24px;
  }
`;

export const CardTrainingStyled = styled(CardTraining)`
  flex-basis: 22%;
  margin: 0;
  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    flex-basis: 41%;
  }
`;
