import styled from 'styled-components';

export const Section = styled.section`
  background: #141414;
  padding: 0 100px;

  h6 {
    margin: 0;
    margin-bottom: 32px;

    text-align: left;
  }
`;

export const ContainerCard = styled.div`
  display: grid;

  margin-top: -100px;

  grid-template-columns: 25% 25% 25% 25%;
`;
