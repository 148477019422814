import PopupModal from 'components/atoms/Modal';
import React from 'react';

import { Title, Separator, Description, Button } from './styled';

const KonfirmasiHapusKomentar = ({ setShowModal, setDelete, ...rest }) => {
  return (
    <PopupModal dark medium {...rest}>
      <Title>Apakah kamu yakin hapus diskusi ini?</Title>
      <Separator />
      <Description>Sekali dihapus, maka akan terhapus selamanya</Description>
      <div>
        <Button className="accept" onClick={() => setDelete()}>
          Yakin
        </Button>
        <Button className="cancel" onClick={() => setShowModal(false)}>
          Tidak
        </Button>
      </div>
    </PopupModal>
  );
};

export default KonfirmasiHapusKomentar;
