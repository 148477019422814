import { gql } from '@apollo/client';

// export const GET_COLLABORATION = gql`
//   query getCollabiration($input: CollaborationRequest) {
//     Collaborations(input: $input) {
//       status
//       statusText
//       total
//       items {
//         id
//         name
//         image
//         is_active
//       }
//     }
//   }
// `;

export const GET_COLLABORATION = gql`
  query getCollabiration {
    Collaborations(input: { is_active: true }) {
      status
      statusText
      total
      items {
        id
        name
        image
      }
    }
  }
`;
