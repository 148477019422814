/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Container,
  DivTrainer,
  AvatarImage,
  ContentDeskripsi,
  ContentTrainer,
  ContentRelated,
} from './styled';
import { ReactComponent as Start } from 'assets/svg/star.svg';
import { ReactComponent as Circle } from 'assets/svg/PelatihanSaya/circle.svg';
import Button from 'components/atoms/Button';
import dokumenImg from 'assets/png/dokumen.png';
import sertifImg from 'assets/png/sertif.png';
import toast from 'react-hot-toast';
import Toast from 'components/atoms/Notification/Toast';
import { GET_DETAIL_TRAINING, GET_TRAINING_SUMMARY, GET_USER_TRAININGS } from 'queries/training';
import { setContext } from 'utils';
import { useSelector } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CREATE_CERTIFICATE } from 'mutation/certificate';
import { GET_USER_CERTIFICATE } from 'queries/certificate';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';

const TentangTrainer = ({ item, isFollowTraining, withDescription }) => {
  const [isSubscribe /*setIsSubscribe*/] = useState(false);
  const [certificateLink, setCertificateLink] = useState(null);
  const [totalProgress, setTotalProgress] = useState(0);
  const [completed, setCompleted] = useState(false);

  const authData = useSelector((state) => state.Auth);

  const { slug } = useParams();

  const { data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    variables: {
      input: slug,
    },
  });

  const [createCertificate] = useMutation(CREATE_CERTIFICATE, {
    context: setContext(authData),
    onCompleted: (data) => {
      setCertificateLink(data?.CreateCertificate?.file?.link);
    },
  });

  const { data: progress } = useQuery(GET_USER_TRAININGS, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    onCompleted: () => {
      getCertificate();
    },
  });

  const [getCertificate, { data: certificate }] = useLazyQuery(GET_USER_CERTIFICATE, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      trainingId: detailTraining?.Training?.id,
    },
    onCompleted: (data) => {
      setCertificateLink(data?.UserCertificate?.file?.link);
    },
    onError: (error) => {
      const trainingData = progress?.UserTrainings?.items?.find(
        (x) => x.training.id === detailTraining?.Training?.id,
      );

      if (error.message === 'Certificate not found' && completed) {
        createCertificate({
          variables: {
            input: {
              trainingTitle: trainingData?.training?.title,
              trainingCollaboration: trainingData?.training?.collaboration?.image || '',
              trainingCooporation: trainingData?.training?.cooperation?.image || '',
              trainerFullname: trainingData?.training?.trainer?.fullname,
              trainerProfession: trainingData?.training?.trainer?.profession,
              trainingId: detailTraining?.Training?.id,
            },
          },
        });
      }
    },
  });

  const { data: postTest } = useQuery(GET_TRAINING_SUMMARY, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      trainingId: detailTraining?.Training?.id,
    },
    onCompleted: (data) => {
      const summary = data?.TrainingUserResult?.summary.split('/');

      setTotalProgress(data?.TrainingUserResult?.levelLearning);

      if (+summary[0] / +summary[1] >= 0.7) {
        setCompleted(true);
      }
    },
    onError: (err) => {
      console.log('errx', err);
    }
  });

  //check the percentage of question, button available when 70% percent or more.
  let checkCertificate = totalProgress >= 60 ? 'btn-sertif' : 'btn-sertif-none';

  return (
    <Container>
      <Toast position="bottom-left" />
      {!checkMobile() ? (
        <>
          <ContentTrainer>
            <h2>{t('Tentang Trainer')}</h2>
            <div className="container-profile">
              <DivTrainer>
                <AvatarImage image={item?.trainer?.avatar} />
                <div className="text">
                  <h3>
                    <Link
                      style={{ textDecoration: 'none', color: '#fdfdfd' }}
                      to={`/trainer/${item?.trainer?.slug}`}
                    >
                      {item?.trainer?.fullname}
                    </Link>
                  </h3>
                  <h4>{item?.trainer?.profession}</h4>
                  <div className="container-start">
                    <Start />
                    <p>
                      {item?.trainer?.rating ? item?.trainer?.rating.toFixed(1) : 0} (
                      {item?.trainer?.total_score ? item?.trainer?.total_score : 0} {t('penilaian')})
                    </p>
                    {item?.trainer?.total_training && (
                      <>
                        <Circle />
                        <p line="line">{item?.trainer?.total_training} Training</p>
                      </>
                    )}
                  </div>
                </div>
              </DivTrainer>
              <DivTrainer>
                {isFollowTraining && (
                  <div className="wrap-button">
                    <h2>Related Document</h2>
                    <div className="button-unduh" style={{ display: 'block' }}>
                      <div style={{ display: 'flex' }}>
                        <img className="ikon" src={dokumenImg} alt="dokumen" />
                        <span className="related-document">Dokumen Pelatihan</span>
                        <Button
                          onClick={() => window.open(item.attachment_link)}
                          small
                          className={item.attachment_link ? 'btn-dokumen' : 'btn-dokumen-none'}
                        >
                          {t('Unduh Dokumen')}
                        </Button>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <img className="ikon" src={sertifImg} alt="sertif" />
                        <span className="related-document">{t('Sertifikat Pelatihan')}</span>
                        <Button
                          onClick={() => window.open(certificateLink)}
                          small
                          className={checkCertificate}
                        >
                          {t('Unduh Sertifikat')}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </DivTrainer>
            </div>
          </ContentTrainer>
          {!isSubscribe ? (
            <div> </div>
          ) : (
            <ContentRelated>
              <h2>Related This Course</h2>
            </ContentRelated>
          )}

          <ContentDeskripsi>
            <p>{item?.trainer?.about}</p>
          </ContentDeskripsi>
        </>
      ) : (
        <>
          <ContentTrainer>
            <h2>{('Tentang Trainer')}</h2>
            <div className="container-profile">
              <DivTrainer>
                <div className={!checkMobile() ? '' : 'mobile-profile'}>
                  <AvatarImage image={item?.trainer?.avatar} />
                  <div className="text">
                    <h3>
                      <Link
                        style={{ textDecoration: 'none', color: '#fdfdfd' }}
                        to={`/trainer/${item?.trainer?.slug}`}
                      >
                        {item?.trainer?.fullname}
                      </Link>
                    </h3>
                    <h4>{item?.trainer?.profession}</h4>
                    <div className="container-start">
                      <Start />
                      <p>
                        {item?.trainer?.rating ? item?.trainer?.rating.toFixed(1) : 0} (
                        {item?.trainer?.total_score ? item?.trainer?.total_score : 0} {t('penilaian')})
                      </p>
                      <Circle />
                      <p line="line">{item?.trainer?.total_training} Training</p>
                    </div>
                  </div>
                  <ContentDeskripsi>
                    <p className={!checkMobile() ? '' : 'mobile-paragraph'}>
                      {item?.trainer?.about}
                    </p>
                  </ContentDeskripsi>
                </div>
              </DivTrainer>
              <DivTrainer>
                {isFollowTraining && (
                  <div className="wrap-button">
                    <h2>Related Document</h2>
                    <div className="button-unduh" style={{ display: 'block' }}>
                      <div style={{ display: 'flex' }}>
                        <img className="ikon" src={dokumenImg} alt="dokumen" />
                        <span className="related-document">Dokumen Pelatihan</span>
                        <Button
                          onClick={() => window.open(item.attachment_link)}
                          small
                          className={item.attachment_link ? 'btn-dokumen' : 'btn-dokumen-none'}
                        >
                          {t('Unduh Dokumen')}
                        </Button>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <img className="ikon" src={sertifImg} alt="sertif" />
                        <span className="related-document">{t('Sertifikat Pelatihan')}</span>
                        <Button
                          onClick={() => window.open(certificateLink)}
                          small
                          className={checkCertificate}
                        >
                          {t('Unduh Sertifikat')}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </DivTrainer>
            </div>
          </ContentTrainer>
          {!isSubscribe ? (
            <div> </div>
          ) : (
            <ContentRelated>
              <h2>Related This Course</h2>
            </ContentRelated>
          )}
        </>
      )}
    </Container>
  );
};

export default TentangTrainer;
