import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import {
  ButtonTagLineContainer,
  Container,
  SubTagLine,
  TagLineSecond,
  TagLineStyled,
  Button,
} from './TagLine.styled';
import MaxContainer from 'components/atoms/MaxContainer';
import logo from 'assets/png/terampil-transparent.png';
import { Link } from 'react-router-dom';
import { checkMobile } from 'utils/checkMobile';
import { dataSubsNotAuth } from 'utils/makeVarAuth';
import RedirectModalToMobile from 'components/molecule/Modal/RedirectToMobile';

const TagLine = ({ scrollPosition }) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  return (
    <MaxContainer>
      <Container>
        <TagLineStyled>Your FUTURE Starts NOW</TagLineStyled>
        {/* <TagLineSecond> Your Future Starts Now</TagLineSecond> */}
        {/* <LazyLoadImage
          src={logo}
          alt={'Transparent Logo'}
          scrollPosition={scrollPosition}
          className="image"
          placeholderSrc="terampil-logo"
        /> */}
        {/* </div> */}
        <SubTagLine>
          #AyoJadiTerampil dengan belajar dari modul praktis melalui metode pembelajaran aktif yang
          diajarkan oleh Trainer terbaik
        </SubTagLine>
        <ButtonTagLineContainer>
          {!checkMobile() ? (
            <Link to="registrasi">
              <Button secondary large>
                Mulai Training Gratis
              </Button>
            </Link>
          ) : (
            <Button secondary large onClick={() => setOpenModal(true)}>
              Mulai Training Gratis
            </Button>
          )}

          <Button
            primary
            large
            onClick={() => {
              dataSubsNotAuth('Yearly-2');
              history.push('/registrasi');
            }}
          >
            Beli Training
          </Button>
        </ButtonTagLineContainer>
      </Container>
      <RedirectModalToMobile isOpen={openModal} onRequestClose={() => setOpenModal(false)} />
    </MaxContainer>
  );
};

export default trackWindowScroll(TagLine);
