import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  SET_BOOKMARK,
  UNSET_BOOKMARK,
  UPDATE_TRAINING,
  UPDATE_USER_TRAINING,
} from 'mutation/training';
import { RETAKE_TRAINING } from 'mutation/training';
import { RETAKE_ALL_TRAINING } from 'mutation/retake';
import { GET_TRAINING_MODULE } from 'queries/training';
import { useSelector } from 'react-redux';
import { setContext } from 'utils';
import { useMutation } from '@apollo/client';
import { submitTrainingViews } from 'mutation/trainingViews';

import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import { Container, TitleTraining, Paragraf, ContainerTitle, ContainerSvg } from './styled';
import MaxContainer from 'components/atoms/MaxContainer';
import Player from 'components/etc/Player';
import ProgressTraining from 'components/atoms/ProgressTraining';
import RetakeHabisModal from 'components/molecule/Modal/RetakeHabisModal';
import RetakeKuisModal from 'components/molecule/Modal/RetakeKuisModal';
import IkutiKelasModal from 'components/molecule/Modal/IkutiKelasModal';

import { ReactComponent as SharedLogo } from 'assets/svg/Icon-Share.svg';
import { ReactComponent as BookmarkLogo } from 'assets/svg/Icon-Bookmark.svg';
import { ReactComponent as UnBookmarkLogo } from 'assets/svg/Icon-Bookmarked.svg';
import { ReactComponent as NoteLogo } from 'assets/svg/icon-notes.svg';
import ShareModal from 'components/molecule/Modal/ShareModal';
import toast from 'react-hot-toast';
import BelumAmbilKuisModal from 'components/molecule/Modal/BelumAmbilKuis';
import { t } from 'hooks/intl/useLang';

let submitted = false;

const VideoTraining = ({
  setCurrentVideoTime,
  showIcon,
  totalUserTrainingDuration,
  training,
  setShowAddNote,
  showAddNote,
  currentSection,
  setCurrentSection,
  totalDuration,
  trainingProgress,
  haveTraining,
  moduleData = [],
  getUserTraining,
  setType,
  getTrainingDetails,
  getTrainingDurations,
  completedSection,
  currentType,
  haveTrainingFreeTrail,
  bookmark = false,
  haveUnlockVideo,
  autoPlayVideo,
  setAutoPlayVideo,
  allowPostTest,
  setStopUpdateLastCurrent,
  getTotalProgressTraining,
}) => {
  const [isBookmark, setIsBookmark] = useState(training?.isBookmark);
  const [showModal, setShowModal] = useState(false);
  const authData = useSelector((state) => state.Auth);
  const [duration, setDuration] = useState(null);
  const history = useHistory();
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [showRetakeHabis, setShowRetakeHabis] = useState(false);
  const [showRetakeModal, setShowRetakeModal] = useState(false);
  const [showIkutiKelasModal, setShowIkutiKelasModal] = useState(false);
  const [showModalAllowPostTest, setShowModallPostTest] = useState(false);
  const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const userTraining = useSelector((state) => state.UserTraining);

  const [retakeKuisPostTest] = useMutation(RETAKE_TRAINING, {
    context: setContext(authData),
  });

  const [retakeTraining] = useMutation(RETAKE_ALL_TRAINING, {
    context: setContext(authData),
    onError: () => {
      setShowIkutiKelasModal(true);
    },
    refetchQueries: [
      {
        query: GET_TRAINING_MODULE,
        context: setContext(authData),
        variables: {
          auth: true,
          id: training?.id,
        },
      },
    ],
  });

  const [updateUserTraining] = useMutation(UPDATE_TRAINING, {
    context: setContext(authData),
  });

  const [updateTraining] = useMutation(UPDATE_USER_TRAINING, {
    context: setContext(authData),
    onCompleted: async () => {
      await getUserTraining();
      await getTrainingDetails();
      await getTrainingDurations();
      await getTotalProgressTraining();
    },
  });

  useEffect(() => {
    if (moduleData) {
      setDuration(
        moduleData[currentSection?.training_order - 1]?.sections[currentSection?.section_order - 1]
          ?.duration,
      );
    }
  }, [moduleData, currentSection]);

  useEffect(() => {
    if (submitted) {
      submitted = false;
    }

    const video = document.getElementById('video');
    if (currentSection?.duration && video) {
      video.curretTime = currentSection?.duration;
    }
  }, [currentSection?.training_chapter_id]);

  useEffect(() => {
    setIsBookmark(bookmark);
  }, [bookmark]);

  const getCurrentTime = async (e) => {
    const video = document.getElementById('video');
    const realTime = ~~video.currentTime;
    const currentTime = await Math.floor(currentPlayer.getStats().playTime);
    setCurrentVideoTime(currentTime);

    if (currentTime % 10 === 0) {
      await updateUserTraining({
        variables: {
          input: {
            trainingId: training.id,
            status: 'Progress',
            trainingProgress: Math.floor(
              ((totalUserTrainingDuration + realTime) / totalDuration) * 100,
            ),
            lastTrainingChapterId: currentSection.training_id,
            lastTrainingSectionId: currentSection.training_chapter_id,
            lastTrainingSectionDuration: realTime,
          },
        },
      });

      await updateTraining({
        variables: {
          input: {
            trainingId: training.id,
            trainingChapterId: currentSection.training_id,
            trainingSectionId: currentSection.training_chapter_id,
            duration: realTime > duration ? duration : realTime,
          },
        },
      });
    }

    if (!submitted && realTime > Math.floor(duration - 2) && training) {
      await updateUserTraining({
        variables: {
          input: {
            trainingId: training.id,
            status: 'Progress',
            trainingProgress: Math.floor(
              ((totalUserTrainingDuration + realTime) / totalDuration) * 100,
            ),
            lastTrainingChapterId: currentSection.training_id,
            lastTrainingSectionId: currentSection.training_chapter_id,
            lastTrainingSectionDuration: realTime,
          },
        },
      });

      await updateTraining({
        variables: {
          input: {
            trainingId: training.id,
            trainingChapterId: currentSection.training_id,
            trainingSectionId: currentSection.training_chapter_id,
            duration: realTime > duration ? duration : realTime,
          },
        },
      });

      if (
        !completedSection[currentSection?.training_id]?.[currentSection?.training_chapter_id]
          ?.completed
      ) {
        await submitTrainingViews(currentSection.training_chapter_id, setContext(authData));
      }

      submitted = true;
    }
  };

  const onPlayVideo = () => {
    setStopUpdateLastCurrent(false);
  };

  const pausePlayer = async (e) => {
    const video = document.getElementById('video');
    const realTime = ~~video.currentTime;
    const currentTime = Math.floor(currentPlayer.getStats().playTime);
    setCurrentVideoTime(currentTime);
    await updateUserTraining({
      variables: {
        input: {
          trainingId: training.id,
          status: 'Progress',
          trainingProgress: Math.floor(
            ((totalUserTrainingDuration + realTime) / totalDuration) * 100,
          ),
          lastTrainingChapterId: currentSection.training_id,
          lastTrainingSectionId: currentSection.training_chapter_id,
          lastTrainingSectionDuration: realTime,
        },
      },
    });

    await updateTraining({
      variables: {
        input: {
          trainingId: training.id,
          trainingChapterId: currentSection.training_id,
          trainingSectionId: currentSection.training_chapter_id,
          duration: realTime > duration ? duration : realTime,
        },
      },
    });
  };

  const { slug } = useParams();

  const [dataSetBookmark] = useMutation(SET_BOOKMARK, {
    context: setContext(authData),
    onCompleted: () => {
      window.Moengage.track_event('Bookmark Training', {
        training_name: training?.title,
        trainer_name: training?.trainer?.fullname,
      });
      toast('Training berhasil dibookmark');
    },
    onError() {
      history.push('/masuk');
    },
  });

  const [dataUnSetBookmark] = useMutation(UNSET_BOOKMARK, {
    context: setContext(authData),
    onCompleted: () => toast(t('Training telah di unbookmark')),
    onError() {
      history.push('/masuk');
    },
  });

  const setBookmark = () => {
    if (!isBookmark) {
      dataSetBookmark({
        variables: {
          idTraining: training.id,
        },
      }).then((_) => {});
    } else {
      dataUnSetBookmark({
        variables: {
          idTraining: training.id,
        },
      }).then((_) => {});
    }
  };

  const rewind = () => {
    const video = document.getElementById('video');
    const currentTime = video.currentTime;

    if (currentTime <= 10) {
      //set current time 0
      video.currentTime = 0;
      return;
    }

    video.currentTime = currentTime - 10;

    return;
  };

  const forward = () => {
    const video = document.getElementById('video');
    const currentTime = video.currentTime;
    const duration =
      moduleData[currentSection?.training_order - 1].sections[currentSection?.section_order - 1]
        ?.duration;

    if (currentTime + 10 >= duration) {
      video.currentTime = duration;

      return;
    }

    video.currentTime = currentTime + 10;

    return;
  };

  const skip = () => {
    const nextSectionId =
      moduleData[currentSection?.training_order - 1].sections[currentSection?.section_order]?.id;
    const type =
      moduleData[currentSection?.training_order - 1].sections[currentSection?.section_order]?.type;

    if (nextSectionId) {
      if (type === 'PostTest' && !allowPostTest) {
        setShowModallPostTest(true);
      } else if (
        !haveTrainingFreeTrail &&
        !haveUnlockVideo &&
        type !== 'Material' &&
        completedSection[currentSection?.training_id]?.[nextSectionId]?.completed
      ) {
        if (type === 'PostTest') {
          setShowRetakeHabis(true);
        } else if (type === 'Test') {
          setShowRetakeModal(true);
        }
      } else if (
        (!haveTrainingFreeTrail &&
          !haveUnlockVideo &&
          type === 'Material' &&
          completedSection[currentSection?.training_id]?.[nextSectionId]?.completed) ||
        userTraining?.data?.sections_freetrial?.includes(nextSectionId) ||
        userTraining?.data?.training_section_id?.includes(nextSectionId) ||
        (!haveTrainingFreeTrail &&
          !haveUnlockVideo &&
          !completedSection[currentSection?.training_id]?.[nextSectionId]?.completed)
      ) {
        setCurrentSection({
          ...currentSection,
          training_order: currentSection.training_order,
          section_order: currentSection.section_order + 1,
          training_chapter_id: nextSectionId,
          duration: 0,
        });

        setType(type);
      }
    } else {
      setType('Material');
      setCurrentSection({
        ...currentSection,
        training_id: moduleData[currentSection?.training_order]?.id,
        training_chapter_id: moduleData[currentSection?.training_order]?.sections[0]?.id,
        training_order: currentSection?.training_order + 1,
        section_order: 1,
      });
    }
  };

  const submitRetake = async () => {
    const nextSectionId =
      moduleData[currentSection?.training_order - 1].sections[currentSection?.section_order]?.id;
    const type =
      moduleData[currentSection?.training_order - 1].sections[currentSection?.section_order]?.type;

    try {
      const data = await retakeKuisPostTest({
        variables: {
          input: {
            retakeType: type === 'Test' ? 'Quiz' : 'PostTest',
            trainingId: training?.id,
            trainingSectionId: nextSectionId,
          },
        },
      });

      if (data?.data?.RetakeQuizPostTest?.status === 200) {
        setCurrentSection({
          ...currentSection,
          training_chapter_id: nextSectionId,
          training_order: currentSection?.training_order,
          section_order: currentSection?.section_order + 1,
        });
        setType(type);
        setShowRetakeModal(false);
      } else {
        setShowRetakeModal(false);
        setShowRetakeHabis(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const submitRetakeTraining = async () => {
    try {
      await retakeTraining({
        variables: {
          input: {
            trainingId: training?.id,
          },
        },
      });

      setShowRetakeModal(false);
      getUserTraining();
      getTrainingDetails();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <MaxContainer>
        {moduleData.length > 0 &&
          (currentSection?.trailer ? (
            <Player
              showControls
              manifestUrl={is_safari ? training?.video_apple_url : training?.video_non_apple_url}
              posterUrl={training?.thumbnail}
              licenseServer={
                'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=5BC068'
              }
            />
          ) : (
            <Player
              showControls
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
              moduleData={moduleData}
              setType={setType}
              manifestUrl={
                is_safari
                  ? moduleData[currentSection?.training_order - 1]?.sections[
                      currentSection?.section_order - 1
                    ]?.video_apple_url
                  : moduleData[currentSection?.training_order - 1]?.sections[
                      currentSection?.section_order - 1
                    ]?.video_non_apple_url
              }
              posterUrl={
                moduleData[currentSection?.training_order - 1]?.sections[
                  currentSection?.section_order - 1
                ]?.thumbnail
              }
              licenseServer={
                'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=5BC068'
              }
              onPause={pausePlayer}
              onTimeUpdate={getCurrentTime}
              isOverlay
              setCurrentPlayer={setCurrentPlayer}
              fullControls
              forwardFunction={forward}
              rewindFunction={rewind}
              skipFunction={skip}
              haveTrainingFreeTrail={haveTrainingFreeTrail}
              haveUnlockVideo={haveUnlockVideo}
              autoPlayVideo={autoPlayVideo}
              setAutoPlayVideo={setAutoPlayVideo}
              completedSection={completedSection}
              setShowRetakeHabis={setShowRetakeHabis}
              setShowModallPostTest={setShowModallPostTest}
              allowPostTest={allowPostTest}
              onPlayVideo={onPlayVideo}
              setShowRetakeModal={setShowRetakeModal}
            />
          ))}
        <ContainerTitle>
          <TitleTraining>{training?.title}</TitleTraining>

          <Paragraf>
            <Link
              style={{ textDecoration: 'none', color: '#fdfdfd' }}
              to={`/trainer/${training?.trainer?.slug}`}
            >
              {training?.trainer?.fullname}
            </Link>
          </Paragraf>
          <ContainerSvg>
            {showIcon && (
              <div className="left" onClick={() => setShowAddNote(!showAddNote)}>
                <Tooltip
                  title="Catatan"
                  position="bottom"
                  touchHold="false"
                  trigger="mouseenter focus"
                  arrow="true"
                >
                  <NoteLogo />
                </Tooltip>
              </div>
            )}
            <div
              className="left"
              onClick={() => {
                setIsBookmark((prevBookmark) => !prevBookmark);
                setBookmark();
              }}
            >
              <Tooltip
                title="Bookmark"
                position="bottom"
                touchHold="false"
                trigger="mouseenter focus"
                arrow="true"
              >
                {!isBookmark ? <BookmarkLogo /> : <UnBookmarkLogo />}
              </Tooltip>
            </div>
            <div className="left">
              <Tooltip
                title="Share"
                position="bottom"
                touchHold="false"
                trigger="mouseenter focus"
                arrow="true"
              >
                <SharedLogo onClick={() => setShowModal(true)} />
              </Tooltip>
            </div>
          </ContainerSvg>
          {haveTraining && <ProgressTraining percetage={trainingProgress} />}
        </ContainerTitle>
      </MaxContainer>
      <ShareModal
        onRequestClose={() => setShowModal(false)}
        urlPath={`https://www.terampil.com/training/${slug}`}
        isOpen={showModal}
      />
      {showRetakeModal && (
        <RetakeKuisModal
          isOpen={true}
          type={'Test'}
          setType={setType}
          moduleData={moduleData}
          trainingId={training.id}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          onRequestClose={() => setShowRetakeModal(false)}
          setShowRetakeModal={setShowRetakeModal}
          submitRetake={submitRetake}
          submitRetakeTraining={submitRetakeTraining}
        />
      )}
      <RetakeHabisModal
        setShowRetakeHabis={setShowRetakeHabis}
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        isOpen={showRetakeHabis}
        onRequestClose={() => setShowRetakeHabis(false)}
        setType={setType}
        moduleData={moduleData}
        getUserTraining={getUserTraining}
        getTrainingDetails={getTrainingDetails}
      />
      {/*<IkutiKelasModal*/}
      {/*  quota={0}*/}
      {/*  freeTrial={true}*/}
      {/*  isOpen={showIkutiKelasModal}*/}
      {/*  onRequestClose={() => setShowIkutiKelasModal(false)}*/}
      {/*/>*/}
      <BelumAmbilKuisModal isOpen={showModalAllowPostTest} onRequestClose={setShowModallPostTest} />
    </Container>
  );
};

export default VideoTraining;
