import styled from 'styled-components';

export const Title = styled.h6`
  font-size: 20px;

  margin: 0;
  margin-top: 16px;
`;

export const ShareContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 12px;

  margin: 0 24px;

  &.mb-3 {
    margin-bottom: 32px;
  }
`;
