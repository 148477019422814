import styled from 'styled-components';

export const Section = styled.section`
  background: #202020;

  text-align: left;
  padding: 20px 100px;
  padding-bottom: 60px;

  border-bottom: 2px solid #626262;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text-container {
    width: 40%;
  }

  h2 {
    font-size: 50px;
    line-height: 54px;
    margin: 0;
    margin-bottom: 28px;
  }

  p {
    font-size: 20px;
    font-weight: normal;
    font-family: Inter, sans-serif;
  }

  button {
    margin-top: 50px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xl} {
    .text-container {
      width: 45%;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 20px 60px;

    h2 {
      font-size: 42px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 10px 24px;
    padding-bottom: 32px;
    text-align: center;

    h2 {
      margin: 24px 0 24px 0;
      font-size: 30px;
      line-height: 33px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
    }

    .text-container {
      width: 100%;
    }

    button {
      margin-top: 24px;
      width: 100%;
    }

    & > div {
      flex-wrap: wrap-reverse;
    }
  }
`;
