import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  padding: 0 92px 0 100px;
  margin-top: 50px;

  .shaka-play-button {
    padding: 4%;
  }
  .shaka-spinner {
    padding: 4.3%;
  }

  video[poster] {
    object-fit: cover;
    border-radius: 10px;
    @media screen and ${(props) => props.theme.breakpoints.md} {
      border-radius: 5px;
    }
  }

  .video-adds {
    border-radius: 10px;
    width: 100%;

  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0 60px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 0 40px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0 25px;
    margin-top: ${({ type }) => (type === 'landing_page' ? 0 : 32)}px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
`;

export const Player = styled.video`
    height: 100%;
    width: 100%;
`

export const VideoPlayer = styled.video`
  width: inherit;
  height: 702px;
  cursor: pointer;

  :active {
    outline: none;
  }

  :focus {
    outline: none;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 100%;
    height: 520px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 420px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    height: 180px;
  }
`;