import styled from 'styled-components';
import Button from 'components/atoms/Button';
import PopupModal from '../../../atoms/Modal';

export const LengkapiProfilModalStyled = styled(PopupModal)`
  padding: 24px;

  .disabled {
    color: #626262;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-align: center;

  color: #fdfdfd;
`;

export const Description = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin: 24px 0 8px;

  color: #fdfdfd;
`;

export const SaveButton = styled(Button)`
  width: 100%;
  margin: 32px 0 16px;
`;
