import React from 'react';
import { Container, CardContainer, Title, Paragraf } from './styled';
import CircleProgressbar from 'components/atoms/CircleProgressTraining/';
import { t } from 'hooks/intl/useLang';

const bgImage = `https://d2jr0bw5daaf2g.cloudfront.net/assets/pelatihan_saya/bg_pelatihan_saya.png`;
const ProgressBarHeader = ({ data, userData, userSummary }) => {
  return (
    <Container image={bgImage}>
      <CardContainer>
        <Title>{t('Training Saya')}</Title>
        <CircleProgressbar userData={userData} userSummary={userSummary} />
        {data?.ProfileUser.is_subscription ? (
          <Paragraf>{t('Yuk selesaikan training yang sudah kamu mulai. Semangat!')}</Paragraf>
        ) : (
          <Paragraf>
            {t('Ayo mulai belajar dengan memilih training yang kamu inginkan untuk menjadi Terampil')}
          </Paragraf>
        )}
      </CardContainer>
    </Container>
  );
};

export default ProgressBarHeader;
