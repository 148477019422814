import styled from 'styled-components';

export const Container = styled.div`
  width: 52%;
  height: ${(props) => (props.trainer ? '750px' : '600px')};
  border-radius: 10px;

  transition: all 0.3s ease;

  display: flex;
  align-items: center;

  position: relative;

  .main-thumb {
    width: 95%;
    height: 80%;
    background-color: #c4c4c4;
    z-index: 2;

    border-radius: 10px;

    background-image: url('${(props) => props.src}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    transition: all 0.1s ease-out;
  }

  .bg-thumb {
    width: 85%;
    height: 400px;
    background-color: #1d1d1d;

    z-index: 1;

    border-radius: 20px;

    position: absolute;

    bottom: 0;
    left: 15%;
    top: 30%;
  }

  .bg-trainer {
    background: #373737;
    height: 550px;
    left: 29%;
    top: 23%;

    width: 72%;

    border-radius: 10px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xl} {
    height: ${({ trainer }) => (trainer ? '650px' : '600px')};

    .bg-trainer {
      top: 20%;
      height: 500px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    height: 450px;

    .bg-main-trainer {
      height: 400px;
      width: 96%;
    }

    .bg-thumb {
      height: 300px;
    }

    .bg-trainer {
      top: 31%;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    height: 300px;

    .bg-main-trainer {
      height: 250px;
    }

    .bg-thumb {
      top: 25%;
      height: 220px;
    }
  }
`;
