import styled from 'styled-components';
import ResultImage from 'assets/png/TBM/result.png';
import CardTraining from '../../../components/molecule/Cards/CardTraining';

export const ResultPageMBTI = styled.div`
  background: white;
  margin-top: 77px;
`;

export const TitleSectionResult = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: left;

  color: #187336;
`;

export const TextResultMBTI = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  /* or 150% */

  color: #626262;

  .strong {
    font-weight: 700;
    color: #141414;
  }
`;

export const TitleResultMBTI = styled.div`
  position: relative;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 45px;
  margin: 0;
  padding: 0;
  white-space: pre-line;
  text-align: left;

  color: #ffffff;
`;

export const AccentGreen = styled.div`
  width: 50%;
  height: 496px;
  background: #187336;
  border-radius: 0 0 0 20px;
  position: absolute;
  top: 77px;
  right: 0;
  z-index: 0;
`;

export const TopSectionResult = styled.div`
  position: relative;
  display: flex;
  padding: 45px 100px;
  box-sizing: border-box;
  background: transparent;
  z-index: 3;
`;

export const ImageResultMBTI = styled.div`
  width: 60%;
  height: 400px;
  border-radius: 10px;
  background-image: url(${(props) => props.image});
  background-size: cover;
`;

export const ResultContainer = styled.div`
  width: 40%;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  padding-left: 70px;
`;

export const SectionContainer = styled.div`
  padding: 32px 100px 18px;
`;

export const JobRadio = styled.div`
  ${(props) =>
    props.selected
      ? 'background: #ffffff;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);'
      : 'border: 1px solid #FFFFFF;'}

  border-radius: 5px;
  width: fit-content;
  padding: 13px;
  margin-top: 14px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  color: ${(props) => (props.selected ? '#187336' : 'white')};
  cursor: pointer;
`;

export const ListDescription = styled.ul``;
export const ItemList = styled.li`
  color: #187336;
`;

export const CardTrainingStyled = styled(CardTraining)`
  flex-basis: 24%;
  margin: 0;
  @media only screen and ${(props) => props.theme.breakpoints.md} {
    flex-basis: 49%;
  }
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    flex-basis: 80%;
  }
`;
