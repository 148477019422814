import styled from 'styled-components';

export const Section = styled.section`
  padding: 50px 100px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 50px 60px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 50px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 20px 24px;

    img {
      max-width: 175px;
    }

    h2 {
      line-height: 30px;
      padding-bottom: 30px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0px 24px;
    padding-bottom: 1px;
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  margin-bottom: 30px;

  &.reverse {
    flex-wrap: wrap-reverse;
  }

  & > div {
    text-align: left;
    width: 42%;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    flex-direction: column-reverse;

    &.reverse {
      flex-direction: column;
    }
    & > div {
      text-align: center;
      width: 100%;
    }
  }
`;

export const SubTitle = styled.h3`
  font-size: 24px;
  color: ${(props) => props.theme.color.primary};

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 18px;
  }
`;

export const Content = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #c4c4c4;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 14px;
  }
`;
