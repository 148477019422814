import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  align-items: center;

  .title {
    font-family: Archivo, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 16px;

    color: #039735;
  }

  .desc {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */
    text-align: center;
    margin-bottom: 32px;

    color: #626262;
  }

  .button-oke {
    width: 90%;
  }
`;


export const Image = styled.img`
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 120px;
    height: 120px;
  }
`;