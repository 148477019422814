import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_TOTAL_RETAKE } from 'queries/retake';
import { useSelector } from 'react-redux';
import { setContext } from 'utils';

import PopupModal from 'components/atoms/Modal';
import { Title, Separator } from '../KeluarModal/styled';
import { Content, Button, ButtonContainer } from '../SmallDarkModal/styled';
import { t } from 'hooks/intl/useLang';

const RetakeKuisModal = ({
  submitRetake,
  setShowRetakeModal,
  trainingId,
  currentSection,
  setCurrentSection,
  moduleData,
  setType,
  type,
  submitRetakeTraining,
  postTest,
  pushLocation,
  ...rest
}) => {
  const authData = useSelector((state) => state.Auth);

  const { loading } = useQuery(GET_TOTAL_RETAKE, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      input: {
        trainingId: trainingId,
        trainingSectionId: currentSection?.training_chapter_id,
      },
    },
    onError: () => {},
  });

  const renderModal = () => {
    // CURRENTLY SET TO TRUE TO ALLOW UNLIMITED RETAKE
    if (true) {
      return (
        <>
          <Title>{t('Yakin Mengikuti')} {type === 'Test' ? t('Kuis') : t('Test Akhir')} Lagi?</Title>
          <Separator />
          <Content>
            <p>
              {type === 'Test'
                ? `${t('Kamu sudah pernah mengikuti')} ${type === 'Test' ? t('Kuis') : t('Test Akhir')} ${t('sebelumnya')}. ${t('Nilai')}
              ${type === 'Test' ? t('Kuis') : t('Test Akhir')} ${t('yang diambil adalah nilai')}
              ${type === 'Test' ? t('Kuis') : t('Test Akhir')} ${t('terakhir')}.`
                : t('Kamu sudah lulus tes akhir dan mendapatkan sertifikat di training ini. Jika kamu mengikuti tes akhir lagi, maka nilai yang diambil adalah nilai terakhir.')}
            </p>
          </Content>
          <ButtonContainer className="mx-3 flex">
            <Button
              className="secondary"
              onClick={() => {
                if (postTest) {
                  pushLocation();
                } else {
                  submitRetake();
                }
              }}
            >
              {t('Ya')}
            </Button>
            <Button
              onClick={() => {
                setShowRetakeModal(false);
              }}
            >
              {t('Tidak')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else {
      return (
        <>
          <Title>Kamu Yakin Mau Mengulangi Training Ini?</Title>
          <Separator />
          <Content>
            <p>
              Kuota kamu untuk mengikuti kuis ini sudah habis. Untuk mengikuti kuis lagi, silahkan
              mengulang training dari awal. Kuota training kamu akan berkurang ketika kamu
              mengulangi training.
            </p>
          </Content>
          <ButtonContainer className="mx-3 flex">
            <Button className="secondary" onClick={submitRetakeTraining}>
              Retake
            </Button>
            <Button
              onClick={() => {
                if (setType) {
                  const nextChapter =
                    moduleData?.TrainingChapters?.items[currentSection?.training_order - 1]
                      ?.sections[currentSection?.section_order];
                  const nextType =
                    moduleData?.TrainingChapters?.items[currentSection?.training_order - 1]
                      ?.sections[currentSection?.section_order]?.type;

                  if (nextChapter) {
                    setType(nextType);

                    setCurrentSection({
                      ...currentSection,
                      training_id:
                        moduleData?.TrainingChapters?.items[currentSection?.training_order - 1]?.id,
                      training_chapter_id:
                        moduleData?.TrainingChapters?.items[currentSection?.training_order - 1]
                          ?.sections[currentSection?.section_order]?.id,
                      training_order: currentSection?.training_order,
                      section_order: currentSection?.section_order + 1,
                    });
                  } else {
                    setType('Material');

                    setCurrentSection({
                      ...currentSection,
                      training_id:
                        moduleData?.TrainingChapters?.items[currentSection?.training_order - 1]?.id,
                      training_chapter_id:
                        moduleData?.TrainingChapters?.items[currentSection?.training_order]
                          ?.sections[0]?.id,
                      training_order: currentSection?.training_order + 1,
                      section_order: 1,
                    });
                  }
                }
                setShowRetakeModal(false);
              }}
            >
              Tidak
            </Button>
          </ButtonContainer>
        </>
      );
    }
  };

  return (
    <PopupModal dark medium {...rest}>
      {loading ? <h6>Loading</h6> : <>{renderModal()}</>}
    </PopupModal>
  );
};

export default RetakeKuisModal;
