import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/';

export const Container = styled.section`
  position: relative;
  width: 100%;
  height: 579px;
  background-image: url(${(props) => props.image});
  box-sizing: border-box;
  background-position: center;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  margin-top: 77px;

  .container-upside {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 70px 23px 10px;
    height: auto;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 70px 25px 10px;
    height: auto;

    .container-upside {
      padding: 50px 0;
    }

    div.container-button {
      width: 50%;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    div.container-button {
      width: 100%;
    }
  }
`;
export const Title = styled.h1`
  font-family: ${(props) => (props.trainer ? 'Crimson Text' : 'Archivo')};
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  color: #fdfdfd;
  letter-spacing: normal;
  margin: 22px 0 22px 0;
  line-height: 1.25;
  width: 75%;
  max-width: ${(props) => (props.prakerja ? '865px' : '')};

  span {
    font-size: 50px;
    font-weight: normal;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 50px;
    width: unset;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 30px;
    font-weight: 700;
    margin: 0;

    span {
      font-size: 24px;
      font-weight: 500;
    }
  }
`;

export const Paragraf = styled.p`
  width: 60%;
  font-family: Inter;
  font-size: 20px;
  color: #ffff;
  font-weight: normal;
  line-height: 1.5;
  margin: 0 auto;
  margin-bottom: 30px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 21px;
    margin: 24px 0;
  }
`;

export const Button = styled(ButtonStyled)`
  width: 100%;
  padding: 16px 24px;
  margin: 0;
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    margin: 0;
  }
`;

export const ButtonOutline = styled(ButtonStyled)`
  width: 270px;
  padding: 16px 24px;
  border: 1px solid #00debf;
  background-color: transparent;
  color: #00debf;
  margin: 0;
  :hover {
    background-color: transparent;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    margin: 0;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  gap: 16px;

  html {
    scroll-behavior: smooth !important;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    flex-direction: column;
    width: 100%;
  }
`;
