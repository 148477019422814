import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query getUserById($id: Int) {
    User(id: $id) {
      id
      email
      phone_number
      Profile {
        fullname
      }
    }
  }
`;

export const GET_USER_DETAIL = gql`
  query GetUser($slug: String) {
    ProfileUser(slug: $slug) {
      fullname
      profession
      website
      id
      user_id
      about
      twitter
      facebook
      linkedin
      unread_notification
      instagram
      avatar
      all_access
      all_access_end_date
      video_quota
      categories {
        id
        name
        icon_default
      }
      User {
        email
        phone_number
        is_active
        isEmptyPassword
        free_trial
      }
      is_skip
      is_subscription
      is_unsubscription
      training_quota
      subscription_end_date
      subscription_type
      isFirstPaid
      total_point
      referral_code
    }
  }
`;

export const GET_USER_HEADER = gql`
  query UserHeader($slug: String) {
    ProfileUser(slug: $slug) {
      fullname
      profession
      avatar
      slug
      subscription_type
      is_subscription
      is_unsubscription
      is_skip
      training_quota
      video_quota
      subscription_end_date
      isFirstPaid
      User {
        email
        phone_number
        free_trial
        roles
        id
      }
      categories {
        id
      }
    }
  }
`;

export const GET_USER_POINT = gql`
  query GetPointUser($limit: Int, $page: Int) {
    UserPoints(input: { limit: $limit, page: $page }) {
      total
      items {
        description
        created_at
        point
        count_register
        message
        referring {
          fullname
          slug
        }
      }
    }
  }
`;

export const GET_USER_CERTIFICATES = gql`
  query GetUserCertificates {
    UserCertificates {
      status
      items {
        id
        is_prakerja
        type
        link
        serial_number
        score
        file {
          id
          filename
          link
          bucket
          key
        }
        training {
          id
          title
          thumbnail
          collaboration {
            image
          }
          cooperation {
            image
          }
          trainer {
            fullname
            profession
          }
        }
        created_at
      }
    }
  }
`;

export const GET_USER_FREE_TRIAL = gql`
  query GetUserFreeTrial($slug: String) {
    ProfileUser(slug: $slug) {
      User {
        free_trial
      }
      subscription_type
      is_subscription
      training_quota
      video_quota
    }
  }
`;

export const GET_USER_TOKEN = gql`
  query GET_USER($id: Int) {
    User(id: $id) {
      token_id
      accessToken
    }
  }
`;

export const CHECK_EMAIL = gql`
  query CHECK_EMAIL($email: String!, $token: String!) {
    CheckEmail(email: $email, token: $token) {
      email
      phone_number
      accessToken
      Profile {
        fullname
        id
        referral_code
        slug
        all_access
        all_access_end_date
      }
      client_id
      id
    }
  }
`;
