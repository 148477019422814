import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .code {
    flex: 1;
    margin-right: 16px;
    text-align: center;
    pointer-events: none;
    border-color: ${(props) => (props.dark ? '#626262' : '#c4c4c4')};
  }

  ::placeholder {
    font-size: 16px;
  }

  .number {
    flex: 4;

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      flex: 3;
    }
  }
`;
