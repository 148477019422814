import styled from 'styled-components';

export const Container = styled.div`
  background-color: #141414;
  display: grid;
  place-items: center;
  margin-top: 77px;

  .logo-bank {
    width: 15%;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    place-items: start;
    margin-top: 16px;
    background-color: #202020;
  }
`;

export const Title = styled.h3`
  ${(props) => props.theme.font.primary.title};
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 20px;
  text-align: center;
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    text-align: left;
    margin: 0;
    background-color: #141414;
    padding: 4px 16px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const DateTitle = styled.h2`
  ${(props) => props.theme.font.primary.title};
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #f18f01;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin: 0;
    background-color: #141414;
    padding: 8px 16px 32px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  div.container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`;

export const TotalPayment = styled.h4.attrs((props) => ({
  color: props.salin ? '#00DEBF' : '#f18f01',
}))`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${(props) => props.color};
  text-align: left;
  margin: 4px 0 0 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const CardDetails = styled.div`
  border-radius: 10px;
  border: solid 1px #626262;
  height: auto;
  box-sizing: border-box;
  width: 50%;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 70%;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    border: none;
    width: 100%;
    height: auto;
    margin-top: 8px;
    background-color: #141414;
    padding: 20px 16px;
    border-radius: 0;
  }
`;

export const PaymentName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  border-bottom: 1px solid #626262;

  h2 {
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #fdfdfd;
    margin: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    h2 {
      font-size: 18px;
      line-height: 20px;
    }

    svg {
      width: 77px;
    }

    padding: 12px 0px;
    border: none;
  }
`;

export const PaymentDetails = styled.div`
  padding: 32px;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #c4c4c4;
    margin: 0;
    text-align: left;
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    p {
      font-size: 14px;
      line-height: 21px;
    }

    padding: 12px 0;
  }
`;

export const NomorVirtual = styled.h5`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${(props) => (props.salin === 'copy' ? '#00DEBF' : '#fdfdfd')};
  cursor: ${(props) => (props.salin === 'copy' ? 'pointer' : '')};
  text-align: left;
  margin: 4px 0 32px 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 20px;
    line-height: 24px;
    cursor: ${(props) => (props.salin === 'copy' ? 'pointer' : '')};
    margin: 4px 0 20px 0;
  }
`;
