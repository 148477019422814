import styled from 'styled-components';

export const Container = styled.section`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-bottom: 50px;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: column;
    padding-bottom: 30px;
  }
`;
