import { gql } from '@apollo/client';
import { webinarFields } from './webinar';

export const GET_USER_ORDERS = gql`
  query GET_USER_ORDERS {
    UserOrders(input: { limit: 100 }) {
      total
      status
      items {
        id
        invoice_number
        status
        price
        order_name
        quota
        start_date
        end_date
        due_date
        payment_date
        payment_method
        subscription {
          id
          title
          subscription_type
        }
        updated_at
      }
    }
  }
`;

export const GET_USER_LAST_ORDER_ACTIVE = gql`
  query GET_USER_LAST_ORDER_ACTIVE {
    UserOrders(input: { lastOrderActive: true }) {
      total
      status
      items {
        id
        invoice_number
        status
        price
        order_name
        quota
        start_date
        end_date
        due_date
        payment_date
        subscription {
          id
          title
          subscription_type
        }
      }
    }
  }
`;

export const GET_USER_ORDERS_DETAIL = gql`
  query GET_USER_ORDERS_DETAIL($invoice: String) {
    UserOrderDetail(input: { invoiceNumber: $invoice }) {
      id
      user {
        user_id
        fullname
        User {
          email
          phone_number
        }
      }
      status
      invoice_number
      price
      subscription {
        id
        subscription_type
        price_per_month
        total_price
      }
      training {
        id
        title
        training_price
      }
      package {
        id
        name
        package_type
        price
      }
      webinar {
        ${webinarFields}
      }
      order_name
      quota
      start_date
      end_date
      payment_method
      fullname
      due_date
      payment_date
      card_number
      updated_at
      voucher_id
      voucher_code
      file {
        id
        link
      }
    }
  }
`;
