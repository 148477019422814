import React from 'react';
import { PopularSection } from './PopularTraining.styled';
import MaxContainer from 'components/atoms/MaxContainer';
import TitleSection from 'components/molecule/TitleSectionHome';
import SliderTraining from 'components/molecule/SliderTraining';
import { useQuery } from '@apollo/client';
import { GET_POPULAR_TRAINING, GET_TRAINING_POPULER_REAL } from 'queries/training';
import { LoaderDualRing } from '../../atoms/Loading';

import { useSelector } from 'react-redux';
import { getValue } from 'utils';
import { t } from 'hooks/intl/useLang';

// this is popular section in HomePage

function PopularTraining() {
  const authData = useSelector((state) => {
    return state.Auth;
  });

  
  const { data: populerTraining, loadingPopuler } = useQuery(GET_TRAINING_POPULER_REAL);
  return (
    <PopularSection>
      <MaxContainer>
        <TitleSection destination="/trainings?category=all&sort=0">{t('Training Populer')}</TitleSection>
        {loadingPopuler && <LoaderDualRing />}
        {populerTraining && (
          <SliderTraining
            populer
            trainings={populerTraining?.TrainingPopuler?.items}
            id="popular-home"
          />
        )}
      </MaxContainer>
    </PopularSection>
  );
}

export default PopularTraining;
