import styled from 'styled-components';

export const Title = styled.h6`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;

  text-align: center;

  color: #fdfdfd;

  margin: 16px;
`;

export const Separator = styled.div`
  background: #626262;

  width: 100%;
  height: 1px;
`;

export const Description = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  text-align: center;

  color: #fdfdfd;

  margin-top: 24px;
  margin-bottom: 32px;

  & + div {
    margin-bottom: 32px;

    display: flex;
    justify-content: space-between;

    padding: 0 32px;
  }
`;

export const Button = styled.button`
  width: 210px;
  height: 50px;

  cursor: pointer;

  padding: 0;

  border-radius: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  &.cancel {
    color: #000004;
    background: ${({ theme }) => theme.color.primary};
    border: none;
  }

  &.accept {
    color: #c4c4c4;
    background: transparent;
    border: 1px solid #c4c4c4;
  }

  &.accept:hover {
    border: 2px solid #c4c4c4;
  }

  &.cancel:hover {
    background: #00f1cf;
  }
`;
