import React from 'react';
import { TabMetodeItem, TabMetodeStyled } from './styled';
import { useHistory } from 'react-router-dom';
function TabMetode({ eventSelect, select, isPrakerja }) {
  const history = useHistory();
  return (
    <TabMetodeStyled>
      <TabMetodeItem
        selected={select === 'Transfer Virtual Account'}
        onClick={() => eventSelect('Transfer Virtual Account')}
      >
        Transfer Virtual Account
      </TabMetodeItem>
      <TabMetodeItem
        selected={select === 'Dompet Online'}
        onClick={() => eventSelect('Dompet Online')}
      >
        Dompet Online
      </TabMetodeItem>
      {isPrakerja && (
        <TabMetodeItem
          selected={select === 'Prakerja'}
          onClick={() => history.push('/pembayaran/Prakerja')}
        >
          Prakerja
        </TabMetodeItem>
      )}
      <TabMetodeItem
        selected={select === 'Kartu Kredit'}
        onClick={() => eventSelect('Kartu Kredit')}
      >
        Kartu Kredit
      </TabMetodeItem>
    </TabMetodeStyled>
  );
}

export default TabMetode;
