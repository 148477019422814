export const submitTrainingViews = async (trainingSectionId, token) => {
  try {
    await fetch(`${process.env.REACT_APP_CMS_URI}video-view/${trainingSectionId}`, {
      method: 'POST',
      headers: {
        authorization: `bearer ${token?.headers?.token}`,
      },
    });
  } catch (error) {
    return error;
  }
};
