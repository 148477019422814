import { makeVar } from '@apollo/client';

export const userIdVar = makeVar(undefined);
export const emailVar = makeVar(undefined);
export const phoneVar = makeVar(undefined);
export const uuidVar = makeVar(undefined);
export const deviceVar = makeVar(undefined);
export const fullnameVar = makeVar(undefined);
export const dataOrderCCMobile = makeVar(undefined);
export const dataSubsNotAuth = makeVar(undefined);
