import styled from 'styled-components';
import InputFieldStyled from 'components/atoms/InputField';

export const Container = styled.div`
  background-color: #141414;
  display: grid;
  place-items: center;
  margin-top: 77px;

  .logo-dompet-online {
    width: 25%;
  }

  .qr-code {
    width: 300px;
    height: 300px;
    justify-self: center;
    margin-top: 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    place-items: start;
    margin-top: 16px;
    background-color: #202020;
  }
`;

export const Title = styled.h3`
  ${(props) => props.theme.font.primary.title};
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 20px;
  text-align: center;
  color: #c4c4c4;
  margin: 32px 0 16px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    text-align: left;
    margin: 0;
    background-color: #141414;
    padding: 4px 16px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const DateTitle = styled.h2`
  ${(props) => props.theme.font.primary.title};
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #f18f01;
  margin: 0 0 32px 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin: 0;
    background-color: #141414;
    padding: 8px 16px 32px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const CardDetails = styled.div`
  border-radius: 10px;
  border: solid 1px #626262;
  height: auto;
  box-sizing: border-box;
  width: 50%;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 70%;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    border: none;
    width: 100%;
    height: auto;
    margin-top: 8px;
    background-color: #141414;
    padding: 20px 16px;
    border-radius: 0;
  }
`;

export const PaymentName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  border-bottom: 1px solid #626262;

  h2 {
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #fdfdfd;
    margin: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    h2 {
      font-size: 18px;
      line-height: 20px;
    }

    svg {
      width: 77px;
    }

    padding: 12px 0px;
    border: none;
  }
`;

export const PaymentDetails = styled.div`
  padding: 32px 32px 0;

  div.rincian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    align-items: flex-end;
  }

  & > h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: Bold;
    font-size: 20px;
    line-height: 30px;
    color: #fdfdfd;
    text-align: left;
    margin: 4px 0 32px 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    h4 {
      font-size: 20px;
      line-height: 30px;
      margin: 4px 0 20px 0;
    }

    div.rincian {
      margin-bottom: 10px;
    }

    padding: 0;
  }
`;

export const TotalPayment = styled.h4.attrs((props) => ({
  color: props.salin ? '#00DEBF' : '#f18f01',
}))`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${(props) => props.color};
  text-align: left;
  margin: 4px 0 0 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #c4c4c4;
  margin: 0;
  text-align: ${(props) => (props.text === 'center' ? 'center' : 'left')};
`;

export const ContainerQrCode = styled.div`
  margin: 24px auto 0;
  background-color: #141414;
  border-radius: 10px;
  border: solid 1px #626262;
  width: 50%;
  padding: 24px 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    background-color: white;
    margin-top: 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 70%;
    align-items: flex-start;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 16px;
    border: none;
    width: 100%;
    border-radius: 0;
    margin: 8px auto 0;
  }
`;

export const InputField = styled(InputFieldStyled)`
  margin: 0;
  border-color: #626262;
  ::placeholder {
    color: #00debf;
  }
`;
