import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  flex-direction: column;

  .main-wrapper {
    position: relative;
    background: #202020;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 240px;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 180px 0 373px 100px;

    h2 {
      margin: 0;
      font-size: 60px;
      font-style: 'Archivo';
      margin-bottom: 24px;
    }

    p {
      margin: 0;
      margin-bottom: 32px;
      font-size: 20px;
      line-height: 26px;
      font-weight: normal;
      font-family: Inter, sans-serif;
    }

    button {
      margin: 0;
      font-size: 20px;
      text-align: center;
      line-height: 26px;
      width: 270px;
      height: 60px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    .main-wrapper {
      margin-right: 170px;
    }

    .text-container {
      padding: 100px 0 333px 50px;
      margin-right: 20px;

      h2 {
        margin: 0 0 24px 0;
        font-size: 35px;
        line-height: 33px;
      }

      p {
        text-align: left;
        font-size: 21px;
        line-height: 30px;
        max-width: 400px;
      }

      button {
        margin: 0;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    .main-wrapper {
      width: 100%;
      background: #202020;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding: 32px 0px;
    }

    .text-container {
      padding: 0;
      align-items: center;
      margin: 0;

      h2 {
        margin: 24px 0 24px 0;
        font-size: 35px;
        line-height: 33px;
        top: 32px;
      }

      p {
        font-size: 21px;
        line-height: 24px;
        text-align: center;
        left: 16px;
      }

      button {
        margin-top: 24px;
        width: 412px;
        height: 60px;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    .main-wrapper {
      width: 100%;
      background: #202020;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding: 32px 0px;
    }

    .text-container {
      padding: 0;
      align-items: center;

      h2 {
        margin: 24px 0 24px 0;
        font-size: 30px;
        line-height: 33px;
        width: 261px;
        height: 36px;
        top: 32px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        width: 328px;
        height: 49px;
        text-align: center;
        left: 16px;
      }

      button {
        margin-top: 24px;
        width: 312px;
        height: 50px;
      }
    }
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  width: 472px;
  height: 519px;
  background-color: #303030;
  border-radius: 10px;
  transform: translate(45%, 5%);

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 371px;
    height: 419px;
    position: absolute;
    transform: translate(23%, 10%);
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 371px;
    height: 419px;
    border-radius: 5px;
    position: absolute;
    transform: translate(20%, 15%);
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 271px;
    height: 299px;
    border-radius: 5px;
    position: absolute;
    transform: translate(20%, 15%);
  }
`;

export const Image = styled.img`
  position: absolute;
  width: 524px;
  height: 548px;
  border-radius: 10px;
  transform: translate(100px, -50px);
  align-self: flex-start;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 424px;
    height: 448px;
    border-radius: 5px;
    transform: translate(0, -6%);
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 424px;
    height: 448px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 58px;
    transform: translate(-6%, 0);
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 301px;
    height: 315px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 58px;
    transform: translate(-6%, 0);
  }
`;
