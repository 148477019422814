import React from 'react';
import { HeaderContainer, SeeAll, Title } from './styled';
import { t } from 'hooks/intl/useLang';

function TitleSection({ children, destination, seeOther = t('Lihat Semua'), withoutSeeAll = false }) {
  return (
    <HeaderContainer>
      <Title>{children}</Title>
      {!withoutSeeAll && <SeeAll href={destination}>{seeOther}</SeeAll>}
    </HeaderContainer>
  );
}

export default TitleSection;
