import styled from 'styled-components';
import { mq } from 'assets/styles/theme';

export const BookmarkContainer = styled.section`
  width: 100%;
  padding: 50px 0;
  background-color: #141414;

  display: ${(props) => (props.data ? 'flex' : 'none')};

  ${mq({
    padding: [null, null, '20px 0', '20px 0', '16px 0'],
  })}
`;
