import React from 'react';
import {
  Container,
  Image,
  Image2,
  CardContainer,
  Column,
  Grid,
  HeadLeftSection,
  Title,
  Paragraf,
} from './styled';

import iosImage from 'assets/png/PelatihanSaya/App Store.png';
import androidImage from 'assets/png/PelatihanSaya/PlayStore.png';
import Phone from 'assets/png/DownloadSertifikat/Phone.png';
import { Link } from 'react-router-dom';
import { t } from 'hooks/intl/useLang';

const NontonOfflineTab = () => {
  return (
    <Container>
      <CardContainer>
        <HeadLeftSection>App Only</HeadLeftSection>
        <Grid>
          <Column>
            <Title>{t('Download & Nonton Offline Kapanpun Dimanapun')}</Title>
            <hr />
            <Paragraf>{t('Nikmati nonton pelatihan tanpa internet di Smartphone kamu!')}</Paragraf>
            <div className="contain-image">
              <Link to="#">
                <Image image={iosImage} />
              </Link>
              <Link to="#">
                <Image2 image={androidImage} />
              </Link>
            </div>
          </Column>
          <div className="logo-phone">
            <img src={Phone} alt="phone" />
          </div>
        </Grid>
      </CardContainer>
    </Container>
  );
};

export default NontonOfflineTab;
