import styled from 'styled-components';

export const Section = styled.section`
  background: ${({ trainerPage }) => (trainerPage ? '#141414' : '#202020')};
  padding: 0;
  padding-bottom: 50px;

  h2 {
    margin: 0;
    padding-bottom: 30px;
  }

  .button-slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slider {
    position: relative;
    margin-bottom: 8px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.md} {
    padding: 20px 0;
    padding-bottom: 50px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 0px 16px;
    padding-bottom: 40px;

    h2 {
      padding-bottom: 16px;
    }
  }
`;

export const Heading = styled.div`
  margin-bottom: 45px;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin-bottom: 24px;
  }
`;

export const SubTitle = styled.h3`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  margin: 0;

  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 14px;
  }
`;

export const SliderPagination = styled.div`
  position: relative;
  text-align: center;
  transition: all 300ms ease;
  transform: translate3d(0, 0, 0);
  z-index: 1;
  margin-top: 20px;

  span {
    margin: 0 5px;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: #c4c4c4;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    width: 24px;
    height: 6px;
    border-radius: 20px;
    background-color: #ffffff;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    margin-top: 0px;

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
    }

    .swiper-pagination-bullet-active {
      width: 20px;
    }
  }
`;
