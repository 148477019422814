import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Footer from 'components/organisms/Footer';
import { checkMobile } from 'utils/checkMobile';
import { useLocation } from 'react-router-dom';
import { CREATE_PAGE_VIEWS } from 'mutation/analytics';

import Headers from 'components/organisms/Headers';
import HeaderMobile from 'components/organisms/HeaderMobile';

import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_HEADER } from 'queries/user';

import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import { useDispatch } from 'react-redux';
import { authOperations } from 'state/modules/auth';
import { subscribeOperations } from 'state/modules/payment';
import NewHeaders from '../../organisms/NewHeaders';

const PageLayout = ({
  seo,
  children,
  blank,
  noHeader,
  noLine,
  noFooter,
  focusBarMobile,
  setFocusBarMobile,
  logoB2b,
  disableLogo,
  white,
  newHeader,
  ...rest
}) => {
  const { title, description } = seo;
  const [bgFooter, setbgFooter] = useState(false);
  const [noLineFooter, setNoLine] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const { data: userData, loading: loadingUserData } = useQuery(GET_USER_HEADER, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const [createPageViews] = useMutation(CREATE_PAGE_VIEWS);

  useEffect(() => {
    if (getValue(authData, 'data.accessToken', '') && getValue(authData, 'data.slug')) {
      setbgFooter(true);
    }
    if (noLine) {
      setNoLine(true);
    } else {
      setNoLine(false);
    }

    // createPageViews({
    //   variables: {
    //     pageName: location.pathname,
    //   },
    // });
  }, []);

  useEffect(() => {
    if (userData?.ProfileUser) {
      window.Moengage.add_unique_user_id(userData?.ProfileUser?.User?.id);
      window.Moengage.add_user_name(userData?.ProfileUser?.fullname);
      window.Moengage.add_email(userData?.ProfileUser?.User?.email);
      window.Moengage.add_mobile(userData?.ProfileUser?.User?.phone_number);
    }
  }, [userData]);

  async function checkResponsive() {
    if (checkMobile() && getValue(authData, 'data.accessToken')) {
      await dispatch(authOperations.removeAuth());
      await dispatch(subscribeOperations.removeSubscribe());
    }
  }

  useEffect(() => {
    checkResponsive();
    window.addEventListener('scroll', (event) => {
      setIsScrolled(window.pageYOffset > 100);
    });
  });

  const renderHeader = () => {
    if (noHeader) {
      return null;
    }

    if (blank) {
      return newHeader ? <NewHeaders blank {...rest} /> : <Headers blank {...rest} />;
    }
    return (
      <>
        <HeaderMobile
          userData={userData?.ProfileUser}
          focusBarMobile={focusBarMobile}
          setFocusBarMobile={setFocusBarMobile}
          showModalDownloadApp={!blank || !noHeader}
          {...rest}
        />
        {newHeader ? (
          <NewHeaders
            logoB2b={logoB2b}
            userData={userData?.ProfileUser}
            loadingUserData={loadingUserData}
            isScrolled={isScrolled}
            {...rest}
          />
        ) : (
          <Headers
            logoB2b={logoB2b}
            userData={userData?.ProfileUser}
            loadingUserData={loadingUserData}
            {...rest}
          />
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Terampil${title ? ` \u2022 ${title}` : ''}`}</title>
        <meta name="description" description={description} />
        <meta name="description" content={description} data-react-helmet="true" />
        <meta property="al:ios:url" content="applinks://terampil.page.link" />
        <meta property="al:ios:app_store_id" content="1559671480" />
        <meta property="al:ios:app_name" content="Terampil" />
        <meta property="al:android:url" content="applinks://terampil.page.link" />
        <meta property="al:android:app_name" content="Terampil" />
        <meta property="al:android:package" content="com.terampil.app" />
      </Helmet>
      {renderHeader()}

      {children}

      {!blank && !noFooter && <Footer bgFooter={bgFooter} noLine={noLineFooter} white={white} />}
    </React.Fragment>
  );
};

PageLayout.propTypes = {
  seo: PropTypes.object,
};

export default PageLayout;
