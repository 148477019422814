import styled from 'styled-components';
import { mq } from 'assets/styles/theme';

export const Container = styled.section`
  display: flex;
  width: 100%;
  height: 198px;
  background: linear-gradient(90deg, #0cc375 0%, #187336 100%);
  ${mq({
    height: [null, null, null, null, '144px'],
  })}

  .side {
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: center;
    ${mq({
      alignItems: [null, null, null, null, 'start'],
      flex: [null, null, null, null, '1'],
    })}
  }

  .img-small {
    width: 44px;
    height: 66px;
    margin-top: -72px;
    margin-left: -32px;

    ${mq({
      width: [null, null, null, null, '12px'],
      height: [null, null, null, null, '18px'],
      marginTop: [null, null, null, null, '8px'],
      marginLeft: [null, null, null, null, '0'],
    })}
  }

  .img-large {
    margin-left: -44px;

    ${mq({
      width: [null, null, null, null, '26px'],
      height: [null, null, null, null, '38px'],
      marginTop: [null, null, null, null, '6px'],
      marginLeft: [null, null, null, null, '0'],
    })}
  }

  .inside-content {
    display: flex;
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 7;
  padding-top: 20px;
  padding-bottom: 20px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    flex: 8;
    justify-content: center;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      flex: 4;
    }
  }

  .button {
    flex: 2;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-left: -32px;
    margin-right: 32px;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      width: 110%;
      margin-left: -2%;
      font-size: 18px;
    }

    @media only screen and ${(props) => props.theme.breakpoints.xs} {
      width: 110%;
      margin-left: -2%;
      font-size: 18px;
    }

    @media only screen and ${(props) => props.theme.breakpoints.xxs} {
      /* margin-top: 10px; */
      margin-left: -2%;
    }
  }

  .title {
    margin: 16px 0 0;
    font-family: Archivo, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 33px;
    /* identical to box height */
    float: left;
    color: #000000;
    text-align: left;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      font-size: 14px;
      line-height: 18px;
      margin: 20px 0 0;
      padding: 0;
    }

    @media only screen and ${(props) => props.theme.breakpoints.xxs} {
      font-size: 12px;
      margin-bottom: -3px;
    }
  }

  .subtitle {
    margin: 24px 0;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    float: left;
    color: #000000;
    text-align: left;
    max-width: 85%;

    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      font-size: 10px;
      margin: 0;
    }
  }
`;
