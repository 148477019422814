import React from 'react';
import { Container, Title, Link } from './index.styled';
import { t } from 'hooks/intl/useLang';

function SocialMediaLink() {
  return (
    <Container className="social-media">
      <Title>{t('Mari Bergabung')}</Title>
      {/* hide for a moment */ <Link href="/business-page">For Enterprise</Link>}
      <Link href="https://prakerja.terampil.com">Prakerja</Link>
      <Link href="/jadi-trainer">{t('Menjadi Trainer')}</Link>
      <Link href="/mari-berkolaborasi">{t('Berkolaborasi')}</Link>
      <Title className="sosmed">{t('Sosial Media')}</Title>
      <Link href="https://www.instagram.com/terampil.official/" target="blank">
        Instagram
      </Link>
      <Link href="https://www.linkedin.com/company/terampil/" target="blank">
        Linkedin
      </Link>
      <Link href="https://www.facebook.com/terampil.official" target="blank">
        Facebook
      </Link>
    </Container>
  );
}

export default SocialMediaLink;
