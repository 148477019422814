import styled from 'styled-components';

export const Container = styled.section`
  padding: 40px 0 50px 50px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 40px 0 30px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 40px 30px 30px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 16px;
  }
`;

export const RealtedTraining = styled.section`
  ${(props) => {
    if (props.verifyCertificate) {
      return `
        margin: 0;
        padding: 0 100px 50px;
      `;
    } else {
      return `
        margin-top: 50px;  
      `;
    }
  }}
  h2 {
    margin-top: 30px;

    ${(props) => props.theme.font.primary.title};
    font-size: 20px;
    text-align: left;
    margin: 0;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    ${(props) => {
      if (props.verifyCertificate) {
        return `
        margin: 0;
        padding: 0 16px 24px;
      `;
      } else {
        return `
        margin-top: 50px;  
      `;
      }
    }}
  }
`;

export const ContainerCard = styled.div`
  display: grid;

  grid-template-columns: ${(props) =>
    props.verifyCertificate ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)'};
  grid-auto-rows: minmax(100px, auto);
  row-gap: 50px;
  column-gap: 20px;
  margin-top: 24px;

  .card {
    margin: 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    ${(props) => {
      if (props.verifyCertificate) {
        return `
          grid-auto-flow: column;
          -webkit-column-gap: 12px;
          column-gap: 12px;
          overflow: auto;
        `;
      }
    }}
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    grid-auto-flow: column;
    -webkit-column-gap: 12px;
    column-gap: 12px;
    overflow: auto;
  }
`;
