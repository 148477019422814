import React from 'react';
import { trackWindowScroll, LazyLoadImage } from 'react-lazy-load-image-component';

import { Container, Title, ContainerLogo } from './index.styled';

import AppStoreLogo from 'assets/png/PelatihanSaya/App Store.png';
import PlayStoreLogo from 'assets/png/PelatihanSaya/PlayStore.png';
import KominfoLogo from 'assets/png/PelatihanSaya/Kominfo.png';
import SectigoLogo from 'assets/png/PelatihanSaya/Sectigo.png';

function MobileLink({ scrollPosition }) {
  return (
    <Container className="mobile-container">
      <Title>Mobile</Title>
      <ContainerLogo>
        <a href="https://apps.apple.com/id/app/terampil/id1559671480" target="blank">
          <LazyLoadImage
            effect="blur"
            className="app-store"
            scrollPosition={scrollPosition}
            src={AppStoreLogo}
            alt="apple-store"
          />
        </a>
      </ContainerLogo>
      <ContainerLogo>
        <a target="blank" href="https://play.google.com/store/apps/details?id=com.terampil.app">
          <LazyLoadImage
            effect="blur"
            className="playstore"
            scrollPosition={scrollPosition}
            src={PlayStoreLogo}
            alt="google-playstore"
          />
        </a>
      </ContainerLogo>
      <div className="flex">
        <a href="https://pse.kominfo.go.id/sistem/4227" target="blank">
          <LazyLoadImage
            effect="blur"
            className="logo app-store"
            scrollPosition={scrollPosition}
            src={KominfoLogo}
            alt="kominfo-logo"
          />
        </a>
        <span>
          <LazyLoadImage
            effect="blur"
            className="app-store logo-none"
            scrollPosition={scrollPosition}
            src={SectigoLogo}
            alt="sectigo-logo"
          />
        </span>
      </div>
    </Container>
  );
}

export default trackWindowScroll(MobileLink);
