import styled from 'styled-components';

export const PembayaranItemStyled = styled.div`
  border: ${(props) => (props.selected ? '2px solid #00DEBF' : '1px solid #373737')};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 22px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    border-radius: 0;
    border: 0 solid #373737;
    border-bottom: 1px solid #373737;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 20px 0;
    height: fit-content;
  }
`;

export const RadioIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 0;
    width: ${(props) => (props.requrring ? '16px' : '24px')};
    height: ${(props) => (props.requrring ? '16px' : '24px')};
  }
`;

export const Logo = styled.img`
  height: 35px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    width: 45px;
    height: auto;
    margin-right: 12px;
    margin-top: ${(props) => (props.dompet ? '2px' : '0')};
  }
`;

export const DescriptionItem = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 16px 0 0;

  color: ${(props) => props.theme.color.white};
  display: ${(props) => (props.mobile ? 'none' : 'block')};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 0;
    display: ${(props) => (props.mobile ? 'block' : 'none')};
  }
`;

export const ItemPembayaran = styled.div`
  align-items: flex-start;
  text-align: left;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: flex;
    flex-direction: row;
    align-items: ${(props) => (props.dompet ? 'flex-start' : 'center')};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RecurringContainer = styled.div`
  display: ${(props) => (props.mobile ? 'none' : 'flex')};
  align-items: center;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: ${(props) => (props.mobile ? 'flex' : 'none')};
    margin-top: 12px;
  }
`;

export const RecurringText = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 0 8px 0 32px;
  text-align: left;
  border-bottom: 1px solid ${(props) => props.theme.color.white};

  color: ${(props) => props.theme.color.white};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    margin: 0 8px 0 0;
  }
`;

export const DescriptionMobile = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin: 4px 0 0;
  text-align: left;
  display: none;

  color: #c4c4c4;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
  }
`;

export const TextItemContainer = styled.div``;

export const RadioCheck = styled.div`
  margin: 0;
  width: ${(props) => (props.selected ? '20px' : '24px')};
  height: ${(props) => (props.selected ? '20px' : '24px')};
  border: ${(props) => (props.selected ? '4px solid #00debf' : '1px solid #c4c4c4')};
  border-radius: 50%;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2px; */
  & > div {
    padding: 0;
    margin: 0;
    background-color: #fdfdfd;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
`;

export const RadioCheckMobile = styled.img`
  margin-right: 16px;
`;
