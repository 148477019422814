import PopupModal from 'components/atoms/Modal';
import React from 'react';

import { Title } from '../ResiPembelianModal/styled';
import { Container, Description, ButtonContainer } from './styled';

import { ReactComponent as Icon1 } from 'assets/svg/batalLangganan/icon1.svg';
import { ReactComponent as Icon2 } from 'assets/svg/batalLangganan/icon2.svg';
import { ReactComponent as Icon3 } from 'assets/svg/batalLangganan/icon3.svg';
import { ReactComponent as Icon4 } from 'assets/svg/batalLangganan/icon4.svg';
import { ReactComponent as Icon5 } from 'assets/svg/batalLangganan/icon5.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { UNSUBSCRIPTION } from 'mutation/subcribe';
import { getValue, setContext } from 'utils';
import { GET_USER_DETAIL } from 'queries/user';
import { checkMobile, checkTablet } from 'utils/checkMobile';
import Button from 'components/atoms/Button';
import { t } from 'hooks/intl/useLang';

const BatalLanggananModal = ({
  setShowCancelModal,
  setShowSuccessModal,
  showCancelModal,
  ...rest
}) => {
  const authData = useSelector((state) => state.Auth);
  const [unsubs] = useMutation(UNSUBSCRIPTION, {
    refetchQueries: [
      {
        query: GET_USER_DETAIL,
        context: setContext(authData),
        variables: {
          slug: getValue(authData, 'data.slug'),
        },
      },
    ],
    onCompleted: () => {
      setShowCancelModal(false);
      setShowSuccessModal(true);
    },
    onError: (err) => {
      console.error(err.message);
    },
  });

  const onCLickUnsubs = () => {
    unsubs({
      context: setContext(authData),
    });
  };

  const renderModal = () => {
    if (checkMobile() || checkTablet()) {
      return (
        <Container isOpen={showCancelModal}>
          <CloseIcon onClick={() => setShowCancelModal(false)} />
          {renderContent()}
        </Container>
      );
    } else {
      return (
        <PopupModal className="w-700" dark {...rest}>
          {renderContent()}
        </PopupModal>
      );
    }
  };

  const renderContent = () => {
    return (
      <>
        <Title className="title-mobile">{t('Kamu Yakin Mau Batal Berlangganan?')}</Title>
        <Description className="mt-5">
          <p>
            {t('Jika kamu membatalkan langgananmu hari ini, akun kamu akan downgrade menjadi free. Kamu akan kehilangan hal-hal berikut:')}
          </p>
        </Description>
        <Description className="my-3">
          <Icon1 /> <p>{t('Kebebasan dalam mengakses video')}</p>
        </Description>
        <Description className="my-3">
          <Icon2 /> <p>{t('Kesempatan untuk meningkatkan skill')}</p>
        </Description>
        <Description className="my-3">
          <Icon3 /> <p>{t('Mengerjakan kuis dan tes akhir')}</p>
        </Description>
        <Description className="my-3">
          <Icon4 /> <p>{t('Diskusi materi dengan Trainer dan pengguna lain')}</p>
        </Description>
        <Description className="my-3">
          <Icon5 /> <p>{t('Download sertifikat')}</p>
        </Description>
        <ButtonContainer>
          <Button className="accept" onClick={() => onCLickUnsubs()}>
            {t('Ya')}
          </Button>
          <Button className="cancel" onClick={() => setShowCancelModal(false)}>
            {t('Tidak')}
          </Button>
        </ButtonContainer>
      </>
    );
  };

  return renderModal();
};

export default BatalLanggananModal;
