import React from 'react';
import { Container, Button } from './styled';
import { ReactComponent as Star } from 'assets/svg/trainingDetails/star.svg';
import { t } from 'hooks/intl/useLang';

const FilterRating = (props) => {
  const rating = [5, 4, 3, 2, 1];
  return (
    <Container>
      <Button selected={props.selected === 10} onClick={() => props.setSelected(10)} all>
        {t('Semua')}
      </Button>

      {rating.map((item, i) => {
        return (
          <Button
            key={i}
            selected={props.selected === item}
            onClick={() => props.setSelected(item)}
          >
            {' '}
            <Star />
            {item}
          </Button>
        );
      })}
    </Container>
  );
};

export default FilterRating;
