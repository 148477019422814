import { gql } from '@apollo/client';

export const DELETE_SESSIONS = gql`
  mutation DeleteSessions($sessionids: [Int]) {
    DeleteSessions(input: { sessionIds: $sessionids }) {
      status
      statusText
    }
  }
`;
