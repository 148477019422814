/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PopupModal from 'components/atoms/Modal';
import { TitleModal } from '../PhoneModal/PhoneModal.styled';
import { CodeContainer, Description, InputSingle, TextBottom } from './OTPModal.styled';
import { deviceVar, fullnameVar, phoneVar, userIdVar, uuidVar } from 'utils/makeVarAuth';
import { useMutation } from '@apollo/client';
import { LOGIN_BY_EMAIL_PHONE } from 'mutation/login';
import { CHECK_OTP, REQUEST_OTP } from 'mutation/otp';
import FormError from '../../../atoms/FormError';
import { COMPLETE_PROFILE } from 'mutation/profile';
import { GET_USER_DETAIL } from 'queries/user';
import { getValue, setContext } from 'utils';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { SIGNUP_PHONE } from 'mutation/register';
import { t } from 'hooks/intl/useLang';

function OTPModal({
  setSignUpByPhone,
  setRegisterData,
  onComplete,
  lengkapiProfile = false,
  dataLengkapiProfile = {},
  selected,
  setCheckLogin,
  isRegister = false,
  setCheckRegisterEmail,
  setOpenModalOTP,
  setIskip,
  referralCheck,
  isReferral,
  registerByAds,
  onSuccessOTP = () => {},
  ...rest
}) {
  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);
  const input6 = useRef(null);

  const [secondsText, setSecondsText] = useState('00:00');
  const [error, setError] = useState(null);

  const [tick, setTick] = useState();
  const [startTime, setStartTime] = useState(Date.now());
  const [otp] = useState(Array(6));
  const history = useHistory();
  const authData = useSelector((state) => state.Auth);
  let timeoutId = null;

  const [otpLogin] = useMutation(LOGIN_BY_EMAIL_PHONE, {
    onCompleted({ DoSignInByEmailOrPhone }) {
      if (DoSignInByEmailOrPhone) {
        userIdVar(DoSignInByEmailOrPhone.id);
        setIskip(DoSignInByEmailOrPhone?.Profile?.is_skip);
        setRegisterData(DoSignInByEmailOrPhone);
        setSignUpByPhone(true);

        setCheckLogin(2);

        // onComplete(DoSignInByEmailOrPhone?.Profile?.is_skip);
      }
    },
    onError(errors) {
      if (errors.message === 'Wrong OTP Code.') {
        setError(t('Code OTP Tidak Valid'));
      } else {
        setError(errors.message);
      }
      console.error(errors.message);
    },
  });

  const [completeProfile, { error: errorCompleteProfile }] = useMutation(COMPLETE_PROFILE, {
    refetchQueries: [
      {
        query: GET_USER_DETAIL,
        context: setContext(authData),
        variables: {
          slug: getValue(authData, 'data.slug'),
        },
      },
    ],
    onCompleted({ CompletingProfile }) {
      if (selected !== 'trainings' && selected) {
        history.push(`/pembayaran/${selected ?? ''}`);
      } else if (selected === 'trainings') {
        history.push(`/trainings`);
      }
      setOpenModalOTP(false);
    },
    onError(errors) {
      if (errors.message === 'Wrong OTP Code.') {
        setError('Code OTP TIdak Valid');
      } else {
        setError(errors.message);
      }
    },
  });

  const [resendOtp, { loading: otpLoading }] = useMutation(REQUEST_OTP, {
    onCompleted({ SendOTP }) {
      if (SendOTP) {
        setError(null);
        setStartTime(new Date().getTime());
        setTick(90000);
      }
    },
    onError(errors) {
      setError(errors.message);
    },
  });

  const [signUpPhone] = useMutation(SIGNUP_PHONE, {
    onCompleted({ DoSignUpByPhone }) {
      setRegisterData(DoSignUpByPhone);
      userIdVar(DoSignUpByPhone.id);
      setSignUpByPhone(true);
      setCheckRegisterEmail(1);
      window.Moengage.track_event('Register Success', { methode: 'Phone' });
    },
  });

  const [checkOTP] = useMutation(CHECK_OTP, {
    onCompleted: () => {
      onSuccessOTP();
      // completeProfile({
      //   context: setContext(authData),
      //   variables: {
      //     input: {
      //       fullname: dataLengkapiProfile.fullname,
      //       email: dataLengkapiProfile.email,
      //       phoneNumber: dataLengkapiProfile.phoneNumber,
      //     },
      //   },
      // });
    },
    onError: (err) => {
      setError(err.message);
    },
  });

  useEffect(() => {
    setTimeout(() => {
      let now = Date.now();
      setTick(91000 - (now - startTime));
      let minute = Math.floor((tick % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((tick % (1000 * 60)) / 1000);
      if (isNaN(tick)) {
        setSecondsText('01:30');
      } else {
        if (tick <= 0) {
          setSecondsText(t('Kirim Ulang Kode'));
        } else {
          setSecondsText(
            `${minute < 10 ? '0' + minute.toString() : minute.toString()}:${
              second < 10 ? '0' + second.toString() : second.toString()
            }`,
          );
        }
      }
    }, 100);
  }, [tick]);

  function resendCode() {
    if (tick <= 0) {
      if (lengkapiProfile) {
        resendOtp({
          variables: {
            input: {
              phoneNumber: dataLengkapiProfile.phoneNumber,
            },
          },
        });
      } else {
        resendOtp({
          variables: {
            input: {
              phoneNumber: phoneVar(),
            },
          },
        });
      }
    }
  }

  function submitOTP() {
    const joinedOtp = otp.join('');
    if (joinedOtp.length === 6) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (lengkapiProfile) {
          checkOTP({
            variables: {
              input: {
                phoneNumber: dataLengkapiProfile.phoneNumber,
                otp: joinedOtp,
              },
            },
          });
        } else if (isRegister) {
          signUpPhone({
            variables: {
              input: {
                phoneNumber: phoneVar(),
                fullname: fullnameVar(),
                otp: joinedOtp,
                uniqueId: uuidVar(),
                accessType: deviceVar().accessType,
                deviceName: deviceVar().deviceName,
                deviceVersion: deviceVar().deviceVersion,
                osName: deviceVar().osName,
                osVersion: deviceVar().osVersion,
                registerByAds: registerByAds !== null,
                slugCampaign: registerByAds !== null ? registerByAds : undefined,
              },
            },
          });
        } else {
          otpLogin({
            variables: {
              input: {
                type: 'phone',
                phoneNumber: phoneVar(),
                otp: joinedOtp,
                uniqueId: uuidVar(),
                accessType: deviceVar().accessType,
                deviceName: deviceVar().deviceName,
                deviceVersion: deviceVar().deviceVersion,
                osName: deviceVar().osName,
                osVersion: deviceVar().osVersion,
              },
            },
          });
        }
      }, 500);
    }
  }

  function handleChange(args) {
    switch (args) {
      case 1:
        input1.current.focus();
        break;
      case 2:
        input2.current.focus();
        break;
      case 3:
        input3.current.focus();
        break;
      case 4:
        input4.current.focus();
        break;
      case 5:
        input5.current.focus();
        break;
      case 6:
        input6.current.focus();
        break;
      default:
        input1.current.focus();
    }
  }

  return (
    <PopupModal lengkapiProfile={lengkapiProfile} {...rest}>
      <TitleModal lengkapiProfile={lengkapiProfile}>{t('Masukkan Kode Verifikasi')}</TitleModal>
      <Description lengkapiProfile={lengkapiProfile}>
        {t('Masukkan kode yang dikirim melalui SMS ke nomor handphone kamu')} (+62){' '}
        {lengkapiProfile ? dataLengkapiProfile.phoneNumber : phoneVar()}.
      </Description>
      <CodeContainer lengkapiProfile={lengkapiProfile}>
        <InputSingle
          lengkapiProfile={lengkapiProfile}
          danger={error}
          onKeyDown={(val) => {
            if (val.keyCode === 8 || val.keyCode === 46) {
              handleChange(1);
            } else {
              val.target.value !== '' ? handleChange(2) : handleChange(1);
            }
          }}
          onChange={(val) => {
            otp[0] = val.target.value;
            submitOTP();
          }}
          register={input1}
          maxLength="1"
        />
        <span>-</span>
        <InputSingle
          lengkapiProfile={lengkapiProfile}
          danger={error}
          onKeyDown={(val) => {
            if (val.keyCode === 8 || val.keyCode === 46) {
              val.target.value !== '' ? handleChange(2) : handleChange(1);
            } else {
              val.target.value !== '' ? handleChange(3) : handleChange(2);
            }
          }}
          onChange={(val) => {
            otp[1] = val.target.value;
            submitOTP();
          }}
          register={input2}
          maxLength="1"
        />
        <span>-</span>
        <InputSingle
          lengkapiProfile={lengkapiProfile}
          danger={error}
          onKeyDown={(val) => {
            if (val.keyCode === 8 || val.keyCode === 46) {
              val.target.value !== '' ? handleChange(3) : handleChange(2);
            } else {
              val.target.value !== '' ? handleChange(4) : handleChange(3);
            }
          }}
          onChange={(val) => {
            otp[2] = val.target.value;
            submitOTP();
          }}
          register={input3}
          maxLength="1"
        />
        <span>-</span>
        <InputSingle
          lengkapiProfile={lengkapiProfile}
          danger={error}
          onKeyDown={(val) => {
            if (val.keyCode === 8 || val.keyCode === 46) {
              val.target.value !== '' ? handleChange(4) : handleChange(3);
            } else {
              val.target.value !== '' ? handleChange(5) : handleChange(4);
            }
          }}
          onChange={(val) => {
            otp[3] = val.target.value;
            submitOTP();
          }}
          register={input4}
          maxLength="1"
        />
        <span>-</span>
        <InputSingle
          lengkapiProfile={lengkapiProfile}
          danger={error}
          onKeyDown={(val) => {
            if (val.keyCode === 8 || val.keyCode === 46) {
              val.target.value !== '' ? handleChange(5) : handleChange(4);
            } else {
              val.target.value !== '' ? handleChange(6) : handleChange(5);
            }
          }}
          onChange={(val) => {
            otp[4] = val.target.value;
            submitOTP();
          }}
          register={input5}
          maxLength="1"
        />
        <span>-</span>
        <InputSingle
          lengkapiProfile={lengkapiProfile}
          danger={error}
          onKeyDown={(val) => {
            if (val.keyCode === 8 || val.keyCode === 46) {
              val.target.value !== '' ? handleChange(6) : handleChange(5);
            } else {
              val.target.value !== '' ? handleChange(6) : handleChange(6);
            }
          }}
          onChange={(val) => {
            otp[5] = val.target.value;
            submitOTP();
          }}
          register={input6}
          maxLength="1"
        />
      </CodeContainer>
      {error && <FormError>{error}</FormError>}
      {otpLoading ? (
        <TextBottom>Loading...</TextBottom>
      ) : (
        <TextBottom onClick={() => resendCode()}>{secondsText}</TextBottom>
      )}
    </PopupModal>
  );
}

export default OTPModal;
