import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px 16px;

  margin-bottom: 16px;

  border-radius: 5px;

  display: flex;
  align-items: center;

  transition: background 0.1s ease;

  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;

  background: #373737;

  .list {
    width: 19px;
    height: 19px;

    border-radius: 100%;

    border: solid 1px #626262;
  }

  .list-selected {
    width: 9px;
    height: 9px;
    flex-shrink: 1;
    border-radius: 100%;

    border: 3px solid ${({ theme }) => theme.color.primary};

    padding: 3px;
    background: #fdfdfd;
    background-clip: content-box;
  }

  &.selected {
    border: 2px solid ${({ theme }) => theme.color.primary};

    background: #266960;
    padding: 6px 14px;
  }

  .option {
    padding-left: 16px;
  }

  &:hover {
    background: #266960;

    .list {
      border: solid 1px #fdfdfd;
    }

    .list-selected {
      border: 3px solid ${({ theme }) => theme.color.primary};
    }
  }
`;
