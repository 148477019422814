import React from 'react';
import { Section, SubTitle, Content, Grid } from './styled';
import Tittle from 'components/atoms/Tittle';
import MaxContainer from 'components/atoms/MaxContainer';

const CollaborationPoint = ({ items }) => {
  return (
    <Section>
      <MaxContainer>
        <Tittle>{items.title}</Tittle>
        <Grid>
          <div>
            <SubTitle>{items.subTitle1}</SubTitle>
            <Content>{items.content1}</Content>
          </div>
          <img src={items.image1} alt="step-1-trainer" />
        </Grid>
        <Grid className="reverse">
          <img src={items.image2} alt="step-2-trainer" />
          <div>
            <SubTitle>{items.subTitle2}</SubTitle>
            <Content>{items.content2}</Content>
          </div>
        </Grid>
        <Grid>
          <div>
            <SubTitle>{items.subTitle3}</SubTitle>
            <Content>{items.content3}</Content>
          </div>
          <img src={items.image3} alt="step-3-trainer" />
        </Grid>
        <Grid>
          <img src={items.image4} alt="step-4-trainer" />
          <div>
            <SubTitle>{items.subTitle4}</SubTitle>
            <Content>{items.content4}</Content>
          </div>
        </Grid>
      </MaxContainer>
    </Section>
  );
};

export default CollaborationPoint;
