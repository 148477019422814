import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FETCH_VIDEOADS } from 'queries/adsVideos';
import { useQuery } from '@apollo/client';
import { Container } from './styled';
import MaxContainer from 'components/atoms/MaxContainer';
import { LoaderDualRing } from 'components/atoms/Loading';
import Player from 'components/etc/Player';

function VideoAdds({ type, campaign, cover = '', linkSafari = '', link = '' }) {
  const [show, setShow] = useState(false);

  const location = useLocation();
  const imageCover = campaign
    ? cover
    : 'https://d2jr0bw5daaf2g.cloudfront.net/assets/images/landing_page_video2.jpg';

  const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const { loading } = useQuery(FETCH_VIDEOADS, {
    variables: {
      input: {
        is_active: true,
        type: type,
        limit: 1,
      },
    },
  });

  useEffect(() => {
    setShow(true);
    // setUrl(
    //   is_safari
    //     ? 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/homepage/hls/master.m3u8'
    //     : 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/landing-new/landingpage/dash/index.mpd',
    // );

    return () => {
      setShow(false);
    };
  }, [location]);

  if (loading)
    return (
      <Container>
        {' '}
        <LoaderDualRing />{' '}
      </Container>
    );
  return (
    <Container type={type}>
      <MaxContainer>
        <div className="video-adds">
          {show && (
            <Player
              className="full"
              manifestUrl={
                campaign
                  ? is_safari
                    ? linkSafari
                    : link
                  : is_safari
                  ? 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/landing-new/landingpage/hls/master.m3u8'
                  : 'https://terampil-public.s3-ap-southeast-1.amazonaws.com/assets/ad_videos/landing-new/landingpage/dash/index.mpd'
              }
              licenseServer={
                'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=5BC068'
              }
              // loop
              // autoPlay={true}
              poster={imageCover}
              showControls
              videoAds
              muted
            />
          )}
        </div>
      </MaxContainer>
    </Container>
  );
}

export default VideoAdds;
