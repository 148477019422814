import styled from 'styled-components';

export const CategoryFilterStyled = styled.div`
  width: 320px;
  box-sizing: border-box;
  height: fit-content;
  text-align: left;
  background-color: #202020;
  border-radius: 5px;
  margin-bottom: 24px;
  padding-bottom: 24px;
  cursor: pointer;

  a {
    text-decoration: none;
  }

  @media only screen and ${(props) => props.theme.breakpoints.md} {
    width: 220px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    background-color: transparent;
    width: 100%;
    padding-bottom: 0;
  }
`;

export const Title = styled.h2`
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  margin: 0 24px 24px;
  padding: 32px 0 12px;
  border-bottom: 1px solid #626262;
  box-sizing: border-box;

  color: #fdfdfd;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
    line-height: 17px;
    border-bottom: 0;
    margin: 0 24px 12px;
  }
`;

export const Item = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  background-color: ${(props) => (props.selected ? '#266960' : 'transparent')};
  cursor: default;
  padding: 16px 24px;
  margin: 0;

  color: #fdfdfd;

  :hover {
    background-color: ${(props) => (props.selected ? '#266960' : '#373737')};
  }
`;
