import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation ResetPassword($oldPassword: String, $newPassword: String) {
    SetPassword(input: { oldPassword: $oldPassword, newPassword: $newPassword }) {
      accessToken
    }
  }
`;

export const SET_NEW_PASSWORD = gql`
  mutation SetNewPassword($newPassword: String, $tokenId: String) {
    SubmitResetPassword(input: { newPassword: $newPassword, tokenId: $tokenId }) {
      updated_at
    }
  }
`;
