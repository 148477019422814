import ButtonStyled from 'components/atoms/Button/';
import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 40px 16px 20px;
    margin-top: 0;
  }
`;

export const Title = styled.h1`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  text-align: center;

  color: #fdfdfd;

  span {
    color: #00debf;
  }

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 24px;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 35px;
  /* identical to box height, or 219% */
  text-align: center;
  color: #ffffff;
  margin: 0;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
  }
`;

export const Button = styled(ButtonStyled)`
  width: 400px;
  height: 50px;
  margin: 60px 0 0 0;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    width: 250px;
  }
`;
