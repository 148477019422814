import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button';
export const Contianer = styled.section`
  background-color: #141414;
  padding: 33px 0px 80px 50px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 33px 0 50px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 33px 16px 50px 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 16px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div:first-child {
    margin-right: 20px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    div:first-child {
      margin-right: 8px;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    justify-content: center;
  }
`;

export const CardUpside = styled.div`
  background: #373737;
  border-radius: 10px;
  width: 100%;
  height: 171px;
  box-sizing: border-box;
  padding: 25px;
  position: relative;

  & > div {
    padding-left: 80px;
    margin-top: 14px;
  }

  svg {
    margin-right: 8px;
  }

  h3 {
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #fdfdfd;
    margin: 0;
    text-align: left;
    padding-left: 80px;
  }
  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
    text-align: left;
    color: #c4c4c4;
    margin: 0;
  }

  h2 {
    font-family: Archivo;
    font-size: 32px;
    font-weight: bold;
    line-height: 0.63;
    letter-spacing: normal;
    text-align: left;
    color: #00debf;
    margin: 18px 0 0 0;
  }

  img {
    position: absolute;
    top: 24px;
    left: 24px;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    & > div {
      padding-left: 12px;
      margin-top: 14px;
    }

    h3 {
      padding-left: 50px;
      margin-top: 10px;
    }

    img {
      width: 44px;
      height: 44px;
      top: 12px;
      left: 12px;
    }
    padding: 15px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    & > div {
      padding-left: 0px;
      margin-top: 14px;
    }

    h3 {
      padding-left: 30px;
      margin-top: 0px;
      font-size: 14px;
      line-height: 20px;
    }

    h2 {
      font-size: 20px;
      line-height: 20px;
      text-align: center;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      padding-left: 0;
    }

    img {
      width: 24px;
      height: 24px;
      top: 12px;
      left: 14px;
    }
    padding: 14px;
  }
`;

export const CardBottom = styled.div`
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: #202020;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;

  svg {
    display: inline-block;
  }

  h2 {
    font-family: Archivo;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fdfdfd;

    span {
      font-size: 24px;
      color: #00debf;
    }
  }

  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #fdfdfd;
    padding: 0 50px;
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    p {
      padding: 0 20px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0 12px;

    h2 {
      font-size: 14px;
      line-height: 20px;

      span {
        font-size: 14px;
      }
    }

    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;

export const Button = styled(ButtonStyled)`
  font-size: 16px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 160px;
    height: 41px;
    font-size: 14px;
    line-height: 17px;
  }
`;
