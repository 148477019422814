import React from 'react';
import { components } from 'react-select';
import { ContainerOption } from './styled';

import { ReactComponent as NotSelectedIcon } from 'assets/svg/empty-select.svg';
import { ReactComponent as SelectedIcon } from 'assets/svg/selected.svg';

const Option = (props) => {
  return (
    <ContainerOption>
      <components.Option {...props}>
        <div className="col">
          <div className="container-image">
            <img src={props.data.icon} alt="title" />
          </div>

          {props.data.label}
        </div>
        {!props.isSelected ? <NotSelectedIcon /> : <SelectedIcon />}
      </components.Option>
    </ContainerOption>
  );
};

export default Option;
