export const convertNumToStr = (num) => {
  return num
    .toString() // convert number to string
    .split('') // convert string to array of characters
    .map(Number) // parse characters as numbers
    .map((n) => (n || 10) + 64) // convert to char code, correcting for J
    .map((c) => String.fromCharCode(c)) // convert char codes to strings
    .join(''); // join values together
};

export function formatRp(angka) {
  if (typeof angka === 'undefined') {
    return 'Rp -';
  }

  let rupiah = '';
  let angkarev = angka.toString().split('').reverse().join('');
  for (let i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.';
  return (
    'Rp ' +
    rupiah
      .split('', rupiah.length - 1)
      .reverse()
      .join('')
  );
}
