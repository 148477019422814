import React from 'react';
import { Container, ContainerCard } from './styled';
import CardDownloadSertifkat from 'components/molecule/Cards/DownloadSertifikat/';
import EmptyState from 'components/molecule/EmptyState/';

// asset
import MaxContainer from 'components/atoms/MaxContainer';
import { useQuery } from '@apollo/client';
import { GET_USER_CERTIFICATES } from 'queries/user';
import { useSelector } from 'react-redux';
import { setContext } from 'utils';
import { t } from 'hooks/intl/useLang';

const emptyStateData = {
  title: t('Belum Ada Sertifikat'),
  paragraf: t('Silahkan ikuti dan selesaikan training kamu untuk mendapatkan sertifikat'),
  button: t('Lihat Training'),
  to: '/trainings',
};

const DownloadSertifikat = () => {
  const authData = useSelector((state) => state.Auth);
  const { data: dataCertificates } = useQuery(GET_USER_CERTIFICATES, {
    context: setContext(authData),
  });

  if (dataCertificates?.UserCertificates?.items.length <= 0) {
    return <EmptyState item={emptyStateData} />;
  }

  return (
    <Container>
      <MaxContainer>
        <ContainerCard>
          {dataCertificates?.UserCertificates?.items.map((item, i) => {
            return <CardDownloadSertifkat key={i} item={item} auth={authData} />;
          })}
        </ContainerCard>
      </MaxContainer>
    </Container>
  );
};

export default DownloadSertifikat;
