import { gql } from '@apollo/client';

export const GET_NOTIFICATION = gql`
  query Notification($orderBy: NotificationOrder, $orderType: OrderType, $limit: Int) {
    Notifications(input: { orderBy: $orderBy, orderType: $orderType, limit: $limit }) {
      total
      filtered
      status
      statusText
      items {
        id
        notification_title
        notification_text
        link
        deeplink
        is_read
        created_at
      }
    }
  }
`;

export const GET_NOTIFICATION_COUNT = gql`
  query NotificatioCount($slug: String) {
    ProfileUser(slug: $slug) {
      unread_notification
    }
  }
`;
