import styled from 'styled-components';

export const Title = styled.h6`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  margin: 0;

  text-align: left;

  padding-bottom: 16px;

  &.py-5,
  &.py-0 {
    padding: 0 100px;
  }

  &.pb-2 {
    padding-bottom: 24px;
  }

  &.pb-3 {
    padding-bottom: 32px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    font-size: 16px;
    padding-bottom: 12px;

    &.py-5 {
      padding: 0 16px;
    }

    &.py-0 {
      padding: 0;
    }

    &.pb-2,
    &.pb-3 {
      padding-bottom: 12px;
    }
  }
`;
