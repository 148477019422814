import React, { useState, useEffect } from 'react';
import { Container, ContainerInput, Button } from './styled';
import InputField from 'components/atoms/InputField/';
import FormError from 'components/atoms/FormError/';
import InputPhone from 'components/molecule/InputPhone/';
import { useForm } from 'react-hook-form';
import TextArea from 'components/atoms/TextArea';
import ModalSuccesRegister from 'components/molecule/Modal/ModalSuccesRegister/';
import { t } from 'hooks/intl/useLang';

const CollaborationFrom = ({ openModal, submitProps, dataModal, loadingForm }) => {
  const { register, handleSubmit, errors } = useForm();
  const [isOpenModalSucces, setIsOpenModalSucces] = useState(false);

  useEffect(() => {
    setIsOpenModalSucces(openModal);
  }, [openModal]);

  const onSubmit = async (data, e) => {
    const { fullname, corporate, email, phone_number, question } = data;
    let payloads = {
      code_area: '62',
      fullname,
      corporate,
      email,
      phone_number,
      question,
    };

    await submitProps(payloads);
    e.target.reset();
  };
  return (
    <Container>
      {isOpenModalSucces && (
        <ModalSuccesRegister
          kolaborasi={true}
          item={dataModal}
          setIsOpenModalSucces={setIsOpenModalSucces}
          isOpen={isOpenModalSucces}
          onRequestClose={() => setIsOpenModalSucces(false)}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContainerInput danger={errors.fullname}>
          <InputField
            dark={true}
            className="input"
            register={register({ required: true })}
            name="fullname"
            placeholder={t("Nama PIC")}
            label={t("Nama Lengkap")}
            danger={errors.fullname}
          />
          {errors.fullname && <FormError>{t('Nama Wajib Di Isi')}</FormError>}
        </ContainerInput>
        <ContainerInput danger={errors.corporate}>
          <InputField
            dark={true}
            className="input"
            register={register({ required: true })}
            name="corporate"
            placeholder={t("Saya mewakili (perusahaan)")}
            label={t("Saya mewakili")}
          />
          {errors.corporate && <FormError>{t('Nama Perusahaan Wajib di Isi')}</FormError>}
        </ContainerInput>
        <ContainerInput danger={errors.email}>
          <InputField
            dark={true}
            className="input"
            register={register({ required: true })}
            name="email"
            placeholder="Email"
            label="Email"
            type="email"
          />
          {errors.email && <FormError>{t('Email Wajib di Isi')}</FormError>}
        </ContainerInput>
        <ContainerInput danger={errors.phone_number}>
          <InputPhone
            lengkapiProfile
            dark={true}
            className="phone"
            type={'tel'}
            name="phone_number"
            label={t("Nomor HP")}
            register={register({
              required: true,
              pattern: /^8\d{8,11}$/,
            })}
          />
          {errors.phone_number && <FormError>{t('No HP tidak boleh kosong')}</FormError>}
        </ContainerInput>
        <ContainerInput>
          <TextArea
            register={register({ required: true })}
            name="question"
            placeholder={t("Hal yang ingin disampaikan")}
            label={t("Hal yang ingin disampaikan")}
            danger={errors.question}
          />
          {errors.question && <FormError>{t('Pesan Wajib di Isi')}</FormError>}
        </ContainerInput>
        <Button type="submit">{loadingForm ? 'loading...' : t('Kirim')}</Button>
      </form>
    </Container>
  );
};

export default CollaborationFrom;
