import { BannerLeftSection } from 'components/organisms';
import PageLayout from 'components/pagelayout/Template';
import React, { useState } from 'react';

import { Container, Title, Paragraf } from 'pages/RegisterPage/styled';
import {
  BoxForm,
  Head,
  Section,
} from '../../components/organisms/ResetPasswordRightSection/styled';
import { Button, ContainerInput, IconPassword } from '../../components/organisms/LoginPage/styled';
import InputField from '../../components/atoms/InputField';
import slashIcon from '../../assets/png/register/slashshow.png';
import showIcon from '../../assets/png/register/show.png';
import FormError from '../../components/atoms/FormError';
import BackgroundLeft from '../../assets/png/klinikPintar/img.png';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { useUrlQuery } from '../../hooks';
import { useMutation } from '@apollo/client';
import { CREATE_PASSWORD } from '../../mutation/user';
import { useDispatch, useSelector } from 'react-redux';
import PopupBerhasil from '../../components/molecule/Modal/PopupBerhail';

function B2BPassword({ regular = false }) {
  const seo = {
    title: 'Atur Kata Sandi',
    description: 'Atur Kata Sandi',
    canonical: null,
  };

  const [showModal, setShowModal] = useState(false);

  const [visible, setVisibility] = useState(false);
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const history = useHistory();
  const { register, errors: errorsForm, handleSubmit } = useForm();

  const queryUrl = useUrlQuery();
  const token = queryUrl.get('token');
  const roomId = queryUrl.get('r');

  if (!token && !roomId) {
    history.push('/');
  }
  const [createPassword] = useMutation(CREATE_PASSWORD, {
    onCompleted(data) {
      if (data?.CreatePassword) {
        history.push('/masuk');
      } else {
        setShowModal(true);
      }
    },
  });

  const updatePassword = (data) => {
    createPassword({
      variables: {
        input: {
          id: authData.data.userId,
          password: data.password,
        },
      },
    });
  };

  return (
    <PageLayout seo={seo} noHeader blank>
      <div style={{ background: regular ? undefined : 'white' }}>
        <Container>
          <BannerLeftSection
            style={{ width: '65%' }}
            withoutLogo={!regular}
            background={regular ? undefined : BackgroundLeft}
          />
          <Section>
            <Head>
              <h6>Tambah Kata Sandi</h6>
              <p>Silakan tambahkan kata sandi yang akan digunakan untuk masuk ke Terampil</p>
            </Head>
            <BoxForm style={{ width: '80%' }}>
              <Title style={{ color: '#101010', textAlign: 'center', width: '100%' }}>
                Atur Kata Sandi
              </Title>
              <form onSubmit={handleSubmit(updatePassword)}>
                <ContainerInput>
                  <InputField
                    type={visible ? 'text' : 'password'}
                    label="Kata Sandi"
                    placeholder="Kata Sandi"
                    danger={errorsForm.password}
                    register={register({
                      required: {
                        value: true,
                        message: 'Kata Sandi wajib diisi',
                      },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,30}$/,
                        message:
                          'Kata sandi minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, simbol dan angka.',
                      },
                    })}
                    name="password"
                  />
                  <IconPassword
                    src={visible ? slashIcon : showIcon}
                    onClick={() => setVisibility((props) => !props)}
                  />
                  {errorsForm.password && <FormError>{errorsForm.password.message}</FormError>}
                </ContainerInput>
                <Button className="button-submit">Simpan</Button>
              </form>
            </BoxForm>
            <PopupBerhasil
              failed
              onRequestClose={() => setShowModal(!showModal)}
              isOpen={showModal}
              title={'Terjadi Kesalahan'}
              text={'Terjadi kesalahan pada sistem, silahkan coba kembali'}
              onRight={() => setShowModal(!showModal)}
              textButtonRight={'OK'}
            />
          </Section>
        </Container>
      </div>
    </PageLayout>
  );
}

export default B2BPassword;
