import { gql } from '@apollo/client';

export const FETCH_VIDEOADS = gql`
  query FETCH_VIDEO($is_active: Boolean, $limit: Int, $type: AdVideoType) {
    AdVideos(input: { is_active: $is_active, limit: $limit, type: $type }) {
      status
      statusText
      items {
        id
        title
        url
        thumbnail
      }
    }
  }
`;
