import React, { useState } from 'react';
import Select from 'react-select';
import { Container, LabelInput } from './styled';

const CategoryDropwdown = ({
  blank = false,
  label,
  value,
  danger,
  dark = false,
  isSearchable = true,
  isClearable = true,
  classNamePrefix = 'react-select',
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(false);

  function setBlur(e) {
    if (e.target.value === '') {
      setIsFocus(false);
    } 
  }

  function setFocus() {
    setIsFocus(true);

    return;
  }

  return (
    <Container dark={dark} danger={danger}>
      {!blank && <LabelInput focus={value.length > 0 || isFocus}>{label}</LabelInput>}
      <Select
        onFocus={setFocus}
        onBlur={setBlur}
        {...rest}
        isClearable={isClearable}
        isSearchable={isSearchable}
        classNamePrefix={classNamePrefix}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: dark ? '#474747' : '#f1f0f0',
            primary25: dark ? '#474747' : '#f1f0f0',
          },
        })}
      />
    </Container>
  );
};

export default CategoryDropwdown;
