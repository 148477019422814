import { mq } from 'assets/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 8px;
  video[poster] {
    width: 100%;
    object-fit: cover;
  }

  img[img] {
    width: 100%;
    object-fit: cover;
  }

  video[icon] {
    text-align: center;
    width: 50%;
  }

  .link-training {
    text-decoration: none;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 4px; // kondisi jika digunakan di prakerja page
    ${(props) => {
      if (props.prakerja && props.grid) {
        return `
          @media only screen and ${props.theme.breakpoints.sm} {
            margin-bottom: 8px;
          }    
        `;
      }
    }}
    @media only screen and ${(props) => props.theme.breakpoints.sm} {
      margin-top: 7px;
    }
  }

  .tinyIcon {
    object-fit: contain;
    float: left;
    margin-right: 8px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    height: auto;
    padding: 0 16px;
  }

  span {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-right: 10px;
    color: #707070;
    float: left;
    text-align: left;

    ${mq({
      fontSize: [null, null, '10px'],
      marginRight: [null, null, '4px'],
    })}
  }

  .penilaian-trainer {
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 300px;
  border-radius: 20px;
  cursor: pointer;
  :focus {
    outline: none;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    border-radius: 4px;
    height: 250px;
  }
`;

export const Image = styled.div`
  display: grid;
  place-items: center;
  background-image: url('${({ src }) => src}');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  height: 250px;
  border-radius: 10px;
  cursor: pointer;
  :focus {
    outline: none;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    border-radius: 4px;
    height: 350px;
    background-position: top;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    border-radius: 4px;
    height: 250px;
  }
`;
export const VideoPlayer = styled.video`
  width: 100%;
  height: 300px;
  border-radius: 20px;
  cursor: pointer;
  :focus {
    outline: none;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    border-radius: 4px;
    height: 250px;
  }
`;

export const Title = styled.h6`
  width: 100%;
  height: 60px;
  font-family: Archivo;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin: 10px 0 16px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    height: 40px;
  }
`;

export const ProfileName = styled.p`
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(253, 253, 253, 0.7);
  margin: 0;
`;

export const PromoTraining = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  text-decoration: none;
  justify-content: space-between;
  font-family: 'Inter';
  font-style: normal;
  align-items: start;
  margin: 8px 0;

  .price-normal {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: line-through;
    color: #c4c4c4;
    margin: 0;
  }

  .price-discount {
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: #0dc274;
    margin: 0;
  }

  .percent-box {
    position: absolute;
    width: 67px;
    height: 27px;
    background: #f5a52f;
    border-radius: 5px;
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */
    color: #ffffff;
    right: 0;
    top: -35px;
  }
`;
