import React, { useEffect, useState } from 'react';
import PageLayout from '../../../components/pagelayout/Template';
import {
  AccentGreen,
  CardTrainingStyled,
  ImageResultMBTI,
  ItemList,
  JobRadio,
  ListDescription,
  ResultContainer,
  ResultPageMBTI,
  SectionContainer,
  TextResultMBTI,
  TitleResultMBTI,
  TitleSectionResult,
  TopSectionResult,
} from './styled';
import MaxContainer from '../../../components/atoms/MaxContainer';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_TRAININGS } from '../../../queries/training';
import { setContext } from '../../../utils';
import { useSelector } from 'react-redux';
import { ModuleContainer } from '../../../components/organisms/KlinikPintar/ModuleSection/styled';
import { ReactComponent as Underline } from 'assets/svg/TBM/underline.svg';
import { ReactComponent as Wave } from 'assets/svg/TBM/wave.svg';
import { ReactComponent as Dots } from 'assets/svg/TBM/dots.svg';
import { RESULT_TBM } from '../../../queries/TBM';

const ResultMBTI = () => {
  const seo = {
    title: 'Hasil Potensi Karir',
    description: 'Hasil Potensi Karir',
    canonical: null,
  };

  const [modules, setModules] = useState([]);
  const [selectedJob, setSelectedJob] = useState();

  const authData = useSelector((state) => state.Auth);

  const [getModule] = useLazyQuery(GET_TRAININGS, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    onCompleted: (data) => {
      setModules(data?.Trainings?.items);
    },
  });

  const { data: dataResult } = useQuery(RESULT_TBM, {
    context: setContext(authData),
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setSelectedJob({ data: data.MinatBakatResult.items.profession[0], index: 0 });
    },
  });

  useEffect(() => {
    if (selectedJob) {
      getModule({
        variables: {
          input: {
            popular: selectedJob?.data?.training_recommendation?.training_ids.length > 0,
            trainingIds: selectedJob?.data?.training_recommendation?.training_ids,
            limit: 4,
          },
        },
      });
    }
  }, [selectedJob]);

  return (
    <PageLayout seo={seo}>
      <ResultPageMBTI>
        <AccentGreen>
          <Wave style={{ position: 'absolute', right: 0, top: 160 }} />
          <Dots style={{ position: 'absolute', left: 45, bottom: 25 }} />
        </AccentGreen>
        <MaxContainer>
          <TopSectionResult>
            <ImageResultMBTI image={selectedJob?.data?.image} />
            <ResultContainer>
              <TitleResultMBTI>
                <Underline style={{ position: 'absolute', top: 36, zIndex: -10 }} />
                {'Karir yang cocok\nuntuk Kamu:'}
              </TitleResultMBTI>
              {dataResult?.MinatBakatResult?.items?.profession?.map((profession, index) => (
                <JobRadio
                  selected={selectedJob?.data?.id === profession.id && selectedJob?.index === index}
                  onClick={() => {
                    setSelectedJob({ data: profession, index: index });
                  }}
                >
                  {index + 1}. {profession.name}
                </JobRadio>
              ))}
            </ResultContainer>
          </TopSectionResult>
          <SectionContainer>
            <TitleSectionResult>Apa itu {selectedJob?.data?.name}?</TitleSectionResult>
            <TextResultMBTI>{selectedJob?.data?.description}</TextResultMBTI>
          </SectionContainer>
          <SectionContainer>
            <TitleSectionResult>Tentang Profil Peserta</TitleSectionResult>
            <TextResultMBTI>
              kamu tergolong dalam tipe{' '}
              {dataResult?.MinatBakatResult?.items?.result_profile?.riasec?.map((riasec) => (
                <strong>{riasec.type}, </strong>
              ))}{' '}
              dan <strong>{dataResult?.MinatBakatResult?.items?.result_profile?.mbti?.type}</strong>
            </TextResultMBTI>
            <ListDescription>
              {dataResult?.MinatBakatResult?.items?.result_profile?.riasec?.map((riasec) => (
                <ItemList>
                  <TextResultMBTI dangerouslySetInnerHTML={{ __html: riasec.description }} />
                </ItemList>
              ))}
              <ItemList>
                <TextResultMBTI
                  dangerouslySetInnerHTML={{
                    __html: dataResult?.MinatBakatResult?.items?.result_profile?.mbti?.description,
                  }}
                />
              </ItemList>
            </ListDescription>
          </SectionContainer>
          {modules.length > 0 && (
            <SectionContainer>
              {selectedJob?.data?.training_recommendation?.training_ids.length > 0 ? (
                <TitleSectionResult>
                  Training yang cocok untuk {selectedJob?.data?.name}
                </TitleSectionResult>
              ) : (
                <TitleSectionResult>Training Populer</TitleSectionResult>
              )}
              <ModuleContainer
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginBottom: 100,
                }}
              >
                {modules?.map((training, index) => (
                  <CardTrainingStyled white disabled training={training} key={index} />
                ))}
              </ModuleContainer>
            </SectionContainer>
          )}
        </MaxContainer>
      </ResultPageMBTI>
    </PageLayout>
  );
};

export default ResultMBTI;
