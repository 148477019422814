import React from 'react';
import { RecomendContainer } from './styled';

import MaxContainer from 'components/atoms/MaxContainer';
import TitleSection from 'components/molecule/TitleSectionHome';
import SliderTraining from 'components/molecule/SliderTraining';

import { useQuery } from '@apollo/client';
import { GET_RECOMMEND_TRAINING } from 'queries/training';
import { LoaderDualRing } from 'components/atoms/Loading';
import { useSelector } from 'react-redux';
import { setContext } from 'utils';
import { t } from 'hooks/intl/useLang';

function RecomendTraining({ assessment, style }) {
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const { data: recommendTrainings, loading } = useQuery(GET_RECOMMEND_TRAINING, {
    context: setContext(authData),
    variables: {
      input: {
        limit: 12,
        rating: 0,
        variableDISC: assessment ? assessment : null,
      },
    },
  });

  return (
    <RecomendContainer
      style={{ ...style }}
      data={recommendTrainings?.TrainingRecommendation?.items?.length > 0}
    >
      <MaxContainer>
        {recommendTrainings?.TrainingRecommendation?.items?.length > 0 && (
          <>
            <TitleSection destination="/trainings?category=recommend&sort=0">
              {t('Rekomendasi untuk Kamu')}
            </TitleSection>
            {loading && <LoaderDualRing />}
            {recommendTrainings?.TrainingRecommendation && (
              <SliderTraining
                trainings={recommendTrainings?.TrainingRecommendation?.items}
                id="recomend"
              />
            )}
          </>
        )}
      </MaxContainer>
    </RecomendContainer>
  );
}

export default RecomendTraining;
