import { gql } from '@apollo/client';

export const SUBMIT_TBM = gql`
  mutation SUBMIT_TBM($input: SubmitMinatBakatTest) {
    SubmitMinatBakatTest(input: $input) {
      user_id
      answer
      riasec_id
      mbti_id
      code
      id
    }
  }
`;

export const RETAKE_TBM = gql`
  mutation RETAKE_TBM {
    RetakeMinatBakatTest {
      status
      statusText
    }
  }
`;
