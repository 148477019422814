import React from 'react';
import { ContainerCardDescTBM, ImageContainer, TextDescCardTBM } from './styled';

const CardDescTBM = ({ text, image, ...rest }) => {
  return (
    <ContainerCardDescTBM {...rest}>
      <ImageContainer>{image}</ImageContainer>
      <TextDescCardTBM>{text}</TextDescCardTBM>
    </ContainerCardDescTBM>
  );
};

export default CardDescTBM;
