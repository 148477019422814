import { gql } from '@apollo/client';

export const GET_MINAT_BAKAT_TEST = gql`
  query GET_MINAT_BAKAT_TEST {
    MinatBakatTest {
      items {
        title
        mbti {
          first_state
          sec_state
          first_key
          sec_key
          code
          id
          progress
          is_active
        }
        riasec {
          state
          key
          id
          progress
        }
      }
    }
  }
`;

export const RESULT_TBM = gql`
  query ResultTBM {
    MinatBakatResult {
      items {
        profession {
          name
          description
          image
          training_recommendation {
            training_ids
          }
          id
        }
        result_profile {
          mbti {
            description
            code
            type
          }
          riasec {
            description
            code
            type
          }
        }
      }
    }
  }
`;
