import React from 'react';
import { Container, ImageCard, Title, Datefont, LabelCategory } from './styled';
import { Link } from 'react-router-dom';
import { formatDateLong } from 'utils/date';

const CardBlog = ({ item, index, slide }) => {
  return (
    <Container slide={slide}>
      <LabelCategory>{item.Category?.name}</LabelCategory>
      <Link to={`/blog/${item?.slug}`} target="_blank">
        <ImageCard url={item?.image_headline || `https://picsum.photos/id/${index + 20}/200/300`} />
        <Title>{item?.title}</Title>

        <Datefont>{formatDateLong(item?.publish_date)}</Datefont>
      </Link>
    </Container>
  );
};

export default CardBlog;
