import React from 'react';
import TemplateHeadline from 'components/molecule/TemplateHeadline';
import { t } from 'hooks/intl/useLang';

const headline = {
  image: `https://d2jr0bw5daaf2g.cloudfront.net/assets/jadi_trainer/banner1.png`,
  firstH1: t('Mari Bergabung Dalam'),
  secondH2: t('Gerakan Menuju Indonesia Terampil'),
  paragraf:
    t('Bagikan pengalaman dan keahlian Anda dalam video pelatihan online berkualitas untuk memajukan masyarakat Indonesia sesuai dengan kebutuhan dunia'),
  link: '/daftar-jadi-trainer',
  button: t('Daftar Jadi Trainer'),
};
const DaftarJadiTrainer = () => {
  return (
    <>
      <TemplateHeadline trainer items={headline} />
    </>
  );
};

export default DaftarJadiTrainer;
