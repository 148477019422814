import React, { useState } from 'react';
import { BackgroundContainer, Title, TextParagraf, Button, ContainerButton } from './styled';
import { ReactComponent as PeopleIcon } from 'assets/svg/undangteman/people.svg';
import { Link } from 'react-router-dom';
import { checkMobile } from 'utils/checkMobile';
import RedirectModalToMobile from 'components/molecule/Modal/RedirectToMobile';
import { t } from 'hooks/intl/useLang';

// const img =
//   'https://terampil-public.s3.ap-southeast-1.amazonaws.com/assets/undang-teman/Referal_blank+1.png';
const BannerSectionUndangTeman = ({ img, page }) => {
  const isMobile = checkMobile();
  const [openModal, setOpenModal] = useState(false);
  return (
    <BackgroundContainer page={page} img={img}>
      <Title page={page}>{t('Undang Teman Dapat Training Gratis')}</Title>
      <TextParagraf page={page}>
        {t('Ajak 1 teman kamu untuk beli salah satu paket, dan dapatkan 1 paket yang sama secara gratis!')}
      </TextParagraf>
      {page && (
        <ContainerButton>
          {isMobile ? (
            <div onClick={() => setOpenModal(true)}>
              <Button>{t('Ajak Temanmu Sekarang')}</Button>
            </div>
          ) : (
            <Link to="/registrasi?invite=1">
              <Button>{t('Ajak Temanmu Sekarang')}</Button>
            </Link>
          )}
        </ContainerButton>
      )}
      {openModal && (
        <RedirectModalToMobile isOpen={openModal} onRequestClose={() => setOpenModal(false)} />
      )}
    </BackgroundContainer>
  );
};

export default BannerSectionUndangTeman;
