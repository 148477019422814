import styled from 'styled-components';

export const Title = styled.h6`
  margin: 0;

  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;

  text-align: center;
`;

export const Product = styled.div`
  text-align: left;
  margin: 32px 0;
  font-family: Inter;

  display: flex;
  justify-content: space-between;

  p {
    font-weight: normal;
  }

  .primary {
    font-size: 16px;
    margin: 0;
  }

  .secondary {
    font-weight: normal;
    font-size: 14px;
    color: #c4c4c4;
    margin: 0;
    margin-top: 4px;
  }
`;

export const Detail = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  color: #c4c4c4;

  strong {
    color: #fdfdfd;
  }
`;

export const Order = styled.div`
  margin: 50px 0 8px 0;
  h6,
  p {
    text-align: left;
    margin: 0;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 16px 0;
  }

  .primary,
  .secondary {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  .highlight {
    font-size: 18px;
  }

  .primary {
    color: ${({ theme }) => theme.color.primary};
  }

  .secondary {
    color: #c4c4c4;
  }
`;
