import styled from 'styled-components';

export const Container = styled.section`
  padding: 80px 228px 140px;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 60px 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    background-color: #191919;
    padding: 30px 0;
    margin: 30px 0;
  }
`;

export const Content = styled.div`
  max-height: 100%;
  border-radius: 10px;
  background-color: #191919;
  padding: 40px 0px;
  display: grid;
  place-items: center;
  box-sizing: border-box;
  margin-top: 10px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 40px 0px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 40px 60px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 100%;
    padding: 10px 0px;
    margin: 0;
  }
`;

export const Title = styled.h2`
  ${(props) => props.theme.font.primary.title}
  margin:0;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 20px;
    line-height: 30px;
    display: ${(props) => (props.hide === 'hide' ? 'none' : '')};
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: ${(props) => (props.hide === 'hide' ? 'none' : '')};
    padding: 0 20px;
  }
`;

export const Paragraf = styled.p`
  ${(props) => props.theme.font.secondary.subtitle2}
  font-size: 20px;
  color: #fdfdfd;
  padding: 0 150px;
  margin: 32px 0;

  span {
    font-weight: bold;
    color: #00debf;
  }

  @media screen and ${(props) => props.theme.breakpoints.xl} {
    padding: 0 10px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 0 10px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 14px;
    line-height: 21px;
    margin: 16px 0 0;
  }
`;
