import { gql } from '@apollo/client';

export const SET_BOOKMARK = gql`
  mutation setBookmark($idTraining: Int) {
    SetBookmark(input: { training_id: $idTraining }) {
      id
      user_id
    }
  }
`;

export const UNSET_BOOKMARK = gql`
  mutation setBookmark($idTraining: Int) {
    UnsetBookmark(input: { training_id: $idTraining }) {
      id
      user_id
    }
  }
`;

export const SET_BOOKMARK_VID = gql`
mutation setBookmarkVid($input: BookmarkInput) {
  SetBookmark(input: $input) {
    id
    user_id
  }
}
`;

export const UNSET_BOOKMARK_VID = gql`
  mutation unsetBookmarkVid($input: BookmarkInput) {
    UnsetBookmark(input: $input) {
      id
      user_id
    }
  }
`;

export const ADD_COMMENT_TRAINING = gql`
  mutation ADD_COMMENT_TRAINING($input: TrainingCommentInput) {
    AddTrainingComment(input: $input) {
      id
      comment
      isLiked
      like
      total_replies
    }
  }
`;

export const CREATE_USER_TRAINING = gql`
  mutation CREATE_USER_TRAINING($input: CreateUserTrainingInput) {
    CreateUserTraining(input: $input) {
      status
    }
  }
`;

export const COMMENT_LIKED_TRAINING = gql`
  mutation COMMENT_LIKED_TRAINING($input: LikedComment) {
    SetUserLikedComment(input: $input) {
      status
      statusText
    }
  }
`;

export const COMMENT_UNLIKED_TRAINING = gql`
  mutation COMMENT_UNLIKED_TRAINING($input: LikedComment) {
    SetUserUnlikedComment(input: $input) {
      status
      statusText
    }
  }
`;

export const UPDATE_TRAINING = gql`
  mutation UPDATE_TRAINING($input: UpdateUserTrainingInput) {
    UpdateUserTraining(input: $input) {
      status
    }
  }
`;

export const RETAKE_TRAINING = gql`
  mutation RETAKE_TRAINING($input: RetakeQuizPostTestInput) {
    RetakeQuizPostTest(input: $input) {
      status
      statusText
    }
  }
`;

export const UPDATE_USER_TRAINING = gql`
  mutation UPDATE_USER_TRAINING($input: CreateTrainingUserDurationInput) {
    CreateTrainingUserDuration(input: $input) {
      status
      statusText
    }
  }
`;
export const DELETE_COMMENT = gql`
mutation DELETE_COMMENT($input: TrainingCommentInput){
  DeleteTrainingComment(input: $input){
    status
    statusText
  }
}
`

export const UPDATE_COMMENT = gql`
mutation UPDATE_COMMENT($input: TrainingCommentInput){
  UpdateTrainingComment(input: $input){
    status
    statusText
  }
}
`

export const ADD_TRAINING_TESTIMONY = gql`
mutation ADD_TRAINING_TESTIMONY($input: TrainingTestimonyInput){
  AddTrainingTestimony(input: $input){
    text
    rating
    id
    created_at
    updated_at
  }
}
`

export const DELETE_TRAINING_TESTIMONY = gql`
mutation DELETE_TRAINING_TESTIMONY($testimonyId: Int, $trainingId: Int){
  DeleteTrainingTestimony(testimonyId: $testimonyId, trainingId: $trainingId){
    status
    statusText
  }
}
`

export const UPDATE_TRAINING_TESTIMONY = gql`
mutation UPDATE_TRAINING_TESTIMONY($input: TrainingTestimonyInput){
  UpdateTrainingTestimony(input: $input){
    status
    statusText
  }
}
`

export const ADD_TRAINER_TESTIMONY = gql`
mutation ADD_TRAINER_TESTIMONY($input: TrainerTestimonyInput){
  AddTrainerTestimony(input: $input){
    text
    rating
    created_at
    id
    profile{
      fullname
      id
      avatar
    }
  }
}
`

export const UPDATE_TRAINER_TESTIMONY = gql`
mutation UPDATE_TRAINER_TESTIMONY($input: TrainerTestimonyInput){
  UpdateTrainerTestimony(input: $input){
    status
    statusText
  }
}
`