import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;

  .button {
    width: 100%;
    height: 45px;
    margin: 0;
    font-size: 12px;
    padding: 12px 0;
    margin-top: 24px;
  }
`;
export const Title = styled.p`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  padding: 0px 30px;
  text-align: center;
  margin: 0 0 16px 0;
  color: #00debf;
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  text-align: center;

  color: #c4c4c4;
`;
