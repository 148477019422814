import React from 'react';
import { Container, Img, Head, Text } from './styled';
import DefaultPic from 'assets/svg/User Default.svg';
import Star from 'assets/png/star.png';
import { formatLocaleDateShort } from 'utils/date';

const TrainerTestimoniCard = ({ trainer, userTestimoni, trainerPage, ratingForTrainer = true }) => {
  return (
    <Container trainerPage={trainerPage}>
      {trainer && (
        <>
          <Head>
            <Img src={trainer?.image} trainerPage={trainerPage} />
            <div className="header-text" id="header-text">
              <h4>{trainer?.name}</h4>

              <Text>{trainer?.profession}</Text>
            </div>
          </Head>
          <Text>{trainer?.text}</Text>
        </>
      )}

      {userTestimoni && (
        <>
          <Head>
            <Img
              src={userTestimoni?.profile?.avatar ? userTestimoni?.profile?.avatar : DefaultPic}
            />
            <div className="header-text" id="header-text">
              <h4>{userTestimoni?.profile?.fullname}</h4>
              {ratingForTrainer && (
                <div className="rating-trainer">
                  <img src={Star} alt="star-rating" />
                  {''} {userTestimoni?.rating}
                  <span>{'\u2022'}</span>
                  <text>{formatLocaleDateShort(userTestimoni?.profile?.created_at)}</text>
                </div>
              )}
            </div>
          </Head>
          <Text>{userTestimoni?.text}</Text>
        </>
      )}
    </Container>
  );
};

export default TrainerTestimoniCard;
