export function checkMobile() {
  return window.innerWidth <= 768;
}
export function checkTablet() {
  return window.innerWidth <= 1024;
}

export function phonesize() {
  return window.innerWidth <= 576;
}
