import React from 'react';
import TemplateHeadline from 'components/molecule/TemplateHeadline';
import { t } from 'hooks/intl/useLang';

const headline = {
  image: `https://d2jr0bw5daaf2g.cloudfront.net/assets/mari_berkolaborasi/Kolaborasi.png`,
  firstH1: null,
  secondH2: t('Mari Berkolaborasi dengan Terampil'),
  paragraf:
    t('Cari tahu bagaimana Terampil dapat memberikan solusi pengembangan karir untuk perusahaan Anda.'),
  link: '#form-register',
  button: t('Mari Berkolaborasi'),
};

const BerkolaborasiDenganTerampil = () => {
  return (
    <>
      <TemplateHeadline items={headline} kolaborasi={true} />
    </>
  );
};

export default BerkolaborasiDenganTerampil;
