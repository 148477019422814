import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button';

export const Contianer = styled.section`
  background-color: #141414;
  padding: 33px 0px 80px 50px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 33px 0 50px 30px;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 33px 16px 50px 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 16px;
  }
`;

export const CardBottom = styled.div`
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: #202020;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 32px 24px;
  box-sizing: border-box;

  svg {
    display: inline-block;
  }

  h2 {
    font-family: Archivo;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fdfdfd;

    span {
      font-size: 24px;
      color: #00debf;
    }
  }

  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #fdfdfd;
    padding: 0 50px;
  }
`

export const Button = styled(ButtonStyled)`
  font-size: 16px;
  font-weight: 700;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 160px;
    height: 41px;
    font-size: 14px;
    line-height: 17px;
  }
`;
