import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Search from './Input.styled';

import SearchIcon from 'assets/png/Search2.png';
import { t } from 'hooks/intl/useLang';

function NewInputComponent({ mobile }) {
  const [search, setSearch] = useState('');

  const history = useHistory();

  const pushRoute = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      if (search !== '') {
        history.push(`/trainings?search=${search}&page=1&category=all`);
      } else {
        history.push(`/trainings?category=all&page=1`);
      }
    }
  };

  return mobile ? (
    <Search className="mobile">
      <input
        className="mobile"
        autoFocus
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        placeholder={t("Cari Training dan Trainer")}
      />
      <img
        src={SearchIcon}
        alt="search"
        className="button"
        onClick={pushRoute}
        onKeyPress={pushRoute}
      />
    </Search>
  ) : (
    <>
      <Search>
        <img src={SearchIcon} value={search} alt="search" className="button" onClick={pushRoute} />
        <input
          id="searchBar"
          type="text"
          placeholder={t("Cari Training dan Trainer")}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={pushRoute}
        />
      </Search>
    </>
  );
}

// Input.propTypes = {};

export default NewInputComponent;
