import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Section, SubTitle, Heading, SliderPagination } from './styled';
import Tittle from 'components/atoms/Tittle';
import MaxContainer from 'components/atoms/MaxContainer';
import TrainerTestimoniCard from 'components/molecule/TrainerTestimoniCard';
import { SliderControlPrimary } from 'components/atoms/Slider/Slider.styled';
import { checkMobile } from 'utils/checkMobile';
import Slider from 'components/atoms/Slider';
import { CardContainer } from 'components/atoms/CardSliderContainer/styled';

import { ReactComponent as PrevIcon } from 'assets/svg/prev-slider.svg';
import { ReactComponent as NextIcon } from 'assets/svg/next-slider.svg';
import TitleSection from 'components/molecule/TitleSectionTrainer';
import img1 from 'assets/png/MaskGroup1.png';
import img2 from 'assets/png/MaskGroup2.png';
import img3 from 'assets/png/MaskGroup3.png';
import img4 from 'assets/png/MaskGroup4.png';
import { t } from 'hooks/intl/useLang';

const dataTrainerTestimoni = [
  {
    name: 'Helmy Yahya',
    profession: t('Pembawa Acara dan Politikus'),
    text: t('Saya senang sekali bisa menjadi bagian dari Terampil. Pemateri-pemateri di Terampil adalah para profesional yang kita percaya sangat bermanfaat untuk diikuti atau disimak, jadi nggak rugi gabung di Terampil. Semoga kehadiran Terampil bisa bermanfaat untuk mendukung profesionalisme di dunia kerja maupun dunia usaha.'),
    image: img3,
  },
  {
    name: 'Hilbram Dunar',
    profession: 'Public Speaking Trainer',
    text: t('Terampil adalah cara baru untuk kita mengembangkan diri dengan berbagai pelatihan yang ada. Menjadi trainer di Terampil seru banget karena team yang sangat kooperatif. Proses syuting video pembelajaran pun sangat lancar. Semoga ke depannya bisa mengajar materi lain di Terampil. Sukses untuk Terampil!'),
    image: img1,
  },
  {
    name: 'Ratih Astarina',
    profession: 'Business Development Manager',
    text: t('Terampil sangat mengerti apa yang market butuhkan saat ini. Saat proses shooting video pembelajaran, Terampil team is very helpful since the day 1. Mereka bantu mengarahkan materi dan skill apa yang saat ini dibutuhkan oleh market dan trend. Selain itu prosesnya digarap dengan serius dan profesional'),
    image: img2,
  },
  {
    name: 'Jibril Erlangga',
    profession: 'Owner MageJibril Production',
    text: t('Pengalaman yang pleasant jadi trainer di Terampil! Aku nggak nyangka proses produksi dan hasil videonya sangat berkualitas. LO-nya juga sangat informatif, dipandu dalam pembuatan modul agar materi bisa tersampaikan dengan baik. Terampil ini platform untuk semua orang dengan harga terjangkau dan pemateri profesional. Sangat mempermudah semua kalangan untuk mendapat keterampilan baru!'),
    image: img4,
  },
];

const TrainerTestimoni = ({
  loading = false,
  userTestimoni = null,
  trainerPage,
  fullname = '',
}) => {
  function renderSlider() {
    return (
      <Slider
        next="next-trainer"
        prev="prev-trainer"
        pagination="trainer-popular"
        count={checkMobile() ? 1 : 2}
        spaceBetween={20}
      >
        {dataTrainerTestimoni &&
          dataTrainerTestimoni?.map((trainer, idx) => {
            return (
              <SwiperSlide key={idx}>
                <TrainerTestimoniCard
                  trainerPage={trainerPage}
                  trainer={trainer}
                  next="next-trainer"
                  prev="prev-trainer"
                />
              </SwiperSlide>
            );
          })}
      </Slider>
    );
  }

  function renderTrainer() {
    return (
      <Slider
        next="next-trainer"
        prev="prev-trainer"
        pagination="trainer-popular"
        count={checkMobile() ? 1 : 2}
        spaceBetween={20}
      >
        {userTestimoni &&
          userTestimoni?.map((item, idx) => {
            return (
              <SwiperSlide key={idx}>
                <TrainerTestimoniCard
                  trainerPage={trainerPage}
                  userTestimoni={item}
                  next="next-trainer"
                  prev="prev-trainer"
                />
              </SwiperSlide>
            );
          })}
      </Slider>
    );
  }

  function renderSection() {
    if (userTestimoni && userTestimoni.length > 0) {
      return (
        <Section trainerPage={trainerPage}>
          <MaxContainer>
            {trainerPage && userTestimoni !== null ? (
              <TitleSection
                title={`${t('Ulasan untuk')} ${fullname.split(' ')[0] || ''}`}
                className="pb-3 py-0"
              />
            ) : (
              <Heading>
                <Tittle>{t('Kata Para Trainer Terampil')}</Tittle>
                <SubTitle>
                  {t('Trainer dari berbagai disiplin ilmu sudah membagikan cerita dan pengalamannya di Terampil.')}{' '}
                </SubTitle>
              </Heading>
            )}
            <div>
              <div className="slider">
                <div className="button-slider">
                  <SliderControlPrimary className="prev-primary" id="prev-trainer">
                    <PrevIcon />
                  </SliderControlPrimary>
                  <SliderControlPrimary className="next-primary" id="next-trainer">
                    <NextIcon />
                  </SliderControlPrimary>
                </div>
                {userTestimoni && trainerPage && !loading ? (
                  <CardContainer>{renderTrainer()}</CardContainer>
                ) : null}
                {!trainerPage && <CardContainer>{renderSlider()}</CardContainer>}
              </div>
              <SliderPagination id="trainer-popular" />
            </div>
          </MaxContainer>
        </Section>
      );
    }
    return null;
  }

  return <>{renderSection()}</>;
};

export default TrainerTestimoni;
