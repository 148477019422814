import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Container, LeftContainer, RightContainer, Header } from './styled';
import SideMenu from 'components/atoms/SideMenu';
import Input from 'components/atoms/Input';

import { ReactComponent as HamburgerIcon } from 'assets/svg/hamburger.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import TerampilLogo from 'assets/png/logo-terampil.png';
import { GET_CATEGORIES } from 'queries/categories';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import NotLogin from './notlogin';
import LoggedIn from './loggedin';

import { useSelector } from 'react-redux';
import { getValue } from 'utils';
import DownloadAppMobile from 'components/molecule/Modal/DownloadAppMobile';

const HeaderMobile = ({ userData, focusBarMobile, setFocusBarMobile, showModalDownloadApp }) => {
  const { loading, data: categoryData } = useQuery(GET_CATEGORIES, {
    variables: {
      is_active: true,
      orderBy: 'id',
      limit: 8,
    },
  });
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const [isOpen, setIsOpen] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const [modalBar, setModalBar] = useState();

  const userAgent = navigator.userAgent;
  const appleDevice = /iPad|iPhone|iPod/.test(userAgent);
  const androidDevice = /android/i.test(userAgent);

  useEffect(() => {
    if (showModalDownloadApp && modalBar === undefined && !appleDevice) {
      setModalBar(true);
    }
  }, [modalBar]);

  return (
    <Header>
      {showModalDownloadApp && modalBar && (
        <DownloadAppMobile
          setModalBar={setModalBar}
          modalBar={modalBar}
          appleDevice={appleDevice}
          androidDevice={androidDevice}
        />
      )}
      <SideMenu isOpen={isOpen} setIsOpen={setIsOpen}>
        {getValue(authData, 'data.accessToken', '') ? (
          <LoggedIn
            setShowCategory={setShowCategory}
            showCategory={showCategory}
            categoryData={categoryData}
            loading={loading}
            userData={userData}
          />
        ) : (
          <NotLogin
            setShowCategory={setShowCategory}
            showCategory={showCategory}
            categoryData={categoryData}
            loading={loading}
            setIsOpen={setIsOpen}
          />
        )}
      </SideMenu>
      <Container second={showModalDownloadApp && modalBar}>
        {searchBar || focusBarMobile ? (
          <>
            <CloseIcon
              className="button close-icon"
              onClick={() => {
                if (setFocusBarMobile) {
                  setFocusBarMobile(false);
                }
                setSearchBar(false);
              }}
            />
            <Input mobile />
          </>
        ) : (
          <>
            <LeftContainer>
              <HamburgerIcon className="button" onClick={() => setIsOpen(true)} />
              <Link to={authData?.data?.accessToken ? '/home' : '/'}>
                <LazyLoadImage
                  src={TerampilLogo}
                  alt="logo-terampil"
                  width={120}
                  className="button"
                  effect="blur"
                />
              </Link>
              <RightContainer className="right-section">
                <SearchIcon
                  className={`button ${isOpen && 'hidden'}`}
                  onClick={() => setSearchBar(false)}
                />
              </RightContainer>
            </LeftContainer>
          </>
        )}
      </Container>
      {showModalDownloadApp && modalBar && <div className="space-vertical" />}
    </Header>
  );
};

export default HeaderMobile;
