import styled from 'styled-components';

export const Container = styled.div`
  background: #202020;
  border-radius: 10px;
  width: 100%;
  height: auto;
  margin: 28px 0 5px 0;
  padding: 24px;
  box-sizing: border-box;

  .grid {
    display: grid;
    place-items: end;
    margin-top: 21px;
  }

  .container-profile {
    display: flex;

    .container-title {
      padding-left: 16px;
    }

    p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #c4c4c4;
      margin: 8px 0 0 0;
      text-align: left;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 8px;

    .container-profile {
      display: flex;

      .container-title {
        padding-left: 8px;
        display: flex;
        justify-content: space-between;

        .hide{
          display: none;
          pointer-events: none;
        }

        .more-action{
          position: absolute;
          display: block;
          background: #202020;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          padding: 5px 40px;
          right: 8%;
          margin-top: 10px;
          text-align: left;

          div.action-task{
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            display: flex;
            line-height: 15px;
            color: #C4C4C4;
            margin: 10px 0;
            position: relative;
            left: -20px;

            svg{
              width: 12px;
              height: auto;
              margin-right: 8px;
            }
          }
        }

        .container-user{
          display: block;
          width: 70vw;
        }
      }

      p {
        font-weight: 300;
        font-size: 10px;
        line-height: 15px;
        margin: 4px 0 0 0;
      }
    }

    .grid {
      margin-top: 18px;
    }
  }
`;

export const AvatarImage = styled.div`
  background-image: url('${(props) => props.image}');
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 40px;
    height: 40px;
  }
`;

export const ReplyAvatarImage = styled.div`
  background-image: url('${(props) => props.image}');
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  margin-right: 16px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 40px;
    height: 40px;
  }
`;

export const TitleName = styled.h2`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #fdfdfd;
  margin: 0;
  text-align: left;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-family: Inter;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
  }
`;

export const CardComent = styled.div`
  background: #373737;
  border-radius: 5px;
  width: 92%;
  height: auto;
  padding: 10px 16px;
  box-sizing: border-box;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #fdfdfd;
  text-align: left;
  margin: 0px 0 0 20px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 6px 8px;
    display: inline-block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    max-lines: 3;
    ${(props) => (props.more ? '-webkit-box-orient: vertical' : '')};
    overflow: hidden;
    font-size: 13px;
    line-height: 24px;
  }
`;

export const ReplayDiv = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => (props.replay ? '10px 0 0 10px' : '10px 0 0 65px')};

  .disable-item{
    pointer-events: none;
    color: #C4C4C4;
  }

  svg {
    cursor: pointer;
    align-self: center;
  }

  .liked{
    stroke: #00DEBF;
  }

  .unliked{
    stroke: red;
  }

  p.like {
    font-family: Archivo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #c4c4c4;
    margin: 0 16px 0 10px;
  }

  h6 {
    font-family: Archivo;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: #00DEBF;
    margin: 0 0 0 16px;
    cursor: pointer;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    p.like{
      font-size: 12px;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin: ${(props) => (props.replay ? '10px 0 0 5px' : '10px 0 0 40px')};
    margin-left: 26px;

    p {
      font-size: 10px;
      line-height: 11px;
    }

    h6 {
      font-weight: 600;
      font-size: 13px;
      line-height: 14px;
    }
  }
`;

export const ReplayComent = styled.div`
  border-top: 1px solid #373737;
  margin: 16px 0 0 65px;
  padding-top: 16px;
  box-sizing: border-box;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin: 16px 0 0 40px;
  }
`;

export const ReplayCard = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  div.container {
    display: flex;
    flex-direction: column;
  }

  div.container-like {
    display: flex;
  }

  div.profile-name {
    display: flex;
    align-items: center;
  }
  h2 {
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    margin: 0 25px 0 0;
    color: #fdfdfd;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #c4c4c4;
    margin: 0 0 0 16px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    h2 {
      font-size: 13px;
      line-height: 16px;
      margin: 0 12px 0 0;
    }

    p {
      font-size: 10px;
      line-height: 15px;
      margin: 0 0 0 12px;
    }

    p.coment {
    }
  }
`;

export const ComentReplay = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #fdfdfd;
  text-align: left;
  margin: 16px 0 0 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 6px 8px;
    display: inline-block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    max-lines: 3;
    ${(props) => (props.more ? '-webkit-box-orient: vertical' : '')};
    overflow: hidden;
    font-size: 13px;
    line-height: 24px;
  }
`;

export const ProfileCard = styled.div`
  background: #373737;
  border-radius: 5px;
  padding: 16px;
  box-sizing: border-box;
  margin-left: 10px;
  width: 100%;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin-left: 8px;
    padding: 8px;
    width: 95%;
  }
`;


export const ContainerAddReply = styled.div`
background: #202020;
border-radius: 10px;
width: 100%;
height: auto;
margin: 24px 0 0 0;
padding: 0px 0px 0px 30px;
box-sizing: border-box;


.disabled-button {
  color: #626262;
  background: transparent;
  cursor: default;
  border: 1px solid #626262;
}

.save-button {
  margin: 0;
  width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
}

.cancel-button{
  margin: 0;
  width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
  color: #c4c4c4;
}

.container-button{
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .loading{
    margin: 0 10px;
    width: 20px;
    height: 20px;
  }
}

.container-profile {
  display: flex;
  margin-left: 30px;

  .container-title {
    padding-left: 16px;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #c4c4c4;
    margin: 8px 0 0 0;
    text-align: left;
  }
}

.input-comment{
  margin: 0;
}

@media screen and ${(props) => props.theme.breakpoints.xs} {
  padding: 8px;

  .container-profile {
    .container-title {
      padding-left: 8px;
    }

    p {
      font-weight: 300;
      font-size: 10px;
      line-height: 15px;
      margin: 4px 0 0 0;
    }
  }

  .grid {
    margin-top: 18px;
  }
}
`
export const ContainerReplies = styled.div`
    display: flex;
    margin: 10px 0 0 10px;
    color: #00DEBF;
    align-items: center;

  .loading{
    margin: 0 10px;
    width: 20px;
    height: 20px;
  }

  .action-save{
    margin: 0 0 0 16px;
    width: 102px;
    height: 30px;
    font-size: 14px;
    font-weight: 600;
  }

  h6 {
    font-family: Archivo;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: #00DEBF;
    margin: 0 0 0 16px;
    cursor: pointer;
  }

  svg {
    cursor: pointer;
    align-self: center;
    margin-left: 16px;
    fill: #00DEBF;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
      h6 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      color: #00DEBF;
      margin: 0 0 0 16px;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
      align-self: center;
      margin-left: 16px;
      fill: #00DEBF;
    }

    .action-save{
      /* margin: 0 0 0 16px; */
      width: 50px;
      height: 20px;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      background: transparent;
      color: #00DEBF;
      justify-content: center;
      align-items: center;
    }
  }
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .hide-component{
    display: none;
  }
`

export const Input = styled.input`
  background: #373737;
  border-radius: 5px;
  border: 1px solid grey;
  width: 92%;
  height: auto;
  padding: 16 0px;
  box-sizing: border-box;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #fdfdfd;
  text-align: left;
  margin: 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 6px 8px;
    display: inline-block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    max-lines: 3;
    ${(props) => (props.more ? '-webkit-box-orient: vertical' : '')};
    overflow: hidden;
    font-size: 13px;
    line-height: 24px;
  }
`;

export const EditComentReplay = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #fdfdfd;
  text-align: left;
  margin: 0px;
  padding: 16px 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 6px 8px;
    display: inline-block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    max-lines: 3;
    ${(props) => (props.more ? '-webkit-box-orient: vertical' : '')};
    overflow: hidden;
    font-size: 13px;
    line-height: 24px;
  }
`;