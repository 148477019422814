import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { checkMobile } from 'utils/checkMobile';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from 'queries/categories';

import FormInputJadiTrainer from 'components/molecule/FormJadiTrainer/';
import { Section, BoxRegister, Title, TermCondition, CheckLogin } from './styled';

// asset
import { ReactComponent as ArrowBack } from 'assets/svg/backArrow.svg';
import LogoTerampil from 'assets/png/register/Logo Terampil Register.png';
import FormError from 'components/atoms/FormError';
import { t } from 'hooks/intl/useLang';

const FormRegisterJadiTrainer = () => {
  const { loading: categoryLoading, data: categoryData } = useQuery(GET_CATEGORIES, {
    variables: {
      is_active: true,
      orderBy: 'id',
      limit: 8,
    },
  });
  const [errorRegister, setErrorRegister] = useState(null);

  const history = useHistory();
  const goToPage = (page) => {
    history.push(`/${page}`);
  };
  return (
    <Section>
      <BoxRegister>
        {checkMobile() && (
          <div>
            {' '}
            <img className="logo" src={LogoTerampil} alt="logo" />{' '}
          </div>
        )}
        <Title>{t('Daftar Menjadi Trainer')}</Title>
        {errorRegister && (
          <div>
            <FormError>{errorRegister}</FormError>
          </div>
        )}
        <FormInputJadiTrainer
          categoryData={categoryData?.Categories?.items}
          loading={categoryLoading}
          setErrorRegister={setErrorRegister}
        />
        {/*<TermCondition>*/}
        {/*  Dengan mendaftar, Anda menyetujui <span login="link">Ketentuan Penggunaan</span> dan*/}
        {/*  <span> Kebijakan Privasi </span>*/}
        {/*</TermCondition>*/}
        {/*<CheckLogin>*/}
        {/*  Sudah punya akun?{' '}*/}
        {/*  <Link to="/masuk">*/}
        {/*    <span>Masuk</span>*/}
        {/*  </Link>*/}
        {/*</CheckLogin>*/}
      </BoxRegister>
      <ArrowBack onClick={() => goToPage('jadi-trainer')} className="back-button" />
    </Section>
  );
};

export default FormRegisterJadiTrainer;
