import React from 'react';
import { Container } from './styled';
import SocialMediaShare from 'components/atoms/SocialMediaShare';

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

const SharedButton = ({ urlLink }) => {
  return (
    <Container>
      <FacebookShareButton
        size={30}
        url={urlLink}
        quote={`Hai, Terampil people! Ada platform training bagus nih buat upgrade karir dan bisnis kamu!\nDapatkan 1 video training gratis dan penawaran spesial! Daftar sekarang melalui link ini:`}
      >
        <SocialMediaShare clicked type="Facebook" />
      </FacebookShareButton>
      <TwitterShareButton
        size={30}
        url={urlLink}
        title={`Hai, Terampil people! Ada platform training bagus nih buat upgrade karir dan bisnis kamu!\nDapatkan 1 video training gratis dan penawaran spesial! Daftar sekarang melalui link ini:`}
      >
        <SocialMediaShare clicked type="Twitter" />
      </TwitterShareButton>
      <EmailShareButton
        size={30}
        subject="Undang Teman Kamu"
        body={`Hai, Terampil people! Ada platform training bagus nih buat upgrade karir dan bisnis kamu!\nDapatkan 1 video training gratis dan penawaran spesial! Daftar sekarang melalui link ini:
`}
        url={urlLink}
      >
        <SocialMediaShare clicked type="Email" />
      </EmailShareButton>
      <WhatsappShareButton
        url={urlLink}
        title={`Hai, Terampil people! Ada platform training bagus nih buat upgrade karir dan bisnis kamu!\nDapatkan 1 video training gratis dan penawaran spesial! Daftar sekarang melalui link ini:`}
        size={30}
      >
        <SocialMediaShare clicked type="WhatsApp" />
      </WhatsappShareButton>
    </Container>
  );
};

export default SharedButton;
