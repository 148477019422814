/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Title, Wrapper } from './styled';
import RatingUlasan from 'components/molecule/Ulasan/Rating';
import FilterRating from 'components/molecule/Ulasan/FilterRating';
import CardUlasanComent from 'components/molecule/Ulasan/CommentUlasan';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_DETAIL_TRAINING, GET_TRAINER_TESTIMONY, LIST_REVIEW_TRAINING } from 'queries/training';
import { getValue, setContext } from 'utils';
import { useSelector } from 'react-redux';
import BelumAdaTestimoni from 'components/organisms/BelumAdaTestimoni';
import Button from 'components/atoms/Button';
import BeriUlasanModal from 'components/molecule/Modal/BeriUlasanModal';
import { LoaderIcon } from 'react-hot-toast';
import { GET_USER_DETAIL } from 'queries/user';
import { checkToken } from 'utils/checkToken';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';

const UlasanTab = ({ isFollowTraining, haveTrainingFreeTrail }) => {
  const [selected, setSelected] = useState(10);
  const [visiblePosts, setVisiblePosts] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isReview, setIsReview] = useState('');
  const { slug } = useParams();
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const handleClick = useCallback(() => {
    setVisiblePosts((prevVisibleBlogs) => prevVisibleBlogs + 10);
  }, []);

  const { data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    context: setContext(authData),
    variables: {
      input: slug,
    },
  });

  const { loading: testimonyLoading, data: listReviewTraining } = useQuery(LIST_REVIEW_TRAINING, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        rating: selected,
        limit: 999999,
      },
    },
  });

  const { data: listReviewTrainingAll, loading: testimonyAllLoading } = useQuery(
    LIST_REVIEW_TRAINING,
    {
      context: setContext(authData),
      variables: {
        input: {
          trainingId: detailTraining?.Training?.id,
          orderBy: 'created_at',
          limit: 999999,
        },
      },
    },
  );

  const { data: userTraining } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const { data: getTrainerTestimony } = useQuery(GET_TRAINER_TESTIMONY, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        trainerId: detailTraining?.Training?.trainer?.id,
      },
    },
  });

  const token = getValue(authData, 'data.accessToken', '');

  const isTestimony = listReviewTraining?.TrainingTestimonies?.items;
  const isTestimonyAll = listReviewTrainingAll?.TrainingTestimonies?.items;
  const isMyId = userTraining?.ProfileUser?.user_id;

  let checkMyId = [];
  let checkReviewId = [];

  //check if the id is already comment or not
  let isGiveTrainerReview = getTrainerTestimony?.TrainerTestimony !== null;

  //check rating that given by user
  const getUserId =
    isTestimonyAll &&
    isTestimonyAll.map((item, i) => {
      return checkMyId.push(item?.profile?.user_id);
    });

  let validId = checkMyId.includes(isMyId);

  const getReviewId =
    isTestimonyAll &&
    isTestimonyAll.map((item, i) => {
      if (item?.profile?.user_id === isMyId) {
        return checkReviewId.push(item?.id);
      }
    });

  useEffect(() => {
    checkToken(token, setIsLoggedIn);

    if (validId || isGiveTrainerReview) {
      setIsReview('Ubah Ulasan');
    } else if (!validId || !isGiveTrainerReview) {
      setIsReview('Beri Ulasan');
    }
  }, [validId, isGiveTrainerReview]);

  return (
    <Container>
      <Wrapper>
        <BeriUlasanModal
          setShowModal={setShowModal}
          selected={selected}
          onRequestClose={() => setShowModal(!showModal)}
          item={detailTraining?.Training}
          isOpen={showModal}
          testiId={isTestimonyAll?.id}
          isGiveReview={isReview}
          isTestimonyId={parseInt(checkReviewId)}
        />
        <Title>Ulasan</Title>
        {!checkMobile() ? (
          <Button
            onClick={() => setShowModal(!showModal)}
            className={isFollowTraining && !haveTrainingFreeTrail ? `ulasan` : `hide-component`}
            small
          >
            <span>{isReview}</span>
          </Button>
        ) : (
          <span
            className={
              isFollowTraining && !haveTrainingFreeTrail ? `isreview-mobile` : `hide-component`
            }
            onClick={() => setShowModal(!showModal)}
          >
            + {isReview}
          </span>
        )}
      </Wrapper>
      <RatingUlasan />
      <FilterRating selected={selected} setSelected={setSelected} />
      {testimonyLoading ? (
        <div className="wrapper-loading">
          <LoaderIcon className="loading-icon" />
        </div>
      ) : isTestimony && isTestimony.length > 0 ? (
        isTestimony.slice(0, visiblePosts).map((item, i) => {
          return (
            <CardUlasanComent
              selected={selected}
              setSelected={setSelected}
              item={item}
              testimonyId={item?.id}
              userId={item?.profile?.user_id}
              key={i}
            />
          );
        })
      ) : testimonyAllLoading ? (
        <div className="wrapper-loading">
          <LoaderIcon className="loading-icon" />
        </div>
      ) : selected === 10 && isTestimonyAll && isTestimonyAll.length > 0 ? (
        isTestimonyAll.slice(0, visiblePosts).map((item, i) => {
          return (
            <CardUlasanComent
              selected={'semua'}
              setSelected={setSelected}
              item={item}
              testimonyId={item?.id}
              userId={item?.profile?.user_id}
              key={i}
            />
          );
        })
      ) : (
        <BelumAdaTestimoni selected={selected} />
      )}
      {isTestimonyAll && isTestimonyAll.length > 10 ? (
        <Button onClick={handleClick} className="loadmore-button">
          {t('Lihat Ulasan Lainnya')}
        </Button>
      ) : null}
    </Container>
  );
};

export default UlasanTab;
