/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { GET_USER_CERTIFICATE } from 'queries/certificate';
import { CREATE_CERTIFICATE } from 'mutation/certificate';
import { GET_USER_TRAINING_DURATIONS, GET_USER_TRAININGS } from 'queries/training';
import { GET_TRAINING_SUMMARY } from 'queries/training';
import { GET_POST_TEST_USER } from 'queries/kuis';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { setContext } from 'utils';
import { useSelector } from 'react-redux';
import { convertDurationSecondProgress } from 'utils/duration';

import { Contianer, CardContainer, CardUpside, CardBottom, Button, Flex } from './styled';

import TimerIcon from 'assets/svg/trainingDetails/hasilTime.svg';
import GrafikIcon from 'assets/svg/trainingDetails/hasilGrafik.svg';
import { ReactComponent as Arrow } from 'assets/svg/trainingDetails/arrow.svg';
import { t } from 'hooks/intl/useLang';

const HasilTrainingSertif = ({ trainingId, verifyCertificate, postTestSectionId }) => {
  const [certificateLink, setCertificateLink] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [totalProgress, setTotalProgress] = useState(0);

  const authData = useSelector((state) => state.Auth);

  const [createCertificate] = useMutation(CREATE_CERTIFICATE, {
    context: setContext(authData),
    onCompleted: (data) => {
      setCertificateLink(data?.CreateCertificate?.file?.link);
    },
  });

  const { data: postTest } = useQuery(GET_TRAINING_SUMMARY, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      trainingId: trainingId,
    },
    onCompleted: (data) => {
      // const summary = data?.TrainingUserResult?.summary.split('/');

      setTotalProgress(data?.TrainingUserResult?.levelLearning);

      // if (+summary[0] / +summary[1] >= 0.7) {
      //   setCompleted(true);
      // }
    },
    onError: (err) => {
      console.log('errx3', err);
    }
  });

  const { data: postTestResult } = useQuery(GET_POST_TEST_USER, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      trainingId: trainingId,
      trainingSectionId: postTestSectionId,
    },
    onCompleted: (data) => {
      const summary = data?.ResultPostTestUser?.summary.split('/');

      if (+summary[0] / +summary[1] >= 0.6) {
        setCompleted(true);
      }
    },
  });

  const { data: durations } = useQuery(GET_USER_TRAINING_DURATIONS, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      trainingId: trainingId,
    },
  });

  const { data: progress } = useQuery(GET_USER_TRAININGS, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    onCompleted: () => {
      getCertificate();
    },
  });

  const [getCertificate, { data: certificate, error: errorCreateCerificate }] = useLazyQuery(
    GET_USER_CERTIFICATE,
    {
      fetchPolicy: 'no-cache',
      context: setContext(authData),
      variables: {
        trainingId: trainingId,
      },
      onCompleted: (data) => {
        setCompleted(true);
        setCertificateLink(data?.UserCertificate?.file?.link);
      },
      onError: (error) => {
        const trainingData = progress?.UserTrainings?.items?.find(
          (x) => x.training.id === trainingId,
        );

        if (error.message === 'Certificate not found' && completed) {
          createCertificate({
            variables: {
              input: {
                trainingTitle: trainingData?.training?.title,
                trainingCollaboration: trainingData?.training?.collaboration?.image || '',
                trainingCooporation: trainingData?.training?.cooperation?.image || '',
                trainerFullname: trainingData?.training?.trainer?.fullname,
                trainerProfession: trainingData?.training?.trainer?.profession,
                trainingId: trainingId,
              },
            },
          });
        }
      },
    },
  );

  const findProgress = (data) => {
    if (!data) return '0';

    const found = data.find((x) => x.training.id === trainingId);
    return found.training_progress;
  };

  const totalDuration = (data) => {
    if (!data) return '0 m 0 s';

    const sum = data.reduce((x, y) => x + y.duration, 0);

    return convertDurationSecondProgress(sum);
  };

  return (
    <Contianer verifyCertificate={verifyCertificate} id="hasil">
      <CardContainer>
        <CardUpside>
          <h3>{t('Level Learning')}</h3>
          <div>
            <p>{t('Pengetahuan diperoleh sejak Anda pertama kali mengikuti pelatihan')}</p>
            <Flex>
              <Arrow />
              <h2>
                {verifyCertificate ? verifyCertificate?.Result?.levelLearning : totalProgress} %
              </h2>
            </Flex>
          </div>
          <img src={GrafikIcon} alt="" />
        </CardUpside>
        <CardUpside>
          <h3>{t('Level Reaction')}</h3>
          <div>
            <p>{t('Total waktu yang Anda habiskan dalam pelatihan ini')}</p>
            <h2>
              {verifyCertificate
                ? `- m -s s`
                : totalDuration(durations?.TrainingUserDurations?.items)}
            </h2>
          </div>
          <img src={TimerIcon} alt="" />
        </CardUpside>
      </CardContainer>
      {!verifyCertificate && (
        <CardBottom>
          <h2>
            {t('Kamu berhasil menjawab')} <span> {postTestResult?.ResultPostTestUser?.summary} </span> {t('pertanyaan dengan benar')}
          </h2>
          {completed && certificateLink && certificate && !errorCreateCerificate && (
            <Button rounded onClick={() => window.open(certificateLink)}>
              {t('Download Sertifikat')}
            </Button>
          )}
          <p style={{ color: '#c4c4c4' }}>
            {t('Kamu harus mendapatkan nilai Tes Akhir')}{' '}
            <strong style={{ color: '#fdfdfd' }}>minimum 60</strong> {t('untuk bisa download sertifikat')}.{' '}
            <br /> {t('Silakan Retake untuk ikuti Tes Akhir lagi. Semangat!')}
          </p>
        </CardBottom>
      )}
    </Contianer>
  );
};

export default HasilTrainingSertif;
