import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { client } from 'App';

import { Kategori, UserProfile, ProfilePic, ProfileDetail, Progress, Line } from './styled';
import { CategoryLoading } from 'components/atoms/Loading';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
import { useMutation, useQuery } from '@apollo/client';
import { DO_SIGNOUT } from 'mutation/logout';
import { useGoogleLogout } from 'react-google-login';

import ProfileDefault from 'assets/svg/User Default.svg';
import { useDispatch, useSelector } from 'react-redux';
import { authOperations } from 'state/modules/auth';
import { getValue, setContext } from 'utils';
import { GET_NOTIFICATION_COUNT } from 'queries/notification';
import { subscribeOperations } from 'state/modules/payment';
import { GET_USER_LAST_ORDER_ACTIVE } from 'queries/orders';
import { GET_SUMMARY_USER_TRAINING } from 'queries/training';
import { t } from 'hooks/intl/useLang';

const LoggedIn = ({ userData = {}, loading, showCategory, setShowCategory, categoryData }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const { data: notificationCount } = useQuery(GET_NOTIFICATION_COUNT, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const { data: userSummary } = useQuery(GET_SUMMARY_USER_TRAINING, {
    context: setContext(authData),
    onError: () => ({}),
  });

  const { data: orderActive } = useQuery(GET_USER_LAST_ORDER_ACTIVE, {
    context: setContext(authData),
  });

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_ID,
    onLogoutSuccess: () => {
      client.resetStore();
    },
    onFailure: (e) => console.error(e, 'ERROR FROM GOOGLE LOGIN'),
  });

  const [fetchSignout] = useMutation(DO_SIGNOUT, {
    onCompleted: () => {
      client.clearStore();
      signOut();

      dispatch(authOperations.removeAuth());
      dispatch(subscribeOperations.removeSubscribe());

      history.push('/');
    },
  });

  const doSignout = () => {
    fetchSignout({
      context: setContext(authData),
    });
  };

  const percentage = ~~userSummary?.SummaryUserTrainings?.summary;

  const renderSubsButton = (is_subscription, is_unsubscription, orderList, free_trial) => {
    if (is_subscription && !free_trial && orderList) {
      return (
        <Link to="/berlangganan" className="header-link py-5 start-subs">
          {t('Lanjut Berlangganan')}
        </Link>
      );
    } else if (is_subscription || is_unsubscription) {
      return (
        <Link to="/berlangganan" className="header-link py-5 start-subs">
          {t('Mulai Berlangganan')}
        </Link>
      );
    }

    return;
  };

  return (
    <>
      <UserProfile>
        <ProfilePic src={`${userData?.avatar ? userData?.avatar : ProfileDefault}`} />
        <ProfileDetail>
          <div className="detail">
            <h6>{userData?.fullname}</h6>
            <p>{userData?.profession}</p>
          </div>
          <Link to="/user/me">{t('Lihat Profil')}</Link>
        </ProfileDetail>
      </UserProfile>

      <Progress>
        <div>
          <p>{t('Progres Belajar')}</p>
          <span>{percentage ?? 0}%</span>
        </div>
        <div>
          <p>{t('Sisa Kuota')}</p>
          <span>
            {userData?.training_quota > 0 && userData?.User?.free_trial
              ? 0
              : userData?.training_quota}
          </span>
        </div>
      </Progress>
      {location.pathname !== '/home' && location.pathname !== '/' && (
        <>
          {userData?.is_subscription && <Line />}
          <Link
            to={getValue(authData, 'accessToken') ? '/home' : '/'}
            className="header-link py-5 pt-5"
          >
            {t('Kembali ke Home')}
          </Link>
          <Line />
        </>
      )}
      <Link to="/business-page" className="header-link py-5 pt-5 mb-3">
        For Enterprise
      </Link>
      <Link to="/pelatihan-saya" className="header-link py-5 mb-3">
        {t('Training Saya')}
      </Link>
      <Link to="/notifikasi" className="header-link notification py-5 mb-3">
        {t('Notifikasi')}{' '}
        {notificationCount?.ProfileUser?.unread_notification > 0 && (
          <div className="notification-count">
            {notificationCount?.ProfileUser?.unread_notification}
          </div>
        )}
      </Link>
      <Kategori className="py-5 pb-3 mb-3">
        <div onClick={() => setShowCategory(!showCategory)}>
          <h3>{t('Kategori')}</h3>{' '}
          <ArrowIcon className={showCategory ? 'arrow-icon-show' : 'arrow-icon-hidden'} />
        </div>
        {showCategory &&
          loading &&
          [...Array(3)].map((data, idx) => <CategoryLoading className="mobile py-5" key={idx} />)}
        {showCategory &&
          categoryData?.Categories?.items?.map((data) => {
            return (
              <Link
                to={`/trainings?category=${data.id}&page=1`}
                className="header-link-kategori py-5"
                key={data.id}
              >
                <div>
                  <img src={data.icon_default} alt={data.name} />
                </div>
                {data.name}
              </Link>
            );
          })}
      </Kategori>
      {/* {renderSubsButton(
        !userData?.is_subscription,
        userData?.is_unsubscription,
        orderActive?.UserOrders?.items?.length > 1,
        userData?.User?.free_trial,
      )} */}
      <Link to="/berlangganan" className="header-link py-5 start-subs">
        {t('Pembayaran')}
      </Link>
      <Link to="/user/settings" className="header-link py-5">
        {t('Pengaturan')}
      </Link>
      <button className="header-link py-5" onClick={doSignout}>
        {t('Keluar')}
      </button>
    </>
  );
};

export default LoggedIn;
