import moment from 'moment';

export const formatDateLong = (date) => {
  const currentDate = new Date(date);
  const result = new Intl.DateTimeFormat('id', { dateStyle: 'long' }).format(currentDate);
  return result;
};

export const formatDateFull = (date) => {
  const currentDate = new Date(date);

  return new Intl.DateTimeFormat('id', { dateStyle: 'full' }).format(currentDate);
};

export const formatDateFullTime = (date) => {
  const currentDate = new Date(date);

  return new Intl.DateTimeFormat('id', { dateStyle: 'full', timeStyle: 'short' }).format(
    currentDate,
  );
};

export const formatShortFullDate = (date) => {
  const currentDate = new Date(date);

  return new Intl.DateTimeFormat('id', { day: '2-digit', month: 'short' }).format(currentDate);
};

export const formatDateFullTimeMediumStyle = (date) => {
  const currentDate = new Date(date);

  return new Intl.DateTimeFormat('id-ID', { dateStyle: 'full', timeStyle: 'medium' }).format(
    currentDate,
  );
};

export const momentDateShort = (date) => {
  // exp :22/12/2021
  if (!date) return '';
  return moment(date).format('DD/MM/YYYY');
};

export const formatHoursMinutes = (durations) => {
  let hours = Math.floor(durations / 60);
  let minutes = durations % 60;

  const minutesDate = (minutes) => {
    if (minutes === 0) {
      return ``;
    } else if (minutes !== 0) {
      return `${minutes} m`;
    }
  };

  const hoursDate = (hours) => {
    if (hours === 0) {
      return ``;
    } else if (hours !== 0) {
      return `${hours} j `;
    }
  };

  return hoursDate(hours) + minutesDate(minutes);
};

// timezone Indonesia dd/mm/yyyy
export const formatLocaleDateShort = (date) => {
  return new Date(date).toLocaleDateString('id-ID');
};

export const isDateExpired = (date) => {
  if (!date) return true;
  let endDate = new Date(date);
  let todayDate = new Date();
  return endDate < todayDate;
};
