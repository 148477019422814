import React, { useState, useEffect } from 'react';
import { GET_VOUCHER } from 'queries/voucher';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getValue, setContext } from 'utils';
import { useSelector } from 'react-redux';

import {
  ChangePlan,
  CheckboxContainer,
  CheckboxIcon,
  FieldVoucher,
  HeaderPaketDetail,
  PaketDetail,
  PointLabel,
  PriceContainer,
  PriceLabel,
  RingkasanPembayaranStyled,
  Separator,
  TitlePaket,
  TitlePembayaran,
  TotalText,
  ValidIcon,
  VoucherContainer,
  PointContainer,
} from './styled';

import check from 'assets/svg/checkbox/check.svg';
import uncheck from 'assets/svg/checkbox/uncheck.svg';
import MaxContainer from 'components/atoms/MaxContainer';
import { checkMobile } from 'utils/checkMobile';
import { useHistory } from 'react-router';
import FormError from 'components/atoms/FormError';
import { GET_USER_DETAIL } from 'queries/user';
import LanggananPrakerja from '../LanggananPrakerja';
import { LoaderIcon } from 'react-hot-toast';
import { t } from 'hooks/intl/useLang';

function RingkasanPembayaran({
  user,
  data,
  name,
  setVoucher,
  dataOrderByPoint,
  detailTraining,
  setDataOrderByPoint,
  setSelectedMethod,
  setDiscountVoucher,
  setPrice,
  createOrder,
  dataPrakerja,
  setDataPrakerja,
  errorVoucherPrakerja,
  setErrorVoucherPrakerja,
  loadingCreateOrder,
  dataOrder,
  setDataOrder,
  dataBundlingTraining,
}) {
  const [pointChecked, setPointChecked] = useState(false);
  const [paketName, setPaketName] = useState(null);
  const title = checkMobile() ? 'Ringkasan Pembayaran' : 'Pilih Metode Pembayaran';
  let timeoutId = null;
  const [point, setPoint] = useState(0);
  const [pointError, setPointError] = useState(null);
  const [messagePointError, setMessagePointError] = useState('');
  const [pointPrice, setPointPrice] = useState(0);
  const [validVoucher, setValidVoucher] = useState(false);
  const [voucherError, setVoucherError] = useState(null);
  const [codeVoucher, setCodeVoucher] = useState(null);
  const [messageVoucherError, setMessageVoucherError] = useState('');
  const [discountPrice, setDiscountPrice] = useState(0);

  const countPrice = () => {
    if (detailTraining) {
      return detailTraining?.training_price;
    } else if (dataBundlingTraining) {
      return dataBundlingTraining?.price;
    } else if (data?.price) {
      return data?.price;
    } else {
      return 0;
    }
  };
  let price = countPrice();
  let subsType = data?.package_type || 'Bronze';
  const authData = useSelector((state) => state.Auth);

  const history = useHistory();

  const { data: userDetail } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const [checkVoucher, { loading: loadingCheckVoucher }] = useLazyQuery(GET_VOUCHER, {
    context: setContext(authData),
    onCompleted: (dataVoucher) => {
      if (price === 9900) {
        setValidVoucher(false);
        setVoucher(null);
        setVoucherError(true);
        setDiscountPrice(0);
        setMessageVoucherError(t('Voucher tidak bisa digunakan'));
      } else if (dataVoucher?.Voucher) {
        if (
          dataVoucher.Voucher.specific_package.length > 0 &&
          !dataVoucher.Voucher.specific_package.includes(subsType)
        ) {
          setDiscountPrice(0);
          setValidVoucher(false);
          setVoucher(null);
          setVoucherError(true);
          setMessageVoucherError(t('Kode Voucher tidak valid'));
        } else {
          if (dataVoucher?.Voucher?.is_active && dataVoucher?.Voucher?.quota !== 0) {
            setValidVoucher(true);
            countDiscount(dataVoucher?.Voucher?.benefit_type, dataVoucher?.Voucher?.benefit_value);
            setVoucher(dataVoucher?.Voucher?.code);
            setVoucherError(false);
            if (dataVoucher?.Voucher?.is_partner_voucher) {
              setSelectedMethod('Partner Voucher');
              setDataOrder({
                ...dataOrder,
                paymentMethod: 'partner_voucher',
              });
            }

            if (
              dataVoucher.Voucher.benefit_value === '100' &&
              dataVoucher?.Voucher?.benefit_type === 'Percentage'
            ) {
              setSelectedMethod('Full Discount');
              setDataOrder({
                ...dataOrder,
                paymentMethod: 'full_discount',
              });
            }
          } else if (pointPrice !== 0) {
            setValidVoucher(false);
            setVoucher(null);
            setVoucherError(true);
            setDiscountPrice(0);
          } else {
            setDiscountPrice(0);
            setValidVoucher(false);
            setVoucher(null);
            setVoucherError(true);
            setMessageVoucherError(t('Kode Voucher tidak valid'));
          }
        }
      }
    },
    onError: (error) => {
      setValidVoucher(false);
      setDiscountPrice(0);
      setVoucher(null);
      setVoucherError(true);
      setMessageVoucherError(t('Kode Voucher tidak valid'));
      console.error(error.message);
    },
  });
  const countDiscount = (type, amount) => {
    switch (type) {
      case 'Percentage':
        setDiscountPrice(Math.floor((price * amount) / 100));
        return;
      default:
        setDiscountPrice(amount);
        return;
    }
  };
  const selectParams = async () => {
    switch (name) {
      case 'Bronze':
      case 'Silver':
      case 'Gold':
        await setPaketName(`${t('Paket')} ${name}`);
        break;
      case detailTraining?.slug:
        await setPaketName(`${detailTraining?.title}`);
        break;
      case 'Prakerja':
        await setPaketName('Prakerja');
        break;
      case dataBundlingTraining?.slug:
        await setPaketName(dataBundlingTraining?.name);
        break;
      default:
        // history.push('/home');
        break;
    }
  };

  useEffect(() => {
    selectParams();
  }, [name]);

  useEffect(() => {
    setPrice(price);

    if (pointChecked) {
      if (userDetail?.ProfileUser?.total_point > 0) {
        if (price === 9900) {
          setPointError(true);
          setMessagePointError(t('Point tidak bisa digunakan'));
        } else {
          switch (name) {
            case 'Monthly':
              if (userDetail?.ProfileUser?.total_point >= 100) {
                setPoint(100);
                setPointError(false);
                setPointPrice(price);
                setDiscountPrice(price);
                setSelectedMethod('Point');

                setDataOrderByPoint({
                  ...dataOrderByPoint,
                  paymentMethod: 'point',
                });
              } else {
                setPoint(userDetail?.ProfileUser?.total_point);
                setPointError(true);
                setMessagePointError(t('Point tidak cukup'));
              }
              break;
            case 'Semester':
              if (userDetail?.ProfileUser?.total_point >= 450) {
                setPoint(450);
                setPointError(false);
                setPointPrice(price);
                setDiscountPrice(price);
                setSelectedMethod('Point');
                setDataOrderByPoint({
                  ...dataOrderByPoint,
                  paymentMethod: 'point',
                });
              } else {
                setPoint(userDetail?.ProfileUser?.total_point);
                setPointError(true);
                setMessagePointError(t('Point tidak cukup'));
              }
              break;
            case 'Yearly':
              if (userDetail?.ProfileUser?.total_point >= 600) {
                setPoint(600);
                setPointError(false);
                setPointPrice(price);
                setDiscountPrice(price);
                setSelectedMethod('Point');
                setDataOrderByPoint({
                  ...dataOrderByPoint,
                  paymentMethod: 'point',
                });
              } else {
                setPoint(userDetail?.ProfileUser?.total_point);
                setPointError(true);
                setMessagePointError(t('Point tidak cukup'));
              }
              break;
            default:
              setPoint(userDetail?.ProfileUser?.total_point);
              setPointError(true);
              setMessagePointError(t('Point tidak cukup'));
              break;
          }
        }
      } else {
        setPoint(0);
        setPointError(true);
        setMessagePointError(t('Tidak ada point'));
      }
    }

    if (!pointChecked) {
      setPoint(0);
      setPointError(null);
      setPointPrice(0);
    }

    if (!codeVoucher) {
      setValidVoucher(false);
    }

    if (!pointChecked && !codeVoucher) {
      setDiscountPrice(0);
      setSelectedMethod('Transfer Virtual Account');
    }
    if (codeVoucher === '') {
      setVoucherError(false);
      setValidVoucher(false);
      setDiscountPrice(0);
      setCodeVoucher(null);
    }

    if (checkMobile() && codeVoucher) {
      checkVoucher({
        variables: {
          code: codeVoucher,
        },
      });
    }

    if (checkMobile() && validVoucher) {
      setDiscountVoucher(discountPrice);
    } else {
      setDiscountVoucher(null);
    }
  }, [codeVoucher, pointChecked, validVoucher, data?.price, detailTraining]);

  return (
    <MaxContainer>
      {paketName !== 'Prakerja' ? (
        <RingkasanPembayaranStyled>
          <TitlePembayaran>{title}</TitlePembayaran>
          <PaketDetail>
            <HeaderPaketDetail>
              <TitlePaket>{detailTraining ? detailTraining?.title : paketName}</TitlePaket>
              <ChangePlan
                onClick={() => {
                  if (detailTraining) {
                    history.push(`/training/${detailTraining?.slug}`);
                  } else {
                    history.push('/berlangganan');
                  }
                }}
              >
                {t('Ganti Plan')}
              </ChangePlan>
            </HeaderPaketDetail>
            <Separator mobile />
            <PriceContainer className="price-content">
              <PriceLabel>{t('Harga')}</PriceLabel>
              <div className="price-message">
                <PriceLabel>Rp {price?.toLocaleString('id')}</PriceLabel>
              </div>
            </PriceContainer>
            {/* <PointContainer>
              <PriceContainer className="point">
                <CheckboxContainer>
                  <CheckboxIcon
                    onClick={() => {
                      if (!validVoucher) {
                        setPointChecked(!pointChecked);
                      }
                    }}
                    src={pointChecked ? check : uncheck}
                  />
                  <PointLabel>Gunakan point:</PointLabel>
                  <PointLabel className={point > 0 ? 'point' : ''}>
                    {point > 0
                      ? point
                      : userDetail?.ProfileUser?.total_point > 0
                      ? userDetail?.ProfileUser?.total_point
                      : 'Tidak ada point'}
                  </PointLabel>
                </CheckboxContainer>
                <PriceLabel>- Rp {pointPrice > 0 ? pointPrice.toLocaleString('id') : 0}</PriceLabel>
              </PriceContainer>
              {pointError && <FormError lengkapiProfile>{messagePointError}</FormError>}
            </PointContainer> */}
            {!checkMobile() && (
              <>
                <PriceContainer className="input-voucher">
                  <VoucherContainer className={voucherError ? 'error' : ''}>
                    <FieldVoucher
                      placeholder="Tambah Kode Promo"
                      onChange={(val) => setCodeVoucher(val.target.value)}
                    />
                    {loadingCheckVoucher ? (
                      <div>
                        <LoaderIcon />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          if (!pointChecked) {
                            if (codeVoucher && codeVoucher.length >= 5) {
                              checkVoucher({
                                variables: {
                                  code: codeVoucher,
                                },
                              });
                            }
                          }
                        }}
                      >
                        {t('Cek')}
                      </div>
                    )}
                  </VoucherContainer>
                  <PriceLabel>
                    - Rp {codeVoucher && validVoucher ? discountPrice.toLocaleString('id') : 0}
                  </PriceLabel>
                </PriceContainer>
                {voucherError && <FormError lengkapiProfile>{messageVoucherError}</FormError>}
              </>
            )}
            {/* {checkMobile() && validVoucher && (
              <PriceContainer>
                <PriceLabel>Kode Voucher</PriceLabel>
                <PriceLabel>
                  - Rp {codeVoucher && validVoucher ? discountPrice.toLocaleString('id') : 0}
                </PriceLabel>
              </PriceContainer>
            )} */}
            <Separator />
            <PriceContainer>
              <PriceLabel>{t('Total Tagihan')}</PriceLabel>
              {paketName !== 'Prakerja' && (
                <TotalText>
                  Rp{' '}
                  {discountPrice > 0
                    ? (price - discountPrice)?.toLocaleString('id')
                    : price?.toLocaleString('id')}
                </TotalText>
              )}
            </PriceContainer>
          </PaketDetail>
          {checkMobile() && (
            <>
              <VoucherContainer className={voucherError ? 'error' : ''}>
                <FieldVoucher
                  onChange={(val) => {
                    if (timeoutId) clearTimeout(timeoutId);
                    timeoutId = setTimeout(() => {
                      setCodeVoucher(val.target.value);
                    }, 1000);
                  }}
                  placeholder="Tambah Kode Voucher"
                />
                {validVoucher && <ValidIcon />}
              </VoucherContainer>
              {voucherError && <FormError lengkapiProfile>{messageVoucherError}</FormError>}
            </>
          )}
        </RingkasanPembayaranStyled>
      ) : (
        // If Prakerja was chosen
        <LanggananPrakerja
          createOrder={createOrder}
          dataPrakerja={dataPrakerja}
          setDataPrakerja={setDataPrakerja}
          user={user}
          errorVoucherPrakerja={errorVoucherPrakerja}
          setErrorVoucherPrakerja={setErrorVoucherPrakerja}
          loadingCreateOrder={loadingCreateOrder}
          isToken={true}
        />
      )}
    </MaxContainer>
  );
}

export default RingkasanPembayaran;
