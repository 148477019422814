import { components } from 'react-select';

const Menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 3 ? props.children : <div style={{ margin: 15 }}>Maksimal 3</div>}
    </components.Menu>
  );
};

export default Menu;
