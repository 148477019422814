import Button from 'components/atoms/Button';
import React from 'react';
import { Container, Message } from './styled';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { t } from 'hooks/intl/useLang';

const DownloadAppMobile = ({ setModalBar, modalBar, androidDevice, appleDevice }) => {
  const hideBar = () => {
    setModalBar(false);
  };

  const toStore = () => {
    if (androidDevice) {
      window.open('https://play.google.com/store/apps/details?id=com.terampil.app');
    }
    if (appleDevice) {
    }
  };

  return (
    <Container show={modalBar}>
      <div className="wrapper-icon" onClick={hideBar}>
        <CloseIcon />
      </div>
      <Message>{t('Menonton sambil belajar lebih nyaman dan mudah melalui aplikasi Terampil')}</Message>
      <Button onClick={toStore}>Download</Button>
    </Container>
  );
};

export default DownloadAppMobile;
