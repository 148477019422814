import styled from 'styled-components';
import Modal from 'react-modal';

Modal.defaultStyles.overlay.zIndex = 99999;
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';
Modal.defaultStyles.overlay.zIndex = '100';

export const StyledModal = styled(Modal)`
  border-radius: 5px;
  background-color: ${({ dark, green }) => (dark ? '#202020' : green ? '#266960' : 'white')};
  position: absolute;
  display: flex;
  width: ${(props) => (props.medium ? '500px' : '448px')};
  ${({ large }) => large && 'width: 100vw;'};
  ${({ UpDownGrade }) => UpDownGrade && 'width: 700px;'};
  ${({ videoTraining }) => videoTraining && 'width: 50%;'};
  box-sizing: border-box;
  flex-direction: column;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    ${({ resizedWidth }) => resizedWidth && 'width: 100%;'};
    ${({ resizedHeight }) => resizedHeight && 'height: 100%;'};
  }

  padding: ${({ dark, lengkapiProfile }) => (dark ? (lengkapiProfile ? '24px' : '0') : '24px')};
  z-index: 10000;

  :focus {
    outline: none;
  }

  .close-button {
    position: absolute;
    top: 20px;
    ${({ review }) => review && 'top: 50px;'};
    right: 20px;
    cursor: pointer;
    fill: ${({ dark, green }) => (dark || green ? '#fdfdfd' : '#000004')};
  }

  .dark {
    background: #202020;
  }

  &.ReactModal__Overlay,
  .ReactModal__Overlay {
    opacity: 0;
    transform: translateY(50px);
    transition: all 400ms ease-in-out;
  }

  &.ReactModal__Overlay--after-open,
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0px);
  }

  &.ReactModal__Overlay--before-close,
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(50px);
  }

  &.w-700 {
    width: 700px;
    padding: 32px;
    padding-bottom: 24px;

    & .close-button {
      top: 36px;
    }
  }

  &.w-80 {
    width: 80%;
    padding-top: 50px;
    background: #202020;
  }

  @media only screen and (max-width: 577px) {
    width: 90%;
    height: auto;
    ${({ resizedWidth }) => resizedWidth && 'width: 100%;'};
    ${({ resizedHeight }) => resizedHeight && 'height: 100%;'};
  }
`;
