import React from 'react';
import { Content, Title, Paragraf } from './styled';
import { t } from 'hooks/intl/useLang';

function KebijakanPrivasi() {
  const desc = {
    kebijakan1:
      'Kebijakan Privasi ini berlaku jika Anda menggunakan Layanan kami. Kami menawarkan pilihan kepada pengguna tentang data yang kami kumpulkan, gunakan, dan bagikan sebagaimana dijelaskan dalam Kebijakan Privasi ini, Pengaturan, dan Pusat Bantuan kami.',
    kebijakan2:
      'Terampil dapat memodifikasi Kebijakan Privasi ini, dan jika kami melakukan perubahan material terhadap kebijakan ini, kami akan menyampaikan pemberitahuan melalui Layanan, atau melalui cara lain, guna memberi Anda kesempatan untuk meninjau perubahan tersebut sebelum diberlakukan. Jika Anda tidak menyetujui perubahan ini, Anda dapat menutup akun.',
    kebijakan3:
      'Anda menyatakan bahwa jika Anda meneruskan penggunaan Layanan kami setelah kami memublikasikan atau mengirim pemberitahuan tentang perubahan terhadap Kebijakan Privasi ini, pengumpulan, penggunaan, dan pembagian data pribadi Anda diatur oleh Kebijakan Privasi yang diperbarui ini, dimulai sejak tanggal berlakunya.',
  };

  return (
    <>
      <Content>
        <Paragraf>{desc.kebijakan1}</Paragraf>
        <Paragraf>{desc.kebijakan2}</Paragraf>
        <Paragraf>{desc.kebijakan3}</Paragraf>
      </Content>
      <Content>
        <Title>{t('Data yang Anda Berikan kepada Kami')}</Title>
        <Title>1. {t('Pendaftaran')}</Title>
        <Paragraf>
          {t('Untuk membuat akun, Anda perlu memberikan data yang mencakup nama, alamat email dan/atau nomor ponsel, dan kata sandi. Jika Anda berlangganan, Anda perlu memberikan informasi pembayaran (misalnya, kartu kredit) dan penagihan.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>2. {t('Profil')}</Title>
        <Paragraf>
          {t('Anda memiliki pilihan tentang informasi yang ada di profil, seperti pengalaman kerja, kategori training, foto dan rekomendasi training. Informasi profil akan membantu Anda mendapatkan lebih banyak manfaat dari Layanan kami.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>3. {t('Penggunaan Layanan')}</Title>
        <Paragraf>
          {t('Kami mencatat data penggunaan ketika Anda mengunjungi atau dengan cara lain menggunakan Layanan kami, termasuk situs, aplikasi, dan teknologi platform, seperti ketika Anda melihat atau mengklik konten (misalnya, video training), melakukan pencarian, menginstal atau memperbarui salah satu aplikasi seluler kami, memberikan link training. Kami menggunakan informasi login, perangkat, dan alamat protokol internet (“IP”) untuk mengidentifikasi dan mencatat penggunaan Anda.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>4. {t('Lainnya')}</Title>
        <Paragraf>
          {t('Layanan kami bersifat dinamis dan kami sering memperkenalkan fitur baru yang mungkin membutuhkan pengumpulan informasi baru. Jika kami mengumpulkan data pribadi yang materinya berbeda atau melakukan perubahan material dalam cara kami mengumpulkan, menggunakan, atau membagikan data Anda, kami akan memberitahukan Anda dan mungkin memodifikasi Kebijakan Privasi ini juga.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>{t('Cara Kami Menggunakan Data Anda')}</Title>
        <Paragraf>
          {t('Kami menggunakan data yang kami miliki tentang Anda untuk menyediakan dan mempersonalisasi Layanan, termasuk dengan bantuan sistem otomatis dan inferensi yang kami buat, agar Layanan kami menjadi lebih relevan dan berguna bagi Anda.')}
        </Paragraf>
        <Paragraf>
          {t('Dengan Layanan kami, Anda dapat terus mengikuti informasi terbaru tentang training terkait yang penting bagi Anda, serta dari trainer yang direkomendasikan. Dengan Layanan kami, Anda juga dapat meningkatkan keahlian profesional atau mempelajari keahlian baru. Kami menggunakan data yang kami miliki tentang Anda (misalnya data yang Anda berikan, data yang kami kumpulkan dari interaksi Anda dengan Layanan dan inferensi yang kami buat dari data yang kami miliki tentang Anda) untuk mempersonalisasikan Layanan kami untuk Anda, misalnya dengan merekomendasikan atau memberikan peringkat kepada konten training dan percakapan forum yang relevan di Layanan kami.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>{t('Komunikasi')}</Title>
        <Paragraf>
          {t('Kami akan menghubungi Anda melalui email, telepon seluler atau melalui cara lain dalam Layanan kami, termasuk SMS dan notifikasi push. Kami akan mengirimi Anda pesan mengenai ketersediaan Layanan, keamanan, atau masalah lain yang berhubungan dengan layanan. Kami juga mengirimkan pesan mengenai cara menggunakan Layanan, pembaruan jaringan, pengingat dan pesan promosi dari kami. Anda dapat mengubah preferensi komunikasi kapan saja. Harap perhatikan bahwa Anda tidak dapat menolak menerima layanan pesan dari kami, termasuk pemberitahuan keamanan.Kami juga memungkinkan komunikasi antara Anda dan orang lain melalui Layanan kami, termasuk misalnya diskusi forum.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>{t('Pengembangan Layanan')}</Title>
        <Paragraf>
          {t('Kami menggunakan data, termasuk masukan publik, untuk melakukan riset dan pengembangan untuk Layanan agar kami dapat menyediakan pengalaman yang lebih baik, lebih intuitif, dan lebih personal kepada Anda dan orang lain.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>{t('Layanan Pelanggan')}</Title>
        <Paragraf>
          {t('Kami menggunakan data (yang mungkin mencakup komunikasi Anda) untuk menyelidiki, menanggapi, dan menyelesaikan keluhan dan masalah Layanan (misalnya bug).')}
        </Paragraf>
      </Content>
      <Content>
        <Title>{t('Data Analitik')}</Title>
        <Paragraf>
          {t('Kami menggunakan data Anda untuk membuat data analitik. Contohnya, kami mungkin menggunakan data Anda untuk membuat statistik mengenai pengguna, profesi atau industri mereka, untuk mengkalkulasi halaman yang sering dikunjungi atau untuk memublikasikan demografi pengunjung.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>{t('Keamanan')}</Title>
        <Paragraf>
          {t('Kami menggunakan data untuk keamanan, pencegahan penipuan, dan penyelidikan.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>{t('Penyimpanan Data')}</Title>
        <Paragraf>
          {t('Pada umumnya kami menyimpan data pribadi Anda selama akun Anda tetap aktif atau selama dibutuhkan untuk menyediakan Layanan kepada Anda. Dalam beberapa kasus, kami memilih untuk mempertahankan informasi tertentu (misalnya wawasan tentang Layanan yang Anda gunakan) dalam bentuk umum atau agregat.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>{t('Hak Mengakses dan Mengontrol Data Pribadi')}</Title>
        <Paragraf>
          {t('Anda dapat mengakses dan mengelola data pribadi anda yang kami miliki, seperti')}:
        </Paragraf>
        <Title>1. {t('Menghapus Data')}</Title>
        <Paragraf>
          {t('Anda dapat menghapus semua data pribadi Anda (misalnya, jika Anda sudah tidak memerlukan Layanan kami).')}
        </Paragraf>
        <Title>2. {t('Mengubah atau Mengoreksi Data')}</Title>
        <Paragraf>
          {t('Anda dapat mengedit beberapa data pribadi melalui akun Anda. Anda juga dapat meminta kami mengubah, memperbarui, atau memperbaiki data Anda dalam kasus tertentu, terutama jika data tidak akurat.')}
        </Paragraf>
      </Content>
      <Content>
        <Title>{t('Penutupan Akun')}</Title>
        <Paragraf>
          {t('Jika Anda memilih untuk menutup akun, Kami tetap menyimpan data pribadi Anda. Hal tersebut diperlukan secara wajar untuk menyelesaikan perselisihan, mempertahankan keamanan, mencegah penipuan dan penyalahgunaan.')}
        </Paragraf>
      </Content>
    </>
  );
}

export default KebijakanPrivasi;
