/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { CardContainer, InsideBox, ResultBar, ProgresInside } from './styled';
import StarRating from 'react-star-ratings';
import { ReactComponent as Star } from 'assets/svg/trainingDetails/star.svg';
import ProgressRating from 'components/atoms/ProgressRating';
import { phonesize } from 'utils/checkMobile';
import { useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_DETAIL_TRAINING, GET_TRAINING_RATING, LIST_REVIEW_TRAINING } from 'queries/training';
import { t } from 'hooks/intl/useLang';
const RatingUlasan = () => {
  const [isMobile, seIsMobile] = useState(false);
  const [RatingS, setRatingS] = useState(0);

  const { slug } = useParams();

  const { data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    variables: {
      input: slug,
    },
    onCompleted: (data) => {
      getReviewTraining({ variables: { input: { trainingId: data?.Training?.id } } });
      getTrainingRating({ variables: { input: data?.Training?.id } });
    },
  });

  const [getReviewTraining, { data: listReviewTraining }] = useLazyQuery(LIST_REVIEW_TRAINING);

  const [getTrainingRating, { data: listTrainingRating }] = useLazyQuery(GET_TRAINING_RATING);

  useEffect(() => {
    seIsMobile(phonesize());

    if (listTrainingRating) {
      setRatingS(listTrainingRating?.TrainingRating);
    }
  }, [listTrainingRating]);

  return (
    <CardContainer>
      <InsideBox>
        <h2>
          {RatingS?.star ? RatingS?.star.toFixed(1) : 5}
          <span>/5</span>
        </h2>
        <StarRating
          rating={RatingS?.star ? RatingS?.star : 5}
          starRatedColor="#F5A52F"
          changeRating={false}
          numberOfStars={5}
          name="rating"
          starDimension={!isMobile ? '26px' : '15px'}
          svgIconPath="M26.3775 9.03429L18.4431 7.88116L14.8962 0.690539C14.7993 0.493664 14.64 0.334289 14.4431 0.237414C13.9493 -0.00633608 13.3493 0.196789 13.1025 0.690539L9.55558 7.88116L1.6212 9.03429C1.40245 9.06554 1.20245 9.16866 1.04933 9.32491C0.864207 9.51518 0.762197 9.77116 0.765713 10.0366C0.769229 10.302 0.877982 10.5552 1.06808 10.7405L6.8087 16.3374L5.45245 24.2405C5.42065 24.4244 5.44099 24.6135 5.51118 24.7863C5.58136 24.9592 5.69858 25.1089 5.84954 25.2186C6.0005 25.3282 6.17916 25.3934 6.36526 25.4066C6.55136 25.4199 6.73746 25.3808 6.90245 25.2937L13.9993 21.5624L21.0962 25.2937C21.29 25.3968 21.515 25.4312 21.7306 25.3937C22.2743 25.2999 22.64 24.7843 22.5462 24.2405L21.19 16.3374L26.9306 10.7405C27.0868 10.5874 27.19 10.3874 27.2212 10.1687C27.3056 9.62179 26.9243 9.11554 26.3775 9.03429Z"
          svgIconViewBox="0 0 28 26"
          starEmptyColor="#202020"
          starSpacing={!isMobile ? '7px' : '2px'}
        />
        <p>
          {listReviewTraining?.TrainingTestimonies
            ? listReviewTraining?.TrainingTestimonies?.total
            : 0}{' '}
          {t('Ulasan')}
        </p>
      </InsideBox>
      <ResultBar>
        <Star className="small" />
        <p className="ulasan">5</p>
        <ProgresInside>
          <ProgressRating
            totalUlasan={RatingS?.rating5 ? RatingS?.rating5 : 0}
            percentage={RatingS?.rating5 ? (RatingS?.rating5 / RatingS?.totalTestimony) * 100 : 0}
          />
        </ProgresInside>
        <Star />
        <p className="ulasan">4</p>
        <ProgresInside>
          <ProgressRating
            totalUlasan={RatingS?.rating4 ? RatingS?.rating4 : 0}
            percentage={RatingS?.rating4 ? (RatingS?.rating4 / RatingS?.totalTestimony) * 100 : 0}
          />
        </ProgresInside>
        <Star />
        <p className="ulasan">3</p>
        <ProgresInside>
          <ProgressRating
            totalUlasan={RatingS?.rating3 ? RatingS?.rating3 : 0}
            percentage={RatingS?.rating3 ? (RatingS?.rating3 / RatingS?.totalTestimony) * 100 : 0}
          />
        </ProgresInside>
        <Star />
        <p className="ulasan">2</p>
        <ProgresInside>
          <ProgressRating
            totalUlasan={RatingS?.rating2 ? RatingS?.rating2 : 0}
            percentage={RatingS?.rating2 ? (RatingS?.rating2 / RatingS?.totalTestimony) * 100 : 0}
          />
        </ProgresInside>
        <Star />
        <p className="ulasan">1</p>
        <ProgresInside>
          <ProgressRating
            totalUlasan={RatingS?.rating1 ? RatingS?.rating1 : 0}
            percentage={RatingS?.rating1 ? (RatingS?.rating1 / RatingS?.totalTestimony) * 100 : 0}
          />
        </ProgresInside>
      </ResultBar>
    </CardContainer>
  );
};

export default RatingUlasan;
