import React from 'react';
import {
  Container,
  FilledProgress,
  ProgressBar,
  ProgressPercentage,
} from './ProgressTraining.styled';

function ProgressTraining({ percetage }) {
  return (
    <Container>
      <ProgressBar>
        <FilledProgress percentage={percetage > 100 ? 100 : percetage} />
      </ProgressBar>
      <ProgressPercentage>{percetage}%</ProgressPercentage>
    </Container>
  );
}

export default ProgressTraining;
