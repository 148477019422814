/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import StarRating from 'react-star-ratings';
import { useMutation, useQuery } from '@apollo/client';
import { checkMobile } from 'utils/checkMobile';
import {
  ADD_TRAINER_TESTIMONY,
  ADD_TRAINING_TESTIMONY,
  UPDATE_TRAINER_TESTIMONY,
  UPDATE_TRAINING_TESTIMONY,
} from 'mutation/training';
import toast, { LoaderIcon } from 'react-hot-toast';
import { getValue, setContext } from 'utils';
import { useSelector } from 'react-redux';
import { GET_DETAIL_TRAINING, GET_TRAINER_TESTIMONY, LIST_REVIEW_TRAINING } from 'queries/training';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { GET_USER_DETAIL } from 'queries/user';
import {
  checkRatedTrainer,
  checkRatedTraining,
  checkReviewTrainer,
  checkReviewTraining,
} from 'utils/checkReviewCondition';

import PopupModal from 'components/atoms/Modal';
import {
  Title,
  Separator,
  Container,
  CardProfile,
  Profile,
  Rating,
  Avatar,
  TitleTraining,
  Wrapper,
  ProfessionTrainer,
  AboutTrainer,
  InputContainer,
  HeaderTitle,
} from './styled';
import InputField from 'components/atoms/InputField';
import Button from 'components/atoms/Button';
import Toast from 'components/atoms/Notification/Toast';

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import defaultUserPic from 'assets/svg/User Default.svg';
import { t } from 'hooks/intl/useLang';

const BeriUlasanModal = ({
  setShowModal,
  setSendRate,
  selected,
  item,
  isValidId,
  isGiveReview,
  isTestimonyId,
  testiId,
  onRequestClose,
  ...rest
}) => {
  const [rated, setRated] = useState(0);
  const [review, setReview] = useState('');
  const [ratedTrainer, setRatedTrainer] = useState(0);
  const [reviewTrainer, setReviewTrainer] = useState('');
  const { handleSubmit, register, control } = useForm();
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const { slug } = useParams();

  const { data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    context: setContext(authData),
    variables: {
      input: slug,
    },
  });

  const { data: userTraining } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  //GET LIST TESTIMONY
  const { data: listReviewTrainingAll } = useQuery(LIST_REVIEW_TRAINING, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        orderBy: 'created_at',
        limit: 9999999,
      },
    },
  });

  //GET TRAINER TESTIMONY
  const { data: getTrainerTestimony } = useQuery(GET_TRAINER_TESTIMONY, {
    fetchPolicy: 'no-cache',
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        trainerId: detailTraining?.Training?.trainer?.id,
      },
    },
  });

  let checkTrainerRating = getTrainerTestimony?.TrainerTestimony?.rating;
  let checkTrainerReview = getTrainerTestimony?.TrainerTestimony?.text;
  let checkMyId = [];
  let checkMyRating = [];
  let checkMyReview = [];
  let checkIsGetIdReview = [];
  let isGiveTrainerReview = getTrainerTestimony?.TrainerTestimony === null;

  const isMyId = userTraining?.ProfileUser?.user_id;
  const isTestimonyAll = listReviewTrainingAll?.TrainingTestimonies?.items;

  //check rating that given by user
  const getUserId =
    isTestimonyAll &&
    isTestimonyAll.map((n, i) => {
      return checkMyId.push(n?.profile?.user_id);
    });

  const getReviewRating =
    isTestimonyAll &&
    isTestimonyAll.map((item, i) => {
      if (item?.profile?.user_id === isMyId) {
        return parseInt(checkMyRating.push(item?.rating));
      }
    });

  const getReviewText =
    isTestimonyAll &&
    isTestimonyAll.map((item, i) => {
      if (item?.profile?.user_id === isMyId) {
        return checkMyReview.push(item?.text).toString();
      }
    });

  //check if user has give trainer review or not
  const getUserTrainerReview =
    isTestimonyAll &&
    isTestimonyAll.map((item, i) => {
      if (item?.profile?.user_id === isMyId) {
        return checkIsGetIdReview.push(item?.id);
      }
    });

  //check if the user is already give training review or not
  let validId = checkMyId.includes(isMyId);

  //state for update training review
  const [editRated, setEditRated] = useState(parseInt(checkMyRating));
  const [editReview, setEditReview] = useState(checkMyReview.toString());

  //state for update trainer review
  const [editRatedTrainer, setEditRatedTrainer] = useState(checkTrainerRating);
  const [editReviewTrainer, setEditReviewTrainer] = useState(checkTrainerReview);

  //ADD TRAINING TESTIMONY
  const [addTrainingTestimony, { loading: trainingLoading }] = useMutation(ADD_TRAINING_TESTIMONY, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        text: review,
        rating: rated,
      },
    },
    refetchQueries: [
      {
        query: LIST_REVIEW_TRAINING,
        context: setContext(authData),
        variables: {
          input: {
            trainingId: detailTraining?.Training?.id,
            orderBy: 'created_at',
            limit: 99999,
          },
        },
      },
    ],
    onCompleted: () => {
      toast(t('Beri ulasan untuk modul berhasil'));
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  //ADD TRAINER TESTIMONY
  const [addTrainerTestimony, { loading: trainerLoading }] = useMutation(ADD_TRAINER_TESTIMONY, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        trainerId: detailTraining?.Training?.trainer?.id,
        text: reviewTrainer,
        rating: ratedTrainer,
      },
    },
    onCompleted: () => {
      toast(t('Beri ulasan untuk trainer berhasil'));
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  //UPDATE TRAINING TESTIMONY
  const [updateTrainingTestimony, { loading: updateLoadingTraining }] = useMutation(
    UPDATE_TRAINING_TESTIMONY,
    {
      context: setContext(authData),
      variables: {
        input: {
          trainingId: detailTraining?.Training?.id,
          text: editReview ? editReview : checkMyReview.toString(),
          rating: editRated ? editRated : parseInt(checkMyRating),
          testimonyId: isTestimonyId,
        },
      },
      refetchQueries: [
        {
          query: LIST_REVIEW_TRAINING,
          context: setContext(authData),
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              orderBy: 'created_at',
              limit: 99999,
            },
          },
        },
      ],
      onCompleted: () => {
        toast(t('Ulasan untuk modul berhasil diperbarui'));
      },
      onError: (error) => {
        console.error(error.message);
      },
    },
  );

  //UPDATE TRAINER TESTIMONY
  const [updateTrainerTestimony, { loading: updateLoadingTrainer }] = useMutation(
    UPDATE_TRAINER_TESTIMONY,
    {
      context: setContext(authData),
      variables: {
        input: {
          trainerId: detailTraining?.Training?.trainer?.id,
          text: editReviewTrainer ? editReviewTrainer : checkMyReview.toString(),
          rating: editRatedTrainer ? editRatedTrainer : parseInt(checkMyRating),
          testimonyId: getTrainerTestimony?.TrainerTestimony?.id,
        },
      },
      refetchQueries: [
        {
          query: LIST_REVIEW_TRAINING,
          context: setContext(authData),
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              orderBy: 'created_at',
              limit: 10,
            },
          },
        },
      ],
      onCompleted: () => {
        toast(t('Ulasan untuk Trainer berhasil diperbarui'));
        setTimeout(() => {
          setShowModal(false);
        }, 2000);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
      onError: (error) => {
        console.error(error.message);
      },
    },
  );

  const submitForm = ({ commentTraining, commentTrainer, ratingTrainer, ratingTraining }) => {
    const rateTraining = checkRatedTraining(validId, rated, editRated, checkMyRating);
    const rateTrainer = checkRatedTrainer(
      isGiveTrainerReview,
      ratedTrainer,
      editRatedTrainer,
      checkTrainerRating,
    );

    // Check if already review training
    if (!isGiveTrainerReview && commentTrainer && rateTraining) {
      updateTrainerTestimony({
        variables: {
          input: {
            trainerId: detailTraining?.Training?.trainer?.id,
            text: commentTrainer || '',
            rating: ratingTrainer || 5,
            testimonyId: getTrainerTestimony?.TrainerTestimony?.id,
          },
        },
      });
    } else if (commentTrainer && ratingTrainer) {
      addTrainerTestimony({
        variables: {
          input: {
            trainingId: detailTraining?.Training?.id,
            trainerId: detailTraining?.Training?.trainer?.id,
            text: commentTrainer || '',
            rating: ratingTrainer || 5,
          },
        },
      });
    }

    // Check if already review trainer
    if (validId && commentTraining && rateTrainer) {
      updateTrainingTestimony({
        variables: {
          input: {
            trainingId: detailTraining?.Training?.id,
            testimonyId: isTestimonyId,
            text: commentTraining || '',
            rating: ratingTraining || 5,
          },
        },
      });
    } else if (commentTraining && ratingTraining) {
      addTrainingTestimony({
        variables: {
          input: {
            trainingId: detailTraining?.Training?.id,
            text: commentTraining || '',
            rating: ratingTraining || 5,
          },
        },
      });
    }
  };

  return (
    <>
      <Toast position="top-right" />
      <PopupModal resizedHeight resizedWidth large review dark withoutCloseButton {...rest}>
        <Container>
          {!checkMobile() && (
            <div className="close-icon">
              <CloseIcon onClick={onRequestClose} />
            </div>
          )}
          {checkMobile() && (
            <div className="wrap-headertitle">
              <div className="wrap-headercontent">
                <CloseIcon onClick={onRequestClose} />
                <HeaderTitle className="header-giverating">{t('Beri Ulasan')}</HeaderTitle>
              </div>
              <span onClick={() => submitForm()} className="header-save">
                {t('Simpan')}
              </span>
            </div>
          )}
          <Title>{!validId ? t('Beri Ulasan untuk Modul') : t('Perbarui Ulasan untuk Modul')}</Title>
          <form onSubmit={handleSubmit(submitForm)}>
            <Wrapper>
              <CardProfile>
                <Profile>
                  <Avatar image={item?.thumbnail ? item?.thumbnail : defaultUserPic} />
                  <AboutTrainer>
                    <TitleTraining>{item?.title ? item?.title : '...'}</TitleTraining>
                  </AboutTrainer>
                </Profile>
                <Separator />
                <Rating>
                  {checkMobile() && <span className="give-rating">{t('Beri Penilaian')}</span>}
                  <Controller
                    control={control}
                    name="ratingTraining"
                    render={({ onChange, value }) => (
                      <StarRating
                        rating={
                          value || checkRatedTraining(validId, rated, editRated, checkMyRating)
                        }
                        starRatedColor="#F5A52F"
                        changeRating={onChange}
                        numberOfStars={5}
                        starHoverColor="#F5A52F"
                        name="rating"
                        starDimension={!checkMobile() ? '26px' : '20px'}
                        svgIconPath="M26.3775 9.03429L18.4431 7.88116L14.8962 0.690539C14.7993 0.493664 14.64 0.334289 14.4431 0.237414C13.9493 -0.00633608 13.3493 0.196789 13.1025 0.690539L9.55558 7.88116L1.6212 9.03429C1.40245 9.06554 1.20245 9.16866 1.04933 9.32491C0.864207 9.51518 0.762197 9.77116 0.765713 10.0366C0.769229 10.302 0.877982 10.5552 1.06808 10.7405L6.8087 16.3374L5.45245 24.2405C5.42065 24.4244 5.44099 24.6135 5.51118 24.7863C5.58136 24.9592 5.69858 25.1089 5.84954 25.2186C6.0005 25.3282 6.17916 25.3934 6.36526 25.4066C6.55136 25.4199 6.73746 25.3808 6.90245 25.2937L13.9993 21.5624L21.0962 25.2937C21.29 25.3968 21.515 25.4312 21.7306 25.3937C22.2743 25.2999 22.64 24.7843 22.5462 24.2405L21.19 16.3374L26.9306 10.7405C27.0868 10.5874 27.19 10.3874 27.2212 10.1687C27.3056 9.62179 26.9243 9.11554 26.3775 9.03429Z"
                        svgIconViewBox="0 0 28 26"
                        starEmptyColor="grey"
                        starSpacing={!checkMobile() ? '7px' : '2px'}
                      />
                    )}
                  />
                </Rating>
                <Separator />
              </CardProfile>
              <InputContainer>
                <InputField
                  required
                  dark={true}
                  defaultValue={checkReviewTraining(validId, review, editReview, checkMyReview)}
                  className="input-comment"
                  name="commentTraining"
                  placeholder={t("Tulis ulasan kamu untuk training disini...")}
                  register={register({
                    pattern: {
                      value: /^[\w\-\s]+$/,
                      message: 'Entered value does not match name format',
                    },
                  })}
                />
                <div>
                  {!checkMobile && (
                    <>
                      {trainingLoading ? (
                        <LoaderIcon className="loading-icon" />
                      ) : updateLoadingTraining ? (
                        <LoaderIcon className="loading-icon" />
                      ) : (
                        <Button id="rated-training" type="submit" small>
                          {t('Kirim')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </InputContainer>
            </Wrapper>
            <Title>
              {isGiveTrainerReview ? t('Beri Ulasan untuk Trainer') : t('Perbarui Ulasan untuk Trainer')}
            </Title>
            <Wrapper>
              <CardProfile>
                <Profile>
                  <Avatar image={item?.trainer?.avatar ? item?.trainer?.avatar : defaultUserPic} />
                  <AboutTrainer>
                    <TitleTraining>
                      {item?.trainer?.fullname ? item?.trainer?.fullname : null}
                    </TitleTraining>
                    {!checkMobile && (
                      <ProfessionTrainer>{item?.trainer?.profession}</ProfessionTrainer>
                    )}
                  </AboutTrainer>
                </Profile>
                <Separator />
                <Rating>
                  {checkMobile() && <span className="give-rating">{t('Beri Penilaian')}</span>}
                  <Controller
                    control={control}
                    name="ratingTrainer"
                    render={({ onChange, value }) => (
                      <StarRating
                        rating={
                          value ||
                          checkRatedTrainer(
                            isGiveTrainerReview,
                            ratedTrainer,
                            editRatedTrainer,
                            checkTrainerRating,
                          )
                        }
                        starRatedColor="#F5A52F"
                        changeRating={onChange}
                        numberOfStars={5}
                        starHoverColor="#F5A52F"
                        name="rating"
                        starDimension={!checkMobile() ? '26px' : '20px'}
                        svgIconPath="M26.3775 9.03429L18.4431 7.88116L14.8962 0.690539C14.7993 0.493664 14.64 0.334289 14.4431 0.237414C13.9493 -0.00633608 13.3493 0.196789 13.1025 0.690539L9.55558 7.88116L1.6212 9.03429C1.40245 9.06554 1.20245 9.16866 1.04933 9.32491C0.864207 9.51518 0.762197 9.77116 0.765713 10.0366C0.769229 10.302 0.877982 10.5552 1.06808 10.7405L6.8087 16.3374L5.45245 24.2405C5.42065 24.4244 5.44099 24.6135 5.51118 24.7863C5.58136 24.9592 5.69858 25.1089 5.84954 25.2186C6.0005 25.3282 6.17916 25.3934 6.36526 25.4066C6.55136 25.4199 6.73746 25.3808 6.90245 25.2937L13.9993 21.5624L21.0962 25.2937C21.29 25.3968 21.515 25.4312 21.7306 25.3937C22.2743 25.2999 22.64 24.7843 22.5462 24.2405L21.19 16.3374L26.9306 10.7405C27.0868 10.5874 27.19 10.3874 27.2212 10.1687C27.3056 9.62179 26.9243 9.11554 26.3775 9.03429Z"
                        svgIconViewBox="0 0 28 26"
                        starEmptyColor="grey"
                        starSpacing={!checkMobile() ? '7px' : '2px'}
                      />
                    )}
                  />
                </Rating>
              </CardProfile>
              <InputContainer>
                <InputField
                  required
                  dark={true}
                  defaultValue={checkReviewTrainer(
                    isGiveTrainerReview,
                    reviewTrainer,
                    editReviewTrainer,
                    checkTrainerReview,
                  )}
                  name="commentTrainer"
                  className="input-comment"
                  placeholder={t("Tulis ulasan kamu untuk trainer disini...")}
                  register={register({
                    pattern: {
                      value: /^[\w\-\s]+$/,
                      message: 'Entered value does not match name format',
                    },
                  })}
                />
                <div>
                  {!checkMobile() && (
                    <>
                      {trainerLoading ? (
                        <LoaderIcon className="loading-icon" />
                      ) : updateLoadingTrainer ? (
                        <LoaderIcon className="loading-icon" />
                      ) : (
                        <Button id="rated-trainer" type="submit" small>
                          {t('Kirim')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </InputContainer>
            </Wrapper>
          </form>
        </Container>
      </PopupModal>
    </>
  );
};

export default BeriUlasanModal;
