import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button';

export const Container = styled.div`
  margin-top: 50px;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    margin-bottom: 5px;
    color: #c4c4c4;
  }

  .flex {
    display: flex;
    justify-content: center;
    gap: 0px;
    margin: 0;
  }

  .svg {
    cursor: pointer;
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    & > p {
      font-size: 12px;
      line-height: 15px;
    }

    .flex {
      gap: 5px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  text-align: left;
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 500px;
  background-image: url('${({ image }) => image}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin: 16px 0;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 320px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    height: 160px;
  }
`;

export const Subtitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #fdfdfd;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #c4c4c4;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ContainerContain = styled.div`
  margin-bottom: 45px;
`;

export const Button = styled(ButtonStyled)`
  width: 500px;
  height: 50px;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
  }
`;
