import React from 'react';

import bca from 'assets/png/bank/image 15.png';
import bni from 'assets/png/bank/image 16.png';
import mandiri from 'assets/png/bank/image 34.png';
import permata from 'assets/png/bank/image 35.png';
import { GridMenus, TitleMobile, TransferVAStyled } from './styled';
import PembayaranItem from 'components/molecule/PembayaranItem';

function TransferVA({ isMobile, setTab, hide, data_va, onVAChange, dataOrder, onDataOrderChange }) {
  const banks = [
    { name: 'BCA Virtual Account', logo: bca, desc: 'Hanya menerima dari bank BCA', kode: 'bca' },
    {
      name: 'BNI Virtual Account',
      logo: bni,
      desc: 'Menerima dari ATM Bersama, Prima dan Alto',
      kode: 'bni',
    },
    {
      name: 'Mandiri Virtual Account',
      logo: mandiri,
      desc: 'Menerima dari berbagai bank',
      kode: 'mandiri',
    },
    {
      name: 'Permata Virtual Account',
      logo: permata,
      desc: 'Hanya menerima dari bank Permata',
      kode: 'permata',
    },
  ];

  return (
    <TransferVAStyled hide={hide}>
      <TitleMobile>Transfer Virtual Account</TitleMobile>
      <GridMenus
        column={2}
        mobileColumn={1}
        renderedData={banks}
        renderedItem={(item, idx) => (
          <PembayaranItem
            key={idx}
            onClick={() => {
              setTab('Transfer Virtual Account');
              if (isMobile) {
                onDataOrderChange({
                  ...dataOrder,
                  paymentMethod: item.kode,
                });
              } else {
                onVAChange({
                  ...data_va,
                  paymentMethod: item.kode,
                });
              }
            }}
            selected={
              isMobile ? dataOrder.paymentMethod === item.kode : data_va.paymentMethod === item.kode
            }
            payment={item}
          />
        )}
      />
    </TransferVAStyled>
  );
}

export default TransferVA;
