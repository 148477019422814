import { gql } from '@apollo/client';
import { webinarFields } from '../queries/webinar';

export const CREATE_USER_WEBINAR = gql`
  mutation CREATE_USER_WEBINAR($input: CreateUserWebinarInput) {
    CreateUserWebinar(input: $input) {
      user_id
      webinar_id
      access_room_id
      add_gcalendar
      is_attend
      add_gcalendar
      webinar {
        ${webinarFields}
      }
      id
    } 
  }
`;

export const UPDATE_USER_WEBINAR = gql`
  mutation UPDATE_USER_WEBINAR($input: UpdateUserWebinarInput) {
    UpdateUserWebinar(input: $input) {
      user_id
      webinar_id
      access_room_id
      add_gcalendar
      is_attend
      add_gcalendar
      webinar {
        ${webinarFields}
      }
      id
    } 
  }
`;
export const CREATE_FEEDBACK_WEBINAR = gql`
  mutation CREATE_FEEDBACK_WEBINAR($input: WebinarFeedbackInput) {
    CreateWebinarFeedback(input: $input) {
      webinar_id
      user_id
      rating
      text
    }
  }
`;
