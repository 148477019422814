import styled from 'styled-components';
import { mq } from 'assets/styles/theme';

export const Container = styled.section`
  width: 100%;
  background-color: #000004;
  padding: 40px 0;

  ${mq({
    padding: [null, null, '20px 0', '20px 0', '16px 0'],
  })}

  .button-slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slider {
    position: relative;
    margin-bottom: 8px;
  }

  .overlay {
    overflow-x: scroll;
  }

  .inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    padding-right: 16px;
    box-sizing: border-box;
    width: fit-content;
  }
`;

export const CardContainer = styled.div`
  padding: 0 100px;
  justify-content: center;
  vertical-align: center;
  align-items: center;

  ${mq({
    padding: ['0 100px', null, '0 60px'],
  })}
  .swiper-slider {
    justify-content: flex-start;
    width: fit-content;
  }
`;

export const Title = styled.h2`
  font-family: Archivo, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-align: center;

  color: #fdfdfd;
  ${mq({
    fontSize: [null, null, '20px', '20px', '20px'],
  })}
`;

export const CategoryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 22px 0 48px;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    height: 40px;
    margin: 0;
  }
`;

export const CategoryText = styled.a`
  flex: 1;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: ${(props) => (props.selected ? '#00DEBF' : '#C4C4C4')};
  margin-left: 10px;
  margin-right: 40px;
  cursor: pointer;
  white-space: nowrap;

  ${mq({
    fontSize: [null, null, null, '14px', '14px'],
    marginLeft: [null, null, null, null, '20px'],
    marginRight: [null, null, null, null, '0'],
  })}
`;

export const FixTraining = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 0 100px;
  justify-content: center;
  box-sizing: border-box;
  gap: 50px 20px;

  @media only screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0 20px;
    gap: 50px 25px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.md} {
    grid-template-columns: auto auto;
    padding: 0 16px;
    gap: 50px 20px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    gap: 40px 20px;
  }

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    gap: 25px 10px;
    padding: 0 16px;
  }
`;

export const DropdownContainer = styled.div`
  padding: 16px;
`;
