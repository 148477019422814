import React from 'react';
import {
  DompetOnlineStyled,
  GridDompet,
  TitleMobile,
  FormContainer,
  FormLabel,
  InputPhoneField,
} from './styled';
import gopay from 'assets/png/dompet/image 20.png';
import shopee from 'assets/png/dompet/image 19.png';
import dana from 'assets/png/dompet/image 36.png';
import PembayaranItem from 'components/molecule/PembayaranItem';

function DompetOnline({
  isMobile,
  setTab,
  hide,
  data_dompet_online,
  onDompetOnlineChange,
  dataOrder,
  onDataOrderChange,
  setIsRecurring,
  isRecurring,
  user,
}) {
  const dompets = [
    {
      name: 'GoPay',
      logo: gopay,
      desc: 'Scan QR code melalui aplikasi gojek',
      hasRecurring: false,
      kode: 'gopay',
    },
    {
      name: 'ShopeePay',
      logo: shopee,
      desc: 'Transfer menggunakan aplikasi ShopeePay',
      hasRecurring: false,
      kode: 'shopeepay',
    },
    {
      name: 'DANA',
      logo: dana,
      desc: 'Transfer menggunakan aplikasi DANA',
      hasRecurring: true,
      kode: 'dana',
    },
  ];

  return (
    <DompetOnlineStyled hide={hide}>
      <TitleMobile>Dompet Online</TitleMobile>
      <GridDompet
        column={2}
        renderedData={dompets}
        renderedItem={(item, idx) => (
          <PembayaranItem
            key={idx}
            setIsRecurring={setIsRecurring}
            isRecurring={isRecurring}
            mobile
            dompet
            onClick={() => {
              setTab('Dompet Online');
              if (isMobile) {
                onDataOrderChange({
                  ...dataOrder,
                  paymentMethod: item.kode,
                });
              } else {
                onDompetOnlineChange({
                  ...data_dompet_online,
                  paymentMethod: item.kode,
                });
              }
            }}
            selected={
              isMobile
                ? dataOrder.paymentMethod === item.kode
                : data_dompet_online.paymentMethod === item.kode
            }
            payment={item}
          />
        )}
      />
      {user && user.ProfileUser.User.phone_number && (
        <FormContainer>
          <FormLabel>Masukkan Nomor HP</FormLabel>
          <InputPhoneField
            name="nama"
            type="tel"
            dark
            placeholder="Nomor HP"
            defaultValue={user && '0' + user?.ProfileUser?.User?.phone_number}
            onChange={(e) =>
              onDompetOnlineChange({
                ...data_dompet_online,
                phoneNumber: e.target.value,
              })
            }
          />
        </FormContainer>
      )}
    </DompetOnlineStyled>
  );
}

export default DompetOnline;
