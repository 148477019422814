import ForumBubble from 'components/atoms/ForumBubble';
import React from 'react';
import UserDefault from 'assets/svg/User Default.svg';

import { Container, Image, ContainerImage, BlackBackgriundImage, ChatContainer } from './styled';

const CardForumTeraktif = ({ data }) => {
  return (
    <Container>
      <ChatContainer>
        {data.datas
          .slice(0, 4)
          .map((d, i) =>
            (i + 1) % 2 !== 0 ? (
              <ForumBubble src={d.image || UserDefault} msg={d.message} key={i} className="left" />
            ) : (
              <ForumBubble src={d.image || UserDefault} msg={d.message} key={i} className="right" />
            ),
          )}
      </ChatContainer>
      <ContainerImage>
        <Image src={data.img} alt="" />
        <BlackBackgriundImage />
      </ContainerImage>
    </Container>
  );
};

export default CardForumTeraktif;
