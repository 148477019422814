import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  justify-items: center;

  padding: 16px 50px 32px 50px;

  font-family: Inter;

  h6 {
    font-size: 24px !important;
    margin: 0 !important;
    margin-bottom: 16px !important;
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .mb-2 {
    margin-bottom: 16px !important;
  }

  .mb-5 {
    margin-bottom: 32px !important;
  }

  .w-100 {
    width: 100%;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    padding: 24px 16px;

    h6 {
      margin: 8px 0 !important;
      margin-bottom: 8px !important;
      font-size: 16px !important;
    }

    p {
      font-size: 14px;
    }

    svg {
      width: 100px;
      height: 100px;
    }
  }
`;
