import styled from 'styled-components';
import Button from '../../../atoms/Button';
import BackgroundImage from 'assets/png/TBM/banner.png';
import BackgroundImageMobile from 'assets/png/TBM/banner-mobile.png';

export const BannerTBMContainer = styled.section`
  height: 45vw;
  width: 100%;
  align-items: center;
  display: flex;
  background-image: url(${BackgroundImage});
  background-size: cover;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    background-image: url(${BackgroundImageMobile});
  }
`;

export const ContentBannerTBM = styled.div`
  width: 50%;
  margin: 100px 0 100px 100px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    margin: 0;
    width: 100%;

    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonBannerTBM = styled(Button)`
  background: linear-gradient(86.42deg, #f5a52f 0%, #f18f01 100%);
  border-radius: 25px;
  height: 50px;
  width: 300px;
  color: white;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    width: 150px;
  }
`;

export const TitleBannerTBM = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 85px;
  text-align: left;
  margin: 0 0 32px;
  color: #ffffff;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 24px;
    line-height: 32px;
    max-width: 250px;
    text-align: center;
    margin: 0 0 14px;
  }
`;

export const BoldTitleTBM = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 85px;
  text-align: left;
  margin: 0 0 32px;
  color: #00debf;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 24px;
    line-height: 32px;
    max-width: 250px;
    text-align: center;
    margin: 0 0 14px;
    color: #f18f01;
  }
`;

export const DescBannerTBM = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  text-align: left;
  margin: 0 0 32px;

  color: #c4c4c4;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    display: none;
  }
`;
