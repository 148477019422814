import React from 'react';
import { Container } from './styled';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircleProgressbar = ({ userData, userSummary }) => {
  const persen = ~~userSummary;

  return (
    <Container>
      <CircularProgressbar
        percentage={persen}
        counterClockwise
        strokeWidth={10}
        value={persen}
        text={`${persen}%`}
        styles={buildStyles({
          textColor: '#00DEBF',
          strokeLinecap: 'butt',
          pathColor: '#00DEBF',
          fontSize: '19px',
          trailColor: '#464646',
          background: {
            fill: 'black',
          },
        })}
      />
    </Container>
  );
};

export default CircleProgressbar;
