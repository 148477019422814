import { gql } from '@apollo/client';

export const REQUEST_OTP = gql`
  mutation requestOTP($input: SendOTPInput) {
    SendOTP(input: $input) {
      status
      statusText
    }
  }
`;

export const CHECK_OTP = gql`
  mutation checkOTP($input: CheckOTPInput) {
    CheckOTP(input: $input) {
      id
      email
      phone_number
      token_id
      isNewRecord
      Profile {
        fullname
      }
    }
  }
`;
