/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { randomizeArray } from 'utils/randomize';
import { RETAKE_ALL_TRAINING } from 'mutation/retake';
import { useHistory, useParams } from 'react-router';

import AnswerOption from 'components/atoms/AnswerOption';
import Prequisite from './prequisite.js';
import Button from 'components/atoms/Button';
import TimeoutModal from 'components/molecule/Modal/TimeoutModal';
import KuisSelesaiModal from 'components/molecule/Modal/KuisSelesaiModal';
import BelumAmbilKuisModal from 'components/molecule/Modal/BelumAmbilKuis';
import RetakeHabisModal from 'components/molecule/Modal/RetakeHabisModal';
import RetakeKuisModal from 'components/molecule/Modal/RetakeKuisModal';
import IkutiKelasModal from 'components/molecule/Modal/IkutiKelasModal';
import SuccessJoinTrainingModal from 'components/molecule/Modal/SuccessJoinTraining';

import { Container, Detail, Question, Answer } from './styled';
import { useMutation, useQuery } from '@apollo/client';
import { SUBMIT_POST_TEST, SUBMIT_QUIZ } from 'mutation/kuis';
import { UPDATE_TRAINING, RETAKE_TRAINING, UPDATE_USER_TRAINING } from 'mutation/training';
import { GET_QUIZ_USER } from 'queries/kuis';
import { GET_TRAINING_MODULE } from 'queries/training';
import { useSelector } from 'react-redux';
import { setContext } from 'utils';
import { t } from 'hooks/intl/useLang.js';

const Kuis = ({
  data,
  detailTraining,
  type,
  title,
  currentSection,
  setCurrentSection,
  totalDuration,
  totalUserTrainingDuration,
  trainingTitle,
  setType,
  trainingId,
  allowPostTest,
  moduleData,
  getTrainingDetails,
  getUserTraining,
  getTrainingDurations,
  typeTraining,
}) => {
  let timeoutID = useRef(null);
  const history = useHistory();
  const { slug } = useParams();

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [randArr, setRandArr] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSelesaiModal, setShowSelesaiModal] = useState(false);
  const [klik, setKlik] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [secondsText, setSecondsText] = useState('00:00');
  const [tick, setTick] = useState();
  const [maximumRetake, setMaximumRetake] = useState(false);
  const [showRetakeModal, setShowRetakeModal] = useState(false);
  const [showIkutiKelasModal, setShowIkutiKelasModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [pretestAnswer, setPretestAnswer] = useState(0);
  const [showModalAllowPostes, setShownAllowPostes] = useState(false);
  const [startButtonDisabled, setStartButtonDisabled] = useState(true);

  const authData = useSelector((state) => {
    return state.Auth;
  });

  // SUBMIT QUIZ ANSWER
  const [submitQuiz, { loading: submitTestLoading }] = useMutation(SUBMIT_QUIZ, {
    context: setContext(authData),
  });

  // SUBMIT POST TEST ANSWER
  const [submitPostTest, { loading: submitPostTestLoading }] = useMutation(SUBMIT_POST_TEST, {
    context: setContext(authData),
  });

  // RETAKE ALL TRAINING
  const [retakeAllTraining] = useMutation(RETAKE_ALL_TRAINING, {
    context: setContext(authData),
    onCompleted: () => {
      setShowSuccessModal(true);
    },
    onError: () => {
      setShowIkutiKelasModal(true);
    },
    refetchQueries: [
      {
        query: GET_TRAINING_MODULE,
        context: setContext(authData),
        variables: {
          auth: true,
          id: trainingId,
        },
      },
    ],
  });

  // UPDATE TRAINING STATUS
  const [updateTraining] = useMutation(UPDATE_TRAINING, {
    context: setContext(authData),
    refetchQueries: [
      {
        query: GET_TRAINING_MODULE,
        context: setContext(authData),
        variables: {
          id: trainingId,
          auth: true,
        },
      },
    ],
  });

  // UPDATE TRAINING STATUS
  const [updateUserTraining] = useMutation(UPDATE_USER_TRAINING, {
    context: setContext(authData),
    async onCompleted() {
      await getTrainingDetails();
      await getUserTraining();
      await getTrainingDurations();
    },
    refetchQueries: [
      {
        query: GET_TRAINING_MODULE,
        context: setContext(authData),
        variables: {
          id: trainingId,
          auth: true,
        },
      },
    ],
  });

  // RETAKE TRAINING
  const [retakeTraining, { loading: loadingRetake }] = useMutation(RETAKE_TRAINING, {
    context: setContext(authData),
    onError(e) {
      setMaximumRetake(true);
    },
    onCompleted({ RetakeQuizPostTest }) {
      if (type === 'PostTest' && RetakeQuizPostTest?.status === 200) {
        setCurrentQuestion(1);
      } else if (type === 'PostTest' && RetakeQuizPostTest?.status === 400) {
        setMaximumRetake(true);
      }
    },
  });

  useEffect(() => {
    if (currentQuestion !== 0) {
      setRandArr(randomizeArray(data[currentQuestion - 1]?.answers));

      if (timeoutID.current) clearTimeout(timeoutID.current);

      setTick(60000);
      // console.log(data[currentQuestion - 1]?.accepted_answer);
    }
  }, [currentQuestion]);

  useEffect(() => {
    setRandArr(randomizeArray(data[currentQuestion - 1]?.answers));
    setCurrentQuestion(0);
  }, [data]);

  useEffect(() => {
    timeoutID.current = setTimeout(() => {
      let minute = Math.floor((tick % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((tick % (1000 * 60)) / 1000);

      if (tick) {
        setSecondsText(tick);
      }

      if (tick <= 0) {
        setSecondsText(t('Waktu Telah Habis'));
        setSelectedAnswer('');
        setShowModal(true);
      } else {
        setSecondsText(
          `${minute < 10 ? '0' + minute.toString() : minute.toString()}:${
            second < 10 ? '0' + second.toString() : second.toString()
          }`,
        );
        if (tick) {
          setTick(tick - 1000);
        }
      }
    }, 1000);
  }, [tick]);

  const updateQuestion = async () => {
    clearTimeout(timeoutID.current);
    if (currentQuestion + 1 > data.length) {
      setShowModal(false);

      if (type === 'Test') {
        await submitQuiz({
          variables: {
            trainingId: trainingId,
            trainingSectionId: currentSection.training_chapter_id,
            trainingQuizId: data[currentQuestion - 1]?.id,
            answer: selectedAnswer,
          },
          refetchQueries: [
            {
              context: setContext(authData),
              query: GET_QUIZ_USER,
              variables: {
                trainingId: trainingId,
                trainingSectionId: currentSection.training_chapter_id,
              },
            },
          ],
        });

        await updateTraining({
          variables: {
            input: {
              trainingId: trainingId,
              trainingProgress: Math.floor(
                ((totalUserTrainingDuration + 100) / totalDuration) * 100,
              ),
              status: 'Progress',
              lastTrainingChapterId: currentSection.training_id,
              lastTrainingSectionId: currentSection.training_chapter_id,
              lastTrainingSectionDuration: 0,
            },
          },
        });
        await updateUserTraining({
          variables: {
            input: {
              trainingId: trainingId,
              trainingChapterId: currentSection.training_id,
              trainingSectionId: currentSection.training_chapter_id,
              duration: data.length * 60,
            },
          },
        });
      } else {
        await submitPostTest({
          variables: {
            input: {
              trainingId: trainingId,
              postTestId: data[currentQuestion - 1]?.id,
              answer: selectedAnswer,
            },
          },
          refetchQueries: [
            {
              context: setContext(authData),
              query: GET_QUIZ_USER,
              variables: {
                trainingId: trainingId,
                trainingSectionId: currentSection.training_chapter_id,
              },
            },
          ],
        });

        await updateTraining({
          variables: {
            input: {
              trainingId: trainingId,
              trainingProgress: 100,
              status: 'Success',
              lastTrainingChapterId: currentSection.training_id,
              lastTrainingSectionId: currentSection.training_chapter_id,
              lastTrainingSectionDuration: 0,
            },
          },
        });

        await updateUserTraining({
          variables: {
            input: {
              trainingId: trainingId,
              trainingChapterId: currentSection.training_id,
              trainingSectionId: currentSection.training_chapter_id,
              duration: data.length * 60,
            },
          },
        });
      }

      setSelectedAnswer(null);
      clearTimeout(timeoutID.current);

      if (type === 'PostTest') {
        window.Moengage.track_event('Finish Training', {
          training_name: detailTraining?.Training?.title,
          trainer_name: detailTraining?.Training?.trainer?.fullname,
        });
        history.push(`/training/selesai/${slug}`, {
          sectionId: currentSection.training_chapter_id,
          training_type: typeTraining,
        });
      }

      setShowSelesaiModal(true);
    } else {
      if (type === 'Test') {
        await submitQuiz({
          variables: {
            trainingId: trainingId,
            trainingSectionId: currentSection.training_chapter_id,
            trainingQuizId: data[currentQuestion - 1]?.id,
            answer: selectedAnswer,
          },
          refetchQueries: [
            {
              context: setContext(authData),
              query: GET_QUIZ_USER,
              variables: {
                trainingId: trainingId,
                trainingSectionId: currentSection.training_chapter_id,
              },
            },
          ],
        });
      } else {
        await submitPostTest({
          variables: {
            input: {
              trainingId: trainingId,
              postTestId: data[currentQuestion - 1]?.id,
              answer: selectedAnswer,
            },
          },
          refetchQueries: [
            {
              context: setContext(authData),
              query: GET_QUIZ_USER,
              variables: {
                trainingId: trainingId,
                trainingSectionId: currentSection.training_chapter_id,
              },
            },
          ],
        });
      }
      setRandArr(null);
      setSelectedAnswer(null);
      setShowModal(false);
      setCurrentQuestion(currentQuestion + 1);
      setStartTime(Date.now());
      clearTimeout(timeoutID.current);
      setTick(60000);
    }
  };

  // const updatePretestQuestion = () => {
  //   clearTimeout(timeoutID.current);
  //   if (currentQuestion + 1 > data.length) {
  //     if (selectedAnswer.toLowerCase() === data[currentQuestion - 1]?.accepted_answer) {
  //       setPretestAnswer((prev) => prev + 1);
  //     }

  //     setShowModal(false);

  //     setSelectedAnswer(null);
  //     clearTimeout(timeoutID.current);

  //     setShowSelesaiModal(true);
  //   } else {
  //     if (selectedAnswer.toLowerCase() === data[currentQuestion - 1]?.accepted_answer) {
  //       setPretestAnswer((prev) => prev + 1);
  //     }

  //     setRandArr(null);
  //     setSelectedAnswer(null);
  //     setShowModal(false);
  //     setCurrentQuestion(currentQuestion + 1);
  //     setStartTime(Date.now());
  //     clearTimeout(timeoutID.current);
  //     setTick(60000);
  //   }
  // };

  const retakeQuizPostTest = async () => {
    if (type === 'Pretest') {
      setSelectedAnswer(null);

      clearTimeout(timeoutID.current);

      setRandArr(null);
      setShowSelesaiModal(false);

      setCurrentQuestion(0);
      setStartTime(Date.now());
      clearTimeout(timeoutID.current);
      // setTick(60000);

      return;
    }

    try {
      const data = await retakeTraining({
        variables: {
          input: {
            retakeType: type === 'Test' ? 'Quiz' : 'PostTest',
            trainingId: trainingId,
            trainingSectionId: currentSection.training_chapter_id,
          },
        },
      });

      if (data?.data?.RetakeQuizPostTest?.status === 200) {
        setSelectedAnswer(null);

        clearTimeout(timeoutID.current);

        setRandArr(null);
        setShowSelesaiModal(false);

        setCurrentQuestion(0);
        setStartTime(Date.now());
        clearTimeout(timeoutID.current);
        // setTick(60000);
      } else {
        clearTimeout(timeoutID.current);
        setShowSelesaiModal(false);
        setShowRetakeModal(true);
      }
    } catch (err) {}
  };

  const submitRetakeTraining = async () => {
    try {
      const data = await retakeAllTraining({
        variables: {
          input: {
            trainingId: trainingId,
          },
        },
      });

      setShowRetakeModal(false);
      getUserTraining();
      getTrainingDetails();
    } catch (err) {
      console.error(err);
    }
  };

  const resetQuestion = () => {
    setSelectedAnswer(null);

    clearTimeout(timeoutID.current);

    setCurrentQuestion(0);
    setStartTime(Date.now());
    clearTimeout(timeoutID.current);
    // setTick(60000);
  };

  const resetModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (moduleData) {
      const section = moduleData?.TrainingUserDurations?.items?.find(
        (userDuration) => userDuration?.training_section_id === currentSection?.training_chapter_id,
      );
      setStartButtonDisabled(section?.completed);
    }
  });

  return (
    <>
      <Container>
        <h6 className="mb-3">{type === 'PostTest' ? 'Test Akhir' : title}</h6>
        {/* {!allowPostTest && type === 'PostTest' ? (
          <BelumAmbilKuisModal
            isOpen={true}
            moduleData={moduleData}
            currentSection={currentSection}
            setType={setType}
            setCurrentSection={setCurrentSection}
            type={type}
            onRequestClose
          />
        ) : ( */}
        <>
          {currentQuestion === 0 ? (
            <>
              <Prequisite type={type} length={data.length} />
              <Button
                className={`ml-auto button ${
                  startButtonDisabled && type === 'PostTest' ? 'disabled' : ''
                }`}
                disabled={startButtonDisabled && type === 'PostTest'}
                onClick={async () => {
                  if (type === 'PostTest') {
                    await retakeTraining({
                      variables: {
                        input: {
                          retakeType: type === 'Test' ? 'Quiz' : 'PostTest',
                          trainingId: trainingId,
                          trainingSectionId: currentSection.training_chapter_id,
                        },
                      },
                    });
                  } else {
                    setCurrentQuestion(1);
                  }
                }}
              >
                {loadingRetake ? 'Loading' : `Mulai ${type === 'PostTest' ? 'Test Akhir' : title}`}
              </Button>
            </>
          ) : (
            <>
              <Detail className="mb-3">
                <p>
                  {t('Pertanyaan')} <strong>{currentQuestion}</strong> {t('dari')} <strong>{data.length}</strong>
                </p>
                <p>
                  {t('Waktu:')} <strong>{secondsText}</strong>
                </p>
              </Detail>
              <Question className="mb-3">
                <p className="list">{currentQuestion}.</p>
                <p className="question">{data[currentQuestion - 1]?.questions}</p>
              </Question>
              {randArr &&
                randArr.map((data, i) => (
                  <Answer className="mb-3" key={i}>
                    <AnswerOption
                      selected={selectedAnswer === data}
                      data={data}
                      setSelectedAnswer={setSelectedAnswer}
                    />
                  </Answer>
                ))}
              <Button
                disabled={!selectedAnswer || submitTestLoading || submitPostTestLoading || klik}
                className={`${
                  (!selectedAnswer || submitTestLoading || submitPostTestLoading || klik) &&
                  'disabled'
                } ml-auto button`}
                onClick={async () => {
                  setKlik(true);
                  setTimeout(
                    async () => {
                      await updateQuestion();
                      setKlik(false);
                    },
                    currentQuestion + 1 > data.length ? 0 : 1000,
                  );
                }}
              >
                {submitTestLoading || submitPostTestLoading || klik
                  ? 'Submitting Answer'
                  : t('Selanjutnya')}
              </Button>
            </>
          )}
          {showSelesaiModal && (
            <KuisSelesaiModal
              pretestAnswer={pretestAnswer}
              type={type}
              getUserTraining={getUserTraining}
              getTrainingDetails={getTrainingDetails}
              trainingId={trainingId}
              isOpen={true}
              setCurrentSection={setCurrentSection}
              setShowSelesaiModal={setShowSelesaiModal}
              currentSection={currentSection}
              title={title}
              trainingTitle={trainingTitle}
              setType={setType}
              timeoutID={timeoutID.current}
              retakeQuizPostTest={retakeQuizPostTest}
              moduleData={moduleData}
              resetModal={resetModal}
              updateTraining={updateTraining}
              updateUserTraining={updateUserTraining}
              allowPostTest={allowPostTest}
              setShownAllowPostes={setShownAllowPostes}
            />
          )}
          <TimeoutModal
            updateQuestion={updateQuestion}
            setSelectedAnswer={setSelectedAnswer}
            isOpen={showModal}
            setShowModal={setShowModal}
          />
        </>
      </Container>
      <SuccessJoinTrainingModal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        resetQuestion={resetQuestion}
        setShowSuccessModal={setShowSuccessModal}
        retake
      />
      <BelumAmbilKuisModal isOpen={showModalAllowPostes} onRequestClose={setShownAllowPostes} />

      {showRetakeModal && (
        <RetakeKuisModal
          isOpen={true}
          type={type}
          setType={setType}
          moduleData={moduleData}
          trainingId={trainingId}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          onRequestClose={() => setShowRetakeModal(false)}
          submitRetakeTraining={submitRetakeTraining}
          setShowRetakeModal={setShowRetakeModal}
        />
      )}
      <RetakeHabisModal
        setShowRetakeHabis={setMaximumRetake}
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        isOpen={maximumRetake}
        onRequestClose={() => setMaximumRetake(false)}
        setType={setType}
        moduleData={moduleData}
        maxRetake={maximumRetake}
      />
      {/*<IkutiKelasModal*/}
      {/*  quota={0}*/}
      {/*  freeTrial={true}*/}
      {/*  isOpen={showIkutiKelasModal}*/}
      {/*  onRequestClose={() => {*/}
      {/*    setType('Material');*/}
      {/*    setCurrentSection({*/}
      {/*      ...currentSection,*/}
      {/*      training_order: 1,*/}
      {/*      section_order: 1,*/}
      {/*      training_id: moduleData?.TrainingChapters?.items[0].id,*/}
      {/*      training_chapter_id: moduleData?.TrainingChapters?.items[0].sections[0].id,*/}
      {/*    });*/}
      {/*    setShowIkutiKelasModal(false);*/}
      {/*  }}*/}
      {/*/>*/}
    </>
  );
};

export default Kuis;
