import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  background-color: #373737;
  display: ${(props) => (props.show ? 'grid' : 'none')};
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
  gap: 0;
  position: fixed;
  z-index: 100;
  top: 0;

  .wrapper-icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 10px;
    height: 10px;
  }

  button {
    margin: 0;
    width: 100px;
    height: 30px;
    font-weight: 600;
    font-size: 12px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: unset;
    padding: 10px 16px;
    gap: 12px;
    justify-content: unset;
  }
`;

export const Message = styled.p`
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  text-align: left;
`;
