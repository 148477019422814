import React from 'react';

import { Container } from './styled';

const AnswerOption = ({ data, selected = false, setSelectedAnswer }) => {
  return (
    <Container className={`${selected && 'selected'}`} onClick={() => setSelectedAnswer(data)}>
      <div>
        <div className={`list ${selected && 'list-selected'}`}></div>
      </div>
      <div className="option">{data}</div>
    </Container>
  );
};

export default AnswerOption;
