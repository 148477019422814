import React, { useState } from 'react';
import { Answer, Question, Container } from './styled';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';

const Accordion = ({ data: { question, answer }, pembayaran = false, undangTeman, ...rest }) => {
  const [isOpened, setOpened] = useState(false);

  function opened(e) {
    setOpened(!isOpened);
  }

  return (
    <Container pembayaran={pembayaran} undangTeman={undangTeman} {...rest}>
      <Question
        {...rest}
        className={`${isOpened ? 'opened' : null} ${rest.className}`}
        onClick={opened}
      >
        {question}
        <ArrowIcon className={`icon-faq ${isOpened ? 'icon-faq-active' : null}`} />
      </Question>
      {!pembayaran ? (
        <Answer className={`${isOpened ? 'active opened' : null} ${rest.className}`}>
          {answer}
        </Answer>
      ) : (
        answer.map((e) => {
          return (
            <Answer
              pembayaran={pembayaran}
              className={`${isOpened ? 'active opened' : null} ${rest.className}`}
            >
              {e}
            </Answer>
          );
        })
      )}
    </Container>
  );
};

export default Accordion;
