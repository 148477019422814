import styled from 'styled-components';
import Button from '../../../components/atoms/Button';
import ImageTest from 'assets/svg/TBM/mbti2.svg';

export const PanduanContainer = styled.div`
  background: white;
  margin-top: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 60px;
  }
`;

export const ImageContainer = styled.div`
  width: 400px;
  height: 400px;
  background-image: url(${ImageTest});
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    width: 250px;
    height: 250px;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  padding: 40px 200px 40px;
  box-sizing: border-box;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 16px 24px 16px;
  }
`;

export const ButtonStart = styled(Button)`
  background: #187336;
  border-radius: 40px;
  color: white;
  font-size: 16px;

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    width: 90%;
    height: 40px;
  }
`;

export const TextPanduan = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  /* or 150% */

  color: #202020;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const ListOrder = styled.ol`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  /* or 150% */

  color: #202020;
  padding-left: 24px;
  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 18px;
    padding-left: 18px;
  }
`;

export const BoldText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  /* or 150% */

  color: #202020;

  @media only screen and ${(props) => props.theme.breakpoints.sm} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const TitlePanduan = styled.h1`
  margin: 0 0 50px;
  padding: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #187336;
`;
