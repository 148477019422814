import styled from 'styled-components';
import InputField from 'components/atoms/InputField';
import GridLayout from 'components/atoms/GridLayout';

export const DompetOnlineStyled = styled.div`
  padding: 32px;
  display: ${(props) => (props.hide ? 'none' : 'block')};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
    padding: 0;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: none;
  }
`;

export const FormLabel = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  text-align: ${(props) => (props.secondary ? 'right' : 'left')};
  flex: 1;
  margin: ${(props) => (props.secondary ? '0 30px 0 0' : '0')};

  color: ${(props) => props.theme.color.white};

  @media only screen and ${(props) => props.theme.breakpoints.md} {
    margin: ${(props) => (props.secondary ? '0 12px 0 0' : '0')};
  }
`;

export const InputPhoneField = styled(InputField)`
  flex: ${(props) => (props.small ? '1' : '4')};
  margin: 0;
  color: ${(props) => props.theme.color.white};
  border-color: #626262;

  ::placeholder {
    color: #626262;
  }
`;

export const GridDompet = styled(GridLayout)`
  row-gap: 24px;
  column-gap: 21px;
  margin: 0;

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    column-gap: 0;
    row-gap: 0;

    & :last-child {
      border-bottom: 0;
    }
  }
`;

export const TitleMobile = styled.h3`
  font-family: Archivo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  display: none;
  margin: 24px 0 0 16px;
  text-align: left;

  color: ${(props) => props.theme.color.white};

  @media only screen and ${(props) => props.theme.breakpoints.xs} {
    display: block;
  }
`;
