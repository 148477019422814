import styled from 'styled-components';

export const CategoryLoading = styled.div`
  height: 16px;

  &.mobile {
    width: 80%;
    margin: 12px 0;
  }

  &.desktop {
    width: 250px;
    margin-bottom: 24px;
  }

  animation: pulse 1s infinite;
  animation-direction: alternate;

  @keyframes pulse {
    0% {
      background: #626262;
    }

    100% {
      background: #7a7a7a;
    }
  }
`;

export const CategoryButtonLoading = styled.button`
  border: none;
  height: 50px;
  border-radius: 30px;

  width: 210px;
  margin: 15px;

  animation: pulse 1s infinite;
  animation-direction: alternate;

  @keyframes pulse {
    0% {
      background: #626262;
    }

    100% {
      background: #7a7a7a;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 100px;
    height: 35px;
    margin: 3px;
  }
`;

export const LoaderDualRing = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;

  ::after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingEllipsis = styled.div`
  &.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  &.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ theme }) => theme.color.primary};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  &.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  &.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  &.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  &.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
