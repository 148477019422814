export const copyToClipboard = (elementId, value) => {
  // https://www.w3schools.com/howto/howto_js_copy_clipboard.asp
  if (document.selection) {
    let range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(elementId));
    range.select().createTextRange();
    document.execCommand('copy');
  } else if (window.getSelection) {
    navigator.clipboard.writeText(value).then(() => console.log('Copied'));
  }
};
