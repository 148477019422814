import React, { useEffect, useRef, useState } from 'react';
import PageLayout from '../../../components/pagelayout/Template';
import {
  BoldTextMBTI,
  ButtonNext,
  ListOrderQuestion,
  MBTIContainer,
  MBTIPage,
  PRKContainer,
  ProgressMBTI,
  RunningProgressMBTI,
  TestContainer,
  TestTopSection,
  TextMBTI,
  TitleMBTI,
} from './styled';
import MaxContainer from '../../../components/atoms/MaxContainer';
import TestOption from '../../../components/molecule/TBMMolecules/TestOption';
import SecondTestOption from '../../../components/molecule/TBMMolecules/SecondTestOption';
import { SelectOptionName } from '../../../components/organisms/DiscQuestionSection/styled';
import { useMutation, useQuery } from '@apollo/client';
import { GET_MINAT_BAKAT_TEST } from '../../../queries/TBM';
import { SUBMIT_TBM } from '../../../mutation/TBM';
import { useSelector } from 'react-redux';
import { setContext } from '../../../utils';
import { useHistory } from 'react-router-dom';

const seo = {
  title: 'Tes Potensi Karir',
  description: 'Tes Potensi Karir',
  canonical: null,
};

const MBTITest = () => {
  const [answers, setAnswers] = useState([]);
  const [answersCount, setAnswersCount] = useState([]);
  const [section, setSection] = useState(1);
  const [progress, setProgress] = useState(1);
  const [newProgress, setNewProgress] = useState(true);
  const authData = useSelector((state) => {
    return state.Auth;
  });
  const [secondsText, setSecondsText] = useState('00:00');
  const [tick, setTick] = useState(60000 * 20);

  const history = useHistory();

  const { data } = useQuery(GET_MINAT_BAKAT_TEST);
  const [updateAnswer] = useMutation(SUBMIT_TBM);

  const submitAnswer = (data) => {
    setNewProgress(false);
    updateAnswer({
      context: setContext(authData),
      variables: {
        input: {
          type: data.type,
          riasec_id: data.riasec_id,
          mbti_id: data.mbti_id,
          answer: data.answer,
          code: data.code,
        },
      },
    });
  };

  const onPressNext = () => {
    if (section === 1 && progress < 6) {
      setProgress((prev) => prev + 1);
      setNewProgress(true);
      window.scrollTo(0, 0);
    } else if (section === 1) {
      setSection((prev) => prev + 1);
      setProgress(1);
      setNewProgress(true);
      clearTimeout(timeoutID.current);
      window.scrollTo(0, 0);
      setTick(60000 * 40);
    } else if (section === 2 && progress < 11) {
      setNewProgress(true);
      setProgress((prev) => prev + 1);
      window.scrollTo(0, 0);
    } else if (section === 2) {
      history.push('/tes-potensi-karir/hasil');
    }
  };
  let timeoutID = useRef(null);

  useEffect(() => {
    timeoutID.current = setTimeout(() => {
      let minute = Math.floor((tick % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((tick % (1000 * 60)) / 1000);

      if (tick) {
        setSecondsText(tick);
      }

      if (tick <= 0) {
        setSecondsText('Waktu Telah Habis');
      } else {
        setSecondsText(
          `${minute < 10 ? '0' + minute.toString() : minute.toString()}:${
            second < 10 ? '0' + second.toString() : second.toString()
          }`,
        );
        if (tick) {
          setTick(tick - 1000);
        }
      }
    }, 1000);
  }, [tick]);

  return (
    <PageLayout seo={seo}>
      <MBTIPage>
        <MaxContainer>
          <TitleMBTI>Soal Bagian {section}</TitleMBTI>
          <TextMBTI style={{ textAlign: 'center' }}>Dari 2 bagian</TextMBTI>
          <MBTIContainer>
            <ProgressMBTI>
              <RunningProgressMBTI
                progress={
                  section === 1
                    ? (progress / (data?.MinatBakatTest?.items[0]?.mbti.length / 6).toFixed()) * 100
                    : (progress / (data?.MinatBakatTest?.items[1]?.riasec.length / 6).toFixed()) *
                      100
                }
              />
            </ProgressMBTI>
            <TextMBTI>
              Progress {progress}/
              {section === 1
                ? (data?.MinatBakatTest?.items[0]?.mbti.length / 6).toFixed()
                : (data?.MinatBakatTest?.items[1]?.riasec.length / 6).toFixed()}
            </TextMBTI>
            <TestContainer>
              <TestTopSection>
                {section === 1 ? (
                  <TextMBTI>
                    Setiap soal berisi 2 pernyataan dan{' '}
                    <BoldTextMBTI>pilihlah salah 1 pernyataan </BoldTextMBTI> yang paling sesuai
                    dengan diri Kamu!
                  </TextMBTI>
                ) : (
                  <TextMBTI>
                    <BoldTextMBTI>Pilih salah 1</BoldTextMBTI> dari 3 pilihan jawaban pada setiap
                    pernyataan yang sesuai dengan kondisi Kamu!
                  </TextMBTI>
                )}
                <TextMBTI>
                  Waktu:{' '}
                  <BoldTextMBTI style={{ color: '#F18F01', fontSize: 20 }}>
                    {secondsText}
                  </BoldTextMBTI>
                </TextMBTI>
              </TestTopSection>

              {section === 2 ? (
                <div style={{ marginTop: 16, marginBottom: 24 }}>
                  <TextMBTI>P = Paling disukai</TextMBTI>
                  <TextMBTI>R = Ragu-ragu</TextMBTI>
                  <TextMBTI>K = Kurang disukai</TextMBTI>
                </div>
              ) : null}

              {section === 1 ? (
                <ListOrderQuestion
                  start={
                    progress * (data?.MinatBakatTest?.items[0]?.mbti?.length / 6).toFixed() -
                    (data?.MinatBakatTest?.items[0]?.mbti?.length / 6).toFixed() +
                    1
                  }
                >
                  {data?.MinatBakatTest?.items[0]?.mbti?.map((mbti, index) => {
                    const answer = answers.find((item) => item.number === index);
                    if (
                      index + 1 >=
                        progress * (data?.MinatBakatTest?.items[0]?.mbti?.length / 6).toFixed() -
                          (data?.MinatBakatTest?.items[0]?.mbti?.length / 6).toFixed() +
                          1 &&
                      index + 1 <=
                        progress * (data?.MinatBakatTest?.items[0]?.mbti?.length / 6).toFixed()
                    ) {
                      return (
                        <li>
                          <TestOption
                            selected={answer?.data === mbti.first_state}
                            data={mbti.first_state}
                            setSelectedAnswer={() => {
                              submitAnswer({
                                type: 'mbti',
                                mbti_id: mbti.id,
                                answer: mbti.first_key,
                                code: mbti.code,
                              });
                              setAnswers(
                                answer
                                  ? () =>
                                      answers.map((item) => {
                                        if (item.number === index) {
                                          return { ...item, number: index, data: mbti.first_state };
                                        }
                                        return item;
                                      })
                                  : [...answers, { number: index, data: mbti.first_state }],
                              );
                            }}
                          />
                          <TestOption
                            selected={answer?.data === mbti.sec_state}
                            data={mbti.sec_state}
                            setSelectedAnswer={() => {
                              submitAnswer({
                                type: 'mbti',
                                mbti_id: mbti.id,
                                answer: mbti.sec_key,
                                code: mbti.code,
                              });
                              setAnswers(
                                answer
                                  ? () =>
                                      answers.map((item) => {
                                        if (item.number === index) {
                                          return { ...item, number: index, data: mbti.sec_state };
                                        }
                                        return item;
                                      })
                                  : [...answers, { number: index, data: mbti.sec_state }],
                              );
                            }}
                          />
                        </li>
                      );
                    }
                  })}
                </ListOrderQuestion>
              ) : (
                <>
                  <PRKContainer>
                    <SelectOptionName style={{ color: '#202020' }}>P</SelectOptionName>
                    <SelectOptionName style={{ color: '#202020' }}>R</SelectOptionName>
                    <SelectOptionName style={{ color: '#202020' }}>
                      <span style={{ color: 'white' }}>--</span>K
                    </SelectOptionName>
                  </PRKContainer>
                  <ListOrderQuestion
                    start={
                      progress * (data?.MinatBakatTest?.items[1]?.riasec?.length / 11).toFixed() -
                      (data?.MinatBakatTest?.items[1]?.riasec?.length / 11).toFixed() +
                      1
                    }
                  >
                    {data?.MinatBakatTest?.items[1]?.riasec?.map((riasec, index) => {
                      if (
                        index + 1 >=
                          progress *
                            (data?.MinatBakatTest?.items[1]?.riasec?.length / 11).toFixed() -
                            (data?.MinatBakatTest?.items[1]?.riasec?.length / 11).toFixed() +
                            1 &&
                        index + 1 <=
                          progress * (data?.MinatBakatTest?.items[1]?.riasec?.length / 11).toFixed()
                      ) {
                        return (
                          <li>
                            <SecondTestOption
                              data={riasec}
                              onChangeSelected={(selected) => {
                                if (selected) {
                                  if (!answersCount.includes(index)) {
                                    setAnswersCount((prev) => [...prev, index]);
                                  }
                                  submitAnswer({
                                    type: 'riasec',
                                    riasec_id: riasec.id,
                                    answer: selected,
                                  });
                                }
                              }}
                            />
                          </li>
                        );
                      }
                    })}
                  </ListOrderQuestion>
                </>
              )}

              <ButtonNext
                disabled={answers.length % 6 !== 0 || answersCount.length % 6 !== 0 || newProgress}
                onClick={onPressNext}
              >
                Selanjutnya
              </ButtonNext>
            </TestContainer>
          </MBTIContainer>
        </MaxContainer>
      </MBTIPage>
    </PageLayout>
  );
};

export default MBTITest;
