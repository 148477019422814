/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Title, AvatarImage, ContainerAddDiscussion, BannerClubhouse } from './styled';
import ComentSection from 'components/molecule/Diskusi/Comment';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import defaultUserPic from 'assets/svg/User Default.svg';
import Button from 'components/atoms/Button';
import { GET_DETAIL_TRAINING, GET_TRAINING_COMMENTS } from 'queries/training';
import { Link, useParams } from 'react-router-dom';
import { ADD_COMMENT_TRAINING, DELETE_COMMENT } from 'mutation/training';
import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import { LoaderDualRing } from 'components/atoms/Loading';
import { Controller, useForm } from 'react-hook-form';
import { checkToken } from 'utils/checkToken';
import { GET_USER_DETAIL } from 'queries/user';
import toast from 'react-hot-toast';
import Toast from 'components/atoms/Notification/Toast';
import BelumAdaDiskusi from 'components/organisms/BelumAdaDiskusi';
import { serialize } from 'utils/serialize';
import MentionExample from 'components/atoms/RichTextInput/Mention';
import { t } from 'hooks/intl/useLang';

const imageBg =
  'https://terampil-public.s3.ap-southeast-1.amazonaws.com/assets/images/YGP_Banner_After.png';
const DiskusiTab = ({ isFollowTraining, haveTrainingFreeTrail }) => {
  const [addComment, setAddComment] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      replyComment: [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ],
      // updateComment: deserialize(new DOMParser().parseFromString(commentUser, 'text/html').body),
    },
  });

  const authData = useSelector((state) => {
    return state.Auth;
  });

  const [submitComment] = useMutation(ADD_COMMENT_TRAINING, {
    onCompleted: () => {
      toast(t('Diskusi berhasil ditambahkan'));
      reset();
    },
  });

  const { slug } = useParams();

  const { data: detailTraining } = useQuery(GET_DETAIL_TRAINING, {
    variables: {
      input: slug,
    },
  });

  const { loading, data: getDiscussionComments } = useQuery(GET_TRAINING_COMMENTS, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        limit: 9999,
        // orderBy: selected,
        orderBy: 'created_at',
        orderType: 'desc',
      },
    },
  });

  //ADD COMMENT
  const onSubmitComment = ({ replyComment }) => {
    submitComment({
      context: setContext(authData),
      variables: {
        input: {
          trainingId: detailTraining?.Training?.id,
          comment: serialize({ children: replyComment }),
        },
      },
      refetchQueries: [
        {
          query: GET_TRAINING_COMMENTS,
          context: setContext(authData),
          variables: {
            input: {
              trainingId: detailTraining?.Training?.id,
              limit: 9999,
              // orderBy: selected,
              orderBy: 'created_at',
              orderType: 'desc',
            },
          },
        },
      ],
      onError: (error) => {
        console.error(error.message);
      },
    });
    setAddComment('');
  };

  const token = getValue(authData, 'data.accessToken', '');

  useEffect(() => {
    checkToken(token, setIsLoggedIn);
  }, []);

  const { data: userTraining } = useQuery(GET_USER_DETAIL, {
    context: setContext(authData),
    variables: {
      slug: getValue(authData, 'data.slug'),
    },
  });

  const [fetchDiscussion, { data: getFetchDiscussion }] = useLazyQuery(GET_TRAINING_COMMENTS, {
    context: setContext(authData),
    variables: {
      input: {
        trainingId: detailTraining?.Training?.id,
        limit: 9999,
        // orderBy: selected,
        orderBy: 'created_at',
        orderType: 'desc',
      },
    },
  });

  //DELETE COMMENT
  const [deleteCommentParent] = useMutation(DELETE_COMMENT, {
    context: setContext(authData),
    onCompleted: () => {
      toast(t('Komentar berhasil dihapus'));
      fetchDiscussion();
    },
    refetchQueries: [
      {
        query: GET_TRAINING_COMMENTS,
        context: setContext(authData),
        variables: {
          input: {
            trainingId: detailTraining?.Training?.id,
            limit: 9999,
            // orderBy: selected,
            orderBy: 'created_at',
            orderType: 'desc',
          },
        },
      },
    ],
    onError: (error) => console.error(error.message),
  });

  //LIST OF USER THAT COMMENTED IN THIS TRAINING
  let saveUserName = [];
  let listOfUser = getDiscussionComments?.TrainingComments?.items.map((item, i) => {
    return saveUserName.push(item?.user?.fullname);
  });
  let listOfUserReplies = getDiscussionComments?.TrainingComments?.items.map((item, i) => {
    return item?.replies.map((items, i) => {
      saveUserName.push(items?.user?.fullname);
    });
  });
  let uniqueChars = [...new Set(saveUserName)];

  const isUnsubsribed = userTraining?.ProfileUser?.is_unsubscription;
  const isSubscribed = userTraining?.ProfileUser?.is_subscription;
  const isFreeTrial = userTraining?.ProfileUser?.User?.free_trial;
  const isAvatar = userTraining?.ProfileUser?.avatar;
  const checkComment = addComment.split('').length <= 0;

  //check condition an user
  const isUserFollowTraining = isLoggedIn && isFreeTrial === true && isFollowTraining;

  return (
    <>
      <Toast position="top-right" />
      <Container>
        {isFollowTraining && detailTraining?.Training?.trainer?.slug === 'coach-yohanes-g-pauly' && (
          <a href="https://clubhouse.com/club/business-is-fun" target="blank">
            <BannerClubhouse image={imageBg}></BannerClubhouse>
          </a>
        )}
        <Title>
          {getDiscussionComments?.TrainingComments?.items
            ? getDiscussionComments?.TrainingComments?.items.length
            : 0}{' '}
          {t('Diskusi')}
        </Title>
        <ContainerAddDiscussion
          className={isFollowTraining && !haveTrainingFreeTrail ? `` : 'not-subscribed'}
        >
          <form onSubmit={handleSubmit(onSubmitComment)}>
            <div className="container-profile">
              <AvatarImage name="avatar" image={isAvatar ? isAvatar : defaultUserPic} />
              <Controller
                control={control}
                name="replyComment"
                render={({ onChange, value }) => (
                  <MentionExample
                    onChange={onChange}
                    listOfUsers={uniqueChars}
                    value={value}
                    type="plain"
                  />
                )}
              />
            </div>
            <div className="container-button">
              <Button
                type="submit"
                disabled={!isDirty || !isValid}
                medium
                className={`${!isDirty || !isValid ? 'disabled-button' : ''} none save-button`}
              >
                {t('Kirim')}
              </Button>
            </div>
          </form>
        </ContainerAddDiscussion>
        {/* <SectionFilterComponent selected={selected} setSelected={setSelected} /> */}
        {loading ? (
          <LoaderDualRing />
        ) : getDiscussionComments && getDiscussionComments?.TrainingComments?.items.length > 0 ? (
          getDiscussionComments?.TrainingComments?.items.map((item, i) => {
            return (
              <ComentSection
                isFollowTraining={isFollowTraining}
                haveTrainingFreeTrail={haveTrainingFreeTrail}
                deleteCommentParent={deleteCommentParent}
                // selected={selected}
                item={item}
                commentUser={item?.comment}
                userId={item?.user?.id}
                key={item?.id}
                id={item?.id}
                index={i}
              />
            );
          })
        ) : (
          <BelumAdaDiskusi />
        )}
      </Container>
    </>
  );
};

export default DiskusiTab;
