import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 450px;
  margin-top: 80px;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 100%;
    margin-bottom: 100px;
  }
`;

export const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  /* identical to box height */

  color: #fdfdfd;
`;

export const LayoutCard = styled.div`
  display: flex;
  gap: 15px;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: column;
    gap: 40px;
  }
`;
