import styled from 'styled-components';

export const Content = styled.div`
  padding: 24px 32px;

  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: #c4c4c4;
  }
`;

export const ButtonContainer = styled.div`
  padding: 0 24px;

  margin: 32px 0;
  margin-top: 0;

  &.flex {
    display: flex;
    gap: 16px;
  }
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.color.primary};

  padding: 0;

  border-radius: 5px;

  width: 100%;
  height: 50px;

  border: none;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #000004;

  cursor: pointer;

  &.secondary {
    background: transparent;
    border: 1px solid #c4c4c4;
    color: #c4c4c4;
  }

  &.secondary:hover {
    background: transparent;
    border: 2px solid #c4c4c4;
  }

  &:hover {
    background: #00f1cf;
  }
`;
