import React from 'react';
import {
  Container,
  CardContainer,
  CardContain,
  CardHeader,
  Paragraf,
  Flex,
  Order,
  Button,
  ButtonContainers,
  StatusName,
  ParagrafBottom,
  TextLanganan,
  PriceText,
  PackageName,
} from './styled';
import success from 'assets/svg/pembayaraan/sucess.svg';
import pending from 'assets/svg/pembayaraan/pending.svg';
import failed from 'assets/svg/pembayaraan/failed.svg';
import { useHistory } from 'react-router';
import { formatLocaleDateShort } from 'utils/date';
import { checkMobile } from 'utils/checkMobile';
import { t } from 'hooks/intl/useLang';

const DetailRiwayatPembayaran = ({ dataOrderDetail, paymentInfo }) => {
  const history = useHistory();
  const exceptMethod = ['point', 'partner_voucher', 'full_discount'];
  const methodNotShow = ['prakerja', 'partner_voucher', 'full_discount'];

  let dataOrder = {};
  switch (dataOrderDetail?.UserOrderDetail?.status) {
    case 'Pending':
      dataOrder = {
        ...dataOrder,
        title: t('Pembayaran Pending'),
        image: pending,
        message:
          t('Pembayaran kamu lagi diproses. Pindah ke halaman Cara Bayar untuk melanjutkan proses pembayaran.'),
        messageMobile:
          t('Pembayaran kamu lagi diproses. Pindah ke halaman Cara Bayar untuk melanjutkan proses pembayaran.'),
        status: 'Pending',
      };
      break;
    case 'Failed':
      dataOrder = {
        ...dataOrder,
        title: t('Pembayaran Gagal'),
        image: failed,
        message: t('Kami tidak bisa memproses pembayaran kamu.'),
        messageMobile: t('Kami tidak bisa memproses pembayaran kamu.'),
        status: t('Gagal Bayar'),
      };
      break;
    case 'Paid':
      dataOrder = {
        ...dataOrder,
        title: t('Pembayaran Berhasil'),
        image: success,
        message: t('Terima kasih sudah membeli paket di Terampil dan selamat menikmati konten kami.'),
        messageMobile: `${t('Pembayaran kamu berhasil diterima pada tanggal')} ${formatLocaleDateShort(
          dataOrderDetail?.UserOrderDetail?.payment_date,
        )}`,
        status: t('Terbayar'),
      };

      if (dataOrderDetail?.UserOrderDetail?.subscription) {
        dataOrder.type = 'subscription';
      } else if (dataOrderDetail?.UserOrderDetail?.training) {
        dataOrder.type = 'training';
      } else if (dataOrderDetail?.UserOrderDetail?.package) {
        dataOrder.type = 'package';
      }

      break;
    default:
      break;
  }

  switch (dataOrderDetail?.UserOrderDetail?.subscription?.subscription_type) {
    case 'Monthly':
      dataOrder.sub = t('Bulanan');
      break;
    case 'Semester':
      dataOrder.sub = t('Semesteran');
      break;
    case 'Yearly':
      dataOrder.sub = t('Tahunan');
      break;
    case 'Prakerja':
      dataOrder.sub = 'Prakerja';
      break;
    default:
      dataOrder.sub = dataOrderDetail?.UserOrderDetail?.order_name;
      break;
  }
  return (
    <Container>
      <CardContainer>
        <CardHeader>
          <img src={dataOrder.image} alt={dataOrderDetail?.UserOrderDetail?.status} />
          <h2>
            {dataOrderDetail?.UserOrderDetail?.payment_method === 'prakerja'
              ? t('Redeem Voucher Berhasil')
              : dataOrder.title}
          </h2>
          {checkMobile() ? <p>{dataOrder.messageMobile}</p> : <p>{dataOrder.message}</p>}
        </CardHeader>
        <CardContain>
          <h2>{t('Paket Pembayaran')}</h2>
          <Flex flex="column">
            <PackageName>{dataOrderDetail?.UserOrderDetail?.order_name}</PackageName>
            {dataOrderDetail?.UserOrderDetail?.payment_date && (
              <Paragraf show="hide">
                {t('Tanggal Pembayaran')}:{' '}
                <span>{formatLocaleDateShort(dataOrderDetail?.UserOrderDetail?.payment_date)}</span>
              </Paragraf>
            )}
          </Flex>
          {dataOrderDetail?.UserOrderDetail?.webinar ? undefined : dataOrderDetail?.UserOrderDetail
              ?.order_name === 'Paket Gold' ? (
            <p>12 {t('Modul Training')}</p>
          ) : dataOrderDetail?.UserOrderDetail?.order_name === 'Paket Silver' ? (
            <p>3 {t('Modul Training')}</p>
          ) : (
            <p>1 {t('Modul Training')}</p>
          )}
          {dataOrderDetail?.UserOrderDetail?.start_date &&
            dataOrderDetail?.UserOrderDetail?.end_date && (
              <p>
                Langganan {dataOrder.sub}:{' '}
                {formatLocaleDateShort(dataOrderDetail?.UserOrderDetail?.start_date)} -{' '}
                {formatLocaleDateShort(dataOrderDetail?.UserOrderDetail?.end_date)}
              </p>
            )}
          <div className="data">
            <Paragraf>
              {t('Nama Pengguna')}:<span>{dataOrderDetail?.UserOrderDetail?.fullname}</span>
            </Paragraf>
            <Paragraf>
              {t('Metode Pembayaran')}:
              <span
                className={`payment-method ${
                  dataOrderDetail?.UserOrderDetail?.payment_method === 'prakerja' && 'prakerja'
                }`}
              >
                {dataOrderDetail?.UserOrderDetail?.payment_method === 'dana_recurring'
                  ? 'DANA Recurring'
                  : dataOrderDetail?.UserOrderDetail?.payment_method === 'prakerja'
                  ? t('Voucher Prakerja')
                  : dataOrderDetail?.UserOrderDetail?.payment_method === 'partner_voucher'
                  ? 'Partner Voucher'
                  : dataOrderDetail?.UserOrderDetail?.payment_method === 'full_discount'
                  ? 'Voucher'
                  : dataOrderDetail?.UserOrderDetail?.payment_method}
              </span>
            </Paragraf>
            {!exceptMethod.includes(dataOrderDetail?.UserOrderDetail?.payment_method) && (
              <>
                {(paymentInfo?.PaymentInfo?.va_number || paymentInfo?.PaymentInfo?.bill_key) && (
                  <Paragraf>
                    {t('Nomor Virtual Account')}:{' '}
                    <span>
                      {dataOrderDetail?.UserOrderDetail?.payment_method === 'mandiri'
                        ? paymentInfo?.PaymentInfo?.bill_key
                        : paymentInfo?.PaymentInfo?.va_number}
                    </span>
                  </Paragraf>
                )}
                {!paymentInfo?.PaymentInfo?.va_number &&
                  !paymentInfo?.PaymentInfo?.bill_key &&
                  !dataOrderDetail?.UserOrderDetail?.card_number &&
                  dataOrderDetail?.UserOrderDetail?.payment_method !== 'prakerja' && (
                    <Paragraf>
                      {t('Nomor HP')}:{' '}
                      <span>0{dataOrderDetail?.UserOrderDetail?.user?.User?.phone_number}</span>
                    </Paragraf>
                  )}
                {dataOrderDetail?.UserOrderDetail?.card_number && (
                  <Paragraf>
                    {t('Nomor Kartu')}: <span>{dataOrderDetail?.UserOrderDetail?.card_number}</span>
                  </Paragraf>
                )}
              </>
            )}
            <Paragraf>
              {t('Nomor Tagihan')}:
              <span>
                {dataOrderDetail?.UserOrderDetail?.invoice_number.split('INV').join('/INV/')}
              </span>
            </Paragraf>
          </div>
          {!methodNotShow.includes(dataOrderDetail?.UserOrderDetail?.payment_method) && (
            <>
              <Order>{t('Order Detail')}</Order>
              <Flex>
                <ParagrafBottom>{dataOrderDetail?.UserOrderDetail?.order_name}</ParagrafBottom>
                <PriceText>
                  Rp.{' '}
                  {dataOrderDetail?.UserOrderDetail?.voucher_code &&
                  dataOrderDetail?.UserOrderDetail?.voucher_id &&
                  dataOrder.type === 'subscription'
                    ? dataOrderDetail?.UserOrderDetail?.subscription?.total_price?.toLocaleString(
                        'id',
                      )
                    : dataOrder.type === 'training'
                    ? dataOrderDetail?.UserOrderDetail?.training?.training_price?.toLocaleString(
                        'id',
                      )
                    : dataOrder.type === 'package'
                    ? dataOrderDetail?.UserOrderDetail?.package?.price?.toLocaleString('id')
                    : dataOrderDetail?.UserOrderDetail?.price.toLocaleString('id')}
                </PriceText>
              </Flex>
              {dataOrderDetail?.UserOrderDetail?.voucher_code &&
                dataOrderDetail?.UserOrderDetail?.voucher_id && (
                  <Flex>
                    <ParagrafBottom>Voucher</ParagrafBottom>
                    <PriceText>
                      - Rp.{' '}
                      {(
                        (dataOrder.type === 'subscription'
                          ? dataOrderDetail?.UserOrderDetail?.subscription?.total_price
                          : dataOrder.type === 'training'
                          ? dataOrderDetail?.UserOrderDetail?.training?.training_price
                          : dataOrder.type === 'package'
                          ? dataOrderDetail?.UserOrderDetail?.package?.price
                          : 0) - dataOrderDetail?.UserOrderDetail?.price
                      ).toLocaleString('id')}
                    </PriceText>
                  </Flex>
                )}
              <Flex>
                <StatusName color={dataOrder.status}>{dataOrder.status}</StatusName>
                <PriceText>
                  Rp.{' '}
                  {!exceptMethod.includes(dataOrderDetail?.UserOrderDetail?.payment_method)
                    ? dataOrderDetail?.UserOrderDetail?.price?.toLocaleString('id')
                    : 0}
                </PriceText>
              </Flex>
            </>
          )}
        </CardContain>
        <ButtonContainers>
          {dataOrderDetail?.UserOrderDetail?.status === 'Paid' ? (
            <>
              <Button secondary onClick={() => history.push('/user/settings/pembayaran')}>
                {checkMobile() ? t('Riwayat Pembelian') : t('Kembali Ke Riwayat Pembelian')}
              </Button>
              {methodNotShow.includes(dataOrderDetail?.UserOrderDetail?.payment_method) ? (
                <Button color="black" onClick={() => history.push('/home')}>
                  {t('Kembali ke Home')}
                </Button>
              ) : (
                <Button
                  color="black"
                  onClick={() =>
                    window.open(`/user/invoice/${dataOrderDetail?.UserOrderDetail?.invoice_number}`)
                  }
                >
                  {t('Lihat Resi')}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button secondary onClick={() => history.push('/home')}>
                {t('Kembali ke Home')}
              </Button>
              <Button color="black" onClick={() => history.push('/user/settings/pembayaran')}>
                {checkMobile() ? t('Riwayat Pembelian') : t('Kembali Ke Riwayat Pembelian')}
              </Button>
            </>
          )}
        </ButtonContainers>
      </CardContainer>
    </Container>
  );
};

export default DetailRiwayatPembayaran;
