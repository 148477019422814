import React, { useEffect, useState } from 'react';
import { Container } from './styled';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import MaxContainer from 'components/atoms/MaxContainer';
import TabLinkComponet from 'components/atoms/TabLink';
import { t } from 'hooks/intl/useLang';

const TabLink = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(pathname.substring(16));

  useEffect(() => {
    setSelected(pathname.substring(16));
  }, [pathname]);

  return (
    <MaxContainer>
      <Container>
        <div className="line">
          <Link to={`${url}/proses`}>
            <TabLinkComponet selected={selected === 'proses'} onClick={() => setSelected('proses')}>
              {t('Training Saya')}
            </TabLinkComponet>
          </Link>
          <Link to={`${url}/bookmark`}>
            <TabLinkComponet
              selected={selected === 'bookmark'}
              onClick={() => setSelected('bookmark')}
            >
              Bookmark
            </TabLinkComponet>
          </Link>
          <Link to={`${url}/download-sertifikat`}>
            <TabLinkComponet
              selected={selected === 'download-sertifikat'}
              onClick={() => setSelected('download-sertifikat')}
            >
              {t('Sertifikat')}
            </TabLinkComponet>
          </Link>
          {/* <Link to={`${url}/nonton-offline`}>
            <TabLinkComponet
              selected={selected === 'nonton-offline'}
              onClick={() => setSelected('nonton-offline')}
            >
              Nonton Offline
            </TabLinkComponet>
          </Link> */}
          {/* <Link to={`${url}/training-saya`}>
            <TabLinkComponet
              selected={selected === 'training-saya'}
              onClick={() => setSelected('training-saya')}
            >
              Training yang Dibeli
            </TabLinkComponet>
          </Link> */}
        </div>
      </Container>
    </MaxContainer>
  );
};

export default TabLink;
