import { BannerLeftSection } from 'components/organisms';
import PageLayout from 'components/pagelayout/Template';
import React, { useState } from 'react';

import { Container, Title, Paragraf } from 'pages/RegisterPage/styled';
import {
  BoxForm,
  Head,
  Section,
} from '../../components/organisms/ResetPasswordRightSection/styled';
import { Button, ContainerInput } from '../../components/organisms/LoginPage/styled';
import InputField from '../../components/atoms/InputField';
import BackgroundLeft from 'assets/png/klinikPintar/img.png';
import FormError from '../../components/atoms/FormError';
import { useUrlQuery } from '../../hooks';
import { useLazyQuery } from '@apollo/client';
import { CHECK_EMAIL } from '../../queries/user';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { authOperations } from '../../state/modules/auth';
import { useDispatch } from 'react-redux';
import PopupBerhasil from '../../components/molecule/Modal/PopupBerhail';
import { CHECK_EMAIL_USER } from '../../queries/webinar';

function B2BLogin({ regular = false }) {
  const seo = {
    title: 'Daftar Terampil',
    description: 'Daftar Termapil',
    canonical: null,
  };

  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const { register, errors: errorsForm, handleSubmit } = useForm();

  const queryUrl = useUrlQuery();
  const token = queryUrl.get('token');
  const roomId = queryUrl.get('r');
  const dispatch = useDispatch();

  if (!token && !roomId) {
    history.push('/');
  }

  const [checkClient] = useLazyQuery(CHECK_EMAIL, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data?.CheckEmail) {
        dispatch(
          authOperations.setAuth({
            accessToken: data?.CheckEmail.accessToken,
            slug: data?.CheckEmail.Profile.slug,
            userId: data?.CheckEmail.id,
            referralCode: data?.CheckEmail.Profile.referral_code,
            allAccess: data?.CheckEmail.Profile.all_access,
          }),
        );
        history.push('password-b2b?token=' + token);
      } else {
        setShowModal(true);
      }
    },
  });

  const [checkWebinar] = useLazyQuery(CHECK_EMAIL_USER, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data?.CheckUserWebinar?.item) {
        dispatch(
          authOperations.setAuth({
            accessToken: data?.CheckUserWebinar?.item?.accessToken,
            slug: data?.CheckUserWebinar?.item?.Profile?.slug,
            userId: data?.CheckUserWebinar?.item?.id,
            referralCode: data?.CheckUserWebinar?.item?.Profile?.referral_code,
            allAccess: data?.CheckUserWebinar?.item?.Profile?.all_access,
          }),
        );
        history.push('password-input?r=' + roomId);
      } else {
        setShowModal(true);
      }
    },
  });

  const checkEmail = (data) => {
    checkClient({
      variables: {
        email: data.email,
        token: token,
      },
    });
  };

  const webinarUserCheck = (data) => {
    checkWebinar({
      variables: {
        input: {
          room_id: roomId,
          access: {
            email: data.email,
          },
        },
      },
    });
  };

  return (
    <PageLayout seo={seo} noHeader blank>
      <div style={{ background: 'white' }}>
        <Container>
          <BannerLeftSection
            withoutLogo={!regular}
            background={regular ? undefined : BackgroundLeft}
          />
          <Section>
            <Head>
              <h6>Validasi Akun</h6>
              <p>Silahkan masukkan email yang sudah kamu daftarkan pada penyelenggara</p>
            </Head>
            <BoxForm style={{ width: '80%' }}>
              <Title style={{ color: '#101010', textAlign: 'center', width: '100%' }}>
                Masukkan Email Kamu
              </Title>
              <form onSubmit={handleSubmit(regular ? webinarUserCheck : checkEmail)}>
                <ContainerInput>
                  <InputField
                    type={'email'}
                    label="Masukkan Email Anda"
                    placeholder="Masukkan Email Anda"
                    danger={errorsForm.email}
                    register={register({
                      required: {
                        value: true,
                        message: 'Email wajib diisi',
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Format email salah. Contoh: terampil@gmail.com',
                      },
                    })}
                    name="email"
                  />
                  {errorsForm.email && <FormError>{errorsForm.email.message}</FormError>}
                </ContainerInput>
                <Button className="button-submit">Kirim</Button>
              </form>
            </BoxForm>
            <PopupBerhasil
              failed
              onRequestClose={() => setShowModal(!showModal)}
              isOpen={showModal}
              title={'Akun Anda Tidak Terdaftar'}
              text={
                'Masukkan email yang telah didaftarkan ke penyelenggara, silahkan hubungi penyelenggara jika membutuhkan bantuan'
              }
              onRight={() => setShowModal(!showModal)}
              textButtonRight={'OK'}
            />
          </Section>
        </Container>
      </div>
    </PageLayout>
  );
}

export default B2BLogin;
