import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  background-color: #141414;
  display: grid;
  place-items: center;
  padding: 41px 0 80px;

  .contain-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    margin-top: 30px;

    @media screen and ${(props) => props.theme.breakpoints.md} {
      padding: 0 20px;
    }

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      padding: 0;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      padding: 0 15px 0 25px;
      margin-top: 20px;
      justify-content: center;
    }
  }

  .logo-phone {
    width: 100%;
    padding: 0px 80px 50px 0;
    box-sizing: border-box;

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      display: none;
    }
  }
`;

export const CardContainer = styled.div`
  width: 840px;
  height: 526px;
  background: #00debf;
  border: 5px solid #fdfdfd;
  box-sizing: border-box;
  border-radius: 10px;

  @media screen and ${(props) => props.theme.breakpoints.md} {
    width: 740px;
    height: 526px;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 640px;
    height: 526px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 328px;
    height: 314px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xxs} {
    width: 300px;
    height: 314px;
  }
`;

export const HeadLeftSection = styled.div`
  font-family: Archivo;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #00debf;
  background-color: #fdfdfd;
  width: 140px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 30px 30px 0px;
  margin: 32px 0 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    width: 63px;
    height: 24px;
    margin-top: 20px;
    padding: 4px;
  }
`;

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  margin: 0 0 15px;
  text-align: center;
  color: #000000;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 14px;
    line-height: 21px;
    padding: 0 30px;
    margin: 0 0 8px;
  }
`;

export const Paragraf = styled.p`
  font-family: Inter;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 24px 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    line-height: 18px;
    padding: 0 30px;
    margin: 16px 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 60% 50%;

  hr {
    width: 150px;
    background-color: #ffffff;
    height: 3px;
    border: none;
    margin: 0 auto;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    grid-template-columns: 65% 35%;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    display: flex;
    hr {
      height: 2px;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 37px;
  margin-top: 50px;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    margin-top: 28px;
  }
`;

export const Image = styled.div`
  width: 161px;
  height: 54px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('${(props) => props.image}');
  cursor: pointer;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 120px;
    height: 42px;
  }
`;

export const Image2 = styled.div`
  width: 200px;
  height: 78px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  background-image: url('${(props) => props.image}');

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 158px;
    height: 62px;
  }
`;
