import React from 'react';

import { Container } from './styled';
import MaxContainer from 'components/atoms/MaxContainer';
import ProfileDetail from 'components/atoms/ProfileDetail';
import ProfileDescription from 'components/atoms/ProfileDescription';
import TitleSection from 'components/molecule/TitleSectionTrainer';
import { Link } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'assets/svg/edit-profile.svg';
import { t } from 'hooks/intl/useLang';

const Profile = ({
  data,
  className = '',
  userProfile = false,
  myProfile = false,
  trainerDetail = false,
}) => {
  return (
    <Container trainerDetail={trainerDetail} userProfile={userProfile}>
      <MaxContainer>
        <div className={`container ${className}`}>
          <ProfileDetail data={data} userProfile={userProfile} />
          <TitleSection title={`${t('Tentang')} ${data?.fullname ? data?.fullname : ''}`} />
          <ProfileDescription
            text={data?.about === '' ? `<p>Hi! my name is ${data?.fullname}</p>` : data?.about}
          />
          {myProfile && (
            <Link to="/user/settings/profil" className="edit-profile">
              <EditIcon />
            </Link>
          )}
        </div>
      </MaxContainer>
    </Container>
  );
};

export default Profile;
