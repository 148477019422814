import { gql } from '@apollo/client';

export const GET_MOST_ACTIVE_FORUM = gql`
  query GET_FORUM_TERAKTIF {
    MostActiveDiscussion {
      statusText
      status
      items {
        user {
          id
          fullname
          avatar
        }
        id
        like
        comment
        tag_person
        total_replies
        isLiked
        replies {
          tag_person
          id
        }
      }
    }
  }
`;
