import styled from 'styled-components';
import ButtonStyled from 'components/atoms/Button/';
import InputField from 'components/atoms/InputField/index';

export const Container = styled.section`
  width: 60%;
  background-color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px 100px;

  .container-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  a {
    text-decoration: none;
  }

  svg.back-button {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 30px;
    top: 40px;
    cursor: pointer;
    transition: all 0.2s ease;

    @media screen and ${(props) => props.theme.breakpoints.sm} {
      flex-direction: column;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      left: 30px;
      top: 28px;
      width: 15px;
      height: 15px;
      flex-direction: column;
    }
  }

  svg.back-button:hover {
    transform: scale(1.2);
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
    padding: 40px 60px;
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 100%;
    padding: 24px;
  }
`;

export const BoxRegister = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: solid 1px #646464;
  width: 65%;
  height: auto;
  padding: 30px 75px 24px;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    border: none;
    width: 70%;
    height: 100%;
    justify-content: flex-start;
  }
  @media screen and ${(props) => props.theme.breakpoints.sm} {
    width: 90%;
    height: 100%;
    padding: 20px 50px;
    justify-content: flex-start;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 20px 10px;
  }

  p.background {
    position: relative;
    z-index: 0;
    margin: 0;
    color: #c4c4c4;

    &:before {
      border-top: 1px solid #626262;
      content: '';
      margin: 0 auto;
      position: absolute;
      top: 65%;
      left: 0;
      right: 0;
      bottom: 0;
      width: 95%;
      z-index: -1;
    }

    span {
      background-color: #202020;
      width: 34px;
      padding: 0 15px;
      font-family: OpenSans;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #626262;
    }
  }

  .line {
    border-bottom: 1px solid #e5e5e5;
    margin: 35px 2px 14px;

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin: 0;
    }
  }

  .line-2 {
    border-bottom: 1px solid #626262;
  }

  div.logotablet {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto 50px;

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      margin: 0 auto;
    }

    img {
      width: 50%;
      height: auto;
    }
  }
`;

export const Title = styled.h2`
  font-family: Archivo;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #fdfdfd;
  font-stretch: normal;
  margin: 0 0 30px 0;
  overflow: auto;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    margin: 30px 0 30px 0;
  }
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 20px;
    margin: 30px 0 20px 0;
  }
`;

export const Button = styled(ButtonStyled)`
  &.button-register {
    width: 100%;
    min-height: 42px;
    border-radius: 5px;
    border: solid 1px #646464;
    background-color: transparent;
    font-family: Inter;
    font-size: 16px;
    color: #fdfdfd;
    line-height: 1.5;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
    margin: 12px 0;
    transition: all 0.2s ease;

    svg {
      color: black;
    }

    @media screen and ${(props) => props.theme.breakpoints.xs} {
      font-size: 14px;

      &.button-register {
        font-size: 14px;
      }
    }
  }

  &.button-submit {
    width: 100%;
    height: 50px;
    padding: 10px 20px 10px 20px;
    margin: 22px 0 0px 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const InputFieldLogin = styled(InputField)`
  color: #fdfdfd;
  border-color: #646464;
`;

export const TermCondition = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  margin-bottom: 20px;
  span {
    font-weight: normal;
    color: #f18f01;
    cursor: pointer;

    &:hover {
      color: #626262;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
  }
`;

export const CheckRegister = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fdfdfd;
  padding: 0;
  margin-top: 24px;

  span {
    font-weight: normal;
    color: #f18f01;
    cursor: pointer;

    &:hover {
      color: #626262;
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    font-size: 12px;
    padding-top: 24px;
    margin-top: 5px;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
`;

export const IconPassword = styled.img`
  position: absolute;
  right: 22px;
  top: 36px;
  cursor: pointer;
  color: #626262;
`;
