import React from 'react';
import { Container } from './InputPhone.styled';
import InputField from '../../atoms/InputField';

function InputPhone({ dark, register, danger, lengkapiProfile, ...rest }) {
  return (
    <Container dark={dark}>
      <InputField
        disabled
        className="code"
        placeholder="+62"
        danger={danger}
        lengkapiProfile={lengkapiProfile}
      />
      <InputField
        dark={dark}
        register={register}
        className="number"
        placeholder="Nomor HP: 81234567890"
        danger={danger}
        {...rest}
      />
    </Container>
  );
}

export default InputPhone;
