import React, { useState } from 'react';
import { Description, LengkapiProfilModalStyled, SaveButton, Title } from './styled';
import InputField from 'components/atoms/InputField';
import InputPhone from 'components/molecule/InputPhone';
import { useForm } from 'react-hook-form';
import FormError from 'components/atoms/FormError';
import { ContainerInput } from '../RegisterEmailModal/styled';
import { useMutation } from '@apollo/client';
import { COMPLETE_PROFILE } from 'mutation/profile';

import { useSelector } from 'react-redux';
import { getValue, setContext } from 'utils';
import { useHistory } from 'react-router';
import { GET_USER_DETAIL } from 'queries/user';
import { REQUEST_OTP } from 'mutation/otp';
import { t } from 'hooks/intl/useLang';

function LengkapiProfilModal({
  setDataLengkapiProfile,
  setOpenModalOTP,
  setOpenModal,
  profil,
  selected,
  ...rest
}) {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      fullname: profil?.fullname || '',
      email: profil?.User?.email || '',
      phoneNumber: profil?.User?.phone_number || '',
    },
  });
  const [errorPhone, setErrorPhone] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [email, setEmail] = useState(false);
  const history = useHistory();
  const authData = useSelector((state) => {
    return state.Auth;
  });

  const [requestOTP] = useMutation(REQUEST_OTP, {
    onCompleted({ SendOTP }) {
      setErrorPhone(null);
      if (SendOTP) {
        setOpenModalOTP(true);
        setOpenModal(false);
      }
    },
    onError(errors) {
      if (errors.message === 'Please wait for one hour.') {
        setErrorPhone(
          t('Pengiriman OTP sudah melebihi batas kirim. Mohon untuk menunggu 1 jam lagi.'),
        );
      } else if (errors.message === 'Phone Number has been register!') {
        setErrorPhone(t('Nomor telepon telah terdaftar'));
      }
      setErrorPhone(errors.message);
      console.error(errors.message);
    },
  });

  const [completeProfile, { loading }] = useMutation(COMPLETE_PROFILE, {
    refetchQueries: [
      {
        query: GET_USER_DETAIL,
        context: setContext(authData),
        variables: {
          slug: getValue(authData, 'data.slug'),
        },
      },
    ],
    onCompleted() {
      if (selected !== 'trainings' && selected) {
        history.push(`/pembayaran/${selected ?? ''}`);
      } else if (selected === 'trainings') {
        history.push(`/trainings`);
      }
      setOpenModal(false);
    },
    onError(errors) {
      setErrorEmail(errors.message);
      console.error(errors.message);
    },
  });

  const onSubmit = (data) => {
    if (!data.phoneNumber) {
      data = {
        ...data,
        phoneNumber: profil?.User?.phone_number,
      };
    }
    if (!data.email) {
      data = {
        ...data,
        email: profil?.User?.email,
      };
    }

    if (phoneNumber) {
      setDataLengkapiProfile({
        fullname: data.fullname,
        email: data.email,
        phoneNumber: data.phoneNumber,
      });

      requestOTP({
        context: setContext(authData),
        variables: {
          input: {
            type: 'completingProfile',
            phoneNumber: data.phoneNumber,
          },
        },
      });
    } else if (email) {
      completeProfile({
        context: setContext(authData),
        variables: {
          input: {
            fullname: data.fullname,
            email: data.email,
            phoneNumber: data.phoneNumber,
          },
        },
      });
    }
  };

  return (
    <LengkapiProfilModalStyled dark {...rest}>
      <Title>{t('Lengkapi Profil Kamu')}</Title>
      <Description>
        {t('Sebelum mulai pembayaran, silahkan lengkapi profil kamu terlebih dahulu.')}
      </Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContainerInput>
          <InputField
            grey
            register={register({ required: t('Nama wajib diisi') })}
            name="fullname"
            label={t('Nama')}
            danger={errors.nama}
            placeholder={t('Nama')}
            type="text"
          />
          <FormError lengkapiProfile>
            {t('Pastikan nama sudah benar. Nama akan digunakan untuk Sertifikat')}
          </FormError>
          {errors.nama && <FormError>{errors.nama.message}</FormError>}
        </ContainerInput>

        <ContainerInput>
          <InputField
            disabled={profil?.User?.email}
            grey
            register={register({
              required: t('Email wajib diisi'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('Format email salah. Contoh: terampil@gmail.com'),
              },
            })}
            name="email"
            label={t('Email')}
            danger={errors.email || errorEmail}
            placeholder={t('Email')}
            type="email"
            onChange={(val) => {
              setEmail(true);
              if (val.target.value === '') {
                setErrorEmail();
              }
            }}
          />
          {errors.email && <FormError>{errors.email.message}</FormError>}
          {errorEmail && <FormError>{errorEmail}</FormError>}
        </ContainerInput>

        <InputPhone
          disabled={profil?.User?.phone_number}
          lengkapiProfile
          grey
          name="phoneNumber"
          label={t('Nomor HP')}
          placeholder={t('Tambah Nomor HP')}
          type="tel"
          danger={errors.phoneNumber || errorPhone}
          register={register({
            required: t('Nomor HP wajib diisi'),
            pattern: {
              value: /^8\d{8,11}$/,
              message: t('Nomor HP tidak valid'),
            },
          })}
          onChange={(val) => {
            setPhoneNumber(true);
            if (val.target.value === '') {
              setErrorPhone();
            }
          }}
        />
        {errors.phoneNumber && <FormError>{errors.phoneNumber.message}</FormError>}
        {errorPhone && <FormError>{errorPhone}</FormError>}
        <SaveButton disabled={loading} type="submit">
          {loading ? 'Loading...' : t('Simpan')}
        </SaveButton>
      </form>
    </LengkapiProfilModalStyled>
  );
}

export default LengkapiProfilModal;
