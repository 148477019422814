import styled from 'styled-components';
import TitleStyled from 'components/atoms/Tittle';

export const Container = styled.section`
  width: 100%;
  background-color: #141414;
  box-sizing: border-box;
  padding: 50px 100px;
  position: relative;

  .container-line {
    width: 75%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;

    img {
      width: 100%;
    }
  }

  .container-up {
    display: grid;
    place-items: center;
  }

  @media screen and ${(props) => props.theme.breakpoints.xl} {
    padding: 100px;

    .container-line {
      width: 69%;
      top: 22%;

      img {
        width: 100%;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 30px;

    .container-line {
      width: 65%;
      top: 26%;

      img {
        width: 100%;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding: 30px;

    .container-line {
      width: 65%;
      top: 29%;

      img {
        width: 100%;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    padding: 30px;

    .container-line {
      width: 5px;
      top: 15%;
      height: 70%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 5px;
  }
`;

export const ContainerContain = styled.div`
  background-color: #202020;
  display: grid;
  place-items: center;
  width: 450px;
  height: 280px;
  padding: 24px 25px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin: 40px 0;
  box-sizing: border-box;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 350px;
  }

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 328px;
    margin: 20px 0;
  }

  @media screen and ${(props) => props.theme.breakpoints.xxs} {
    width: 290px;
  }
`;
export const Title = styled(TitleStyled)`
  padding: 0;

  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0 30px;
    box-sizing: border-box;
    margin: 20px 0;
  }
`;

export const SubTitle = styled.h2`
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #fdfdfd;
  margin: 0;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 18px;
  }
`;

export const ContainerImage = styled.div`
  width: 80px;

  img {
    max-width: 100%;
  }
`;
export const Paragraf = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: center;
  color: #fdfdfd;
  margin: 16px 0 0 0;

  @media screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 14px;
  }
`;

export const ContainerBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and ${(props) => props.theme.breakpoints.sm} {
    flex-direction: column;
  }
`;
