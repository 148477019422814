import styled from 'styled-components';

export const ContainerCardDescTBM = styled.div`
  background: #ffffff;

  box-shadow: 0 4px 10px rgba(98, 98, 98, 0.15);
  border-radius: 10px;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.div`
  width: 120px;
  height: 120px;
  margin-bottom: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const TextDescCardTBM = styled.p`
  max-width: 232px;
  padding: 0;
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  color: #707070;
`;
