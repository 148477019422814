import { gql } from '@apollo/client';

export const FETCH_TESTIMONI = gql`
  query getTestimonies($input: TestimonyRequest) {
    Testimonies(input: $input) {
      status
      statusText
      items {
        id
        name
        text
        image
        profession {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_TESTIMONI_TRAINER = gql`
  query getTestimoniesTrainer($limit: Int) {
    TestimoniesTrainer(input: { limit: $limit }) {
      status
      statusText
      total
      items {
        id
        name
        text
        image
        profession {
          name
        }
      }
    }
  }
`;

export const FETCH_TESTIMONI_USER = gql`
  query getTestimoines($trainerId: Int) {
    TrainerTestimonies(trainerId: $trainerId) {
      total
      items {
        text
        profile {
          avatar
          fullname
          profession
          total_point
          created_at
        }
        rating
      }
    }
  }
`;
