import styled from 'styled-components';

export const Header = styled.header`
  width: 100vw;
  height: 60px;

  box-sizing: border-box;

  display: none;
  align-items: center;
  justify-content: space-between;

  font-size: 20px;

  position: sticky;
  z-index: 3;
  top: 0;

  background: #141414;
  padding: 0 20px;

  svg {
    margin-right: 36px;
  }

  & div {
    display: flex;
    align-items: center;

    cursor: pointer;
  }

  button {
    font-family: Inter;
    background: transparent;
    color: ${({ theme }) => theme.color.primary};
    border: none;
    transition: all 200ms ease;

    cursor: pointer;
  }

  .disabled {
    cursor: default;
    color: #626262;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    display: flex;
  }
`;
