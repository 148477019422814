import React from 'react';
import MaxContainer from '../../../atoms/MaxContainer';
import { DescTBMContainer, ItemsDescTBM, TextDescTBM, TitleDescTBM } from './styled';
import CardDescTBM from '../../../molecule/TBMMolecules/CardDescTBM';
import { ReactComponent as ArrowIcon } from 'assets/svg/TBM/arrow.svg';
import { ReactComponent as PeopleIcon } from 'assets/svg/TBM/people.svg';
import { ReactComponent as ListIcon } from 'assets/svg/TBM/list.svg';

const DescSectionTBM = () => {
  return (
    <DescTBMContainer>
      <MaxContainer>
        <DescTBMContainer>
          <TitleDescTBM>{'Bagaimana Terampil Membantu\nMenemukan Karier Terbaikmu?'}</TitleDescTBM>
          <TextDescTBM>
            Sumber Daya Manusia di Indonesia perlu mengetahui potensi kariernya secara “spesifik”
            untuk memastikan Kamu sebagai bagian dari SDM Indonesia mampu memaksimalkan potensi
            dalam dunia profesional
          </TextDescTBM>
          <ItemsDescTBM>
            <CardDescTBM
              image={<ArrowIcon />}
              text={'Menggunakan Metode RIASEC & MBTI sehingga akurasi tes \n' + 'lebih tinggi'}
            />
            <CardDescTBM
              image={<PeopleIcon />}
              text={'Menggunakan personalisasi untuk menentukan profesi \n' + 'yang cocok'}
            />
            <CardDescTBM
              image={<ListIcon />}
              text={'Menampilkan kepribadian yang lebih detail dengan Metode DISC'}
            />
          </ItemsDescTBM>
        </DescTBMContainer>
      </MaxContainer>
    </DescTBMContainer>
  );
};

export default DescSectionTBM;
