import React from 'react';
import { trackWindowScroll, LazyLoadImage } from 'react-lazy-load-image-component';
import { useQuery } from '@apollo/client';
import { GET_COOPERATION } from 'queries/cooperation';

import { Container, Title, ImageDiv, ContainerImage } from './Cooperation.styled';
import MaxContainer from 'components/atoms/MaxContainer';
import { LoaderDualRing } from 'components/atoms/Loading';

//logo cooperations
import AMGLogo from 'assets/png/Cooperations/amg.png';
import CelfitLogo from 'assets/png/Cooperations/celfit.png';
import CicilsewaLogo from 'assets/png/Cooperations/cicilsewa.png';
import OtegoLogo from 'assets/png/Cooperations/otego.png';
import GoldsgymLogo from 'assets/png/Cooperations/goldsgym.png';
import MrmontirLogo from 'assets/png/Cooperations/mrmontir.png';
import GratyoLogo from 'assets/png/Cooperations/gratyo.png';
import TdpLogo from 'assets/png/Cooperations/WhatsApp_Image_2021-09-03_at_11.25 1.png';
import { t } from 'hooks/intl/useLang';

function Cooperation({ scrollPosition, title }) {
  const { loading } = useQuery(GET_COOPERATION, {
    variables: {
      input: {
        limit: 6,
        orderBy: 'id',
        is_active: false,
      },
    },
  });

  const CooperationsImg = [
    // {
    //   id: 1,
    //   name: 'Cicil Sewa',
    //   image: CicilsewaLogo,
    // },
    {
      id: 2,
      name: 'Tdp',
      image: TdpLogo,
    },
    {
      id: 3,
      name: 'Gratyo',
      image: GratyoLogo,
    },
    {
      id: 4,
      name: 'Otego',
      image: OtegoLogo,
    },
    // {
    //   id: 5,
    //   name: 'Celibrity Fitness',
    //   image: CelfitLogo,
    // },
    {
      id: 6,
      name: 'AMG',
      image: AMGLogo,
    },
  ];

  // const activeCompany = data?.Cooperations?.items;
  // let listCompany = [];

  // const filteredCompany = activeCompany && activeCompany.map((n, i) => {
  //   if(n?.name === "Cicil Sewa"){
  //     return listCompany.push(n);
  //   }else if(n?.name === "Golds Gym"){
  //     return listCompany.push(n);
  //   }else if(n?.name === "Mr Montir"){
  //     return listCompany.push(n);
  //   }else if(n?.name === "Otego" ){
  //     return listCompany.push(n)
  //   }else if(n?.name === "Celibrity Fitness" ){
  //     return listCompany.push(n)
  //   }else if(n?.name === "AMG" ){
  //     return listCompany.push(n)
  //   }
  // });

  if (loading) return <LoaderDualRing></LoaderDualRing>;

  return (
    <Container>
      <MaxContainer>
        <div>
          <Title>{title ? title : t('Sudah Dipercaya oleh Perusahaan Ternama')}</Title>
        </div>
        <ContainerImage>
          {CooperationsImg.map((item) => {
            return (
              <ImageDiv key={item.id}>
                <LazyLoadImage
                  key={item.id}
                  alt="logo"
                  src={item?.image}
                  scrollPosition={scrollPosition}
                  effect="blur"
                  className="image-icon"
                />
              </ImageDiv>
            );
          })}
        </ContainerImage>
      </MaxContainer>
    </Container>
  );
}

export default trackWindowScroll(Cooperation);
