import React from 'react';
import { Container, Title } from './styled';
import CardPointPrakerja from 'components/atoms/CardPointPrakerja';
import { t } from 'hooks/intl/useLang';

const PointLangkahPrakerja = () => {
  return (
    <section>
      <Title>{t('4 Langkah #JadiTerampil dengan Kartu Prakerja')}</Title>
      <Container>
        <CardPointPrakerja
          point={1}
          content={t("Dapatkan Kode Voucher Training Terampil di Maubelajarapa.com dan Tokopedia")}
        />
        <CardPointPrakerja point={2} content="Redeem voucher Terampil yang sudah kamu beli." />
        <CardPointPrakerja
          point={3}
          content={t("Pilih online training yang sesuai dengan pilihan kamu")}
        />
        <CardPointPrakerja
          point={4}
          content={t("Selesaikan training dan dapatkan insentif senilai 2,55 juta dari Kartu Prakerja")}
        />
      </Container>
    </section>
  );
};

export default PointLangkahPrakerja;
