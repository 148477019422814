import React from 'react';
import { StyledModal } from './PopupModal.styled';

import './styles.css';

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { checkMobile } from 'utils/checkMobile';

function PopupModal({
  children,
  onRequestClose,
  withoutCloseButton = false,
  medium,
  lengkapiProfile = false,
  videoTraining,
  video,
  mobileClose,
  ...rest
}) {
  return (
    <StyledModal
      lengkapiProfile={lengkapiProfile}
      medium={medium}
      onRequestClose={onRequestClose}
      closeTimeoutMS={400}
      ariaHideApp={false}
      videoTraining={videoTraining}
      {...rest}
    >
      {/* {!checkMobile ? <>
        {!withoutCloseButton && <CloseIcon className="close-button" onClick={onRequestClose} />}
      </> : null} */}
      {(!checkMobile() || mobileClose) && (
        <>
          {!withoutCloseButton && <CloseIcon className="close-button" onClick={onRequestClose} />}
        </>
      )}
      {!video ? children : <div style={{ marginTop: '20px' }}>{children}</div>}
    </StyledModal>
  );
}

export default PopupModal;
