import React from 'react';
import Accordion from 'components/atoms/Accordion';
import { Container, CardContainer } from './styled';
import { t } from 'hooks/intl/useLang';

const CaraPembayaran = ({ data }) => {
  return (
    <Container>
      {data && (
        <>
          <div className="container-title">
            <h2>{t('Cara Pembayaran')}</h2>
          </div>

          <CardContainer>
            {data.map((item, i) => {
              return <Accordion pembayaran={true} className="pembayaran" data={item} key={i} />;
            })}
          </CardContainer>
        </>
      )}
    </Container>
  );
};

export default CaraPembayaran;
