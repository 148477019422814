import styled from 'styled-components';

export const InputFieldStyled = styled.textarea.attrs((props) => ({
  rounded: props.rounded ? '50px' : '5px',
  border: props.danger ? '#D51919' : '#626262',
  borderSize: props.danger ? '2px' : '1px',
}))`
  border: ${(props) => props.borderSize} solid ${(props) => props.border};
  border-radius: ${(props) => props.rounded};
  width: 100%;
  height: 150px;
  font-size: 16px;
  padding: 20px 16px;
  box-sizing: border-box;
  background-color: transparent;
  color: #fdfdfd;
  font-family: Inter, serif;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: #c4c4c4;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 16px 0 4px;
  box-sizing: border-box;
`;

export const LabelInput = styled.div.attrs((props) => ({
  show: props.focus ? 'none' : 'hidden',
}))`
  position: absolute;
  visibility: ${(props) => props.show};
  background-color: #191919;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  margin-left: 18px;
  top: -4px;
  /* identical to box height */

  color: #626262;
`;
