import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ userSettings }) => (userSettings ? '0 0 16px 0' : '50px 0')};

  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: left;

  h3 {
    display: inline;

    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 24px;

    color: #fdfdfd;

    margin: 0;
    margin-right: 32px;
  }

  p {
    font-family: Inter;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    h3 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }

    padding-bottom: 32px;
    flex-wrap: ${({ userSettings }) => (userSettings ? '' : 'wrap')};
    ${({ userSettings }) => (userSettings ? 'padding: 16px 16px 32px 16px;' : '')}
  }
`;

export const ProfilePic = styled.div`
  width: 150px;
  height: 150px;

  border-radius: 100%;

  background: #626262;

  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: cover;

  margin-right: 24px;

  flex-shrink: 0;

  &.trainer {
    width: 200px;
    height: 200px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    &.trainer,
    & {
      width: 100px;
      height: 100px;
    }

    margin-right: 12px;
  }
`;

export const Detail = styled.div`
  .profession {
    margin-bottom: 35px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    .profession {
      margin-bottom: 24px;
    }
  }
`;

export const HeadContainer = styled.div`
  margin-bottom: 12px;

  display: flex;
  align-items: center;

  button {
    background: transparent;
    border: 1px solid #f18f01;

    padding: 0;

    width: 140px;
    height: 40px;

    border-radius: 5px;

    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #f18f01;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    margin-bottom: 0;

    button {
      font-size: 12px;
      width: 80px;
      height: 30px;
    }
  }
`;

export const MainContainer = styled.div`
  color: #707070;

  img {
    margin-right: 10px;
  }

  span {
    display: grid;
    place-items: center;
    margin: 0px 16px;
    border-radius: 100%;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  .trainer-stats {
    margin-bottom: 14px;
    display: flex;
  }

  .trainer-kategori {
    display: flex;

    @media screen and ${({ theme }) => theme.breakpoints.xs} {
      margin-top: 32px;
    }
  }

  div.trainer-kategori-more {
    display: flex;

    @media screen and ${({ theme }) => theme.breakpoints.xs} {
      margin-top: 0px;
    }
  }


  .point {
    display: inline;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    color: #00debf;
  }

  .point-secondary {
    color: #c4c4c4;
  }

  a {
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.color.primary};

    margin-right: 16px;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    a {
      font-size: 12px;
    }

    span {
      margin: 0 8px;
    }

    .trainer-stats {
      margin-top: 16px;
      margin-bottom: 12px;

      & p {
        line-height: 15px;
      }
    }

    .align-center {
      font-size: 12px;
    }

    .point {
      font-weight: normal;
      font-size: 14px;
    }
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;

  & > a {
    margin-right: 16px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.sm} {
    & > a {
      margin-right: 12px;
      
      img {
        max-width: 16px;
      }
  }

`;
