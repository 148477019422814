import React from 'react';
import {
  Container,
  Image,
  BackgroundImage,
  Points,
  Content,
  IconRoundChekList,
  Body,
  Title,
} from './Styled';

// import assets
import IconRoundCheck from 'assets/svg/Prakerja/RoundChecklist.svg';
import { t } from 'hooks/intl/useLang';

function KartuPrakerjaImage() {
  return (
    <Container>
      <div>
        <BackgroundImage />
        <Image
          src="https://d2jr0bw5daaf2g.cloudfront.net/assets/prakerja/kartu-prakerja.svg"
          alt="kartu prakerja"
        />
      </div>
      <div>
        <Title>{t('Apa itu Kartu Prakerja?')}</Title>
        <Points>
          <Content>
            <IconRoundChekList src={IconRoundCheck} alt="icon-round-check" />
            <Body>
              {t('Kartu Prakerja adalah program pengembangan kompetensi berupa bantuan biaya yang ditujukan untuk WNI usia 18 tahun ke atas')}
            </Body>
          </Content>
          <Content>
            <IconRoundChekList src={IconRoundCheck} alt="icon-round-check" />
            <Body>
              {t('Bantuan yang didapat berupa subsidi non-tunai sebesar Rp 1 Juta untuk mengikuti Online Training di Terampil')}
            </Body>
          </Content>
          <Content>
            <IconRoundChekList src={IconRoundCheck} alt="icon-round-check" />
            <Body>
              {t('Merespon dampak COVID-19, Kartu Prakerja akan diprioritaskan bagi pekerja maupun pelaku usaha mikro/kecil yang terdampak penghidupannya')}
            </Body>
          </Content>
          <Content>
            <IconRoundChekList src={IconRoundCheck} alt="icon-round-check" />
            <Body>
              {t('Pemegang Kartu Prakerja juga akan mendapatkan insentif langsung sebesar Rp 600 ribu / bulan selama 4 bulan setelah menyelesaikan Online Training pertama dan tambahan Rp 150 ribu setelah mengisi survei di www.prakerja.go.id')}
            </Body>
          </Content>
        </Points>
      </div>
    </Container>
  );
}

export default KartuPrakerjaImage;
